import React from "react";
import {observer} from "mobx-react-lite";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import Header from "../Header";
import {CContainer} from "@coreui/react";

const AuthorizedView = observer((props) => {
    return (
        <div className="c-app c-default-layout">
            <Sidebar/>

            <div className="c-wrapper">
                <Header/>

                <div className="c-body">
                    <main className="c-main">
                        <CContainer fluid>
                            {props.children}
                        </CContainer>
                    </main>
                </div>

                <Footer/>
            </div>
        </div>
    );
});

export default AuthorizedView;
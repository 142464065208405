import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {CBadge, CCard, CCardBody, CCardHeader, CCol, CDataTable, CLink, CRow} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import {DateAndTime} from "../DateAndTime";
import Error from "../Error";
import Pagination from "../Pagination";
import {UserFile, UserFileStatusType} from "../../generated/graphql";

const getStatusBadgeColor = (status: UserFileStatusType) => {
    switch (status) {
        case UserFileStatusType.Failed:
            return "danger";
        case UserFileStatusType.Ready:
            return "success";
        case UserFileStatusType.Submitted:
            return "info";
    }
};

const fields = [
    {key: "file"},
    {key: "status"},
    {key: "createdAtUtc", _style: {width: "25%"}},
    {
        key: "download",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const UserFilesView = observer(() => {
    const store = useContext(StoreContext);
    const {userFiles} = store;

    const refresh = () => {
        userFiles.fetchUserFiles();
    };

    const filter = (str: string) => {
        userFiles.setFilter(!str ? undefined : {
            or: [
                {
                    name: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    ext: {
                        likeInsensitive: `%${str}%`
                    }
                }
            ]
        });
    };

    return (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`User Files`}

                        <CLink className="ml-1"
                               title={t`Refresh`}
                               onClick={() => refresh()}
                               disabled={userFiles.loading}>
                            <CIcon name="cil-sync" className="mfe-2" />
                        </CLink>
                    </CCardHeader>

                    <CCardBody>
                        <CDataTable
                                noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                items={(userFiles.userFiles && userFiles.userFiles.length > 0 && userFiles.userFiles) || []}
                                loading={userFiles.loading}
                                fields={fields}
                                border={true}
                                tableFilter={{external: true, label: t`Filter:`, placeholder: t`type file name or extension`}}
                                onTableFilterChange={filter}
                                columnHeaderSlot={{
                                    file: t`File`,
                                    status: t`Status`,
                                    createdAtUtc: t`Registered`
                                }}
                                scopedSlots={{
                                    file: (userFile: UserFile) =>
                                        <Observer>{() => {
                                            return (
                                                <td>
                                                    {userFile.name}
                                                    <CBadge color="primary">
                                                        {userFile.ext}
                                                    </CBadge>
                                                </td>
                                            );
                                        }}</Observer>,
                                    status: (userFile: UserFile) =>
                                        <Observer>{() => {
                                            let status = "";
                                            switch (userFile.status) {
                                                case UserFileStatusType.Failed:
                                                    status = t`failed`;
                                                    break;
                                                case UserFileStatusType.Ready:
                                                    status = t`ready`;
                                                    break;
                                                case UserFileStatusType.Submitted:
                                                    status = t`submitted`;
                                                    break;
                                            }

                                            return (
                                                <td>
                                                    <CBadge color={getStatusBadgeColor(userFile.status)}>
                                                        {status}
                                                    </CBadge>
                                                </td>
                                            );
                                        }}</Observer>,
                                    createdAtUtc: (userFile: UserFile) => (
                                        <td>
                                            <DateAndTime date={new Date(userFile.createdAtUtc)}/>
                                        </td>
                                    ),
                                    download: (userFile: UserFile) => {
                                        return (
                                            <Observer>{() =>
                                                    <td className="py-2">
                                                        {userFile.status === UserFileStatusType.Ready && <CLink onClick={() => userFiles.download(userFile.id, userFile.ext)}
                                                               disabled={userFiles.loading}
                                                               title={t`Download`}>
                                                            <CIcon name="cil-data-transfer-down" className="mfe-2"/>
                                                        </CLink>}
                                                    </td>
                                            }</Observer>
                                        );
                                    }
                                }}
                                underTableSlot={userFiles.error ? <Error>{userFiles.error}</Error> : <Pagination loading={userFiles.loading}
                                                                                                           pageInfo={userFiles.pageInfo}
                                                                                                           onPage={(page) => userFiles.setPage(page)}/>}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
});

export default UserFilesView;
import React from "react";
import {t} from "ttag";
import {RoyaltyVariantType} from "../generated/graphql";

const RoyaltyVariants: React.FC = () => {
    return (
        <React.Fragment>
            <option value={RoyaltyVariantType.PerRevenueShare}>{t`Revenue share`}</option>
            <option value={RoyaltyVariantType.PerSessionFixed}>{t`Per session (fixed)`}</option>
            <option value={RoyaltyVariantType.PerSessionMinute}>{t`Per minute`}</option>
            <option value={RoyaltyVariantType.PerSessionProgressive}>{t`Per hour (1st hour up to 90min)`}</option>
            <option value={RoyaltyVariantType.PerPlayerFixed}>{t`Per player (fixed)`}</option>
            <option value={RoyaltyVariantType.PerPlayerMinute}>{t`Per player minute`}</option>
            <option value={RoyaltyVariantType.PerPlayerProgressive}>{t`Per player hour (1st hour up to 90min)`}</option>
            <option value={RoyaltyVariantType.PerSessionFixedStrict}>{t`Per session (fixed, even unpaid)`}</option>
            <option value={RoyaltyVariantType.PerSessionMinuteStrict}>{t`Per minute (even unpaid)`}</option>
            <option value={RoyaltyVariantType.PerSessionProgressiveStrict}>{t`Per hour (even unpaid, 1st hour up to 90min)`}</option>
            <option value={RoyaltyVariantType.PerPlayerFixedStrict}>{t`Per player (fixed, even unpaid)`}</option>
            <option value={RoyaltyVariantType.PerPlayerMinuteStrict}>{t`Per player minute (even unpaid)`}</option>
            <option value={RoyaltyVariantType.PerPlayerProgressiveStrict}>{t`Per player hour (even unpaid, 1st hour up to 90min)`}</option>
        </React.Fragment>
    );
};

export default RoyaltyVariants;
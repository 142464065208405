import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Mutation
} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import ProductFragment from "../api/graphql/ProductFragment";
import {EditProductStore} from "./EditProductStore";

class NewProductStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async create(
        names: {[locale: string]: string},
        imgBase64: string
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            const result = await this.createProduct(imgBase64);

            if (result?.data?.createProduct?.product?.id) {
                for (let key in names) {
                    const value = names[key];
                    await EditProductStore.setProductName(result.data.createProduct.product.id, key, value);
                }
            }

            this.rootStore.products.fetchProducts(true);
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private createProduct(imgBase64: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            imgBase64: string
        }>({
            mutation: gql`mutation ($imgBase64: String!) {
                createProduct(input: {product: {imgBase64: $imgBase64}}) {
                    product {
                        ...ProductFragment
                    }
                }
            },
            ${ProductFragment}`,
            variables: {
                imgBase64
            }
        }));
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }
}

export {
    NewProductStore
};
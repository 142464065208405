import {action, observable} from "mobx";
import {Customer, Product, UserRoleType, Venue} from "../generated/graphql";
import {beginOfTheMonth} from "../utils/DateUtils";
import {RootStore} from "./RootStore";
import {
    AggregateCustomersType,
    AggregateDateType,
    AggregateProductsType,
    CompareSumType,
    CompareType,
    IncludeUnknown,
    IncludeUnpaid,
    OrderByType
} from "../api/LicenseSessionsApi";

class ReportsFilterStore {
    @observable
    public filterIncludeUnknown: IncludeUnknown = IncludeUnknown.Include;

    @observable
    public filterIncludeUnpaid: IncludeUnpaid = IncludeUnpaid.Include;

    @observable.ref
    public filterCustomers: Customer[] = [];

    @observable.ref
    public filterCustomerTags: string[] = [];

    @observable.ref
    public filterVenues: Venue[] = [];

    @observable.ref
    public filterProducts: Product[] = [];

    @observable
    public filterDateStart: Date | null = beginOfTheMonth(new Date());

    @observable
    public filterDateEnd: Date | null = new Date();

    @observable
    public orderBy: OrderByType = OrderByType.ByDateDesc;

    @observable
    public aggregateDateType: AggregateDateType = AggregateDateType.ByDay;

    @observable
    public aggregateCustomersType: AggregateCustomersType = AggregateCustomersType.NotSplit;

    @observable
    public aggregateProductsType: AggregateProductsType = AggregateProductsType.NotSplit;

    @observable
    public compareType: CompareType = CompareType.Approved;

    @observable
    public compareSumType: CompareSumType = CompareSumType.Total;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public setFilter(filter: {
        filterIncludeUnknown?: IncludeUnknown,
        filterIncludeUnpaid?: IncludeUnpaid,
        filterCustomers?: Customer[],
        filterCustomerTags?: string[],
        filterVenues?: Venue[],
        filterProducts?: Product[],
        filterDateStart?: Date | null,
        filterDateEnd?: Date | null
    }) {
        if (filter.filterIncludeUnknown !== undefined) {
            this.filterIncludeUnknown = filter.filterIncludeUnknown;
        }

        if (filter.filterIncludeUnpaid !== undefined) {
            this.filterIncludeUnpaid = filter.filterIncludeUnpaid;
        }

        if (filter.filterCustomers) {
            this.filterCustomers = filter.filterCustomers;
        }

        if (filter.filterCustomerTags) {
            this.filterCustomerTags = filter.filterCustomerTags;
        }

        if (filter.filterVenues) {
            this.filterVenues = filter.filterVenues;
        }

        if (filter.filterProducts) {
            this.filterProducts = filter.filterProducts;
        }

        if (filter.filterDateStart !== undefined) {
            this.filterDateStart = filter.filterDateStart
        }

        if (filter.filterDateEnd !== undefined) {
            this.filterDateEnd = filter.filterDateEnd
        }
    }

    @action
    public setAggregateDateType(aggregateDateType: AggregateDateType) {
        this.aggregateDateType = aggregateDateType;
    }

    @action
    public setAggregateCustomersType(aggregateCustomersType: AggregateCustomersType) {
        this.aggregateCustomersType = aggregateCustomersType;
    }

    @action
    public setAggregateProductsType(aggregateProductsType: AggregateProductsType) {
        this.aggregateProductsType = aggregateProductsType;
    }

    @action
    public setCompareType(compareType: CompareType) {
        this.compareType = compareType;
    }

    @action
    public setCompareSumType(compareSumType: CompareSumType) {
        this.compareSumType = compareSumType;
    }

    @action
    public setOrderBy(orderBy: OrderByType) {
        this.orderBy = orderBy;
    }

    @action
    public reset() {
        this.filterIncludeUnknown = this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelUser ? IncludeUnknown.NotInclude : IncludeUnknown.Include;
        this.filterIncludeUnpaid = IncludeUnpaid.Include;
        this.filterCustomers =
            this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelUser && this.rootStore.auth.currentUser.userCustomerByUserId?.customerByCustomerId ?
                [this.rootStore.auth.currentUser.userCustomerByUserId.customerByCustomerId] : [];
        this.filterCustomerTags = [];
        this.filterVenues = [];
        this.filterProducts = [];
        this.filterDateStart = beginOfTheMonth(new Date());
        this.filterDateEnd = new Date();
        this.orderBy = OrderByType.ByDateDesc;

        this.aggregateDateType = AggregateDateType.ByDay;
        this.aggregateCustomersType = AggregateCustomersType.NotSplit;
        this.aggregateProductsType = AggregateProductsType.NotSplit;
        this.compareType = CompareType.Approved;
        this.compareSumType = CompareSumType.Total;
    }
}

export {
    ReportsFilterStore
}
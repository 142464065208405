import React, {useContext} from "react"
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CRow,
    CSpinner
} from "@coreui/react"
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import Error from "../Error";
import {jt} from "ttag";
import {goToWithPrev, routes} from "../../routes";

const AuthenticatingView = observer(() => {
    const store = useContext(StoreContext);
    const {auth} = store;

    const errorBlock = <CCardGroup>
        <CCard className="p-4">
            <CCardBody>
                <Error>{auth.error}</Error>

                <CRow>
                    <CCol xs="12" className="text-right">
                        <CButton color="primary" className="px-4" onClick={() => goToWithPrev(store, routes.login)}>{jt`Login`}</CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </CCardGroup>;

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="4">
                        {auth.loading && <CSpinner color="primary" style={{width:'4rem', height:'4rem'}}/>}

                        {auth.error && errorBlock}
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
});

export default AuthenticatingView;
import {CBadge, CButton} from "@coreui/react";
import {jt} from "ttag";
import React from "react";
import {PageInfo, PageParam} from "../api/graphql/ApolloClient";

interface PaginationProps {
    loading: boolean,
    pageInfo?: PageInfo,
    onPage: (page: PageParam) => void
}

const Pagination: React.FC<PaginationProps> = (props) => {
    return (
        <div>
            <CButton color="dark" className="px-4 ml-2 mb-2" disabled={props.loading || !props.pageInfo?.firstPage}
                     onClick={() => props.pageInfo?.firstPage && props.onPage(props.pageInfo.firstPage)}>
                {jt`First`}
            </CButton>

            <CButton color="dark" className="px-4 ml-2 mb-2" disabled={props.loading || !props.pageInfo?.prevPage}
                     onClick={() => props.pageInfo?.prevPage && props.onPage(props.pageInfo.prevPage)}>
                {jt`Prev`}
            </CButton>

            <CButton color="dark" className="px-4 ml-2 mb-2" disabled={props.loading || !props.pageInfo?.nextPage}
                     onClick={() => props.pageInfo?.nextPage && props.onPage(props.pageInfo.nextPage)}>
                {jt`Next`}
            </CButton>

            <CButton color="dark" className="px-4 ml-2 mb-2" disabled={props.loading || !props.pageInfo?.lastPage}
                     onClick={() => props.pageInfo?.lastPage && props.onPage(props.pageInfo.lastPage)}>
                {jt`Last`}
            </CButton>

            {props.pageInfo &&
                <CBadge color="light" className="px-4 ml-2 mb-2">{jt`Total: ${props.pageInfo.totalCount}`}</CBadge>}
        </div>
    );
}

export default Pagination;
import React from "react";
import {LicenseSessionComment, Maybe} from "../generated/graphql";
import {CCol, CListGroup, CListGroupItem, CRow} from "@coreui/react";
import {DateAndTime} from "./DateAndTime";
import {t} from "ttag";

const Comments: React.FC<{comments: Maybe<LicenseSessionComment>[]}> = (props) => {
    return (
        <CListGroup>
            {props.comments.filter(comment => !!comment).map(comment => (
                    <CListGroupItem key={comment?.id}>
                        <h6 className="d-flex w-100 justify-content-between">
                            {comment?.userByUserId?.name && <strong className="mr-2">{comment?.userByUserId?.name}</strong>}

                            <u><DateAndTime date={new Date(comment?.createdAtUtc)}/></u>
                        </h6>

                        <div className="mb-1">
                            {comment?.comment}
                        </div>
                    </CListGroupItem>
            ))}
        </CListGroup>
    );
};

export default Comments;
import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {Customer, Product, UserRoleType, Venue} from "../generated/graphql";
import {CBadge, CDropdown, CDropdownMenu, CDropdownToggle, CInput, CLink, CRow, CSelect} from "@coreui/react";
import {t} from "ttag";
import SelectCustomer from "./SelectCustomer";
import SelectProduct from "./SelectProduct";
import {beginOfTheMonth, beginOfTheYear, endOfTheMonth, endOfTheYear, toISODate} from "../utils/DateUtils";
import {useForm} from "react-hook-form";
import {
    AggregateCustomersType,
    AggregateDateType,
    AggregateProductsType,
    CompareSumType,
    CompareType,
    IncludeUnknown,
    IncludeUnpaid,
    OrderByType
} from "../api/LicenseSessionsApi";
import SelectVenue from "./SelectVenue";

interface ReportsFilter {
    areControlsEnabled: boolean;
    showUnknown?: boolean;
    showUnpaid?: boolean;
    showCustomers?: boolean;
    showVenues?: boolean;
    showProducts?: boolean;
    showDates?: boolean;
    showOrderBy?: boolean;
    orderBySupportedValues: string[];
    showAggregateBy?: boolean;
    showSplitBy?: boolean;
    showCompareBy?: boolean;
    showCompareSumType?: boolean;
}

const ReportsFilter = observer((props: ReportsFilter) => {
    const store = useContext(StoreContext);
    const {auth, reportsFilter, customers} = store;

    const {register, setValue} = useForm();

    const onChangeDateStart = (value: string) => {
        let filterDateStart = value ? new Date(value) : null;

        if (filterDateStart) {
            switch (reportsFilter.aggregateDateType) {
                case AggregateDateType.ByMonth:
                    filterDateStart = beginOfTheMonth(filterDateStart);
                    break;
                case AggregateDateType.ByYear:
                    filterDateStart = beginOfTheYear(filterDateStart);
                    break;
            }

            setValue("dateStart", toISODate(filterDateStart));
        }

        reportsFilter.setFilter({filterDateStart});
    };

    const onChangeDateEnd = (value: string) => {
        let filterDateEnd = value ? new Date(value) : null;

        if (filterDateEnd) {
            switch (reportsFilter.aggregateDateType) {
                case AggregateDateType.ByMonth:
                    filterDateEnd = endOfTheMonth(filterDateEnd);
                    break;
                case AggregateDateType.ByYear:
                    filterDateEnd = endOfTheYear(filterDateEnd);
                    break;
            }

            setValue("dateEnd", toISODate(filterDateEnd));
        }

        reportsFilter.setFilter({filterDateEnd});
    };

    const onChangeAggregateDateType = (aggregateDateType: AggregateDateType) => {
        if (aggregateDateType === AggregateDateType.ByMonth) {
            const filterDateStart = !reportsFilter.filterDateStart ? null : beginOfTheMonth(reportsFilter.filterDateStart);

            const filterDateEnd = !reportsFilter.filterDateEnd ? null : endOfTheMonth(reportsFilter.filterDateEnd);

            if (filterDateStart) {
                setValue("dateStart", toISODate(filterDateStart));
            }

            if (filterDateEnd) {
                setValue("dateEnd", toISODate(filterDateEnd));
            }

            reportsFilter.setFilter({filterDateStart, filterDateEnd});
            reportsFilter.setAggregateDateType(aggregateDateType);
        } else if (aggregateDateType === AggregateDateType.ByYear) {
            const filterDateStart = !reportsFilter.filterDateStart ? null : beginOfTheYear(reportsFilter.filterDateStart);

            const filterDateEnd = !reportsFilter.filterDateEnd ? null : endOfTheYear(reportsFilter.filterDateEnd);

            if (filterDateStart) {
                setValue("dateStart", toISODate(filterDateStart));
            }

            if (filterDateEnd) {
                setValue("dateEnd", toISODate(filterDateEnd));
            }

            reportsFilter.setFilter({filterDateStart, filterDateEnd});
            reportsFilter.setAggregateDateType(aggregateDateType);
        } else {
            reportsFilter.setAggregateDateType(aggregateDateType);
        }
    };

    const availableTags = customers.tags?.filter(tag => tag && !reportsFilter.filterCustomerTags.includes(tag));

    return (
        <React.Fragment>
                <CRow alignHorizontal="left" gutters={false} className="mb-3">
                    {props.showUnpaid && <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setFilter({filterIncludeUnpaid: (event.target as HTMLSelectElement).value as IncludeUnpaid})}
                                 defaultValue={reportsFilter.filterIncludeUnpaid}
                                 disabled={!props.areControlsEnabled}>
                            <option value={IncludeUnpaid.NotInclude}>{t`Not include unpaid`}</option>
                            <option value={IncludeUnpaid.Include}>{t`Include unpaid`}</option>
                        </CSelect>
                    </div>}

                    {props.showUnknown && auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setFilter({filterIncludeUnknown: (event.target as HTMLSelectElement).value as IncludeUnknown})}
                                 defaultValue={reportsFilter.filterIncludeUnknown}
                                 disabled={!props.areControlsEnabled}>
                            <option value={IncludeUnknown.NotInclude}>{t`Not include unknown`}</option>
                            <option value={IncludeUnknown.Include}>{t`Include unknown`}</option>
                            <option value={IncludeUnknown.IncludeOnly}>{t`Include only unknown`}</option>
                        </CSelect>
                    </div>}

                    {props.showCustomers && auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly &&
                    <div className="mb-2 ml-2">
                        <CDropdown>
                            <CDropdownToggle color="primary" disabled={!props.areControlsEnabled}>
                                {t`Select customers`}
                            </CDropdownToggle>

                            <CDropdownMenu>
                                <div className="px-4 py-3">
                                    <SelectCustomer
                                            selectedCustomerIds={reportsFilter.filterCustomers.map(customer => customer.id)}
                                            onSelect={(customer: Customer) => {
                                                if (reportsFilter.filterCustomers.find(customerFind => customerFind.id === customer.id)) {
                                                    reportsFilter.setFilter({
                                                        filterCustomers: reportsFilter.filterCustomers
                                                                .filter(customerFilter => customerFilter.id !== customer.id)
                                                    });
                                                } else {
                                                    reportsFilter.setFilter({
                                                        filterCustomers: [...reportsFilter.filterCustomers, customer]
                                                    });
                                                }
                                            }}/>
                                </div>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>}

                    {props.showCustomers && auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly &&
                    <div className="mb-2 ml-2">
                        <CDropdown>
                            <CDropdownToggle color="primary" disabled={!props.areControlsEnabled || !availableTags || availableTags.length <= 0}>
                                {t`Select customer tags`}
                            </CDropdownToggle>

                            <CDropdownMenu>
                                <div className="px-4 py-3">
                                    {availableTags && availableTags.map(tag =>
                                        <CBadge color="success"
                                                style={{"cursor": "pointer"}}
                                                title={t`Add tag`}
                                                className="mr-2"
                                                key={tag}
                                                onClick={() => tag && reportsFilter.setFilter({filterCustomerTags: [...reportsFilter.filterCustomerTags, tag]})}>{tag}</CBadge>
                                    )}
                                </div>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>}

                    {props.showVenues && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly &&
                            <div className="mb-2 ml-2">
                                <CDropdown>
                                    <CDropdownToggle color="primary" disabled={!props.areControlsEnabled}>
                                        {t`Select venues`}
                                    </CDropdownToggle>

                                    <CDropdownMenu>
                                        <div className="px-4 py-3">
                                            <SelectVenue
                                                    selectedVenueIds={reportsFilter.filterVenues.map(venue => venue.id)}
                                                    onSelect={(venue: Venue) => {
                                                        if (reportsFilter.filterVenues.find(venueFind => venueFind.id === venue.id)) {
                                                            reportsFilter.setFilter({
                                                                filterVenues: reportsFilter.filterVenues
                                                                        .filter(venueFilter => venueFilter.id !== venue.id)
                                                            });
                                                        } else {
                                                            reportsFilter.setFilter({
                                                                filterVenues: [...reportsFilter.filterVenues, venue]
                                                            });
                                                        }
                                                    }}/>
                                        </div>
                                    </CDropdownMenu>
                                </CDropdown>
                            </div>}

                    {props.showProducts && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly &&
                    <div className="mb-2 ml-2">
                        <CDropdown>
                            <CDropdownToggle color="primary" disabled={!props.areControlsEnabled}>
                                {t`Select products`}
                            </CDropdownToggle>

                            <CDropdownMenu>
                                <div className="px-4 py-3">
                                    <SelectProduct
                                            selectedProductIds={reportsFilter.filterProducts.map(product => product.id)}
                                            onSelect={(product: Product) => {
                                                if (reportsFilter.filterProducts.find(productFind => productFind.id === product.id)) {
                                                    reportsFilter.setFilter({
                                                        filterProducts: reportsFilter.filterProducts
                                                                .filter(customerFilter => customerFilter.id !== product.id)
                                                    });
                                                } else {
                                                    reportsFilter.setFilter({
                                                        filterProducts: [...reportsFilter.filterProducts, product]
                                                    });
                                                }
                                            }}/>
                                </div>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>}

                    {props.showDates && <React.Fragment>
                    <div className="mb-2 ml-2">
                        <CInput type="date"
                                name="dateStart"
                                title={t`Select start date`}
                                innerRef={register()}
                                defaultValue={toISODate(reportsFilter.filterDateStart) || ""}
                                onChange={event => onChangeDateStart((event.target as HTMLInputElement).value)}
                                disabled={!props.areControlsEnabled}/>
                    </div>

                    <div className="mb-2 ml-2">
                        <CInput type="date"
                                name="dateEnd"
                                title={t`Select end date`}
                                innerRef={register()}
                                defaultValue={toISODate(reportsFilter.filterDateEnd) || ""}
                                onChange={event => onChangeDateEnd((event.target as HTMLInputElement).value)}
                                disabled={!props.areControlsEnabled}/>
                    </div>
                    </React.Fragment>}

                    {props.showOrderBy &&
                    <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setOrderBy((event.target as HTMLSelectElement).value as OrderByType)}
                                 defaultValue={reportsFilter.orderBy}
                                 disabled={!props.areControlsEnabled}>
                            {props.orderBySupportedValues.includes(OrderByType.SubmittedFirst) && <option value={OrderByType.SubmittedFirst}>{t`Submitted first order`}</option>}
                            {props.orderBySupportedValues.includes(OrderByType.ByDateDesc) && <option value={OrderByType.ByDateDesc}>{t`Date descending order`}</option>}
                            {props.orderBySupportedValues.includes(OrderByType.ByDateAsc) && <option value={OrderByType.ByDateAsc}>{t`Date ascending order`}</option>}
                        </CSelect>
                    </div>}

                    {props.showAggregateBy &&
                    <div className="mb-2 ml-2">
                        <CSelect onChange={event => onChangeAggregateDateType((event.target as HTMLSelectElement).value as AggregateDateType)}
                                 name="aggregateDateType"
                                 innerRef={register()}
                                 defaultValue={reportsFilter.aggregateDateType}
                                 disabled={!props.areControlsEnabled}>
                            <option value={AggregateDateType.ByDay}>{t`Aggregate by day`}</option>
                            <option value={AggregateDateType.ByMonth}>{t`Aggregate by month`}</option>
                            <option value={AggregateDateType.ByYear}>{t`Aggregate by year`}</option>
                        </CSelect>
                    </div>}

                    {props.showSplitBy && props.showCustomers && <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setAggregateCustomersType((event.target as HTMLSelectElement).value as AggregateCustomersType)}
                                 defaultValue={reportsFilter.aggregateCustomersType}
                                 disabled={!props.areControlsEnabled}>
                            {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <option value={AggregateCustomersType.Split}>{t`Split by customer`}</option>}
                            <option value={AggregateCustomersType.SplitByVenue}>{t`Split by venue`}</option>
                            <option value={AggregateCustomersType.NotSplit}>{auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ? t`Don't split by customer` : t`Don't split by venue`}</option>
                        </CSelect>
                    </div>}

                    {props.showSplitBy && props.showProducts && <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setAggregateProductsType((event.target as HTMLSelectElement).value as AggregateProductsType)}
                                 defaultValue={reportsFilter.aggregateProductsType}
                                 disabled={!props.areControlsEnabled}>
                            <option value={AggregateProductsType.Split}>{t`Split by product`}</option>
                            <option value={AggregateProductsType.NotSplit}>{t`Don't split by product`}</option>
                        </CSelect>
                    </div>}

                    {props.showCompareBy && <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setCompareType((event.target as HTMLSelectElement).value as CompareType)}
                                 defaultValue={reportsFilter.compareType}
                                 disabled={!props.areControlsEnabled}>
                            <option value={CompareType.Submitted}>{t`Compare submitted`}</option>
                            <option value={CompareType.Approved}>{t`Compare approved`}</option>
                            <option value={CompareType.Original}>{t`Compare original`}</option>
                        </CSelect>
                    </div>}

                    {props.showCompareSumType && <div className="mb-2 ml-2">
                        <CSelect onChange={(event) => reportsFilter.setCompareSumType((event.target as HTMLSelectElement).value as CompareSumType)}
                                 defaultValue={reportsFilter.compareSumType}
                                 disabled={!props.areControlsEnabled}>
                            <option value={CompareSumType.Total}>{t`Compare total`}</option>
                            <option value={CompareSumType.Min}>{t`Compare min`}</option>
                            <option value={CompareSumType.Max}>{t`Compare max`}</option>
                        </CSelect>
                    </div>}
                </CRow>

                {props.showCustomers && auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly && reportsFilter.filterCustomers.length > 0 &&
                <CRow gutters={false} className="mb-3">
                    <strong>{t`Selected customers: `}</strong>

                    {reportsFilter.filterCustomers.map(customer =>
                            <CLink key={customer.id}
                                   onClick={() => reportsFilter.setFilter({filterCustomers: reportsFilter.filterCustomers.filter(customerFilter => customerFilter.id !== customer.id)})}
                                   disabled={!props.areControlsEnabled}
                                   title={t`Remove from filter`}
                                   className="ml-2 mb-2">
                                <CBadge color="info">
                                    {customer.name}
                                </CBadge>
                            </CLink>
                    )}
                </CRow>}

                {props.showCustomers && auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly && reportsFilter.filterCustomerTags.length > 0 &&
                <CRow gutters={false} className="mb-3">
                    <strong>{t`Selected customer tags: `}</strong>

                    {reportsFilter.filterCustomerTags.map(tag =>
                            <CLink key={tag}
                                   onClick={() => reportsFilter.setFilter({filterCustomerTags: reportsFilter.filterCustomerTags.filter(t => t !== tag)})}
                                   disabled={!props.areControlsEnabled}
                                   title={t`Remove from filter`}
                                   className="ml-2 mb-2">
                                <CBadge color="info">
                                    {tag}
                                </CBadge>
                            </CLink>
                    )}
                </CRow>}

                {props.showVenues && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly && reportsFilter.filterVenues.length > 0 &&
                    <CRow gutters={false} className="mb-3">
                        <strong>{t`Selected venues: `}</strong>

                        {reportsFilter.filterVenues.map(venue =>
                                <CLink key={venue.id}
                                       onClick={() => reportsFilter.setFilter({filterVenues: reportsFilter.filterVenues.filter(venueFilter => venueFilter.id != venue.id)})}
                                       disabled={!props.areControlsEnabled}
                                       title={t`Remove from filter`}
                                       className="ml-2 mb-2">
                                    <CBadge color="info">
                                        {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ? `${venue.customerByCustomerId?.name} / ${venue.country} ${venue.city} ${venue.location}` : `${venue.country} ${venue.city} ${venue.location}`}
                                    </CBadge>
                                </CLink>
                        )}
                    </CRow>}

                {props.showProducts && reportsFilter.filterIncludeUnknown !== IncludeUnknown.IncludeOnly && reportsFilter.filterProducts.length > 0 &&
                <CRow gutters={false} className="mb-3">
                    <strong>{t`Selected products: `}</strong>

                    {reportsFilter.filterProducts.map(product =>
                            <CLink key={product.id}
                                   onClick={() => reportsFilter.setFilter({filterProducts: reportsFilter.filterProducts.filter(productFilter => productFilter.id !== product.id)})}
                                   disabled={!props.areControlsEnabled}
                                   title={t`Remove from filter`}
                                   className="ml-2 mb-2">
                                <CBadge color="info">
                                    {product.name}
                                </CBadge>
                            </CLink>
                    )}
                </CRow>}
        </React.Fragment>
    );
});

export default ReportsFilter;
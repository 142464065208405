import React from "react";
import {supportedLocales} from "../utils/I18nUtils";

const Locales: React.FC = () => {
    let options = [];

    for (let key in supportedLocales) {
        const value = supportedLocales[key];
        options.push(<option key={key} value={key}>{value}</option>);
    }

    return (
        <React.Fragment>
            {options}
        </React.Fragment>
    );
};

export default Locales;
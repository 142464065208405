import {t} from "ttag";
import {StatusCodes} from "http-status-codes";
import {baseApiURI} from "../utils/ApiUtils";

interface ApiError {
    getMessage(): string;
}

class InternalServerError implements ApiError {
    private readonly statusCode: number;

    constructor(statusCode: number) {
        this.statusCode = statusCode;
    }

    getMessage(): string {
        return t`Unexpected internal server error: ${this.statusCode}! Please ask Another World support for further instructions.`;
    }
}

class UnknownError implements ApiError {
    private readonly error: any;

    constructor(error: any) {
        this.error = error;
    }

    getMessage(): string {
        let errorDesc = this.error ?
            ': ' + JSON.stringify(this.error) : "";
        errorDesc = errorDesc !== "{}" && errorDesc !== "[]" ?
            errorDesc : "";

        return t`Unknown error${errorDesc}! Please ask Another World support for further instructions.`;
    }
}

class NotAuthenticatedError implements ApiError {
    getMessage(): string {
        return t`Not authenticated!`;
    }
}

function wrapForApiErrors(promise: Promise<Response>, knownStatus: number[] = [StatusCodes.OK]): Promise<Response> {
    return promise.then((response: Response) => {
        if (knownStatus.includes(response.status)) {
            return response;
        } else {
            throw new InternalServerError(response.status);
        }
    }, (err) => {
        throw new UnknownError(err);
    });
}

abstract class AbstractApi {
    protected baseUrl(): string {
        return baseApiURI;
    }
}

export type {
    ApiError
};

export {
    InternalServerError,
    UnknownError,
    NotAuthenticatedError,
    wrapForApiErrors,
    AbstractApi
};
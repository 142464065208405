import React from "react";
import {CFooter} from "@coreui/react";
import {t} from "ttag";

const Footer:React.FC = () => {
    return (
        <CFooter fixed={false}>
            <div>
                <a href="https://another-world.com" target="_blank" rel="noopener noreferrer">{t`Another World`}</a>
                <span className="ml-1">&copy; {t`2020-2023 Another World.`}</span>
            </div>
        </CFooter>
    );
}

export default Footer;
import {
    CButton,
    CCol,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CLabel,
    CPopover,
    CRow,
    CSelect,
    CSpinner,
    CSwitch,
    CTextarea
} from "@coreui/react";
import React, {useContext} from "react";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import Error from "./Error";
import {
    LicenseSession,
    PriceDefinition,
    PriceVariantType,
    RoyaltyDefinition,
    RoyaltyVariantType,
    UserRoleType
} from "../generated/graphql";
import Currencies from "./Currencies";
import {observer} from "mobx-react-lite";
import PriceVariants from "./PriceVariants";
import RoyaltyVariants from "./RoyaltyVariants";
import {StoreContext} from "../context/StoreContext";
import {moneyToDisplayStr} from "../utils/MoneyUtils";

interface EditLicenseSessionProps {
    licenseSession: LicenseSession;
    loading?: boolean;
    error?: string;
    onSubmit: (
        price: PriceDefinition,
        isPaid: boolean,
        comment: string,
        royalty?: RoyaltyDefinition,
        isLocked?: boolean
    ) => void;
    onCancel?: () => void;
    isVertical?: boolean;
    hideCancel?: boolean;
}

interface LicenseSessionSettingsInput {
    price: string;
    priceVariant: PriceVariantType;
    ispaid: boolean;
    comment: string;
    priceCurrency?: string;
    royaltyVariant?: RoyaltyVariantType;
    royaltyCurrency?: string;
    royalty?: string;
    royaltyShare?: string;
    islocked?: boolean;
}

const EditLicenseSession: React.FC<EditLicenseSessionProps> = observer((props: EditLicenseSessionProps) => {
    const store = useContext(StoreContext);
    const {auth} = store;

    const {register, handleSubmit, errors, watch, getValues, setValue, reset} = useForm<LicenseSessionSettingsInput>({
        defaultValues: {
            priceCurrency: props.licenseSession.price.priceCurrency || "RUB",
            price: (props.licenseSession.price.price && moneyToDisplayStr(props.licenseSession.price.price)) || "0",
            priceVariant: props.licenseSession.price.priceVariant || PriceVariantType.PerSession,
            ispaid: props.licenseSession.isPaid,
            comment: "",
            royaltyVariant: props.licenseSession.royalty.royaltyVariant || RoyaltyVariantType.PerRevenueShare,
            royaltyCurrency: props.licenseSession.royalty.royaltyCurrency || props.licenseSession.price.priceCurrency || "RUB",
            royalty: (props.licenseSession.royalty.royalty && moneyToDisplayStr(props.licenseSession.royalty.royalty)) || "0",
            royaltyShare: (props.licenseSession.royalty.royaltyShare && (+props.licenseSession.royalty.royaltyShare * 100).toFixed(2)) || "0",
            islocked: props.licenseSession.isLocked
        }
    });

    const onSubmit = (data: LicenseSessionSettingsInput) => {
        props.onSubmit(
                {
                    price: data.price,
                    priceCurrency: !data.priceCurrency ?
                            props.licenseSession.price.priceCurrency : data.priceCurrency,
                    priceVariant: data.priceVariant
                },
                data.ispaid,
                data.comment,
                !data.royaltyVariant ? undefined : {
                    royalty: data.royalty || "0",
                    royaltyVariant: data.royaltyVariant,
                    royaltyCurrency: auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ?
                            data.royaltyCurrency : props.licenseSession.royalty.royaltyCurrency,
                    royaltyShare: (data.royaltyShare && (+data.royaltyShare / 100).toFixed(4)) || "0"
                },
                data.islocked
        );
        reset();
    };

    const onDiscard = () => {
        props.onSubmit(
                {
            price: props.licenseSession.priceApproved.price,
            priceCurrency: props.licenseSession.priceApproved.priceCurrency,
            priceVariant: props.licenseSession.priceApproved.priceVariant
            },
            props.licenseSession.isPaidApproved,
            getValues("comment")
        );
        reset();
    }

    const priceVariantBlock = <React.Fragment>
        <CLabel htmlFor="priceVariant">{t`Price type`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.priceVariant}}>
            <CSelect name="priceVariant"
                     innerRef={register({required: true})}
                     invalid={!!errors.priceVariant}>
                <PriceVariants/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const priceCurrencyBlock = auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <React.Fragment>
        <CLabel htmlFor="priceCurrency">{t`Price currency`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.priceCurrency}}>
            <CSelect name="priceCurrency"
                     innerRef={register({required: true})}
                     invalid={!!errors.priceCurrency}
                     onChange={() => setValue("royaltyCurrency", getValues("priceCurrency"))}>
                <Currencies/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const priceBlock = <React.Fragment>
        <CLabel htmlFor="price">{t`Price`}</CLabel>
        <CPopover content={t`Required, example 12345.67`} placement="right"
                  advancedOptions={{onShow: () => !!errors.price}}>
            <CInputGroup>
                <CInput name="price" type="text"
                        innerRef={register({required: true, pattern: /^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/g})}
                        invalid={!!errors.price}/>
                <CInputGroupAppend>
                    <CInputGroupText>{watch("priceCurrency") || props.licenseSession.price.priceCurrency}</CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const royaltyVariantBlock = auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <React.Fragment>
        <CLabel htmlFor="royaltyVariant">{t`Royalty type`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royaltyVariant}}>
            <CSelect name="royaltyVariant"
                     innerRef={register({required: true})}
                     invalid={!!errors.royaltyVariant}
                     onChange={() => getValues("royaltyVariant") !== RoyaltyVariantType.PerRevenueShare ? true : setValue("royaltyCurrency", getValues("priceCurrency"))}>
                <RoyaltyVariants/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const royaltyCurrencyBlock = auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <React.Fragment>
        <CLabel htmlFor="royaltyCurrency">{t`Royalty currency`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royaltyCurrency}}>
            <CSelect name="royaltyCurrency"
                     innerRef={register({required: true})}
                     invalid={!!errors.royaltyCurrency}>
                <Currencies/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const royaltyBlock = auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && watch("royaltyVariant") !== RoyaltyVariantType.PerRevenueShare && <React.Fragment>
        <CLabel htmlFor="royalty">{t`Royalty`}</CLabel>
        <CPopover content={t`Required, example 12345.67`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royalty}}>
            <CInputGroup>
                <CInput name="royalty" type="text"
                        innerRef={register({required: true, pattern: /^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/})}
                        invalid={!!errors.royalty}/>
                <CInputGroupAppend>
                    <CInputGroupText>{watch("royaltyCurrency")}</CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const royaltyShareBlock = auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && watch("royaltyVariant") === RoyaltyVariantType.PerRevenueShare && <React.Fragment>
        <CLabel htmlFor="royaltyShare">{t`Royalty (%)`}</CLabel>
        <CPopover content={t`Required, example 15.65`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royaltyShare}}>
            <CInputGroup>
                <CInput name="royaltyShare" type="text"
                        innerRef={register({required: true, pattern: /^(([1-9][0-9]?)|0)([.][0-9]{1,2})?$/})}
                        invalid={!!errors.royaltyShare}/>
                <CInputGroupAppend>
                    <CInputGroupText>%</CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const isPaidBlock = <React.Fragment>
        <CLabel htmlFor="ispaid" style={{width: "100%"}}>{t`Paid`}</CLabel>
        <CSwitch name="ispaid"
                 innerRef={register()}
                 shape="pill"
                 color="success"/>
    </React.Fragment>;

    const commentBlock = <React.Fragment>
        <CLabel htmlFor="comment">{t`Comment`}</CLabel>
        <CPopover content={t`Max 500 characters`} placement="right"
                  advancedOptions={{onShow: () => !!errors.comment}}>
            <CInputGroup>
                <CTextarea name="comment" type="text"
                           rows={6}
                           innerRef={register({max: 500})}
                           invalid={!!errors.comment}/>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const isLockedBlock = auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <React.Fragment>
        <CLabel htmlFor="islocked" style={{width: "100%"}}>{t`Locked changes`}</CLabel>
        <CSwitch name="islocked"
                 innerRef={register()}
                 shape="pill"
                 color="secondary"/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit" disabled={!!props.loading}>
        {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ? (props.licenseSession.isApproved ? t`Save` : t`Approve`) : t`Submit`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const discardBlock = !props.licenseSession.isApproved && <CButton color="dark" className="px-4 ml-2" onClick={() => onDiscard()} disabled={!!props.loading}>
        {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ? t`Discard` : t`Reset`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = !props.hideCancel &&
            <CButton color="secondary" className="px-4 ml-2" type="reset" onClick={props.onCancel}>{jt`Cancel`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        <CRow>
            {priceVariantBlock}
        </CRow>

        {priceCurrencyBlock && <CRow>
            {priceCurrencyBlock}
        </CRow>}

        <CRow>
            {priceBlock}
        </CRow>

        {royaltyVariantBlock && <CRow>
            {royaltyVariantBlock}
        </CRow>}

        {royaltyCurrencyBlock && <CRow className={{"d-none": watch("royaltyVariant") === RoyaltyVariantType.PerRevenueShare}}>
            {royaltyCurrencyBlock}
        </CRow>}

        {royaltyShareBlock && <CRow>
            {royaltyShareBlock}
        </CRow>}

        {royaltyBlock && <CRow>
            {royaltyBlock}
        </CRow>}

        <CRow>
            {isPaidBlock}
        </CRow>

        <CRow>
            {commentBlock}
        </CRow>

        {isLockedBlock && <CRow>
            {isLockedBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {discardBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            {priceVariantBlock && <CCol>
                {priceVariantBlock}
            </CCol>}

            {priceCurrencyBlock && <CCol>
                {priceCurrencyBlock}
            </CCol>}

            <CCol>
                {priceBlock}
            </CCol>

            {royaltyVariantBlock && <CCol>
                {royaltyVariantBlock}
            </CCol>}

            {royaltyCurrencyBlock && <CCol className={{"d-none": watch("royaltyVariant") === RoyaltyVariantType.PerRevenueShare}}>
                {royaltyCurrencyBlock}
            </CCol>}

            {royaltyShareBlock && <CCol>
                {royaltyShareBlock}
            </CCol>}

            {royaltyBlock && <CCol>
                {royaltyBlock}
            </CCol>}

            <CCol>
                {isPaidBlock}
            </CCol>

            <CCol>
                {commentBlock}
            </CCol>

            {isLockedBlock && <CCol>
                {isLockedBlock}
            </CCol>}

            <CCol>
                {submitBlock}

                {discardBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            {elements}

            {props.error && <CRow><Error>{props.error}</Error></CRow>}
        </CForm>
    );
});

export default EditLicenseSession;
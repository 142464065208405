import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {Customer} from "../../generated/graphql";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol, CDataTable,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CLink,
    CRow
} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import {DateAndTime} from "../DateAndTime";
import Pagination from "../Pagination";
import EditCustomer from "../EditCustomer";
import Error from "../Error";

const getStatusBadgeColor = (isArchived: boolean) => isArchived ? "secondary" : "success";

const fields = [
    {key: "name"},
    {key: "tags", _style: {width: "10%"}},
    {key: "isArchived", _style: {width: "7%"}},
    {key: "createdAtUtc", _style: {width: "15%"}},
    {
        key: "edit",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const CustomersView = observer(() => {
    const store = useContext(StoreContext);
    const {customers, editCustomer, newCustomer} = store;

    const toggleEdit = (customer: Customer) => {
        if (editCustomer.customer?.id === customer.id) {
            editCustomer.setCustomer(undefined);
        } else {
            editCustomer.setCustomer(customer);
        }
    };

    const saveCustomer = (name: string, tags: string[], isArchived?: boolean) => {
        editCustomer.save(name, !!isArchived, tags);
    };

    const createCustomer = (name: string, tags: string[]) => {
        newCustomer.create(name, tags);
    };

    const filter = (str: string) => {
        customers.setFilter(!str ? undefined : {
            or: [
                {
                    name: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    tags: {
                        anyEqualTo: str
                    }
                }
            ]
        });
    };

    const refresh = () => {
        customers.fetchCustomers();
    };

    return (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`Customers`}

                        <CLink className="ml-1"
                               title={t`Refresh`}
                               onClick={() => refresh()}
                               disabled={customers.loading}>
                            <CIcon name="cil-sync" className="mfe-2" />
                        </CLink>
                    </CCardHeader>

                    <CCardBody>
                        <CDropdown>
                            <CDropdownToggle color="primary">
                                {t`New customer`}
                            </CDropdownToggle>

                            <CDropdownMenu>
                                <div className="px-4 py-3">
                                    <EditCustomer onSubmit={createCustomer}
                                                  onCancel={() => newCustomer.reset()}
                                                  loading={newCustomer.loading}
                                                  error={newCustomer.error}
                                                  isVertical={true}
                                                  hideCancel={true}/>
                                </div>
                            </CDropdownMenu>
                        </CDropdown>

                        <CDataTable
                                noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                items={(customers.customers && customers.customers.length > 0 && customers.customers) || []}
                                loading={customers.loading}
                                fields={fields}
                                border={true}
                                tableFilter={{external: true, label: t`Filter:`, placeholder: t`type name or tag`}}
                                onTableFilterChange={filter}
                                columnHeaderSlot={{
                                    name: t`Customer`,
                                    tags: t`Tags`,
                                    isArchived: t`Status`,
                                    createdAtUtc: t`Registered`
                                }}
                                scopedSlots={{
                                    isArchived: (customer: Customer) => (
                                            <td>
                                                <CBadge color={getStatusBadgeColor(customer.isArchived)}>
                                                    {customer.isArchived ? t`archived` : t`active`}
                                                </CBadge>
                                            </td>
                                    ),
                                    tags: (customer: Customer) => (
                                            <td>
                                                {customer.tags && customer.tags.map(tag =>
                                                    <CBadge key={tag} color="info" className="mr-2">{tag}</CBadge>
                                                )}
                                            </td>
                                    ),
                                    createdAtUtc: (customer: Customer) => (
                                            <td>
                                                <DateAndTime date={new Date(customer.createdAtUtc)}/>
                                            </td>
                                    ),
                                    edit: (customer: Customer) =>
                                            <Observer>{() =>
                                                    <td className="py-2">
                                                        <CLink onClick={() => toggleEdit(customer)} disabled={editCustomer.loading}>
                                                            <CIcon name="cil-pencil" className="mfe-2" />
                                                        </CLink>
                                                    </td>
                                            }</Observer>,
                                    details: (customer: Customer) =>
                                            <Observer key={customer.id}>{() => {
                                                return (
                                                        <React.Fragment>
                                                            {editCustomer.customer?.id === customer.id && <CCardBody>
                                                                <EditCustomer onSubmit={saveCustomer}
                                                                              onCancel={() => editCustomer.reset()}
                                                                              customer={customer}
                                                                              loading={editCustomer.loading}
                                                                              error={editCustomer.error}/>
                                                            </CCardBody>}
                                                        </React.Fragment>
                                                );
                                            }}</Observer>
                                }}
                                underTableSlot={customers.error ? <Error>{customers.error}</Error> : <Pagination loading={customers.loading}
                                                            pageInfo={customers.pageInfo}
                                                            onPage={(page) => customers.setPage(page)}/>}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
});

export default CustomersView;
import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Mutation} from "../generated/graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import CurrentUserFragment from "../api/graphql/CurrentUserFragment";
import {ExecutionResult} from "graphql";

interface CurrentUserSettingsInput {
    newusername: string;
    locale: string;
    timezone: string;
    newpassword: string;
    passwordcopy: string;
}

class CurrentUserSettingsStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async save(input: CurrentUserSettingsInput) {
        this.loading = true;
        this.error = undefined;

        try {
            const result = await this.saveGeneralSettings(input);
            this.rootStore.auth.setCurrentUser(result.data?.updateUserById?.user);

            if (input.newpassword) {
                await this.resetPassword(input);
            }
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }

    private resetPassword(input: CurrentUserSettingsInput): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            password: string
        }>({
            mutation: gql`mutation ($password: String!) {
                resetSelfPassword(input: {accountPassword: $password}) {
                    clientMutationId
                }
            }`,
            variables: {
                password: input.newpassword
            }
        }));
    }

    private saveGeneralSettings(input: CurrentUserSettingsInput): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            userId: string,
            locale: string,
            userName: string,
            timezone: string
        }>({
            mutation: gql`mutation ($userId: UUID!, $locale: LocaleType!, $userName: NameType!, $timezone: String!) {
                updateUserById(input: {userPatch: {locale: $locale, name: $userName, timezone: $timezone}, id: $userId}) {
                    user {
                        ...CurrentUserFragment
                    }
                }
            },
            ${CurrentUserFragment}`,
            variables: {
                userId: this.rootStore.auth.currentUser?.id,
                locale: input.locale,
                userName: input.newusername,
                timezone: input.timezone
            }
        }));
    }
}

export type {
    CurrentUserSettingsInput
};

export {
    CurrentUserSettingsStore
};
import {observer} from "mobx-react-lite";
import {Product} from "../generated/graphql";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {CDropdown, CDropdownMenu, CDropdownToggle, CWidgetDropdown, CRow} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import EditProduct from "./EditProduct";

const ProductWidget = observer((props: {product: Product}) => {
    const store = useContext(StoreContext);
    const {editProduct} = store;

    const toggleEdit = () => {
        editProduct.setProduct(props.product);
    };

    const saveProduct = (names: {[locale: string]: string},
                         imgBase64?: string,
                         isGame?: boolean,
                         isArchived?: boolean) => {
        editProduct.save(names, !!isGame, !!isArchived, imgBase64);
    };

    return (
            <CWidgetDropdown header={props.product.name || ""}
                             color={props.product.isArchived ? "secondary" : "gradient-dark"}
                             footerSlot={
                                 <div className="px-3 py-2">
                                     {props.product.imgBase64 && <img height="200"
                                                                      style={{
                                                                          maxWidth: "100%",
                                                                          objectFit: "cover",
                                                                          objectPosition: "center"
                                                                      }}
                                                                      alt={t`Product`}
                                                                      src={props.product.imgBase64}/>}

                                     <CRow gutters={true} className="px-3 py-3">Id: {props.product.id}</CRow>
                                 </div>
                             }>
                <CDropdown>
                    <CDropdownToggle color="transparent" onClick={toggleEdit}>
                        <CIcon name="cil-pencil"/>
                    </CDropdownToggle>

                    <CDropdownMenu>
                        <div className="px-4 py-3">
                            <EditProduct onSubmit={saveProduct}
                                         onCancel={() => editProduct.reset()}
                                         loading={editProduct.loading}
                                         error={editProduct.error}
                                         product={props.product}
                                         isVertical={true}
                                         hideCancel={true}/>
                        </div>
                    </CDropdownMenu>
                </CDropdown>
            </CWidgetDropdown>
    );
});

export default ProductWidget;
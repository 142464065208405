import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {Product} from "../generated/graphql";

interface ProductsProps {
    filter?: (product: Product) => boolean;
}

const Products = observer((props: ProductsProps) => {
    const store = useContext(StoreContext);
    const {products} = store;

    const options = products.activeProducts
        .filter(product => !props.filter || props.filter(product))
        .map(product =>
            <option key={product.id} value={product.id}>{product.name}</option>
        );

    return (
            <React.Fragment>
                {options}
            </React.Fragment>
    );
});

export default Products;
import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {
    License,
    PriceCalendarDefinition,
    PriceDefinition,
    RoyaltyDefinition
} from "../../generated/graphql";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CLink,
    CRow
} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import {DateAndTime} from "../DateAndTime";
import Pagination from "../Pagination";
import EditLicense from "../EditLicense";
import Royalty from "../Royalty";
import Price from "../Price";
import Error from "../Error";
import PriceCalendar from "../PriceCalendar";

const getLicenseStatusBadgeColor = (isRevoked: boolean, isExpired: boolean) => isRevoked ? "secondary" : (isExpired ? "danger" : "success");

const fields = [
    "venue",
    "product",
    "licenseKey",
    "price",
    "royalty",
    {key: "status", _style: {width: "7%"}},
    {key: "expirateAtUtc", _style: {width: "5%"}},
    {key: "createdAtUtc", _style: {width: "5%"}},
    {
        key: "edit",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const LicensesView = observer(() => {
    const store = useContext(StoreContext);
    const {licenses, editLicense, newLicense} = store;

    const toggleEdit = (license: License) => {
        if (editLicense.license?.id === license.id) {
            editLicense.setLicense(undefined);
        } else {
            editLicense.setLicense(license);
        }
    };

    const saveLicense = (price: PriceDefinition,
                         royalty: RoyaltyDefinition,
                         priceCalendar?: PriceCalendarDefinition[],
                         venueId?: string,
                         productId?: string,
                         licenseKey?: string,
                         expirateAtUtc?: string,
                         isRevoked?: boolean) => {
        editLicense.save(!!isRevoked, price, royalty, priceCalendar);
    };

    const createLicense = (price: PriceDefinition,
                           royalty: RoyaltyDefinition,
                           priceCalendar?: PriceCalendarDefinition[],
                           venueId?: string,
                           productId?: string,
                           licenseKey?: string,
                           expirateAtUtc?: string,
                           isRevoked?: boolean) => {
        if (venueId && productId && licenseKey && expirateAtUtc) {
            newLicense.create(productId, venueId, licenseKey, expirateAtUtc, price, royalty, priceCalendar);
        }
    };

    const filter = (str: string) => {
        licenses.setFilter(!str ? undefined : {
            or: [
                {
                    licenseKey: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    venueByVenueId: {
                        country: {
                            likeInsensitive: `%${str}%`
                        }
                    }
                },
                {
                    venueByVenueId: {
                        city: {
                            likeInsensitive: `%${str}%`
                        }
                    }
                },
                {
                    venueByVenueId: {
                        location: {
                            likeInsensitive: `%${str}%`
                        }
                    }
                },
                {
                    productByProductId: {
                        name: {
                            likeInsensitive: `%${str}%`
                        }
                    }
                },
                {
                    venueByVenueId: {
                        customerByCustomerId: {
                            name: {
                                likeInsensitive: `%${str}%`
                            }
                        }
                    }
                }
            ]
        });
    };

    const refresh = () => {
        licenses.fetchLicenses();
    };

    return (
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            {t`Licenses (obsolete)`}

                            <CLink className="ml-1"
                                   title={t`Refresh`}
                                   onClick={() => refresh()}
                                   disabled={licenses.loading}>
                                <CIcon name="cil-sync" className="mfe-2" />
                            </CLink>
                        </CCardHeader>

                        <CCardBody>
                            <CDropdown>
                                <CDropdownToggle color="primary">
                                    {t`New license`}
                                </CDropdownToggle>

                                <CDropdownMenu>
                                    <div className="px-4 py-3">
                                        <EditLicense onSubmit={createLicense}
                                                     onCancel={() => newLicense.reset()}
                                                     loading={newLicense.loading}
                                                     error={newLicense.error}
                                                     isVertical={true}
                                                     hideCancel={true}/>
                                    </div>
                                </CDropdownMenu>
                            </CDropdown>

                            <CDataTable
                                    noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                    items={(licenses.licenses && licenses.licenses.length > 0 && licenses.licenses) || []}
                                    loading={licenses.loading}
                                    fields={fields}
                                    border={true}
                                    tableFilter={{external: true, label: t`Filter:`, placeholder: t`type here...`}}
                                    onTableFilterChange={filter}
                                    columnHeaderSlot={{
                                        venue: t`Customer/Venue`,
                                        product: t`Product`,
                                        licenseKey: t`License key`,
                                        price: t`Price`,
                                        royalty: t`Royalty`,
                                        status: t`Status`,
                                        expirateAtUtc: t`Expiration date`,
                                        createdAtUtc: t`Registered`
                                    }}
                                    scopedSlots={{
                                        venue: (license: License) => {
                                            const isArchived = !!license.venueByVenueId?.isArchived || !!license.venueByVenueId?.customerByCustomerId?.isArchived;
                                            return (
                                                <td>
                                                    {license.venueByVenueId?.customerByCustomerId?.name}
                                                    /
                                                    {license.venueByVenueId?.country} {license.venueByVenueId?.city} {license.venueByVenueId?.location}
                                                    {isArchived && <CBadge color="secondary">
                                                        {t`archived`}
                                                    </CBadge>}
                                                </td>
                                            );
                                        },
                                        product: (license: License) => {
                                            const isArchived = !!license.productByProductId?.isArchived;
                                            return (
                                                <td>
                                                    {license.productByProductId?.name}
                                                    {isArchived && <CBadge color="secondary">
                                                        {t`archived`}
                                                    </CBadge>}
                                                </td>
                                            );
                                        },
                                        price: (license: License) => (
                                            <td>
                                                <Price price={license.price}/>

                                                {license.priceCalendar && license.priceCalendar.length > 0 &&
                                                    <PriceCalendar priceCalendar={license.priceCalendar}
                                                                   priceCurrency={license.price.priceCurrency}/>}
                                            </td>
                                        ),
                                        royalty: (license: License) => (
                                            <td>
                                                <Royalty royalty={license.royalty}/>
                                            </td>
                                        ),
                                        status: (license: License) => {
                                            const isRevoked = license.isRevoked;
                                            const isExpired = new Date(Date.now()) > new Date(license.expirateAtUtc);
                                            return (
                                                <td>
                                                    <CBadge color={getLicenseStatusBadgeColor(isRevoked, isExpired)}>
                                                        {isRevoked ? t`revoked` : (isExpired ? t`expired` : t`active`)}
                                                    </CBadge>
                                                </td>
                                            );
                                        },
                                        expirateAtUtc: (license: License) => (
                                                <td>
                                                    <DateAndTime date={new Date(license.expirateAtUtc)} format={"YYYY/MM"}/>
                                                </td>
                                        ),
                                        createdAtUtc: (license: License) => (
                                            <td>
                                                <DateAndTime date={new Date(license.createdAtUtc)}/>
                                            </td>
                                        ),
                                        edit: (license: License) =>
                                            <Observer>{() =>
                                                    <td className="py-2">
                                                        <CLink onClick={() => toggleEdit(license)} disabled={licenses.loading}>
                                                            <CIcon name="cil-pencil" className="mfe-2" />
                                                        </CLink>
                                                    </td>
                                            }</Observer>,
                                        details: (license: License) =>
                                                <Observer key={license.id}>{() => {
                                                    return (
                                                        <React.Fragment>
                                                            {editLicense.license?.id === license.id && <CCardBody>
                                                                    <EditLicense onSubmit={saveLicense}
                                                                                 onCancel={() => editLicense.reset()}
                                                                                 license={license}
                                                                                 loading={editLicense.loading}
                                                                                 error={editLicense.error}/>
                                                                </CCardBody>}
                                                        </React.Fragment>
                                                    );
                                                }}</Observer>
                                    }}
                                    underTableSlot={licenses.error ? <Error>{licenses.error}</Error> : <Pagination loading={licenses.loading}
                                                                pageInfo={licenses.pageInfo}
                                                                onPage={(page) => licenses.setPage(page)}/>}/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
    );
});

export default LicensesView;
import {action, computed, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Maybe, Product, ProductFilter, Query, UserRoleType} from "../generated/graphql";
import {
    apolloClient,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import ProductFragment from "../api/graphql/ProductFragment";
import ProductFullFragment from "../api/graphql/ProductFullFragment";

class ProductsStore {
    @observable
    public archivedProductsShow: boolean = false;

    @observable.ref
    public products: Maybe<Product>[] | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin,
            (shouldFetch) => {
                if (shouldFetch) {
                    this.fetchProducts(false);
                }
            }
        );
    }

    @computed
    public get activeProducts(): Product[] {
        return (this.products ? this.products : [])
            .filter(product => product && !product.isArchived)
            .map(product => product as Product);
    }

    @action
    public fetchProducts(full: boolean) {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query, {
            filter: ProductFilter | undefined
        }>({
            query: full ? gql`query {
                currentUserId,
                allProducts(orderBy: [IS_ARCHIVED_ASC, CREATED_AT_UTC_DESC]) {
                    nodes {
                        ...ProductFullFragment
                    }
                }
            }
            ${ProductFullFragment}` : gql`query {
                currentUserId,
                allProducts(orderBy: [IS_ARCHIVED_ASC, CREATED_AT_UTC_DESC]) {
                    nodes {
                        ...ProductFragment
                    }
                }
            }
            ${ProductFragment}`
        })).then(result => {
            runInAction(() => {
                this.products = result.data.allProducts?.nodes && [...result.data.allProducts?.nodes].sort((p1, p2) => {
                    const n1 = p1?.name?.toLowerCase();
                    const n2 = p2?.name?.toLowerCase();

                    if (n1 === undefined && n2 !== undefined) {
                        return -1;
                    } else if (n1 !== undefined && n2 === undefined) {
                        return 1;
                    } else if (n1 !== undefined && n2 !== undefined) {
                        return n1.localeCompare(n2);
                    } else {
                        return 0;
                    }
                }) || result.data.allProducts?.nodes;
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public toggleArchivedProductsShow() {
        this.archivedProductsShow = !this.archivedProductsShow;
    }

    @action
    public reset() {
        this.products = undefined;
        this.loading = false;
        this.error = undefined;
    }

    public static getImage(products: Maybe<Product>[] | undefined, productId: string): string | undefined {
        if (!products) {
            return undefined;
        }
        return products.find(product => product?.id === productId)?.imgBase64 || undefined;
    }
}

export {
    ProductsStore
};
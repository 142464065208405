const dateFormatter = require('date-and-time');

function beginOfTheMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

function endOfTheMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function beginOfTheYear(date: Date): Date {
    return new Date(date.getFullYear(), 0, 1);
}

function endOfTheYear(date: Date): Date {
    return new Date(date.getFullYear(), 12, 0);
}

function addYears(date: Date, years: number): Date {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
}

function lastDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
}

function toISODate(date: Date | null): string | null {
    if (!date) {
        return null;
    }

    return dateFormatter.format(date, "YYYY-MM-DD");
}

function getYearSeries(start: Date, end: Date): {year: string}[] {
    let currentYear = start.getFullYear();

    const dateSeries: {year: string}[] = [];

    while (currentYear <= end.getFullYear()) {
        dateSeries.push({
            year: currentYear.toString()
        });
        currentYear++;
    }

    return dateSeries;
}

function getMonthSeries(start: Date, end: Date): {year: string, month: string}[] {
    let currentYear = start.getFullYear();
    let currentMonth = start.getMonth();

    const dateSeries: {year: string, month: string}[] = [];

    const endFullYear = end.getFullYear();
    const endMonth = end.getMonth();

    while (currentYear < endFullYear || currentYear === endFullYear && currentMonth <= endMonth) {
        dateSeries.push({
            year: currentYear.toString(),
            month: (currentMonth+1).toString()
        });

        if (currentMonth >= 11) {
            currentMonth = 0;
            currentYear++;
        } else {
            currentMonth++;
        }
    }

    return dateSeries;
}

function getDateSeries(start: Date, end: Date): string[] {
    let currentDate = new Date(start);

    const dateSeries: string[] = [];

    while (currentDate <= end) {
        const isoDate = toISODate(currentDate)
        if (isoDate) {
            dateSeries.push(isoDate);
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateSeries;
}

export {
    beginOfTheMonth,
    endOfTheMonth,
    beginOfTheYear,
    endOfTheYear,
    addYears,
    lastDay,
    toISODate,
    getYearSeries,
    getMonthSeries,
    getDateSeries
}
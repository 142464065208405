import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {LicenseV2Claim, LicenseV2StringClaimValue} from "../api/LicenseV2Claims";
import {CRow, CSelect} from "@coreui/react";
import {getLicenseV2ClaimIdName} from "../utils/LicenseV2ClaimUtils";
import {StoreContext} from "../context/StoreContext";

interface EditLicenseV2AllowProductsClaimProps {
    editClaim: LicenseV2Claim;
    onSetEditClaimValue: (value: LicenseV2StringClaimValue) => void;
}

const EditLicenseV2AllowProductsClaim = observer((props: EditLicenseV2AllowProductsClaimProps) => {
    const store = useContext(StoreContext);
    const {products} = store;

    const allowProductsValue = props.editClaim.value as LicenseV2StringClaimValue;

    if (!allowProductsValue) {
        return null;
    }

    const options = products.activeProducts
        .map(product =>
            <option key={product.id} value={product.id}>{product.name}</option>
        );

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{getLicenseV2ClaimIdName(props.editClaim.id)}</strong>
            </CRow>

            <CRow gutters={false}>
                <CSelect multiple={true}
                         sizeHtml={options.length}
                         value={allowProductsValue.exact}
                         onChange={(event) => props.onSetEditClaimValue({
                             exact: Array.from((event.target as HTMLSelectElement).selectedOptions).map(element => element.value)
                         } as LicenseV2StringClaimValue)}>
                    {options}
                </CSelect>
            </CRow>
        </React.Fragment>
    );
});

export default EditLicenseV2AllowProductsClaim;
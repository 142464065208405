import React, {useContext} from "react"
import {observer} from "mobx-react-lite";
import {CCol, CRow, CSelect, CSpinner, CWidgetDropdown} from "@coreui/react";
import {CChartLine} from "@coreui/react-chartjs";
import {StoreContext} from "../../context/StoreContext";
import {t} from "ttag";
import {computed} from "mobx";
import {moneyToDisplayStr} from "../../utils/MoneyUtils";
import Error from "../Error";
import {UserRoleType} from "../../generated/graphql";
import {DashboardViewType} from "../../stores/DashboardStore";

const defaultChartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    scales: {
        xAxes: [{
            ticks: {
                fontColor: "white",
                fontSize: 11
            },
            gridLines: {
                drawOnChartArea: true,
                color: 'white',
                zeroLineColor: 'white',
                lineWidth: 0.4,
                drawBorder: false
            }
        }],
        yAxes: [{
            ticks: {
                fontColor: "white",
                fontSize: 11,
                beginAtZero: true,
                maxTicksLimit: 5
            },
            gridLines: {
                display: true,
                color: 'white',
                zeroLineColor: 'white',
                lineWidth: 0.4,
                drawBorder: false
            }
        }]
    },
    elements: {
        point: {
            radius: 2,
            hitRadius: 4,
            hoverRadius: 4,
            hoverBorderWidth: 4
        },
        line: {
            tension: 0.00001
        }
    },
    tooltips : {
        position: "nearest"
    }
};

const DashboardView = observer(() => {
    const store = useContext(StoreContext);
    const {licenseSessionsAgg, auth, dashboard} = store;

    const totalToday = (array: any[]) => {
        let result = 0;

        for (let dataset of array) {
            if (dataset.data.length > 0) {
                result += dataset.data[dataset.data.length - 1];
            }
        }

        return result;
    }

    const totalMonth = (array: any[]) => {
        let result = 0;

        for (let dataset of array) {
            for (let data of dataset.data) {
                result += data;
            }
        }

        return result;
    }

    const totalSessionsToday = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalToday(licenseSessionsAgg.aggTotalSessionsDatasets)).get() : undefined;
    const totalSessionsMonth = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalMonth(licenseSessionsAgg.aggTotalSessionsDatasets)).get() : undefined;

    const totalPlayersToday = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalToday(licenseSessionsAgg.aggPlayersDatasets)).get() : undefined;
    const totalPlayersMonth = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalMonth(licenseSessionsAgg.aggPlayersDatasets)).get() : undefined;

    const totalDurationToday = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalToday(licenseSessionsAgg.aggDurationDatasets).toFixed(2)).get() : undefined;
    const totalDurationMonth = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalMonth(licenseSessionsAgg.aggDurationDatasets).toFixed(2)).get() : undefined;

    const totalPriceUSDToday = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalToday(licenseSessionsAgg.aggTotalUSDPriceDataset).toFixed(2)).get() : undefined;
    const totalPriceUSDMonth = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalMonth(licenseSessionsAgg.aggTotalUSDPriceDataset).toFixed(2)).get() : undefined;

    const totalRoyaltyUSDToday = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalToday(licenseSessionsAgg.aggTotalUSDRoyaltyDataset).toFixed(2)).get() : undefined;
    const totalRoyaltyUSDMonth = dashboard.view === DashboardViewType.CurrentMonth ?
            computed(() => totalMonth(licenseSessionsAgg.aggTotalUSDRoyaltyDataset).toFixed(2)).get() : undefined;

    return licenseSessionsAgg.loading || licenseSessionsAgg.error ? (
            licenseSessionsAgg.error ?
                    <Error>{licenseSessionsAgg.error}</Error> :
                    <CSpinner color="primary" style={{width:'4rem', height:'4rem'}}/>
    ) : (
    <React.Fragment>
        <CRow>
            <CCol className="col-sm-auto mb-4">
                <CSelect name="dashboardView" value={dashboard.view} onChange={event => dashboard.setView((event.target as HTMLSelectElement).value as DashboardViewType)}>
                    <option value={DashboardViewType.CurrentMonth}>{t`Current month`}</option>
                    <option value={DashboardViewType.TwoLastYearsByMonth}>{t`Two last years group by month`}</option>
                </CSelect>
            </CCol>
        </CRow>

        <CRow>
            {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <CCol xs="12" sm="6" lg="6" xl="6" xxl="4">
                <CWidgetDropdown
                        color="gradient-primary"
                        header={t`Active customers`}
                        footerSlot={
                            <CChartLine
                                    className="mt-3 mx-3"
                                    style={{height: dashboard.view === DashboardViewType.CurrentMonth ? '130px' : '110px'}}
                                    datasets={licenseSessionsAgg.aggTotalActiveCustomersDatasets}
                                    options={defaultChartOptions}
                                    labels={licenseSessionsAgg.aggDateLabels}/>
                        }>
                </CWidgetDropdown>
            </CCol>}

            <CCol xs="12" sm="6" lg="6" xl="6" xxl="4">
                <CWidgetDropdown
                        color="gradient-primary"
                        header={t`Sessions`}
                        text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalSessionsToday}, Month: ${totalSessionsMonth}` : undefined}
                        footerSlot={
                            <CChartLine
                                    className="mt-3 mx-3"
                                    style={{height: '110px'}}
                                    datasets={licenseSessionsAgg.aggTotalSessionsDatasets}
                                    options={defaultChartOptions}
                                    labels={licenseSessionsAgg.aggDateLabels}/>
                        }>
                </CWidgetDropdown>
            </CCol>

            <CCol xs="12" sm="6" lg="6" xl="6" xxl="4">
                <CWidgetDropdown
                        color="gradient-primary"
                        header={t`Players`}
                        text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalPlayersToday}, Month: ${totalPlayersMonth}` : undefined}
                        footerSlot={
                            <CChartLine
                                    className="mt-3 mx-3"
                                    style={{height: '110px'}}
                                    datasets={licenseSessionsAgg.aggPlayersDatasets}
                                    options={defaultChartOptions}
                                    labels={licenseSessionsAgg.aggDateLabels}/>
                        }>
                </CWidgetDropdown>
            </CCol>

            <CCol xs="12" sm="6" lg="6" xl="6" xxl="4">
                <CWidgetDropdown
                        color="gradient-primary"
                        header={t`Duration (min)`}
                        text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalDurationToday}min, Month: ${totalDurationMonth}min` : undefined}
                        footerSlot={
                            <CChartLine
                                    className="mt-3 mx-3"
                                    style={{height: '110px'}}
                                    datasets={licenseSessionsAgg.aggDurationDatasets}
                                    options={defaultChartOptions}
                                    labels={licenseSessionsAgg.aggDateLabels}/>
                        }>
                </CWidgetDropdown>
            </CCol>
        </CRow>

        <CRow>
            <CCol xs="12" sm="6" lg="6" xl="6" xxl="4">
                <CWidgetDropdown
                        color="gradient-success"
                        header={t`Total revenue USD (converted)`}
                        text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalPriceUSDToday}, Month: ${totalPriceUSDMonth}` : undefined}
                        footerSlot={
                            <CChartLine
                                    className="mt-3 mx-3"
                                    style={{height: '110px'}}
                                    datasets={licenseSessionsAgg.aggTotalUSDPriceDataset}
                                    options={defaultChartOptions}
                                    labels={licenseSessionsAgg.aggDateLabels}/>
                        }>
                </CWidgetDropdown>
            </CCol>

            {licenseSessionsAgg.aggPriceCurrencies.filter(currency => licenseSessionsAgg.aggPriceDatasets[currency].length > 0).map(currency => {
                const aggPriceDatasets = licenseSessionsAgg.aggPriceDatasets[currency];

                const totalPriceToday = dashboard.view === DashboardViewType.CurrentMonth ? computed(() =>
                    moneyToDisplayStr(totalToday(aggPriceDatasets).toString()) + currency
                ) : undefined;

                const totalPriceMonth = dashboard.view === DashboardViewType.CurrentMonth ? computed(() =>
                    moneyToDisplayStr(totalMonth(aggPriceDatasets).toString()) + currency
                ) : undefined;

                return (
                        <CCol key={currency} xs="12" sm="6" lg="6" xl="6" xxl="4">
                            <CWidgetDropdown
                                    color="gradient-success"
                                    header={t`Revenue ${currency}`}
                                    text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalPriceToday}, Month: ${totalPriceMonth}` : undefined}
                                    footerSlot={
                                        <CChartLine
                                                className="mt-3 mx-3"
                                                style={{height: '110px'}}
                                                datasets={aggPriceDatasets}
                                                options={defaultChartOptions}
                                                labels={licenseSessionsAgg.aggDateLabels}/>
                                    }>
                            </CWidgetDropdown>
                        </CCol>
                );
            })}
        </CRow>

        <CRow>
            <CCol xs="12" sm="6" lg="6" xl="6" xxl="4">
                <CWidgetDropdown
                        color="gradient-warning"
                        header={t`Total royalty USD (converted)`}
                        text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalRoyaltyUSDToday}, Month: ${totalRoyaltyUSDMonth}` : undefined}
                        footerSlot={
                            <CChartLine
                                    className="mt-3 mx-3"
                                    style={{height: '110px'}}
                                    datasets={licenseSessionsAgg.aggTotalUSDRoyaltyDataset}
                                    options={defaultChartOptions}
                                    labels={licenseSessionsAgg.aggDateLabels}/>
                        }>
                </CWidgetDropdown>
            </CCol>

            {licenseSessionsAgg.aggRoyaltyCurrencies.filter(currency => licenseSessionsAgg.aggRoyaltyDatasets[currency].length > 0).map(currency => {
                const aggRoyaltyDatasets = licenseSessionsAgg.aggRoyaltyDatasets[currency];

                const totalRoyaltyToday = dashboard.view === DashboardViewType.CurrentMonth ? computed(() =>
                    moneyToDisplayStr(totalToday(aggRoyaltyDatasets).toString()) + currency
                ) : undefined;

                const totalRoyaltyMonth = dashboard.view === DashboardViewType.CurrentMonth ? computed(() =>
                    moneyToDisplayStr(totalMonth(aggRoyaltyDatasets).toString()) + currency
                ) : undefined;

                return (
                        <CCol key={currency} xs="12" sm="6" lg="6" xl="6" xxl="4">
                            <CWidgetDropdown
                                    color="gradient-warning"
                                    header={t`Royalty ${currency}`}
                                    text={dashboard.view === DashboardViewType.CurrentMonth ? t`Today: ${totalRoyaltyToday}, Month: ${totalRoyaltyMonth}` : undefined}
                                    footerSlot={
                                        <CChartLine
                                                className="mt-3 mx-3"
                                                style={{height: '110px'}}
                                                datasets={aggRoyaltyDatasets}
                                                options={defaultChartOptions}
                                                labels={licenseSessionsAgg.aggDateLabels}/>
                                    }>
                            </CWidgetDropdown>
                        </CCol>
                );
            })}
        </CRow>
    </React.Fragment>
    );
});

export default DashboardView;
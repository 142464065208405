import {CButton, CCol, CForm, CInput, CLabel, CPopover, CRow, CSelect, CSpinner, CSwitch} from "@coreui/react";
import React, {useContext} from "react";
import {jt, t} from "ttag";
import {observer} from "mobx-react-lite";
import {useForm} from "react-hook-form";
import {Venue} from "../generated/graphql";
import Error from "./Error";
import {StoreContext} from "../context/StoreContext";
import Customers from "./Customers";
import Timezones from "./Timezones";

interface EditVenueProps {
    venue?: Venue;
    loading?: boolean;
    error?: string;
    onSubmit: (
        country: string,
        city: string,
        location: string,
        timezone: string,
        customerId?: string,
        isArchived?: boolean
    ) => void;
    onCancel?: () => void;
    isVertical?: boolean;
    hideCancel?: boolean;
}

interface VenueSettingsInput {
    country: string;
    city: string;
    location: string;
    timezone: string;
    customerId?: string;
    isarchived?: boolean;
}

const EditVenue: React.FC<EditVenueProps> = observer((props: EditVenueProps) => {
    const store = useContext(StoreContext);
    const {auth, activeCustomers} = store;

    const {register, handleSubmit, errors, reset} = useForm<VenueSettingsInput>({
        defaultValues: {
            country: props.venue?.country || "",
            city: props.venue?.city || "",
            location: props.venue?.location,
            timezone: props.venue?.timezone || auth.currentUser?.timezone,
            isarchived: !!props.venue?.isArchived
        }
    });

    const onSubmit = (data: VenueSettingsInput) => {
        props.onSubmit(
            data.country,
            data.city,
            data.location,
            data.timezone,
            data.customerId,
            data.isarchived
        );
        reset();
    };

    const customerBlock = !props.venue && <React.Fragment>
        <CLabel htmlFor="customerId">{t`Customer`}</CLabel>
        <CPopover content={activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length > 0 ? t`Required` : t`No active customers`)} placement="right"
                  advancedOptions={{onShow: () => !!errors.customerId || !!activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length <= 0)}}>
            <CSelect name="customerId"
                     innerRef={register({required: true})}
                     invalid={!!errors.customerId}>
                <Customers/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const countryBlock = <React.Fragment>
        <CLabel htmlFor="country">{t`Country`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.country}}>
            <CInput name="country" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.country}/>
        </CPopover>
    </React.Fragment>;

    const cityBlock = <React.Fragment>
        <CLabel htmlFor="city">{t`City`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.city}}>
            <CInput name="city" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.city}/>
        </CPopover>
    </React.Fragment>;

    const locationBlock = <React.Fragment>
        <CLabel htmlFor="location">{t`Location`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.location}}>
            <CInput name="location" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.location}/>
        </CPopover>
    </React.Fragment>;

    const timezoneBlock = <React.Fragment>
        <CLabel htmlFor="timezone">{t`Timezone`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.timezone}}>
            <CSelect name="timezone"
                     innerRef={register({required: true})}
                     invalid={!!errors.timezone}>
                <Timezones/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const disabledBlock = props.venue && <React.Fragment>
        <CLabel htmlFor="isarchived" style={{width: "100%"}}>{t`Archived`}</CLabel>
        <CSwitch name="isarchived"
                 innerRef={register()}
                 shape="pill"
                 color="danger"/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit" disabled={!!props.loading}>
        {props.venue ? t`Save` : t`Create`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = !props.hideCancel &&
            <CButton color="secondary" className="px-4 ml-2" type="reset" onClick={props.onCancel}>{jt`Cancel`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        {customerBlock && <CRow>
            {customerBlock}
        </CRow>}

        <CRow>
            {countryBlock}
        </CRow>

        <CRow>
            {cityBlock}
        </CRow>

        <CRow>
            {locationBlock}
        </CRow>

        <CRow>
            {timezoneBlock}
        </CRow>

        {disabledBlock && <CRow>
            {disabledBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            {customerBlock && <CCol>
                {customerBlock}
            </CCol>}

            <CCol>
                {countryBlock}
            </CCol>

            <CCol>
                {cityBlock}
            </CCol>

            <CCol>
                {locationBlock}
            </CCol>

            <CCol>
                {timezoneBlock}
            </CCol>

            {disabledBlock && <CCol>
                {disabledBlock}
            </CCol>}

            <CCol>
                {submitBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            {elements}

            {props.error && <CRow><Error>{props.error}</Error></CRow>}
        </CForm>
    );
});

export default EditVenue;
import gql from "graphql-tag";

const CustomerFragment = gql`fragment CustomerFragment on Customer {
    id,
    isArchived,
    name,
    createdAtUtc,
    tags,
    priceCalendarDefault {
        fromDayOfWeek,
        toDayOfWeek,
        fromHour,
        toHour,
        price
    },
    priceDefault {
        price,
        priceCurrency,
        priceVariant
    },
    royaltyDefault {
        royalty,
        royaltyShare,
        royaltyCurrency,
        royaltyVariant
    }
}`;

export default CustomerFragment;
import React from "react";
import {PriceVariantType} from "../generated/graphql";
import {t} from "ttag";

const PriceVariants: React.FC = () => {
    return (
        <React.Fragment>
            <option value={PriceVariantType.PerPlayer}>{t`Per player`}</option>
            <option value={PriceVariantType.PerPlayerMinute}>{t`Per player minute`}</option>
            <option value={PriceVariantType.PerPlayerProgressive}>{t`Per player hour (1st hour up to 90min)`}</option>
            <option value={PriceVariantType.PerSession}>{t`Per session`}</option>
            <option value={PriceVariantType.PerSessionMinute}>{t`Per minute`}</option>
            <option value={PriceVariantType.PerSessionProgressive}>{t`Per hour (1st hour up to 90min)`}</option>
        </React.Fragment>
    );
};

export default PriceVariants;
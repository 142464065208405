import {observer} from "mobx-react-lite";
import {License} from "../generated/graphql";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {CBadge, CWidgetDropdown} from "@coreui/react";
import {t} from "ttag";
import {ProductsStore} from "../stores/ProductsStore";
import Price from "./Price";
import Royalty from "./Royalty";
import {DateAndTime} from "./DateAndTime";
import PriceCalendar from "./PriceCalendar";

const getLicenseStatusBadgeColor = (isRevoked: boolean, isExpired: boolean) =>
    isRevoked ? "secondary" : (isExpired ? "danger" : "success");

const getStatusBadgeColor = (isArchived: boolean) => isArchived ? "secondary" : "success";

const UserLicenseWidget = observer((props: {license: License}) => {
    const store = useContext(StoreContext);
    const {products} = store;

    const imgBase64 = ProductsStore.getImage(products.products, props.license.productByProductId?.id);

    const isRevoked = props.license.isRevoked;
    const isExpired = new Date(Date.now()) > new Date(props.license.expirateAtUtc);

    const isVenueArchived = !!props.license.venueByVenueId?.isArchived;

    const location = props.license.venueByVenueId ? `${props.license.venueByVenueId.country} ${props.license.venueByVenueId.city} ${props.license.venueByVenueId.location}` : "";

    return (
        <CWidgetDropdown header={props.license.productByProductId?.name || ""}
                         color={props.license.isRevoked ? "secondary" : "gradient-dark"}
                         footerSlot={
                             <React.Fragment>
                                 <div className="px-3 py-2">
                                     {imgBase64 && <img height="200"
                                                        style={{
                                                          maxWidth: "100%",
                                                          objectFit: "cover",
                                                          objectPosition: "center"
                                                        }}
                                                        alt={t`Product`}
                                                        src={imgBase64}/>}

                                     <dl className="row">
                                         <dt className="col-sm-4">{t`Venue`}</dt>
                                         <dd className="col-sm-8">
                                             {location}
                                             <CBadge color={getStatusBadgeColor(isVenueArchived)}>
                                                 {isVenueArchived ? t`archived` : t`active`}
                                             </CBadge>
                                         </dd>

                                         <dt className="col-sm-4 text-truncate" title={t`License key`}>{t`License key`}</dt>
                                         <dd className="col-sm-8">
                                             {props.license.licenseKey}
                                         </dd>

                                         <dt className="col-sm-4">{t`Price`}</dt>
                                         <dd className="col-sm-8">
                                             <Price price={props.license.price}/>

                                             {props.license.priceCalendar && props.license.priceCalendar.length > 0 &&
                                                 <PriceCalendar priceCalendar={props.license.priceCalendar}
                                                                priceCurrency={props.license.price.priceCurrency}/>}
                                         </dd>

                                         <dt className="col-sm-4">{t`Royalty`}</dt>
                                         <dd className="col-sm-8">
                                             <Royalty royalty={props.license.royalty}/>
                                         </dd>

                                         <dt className="col-sm-4">{t`Status`}</dt>
                                         <dd className="col-sm-8">
                                             <CBadge color={getLicenseStatusBadgeColor(isRevoked, isExpired)}>
                                                 {isRevoked ? t`revoked` : (isExpired ? t`expired` : t`active`)}
                                             </CBadge>
                                         </dd>

                                         <dt className="col-sm-4 text-truncate" title={t`Expiration date`}>{t`Expiration date`}</dt>
                                         <dd className="col-sm-8">
                                             <DateAndTime date={new Date(props.license.expirateAtUtc)} format={"YYYY/MM"}/>
                                         </dd>
                                     </dl>
                                 </div>
                             </React.Fragment>
                         }>
        </CWidgetDropdown>
    );
});

export default UserLicenseWidget;
import {InMemoryCache, NormalizedCacheObject} from "apollo-cache-inmemory";
import {createHttpLink} from "apollo-link-http";
import ApolloClient from "apollo-client";
import {baseApiURI} from "../../utils/ApiUtils";
import {NotAuthenticatedError, UnknownError} from "../AbstractApi";

const cache = new InMemoryCache();

const link = createHttpLink({
    uri: `${baseApiURI}/graphql`,
    credentials: process.env.NODE_ENV === "development" ? "include" : undefined
});

let apolloClient: ApolloClient<NormalizedCacheObject>;

function init() {
    apolloClient = new ApolloClient({
        cache: cache,
        link: link,

        //queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                errorPolicy: "all",
                fetchPolicy: "cache-and-network"
            },
            query: {
                errorPolicy: "all",
                fetchPolicy: "network-only"
            },
            mutate: {
                errorPolicy: "all"
            }
        }
    });
}

function defaultHandleResponseForError<R>(response: R): R {
    const responseAny: any = response;

    if (responseAny && responseAny["errors"]) {
        const errors = responseAny["errors"];
        throw new UnknownError(errors && errors[0] && errors[0]["message"]);
    }

    return response;
}

function wrapForApiErrors<R>(
    promise: Promise<R>,
    handleResponseForError: (response: R) => R = defaultHandleResponseForError): Promise<R> {
    return promise.then((response: R) => {
        const responseAny: any = response;
        if (responseAny && responseAny["data"] && (responseAny["data"] as any)["currentUserId"] === null) {
            throw new NotAuthenticatedError();
        }

        return handleResponseForError(response);
    }, (err) => {
        throw new UnknownError(err);
    });
}

interface PageParam {
    after: string | null,
    before: string | null,
    isLast?: boolean
}

interface PageInfo {
    firstPage?: PageParam,
    lastPage?: PageParam,
    nextPage?: PageParam,
    prevPage?: PageParam,
    totalCount: number
}

const PageSizeDefault = 50;
const DelayMSDefault = 350;

export type {
    PageParam,
    PageInfo
}

export {
    init,
    apolloClient,
    wrapForApiErrors,
    PageSizeDefault,
    DelayMSDefault
};
import {action, autorun, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    License,
    Mutation,
    PriceCalendarDefinition,
    PriceDefinition,
    RoyaltyDefinition
} from "../generated/graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {ExecutionResult} from "graphql";

class EditLicenseStore {
    @observable.ref
    public license: License | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.rootStore.licenses.licenses) {
                this.reset();
            }
        });
    }

    @action
    public async save(
        isRevoked: boolean,
        price: PriceDefinition,
        royalty: RoyaltyDefinition,
        priceCalendar?: PriceCalendarDefinition[]
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.saveQuery(this.license?.id, isRevoked, price, royalty, priceCalendar);

            this.rootStore.licenses.fetchLicenses();

            this.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async saveQuery(licenseId: string,
                            isRevoked: boolean,
                            price: PriceDefinition,
                            royalty: RoyaltyDefinition,
                            priceCalendar?: PriceCalendarDefinition[]): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            licenseId: string,
            isRevoked: boolean,
            price: PriceDefinition,
            priceCalendar?: PriceCalendarDefinition[],
            royalty: RoyaltyDefinition
        }>({
            mutation: gql`mutation ($licenseId: UUID!, $isRevoked: Boolean!, $price: PriceDefinitionInput!, $priceCalendar: [PriceCalendarDefinitionInput], $royalty: RoyaltyDefinitionInput!) {
                updateLicenseById(input: {licensePatch: {isRevoked: $isRevoked, price: $price, priceCalendar: $priceCalendar, royalty: $royalty}, id: $licenseId}) {
                    clientMutationId
                }
            }`,
            variables: {
                licenseId,
                isRevoked,
                price,
                priceCalendar,
                royalty
            }
        }));
    }

    @action
    public setLicense(license: License | undefined) {
        this.reset();

        setTimeout(() => runInAction(() => {
            this.license = license;
        }));
    }

    @action
    public reset() {
        this.license = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    EditLicenseStore
};
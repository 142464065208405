import React from "react";
import ReactDOM from "react-dom";
import {MobxRouter, startRouter} from "mobx-router";
import * as serviceWorker from "./serviceWorker";
import {StoreProvider} from "./context/StoreContext";
import {routes} from "./routes";
import {store} from "./stores/RootStore";
import {loadCurrentLocale} from "./utils/I18nUtils";
import {icons} from "./assets/icons";
import "./scss/style.scss";

const ReactAny: any = React;
ReactAny["icons"] = icons;

loadCurrentLocale();

require("./api/graphql/ApolloClient").init();

startRouter(routes, store, {
    html5history: true
});

ReactDOM.render(
  <React.StrictMode>
      <StoreProvider value={store}>
          <MobxRouter store={store}/>
      </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {QueryParams, Route} from "mobx-router";
import {toJS} from "mobx";
import {RouteParams} from "mobx-router/src/route";
import React from "react";
import {RootStore} from "./stores/RootStore";
import AuthorizedView from "./components/views/AuthorizedView";
import LoginView from "./components/views/LoginView";
import AuthenticatingView from "./components/views/AuthenticatingView";
import DashboardView from "./components/views/DashboardView";
import CurrentUserSettingsView from "./components/views/CurrentUserSettingsView";
import FinancialReportsView from "./components/views/FinancialReportsView";
import ProductReportsView from "./components/views/ProductReportsView";
import ProductsView from "./components/views/ProductsView";
import UsersView from "./components/views/UsersView";
import CustomersView from "./components/views/CustomersView";
import VenuesView from "./components/views/VenuesView";
import LicensesView from "./components/views/LicensesView";
import {UserRoleType} from "./generated/graphql";
import LoadedSessionsView from "./components/views/LoadedSessionsView";
import UserFilesView from "./components/views/UserFilesView";
import LicensesV2View from "./components/views/LicensesV2View";
import ServicesView from "./components/views/ServicesView";

type PrevRoute = {
    route: Route<RootStore>,
    params: RouteParams,
    queryParams: QueryParams
};

function getPrev(queryParams: QueryParams): PrevRoute | undefined {
    try {
        const queryParamsJS = queryParams && toJS(queryParams);
        const routePath = queryParamsJS && queryParamsJS["prev"];

        const route = routePath && typeof(routePath) === "string" && Object.values(routes).find(route => route.path = routePath);

        if (!routePath || !route) {
            return undefined;
        }

        const getParams = (paramsKey: "prevParams" | "prevQueryParams") => {
            const val = queryParamsJS && queryParamsJS[paramsKey];
            return (val && typeof (val) === "string" && JSON.parse(val)) || {};
        }

        return {
            route: route,
            params: getParams("prevParams"),
            queryParams: getParams("prevQueryParams"),
        };
    } catch (e) {
        console.error(`routes.getPrev failed: ${e}`);
        return undefined;
    }
}

function goToDeferred(store: RootStore, route: Route<RootStore>, params?: RouteParams, queryParams?: QueryParams) {
    setTimeout(() => store.router.goTo(route, params, queryParams));
}

function goToPrev(store: RootStore, defaultRoute = {route: routes.authorized, params: {}, queryParams: {}}): boolean {
    const prev = getPrev(store.router.queryParams);
    const route = prev || defaultRoute;

    if (!route) {
        console.error(`routes.goToPrev has unexpected prev: ${JSON.stringify(prev)} or defaultRoute: ${JSON.stringify(defaultRoute)}`);
        return false;
    }

    goToDeferred(store, route.route, route.params, route.queryParams);
    return true;
}

function goToWithPrev(store: RootStore, route: Route<RootStore>, params: RouteParams = {}, queryParams: QueryParams = {}) {
    const queryParamsJS = queryParams && toJS(queryParams);
    const currentQueryParamsJS = store.router.queryParams && toJS(store.router.queryParams);

    goToDeferred(store, route, params, {
        ...queryParamsJS,
        ...(currentQueryParamsJS && currentQueryParamsJS["prev"] && currentQueryParamsJS["prevParams"] && currentQueryParamsJS["prevQueryParams"] ? {
            prev: currentQueryParamsJS["prev"],
            prevParams: JSON.stringify(currentQueryParamsJS["prevParams"]),
            prevQueryParams: JSON.stringify(currentQueryParamsJS["prevQueryParams"])
        } : {})
    });
}

function defaultRouting(route: Route<RootStore>, params: RouteParams, store: RootStore, queryParams: QueryParams): boolean {
    const queryParamsJS = queryParams && toJS(queryParams);

    const prevParams = {
        prev: (queryParamsJS && queryParamsJS["prev"]) || route.path,
        prevParams: JSON.stringify((queryParamsJS && queryParamsJS["prevParams"]) || toJS(params)),
        prevQueryParams: JSON.stringify((queryParamsJS && queryParamsJS["prevQueryParams"]) || toJS(queryParams))
    };

    if ((store.auth.isAuthorizing || !store.auth.isAuthorized) && route !== routes.authenticating) {
        goToDeferred(store, routes.authenticating, undefined, prevParams);
        return true;
    } else if (route === routes.authorized && !store.auth.isServiceUser) {
        goToDeferred(store, routes.dashboard);
        return true;
    } else if (route === routes.authorized && store.auth.isServiceUser) {
        goToDeferred(store, routes.services);
        return true;
    }

    return false;
}

const routes = {
    authorized: new Route<RootStore>({
        path: "/",
        component: <div/>,
        beforeEnter(route, params, store, queryParams) {
            return !defaultRouting(route, params, store, queryParams);
        }
    }),
    services: new Route<RootStore>({
        path: "/services",
        component: <AuthorizedView><ServicesView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }
        }
    }),
    dashboard: new Route<RootStore>({
        path: "/dashboard",
        component: <AuthorizedView><DashboardView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.dashboard.reset();
        }
    }),
    products: new Route<RootStore>({
        path: "/products",
        component: <AuthorizedView><ProductsView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.products.fetchProducts(true);

            if (store.auth.currentUser?.role === UserRoleType.VerControlpanelUser) {
                store.userLicenses.fetchLicenses();
                store.userLicensesV2.fetchLicenses();
            }
        }
    }),
    users: new Route<RootStore>({
        path: "/users",
        component: <AuthorizedView><UsersView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.users.reset();
        }
    }),
    customers: new Route<RootStore>({
        path: "/customers",
        component: <AuthorizedView><CustomersView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.customers.reset();
        }
    }),
    venues: new Route<RootStore>({
        path: "/venues",
        component: <AuthorizedView><VenuesView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.venues.reset();
        }
    }),
    licensesV2: new Route<RootStore>({
        path: "/licensesV2",
        component: <AuthorizedView><LicensesV2View/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.licensesV2.reset();
        }
    }),
    licenses: new Route<RootStore>({
        path: "/licenses",
        component: <AuthorizedView><LicensesView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.licenses.reset();
        }
    }),
    loadedSessions: new Route<RootStore>({
        path: "/loadedSessions",
        component: <AuthorizedView><LoadedSessionsView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.loadedSessions.reset();
            store.loadedSessions.fetchLoadedLicenseSession();
        }
    }),
    userFiles: new Route<RootStore>({
        path: "/userFiles",
        component: <AuthorizedView><UserFilesView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            store.userFiles.reset();
        }
    }),
    financialReports: new Route<RootStore>({
        path: "/financialReports",
        component: <AuthorizedView><FinancialReportsView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            if (store.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin) {
                store.customers.reset();
            }

            store.venues.reset();

            store.reportsFilter.reset();
            store.licenseSessionsAgg.reset();
            store.licenseSessions.reset();
        }
    }),
    productReports: new Route<RootStore>({
        path: "/productReports",
        component: <AuthorizedView><ProductReportsView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            if (store.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin) {
                store.customers.reset();
            }

            store.venues.reset();

            store.products.fetchProducts(false);

            store.reportsFilter.reset();
            store.licenseSessionsAgg.reset();
            store.licenseSessions.reset();
        }
    }),
    currentUserSettings: new Route<RootStore>({
        path: "/currentUserSettings",
        component: <AuthorizedView><CurrentUserSettingsView/></AuthorizedView>,
        beforeEnter(route, params, store, queryParams) {
            return !defaultRouting(route, params, store, queryParams);
        }
    }),
    authenticating: new Route<RootStore, {}, {
        logout?: boolean
        logoutAll?: boolean
    }>({
        path: "/authenticating",
        component: <AuthenticatingView/>,
        beforeEnter(route, params, store, queryParams) {
            if (defaultRouting(route, params, store, queryParams)) {
                return false;
            }

            if (queryParams.logout || queryParams.logoutAll) {
                store.auth.logout(queryParams.logoutAll);
            } else if (store.auth.isAuthorized) {
                return !goToPrev(store);
            } else {
                store.auth.fetchCurrentInfo();
            }
        }
    }),
    login: new Route<RootStore>({
        path: "/login",
        component: <LoginView/>,
        beforeEnter(route, params, store) {
            store.login.reset();
        }
    })
}

export {
    routes,
    goToDeferred,
    goToPrev,
    goToWithPrev
};
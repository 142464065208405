import {action, autorun, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Customer, Mutation, PriceCalendarDefinition, PriceDefinition, RoyaltyDefinition} from "../generated/graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {ExecutionResult} from "graphql";

class EditCustomerStore {
    @observable.ref
    public customer: Customer | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.rootStore.customers.customers) {
                this.reset();
            }
        });
    }

    @action
    public async save(
        name: string,
        isArchived: boolean,
        tags: string[],
        priceDefault?: PriceDefinition,
        priceCalendarDefault?: PriceCalendarDefinition[],
        royaltyDefault?: RoyaltyDefinition
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.saveQuery(this.customer?.id, name, isArchived, tags, priceDefault, priceCalendarDefault, royaltyDefault);

            this.rootStore.customers.fetchCustomers();
            this.rootStore.customers.fetchTags();

            this.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async saveQuery(customerId: string,
                            customerName: string,
                            isArchived: boolean,
                            tags: string[],
                            priceDefault?: PriceDefinition,
                            priceCalendarDefault?: PriceCalendarDefinition[],
                            royaltyDefault?: RoyaltyDefinition): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            customerId: string,
            customerName: string,
            isArchived: boolean,
            tags: string[],
            priceDefault?: PriceDefinition,
            priceCalendarDefault?: PriceCalendarDefinition[],
            royaltyDefault?: RoyaltyDefinition
        }>({
            mutation: gql`mutation ($customerId: UUID!, $customerName: NameType!, $isArchived: Boolean!, $tags: [String], $priceDefault: PriceDefinitionInput, $priceCalendarDefault: [PriceCalendarDefinitionInput], $royaltyDefault: RoyaltyDefinitionInput) {
                updateCustomerById(input: {customerPatch: {name: $customerName, isArchived: $isArchived, tags: $tags, priceDefault: $priceDefault, priceCalendarDefault: $priceCalendarDefault, royaltyDefault: $royaltyDefault}, id: $customerId}) {
                    clientMutationId
                }
            }`,
            variables: {
                customerId,
                customerName,
                isArchived,
                tags,
                priceDefault,
                priceCalendarDefault,
                royaltyDefault
            }
        }));
    }

    @action
    public setCustomer(customer: Customer | undefined) {
        this.reset();

        setTimeout(() => runInAction(() => {
            this.customer = customer;
        }));
    }

    @action
    public reset() {
        this.customer = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    EditCustomerStore
};
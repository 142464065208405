import {action, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {License, LicenseFilter, Maybe, Query} from "../generated/graphql";
import {
    apolloClient,
    DelayMSDefault,
    PageInfo,
    PageParam,
    PageSizeDefault,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import {debounceEffect} from "../utils/MobxUtils";
import LicenseFragment from "../api/graphql/LicenseFragment";

class LicensesStore {
    @observable.ref
    public filter: LicenseFilter | undefined = undefined;

    @observable.ref
    public page: PageParam = {
        after: null,
        before: null,
        isLast: false
    };

    @observable.ref
    public licenses: Maybe<License>[] | undefined = undefined;

    @observable.ref
    public pageInfo: PageInfo | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => {
                return {
                    filter: this.filter,
                    page: this.page
                };
            },
            debounceEffect(() => {
                this.fetchLicenses();
            }, DelayMSDefault)
        );
    }

    @action
    public fetchLicenses() {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseFilter | undefined,
            afterInput: string | null,
            firstInput: number | null,
            beforeInput: string | null,
            lastInput: number | null
        }>({
            query: gql`query ($filterInput: LicenseFilter, $afterInput: Cursor, $firstInput: Int, $beforeInput: Cursor, $lastInput: Int) {
                currentUserId,
                allLicenses(orderBy: [IS_REVOKED_ASC, EXPIRATE_AT_UTC_ASC, CREATED_AT_UTC_DESC], filter: $filterInput, after: $afterInput, first: $firstInput, before: $beforeInput, last: $lastInput) {
                    nodes {
                        ...LicenseFragment
                    },
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    totalCount
                }
            },
            ${LicenseFragment}`,
            variables: {
                filterInput: this.filter,
                afterInput: this.page.after,
                firstInput: this.page.after || (!this.page.before && !this.page.isLast) ? PageSizeDefault : null,
                beforeInput: this.page.before,
                lastInput: this.page.before || this.page.isLast ? PageSizeDefault : null
            }
        })).then(result => {
            runInAction(() => {
                this.licenses = result.data.allLicenses?.nodes;
                this.pageInfo = {
                    firstPage: !result.data.allLicenses?.pageInfo.hasPreviousPage ? undefined : {
                        after: null,
                        before: null
                    },
                    lastPage: !result.data.allLicenses?.pageInfo.hasNextPage ? undefined : {
                        after: null,
                        before: null,
                        isLast: true
                    },
                    nextPage: !result.data.allLicenses?.pageInfo.hasNextPage ? undefined : {
                        after: result.data.allLicenses?.pageInfo.endCursor as string,
                        before: null
                    },
                    prevPage: !result.data.allLicenses?.pageInfo.hasPreviousPage ? undefined : {
                        after: null,
                        before: result.data.allLicenses?.pageInfo.startCursor as string
                    },
                    totalCount: result.data.allLicenses?.totalCount || 0
                };
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public setFilter(filter: LicenseFilter | undefined) {
        this.filter = filter;

        if (this.pageInfo?.firstPage) {
            this.setPage(this.pageInfo.firstPage);
        }
    }

    @action
    public setPage(page: PageParam) {
        this.page = page;
    }

    @action
    public reset() {
        this.filter = undefined;
        this.page = {
            after: null,
            before: null,
            isLast: false
        };
        this.licenses = undefined;
        this.pageInfo = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    LicensesStore
};
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {
    LicenseV2BooleanClaimValue,
    LicenseV2Claim,
    LicenseV2ClaimId,
    LicenseV2ClaimValue, LicenseV2NumberClaimValue,
    LicenseV2ProductOptionClaimValue, LicenseV2StringClaimValue
} from "../api/LicenseV2Claims";
import {CInput, CPopover, CRow} from "@coreui/react";
import {t} from "ttag";
import {getLicenseV2ClaimIdName} from "../utils/LicenseV2ClaimUtils";
import EditLicenseV2NumberClaim from "./EditLicenseV2NumberClaim";
import EditLicenseV2BooleanClaim from "./EditLicenseV2BooleanClaim";
import EditLicenseV2StringClaim from "./EditLicenseV2StringClaim";

interface EditLicenseV2ProductOptionClaimProps {
    editClaim: LicenseV2Claim;
    onSetEditClaimValue: (value: LicenseV2ClaimValue) => void;
}

const EditLicenseV2ProductOptionClaim = observer((props: EditLicenseV2ProductOptionClaimProps) => {
    const [isOptionIdValid, setIsOptionIdValid] = useState(false);

    const optionIdInputRef = useRef<HTMLInputElement>();

    const optionValue = props.editClaim.value as LicenseV2ProductOptionClaimValue;

    if (!optionValue) {
        return null;
    }

    useEffect(() => {
        setIsOptionIdValid(!!optionIdInputRef?.current?.value && optionIdInputRef?.current?.value.trim().length !== 0);
    }, [optionIdInputRef?.current?.value]);

    let valueComponent = null;
    
    switch (props.editClaim.id) {
        case LicenseV2ClaimId.PRODUCT_OPTION_STRING:
            valueComponent = <EditLicenseV2StringClaim label={t`Exact values`}
                                                       editClaimValue={optionValue as LicenseV2StringClaimValue}
                                                       onSetEditClaimValue={props.onSetEditClaimValue}/>
            break;
        case LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN:
            valueComponent = <EditLicenseV2BooleanClaim label={t`Exact value`}
                                                        editClaimValue={optionValue as LicenseV2BooleanClaimValue}
                                                        onSetEditClaimValue={props.onSetEditClaimValue}/>;
            break;
        case LicenseV2ClaimId.PRODUCT_OPTION_NUMBER:
            valueComponent = <EditLicenseV2NumberClaim editClaimValue={optionValue as LicenseV2NumberClaimValue}
                                                       onSetEditClaimValue={props.onSetEditClaimValue}/>;
    }
    
    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{getLicenseV2ClaimIdName(props.editClaim.id)} {t`name`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Required, example 12345.67`} placement="right"
                          advancedOptions={{onShow: () => !isOptionIdValid}}>
                    <CInput type="text"
                            innerRef={optionIdInputRef}
                            invalid={!isOptionIdValid}
                            defaultValue={optionValue.option_id}
                            onChange={(event) => props.onSetEditClaimValue({
                                option_id: (event.target as HTMLInputElement).value?.trim()
                            } as LicenseV2ProductOptionClaimValue)}/>
                </CPopover>
            </CRow>

            {valueComponent}
        </React.Fragment>
    );
});

export default EditLicenseV2ProductOptionClaim;
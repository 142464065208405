import {CBadge, CButton, CCol, CForm, CInput, CLabel, CPopover, CRow, CSpinner, CSwitch} from "@coreui/react";
import React, {useContext, useRef} from "react";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import {Customer} from "../generated/graphql";
import Error from "./Error";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../context/StoreContext";

interface EditCustomerProps {
    customer?: Customer;
    loading?: boolean;
    error?: string;
    onSubmit: (
        name: string,
        tags: string[],
        isArchived?: boolean
    ) => void;
    onCancel?: () => void;
    isVertical?: boolean;
    hideCancel?: boolean;
}

interface CustomerSettingsInput {
    newcustomername: string;
    isarchived?: boolean;
    tags: string;
}

const EditCustomer = observer((props: EditCustomerProps) => {
    const store = useContext(StoreContext);
    const {customers} = store;

    const {register, handleSubmit, errors, watch, setValue, reset} = useForm<CustomerSettingsInput>({
        defaultValues: {
            newcustomername: props.customer?.name,
            isarchived: !!props.customer?.isArchived,
            tags: props.customer?.tags?.join(',') || ""
        }
    });

    const onSubmit = (data: CustomerSettingsInput) => {
        props.onSubmit(
            data.newcustomername,
            data.tags.split(','),
            data.isarchived
        );
        reset();
    };

    const customernameBlock = <React.Fragment>
        <CLabel htmlFor="newcustomername">{t`Customer name`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.newcustomername}}>
            <CInput name="newcustomername" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.newcustomername}/>
        </CPopover>
    </React.Fragment>;

    const taginputRef = useRef<HTMLInputElement>();

    const tagsArray = watch("tags").split(',');

    const availableTags = customers.tags?.filter(tag => tag && !tagsArray.includes(tag));

    const onAddTag = (tag: string) => {
        setTimeout(() =>
            setValue("tags", [...tagsArray, tag].join(','))
        );
    };

    const onRemoveTag = (tag: string) => {
        setTimeout(() =>
            setValue("tags", tagsArray.filter(t => t !== tag).join(','))
        );
    };

    const onAddTagFromInput = () => {
        const tag = taginputRef?.current?.value;

        if (!tag || tagsArray.includes(tag)) {
            return;
        }

        onAddTag(tag);

        if (taginputRef?.current?.value) {
            taginputRef.current.value = "";
        }
    };

    const tagsBlock = <React.Fragment>
        <CLabel style={{width: "100%"}}>{t`Tags`}
            {availableTags && availableTags.length > 0 && t` (available:`}
            {availableTags && availableTags.map(tag =>
                <CBadge color="success"
                        style={{"cursor": "pointer"}}
                        title={t`Add tag`}
                        className="ml-2"
                        key={tag}
                        onClick={(e: Event) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (tag) {
                                onAddTag(tag);
                            }
                        }}>{tag}</CBadge>
            )}
            {availableTags && availableTags.length > 0 && ')'}
        </CLabel>

        <CRow gutters={false} style={{width: "100%"}}>
            <CCol className="col-sm-auto">
            {tagsArray && tagsArray.map(tag =>
                    <CBadge color="info"
                            style={{"cursor": "pointer"}}
                            title={t`Remove tag`}
                            className="mr-2"
                            key={tag}
                            onClick={(e: Event) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (tag) {
                                    onRemoveTag(tag);
                                }
                            }}>{tag}</CBadge>
            )}
            </CCol>

            <CCol className="col-sm-6">
                <CRow gutters={false}>
                    <CCol>
                        <CInput name="taginput" type="text"
                                innerRef={taginputRef}
                                title={t`New tag`}
                                onKeyPress={e => {
                                    if (e.key !== "Enter") {
                                        return;
                                    }

                                    e.preventDefault();
                                    e.stopPropagation();

                                    onAddTagFromInput();
                                }}/>
                    </CCol>

                    <CCol className="col-sm-auto">
                        <CButton color="info"
                                 className={{"px-4": true, "ml-2": true}}
                                 onClick={onAddTagFromInput}>
                            {t`Add`}
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>

        <CInput name="tags" type="text"
                hidden
                innerRef={register()}/>
    </React.Fragment>;

    const disabledBlock = props.customer && <React.Fragment>
        <CLabel htmlFor="isarchived" style={{width: "100%"}}>{t`Archived`}</CLabel>
        <CSwitch name="isarchived"
                 innerRef={register()}
                 shape="pill"
                 color="danger"/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit" disabled={!!props.loading}>
        {props.customer ? t`Save` : t`Create`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = !props.hideCancel &&
            <CButton color="secondary" className="px-4 ml-2" type="reset" onClick={props.onCancel}>{jt`Cancel`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        <CRow>
            {customernameBlock}
        </CRow>

        <CRow>
            {tagsBlock}
        </CRow>

        {disabledBlock && <CRow>
            {disabledBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            <CCol>
                {customernameBlock}
            </CCol>

            <CCol>
                {tagsBlock}
            </CCol>

            {disabledBlock && <CCol>
                {disabledBlock}
            </CCol>}

            <CCol>
                {submitBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            {elements}

            {props.error && <CRow><Error>{props.error}</Error></CRow>}
        </CForm>
    );
});

export default EditCustomer;
import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {
    CBadge,
    CDataTable,
} from "@coreui/react";
import {t} from "ttag";
import {UserRoleType, Venue} from "../generated/graphql";
import Error from "./Error";
import Pagination from "./Pagination";

const SelectVenue = observer((props: {selectedVenueIds: string[], onSelect: (venue: Venue) => void}) => {
    const store = useContext(StoreContext);
    const {venues, auth} = store;

    const filter = (str: string) => {
        venues.setFilter(!str ? undefined : {
            or: [
                {
                    country: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    city: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    location: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    customerByCustomerId: {
                        name: {
                            likeInsensitive: `%${str}%`
                        }
                    }
                }
            ]
        });
    };

    return (
            <CDataTable
                    noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                    items={(venues.venues && venues.venues.length > 0 && venues.venues) || []}
                    loading={venues.loading}
                    fields={["location"]}
                    border={true}
                    clickableRows={true}
                    tableFilter={{external: true, label: t`Filter:`, placeholder: t`type location or customer name`}}
                    onTableFilterChange={filter}
                    onRowClick={props.onSelect}
                    columnHeaderSlot={{
                        location: t`Venue`
                    }}
                    scopedSlots={{
                        location: (venue: Venue) => (
                                <td className={`${props.selectedVenueIds.includes(venue.id) ? "bg-info" : ""}`}>
                                    {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ? `${venue.customerByCustomerId?.name} / ${venue.country} ${venue.city} ${venue.location}` : `${venue.country} ${venue.city} ${venue.location}`}
                                    {(venue.isArchived || venue.customerByCustomerId?.isArchived) && <CBadge color="secondary">
                                        {t`archived`}
                                    </CBadge>}
                                </td>
                        )
                    }}
                    underTableSlot={venues.error ? <Error>{venues.error}</Error> : <Pagination loading={venues.loading}
                                                                                                     pageInfo={venues.pageInfo}
                                                                                                     onPage={(page) => venues.setPage(page)}/>}/>
    );
});

export default SelectVenue;
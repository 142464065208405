import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Mutation,
    Product
} from "../generated/graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {ExecutionResult} from "graphql";
import ProductFragment from "../api/graphql/ProductFragment";

class EditProductStore {
    @observable.ref
    public product: Product | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async save(
        names: {[locale: string]: string},
        isGame: boolean,
        isArchived: boolean,
        imgBase64?: string
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.setIsGame(this.product?.id, isGame);

            await this.setArchived(this.product?.id, isArchived);

            if (imgBase64) {
                await this.setImage(this.product?.id, imgBase64);
            }

            for (let key in names) {
                const value = names[key];
                await EditProductStore.setProductName(this.product?.id, key, value);
            }

            this.rootStore.products.fetchProducts(true);

            this.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async setImage(productId: string,
                           imgBase64: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            productId: string,
            imgBase64: string
        }>({
            mutation: gql`mutation ($productId: UUID!, $imgBase64: String!) {
                updateProductById(input: {productPatch: {imgBase64: $imgBase64}, id: $productId}) {
                    clientMutationId
                }
            }`,
            variables: {
                productId,
                imgBase64
            }
        }));
    }

    @action
    private async setArchived(productId: string,
                              isArchived: boolean): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            productId: string,
            isArchived: boolean
        }>({
            mutation: gql`mutation ($productId: UUID!, $isArchived: Boolean!) {
                updateProductById(input: {productPatch: {isArchived: $isArchived}, id: $productId}) {
                    clientMutationId
                }
            }`,
            variables: {
                productId,
                isArchived
            }
        }));
    }

    @action
    private async setIsGame(productId: string, isGame: boolean): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            productId: string,
            isGame: boolean
        }>({
            mutation: gql`mutation ($productId: UUID!, $isGame: Boolean!) {
                updateProductById(input: {productPatch: {isGame: $isGame}, id: $productId}) {
                    clientMutationId
                }
            }`,
            variables: {
                productId,
                isGame
            }
        }));
    }

    public static setProductName(productId: string, locale: string, name: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            productId: string,
            locale: string,
            name: string
        }>({
            mutation: gql`mutation ($productId: UUID!, $locale: LocaleType!, $name: NameType!) {
                setProductName(input: {productId: $productId, locale: $locale, productName: $name}) {
                    product {
                        ...ProductFragment
                    }
                }
            },
            ${ProductFragment}`,
            variables: {
                productId,
                locale,
                name
            }
        }));
    }

    @action
    public setProduct(product: Product | undefined) {
        this.reset();

        setTimeout(() => runInAction(() => {
            this.product = product;
        }));
    }

    @action
    public reset() {
        this.product = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    EditProductStore
};
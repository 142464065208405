import {action, observable} from "mobx";
import {RootStore} from "./RootStore";

class UiStore {
    @observable
    public sidebarShow: boolean | "responsive" = "responsive";

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action.bound
    setSidebarShow(val: boolean | "responsive") {
        this.sidebarShow = val;
    }

    @action.bound
    toggleSidebarShowMobile() {
        this.sidebarShow = [false, "responsive"].includes(this.sidebarShow) ?
            true : "responsive";
    }

    @action.bound
    toggleSidebarShow() {
        this.sidebarShow = [true, "responsive"].includes(this.sidebarShow) ?
            false : "responsive";
    }
}

export default UiStore;
import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {
    LicenseSession, UserRoleType
} from "../../generated/graphql";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CCollapse,
    CDataTable,
    CLink,
    CRow,
} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import Pagination from "../Pagination";
import Error from "../Error";
import {DateAndTime} from "../DateAndTime";
import ReportsFilter from "../ReportsFilter";
import ProductReportsCharts from "../ProductReportsCharts";
import {OrderByType} from "../../api/LicenseSessionsApi";
import {leaderboardBaseURI} from "../../utils/ApiUtils";

const fields = [
    "venue",
    "product",
    "version",
    "players",
    "duration",
    "osVersion",
    {key: "startTimeLocal", _style: {width: "5%"}},
    {key: "other", _style: {width: "7%"}}
];

const ProductReportsView = observer(() => {
    const store = useContext(StoreContext);
    const {auth, productsUsage, productsUsageAgg} = store;

    const refresh = () => {
        productsUsageAgg.fetchAgg();
        productsUsage.fetchLicenseSessions();
    };

    return (
        <React.Fragment>
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            {t`Filter`}

                            <CLink className="ml-1"
                                   title={t`Refresh`}
                                   onClick={() => refresh()}
                                   disabled={productsUsage.loading || productsUsageAgg.loading}>
                                <CIcon name="cil-sync" className="mfe-2" />
                            </CLink>
                        </CCardHeader>

                        <CCardBody>
                            <ReportsFilter showUnpaid={true}
                                           showProducts={true}
                                           showCustomers={true}
                                           showVenues={true}
                                           showDates={true}
                                           showOrderBy={true}
                                           orderBySupportedValues={[OrderByType.ByDateDesc, OrderByType.ByDateAsc]}
                                           showAggregateBy={true}
                                           showSplitBy={true}
                                           showCompareSumType={true}
                                           areControlsEnabled={!productsUsage.loading && !productsUsageAgg.loading}/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <ProductReportsCharts/>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            {t`Products Usage`}
                        </CCardHeader>

                        <CCardBody>
                            <CDataTable
                                    noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                    items={(productsUsage.licenseSessions && productsUsage.licenseSessions.length > 0 && productsUsage.licenseSessions) || []}
                                    loading={productsUsage.loading}
                                    fields={fields}
                                    border={true}
                                    columnHeaderSlot={{
                                        venue: t`Customer/Venue`,
                                        product: t`Product`,
                                        version: t`Version`,
                                        players: t`Players`,
                                        duration: t`Duration`,
                                        osVersion: t`OS ver`,
                                        other: t`Info`,
                                        startTimeLocal: t`Date (local)`
                                    }}
                                    scopedSlots={{
                                        venue: (licenseSession: LicenseSession) => {
                                            const venue = licenseSession.venueByVenueId;
                                            const customer = licenseSession.customerByCustomerId;

                                            return !venue ? (
                                                <td>
                                                    <CBadge color="danger">
                                                        {t`unknown`}
                                                    </CBadge>
                                                </td>
                                            ) : (
                                                <td>
                                                    {customer?.name}

                                                    {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin &&
                                                            customer?.tags?.map(tag =>
                                                                    <CBadge key={tag} color="info" className="ml-2">{tag}</CBadge>
                                                            )} / {venue?.country ? venue?.country + " " : ""}{venue?.city ? venue?.city + " " : ""}{venue?.location}
                                                </td>
                                            );
                                        },
                                        product: (licenseSession: LicenseSession) => {
                                            return !licenseSession.productByProductId ? (
                                                    <td>
                                                        <CBadge color="danger">
                                                            {t`unknown`}
                                                        </CBadge>
                                                    </td>
                                            ) : (
                                                <td>
                                                    {licenseSession.productByProductId?.name}
                                                </td>
                                            );
                                        },
                                        version: (licenseSession: LicenseSession) => {
                                            return  (
                                                <td>
                                                    {licenseSession.productVersion?.join(', ')}
                                                </td>
                                            );
                                        },
                                        players: (licenseSession: LicenseSession) => {
                                            return  (
                                                    <td>
                                                        {licenseSession.players}
                                                    </td>
                                            );
                                        },
                                        duration: (licenseSession: LicenseSession) => {
                                            const duration = licenseSession.duration?.toFixed(2);

                                            return  (
                                                    <td>
                                                        {t`${duration}min`}
                                                    </td>
                                            );
                                        },
                                        osVersion: (licenseSession: LicenseSession) => {
                                            return  (
                                                    <td>
                                                        {licenseSession.osVersion?.join(', ')}
                                                    </td>
                                            );
                                        },
                                        other: (licenseSession: LicenseSession) =>
                                            <Observer>{() => {
                                                const leaderboardUrl = `${leaderboardBaseURI}/${licenseSession.reportedSessionId}`;

                                                return (
                                                <td>
                                                    <CRow gutters={false}>
                                                        <strong>{t`Leaderboard`}</strong>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <CLink href={leaderboardUrl}
                                                               target="_blank">
                                                            {leaderboardUrl}
                                                        </CLink>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <CLink onClick={() => productsUsage.toggleShowMoreInfo(licenseSession.id)}
                                                               disabled={productsUsage.loading}
                                                               title={t`Toggle more info`}>
                                                            <CIcon name="cil-storage" className="mfe-2"/>
                                                        </CLink>
                                                    </CRow>

                                                    <CCollapse
                                                            show={productsUsage.showMoreInfoLicenseSessionIds.has(licenseSession.id)}>
                                                        <CRow gutters={false}>
                                                            <strong>{t`License key:`}</strong>
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            {licenseSession.licenseKey?.join(', ') || t`Unknown`}
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            <strong>{t`Reported id:`}</strong>
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            {licenseSession.reportedSessionId || t`Unknown`}
                                                        </CRow>

                                                        {licenseSession.location && licenseSession.location.length > 0 &&
                                                        <React.Fragment>
                                                            <CRow gutters={false}>
                                                                <strong>{t`Location:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {licenseSession.location?.join(', ')}
                                                            </CRow>
                                                        </React.Fragment>}

                                                        {licenseSession.ip && licenseSession.ip.length > 0 &&
                                                        <React.Fragment>
                                                            <CRow gutters={false}>
                                                                <strong>{t`Ip:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {licenseSession.ip?.join(', ')}
                                                            </CRow>
                                                        </React.Fragment>}

                                                        {licenseSession.machineid && licenseSession.machineid.length > 0 &&
                                                                <React.Fragment>
                                                                    <CRow gutters={false}>
                                                                        <strong>{t`Machine id:`}</strong>
                                                                    </CRow>

                                                                    <CRow gutters={false}>
                                                                        {licenseSession.machineid?.join(', ')}
                                                                    </CRow>
                                                                </React.Fragment>}
                                                    </CCollapse>
                                                </td>
                                                );
                                            }}</Observer>,
                                        startTimeLocal: (licenseSession: LicenseSession) => (
                                                <td>
                                                    <DateAndTime date={new Date(licenseSession.startTimeLocal)} local={true}/>
                                                </td>
                                        )
                                    }}
                                    underTableSlot={productsUsage.error ? <Error>{productsUsage.error}</Error> : <Pagination loading={productsUsage.loading}
                                                                                                                     pageInfo={productsUsage.pageInfo}
                                                                                                                     onPage={(page) => productsUsage.setPage(page)}/>}/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </React.Fragment>
    );
});

export default ProductReportsView;
import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {License, Maybe, Query} from "../generated/graphql";
import {
    apolloClient,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import LicenseFragment from "../api/graphql/LicenseFragment";

class UserLicensesStore {
    @observable.ref
    public licenses: Maybe<License>[] | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public fetchLicenses() {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query>({
            query: gql`query ($customerId: UUID!) {
                currentUserId,
                allLicenses(
                    orderBy: [IS_REVOKED_ASC, EXPIRATE_AT_UTC_ASC, CREATED_AT_UTC_DESC],
                    filter: {
                        venueByVenueId: {
                            customerId: {
                                equalTo: $customerId
                            }
                        }
                    }) {
                    nodes {
                        ...LicenseFragment
                    }
                }
            },
            ${LicenseFragment}`,
            variables: {
                customerId: this.rootStore.auth.currentUser?.userCustomerByUserId?.customerId
            }
        })).then(result => {
            runInAction(() => {
                this.licenses = result.data.allLicenses?.nodes;
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public reset() {
        this.licenses = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    UserLicensesStore
};
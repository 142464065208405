import {Maybe, PriceCalendarDefinition} from "../generated/graphql";
import React from "react";
import {t} from "ttag";
import {moneyToDisplayStr} from "../utils/MoneyUtils";
import {CRow} from "@coreui/react";

const PriceCalendar: React.FC<{priceCalendar: Maybe<PriceCalendarDefinition>[], priceCurrency: string, label?: string}> = (props) => {
    const dayOfWeekToStr = (dayOfWeek: number | undefined | null) => {
        switch(dayOfWeek){
            case 0: return t`Sunday`;
            case 1: return t`Monday`;
            case 2: return t`Tuesday`;
            case 3: return t`Wednesday`;
            case 4: return t`Thursday`;
            case 5: return t`Friday`;
            case 6: return t`Saturday`;
            default: return t`Unknown`;
        }
    }

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{props.label && props.label}{t`Calendar:`}</strong>
            </CRow>

            {props.priceCalendar.map((calendar, index) => <CRow key={index} gutters={false}>
                {calendar && dayOfWeekToStr(calendar.fromDayOfWeek)}
                {calendar && calendar.fromDayOfWeek !== calendar.toDayOfWeek && " - " + dayOfWeekToStr(calendar.toDayOfWeek)}
                {calendar && t` / ${calendar.fromHour}h - ${calendar.toHour}h`}
                {calendar && `: ${moneyToDisplayStr(calendar.price)}${props.priceCurrency}`}
            </CRow>)}
        </React.Fragment>
    );
};

export default PriceCalendar;
const distinctColors: string[] = [
    "#9556BD",
    "#953F00",
    "#B05B6F",
    "#C86240",
    "#518A87",
    "#3A3F00",
    "#1E6E00",
    "#0089A3",
    "#671190",
    "#C8A1A1",
    "#66E1D3",
    "#6367A9",
    "#89412E",
    "#F4ABAA",
    "#320033",
    "#8D8546",
    "#99ADC0",
    "#7B4F4B",
    "#C8D0F6",
    "#A97399",
    "#66796D",
    "#34362D",
    "#658188",
    "#00C2A0",
    "#FFAA92",
    "#402334",
    "#868E7E",
    "#061203",
    "#FFFFFE",
    "#324E72",
    "#E83000",
    "#A3C8C9",
    "#006C31",
    "#1B4400",
    "#97979E",
    "#456D75",
    "#FF8A9A",
    "#B5D6C3",
    "#1D1702",
    "#7900D7",
    "#BF5650",
    "#013349",
    "#C0B9B2",
    "#FEFFE6",
    "#6C8F7D",
    "#00D891",
    "#788D66",
    "#636375",
    "#997D87",
    "#575329",
    "#FFA0F2",
    "#CCAA35",
    "#A4E804",
    "#353339",
    "#1A3A2A",
    "#A30059",
    "#D16100",
    "#EEC3FF",
    "#29201D",
    "#958A9F",
    "#CCB87C",
    "#AA5199",
    "#837393",
    "#6B3A64",
    "#885578",
    "#F5E1FF",
    "#71BB8C",
    "#DDEFFF",
    "#FF5DA7",
    "#0060CD",
    "#DBD5DD",
    "#886F4C",
    "#9F94F0",
    "#643127",
    "#FFF69F",
    "#391406",
    "#FDE8DC",
    "#895563",
    "#452C2C",
    "#008941",
    "#BC23FF",
    "#9C6966",
    "#64547B",
    "#A079BF",
    "#00FECF",
    "#61615A",
    "#02684E",
    "#FF6832",
    "#98D058",
    "#DA007C",
    "#7D5A44",
    "#EA8B66",
    "#4C6001",
    "#962B75",
    "#A3F3AB",
    "#FFDBE5",
    "#C6005A",
    "#549E79",
    "#000035",
    "#83A485",
    "#A77500",
    "#3A2465",
    "#456648",
    "#5A0007",
    "#3B5DFF",
    "#000000",
    "#D0AC94",
    "#001325",
    "#E704C4",
    "#15A08A",
    "#203B3C",
    "#938A81",
    "#3C3E6E",
    "#6A714A",
    "#FFFF00",
    "#00C6C8",
    "#4FC601",
    "#8FB0FF",
    "#BDC9D2",
    "#83AB58",
    "#FEB2C6",
    "#6D80BA",
    "#453C23",
    "#FF34FF",
    "#0045D2",
    "#FF913F",
    "#772600",
    "#47675D",
    "#6B7900",
    "#02525F",
    "#55813B",
    "#8ADBB4",
    "#A38469",
    "#72418F",
    "#9FA064",
    "#04F757",
    "#0086ED",
    "#A05837",
    "#9695C5",
    "#7ED379",
    "#1E0200",
    "#5EFF03",
    "#C6DC99",
    "#D2DCD5",
    "#5B113C",
    "#C2FF99",
    "#E7AB63",
    "#A3A489",
    "#59738A",
    "#5EBCD1",
    "#78AFA1",
    "#FF90C9",
    "#BA0900",
    "#B88183",
    "#8CD0FF",
    "#004D43",
    "#7E6405",
    "#806C66",
    "#D157A0",
    "#C2FFED",
    "#00005F",
    "#B4A8BD",
    "#6F0062",
    "#D7BFC2",
    "#E773CE",
    "#F4D749",
    "#76912F",
    "#003109",
    "#A74571",
    "#FAD09F",
    "#00846F",
    "#3D4F44",
    "#1CE6FF",
    "#001E09",
    "#374527",
    "#E98176",
    "#B894A6",
    "#5B4534",
    "#75797C",
    "#FF1A59",
    "#0CBD66",
    "#A76F42",
    "#CB7E98",
    "#3B000A",
    "#7A87A1",
    "#00489C",
    "#372101",
    "#8BB400",
    "#D1F7CE",
    "#A88C85",
    "#809693",
    "#B79762",
    "#9B9700",
    "#4B8160",
    "#BEC459",
    "#00B433",
    "#006A66",
    "#943A4D",
    "#3E89BE",
    "#B5F4FF",
    "#E4FFFC",
    "#7A7BFF",
    "#012C58",
    "#5B3213",
    "#BE4700",
    "#797868",
    "#B77B68",
    "#0AA6D8",
    "#BC65E9",
    "#006FA6",
    "#6B002C",
    "#B903AA",
    "#C895C5",
    "#F7C9BF",
    "#CA834E",
    "#7A4900",
    "#494B5A",
    "#DFFB71",
    "#404E55",
    "#51A058",
    "#6A3A4C",
    "#D86A78",
    "#201625",
    "#1BE177",
    "#222800",
    "#513A01",
    "#3B9700",
    "#FF4A46",
    "#5B4E51",
    "#00A6AA",
    "#29607C",
    "#004B28",
    "#0000A6",
    "#D790FF",
    "#0AA3F7",
    "#001C1E",
    "#4A3B53",
    "#FF2F80",
    "#D68E01",
    "#63FFAC",
    "#E20027",
    "#FFB500",
    "#A1C299",
    "#9FB2A4",
    "#CC0744",
    "#A45B02",
    "#DDB6D0",
    "#BCB1E5",
    "#D83D66",
    "#922329",
    "#789EC9",
    "#CFCDAC",
    "#300018",
    "#D20096",
    "#7C6571",
    "#6B94AA"
];

export {
    distinctColors
}
import {
    CButton,
    CCol,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CLabel,
    CPopover,
    CRow,
    CSelect,
    CSpinner,
    CSwitch, CTextarea
} from "@coreui/react";
import React, {useContext} from "react";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import JSON5 from "json5";
import Error from "./Error";
import {StoreContext} from "../context/StoreContext";
import Customers from "./Customers";
import {
    License,
    PriceCalendarDefinition,
    PriceDefinition,
    PriceVariantType,
    RoyaltyDefinition,
    RoyaltyVariantType
} from "../generated/graphql";
import Venues from "./Venues";
import Products from "./Products";
import Currencies from "./Currencies";
import {observer} from "mobx-react-lite";
import PriceVariants from "./PriceVariants";
import RoyaltyVariants from "./RoyaltyVariants";
import {moneyToDisplayStr} from "../utils/MoneyUtils";

interface EditLicenseProps {
    license?: License;
    loading?: boolean;
    error?: string;
    onSubmit: (
        price: PriceDefinition,
        royalty: RoyaltyDefinition,
        priceCalendar?: PriceCalendarDefinition[],
        venueId?: string,
        productId?: string,
        licenseKey?: string,
        expirateAtUtc?: string,
        isRevoked?: boolean
    ) => void;
    onCancel?: () => void;
    isVertical?: boolean;
    hideCancel?: boolean;
}

interface LicenseSettingsInput {
    priceCurrency: string;
    price: string;
    priceCalendar: string;
    priceVariant: PriceVariantType;
    royaltyVariant: RoyaltyVariantType;
    royaltyCurrency?: string;
    royalty?: string;
    royaltyShare?: string;
    licenseKey?: string;
    expirateAtYear?: string;
    expirateAtMonth?: string;
    customerId?: string;
    venueId?: string;
    productId?: string;
    isrevoked?: boolean;
}

const EditLicense: React.FC<EditLicenseProps> = observer((props: EditLicenseProps) => {
    const store = useContext(StoreContext);
    const {activeCustomers, activeVenues, products} = store;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const {register, handleSubmit, errors, watch, getValues, reset, setValue} = useForm<LicenseSettingsInput>({
        defaultValues: {
            priceCurrency: props.license?.price.priceCurrency || "RUB",
            price: (props.license?.price.price && moneyToDisplayStr(props.license?.price.price)) || "0",
            priceCalendar: props.license?.priceCalendar && props.license?.priceCalendar.length > 0 ? JSON5.stringify(props.license?.priceCalendar).replaceAll(",__typename:'PriceCalendarDefinition'", "") : "",
            priceVariant: props.license?.price.priceVariant || PriceVariantType.PerPlayer,
            royaltyVariant: props.license?.royalty.royaltyVariant || RoyaltyVariantType.PerRevenueShare,
            royaltyCurrency: props.license?.royalty.royaltyCurrency || props.license?.price.priceCurrency || "RUB",
            royalty: (props.license?.royalty.royalty && moneyToDisplayStr(props.license?.royalty.royalty)) || "0",
            royaltyShare: (props.license?.royalty.royaltyShare && (+props.license?.royalty.royaltyShare * 100).toFixed(2)) || "0",
            expirateAtYear: (currentYear + 1).toString(),
            expirateAtMonth: currentMonth.toString(),
            isrevoked: !!props.license?.isRevoked
        }
    });

    const onSubmit = (data: LicenseSettingsInput) => {
        const expirateAtUtc = (data.expirateAtYear && data.expirateAtMonth) ?
                new Date(Date.UTC(+data.expirateAtYear, +data.expirateAtMonth - 1, 1)).toISOString() : undefined;

        props.onSubmit(
                {
                    price: data.price,
                    priceCurrency: data.priceCurrency,
                    priceVariant: data.priceVariant
                },
                {
                    royalty: data.royalty || "0",
                    royaltyVariant: data.royaltyVariant,
                    royaltyCurrency: data.royaltyCurrency || data.priceCurrency,
                    royaltyShare: (data.royaltyShare && (+data.royaltyShare / 100).toFixed(4)) || "0"
                },
                !data.priceCalendar || data.priceCalendar.trim().length === 0 ?
                        undefined : JSON5.parse(data.priceCalendar) as PriceCalendarDefinition[],
                data.venueId,
                data.productId,
                data.licenseKey?.trim(),
                expirateAtUtc,
                data.isrevoked
        );
        reset();
    };

    const validatePriceCalendar = (val: string): boolean => {
        try {
            if (val.trim().length === 0) {
                return true;
            }

            const parsed = JSON5.parse(val) as PriceCalendarDefinition[];
            if (parsed.length <= 0) {
                return false;
            }

            return parsed.every(definition =>
                    typeof(definition.fromDayOfWeek) === "number" &&
                    definition.fromDayOfWeek >= 0 &&
                    typeof(definition.toDayOfWeek) === "number" &&
                    definition.toDayOfWeek <= 6 &&
                    typeof(definition.fromHour) === "number" &&
                    definition.fromHour >= 0 &&
                    typeof(definition.toHour) === "number" &&
                    definition.toHour <= 23 &&
                    definition.fromDayOfWeek <= definition.toDayOfWeek &&
                    definition.fromHour <= definition.toHour &&
                    (typeof(definition.price) === "number" || !Number.isNaN(+definition.price))
            );
        } catch (e) {
            return false;
        }
    };

    const customerBlock = !props.license && <React.Fragment>
        <CLabel htmlFor="customerId">{t`Customer`}</CLabel>
        <CPopover content={activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length > 0 ? t`Required` : t`No active customers`)} placement="right"
                  advancedOptions={{onShow: () => !!errors.customerId || !!activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length <= 0)}}>
            <CSelect name="customerId"
                     innerRef={register({required: true})}
                     invalid={!!errors.customerId}
                     onChange={() => activeVenues.setCustomerId(getValues("customerId"))}>
                <Customers/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const venueBlock = !props.license && <React.Fragment>
        <CLabel htmlFor="venueId">{t`Venue`}</CLabel>
        <CPopover content={activeVenues.error || (activeVenues.activeVenues && activeVenues.activeVenues.length > 0 ? t`Required` : t`No active venues`)} placement="right"
                  advancedOptions={{onShow: () => !!errors.venueId || !!activeVenues.error || (activeVenues.activeVenues && activeVenues.activeVenues.length <= 0)}}>
            <CSelect name="venueId"
                     innerRef={register({required: true})}
                     invalid={!!errors.venueId}>
                <Venues/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const productBlock = !props.license && <React.Fragment>
        <CLabel htmlFor="productId">{t`Product`}</CLabel>
        <CPopover content={products.error || (products.activeProducts && products.activeProducts.length > 0 ? t`Required` : t`No active products`)} placement="right"
                  advancedOptions={{onShow: () => !!errors.productId || !!products.error || (products.activeProducts && products.activeProducts.length <= 0)}}>
            <CSelect name="productId"
                     innerRef={register({required: true})}
                     invalid={!!errors.productId}>
                <Products/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const priceVariantBlock = <React.Fragment>
        <CLabel htmlFor="priceVariant">{t`Price type`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.priceVariant}}>
            <CSelect name="priceVariant"
                     innerRef={register({required: true})}
                     invalid={!!errors.priceVariant}>
                <PriceVariants/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const priceCurrencyBlock = <React.Fragment>
        <CLabel htmlFor="priceCurrency">{t`Price currency`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.priceCurrency}}>
            <CSelect name="priceCurrency"
                     innerRef={register({required: true})}
                     invalid={!!errors.priceCurrency}
                     onChange={() => setValue("royaltyCurrency", getValues("priceCurrency"))}>
                <Currencies/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const priceBlock = <React.Fragment>
        <CLabel htmlFor="price">{t`Price`}</CLabel>
        <CPopover content={t`Required, example 12345.67`} placement="right"
                  advancedOptions={{onShow: () => !!errors.price}}>
            <CInputGroup>
                <CInput name="price" type="text"
                        innerRef={register({required: true, pattern: /^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/g})}
                        invalid={!!errors.price}/>
                <CInputGroupAppend>
                    <CInputGroupText>{watch("priceCurrency")}</CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const priceCalendarBlock = <React.Fragment>
        <CLabel htmlFor="priceCalendar">{t`Price calendar`}</CLabel>
        <CPopover content={t`Example [{fromDayOfWeek: 6, toDayOfWeek: 6, fromHour: 11, toHour: 11, price: 1200}, ...], where day of week is 0-6, Sunday is 0`} placement="right"
                  advancedOptions={{onShow: () => !!errors.priceCalendar}}>
            <CTextarea name="priceCalendar"
                       rows={6}
                       innerRef={register({validate: val => validatePriceCalendar(val)})}
                       invalid={!!errors.priceCalendar}/>
        </CPopover>
    </React.Fragment>;

    const royaltyVariantBlock = <React.Fragment>
        <CLabel htmlFor="royaltyVariant">{t`Royalty type`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royaltyVariant}}>
            <CSelect name="royaltyVariant"
                     innerRef={register({required: true})}
                     invalid={!!errors.royaltyVariant}
                     onChange={() => getValues("royaltyVariant") !== RoyaltyVariantType.PerRevenueShare ? true : setValue("royaltyCurrency", getValues("priceCurrency"))}>
                <RoyaltyVariants/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const royaltyCurrencyBlock = <React.Fragment>
        <CLabel htmlFor="royaltyCurrency">{t`Royalty currency`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royaltyCurrency}}>
            <CSelect name="royaltyCurrency"
                     innerRef={register({required: true})}
                     invalid={!!errors.royaltyCurrency}>
                <Currencies/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const royaltyBlock = watch("royaltyVariant") !== RoyaltyVariantType.PerRevenueShare && <React.Fragment>
        <CLabel htmlFor="royalty">{t`Royalty`}</CLabel>
        <CPopover content={t`Required, example 12345.67`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royalty}}>
            <CInputGroup>
                <CInput name="royalty" type="text"
                        innerRef={register({required: true, pattern: /^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/})}
                        invalid={!!errors.royalty}/>
                <CInputGroupAppend>
                    <CInputGroupText>{watch("royaltyCurrency")}</CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const royaltyShareBlock = watch("royaltyVariant") === RoyaltyVariantType.PerRevenueShare && <React.Fragment>
        <CLabel htmlFor="royaltyShare">{t`Royalty (%)`}</CLabel>
        <CPopover content={t`Required, example 15.65`} placement="right"
                  advancedOptions={{onShow: () => !!errors.royaltyShare}}>
            <CInputGroup>
                <CInput name="royaltyShare" type="text"
                        innerRef={register({required: true, pattern: /^(([1-9][0-9]?)|0)([.][0-9]{1,2})?$/})}
                        invalid={!!errors.royaltyShare}/>
                <CInputGroupAppend>
                    <CInputGroupText>%</CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        </CPopover>
    </React.Fragment>;

    const licenseKeyBlock = !props.license && <React.Fragment>
        <CLabel htmlFor="licenseKey">{t`License key`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.licenseKey}}>
            <CInput name="licenseKey" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.licenseKey}/>
        </CPopover>
    </React.Fragment>;

    const expirateAtYearBlock = !props.license && <React.Fragment>
        <CLabel htmlFor="expirateAtYear">{t`Expires at (year)`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.expirateAtYear}}>
            <CSelect name="expirateAtYear"
                     innerRef={register({required: true})}
                     invalid={!!errors.expirateAtYear}>
                <option value={currentYear}>{currentYear}</option>
                <option value={currentYear + 1}>{currentYear + 1}</option>
                <option value={currentYear + 2}>{currentYear + 2}</option>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const expirateAtMonthBlock = !props.license && <React.Fragment>
        <CLabel htmlFor="expirateAtMonth">{t`Expires at (month)`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.expirateAtMonth}}>
            <CSelect name="expirateAtMonth"
                     innerRef={register({required: true})}
                     invalid={!!errors.expirateAtMonth}>
                <option value="1">{t`Jan`}</option>
                <option value="2">{t`Feb`}</option>
                <option value="3">{t`Mar`}</option>
                <option value="4">{t`Apr`}</option>
                <option value="5">{t`May`}</option>
                <option value="6">{t`Jun`}</option>
                <option value="7">{t`Jul`}</option>
                <option value="8">{t`Aug`}</option>
                <option value="9">{t`Sep`}</option>
                <option value="10">{t`Oct`}</option>
                <option value="11">{t`Nov`}</option>
                <option value="12">{t`Dec`}</option>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const disabledBlock = props.license && <React.Fragment>
        <CLabel htmlFor="isrevoked" style={{width: "100%"}}>{t`Revoked`}</CLabel>
        <CSwitch name="isrevoked"
                 innerRef={register()}
                 shape="pill"
                 color="secondary"/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit" disabled={!!props.loading}>
        {props.license ? t`Save` : t`Create`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = !props.hideCancel &&
            <CButton color="secondary" className="px-4 ml-2" type="reset" onClick={props.onCancel}>{jt`Cancel`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        {customerBlock && <CRow>
            {customerBlock}
        </CRow>}

        {venueBlock && <CRow>
            {venueBlock}
        </CRow>}

        {productBlock && <CRow>
            {productBlock}
        </CRow>}

        <CRow>
            {priceVariantBlock}
        </CRow>

        <CRow>
            {priceCurrencyBlock}
        </CRow>

        <CRow>
            {priceBlock}
        </CRow>

        <CRow>
            {priceCalendarBlock}
        </CRow>

        <CRow>
            {royaltyVariantBlock}
        </CRow>

        {royaltyCurrencyBlock && <CRow className={{"d-none": watch("royaltyVariant") === RoyaltyVariantType.PerRevenueShare}}>
            {royaltyCurrencyBlock}
        </CRow>}

        {royaltyShareBlock && <CRow>
            {royaltyShareBlock}
        </CRow>}

        {royaltyBlock && <CRow>
            {royaltyBlock}
        </CRow>}

        {licenseKeyBlock && <CRow>
            {licenseKeyBlock}
        </CRow>}

        {expirateAtYearBlock && <CRow>
            {expirateAtYearBlock}
        </CRow>}

        {expirateAtMonthBlock && <CRow>
            {expirateAtMonthBlock}
        </CRow>}

        {disabledBlock && <CRow>
            {disabledBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            {customerBlock && <CCol>
                {customerBlock}
            </CCol>}

            {venueBlock && <CCol>
                {venueBlock}
            </CCol>}

            {productBlock && <CCol>
                {productBlock}
            </CCol>}

            <CCol>
                {priceVariantBlock}
            </CCol>

            <CCol>
                {priceCurrencyBlock}
            </CCol>

            <CCol>
                {priceBlock}
            </CCol>

            <CCol>
                {priceCalendarBlock}
            </CCol>

            <CCol>
                {royaltyVariantBlock}
            </CCol>

            {royaltyCurrencyBlock && <CCol className={{"d-none": watch("royaltyVariant") === RoyaltyVariantType.PerRevenueShare}}>
                {royaltyCurrencyBlock}
            </CCol>}

            {royaltyShareBlock && <CCol>
                {royaltyShareBlock}
            </CCol>}

            {royaltyBlock && <CCol>
                {royaltyBlock}
            </CCol>}

            {licenseKeyBlock && <CCol>
                {licenseKeyBlock}
            </CCol>}

            {expirateAtYearBlock && <CCol>
                {expirateAtYearBlock}
            </CCol>}

            {expirateAtMonthBlock && <CCol>
                {expirateAtMonthBlock}
            </CCol>}

            {disabledBlock && <CCol>
                {disabledBlock}
            </CCol>}

            <CCol>
                {submitBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            {elements}

            {props.error && <CRow><Error>{props.error}</Error></CRow>}
        </CForm>
    );
});

export default EditLicense;
import gql from "graphql-tag";

const CurrentUserFragment = gql`fragment CurrentUserFragment on User {
    id,
    role,
    name,
    locale,
    timezone,
    timezoneByTimezone {
        utcSecondsOffset
    },
    userCustomerByUserId {
        customerId,
        customerByCustomerId {
            id,
            name,
            isArchived
        }
    }
}`;

export default CurrentUserFragment;
import {CButton, CCol, CForm, CInput, CLabel, CPopover, CRow, CSpinner, CSwitch} from "@coreui/react";
import React from "react";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import {Product} from "../generated/graphql";
import Error from "./Error";

interface EditProductProps {
    product?: Product;
    loading?: boolean;
    error?: string;
    onSubmit: (
        names: {[locale: string]: string},
        imgBase64?: string,
        isGame?: boolean,
        isArchived?: boolean
    ) => void;
    onCancel?: () => void;
    isVertical?: boolean;
    hideCancel?: boolean;
}

interface ProductSettingsInput {
    name_en: string;
    name_ru: string;
    imgbase64: FileList;
    isgame?: boolean;
    isarchived?: boolean;
}

const EditProduct = (props: EditProductProps) => {
    const {register, handleSubmit, errors, reset} = useForm<ProductSettingsInput>({
        defaultValues: {
            name_en: props.product?.namesAll["en"],
            name_ru: props.product?.namesAll["ru"],
            isgame: !!props.product?.isGame,
            isarchived: !!props.product?.isArchived
        }
    });

    const onSubmit = (data: ProductSettingsInput) => {
        const imgReader = (new FileReader());

        imgReader.onload = function(uploadEvent) {
            let image = "";

            if (uploadEvent?.target?.result && typeof(uploadEvent.target.result) === "string") {
                image = uploadEvent.target.result;
            }

            props.onSubmit(
                {"en": data.name_en, "ru": data.name_ru},
                    image,
                data.isgame,
                data.isarchived
            );
            reset();
        };

        if (data.imgbase64 && data.imgbase64[0]) {
            imgReader.readAsDataURL(data.imgbase64[0]);
        } else {
            props.onSubmit(
                    {"en": data.name_en, "ru": data.name_ru},
                    undefined,
                    data.isgame,
                    data.isarchived
            );
            reset();
        }
    };

    const productnameEnBlock = <React.Fragment>
        <CLabel htmlFor="name_en">{t`Product name (en)`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.name_en}}>
            <CInput name="name_en" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.name_en}/>
        </CPopover>
    </React.Fragment>;

    const productnameRuBlock = <React.Fragment>
        <CLabel htmlFor="name_ru">{t`Product name (ru)`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.name_ru}}>
            <CInput name="name_ru" type="text"
                    innerRef={register({required: true})}
                    invalid={!!errors.name_ru}/>
        </CPopover>
    </React.Fragment>;

    const imgBase64Block = <React.Fragment>
        <CLabel htmlFor="imgbase64">{t`Image`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.imgbase64}}>
            <CInput name="imgbase64" type="file"
                    accept="image/*"
                    innerRef={register({required: !props.product})}
                    invalid={!!errors.imgbase64}/>
        </CPopover>
    </React.Fragment>;

    const isgameBlock = props.product && <React.Fragment>
        <CLabel htmlFor="isgame" style={{width: "100%"}}>{t`Is game`}</CLabel>
        <CSwitch name="isgame"
                 innerRef={register()}
                 shape="pill"
                 color="success"/>
    </React.Fragment>;

    const disabledBlock = props.product && <React.Fragment>
        <CLabel htmlFor="isarchived" style={{width: "100%"}}>{t`Archived`}</CLabel>
        <CSwitch name="isarchived"
                 innerRef={register()}
                 shape="pill"
                 color="danger"/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit" disabled={!!props.loading}>
        {props.product ? t`Save` : t`Create`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = !props.hideCancel &&
            <CButton color="secondary" className="px-4 ml-2" type="reset" onClick={props.onCancel}>{jt`Cancel`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        <CRow>
            {productnameEnBlock}
        </CRow>

        <CRow>
            {productnameRuBlock}
        </CRow>

        <CRow>
            {imgBase64Block}
        </CRow>

        {isgameBlock && <CRow>
            {isgameBlock}
        </CRow>}

        {disabledBlock && <CRow>
            {disabledBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            <CCol>
                {productnameEnBlock}
            </CCol>

            <CCol>
                {productnameRuBlock}
            </CCol>

            <CCol>
                {imgBase64Block}
            </CCol>

            {isgameBlock && <CCol>
                {isgameBlock}
            </CCol>}

            {disabledBlock && <CCol>
                {disabledBlock}
            </CCol>}

            <CCol>
                {submitBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            {elements}

            {props.error && <CRow><Error>{props.error}</Error></CRow>}
        </CForm>
    );
}

export default EditProduct;
import {CBadge, CButton, CLabel, CPopover, CRow, CSelect} from "@coreui/react";
import React, {ReactNode, useContext} from "react";
import {t} from "ttag";
import Error from "./Error";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../context/StoreContext";
import {
    LicenseV2Claim,
    LicenseV2ClaimId,
    LicenseV2ClaimValue,
    LicenseV2NumberClaimValue,
    LicenseV2PriceCalendarItemClaimValue,
    LicenseV2PriceClaimValue,
    LicenseV2ProductOptionBooleanClaimValue,
    LicenseV2ProductOptionNumberClaimValue,
    LicenseV2ProductOptionStringClaimValue,
    LicenseV2RoyaltyClaimValue,
    LicenseV2StringClaimValue,
    toPriceCalendar,
    toPriceDefinition,
    toRoyaltyDefinition
} from "../api/LicenseV2Claims";
import {
    getLicenseV2ClaimIdName,
    licenseV2BooleanClaimValueToString,
    LicenseV2ClaimIdOrder,
    licenseV2NumberClaimValueToString,
    licenseV2StringClaimValueToString,
    validateLicenseV2Claim
} from "../utils/LicenseV2ClaimUtils";
import Price from "./Price";
import Royalty from "./Royalty";
import PriceCalendar from "./PriceCalendar";
import Products from "./Products";
import EditLicenseV2PriceClaim from "./EditLicenseV2PriceClaim";
import CIcon from "@coreui/icons-react";
import EditLicenseV2RoyaltyClaim from "./EditLicenseV2RoyaltyClaim";
import EditLicenseV2AllowProductsClaim from "./EditLicenseV2AllowProductsClaim";
import EditLicenseV2NumberClaim from "./EditLicenseV2NumberClaim";
import EditLicenseV2PriceCalendarClaim from "./EditLicenseV2PriceCalendarClaim";
import EditLicenseV2ProductOptionClaim from "./EditLicenseV2ProductOptionClaim";
import EditLicenseV2StringClaim from "./EditLicenseV2StringClaim";

interface LicenseV2ClaimsProps {
    claims: LicenseV2Claim[];
    editClaim?: LicenseV2Claim;
    editClaimIndex?: number;
    onAdd?: (id: LicenseV2ClaimId, productId?: string) => void;
    onEdit?: (index: number) => void;
    onSave?: () => void;
    onCancel?: () => void;
    onRemove?: () => void;
    onSetEditClaim?: (claim: LicenseV2Claim) => void;
}

const LicenseV2Claims = observer((props: LicenseV2ClaimsProps) => {
    const store = useContext(StoreContext);
    const {products} = store;

    if (products.error) {
        return <CRow gutters={false}><Error>{products.error}</Error></CRow>;
    }

    const onSetEditClaimValue = (value: LicenseV2ClaimValue | undefined) => {
        if (!props.onSetEditClaim || !props.editClaim) {
            return;
        }

        if (value !== undefined && Array.isArray(value)) {
            props.onSetEditClaim({
                ...props.editClaim,
                value
            } as LicenseV2Claim);
        } else if (value !== undefined) {
            props.onSetEditClaim({
                ...props.editClaim,
                value: {
                    ...props.editClaim?.value,
                    ...value
                }
            } as LicenseV2Claim);
        } else {
            props.onSetEditClaim({
                ...props.editClaim,
                value: undefined
            } as LicenseV2Claim);
        }
    }

    const claims = props.claims;

    const anyPrice = claims && claims.find(c => c.id === LicenseV2ClaimId.PRICE)?.value as LicenseV2PriceClaimValue;

    const availableLicenseV2ClaimIds: {id: LicenseV2ClaimId, label: string, productIds?: (string | undefined)[]}[] = [];

    let availableProductIdsForEditClaim: (string | undefined)[] | undefined = undefined;

    LicenseV2ClaimIdOrder.forEach(id => {
        const isUsed = claims.find(claim => claim.id === id);

        const supportProduct = !(
            id === LicenseV2ClaimId.ALLOW_MULTISESSIONS ||
            id === LicenseV2ClaimId.ALLOW_SHAREDSPACES ||
            id === LicenseV2ClaimId.ALLOW_PRODUCTS ||
            id === LicenseV2ClaimId.ALLOW_MACHINES
        );

        const UnlimitedProductUsage = id === LicenseV2ClaimId.PRODUCT_OPTION_STRING ||
            id === LicenseV2ClaimId.PRODUCT_OPTION_NUMBER ||
            id === LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN;

        if (isUsed && !supportProduct) {
            return;
        }

        const availableProductIds = !supportProduct ? undefined : products.products && products.products
            .filter(p => !!p && (UnlimitedProductUsage || !claims.find(claim => claim.id === id && claim.product_id === p.id)))
            .map(p => p?.id);

        if ((!isUsed || UnlimitedProductUsage) && availableProductIds) {
            availableProductIds.unshift(undefined);
        }

        if (availableProductIds && availableProductIds.length > 0) {
            availableLicenseV2ClaimIds.push({
                id,
                label: getLicenseV2ClaimIdName(id),
                productIds: availableProductIds
            });

            if (props.editClaim && props.editClaim.id === id) {
                availableProductIdsForEditClaim = props.editClaimIndex === undefined ? availableProductIds : [
                    ...availableProductIds,
                    props.editClaim.product_id || undefined
                ];
            }
        } else if (!isUsed && !supportProduct) {
            availableLicenseV2ClaimIds.push({
                id,
                label: getLicenseV2ClaimIdName(id)
            });

            availableProductIdsForEditClaim = undefined;
        }
    });

    const renderClaim = (claim: LicenseV2Claim, index: number): ReactNode => {
        if (!validateLicenseV2Claim(claim)) {
            return <React.Fragment key={index}><CRow gutters={false}><Error>{claim ? t`Wrong ${claim.id} value` : t`Wrong claim value`}</Error></CRow></React.Fragment>
        }

        let claimComponent;

        let productName = claim.product_id && products.products &&
                products.products.find(p => p && p.id === claim.product_id)?.name;

        productName = productName ? productName + " | " : "";

        const claimName = getLicenseV2ClaimIdName(claim.id);

        switch (claim.id) {
            case LicenseV2ClaimId.PRICE:
                claimComponent = <Price price={toPriceDefinition(claim.value as LicenseV2PriceClaimValue)} label={productName}/>;
                break;
            case LicenseV2ClaimId.ROYALTY:
                claimComponent = <Royalty royalty={toRoyaltyDefinition(claim.value as LicenseV2RoyaltyClaimValue)} label={productName}/>;
                break;
            case LicenseV2ClaimId.PRICE_CALENDAR:
                claimComponent = <PriceCalendar priceCalendar={toPriceCalendar(claim.value as LicenseV2PriceCalendarItemClaimValue[])}
                                                priceCurrency={anyPrice ? anyPrice.price_currency : t`Unknown`}
                                                label={productName}/>;
                break;
            case LicenseV2ClaimId.ALLOW_PRODUCTS: {
                const allowProducts = products.products && products.products
                        .filter(p => p && (claim.value as LicenseV2StringClaimValue)?.exact.includes(p.id));

                claimComponent = allowProducts && (
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{`${claimName} (${allowProducts.length}):`}</strong>
                            </CRow>

                            <CRow gutters={false}>
                                {allowProducts.length > 0 ? allowProducts.map(p => p && p.name).join(", ") : t`None`}
                            </CRow>
                        </React.Fragment>
                );
                break;
            }
            case LicenseV2ClaimId.ALLOW_PRODUCT_VARIANTS:
            case LicenseV2ClaimId.PREFERRED_PRODUCT_VARIANTS: {
                const variants = (claim.value as LicenseV2StringClaimValue)?.exact;

                claimComponent = variants && (
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{`${productName}${claimName} (${variants.length}):`}</strong>
                            </CRow>

                            <CRow gutters={false}>{licenseV2StringClaimValueToString(claim.value as LicenseV2StringClaimValue)}</CRow>
                        </React.Fragment>
                );
                break;
            }
            case LicenseV2ClaimId.ALLOW_MULTISESSIONS:
            case LicenseV2ClaimId.ALLOW_SHAREDSPACES:
                claimComponent =
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{claimName}</strong>
                            </CRow>
                        </React.Fragment>;
                break;
            case LicenseV2ClaimId.PLAYERS:
                claimComponent =
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{productName}{claimName}</strong>
                            </CRow>

                            <CRow gutters={false}>{licenseV2NumberClaimValueToString(claim.value as LicenseV2NumberClaimValue)}</CRow>
                        </React.Fragment>;
                break;
            case LicenseV2ClaimId.PRODUCT_OPTION_STRING: {
                const value = claim.value as LicenseV2ProductOptionStringClaimValue;

                claimComponent = value?.exact && (
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{`${productName}${claimName} ${value.option_id} (${value?.exact.length}):`}</strong>
                            </CRow>

                            <CRow gutters={false}>{licenseV2StringClaimValueToString(value)}</CRow>
                        </React.Fragment>
                );
                break;
            }
            case LicenseV2ClaimId.PRODUCT_OPTION_NUMBER: {
                const value = claim.value as LicenseV2ProductOptionNumberClaimValue;

                claimComponent =
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{productName}{claimName} {value.option_id}</strong>
                            </CRow>

                            <CRow gutters={false}>{licenseV2NumberClaimValueToString(value)}</CRow>
                        </React.Fragment>;
                break;
            }
            case LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN: {
                const value = claim.value as LicenseV2ProductOptionBooleanClaimValue;

                claimComponent =
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{productName}{claimName} {value.option_id}</strong>
                            </CRow>

                            <CRow gutters={false}>{licenseV2BooleanClaimValueToString(value)}</CRow>
                        </React.Fragment>;
                break;
            }
            case LicenseV2ClaimId.ALLOW_MACHINES: {
                claimComponent = (
                        <React.Fragment>
                            <CRow gutters={false}>
                                <strong>{claimName}</strong>
                            </CRow>

                            <CRow gutters={false}>{licenseV2StringClaimValueToString(claim.value as LicenseV2StringClaimValue)}</CRow>
                        </React.Fragment>
                );
                break;
            }
            default:
                claimComponent = <CRow gutters={false}><Error>{t`Unknown ${claim.id}`}</Error></CRow>;
        }

        return (
                <React.Fragment key={`${index}_${claim.id}_${claim.product_id}`}>
                    <div className={`${index < claims.length - 1 ? "mb-1" : ""}`}
                         onClick={() => props.onEdit && props.onEdit(index)}
                         style={{cursor: props.onEdit ? "pointer": ""}}
                         title={props.onEdit ? t`Edit claim` : ""}>
                        {claimComponent}
                    </div>
                </React.Fragment>
        );
    };

    const renderEditClaim = (): ReactNode => {
        if (!props.editClaim) {
            return;
        }

        const isValid = validateLicenseV2Claim(props.editClaim);
        const claimName = getLicenseV2ClaimIdName(props.editClaim.id);

        let claimComponent;

        switch (props.editClaim.id) {
            case LicenseV2ClaimId.PRICE:
                claimComponent = <EditLicenseV2PriceClaim editClaim={props.editClaim} onSetEditClaimValue={onSetEditClaimValue}/>;
                break;
            case LicenseV2ClaimId.PRICE_CALENDAR:
                claimComponent = <EditLicenseV2PriceCalendarClaim editClaim={props.editClaim} onSetEditClaimValue={onSetEditClaimValue}/>
                break;
            case LicenseV2ClaimId.ROYALTY:
                claimComponent = <EditLicenseV2RoyaltyClaim editClaim={props.editClaim} onSetEditClaimValue={onSetEditClaimValue}/>;
                break;
            case LicenseV2ClaimId.ALLOW_PRODUCTS:
                claimComponent = <EditLicenseV2AllowProductsClaim editClaim={props.editClaim} onSetEditClaimValue={onSetEditClaimValue}/>;
                break;
            case LicenseV2ClaimId.PLAYERS:
                claimComponent = <EditLicenseV2NumberClaim label={claimName}
                                                           editClaimValue={props.editClaim.value as LicenseV2NumberClaimValue}
                                                           onSetEditClaimValue={onSetEditClaimValue}/>;
                break;
            case LicenseV2ClaimId.ALLOW_MULTISESSIONS:
            case LicenseV2ClaimId.ALLOW_SHAREDSPACES:
            case LicenseV2ClaimId.ALLOW_MACHINES:
                claimComponent =
                    <CRow gutters={false}>
                        <strong>{claimName}</strong>
                    </CRow>;
                break;
            case LicenseV2ClaimId.PREFERRED_PRODUCT_VARIANTS:
            case LicenseV2ClaimId.ALLOW_PRODUCT_VARIANTS:
                claimComponent = <EditLicenseV2StringClaim label={claimName}
                                                           editClaimValue={props.editClaim.value as LicenseV2StringClaimValue}
                                                           onSetEditClaimValue={onSetEditClaimValue}/>
                break;
            case LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN:
            case LicenseV2ClaimId.PRODUCT_OPTION_NUMBER:
            case LicenseV2ClaimId.PRODUCT_OPTION_STRING:
                claimComponent = <EditLicenseV2ProductOptionClaim editClaim={props.editClaim} onSetEditClaimValue={onSetEditClaimValue}/>;
                break;
        }

        return (
            <div key={`${props.editClaim.id}_${props.editClaimIndex}`} style={{border: "1px solid #d8dbe0"}} className="p-2">
                {availableProductIdsForEditClaim && availableProductIdsForEditClaim.length > 0 && <React.Fragment>
                    <CRow gutters={false}>
                        <strong>{t`For product`}</strong>
                    </CRow>

                    <CRow gutters={false}>
                        <CPopover content={products.error || (products.activeProducts && products.activeProducts.length > 0 ? t`Required` : t`No active products`)} placement="right"
                                  advancedOptions={{onShow: () => !!products.error || (products.activeProducts && products.activeProducts.length <= 0)}}>
                            <CSelect value={props.editClaim.product_id || undefined}
                                    onChange={(event) => props.onSetEditClaim && props.onSetEditClaim({
                                        ...props.editClaim,
                                        product_id: (event.target as HTMLSelectElement).value
                                    } as LicenseV2Claim)}>

                                {availableProductIdsForEditClaim.includes(undefined) && <option value={undefined}>{t`All`}</option>}

                                <Products filter={product => !!availableProductIdsForEditClaim?.includes(product.id)}/>
                            </CSelect>
                        </CPopover>
                    </CRow>
                </React.Fragment>}

                {claimComponent}

                <CRow gutters={false} className="mt-2">
                    {props.onSave && <CButton color="primary"
                             className={{"px-4": true}}
                             type="button"
                             onClick={props.onSave}
                             disabled={!isValid}
                             title={isValid ? "" : t`Invalid value`}>
                        {t`Save`}
                    </CButton>}

                    {props.onCancel && <CButton color="secondary"
                             className="px-4 ml-2"
                             type="button"
                             onClick={props.onCancel}>
                        {t`Cancel`}
                    </CButton>}

                    {props.onRemove && props.editClaimIndex !== undefined && <CButton color="danger"
                                                                                      className="px-4 ml-2"
                                                                                      type="button"
                                                                                      onClick={props.onRemove}>
                        <CIcon name="cil-trash"/>
                    </CButton>}
                </CRow>
            </div>
        );
    };

    return (
        <React.Fragment>
            {!props.editClaim && props.onAdd && availableLicenseV2ClaimIds.length > 0 && <CRow gutters={false}>
                <CLabel style={{width: "100%"}}><strong>{t`Claims`}</strong>
                    {t` (available:`}
                    {availableLicenseV2ClaimIds.map(a =>
                            <CBadge color="success"
                                    style={{"cursor": "pointer"}}
                                    title={t`Add claim`}
                                    className="ml-2"
                                    key={`${a.id}_${a.productIds?.join('_')}`}
                                    onClick={(e: Event) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (a && props.onAdd) {
                                            props.onAdd(a.id, a.productIds && a.productIds[0] || undefined);
                                        }
                                    }}>{a.label}</CBadge>
                    )})
                </CLabel>
            </CRow>}

            {props.editClaimIndex === undefined && !!props.editClaim && renderEditClaim()}

            {claims && claims.map((claim: LicenseV2Claim, index) => {
                if (props.editClaimIndex !== undefined && index === props.editClaimIndex) {
                    return renderEditClaim();
                } else {
                    return renderClaim(claim, index);
                }
            })}
        </React.Fragment>
    );
});

export default LicenseV2Claims;
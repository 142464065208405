import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {CCard, CCardBody, CCardHeader, CCol, CRow, CSpinner} from "@coreui/react";
import {t} from "ttag";
import {CChartLine} from "@coreui/react-chartjs";
import Error from "./Error";
import {CompareType} from "../api/LicenseSessionsApi";
import {defaultChartOptions} from "../utils/ChartUtils";
import {UserRoleType} from "../generated/graphql";

const FinancialReportsCharts = observer(() => {
    const store = useContext(StoreContext);
    const {licenseSessionsAgg, reportsFilter, auth} = store;

    return licenseSessionsAgg.error || licenseSessionsAgg.loading ? (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`Charts`}
                    </CCardHeader>

                    <CCardBody>
                        {licenseSessionsAgg.error && <Error>{licenseSessionsAgg.error}</Error>}
                        {licenseSessionsAgg.loading && <CSpinner color="primary" style={{width:'4rem', height:'4rem'}}/>}
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    ): (
        <React.Fragment>
            {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowTotalActiveCustomers(!licenseSessionsAgg.showTotalActiveCustomers)}>
                            {t`Total active customers`}
                        </CCardHeader>

                        {licenseSessionsAgg.showTotalActiveCustomers &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggTotalActiveCustomersDatasets}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>}

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowTotalSessions(!licenseSessionsAgg.showTotalSessions)}>
                            {t`Total sessions`}
                        </CCardHeader>

                        {licenseSessionsAgg.showTotalSessions &&
                            <CCardBody>
                                <CChartLine
                                        style={{height: '250px', marginTop: '0px'}}
                                        datasets={licenseSessionsAgg.aggTotalSessionsDatasets}
                                        options={defaultChartOptions}
                                        labels={licenseSessionsAgg.aggDateLabels}/>
                            </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowPlayers(!licenseSessionsAgg.showPlayers)}>
                            {t`Players`}
                        </CCardHeader>

                        {licenseSessionsAgg.showPlayers &&
                            <CCardBody>
                                <CChartLine
                                        style={{height: '250px', marginTop: '0px'}}
                                        datasets={licenseSessionsAgg.aggPlayersDatasets}
                                        options={defaultChartOptions}
                                        labels={licenseSessionsAgg.aggDateLabels}/>
                            </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowDuration(!licenseSessionsAgg.showDuration)}>
                            {t`Duration (min)`}
                        </CCardHeader>

                        {licenseSessionsAgg.showDuration &&
                            <CCardBody>
                                <CChartLine
                                        style={{height: '250px', marginTop: '0px'}}
                                        datasets={licenseSessionsAgg.aggDurationDatasets}
                                        options={defaultChartOptions}
                                        labels={licenseSessionsAgg.aggDateLabels}/>
                            </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowPrice(!licenseSessionsAgg.showPrice)}>
                            {t`Total revenue USD (converted)`}
                        </CCardHeader>

                        {licenseSessionsAgg.showPrice &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggTotalUSDPriceDataset}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            {licenseSessionsAgg.aggPriceCurrencies.filter(currency => licenseSessionsAgg.aggPriceDatasets[currency].length > 0).map(currency =>
                <CRow key={currency}>
                    <CCol>
                        <CCard>
                            <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowPrice(!licenseSessionsAgg.showPrice)}>
                                {t`Revenue ${currency}`}
                            </CCardHeader>

                            {licenseSessionsAgg.showPrice &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggPriceDatasets[currency]}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                        </CCard>
                    </CCol>
                </CRow>)}

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowRoyalty(!licenseSessionsAgg.showRoyalty)}>
                            {t`Total royalty USD (converted)`}
                        </CCardHeader>

                        {licenseSessionsAgg.showRoyalty &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggTotalUSDRoyaltyDataset}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            {licenseSessionsAgg.aggRoyaltyCurrencies.filter(currency => licenseSessionsAgg.aggRoyaltyDatasets[currency].length > 0).map(currency =>
                <CRow key={currency}>
                    <CCol>
                        <CCard>
                            <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowRoyalty(!licenseSessionsAgg.showRoyalty)}>
                                {t`Royalty ${currency}`}
                            </CCardHeader>

                            {licenseSessionsAgg.showRoyalty &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggRoyaltyDatasets[currency]}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                        </CCard>
                    </CCol>
                </CRow>)}

            {reportsFilter.compareType !== CompareType.Original && licenseSessionsAgg.aggPriceCurrencies.filter(currency => licenseSessionsAgg.aggPriceDiscountDatasets[currency].length > 0).map(currency =>
                <CRow key={currency}>
                    <CCol>
                        <CCard>
                            <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowPriceDiscount(!licenseSessionsAgg.showPriceDiscount)}>
                                {t`Revenue Discount ${currency}`}
                            </CCardHeader>

                            {licenseSessionsAgg.showPriceDiscount &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggPriceDiscountDatasets[currency]}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                        </CCard>
                    </CCol>
                </CRow>)}

            {reportsFilter.compareType !== CompareType.Original && licenseSessionsAgg.aggRoyaltyCurrencies.filter(currency => licenseSessionsAgg.aggRoyaltyDiscountDatasets[currency].length > 0).map(currency =>
                <CRow key={currency}>
                    <CCol>
                        <CCard>
                            <CCardHeader role="button" onClick={() => licenseSessionsAgg.setShowRoyaltyDiscount(!licenseSessionsAgg.showRoyaltyDiscount)}>
                                {t`Royalty Discount ${currency}`}
                            </CCardHeader>

                            {licenseSessionsAgg.showRoyaltyDiscount &&
                                <CCardBody>
                                    <CChartLine
                                            style={{height: '250px', marginTop: '0px'}}
                                            datasets={licenseSessionsAgg.aggRoyaltyDiscountDatasets[currency]}
                                            options={defaultChartOptions}
                                            labels={licenseSessionsAgg.aggDateLabels}/>
                                </CCardBody>}
                        </CCard>
                    </CCol>
                </CRow>)}
        </React.Fragment>
    );
});

export default FinancialReportsCharts;
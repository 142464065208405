import {RoyaltyDefinition, RoyaltyVariantType} from "../generated/graphql";
import React from "react";
import {t} from "ttag";
import {CRow} from "@coreui/react";

const Royalty: React.FC<{royalty: RoyaltyDefinition, label?: string}> = (props) => {
    let royaltyType;

    switch (props.royalty.royaltyVariant) {
        case RoyaltyVariantType.PerRevenueShare:
            royaltyType = t`Revenue share`;
            break;
        case RoyaltyVariantType.PerSessionFixed:
            royaltyType = t`Per session (fixed)`;
            break;
        case RoyaltyVariantType.PerSessionMinute:
            royaltyType = t`Per minute`;
            break;
        case RoyaltyVariantType.PerSessionProgressive:
            royaltyType = t`Per hour (1st hour up to 90min)`;
            break;
        case RoyaltyVariantType.PerPlayerFixed:
            royaltyType = t`Per player (fixed)`;
            break;
        case RoyaltyVariantType.PerPlayerMinute:
            royaltyType = t`Per player minute`;
            break;
        case RoyaltyVariantType.PerPlayerProgressive:
            royaltyType = t`Per player hour (1st hour up to 90min)`;
            break;
        case RoyaltyVariantType.PerSessionFixedStrict:
            royaltyType = t`Per session (fixed, even unpaid)`;
            break;
        case RoyaltyVariantType.PerSessionMinuteStrict:
            royaltyType = t`Per minute (even unpaid)`;
            break;
        case RoyaltyVariantType.PerSessionProgressiveStrict:
            royaltyType = t`Per hour (even unpaid, 1st hour up to 90min)`;
            break;
        case RoyaltyVariantType.PerPlayerFixedStrict:
            royaltyType = t`Per player (fixed, even unpaid)`;
            break;
        case RoyaltyVariantType.PerPlayerMinuteStrict:
            royaltyType = t`Per player minute (even unpaid)`;
            break;
        case RoyaltyVariantType.PerPlayerProgressiveStrict:
            royaltyType = t`Per player hour (even unpaid, 1st hour up to 90min)`;
            break;
    }

    const isRevenueShare = props.royalty.royaltyVariant === RoyaltyVariantType.PerRevenueShare;
    const royaltyShare = +props.royalty.royaltyShare && ((+props.royalty.royaltyShare)*100).toFixed(2).replace(/(\.0+|0+)$/, '');
    const royalty = (+props.royalty.royalty).toFixed(3).replace(/(\.0+|0+)$/, '');

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{props.label && props.label}{royaltyType}:</strong>
            </CRow>

            <CRow gutters={false}>
                {isRevenueShare ? royaltyShare : royalty}{isRevenueShare ? '%' : props.royalty.royaltyCurrency}
            </CRow>
        </React.Fragment>
    );
};

export default Royalty;
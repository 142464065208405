import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {Venue} from "../generated/graphql";

const Venues = observer(() => {
    const store = useContext(StoreContext);
    const {activeVenues} = store;

    const options = (activeVenues.activeVenues ? activeVenues.activeVenues : []).filter(venue => !!venue)
        .map((venue: Venue | null) =>
                <option key={venue?.id} value={venue?.id ?? ""}>{venue?.country} {venue?.city} {venue?.location}</option>
        );
    return (
            <React.Fragment>
                {options}
            </React.Fragment>
    );
});

export default Venues;
import {action, computed, observable, reaction, runInAction} from "mobx";
import {
    Customer,
    LicenseSessionCustomerVenueProductDateAgg,
    LicenseSessionCustomerVenueProductDateAggFilter,
    LicenseSessionCustomerVenueProductYearAgg,
    LicenseSessionCustomerVenueProductYearAggFilter,
    LicenseSessionCustomerVenueProductYearMonthAgg,
    LicenseSessionCustomerVenueProductYearMonthAggFilter,
    Maybe,
    Product,
    Query,
    UserRoleType,
    Venue
} from "../generated/graphql";
import {RootStore} from "./RootStore";
import {debounceEffect} from "../utils/MobxUtils";
import {apolloClient, DelayMSDefault, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {getDateSeries, getMonthSeries, getYearSeries, toISODate} from "../utils/DateUtils";
import {t} from "ttag";
import {distinctColors} from "../utils/ColorUtils";
import gql from "graphql-tag";
import {
    AggregateCustomersType,
    AggregateDateType,
    AggregateProductsType,
    CompareSumType, IncludeUnpaid
} from "../api/LicenseSessionsApi";
import {
    createDataset, getAggCustomerVenueKey,
    getAggDataAggregator,
    getAggSumDataAggregator,
    getLabelPostfixByCompareSumType,
    getLabelPrefixByKey
} from "../utils/LicenseSessionsAggUtils";

type LicenseSessionAggFilter =
    LicenseSessionCustomerVenueProductDateAggFilter | LicenseSessionCustomerVenueProductYearMonthAggFilter | LicenseSessionCustomerVenueProductYearAggFilter;

type LicenseSessionAgg =
    LicenseSessionCustomerVenueProductDateAgg | LicenseSessionCustomerVenueProductYearMonthAgg | LicenseSessionCustomerVenueProductYearAgg;

function getAggPaidDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        if (agg) {
            return isPaid ? +agg.paidApprovedAmount : +agg.unpaidApprovedAmount;
        }

        return 0;
    }
}

function getAggPlayersDataAccessor(compareSumType: CompareSumType, isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalPlayersDataAccessor(isPaid);
        case CompareSumType.Min:
            return getAggMinPlayersDataAccessor(isPaid);
        case CompareSumType.Max:
            return getAggMaxPlayersDataAccessor(isPaid);
    }
}

function getAggDurationDataAccessor(compareSumType: CompareSumType, isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalDurationDataAccessor(isPaid);
        case CompareSumType.Min:
            return getAggMinDurationDataAccessor(isPaid);
        case CompareSumType.Max:
            return getAggMaxDurationDataAccessor(isPaid);
    }
}

function getAggTotalPlayersDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        let players: number | undefined | null = 0;

        if (agg) {
            players = isPaid ? agg.paidApprovedPlayers : agg.unpaidApprovedPlayers;

            if (players) {
                players = +players;
            }
        }

        return players || 0;
    }
}

function getAggMinPlayersDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        let players: number | undefined | null = 0;

        if (agg) {
            players = isPaid ? agg.paidApprovedPlayersMin : agg.unpaidApprovedPlayersMin;

            if (players) {
                players = +players;
            }
        }

        return players || 0;
    }
}

function getAggMaxPlayersDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        let players: number | undefined | null = 0;

        if (agg) {
            players = isPaid ? agg.paidApprovedPlayersMax : agg.unpaidApprovedPlayersMax;

            if (players) {
                players = +players;
            }
        }

        return players || 0;
    }
}

function getAggTotalDurationDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        let duration: number | undefined | null = 0;

        if (agg) {
            duration = isPaid ? agg.paidApprovedDuration : agg.unpaidApprovedDuration;

            if (duration) {
                duration = +duration;
            }
        }

        return duration || 0;
    }
}

function getAggMinDurationDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        let duration: number | undefined | null = 0;

        if (agg) {
            duration = isPaid ? agg.paidApprovedDurationMin : agg.unpaidApprovedDurationMin;

            if (duration) {
                duration = +duration;
            }
        }

        return duration || 0;
    }
}

function getAggMaxDurationDataAccessor(isPaid: boolean): (agg: LicenseSessionAgg | null) => number {
    return (agg: LicenseSessionAgg | null) => {
        let duration: number | undefined | null = 0;

        if (agg) {
            duration = isPaid ? agg.paidApprovedDurationMax : agg.unpaidApprovedDurationMax;

            if (duration) {
                duration = +duration;
            }
        }

        return duration || 0;
    }
}

class ProductsUsageAggStore {
    @observable.ref
    public licenseSessionsAgg: Maybe<LicenseSessionAgg>[] | undefined = undefined;

    @observable
    public showTotalSessions: boolean = true;

    @observable
    public showPlayers: boolean = false;

    @observable
    public showDuration: boolean = false;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(() => this.filter, debounceEffect(() => {
            this.fetchAgg();
        }, DelayMSDefault));
    }

    @computed
    public get filter(): LicenseSessionAggFilter | undefined {
        return ProductsUsageAggStore.constructLicenseSessionAggFilter(
            this.rootStore.reportsFilter.filterCustomers,
            this.rootStore.reportsFilter.filterCustomerTags,
            this.rootStore.reportsFilter.filterVenues,
            this.rootStore.reportsFilter.filterProducts,
            this.rootStore.reportsFilter.filterDateStart,
            this.rootStore.reportsFilter.filterDateEnd,
            this.rootStore.reportsFilter.aggregateDateType
        );
    }

    @computed
    public get aggDateLabels(): string[] {
        return this.aggDateSeries.map(val => {
            if (typeof(val) === "string") {
                return val;
            } else {
                return `${val.year}${val.month ? '-' + val.month : ''}`;
            }
        });
    }

    @computed
    public get aggDateSeries(): (string | {year: string, month?: string})[] {
        const licenseSessionAggFirst =
            this.licenseSessionsAgg && this.licenseSessionsAgg.length > 0 && this.licenseSessionsAgg[0] as LicenseSessionAgg;

        let dateStart = this.rootStore.reportsFilter.filterDateStart ? this.rootStore.reportsFilter.filterDateStart : null;

        const dateEnd = this.rootStore.reportsFilter.filterDateEnd ?
            this.rootStore.reportsFilter.filterDateEnd : new Date();

        switch (this.rootStore.reportsFilter.aggregateDateType) {
            case AggregateDateType.ByDay:
                const licenseSessionAggDayFirst = licenseSessionAggFirst && licenseSessionAggFirst as LicenseSessionCustomerVenueProductDateAgg;
                if (!dateStart && licenseSessionAggDayFirst) {
                    dateStart = new Date(licenseSessionAggDayFirst.date);
                }
                return !dateStart ? [] : getDateSeries(dateStart, dateEnd);
            case AggregateDateType.ByMonth:
                const licenseSessionAggMonthFirst = licenseSessionAggFirst && licenseSessionAggFirst as LicenseSessionCustomerVenueProductYearMonthAgg;
                if (!dateStart && licenseSessionAggMonthFirst) {
                    dateStart = new Date(`${licenseSessionAggMonthFirst.year}-${licenseSessionAggMonthFirst.month}`);
                }
                return !dateStart ? [] : getMonthSeries(dateStart, dateEnd);
            case AggregateDateType.ByYear:
                const licenseSessionAggYearFirst = licenseSessionAggFirst && licenseSessionAggFirst as LicenseSessionCustomerVenueProductYearAgg;
                if (!dateStart && licenseSessionAggYearFirst) {
                    dateStart = new Date(`${licenseSessionAggYearFirst.year}`);
                }
                return !dateStart ? [] : getYearSeries(dateStart, dateEnd);
        }

        return [];
    }

    @computed
    public get aggCustomerNames(): (string | null)[] {
        const allNames = this.licenseSessionsAgg &&
            this.licenseSessionsAgg
                .map(agg => agg?.customerName || null);

        if (allNames && allNames.length > 0) {
            return Array.from(new Set(allNames).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggVenueLocations(): (string | null)[] {
        const isAdmin = this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin;

        const allLocations = this.licenseSessionsAgg &&
            this.licenseSessionsAgg.map(agg => getAggCustomerVenueKey(isAdmin, agg));

        if (allLocations && allLocations.length > 0) {
            return Array.from(new Set(allLocations).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggProductNames(): (string | null)[] {
        const allNames = this.licenseSessionsAgg &&
            this.licenseSessionsAgg
                .map(agg => agg?.productName || null);

        if (allNames && allNames.length > 0) {
            return Array.from(new Set(allNames).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggTotalSessionsDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const paidDataset = createDataset(
                labelPrefix ? t`${labelPrefix} - paid` : t`Paid`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsAgg, key, getAggPaidDataAccessor(true), getAggSumDataAggregator)
            );

            if (paidDataset) {
                datasets.push(paidDataset);
                currentColorIndex++;
            }

            if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
                const unpaidDataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - unpaid` : t`Unpaid`,
                    distinctColors[currentColorIndex],
                    this.getAggData(this.licenseSessionsAgg, key, getAggPaidDataAccessor(false), getAggSumDataAggregator)
                );

                if (unpaidDataset) {
                    datasets.push(unpaidDataset);
                    currentColorIndex++;
                }
            }

            return datasets;
        });
    }

    @computed
    public get aggPlayersDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareSumType = this.rootStore.reportsFilter.compareSumType;

        const labelPostfix = getLabelPostfixByCompareSumType(compareSumType);

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const paidDataset = createDataset(
                labelPrefix ? t`${labelPrefix} - paid players ${labelPostfix}` : t`Paid players ${labelPostfix}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsAgg, key, getAggPlayersDataAccessor(compareSumType, true), getAggDataAggregator(compareSumType))
            );

            if (paidDataset) {
                datasets.push(paidDataset);
                currentColorIndex++;
            }

            if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
                const unpaidDataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - unpaid players ${labelPostfix}` : t`Unpaid players ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(this.licenseSessionsAgg, key, getAggPlayersDataAccessor(compareSumType, false), getAggDataAggregator(compareSumType))
                );

                if (unpaidDataset) {
                    datasets.push(unpaidDataset);
                    currentColorIndex++;
                }
            }

            return datasets;
        });
    }

    @computed
    public get aggDurationDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareSumType = this.rootStore.reportsFilter.compareSumType;

        const labelPostfix = getLabelPostfixByCompareSumType(compareSumType);

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const paidDataset = createDataset(
                labelPrefix ? t`${labelPrefix} - paid duration ${labelPostfix}` : t`Paid duration ${labelPostfix}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsAgg, key, getAggDurationDataAccessor(compareSumType, true), getAggDataAggregator(compareSumType))
            );

            if (paidDataset) {
                datasets.push(paidDataset);
                currentColorIndex++;
            }

            if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
                const unpaidDataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - unpaid duration ${labelPostfix}` : t`Unpaid duration ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(this.licenseSessionsAgg, key, getAggDurationDataAccessor(compareSumType, false), getAggDataAggregator(compareSumType))
                );

                if (unpaidDataset) {
                    datasets.push(unpaidDataset);
                    currentColorIndex++;
                }
            }

            return datasets;
        });
    }

    @action
    public setShowTotalSessions(showTotalSessions: boolean) {
        this.showTotalSessions = showTotalSessions;
    }

    @action
    public setShowPlayers(showPlayers: boolean) {
        this.showPlayers = showPlayers;
    }

    @action
    public setShowDuration(showDuration: boolean) {
        this.showDuration = showDuration;
    }

    @action
    public async fetchAgg() {
        this.loading = true;
        this.error = undefined;

        try {
            switch (this.rootStore.reportsFilter.aggregateDateType) {
                case AggregateDateType.ByDay: {
                    const result = await this.aggregateByDateQuery();
                    runInAction(() => {
                        this.licenseSessionsAgg = result.data.allLicenseSessionCustomerVenueProductDateAggs?.nodes;
                    });
                    break;
                }
                case AggregateDateType.ByMonth: {
                    const result = await this.aggregateByMonthQuery();
                    runInAction(() => {
                        this.licenseSessionsAgg = result.data.allLicenseSessionCustomerVenueProductYearMonthAggs?.nodes;
                    });
                    break;
                }
                case AggregateDateType.ByYear: {
                    const result = await this.aggregateByYearQuery();
                    runInAction(() => {
                        this.licenseSessionsAgg = result.data.allLicenseSessionCustomerVenueProductYearAggs?.nodes;
                    });
                    break;
                }
            }
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async aggregateByDateQuery() {
        return wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseSessionAggFilter | undefined
        }>({
            query: gql`query ($filterInput: LicenseSessionCustomerVenueProductDateAggFilter) {
                currentUserId,

                allLicenseSessionCustomerVenueProductDateAggs(filter: $filterInput) {
                    nodes {
                        paidApprovedAmount,
                        unpaidApprovedAmount,
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        paidApprovedDuration,
                        unpaidApprovedDuration,
                        paidApprovedDurationMax,
                        unpaidApprovedDurationMax,
                        paidApprovedDurationMin,
                        unpaidApprovedDurationMin,
                        paidApprovedPlayers,
                        unpaidApprovedPlayers,
                        paidApprovedPlayersMax,
                        unpaidApprovedPlayersMax,
                        paidApprovedPlayersMin,
                        unpaidApprovedPlayersMin,
                        productId,
                        productName,
                        date
                    }
                }
            }`,
            variables: {
                filterInput: this.filter
            }
        }));
    }

    @action
    private async aggregateByMonthQuery() {
        return wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseSessionAggFilter | undefined
        }>({
            query: gql`query ($filterInput: LicenseSessionCustomerVenueProductYearMonthAggFilter) {
                currentUserId,

                allLicenseSessionCustomerVenueProductYearMonthAggs(filter: $filterInput) {
                    nodes {
                        paidApprovedAmount,
                        unpaidApprovedAmount,
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        paidApprovedDuration,
                        unpaidApprovedDuration,
                        paidApprovedDurationMax,
                        unpaidApprovedDurationMax,
                        paidApprovedDurationMin,
                        unpaidApprovedDurationMin,
                        paidApprovedPlayers,
                        unpaidApprovedPlayers,
                        paidApprovedPlayersMax,
                        unpaidApprovedPlayersMax,
                        paidApprovedPlayersMin,
                        unpaidApprovedPlayersMin,
                        productId,
                        productName,
                        year,
                        month
                    }
                }
            }`,
            variables: {
                filterInput: this.filter
            }
        }));
    }

    @action
    private async aggregateByYearQuery() {
        return wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseSessionAggFilter | undefined
        }>({
            query: gql`query ($filterInput: LicenseSessionCustomerVenueProductYearAggFilter) {
                currentUserId,

                allLicenseSessionCustomerVenueProductYearAggs(filter: $filterInput) {
                    nodes {
                        paidApprovedAmount,
                        unpaidApprovedAmount,
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        paidApprovedDuration,
                        unpaidApprovedDuration,
                        paidApprovedDurationMax,
                        unpaidApprovedDurationMax,
                        paidApprovedDurationMin,
                        unpaidApprovedDurationMin,
                        paidApprovedPlayers,
                        unpaidApprovedPlayers,
                        paidApprovedPlayersMax,
                        unpaidApprovedPlayersMax,
                        paidApprovedPlayersMin,
                        unpaidApprovedPlayersMin,
                        productId,
                        productName,
                        year
                    }
                }
            }`,
            variables: {
                filterInput: this.filter
            }
        }));
    }

    @action
    public reset() {
        this.licenseSessionsAgg = undefined;
        this.loading = false;
        this.error = undefined;
    }

    private getAggData(
        allData: Maybe<LicenseSessionAgg>[] | undefined,
        key: {customerVenueName?: string, productName?: string} | null | undefined,
        dataAccessor: (agg: LicenseSessionAgg | null) => number,
        dataAggregator: (accumulator?: number, currentValue?: number, rows?: number) => number
    ) {
        const isAdmin = this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin;
        const isSplitByVenue = this.rootStore.reportsFilter.aggregateCustomersType === AggregateCustomersType.SplitByVenue;

        return this.aggDateSeries.map(date => {
            let agg: Maybe<LicenseSessionAgg>[];

            switch (this.rootStore.reportsFilter.aggregateDateType) {
                case AggregateDateType.ByDay:
                    agg = (allData && allData.filter(agg => {
                        const aggCasted = agg && agg as LicenseSessionCustomerVenueProductDateAgg;
                        return aggCasted && aggCasted.date === date;
                    })) || [];
                    break;
                case AggregateDateType.ByMonth: {
                    const dateCasted = date as { year: string, month: string };
                    agg = (allData && allData.filter(agg => {
                        const aggCasted = agg && agg as LicenseSessionCustomerVenueProductYearMonthAgg;
                        return aggCasted && aggCasted.year?.toString() === dateCasted.year && aggCasted.month?.toString() === dateCasted.month;
                    })) || [];
                    break;
                }
                case AggregateDateType.ByYear: {
                    const dateCasted = date as { year: string };
                    agg = (allData && allData.filter(agg => {
                        const aggCasted = agg && agg as LicenseSessionCustomerVenueProductYearAgg;
                        return aggCasted && aggCasted.year?.toString() === dateCasted.year;
                    })) || [];
                    break;
                }
            }

            if (agg.length === 0) {
                return dataAccessor(null);
            }

            agg = agg.filter(agg =>
                key === undefined ||
                (key === null && !agg?.customerName) ||
                (key?.customerVenueName && !key?.productName && key?.customerVenueName === (isSplitByVenue ? getAggCustomerVenueKey(isAdmin, agg) : agg?.customerName)) ||
                (!key?.customerVenueName && key?.productName && key?.productName === agg?.productName) ||
                (key?.customerVenueName && key?.productName && key?.customerVenueName === (isSplitByVenue ? getAggCustomerVenueKey(isAdmin, agg) : agg?.customerName) && key?.productName === agg?.productName)
            );

            let accumulator: number = dataAggregator();
            for (let aggRow of agg) {
                accumulator = dataAggregator(accumulator, dataAccessor(aggRow));
            }

            return dataAggregator(accumulator, undefined, agg.length);
        });
    }

    private getAggDatasets(addDataset: (key: {customerVenueName?: string, productName?: string} | null | undefined) => any[]): any[] {
        let customerVenueNames: (string | null)[];

        switch (this.rootStore.reportsFilter.aggregateCustomersType) {
            case AggregateCustomersType.Split:
                customerVenueNames = this.aggCustomerNames;
                break;
            case AggregateCustomersType.SplitByVenue:
                customerVenueNames = this.aggVenueLocations;
                break;
            default:
                customerVenueNames = [];
        }

        const productNames = this.rootStore.reportsFilter.aggregateProductsType === AggregateProductsType.NotSplit ? [] : this.aggProductNames;

        let addUnknown: boolean = false;

        let datasets: any[] = [];

        for (let customerVenueName of customerVenueNames) {
            if (!customerVenueName) {
                addUnknown = true;
                continue;
            }

            for (let productName of productNames) {
                if (!productName) {
                    addUnknown = true;
                    continue;
                }

                datasets = addDataset({customerVenueName, productName});
            }

            if (productNames.length === 0) {
                datasets = addDataset({customerVenueName});
            }
        }

        if (customerVenueNames.length === 0 && productNames.length > 0) {
            for (let productName of productNames) {
                if (!productName) {
                    addUnknown = true;
                    continue;
                }

                datasets = addDataset({productName});
            }
        } else if (customerVenueNames.length === 0 && productNames.length === 0) {
            datasets = addDataset(undefined);
        }

        if (addUnknown) {
            datasets = addDataset(null);
        }

        return datasets;
    }

    private static constructLicenseSessionAggFilter(
        filterCustomers: Customer[],
        filterCustomerTags: string[],
        filterVenues: Venue[],
        filterProducts: Product[],
        filterDateStart: Date | null,
        filterDateEnd: Date | null,
        aggregateDateType: AggregateDateType
    ): LicenseSessionAggFilter | undefined {
        const conditions: LicenseSessionAggFilter[] = [];

        if (filterCustomers.length > 0) {
            const customersCondition = {
                customerId: {
                    in: filterCustomers.map(customer => customer.id)
                }
            };

            conditions.push(customersCondition);
        }

        if (filterCustomerTags.length > 0) {
            conditions.push({
                customerTags: {
                    contains: filterCustomerTags
                }
            });
        }

        if (filterVenues.length > 0) {
            const venuesCondition = {
                venueId: {
                    in: filterVenues.map(venue => venue.id)
                }
            };

            conditions.push(venuesCondition);
        }

        if (filterProducts.length > 0) {
            const productsCondition = {
                productId: {
                    in: filterProducts.map(product => product.id)
                }
            };

            conditions.push(productsCondition);
        }

        if (filterDateStart) {
            switch (aggregateDateType) {
                case AggregateDateType.ByDay:
                    conditions.push({
                        date: {
                            greaterThanOrEqualTo: toISODate(filterDateStart)
                        }
                    });
                    break;
                case AggregateDateType.ByMonth:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateStart.getFullYear()
                                        }
                                    },
                                    {
                                        month: {
                                            greaterThanOrEqualTo: filterDateStart.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    greaterThan: filterDateStart.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByYear:
                    conditions.push({
                        year: {
                            greaterThanOrEqualTo: filterDateStart.getFullYear()
                        }
                    });
                    break;
            }
        }

        if (filterDateEnd) {
            switch (aggregateDateType) {
                case AggregateDateType.ByDay:
                    conditions.push({
                        date: {
                            lessThanOrEqualTo: toISODate(filterDateEnd)
                        }
                    });
                    break;
                case AggregateDateType.ByMonth:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateEnd.getFullYear()
                                        }
                                    },
                                    {
                                        month: {
                                            lessThanOrEqualTo: filterDateEnd.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    lessThan: filterDateEnd.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByYear:
                    conditions.push({
                        year: {
                            lessThanOrEqualTo: filterDateEnd.getFullYear()
                        }
                    });
                    break;
            }
        }

        return conditions.length <= 0 ? undefined : {
            and: conditions
        };
    }
}

export default ProductsUsageAggStore;
import React, {useContext} from "react";
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CHeader,
    CHeaderNav,
    CToggler
} from "@coreui/react";
import {observer} from "mobx-react-lite";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import {StoreContext} from "../context/StoreContext";
import {routes} from "../routes";

const Header = observer(() => {
    const store = useContext(StoreContext);
    const {ui, auth, router} = store;

    const logout = (allSessions: boolean = false) =>
        router.goTo(routes.authenticating, undefined, {
            logout: allSessions ? undefined : true,
            logoutAll: allSessions ? true : undefined
        });

    return (
        <CHeader>
            <CToggler inHeader
                      className="ml-md-3 d-lg-none"
                      title={t`Toggle menu`}
                      onClick={ui.toggleSidebarShowMobile}/>
            <CToggler inHeader
                      className="ml-3 d-md-down-none"
                      title={t`Toggle menu`}
                      onClick={ui.toggleSidebarShow}/>

            <CHeaderNav className="d-md-down-none mr-auto"/>

            <CHeaderNav className="px-3">
                <CDropdown inNav
                           className="c-header-nav-items mx-2">
                    <CDropdownToggle className="c-header-nav-link" caret={false}>
                        <div className="c-avatar">
                            {`${auth.currentUser?.name} ${auth.currentUser?.userCustomerByUserId?.customerByCustomerId?.name ? '(' + auth.currentUser?.userCustomerByUserId.customerByCustomerId.name + ')' : ''}`}
                        </div>
                    </CDropdownToggle>

                    <CDropdownMenu className="pt-0" placement="bottom-end">
                        <CDropdownItem onClick={() => router.goTo(routes.currentUserSettings)}>
                            <CIcon name="cil-settings" className="mfe-2" />
                            {t`Settings`}
                        </CDropdownItem>

                        <CDropdownItem onClick={() => logout()}>
                            <CIcon name="cil-account-logout" className="mfe-2" />
                            {t`Logout`}
                        </CDropdownItem>

                        <CDropdownItem onClick={() => logout(true)}>
                            <CIcon name="cil-account-logout" className="mfe-2" />
                            {t`Logout all sessions`}
                        </CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </CHeaderNav>
        </CHeader>
    );
});

export default Header;
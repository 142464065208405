import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {
    CBadge,
    CDataTable,
} from "@coreui/react";
import {t} from "ttag";
import {Product} from "../generated/graphql";
import Error from "./Error";

const SelectProduct = observer((props: {selectedProductIds: string[], onSelect: (product: Product) => void}) => {
    const store = useContext(StoreContext);
    const {products} = store;

    return (
            <CDataTable
                    noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                    items={(products.products && products.products.length > 0 && products.products.filter(prod => prod && prod.isGame)) || []}
                    loading={products.loading}
                    fields={["name"]}
                    border={true}
                    clickableRows={true}
                    onRowClick={props.onSelect}
                    columnHeaderSlot={{
                        name: t`Product`
                    }}
                    scopedSlots={{
                        name: (product: Product) => (
                                <td className={`${props.selectedProductIds.includes(product.id) ? "bg-info" : ""}`}>
                                    {product.name}
                                    {product.isArchived && <CBadge color="secondary">
                                        {t`archived`}
                                    </CBadge>}
                                </td>
                        )
                    }}
                    underTableSlot={products.error && <Error>{products.error}</Error>}/>
    );
});

export default SelectProduct;
import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {
    CBadge,
    CDataTable,
} from "@coreui/react";
import {t} from "ttag";
import {Customer} from "../generated/graphql";
import Error from "./Error";
import Pagination from "./Pagination";

const SelectCustomer = observer((props: {selectedCustomerIds: string[], onSelect: (customer: Customer) => void}) => {
    const store = useContext(StoreContext);
    const {customers} = store;

    const filter = (str: string) => {
        customers.setFilter(!str ? undefined : {
            name: {
                likeInsensitive: `%${str}%`
            }
        });
    };

    return (
            <CDataTable
                    noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                    items={(customers.customers && customers.customers.length > 0 && customers.customers) || []}
                    loading={customers.loading}
                    fields={["name"]}
                    border={true}
                    clickableRows={true}
                    tableFilter={{external: true, label: t`Filter:`, placeholder: t`type name`}}
                    onTableFilterChange={filter}
                    onRowClick={props.onSelect}
                    columnHeaderSlot={{
                        name: t`Customer`
                    }}
                    scopedSlots={{
                        name: (customer: Customer) => (
                                <td className={`${props.selectedCustomerIds.includes(customer.id) ? "bg-info" : ""}`}>
                                    {customer.name}
                                    {customer.isArchived && <CBadge color="secondary">
                                        {t`archived`}
                                    </CBadge>}
                                </td>
                        )
                    }}
                    underTableSlot={customers.error ? <Error>{customers.error}</Error> : <Pagination loading={customers.loading}
                                                                                                     pageInfo={customers.pageInfo}
                                                                                                     onPage={(page) => customers.setPage(page)}/>}/>
    );
});

export default SelectCustomer;
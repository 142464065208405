export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountLoginType: any;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  CurrencyType: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A set of key/value pairs, keys are strings, values may be a string or null. Exposed as a JSON object. */
  KeyValueHash: any;
  LicenseKeyType: any;
  LocaleType: any;
  NameType: any;
  PriceNumberType: any;
  PriceShareType: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};


/** A filter to be used against AccountLoginType fields. All fields are combined with a logical ‘and.’ */
export type AccountLoginTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['AccountLoginType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['AccountLoginType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['AccountLoginType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['AccountLoginType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['AccountLoginType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['AccountLoginType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['AccountLoginType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['AccountLoginType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['AccountLoginType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['AccountLoginType']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['AccountLoginType']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['AccountLoginType']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['AccountLoginType']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['AccountLoginType']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['AccountLoginType']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['AccountLoginType']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['AccountLoginType']>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['AccountLoginType']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['AccountLoginType']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['AccountLoginType']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['AccountLoginType']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['AccountLoginType']>;
};

/** All input for the `authenticate` mutation. */
export type AuthenticateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  accountLogin: Scalars['AccountLoginType'];
  accountPassword: Scalars['String'];
};

/** The output of our `authenticate` mutation. */
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtTokenPair?: Maybe<JwtTokenPair>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigFloat']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigFloat']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};


/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigInt']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/** All input for the create `CurrencyExchangeRate` mutation. */
export type CreateCurrencyExchangeRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrencyExchangeRate` to be created by this mutation. */
  currencyExchangeRate: CurrencyExchangeRateInput;
};

/** The output of our create `CurrencyExchangeRate` mutation. */
export type CreateCurrencyExchangeRatePayload = {
  __typename?: 'CreateCurrencyExchangeRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrencyExchangeRate` that was created by this mutation. */
  currencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CurrencyExchangeRate`. May be used by Relay 1. */
  currencyExchangeRateEdge?: Maybe<CurrencyExchangeRatesEdge>;
};


/** The output of our create `CurrencyExchangeRate` mutation. */
export type CreateCurrencyExchangeRatePayloadCurrencyExchangeRateEdgeArgs = {
  orderBy?: Maybe<Array<CurrencyExchangeRatesOrderBy>>;
};

/** All input for the create `CurrencyExchangeRatesFiltered` mutation. */
export type CreateCurrencyExchangeRatesFilteredInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrencyExchangeRatesFiltered` to be created by this mutation. */
  currencyExchangeRatesFiltered: CurrencyExchangeRatesFilteredInput;
};

/** The output of our create `CurrencyExchangeRatesFiltered` mutation. */
export type CreateCurrencyExchangeRatesFilteredPayload = {
  __typename?: 'CreateCurrencyExchangeRatesFilteredPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrencyExchangeRatesFiltered` that was created by this mutation. */
  currencyExchangeRatesFiltered?: Maybe<CurrencyExchangeRatesFiltered>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CurrencyExchangeRatesFiltered`. May be used by Relay 1. */
  currencyExchangeRatesFilteredEdge?: Maybe<CurrencyExchangeRatesFilteredsEdge>;
};


/** The output of our create `CurrencyExchangeRatesFiltered` mutation. */
export type CreateCurrencyExchangeRatesFilteredPayloadCurrencyExchangeRatesFilteredEdgeArgs = {
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredsOrderBy>>;
};

/** All input for the create `Customer` mutation. */
export type CreateCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Customer` to be created by this mutation. */
  customer: CustomerInput;
};

/** The output of our create `Customer` mutation. */
export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Customer` that was created by this mutation. */
  customer?: Maybe<Customer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Customer`. May be used by Relay 1. */
  customerEdge?: Maybe<CustomersEdge>;
};


/** The output of our create `Customer` mutation. */
export type CreateCustomerPayloadCustomerEdgeArgs = {
  orderBy?: Maybe<Array<CustomersOrderBy>>;
};

/** All input for the create `LeaderboardPlayerScore` mutation. */
export type CreateLeaderboardPlayerScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardPlayerScore` to be created by this mutation. */
  leaderboardPlayerScore: LeaderboardPlayerScoreInput;
};

/** The output of our create `LeaderboardPlayerScore` mutation. */
export type CreateLeaderboardPlayerScorePayload = {
  __typename?: 'CreateLeaderboardPlayerScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardPlayerScore` that was created by this mutation. */
  leaderboardPlayerScore?: Maybe<LeaderboardPlayerScore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardTeamScore` that is related to this `LeaderboardPlayerScore`. */
  leaderboardTeamScoreByLeaderboardScoreId?: Maybe<LeaderboardTeamScore>;
  /** An edge for our `LeaderboardPlayerScore`. May be used by Relay 1. */
  leaderboardPlayerScoreEdge?: Maybe<LeaderboardPlayerScoresEdge>;
};


/** The output of our create `LeaderboardPlayerScore` mutation. */
export type CreateLeaderboardPlayerScorePayloadLeaderboardPlayerScoreEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardPlayerScoresOrderBy>>;
};

/** All input for the create `LeaderboardRound` mutation. */
export type CreateLeaderboardRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardRound` to be created by this mutation. */
  leaderboardRound: LeaderboardRoundInput;
};

/** The output of our create `LeaderboardRound` mutation. */
export type CreateLeaderboardRoundPayload = {
  __typename?: 'CreateLeaderboardRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardRound` that was created by this mutation. */
  leaderboardRound?: Maybe<LeaderboardRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardSession` that is related to this `LeaderboardRound`. */
  leaderboardSessionByLeaderboardSessionId?: Maybe<LeaderboardSession>;
  /** An edge for our `LeaderboardRound`. May be used by Relay 1. */
  leaderboardRoundEdge?: Maybe<LeaderboardRoundsEdge>;
};


/** The output of our create `LeaderboardRound` mutation. */
export type CreateLeaderboardRoundPayloadLeaderboardRoundEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardRoundsOrderBy>>;
};

/** All input for the create `LeaderboardSession` mutation. */
export type CreateLeaderboardSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardSession` to be created by this mutation. */
  leaderboardSession: LeaderboardSessionInput;
};

/** The output of our create `LeaderboardSession` mutation. */
export type CreateLeaderboardSessionPayload = {
  __typename?: 'CreateLeaderboardSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardSession` that was created by this mutation. */
  leaderboardSession?: Maybe<LeaderboardSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseSession` that is related to this `LeaderboardSession`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** An edge for our `LeaderboardSession`. May be used by Relay 1. */
  leaderboardSessionEdge?: Maybe<LeaderboardSessionsEdge>;
};


/** The output of our create `LeaderboardSession` mutation. */
export type CreateLeaderboardSessionPayloadLeaderboardSessionEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardSessionsOrderBy>>;
};

/** All input for the create `LeaderboardTeamScore` mutation. */
export type CreateLeaderboardTeamScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardTeamScore` to be created by this mutation. */
  leaderboardTeamScore: LeaderboardTeamScoreInput;
};

/** The output of our create `LeaderboardTeamScore` mutation. */
export type CreateLeaderboardTeamScorePayload = {
  __typename?: 'CreateLeaderboardTeamScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardTeamScore` that was created by this mutation. */
  leaderboardTeamScore?: Maybe<LeaderboardTeamScore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardRound` that is related to this `LeaderboardTeamScore`. */
  leaderboardRoundByLeaderboardRoundId?: Maybe<LeaderboardRound>;
  /** An edge for our `LeaderboardTeamScore`. May be used by Relay 1. */
  leaderboardTeamScoreEdge?: Maybe<LeaderboardTeamScoresEdge>;
};


/** The output of our create `LeaderboardTeamScore` mutation. */
export type CreateLeaderboardTeamScorePayloadLeaderboardTeamScoreEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardTeamScoresOrderBy>>;
};

/** All input for the create `LicenseEvent` mutation. */
export type CreateLicenseEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseEvent` to be created by this mutation. */
  licenseEvent: LicenseEventInput;
};

/** The output of our create `LicenseEvent` mutation. */
export type CreateLicenseEventPayload = {
  __typename?: 'CreateLicenseEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseEvent` that was created by this mutation. */
  licenseEvent?: Maybe<LicenseEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `License` that is related to this `LicenseEvent`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseEvent`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** An edge for our `LicenseEvent`. May be used by Relay 1. */
  licenseEventEdge?: Maybe<LicenseEventsEdge>;
};


/** The output of our create `LicenseEvent` mutation. */
export type CreateLicenseEventPayloadLicenseEventEdgeArgs = {
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
};

/** All input for the create `License` mutation. */
export type CreateLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `License` to be created by this mutation. */
  license: LicenseInput;
};

/** The output of our create `License` mutation. */
export type CreateLicensePayload = {
  __typename?: 'CreateLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `License` that was created by this mutation. */
  license?: Maybe<License>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Venue` that is related to this `License`. */
  venueByVenueId?: Maybe<Venue>;
  /** Reads a single `Product` that is related to this `License`. */
  productByProductId?: Maybe<Product>;
  /** An edge for our `License`. May be used by Relay 1. */
  licenseEdge?: Maybe<LicensesEdge>;
};


/** The output of our create `License` mutation. */
export type CreateLicensePayloadLicenseEdgeArgs = {
  orderBy?: Maybe<Array<LicensesOrderBy>>;
};

/** All input for the create `LicenseSessionComment` mutation. */
export type CreateLicenseSessionCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSessionComment` to be created by this mutation. */
  licenseSessionComment: LicenseSessionCommentInput;
};

/** The output of our create `LicenseSessionComment` mutation. */
export type CreateLicenseSessionCommentPayload = {
  __typename?: 'CreateLicenseSessionCommentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSessionComment` that was created by this mutation. */
  licenseSessionComment?: Maybe<LicenseSessionComment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSessionComment`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** Reads a single `User` that is related to this `LicenseSessionComment`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `LicenseSessionComment`. May be used by Relay 1. */
  licenseSessionCommentEdge?: Maybe<LicenseSessionCommentsEdge>;
};


/** The output of our create `LicenseSessionComment` mutation. */
export type CreateLicenseSessionCommentPayloadLicenseSessionCommentEdgeArgs = {
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
};

/** All input for the create `LicenseSession` mutation. */
export type CreateLicenseSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSession` to be created by this mutation. */
  licenseSession: LicenseSessionInput;
};

/** The output of our create `LicenseSession` mutation. */
export type CreateLicenseSessionPayload = {
  __typename?: 'CreateLicenseSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSession` that was created by this mutation. */
  licenseSession?: Maybe<LicenseSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `License` that is related to this `LicenseSession`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSession`. */
  licenseSessionByMasterSessionId?: Maybe<LicenseSession>;
  /** Reads a single `LicenseV2` that is related to this `LicenseSession`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseSession`. */
  productByProductId?: Maybe<Product>;
  /** Reads a single `Customer` that is related to this `LicenseSession`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Venue` that is related to this `LicenseSession`. */
  venueByVenueId?: Maybe<Venue>;
  /** An edge for our `LicenseSession`. May be used by Relay 1. */
  licenseSessionEdge?: Maybe<LicenseSessionsEdge>;
};


/** The output of our create `LicenseSession` mutation. */
export type CreateLicenseSessionPayloadLicenseSessionEdgeArgs = {
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
};

/** All input for the create `LicenseV2Event` mutation. */
export type CreateLicenseV2EventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2Event` to be created by this mutation. */
  licenseV2Event: LicenseV2EventInput;
};

/** The output of our create `LicenseV2Event` mutation. */
export type CreateLicenseV2EventPayload = {
  __typename?: 'CreateLicenseV2EventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2Event` that was created by this mutation. */
  licenseV2Event?: Maybe<LicenseV2Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseV2` that is related to this `LicenseV2Event`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseV2Event`. */
  productByProductId?: Maybe<Product>;
  /** An edge for our `LicenseV2Event`. May be used by Relay 1. */
  licenseV2EventEdge?: Maybe<LicenseV2EventsEdge>;
};


/** The output of our create `LicenseV2Event` mutation. */
export type CreateLicenseV2EventPayloadLicenseV2EventEdgeArgs = {
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
};

/** All input for the create `LicenseV2` mutation. */
export type CreateLicenseV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2` to be created by this mutation. */
  licenseV2: LicenseV2Input;
};

/** The output of our create `LicenseV2` mutation. */
export type CreateLicenseV2Payload = {
  __typename?: 'CreateLicenseV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2` that was created by this mutation. */
  licenseV2?: Maybe<LicenseV2>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Venue` that is related to this `LicenseV2`. */
  venueByVenueId?: Maybe<Venue>;
  /** An edge for our `LicenseV2`. May be used by Relay 1. */
  licenseV2Edge?: Maybe<LicenseV2SEdge>;
};


/** The output of our create `LicenseV2` mutation. */
export type CreateLicenseV2PayloadLicenseV2EdgeArgs = {
  orderBy?: Maybe<Array<LicenseV2SOrderBy>>;
};

/** All input for the create `LoadedLicenseSession` mutation. */
export type CreateLoadedLicenseSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LoadedLicenseSession` to be created by this mutation. */
  loadedLicenseSession: LoadedLicenseSessionInput;
};

/** The output of our create `LoadedLicenseSession` mutation. */
export type CreateLoadedLicenseSessionPayload = {
  __typename?: 'CreateLoadedLicenseSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LoadedLicenseSession` that was created by this mutation. */
  loadedLicenseSession?: Maybe<LoadedLicenseSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `LoadedLicenseSession`. May be used by Relay 1. */
  loadedLicenseSessionEdge?: Maybe<LoadedLicenseSessionsEdge>;
};


/** The output of our create `LoadedLicenseSession` mutation. */
export type CreateLoadedLicenseSessionPayloadLoadedLicenseSessionEdgeArgs = {
  orderBy?: Maybe<Array<LoadedLicenseSessionsOrderBy>>;
};

/** All input for the create `Product` mutation. */
export type CreateProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` to be created by this mutation. */
  product: ProductInput;
};

/** The output of our create `Product` mutation. */
export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was created by this mutation. */
  product?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Product`. May be used by Relay 1. */
  productEdge?: Maybe<ProductsEdge>;
};


/** The output of our create `Product` mutation. */
export type CreateProductPayloadProductEdgeArgs = {
  orderBy?: Maybe<Array<ProductsOrderBy>>;
};

/** All input for the create `ReportAllProduct` mutation. */
export type CreateReportAllProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReportAllProduct` to be created by this mutation. */
  reportAllProduct: ReportAllProductInput;
};

/** The output of our create `ReportAllProduct` mutation. */
export type CreateReportAllProductPayload = {
  __typename?: 'CreateReportAllProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReportAllProduct` that was created by this mutation. */
  reportAllProduct?: Maybe<ReportAllProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `ReportAllProduct`. May be used by Relay 1. */
  reportAllProductEdge?: Maybe<ReportAllProductsEdge>;
};


/** The output of our create `ReportAllProduct` mutation. */
export type CreateReportAllProductPayloadReportAllProductEdgeArgs = {
  orderBy?: Maybe<Array<ReportAllProductsOrderBy>>;
};

/** All input for the create `Timezone` mutation. */
export type CreateTimezoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Timezone` to be created by this mutation. */
  timezone: TimezoneInput;
};

/** The output of our create `Timezone` mutation. */
export type CreateTimezonePayload = {
  __typename?: 'CreateTimezonePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Timezone` that was created by this mutation. */
  timezone?: Maybe<Timezone>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Timezone`. May be used by Relay 1. */
  timezoneEdge?: Maybe<TimezonesEdge>;
};


/** The output of our create `Timezone` mutation. */
export type CreateTimezonePayloadTimezoneEdgeArgs = {
  orderBy?: Maybe<Array<TimezonesOrderBy>>;
};

/** All input for the create `UserCustomer` mutation. */
export type CreateUserCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserCustomer` to be created by this mutation. */
  userCustomer: UserCustomerInput;
};

/** The output of our create `UserCustomer` mutation. */
export type CreateUserCustomerPayload = {
  __typename?: 'CreateUserCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserCustomer` that was created by this mutation. */
  userCustomer?: Maybe<UserCustomer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserCustomer`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Customer` that is related to this `UserCustomer`. */
  customerByCustomerId?: Maybe<Customer>;
  /** An edge for our `UserCustomer`. May be used by Relay 1. */
  userCustomerEdge?: Maybe<UserCustomersEdge>;
};


/** The output of our create `UserCustomer` mutation. */
export type CreateUserCustomerPayloadUserCustomerEdgeArgs = {
  orderBy?: Maybe<Array<UserCustomersOrderBy>>;
};

/** All input for the create `UserFile` mutation. */
export type CreateUserFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserFile` to be created by this mutation. */
  userFile: UserFileInput;
};

/** The output of our create `UserFile` mutation. */
export type CreateUserFilePayload = {
  __typename?: 'CreateUserFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserFile` that was created by this mutation. */
  userFile?: Maybe<UserFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserFile`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `UserFile`. May be used by Relay 1. */
  userFileEdge?: Maybe<UserFilesEdge>;
};


/** The output of our create `UserFile` mutation. */
export type CreateUserFilePayloadUserFileEdgeArgs = {
  orderBy?: Maybe<Array<UserFilesOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `User`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the create `Venue` mutation. */
export type CreateVenueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Venue` to be created by this mutation. */
  venue: VenueInput;
};

/** The output of our create `Venue` mutation. */
export type CreateVenuePayload = {
  __typename?: 'CreateVenuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Venue` that was created by this mutation. */
  venue?: Maybe<Venue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Customer` that is related to this `Venue`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Timezone` that is related to this `Venue`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `Venue`. May be used by Relay 1. */
  venueEdge?: Maybe<VenuesEdge>;
};


/** The output of our create `Venue` mutation. */
export type CreateVenuePayloadVenueEdgeArgs = {
  orderBy?: Maybe<Array<VenuesOrderBy>>;
};

/** Exchange rate for each currency per each day. */
export type CurrencyExchangeRate = Node & {
  __typename?: 'CurrencyExchangeRate';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  currency: Scalars['CurrencyType'];
  rate: Scalars['PriceNumberType'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `CurrencyExchangeRate` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CurrencyExchangeRateCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `day` field. */
  day?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `rate` field. */
  rate?: Maybe<Scalars['PriceNumberType']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `CurrencyExchangeRate` object types. All fields are combined with a logical ‘and.’ */
export type CurrencyExchangeRateFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<IntFilter>;
  /** Filter by the object’s `day` field. */
  day?: Maybe<IntFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `rate` field. */
  rate?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CurrencyExchangeRateFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CurrencyExchangeRateFilter>>;
  /** Negates the expression. */
  not?: Maybe<CurrencyExchangeRateFilter>;
};

/** An input for mutations affecting `CurrencyExchangeRate` */
export type CurrencyExchangeRateInput = {
  id?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['CurrencyType']>;
  rate?: Maybe<Scalars['PriceNumberType']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `CurrencyExchangeRate`. Fields that are set will be updated. */
export type CurrencyExchangeRatePatch = {
  id?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['CurrencyType']>;
  rate?: Maybe<Scalars['PriceNumberType']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `CurrencyExchangeRate` values. */
export type CurrencyExchangeRatesConnection = {
  __typename?: 'CurrencyExchangeRatesConnection';
  /** A list of `CurrencyExchangeRate` objects. */
  nodes: Array<Maybe<CurrencyExchangeRate>>;
  /** A list of edges which contains the `CurrencyExchangeRate` and cursor to aid in pagination. */
  edges: Array<CurrencyExchangeRatesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrencyExchangeRate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrencyExchangeRate` edge in the connection. */
export type CurrencyExchangeRatesEdge = {
  __typename?: 'CurrencyExchangeRatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrencyExchangeRate` at the end of the edge. */
  node?: Maybe<CurrencyExchangeRate>;
};

export type CurrencyExchangeRatesFiltered = {
  __typename?: 'CurrencyExchangeRatesFiltered';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  currency: Scalars['CurrencyType'];
  rate: Scalars['PriceNumberType'];
};

/**
 * A condition to be used against `CurrencyExchangeRatesFiltered` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CurrencyExchangeRatesFilteredCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `day` field. */
  day?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `rate` field. */
  rate?: Maybe<Scalars['PriceNumberType']>;
};

/** A filter to be used against `CurrencyExchangeRatesFiltered` object types. All fields are combined with a logical ‘and.’ */
export type CurrencyExchangeRatesFilteredFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<IntFilter>;
  /** Filter by the object’s `day` field. */
  day?: Maybe<IntFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `rate` field. */
  rate?: Maybe<PriceNumberTypeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CurrencyExchangeRatesFilteredFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CurrencyExchangeRatesFilteredFilter>>;
  /** Negates the expression. */
  not?: Maybe<CurrencyExchangeRatesFilteredFilter>;
};

/** An input for mutations affecting `CurrencyExchangeRatesFiltered` */
export type CurrencyExchangeRatesFilteredInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['CurrencyType']>;
  rate?: Maybe<Scalars['PriceNumberType']>;
};

/** A connection to a list of `CurrencyExchangeRatesFiltered` values. */
export type CurrencyExchangeRatesFilteredsConnection = {
  __typename?: 'CurrencyExchangeRatesFilteredsConnection';
  /** A list of `CurrencyExchangeRatesFiltered` objects. */
  nodes: Array<Maybe<CurrencyExchangeRatesFiltered>>;
  /** A list of edges which contains the `CurrencyExchangeRatesFiltered` and cursor to aid in pagination. */
  edges: Array<CurrencyExchangeRatesFilteredsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrencyExchangeRatesFiltered` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrencyExchangeRatesFiltered` edge in the connection. */
export type CurrencyExchangeRatesFilteredsEdge = {
  __typename?: 'CurrencyExchangeRatesFilteredsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrencyExchangeRatesFiltered` at the end of the edge. */
  node?: Maybe<CurrencyExchangeRatesFiltered>;
};

/** Methods to use when ordering `CurrencyExchangeRatesFiltered`. */
export enum CurrencyExchangeRatesFilteredsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  DayAsc = 'DAY_ASC',
  DayDesc = 'DAY_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC'
}

export type CurrencyExchangeRatesFilteredYearAgg = {
  __typename?: 'CurrencyExchangeRatesFilteredYearAgg';
  year?: Maybe<Scalars['Int']>;
  currency: Scalars['CurrencyType'];
  rateAvg?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CurrencyExchangeRatesFilteredYearAgg` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type CurrencyExchangeRatesFilteredYearAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `rateAvg` field. */
  rateAvg?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `CurrencyExchangeRatesFilteredYearAgg` object types.
 * All fields are combined with a logical ‘and.’
 */
export type CurrencyExchangeRatesFilteredYearAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `rateAvg` field. */
  rateAvg?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CurrencyExchangeRatesFilteredYearAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CurrencyExchangeRatesFilteredYearAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<CurrencyExchangeRatesFilteredYearAggFilter>;
};

/** A connection to a list of `CurrencyExchangeRatesFilteredYearAgg` values. */
export type CurrencyExchangeRatesFilteredYearAggsConnection = {
  __typename?: 'CurrencyExchangeRatesFilteredYearAggsConnection';
  /** A list of `CurrencyExchangeRatesFilteredYearAgg` objects. */
  nodes: Array<Maybe<CurrencyExchangeRatesFilteredYearAgg>>;
  /** A list of edges which contains the `CurrencyExchangeRatesFilteredYearAgg` and cursor to aid in pagination. */
  edges: Array<CurrencyExchangeRatesFilteredYearAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrencyExchangeRatesFilteredYearAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrencyExchangeRatesFilteredYearAgg` edge in the connection. */
export type CurrencyExchangeRatesFilteredYearAggsEdge = {
  __typename?: 'CurrencyExchangeRatesFilteredYearAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrencyExchangeRatesFilteredYearAgg` at the end of the edge. */
  node?: Maybe<CurrencyExchangeRatesFilteredYearAgg>;
};

/** Methods to use when ordering `CurrencyExchangeRatesFilteredYearAgg`. */
export enum CurrencyExchangeRatesFilteredYearAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RateAvgAsc = 'RATE_AVG_ASC',
  RateAvgDesc = 'RATE_AVG_DESC'
}

export type CurrencyExchangeRatesFilteredYearMonthAgg = {
  __typename?: 'CurrencyExchangeRatesFilteredYearMonthAgg';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  currency: Scalars['CurrencyType'];
  rateAvg?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CurrencyExchangeRatesFilteredYearMonthAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type CurrencyExchangeRatesFilteredYearMonthAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `rateAvg` field. */
  rateAvg?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `CurrencyExchangeRatesFilteredYearMonthAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type CurrencyExchangeRatesFilteredYearMonthAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<IntFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `rateAvg` field. */
  rateAvg?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CurrencyExchangeRatesFilteredYearMonthAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CurrencyExchangeRatesFilteredYearMonthAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggFilter>;
};

/** A connection to a list of `CurrencyExchangeRatesFilteredYearMonthAgg` values. */
export type CurrencyExchangeRatesFilteredYearMonthAggsConnection = {
  __typename?: 'CurrencyExchangeRatesFilteredYearMonthAggsConnection';
  /** A list of `CurrencyExchangeRatesFilteredYearMonthAgg` objects. */
  nodes: Array<Maybe<CurrencyExchangeRatesFilteredYearMonthAgg>>;
  /** A list of edges which contains the `CurrencyExchangeRatesFilteredYearMonthAgg` and cursor to aid in pagination. */
  edges: Array<CurrencyExchangeRatesFilteredYearMonthAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrencyExchangeRatesFilteredYearMonthAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrencyExchangeRatesFilteredYearMonthAgg` edge in the connection. */
export type CurrencyExchangeRatesFilteredYearMonthAggsEdge = {
  __typename?: 'CurrencyExchangeRatesFilteredYearMonthAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrencyExchangeRatesFilteredYearMonthAgg` at the end of the edge. */
  node?: Maybe<CurrencyExchangeRatesFilteredYearMonthAgg>;
};

/** Methods to use when ordering `CurrencyExchangeRatesFilteredYearMonthAgg`. */
export enum CurrencyExchangeRatesFilteredYearMonthAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RateAvgAsc = 'RATE_AVG_ASC',
  RateAvgDesc = 'RATE_AVG_DESC'
}

/** Methods to use when ordering `CurrencyExchangeRate`. */
export enum CurrencyExchangeRatesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  DayAsc = 'DAY_ASC',
  DayDesc = 'DAY_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against CurrencyType fields. All fields are combined with a logical ‘and.’ */
export type CurrencyTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['CurrencyType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['CurrencyType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['CurrencyType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['CurrencyType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['CurrencyType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['CurrencyType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['CurrencyType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['CurrencyType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['CurrencyType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['CurrencyType']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['CurrencyType']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['CurrencyType']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['CurrencyType']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['CurrencyType']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['CurrencyType']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['CurrencyType']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['CurrencyType']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['CurrencyType']>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['CurrencyType']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['CurrencyType']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['CurrencyType']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['CurrencyType']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['CurrencyType']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['CurrencyType']>;
};


/** A customer of the system. */
export type Customer = Node & {
  __typename?: 'Customer';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  name: Scalars['NameType'];
  isArchived: Scalars['Boolean'];
  priceDefault?: Maybe<PriceDefinition>;
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinition>>>;
  royaltyDefault?: Maybe<RoyaltyDefinition>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `LicenseSession`. */
  licenseSessionsByCustomerId: LicenseSessionsConnection;
  /** Reads and enables pagination through a set of `Venue`. */
  venuesByCustomerId: VenuesConnection;
  /** Reads and enables pagination through a set of `UserCustomer`. */
  userCustomersByCustomerId: UserCustomersConnection;
};


/** A customer of the system. */
export type CustomerLicenseSessionsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** A customer of the system. */
export type CustomerVenuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VenuesOrderBy>>;
  condition?: Maybe<VenueCondition>;
  filter?: Maybe<VenueFilter>;
};


/** A customer of the system. */
export type CustomerUserCustomersByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserCustomersOrderBy>>;
  condition?: Maybe<UserCustomerCondition>;
  filter?: Maybe<UserCustomerFilter>;
};

/**
 * A condition to be used against `Customer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CustomerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['NameType']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `priceDefault` field. */
  priceDefault?: Maybe<PriceDefinitionInput>;
  /** Checks for equality with the object’s `priceCalendarDefault` field. */
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  /** Checks for equality with the object’s `royaltyDefault` field. */
  royaltyDefault?: Maybe<RoyaltyDefinitionInput>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A filter to be used against `Customer` object types. All fields are combined with a logical ‘and.’ */
export type CustomerFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<NameTypeFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: Maybe<StringListFilter>;
  /** Filter by the object’s `priceDefault` field. */
  priceDefault?: Maybe<PriceDefinitionFilter>;
  /** Filter by the object’s `royaltyDefault` field. */
  royaltyDefault?: Maybe<RoyaltyDefinitionFilter>;
  /** Filter by the object’s `licenseSessionsByCustomerId` relation. */
  licenseSessionsByCustomerId?: Maybe<CustomerToManyLicenseSessionFilter>;
  /** Some related `licenseSessionsByCustomerId` exist. */
  licenseSessionsByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `venuesByCustomerId` relation. */
  venuesByCustomerId?: Maybe<CustomerToManyVenueFilter>;
  /** Some related `venuesByCustomerId` exist. */
  venuesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userCustomersByCustomerId` relation. */
  userCustomersByCustomerId?: Maybe<CustomerToManyUserCustomerFilter>;
  /** Some related `userCustomersByCustomerId` exist. */
  userCustomersByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CustomerFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CustomerFilter>>;
  /** Negates the expression. */
  not?: Maybe<CustomerFilter>;
};

/** An input for mutations affecting `Customer` */
export type CustomerInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['NameType'];
  isArchived?: Maybe<Scalars['Boolean']>;
  priceDefault?: Maybe<PriceDefinitionInput>;
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  royaltyDefault?: Maybe<RoyaltyDefinitionInput>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Represents an update to a `Customer`. Fields that are set will be updated. */
export type CustomerPatch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['NameType']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  priceDefault?: Maybe<PriceDefinitionInput>;
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  royaltyDefault?: Maybe<RoyaltyDefinitionInput>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A connection to a list of `Customer` values. */
export type CustomersConnection = {
  __typename?: 'CustomersConnection';
  /** A list of `Customer` objects. */
  nodes: Array<Maybe<Customer>>;
  /** A list of edges which contains the `Customer` and cursor to aid in pagination. */
  edges: Array<CustomersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Customer` edge in the connection. */
export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Customer` at the end of the edge. */
  node?: Maybe<Customer>;
};

/** Methods to use when ordering `Customer`. */
export enum CustomersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  PriceDefaultAsc = 'PRICE_DEFAULT_ASC',
  PriceDefaultDesc = 'PRICE_DEFAULT_DESC',
  PriceCalendarDefaultAsc = 'PRICE_CALENDAR_DEFAULT_ASC',
  PriceCalendarDefaultDesc = 'PRICE_CALENDAR_DEFAULT_DESC',
  RoyaltyDefaultAsc = 'ROYALTY_DEFAULT_ASC',
  RoyaltyDefaultDesc = 'ROYALTY_DEFAULT_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type CustomerToManyLicenseSessionFilter = {
  /** Every related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionFilter>;
  /** Some related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionFilter>;
  /** No related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionFilter>;
};

/** A filter to be used against many `UserCustomer` object types. All fields are combined with a logical ‘and.’ */
export type CustomerToManyUserCustomerFilter = {
  /** Every related `UserCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserCustomerFilter>;
  /** Some related `UserCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserCustomerFilter>;
  /** No related `UserCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserCustomerFilter>;
};

/** A filter to be used against many `Venue` object types. All fields are combined with a logical ‘and.’ */
export type CustomerToManyVenueFilter = {
  /** Every related `Venue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<VenueFilter>;
  /** Some related `Venue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<VenueFilter>;
  /** No related `Venue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<VenueFilter>;
};


/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Date']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Date']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Date']>;
};


/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteCurrencyExchangeRateById` mutation. */
export type DeleteCurrencyExchangeRateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteCurrencyExchangeRateByYearAndMonthAndDayAndCurrency` mutation. */
export type DeleteCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  currency: Scalars['CurrencyType'];
};

/** All input for the `deleteCurrencyExchangeRate` mutation. */
export type DeleteCurrencyExchangeRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CurrencyExchangeRate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `CurrencyExchangeRate` mutation. */
export type DeleteCurrencyExchangeRatePayload = {
  __typename?: 'DeleteCurrencyExchangeRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrencyExchangeRate` that was deleted by this mutation. */
  currencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  deletedCurrencyExchangeRateId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CurrencyExchangeRate`. May be used by Relay 1. */
  currencyExchangeRateEdge?: Maybe<CurrencyExchangeRatesEdge>;
};


/** The output of our delete `CurrencyExchangeRate` mutation. */
export type DeleteCurrencyExchangeRatePayloadCurrencyExchangeRateEdgeArgs = {
  orderBy?: Maybe<Array<CurrencyExchangeRatesOrderBy>>;
};

/** All input for the `deleteCustomerById` mutation. */
export type DeleteCustomerByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteCustomerByName` mutation. */
export type DeleteCustomerByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['NameType'];
};

/** All input for the `deleteCustomer` mutation. */
export type DeleteCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Customer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Customer` mutation. */
export type DeleteCustomerPayload = {
  __typename?: 'DeleteCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Customer` that was deleted by this mutation. */
  customer?: Maybe<Customer>;
  deletedCustomerId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Customer`. May be used by Relay 1. */
  customerEdge?: Maybe<CustomersEdge>;
};


/** The output of our delete `Customer` mutation. */
export type DeleteCustomerPayloadCustomerEdgeArgs = {
  orderBy?: Maybe<Array<CustomersOrderBy>>;
};

/** All input for the `deleteLeaderboardPlayerScoreById` mutation. */
export type DeleteLeaderboardPlayerScoreByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerId` mutation. */
export type DeleteLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leaderboardScoreId: Scalars['UUID'];
  playerId: Scalars['String'];
};

/** All input for the `deleteLeaderboardPlayerScore` mutation. */
export type DeleteLeaderboardPlayerScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardPlayerScore` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LeaderboardPlayerScore` mutation. */
export type DeleteLeaderboardPlayerScorePayload = {
  __typename?: 'DeleteLeaderboardPlayerScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardPlayerScore` that was deleted by this mutation. */
  leaderboardPlayerScore?: Maybe<LeaderboardPlayerScore>;
  deletedLeaderboardPlayerScoreId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardTeamScore` that is related to this `LeaderboardPlayerScore`. */
  leaderboardTeamScoreByLeaderboardScoreId?: Maybe<LeaderboardTeamScore>;
  /** An edge for our `LeaderboardPlayerScore`. May be used by Relay 1. */
  leaderboardPlayerScoreEdge?: Maybe<LeaderboardPlayerScoresEdge>;
};


/** The output of our delete `LeaderboardPlayerScore` mutation. */
export type DeleteLeaderboardPlayerScorePayloadLeaderboardPlayerScoreEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardPlayerScoresOrderBy>>;
};

/** All input for the `deleteLeaderboardRoundById` mutation. */
export type DeleteLeaderboardRoundByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeId` mutation. */
export type DeleteLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leaderboardSessionId: Scalars['UUID'];
  roundNum: Scalars['Int'];
  modeId: Scalars['String'];
};

/** All input for the `deleteLeaderboardRound` mutation. */
export type DeleteLeaderboardRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardRound` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LeaderboardRound` mutation. */
export type DeleteLeaderboardRoundPayload = {
  __typename?: 'DeleteLeaderboardRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardRound` that was deleted by this mutation. */
  leaderboardRound?: Maybe<LeaderboardRound>;
  deletedLeaderboardRoundId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardSession` that is related to this `LeaderboardRound`. */
  leaderboardSessionByLeaderboardSessionId?: Maybe<LeaderboardSession>;
  /** An edge for our `LeaderboardRound`. May be used by Relay 1. */
  leaderboardRoundEdge?: Maybe<LeaderboardRoundsEdge>;
};


/** The output of our delete `LeaderboardRound` mutation. */
export type DeleteLeaderboardRoundPayloadLeaderboardRoundEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardRoundsOrderBy>>;
};

/** All input for the `deleteLeaderboardSessionById` mutation. */
export type DeleteLeaderboardSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionId` mutation. */
export type DeleteLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseSessionId: Scalars['UUID'];
  reportedLeaderboardSessionId: Scalars['String'];
};

/** All input for the `deleteLeaderboardSession` mutation. */
export type DeleteLeaderboardSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LeaderboardSession` mutation. */
export type DeleteLeaderboardSessionPayload = {
  __typename?: 'DeleteLeaderboardSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardSession` that was deleted by this mutation. */
  leaderboardSession?: Maybe<LeaderboardSession>;
  deletedLeaderboardSessionId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseSession` that is related to this `LeaderboardSession`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** An edge for our `LeaderboardSession`. May be used by Relay 1. */
  leaderboardSessionEdge?: Maybe<LeaderboardSessionsEdge>;
};


/** The output of our delete `LeaderboardSession` mutation. */
export type DeleteLeaderboardSessionPayloadLeaderboardSessionEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardSessionsOrderBy>>;
};

/** All input for the `deleteLeaderboardTeamScoreById` mutation. */
export type DeleteLeaderboardTeamScoreByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLeaderboardTeamScoreByLeaderboardRoundIdAndTeamName` mutation. */
export type DeleteLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leaderboardRoundId: Scalars['UUID'];
  teamName: Scalars['String'];
};

/** All input for the `deleteLeaderboardTeamScore` mutation. */
export type DeleteLeaderboardTeamScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardTeamScore` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LeaderboardTeamScore` mutation. */
export type DeleteLeaderboardTeamScorePayload = {
  __typename?: 'DeleteLeaderboardTeamScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardTeamScore` that was deleted by this mutation. */
  leaderboardTeamScore?: Maybe<LeaderboardTeamScore>;
  deletedLeaderboardTeamScoreId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardRound` that is related to this `LeaderboardTeamScore`. */
  leaderboardRoundByLeaderboardRoundId?: Maybe<LeaderboardRound>;
  /** An edge for our `LeaderboardTeamScore`. May be used by Relay 1. */
  leaderboardTeamScoreEdge?: Maybe<LeaderboardTeamScoresEdge>;
};


/** The output of our delete `LeaderboardTeamScore` mutation. */
export type DeleteLeaderboardTeamScorePayloadLeaderboardTeamScoreEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardTeamScoresOrderBy>>;
};

/** All input for the `deleteLicenseById` mutation. */
export type DeleteLicenseByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLicenseByLicenseKey` mutation. */
export type DeleteLicenseByLicenseKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseKey: Scalars['LicenseKeyType'];
};

/** All input for the `deleteLicenseEventById` mutation. */
export type DeleteLicenseEventByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLicenseEvent` mutation. */
export type DeleteLicenseEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LicenseEvent` mutation. */
export type DeleteLicenseEventPayload = {
  __typename?: 'DeleteLicenseEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseEvent` that was deleted by this mutation. */
  licenseEvent?: Maybe<LicenseEvent>;
  deletedLicenseEventId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `License` that is related to this `LicenseEvent`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseEvent`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** An edge for our `LicenseEvent`. May be used by Relay 1. */
  licenseEventEdge?: Maybe<LicenseEventsEdge>;
};


/** The output of our delete `LicenseEvent` mutation. */
export type DeleteLicenseEventPayloadLicenseEventEdgeArgs = {
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
};

/** All input for the `deleteLicense` mutation. */
export type DeleteLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `License` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `License` mutation. */
export type DeleteLicensePayload = {
  __typename?: 'DeleteLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `License` that was deleted by this mutation. */
  license?: Maybe<License>;
  deletedLicenseId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Venue` that is related to this `License`. */
  venueByVenueId?: Maybe<Venue>;
  /** Reads a single `Product` that is related to this `License`. */
  productByProductId?: Maybe<Product>;
  /** An edge for our `License`. May be used by Relay 1. */
  licenseEdge?: Maybe<LicensesEdge>;
};


/** The output of our delete `License` mutation. */
export type DeleteLicensePayloadLicenseEdgeArgs = {
  orderBy?: Maybe<Array<LicensesOrderBy>>;
};

/** All input for the `deleteLicenseSessionById` mutation. */
export type DeleteLicenseSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLicenseSessionCommentById` mutation. */
export type DeleteLicenseSessionCommentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLicenseSessionComment` mutation. */
export type DeleteLicenseSessionCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseSessionComment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LicenseSessionComment` mutation. */
export type DeleteLicenseSessionCommentPayload = {
  __typename?: 'DeleteLicenseSessionCommentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSessionComment` that was deleted by this mutation. */
  licenseSessionComment?: Maybe<LicenseSessionComment>;
  deletedLicenseSessionCommentId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSessionComment`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** Reads a single `User` that is related to this `LicenseSessionComment`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `LicenseSessionComment`. May be used by Relay 1. */
  licenseSessionCommentEdge?: Maybe<LicenseSessionCommentsEdge>;
};


/** The output of our delete `LicenseSessionComment` mutation. */
export type DeleteLicenseSessionCommentPayloadLicenseSessionCommentEdgeArgs = {
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
};

/** All input for the `deleteLicenseSession` mutation. */
export type DeleteLicenseSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LicenseSession` mutation. */
export type DeleteLicenseSessionPayload = {
  __typename?: 'DeleteLicenseSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSession` that was deleted by this mutation. */
  licenseSession?: Maybe<LicenseSession>;
  deletedLicenseSessionId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `License` that is related to this `LicenseSession`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSession`. */
  licenseSessionByMasterSessionId?: Maybe<LicenseSession>;
  /** Reads a single `LicenseV2` that is related to this `LicenseSession`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseSession`. */
  productByProductId?: Maybe<Product>;
  /** Reads a single `Customer` that is related to this `LicenseSession`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Venue` that is related to this `LicenseSession`. */
  venueByVenueId?: Maybe<Venue>;
  /** An edge for our `LicenseSession`. May be used by Relay 1. */
  licenseSessionEdge?: Maybe<LicenseSessionsEdge>;
};


/** The output of our delete `LicenseSession` mutation. */
export type DeleteLicenseSessionPayloadLicenseSessionEdgeArgs = {
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
};

/** All input for the `deleteLicenseV2ById` mutation. */
export type DeleteLicenseV2ByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLicenseV2ByLicenseKey` mutation. */
export type DeleteLicenseV2ByLicenseKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseKey: Scalars['LicenseKeyType'];
};

/** All input for the `deleteLicenseV2EventById` mutation. */
export type DeleteLicenseV2EventByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLicenseV2Event` mutation. */
export type DeleteLicenseV2EventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseV2Event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LicenseV2Event` mutation. */
export type DeleteLicenseV2EventPayload = {
  __typename?: 'DeleteLicenseV2EventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2Event` that was deleted by this mutation. */
  licenseV2Event?: Maybe<LicenseV2Event>;
  deletedLicenseV2EventId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseV2` that is related to this `LicenseV2Event`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseV2Event`. */
  productByProductId?: Maybe<Product>;
  /** An edge for our `LicenseV2Event`. May be used by Relay 1. */
  licenseV2EventEdge?: Maybe<LicenseV2EventsEdge>;
};


/** The output of our delete `LicenseV2Event` mutation. */
export type DeleteLicenseV2EventPayloadLicenseV2EventEdgeArgs = {
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
};

/** All input for the `deleteLicenseV2` mutation. */
export type DeleteLicenseV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseV2` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LicenseV2` mutation. */
export type DeleteLicenseV2Payload = {
  __typename?: 'DeleteLicenseV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2` that was deleted by this mutation. */
  licenseV2?: Maybe<LicenseV2>;
  deletedLicenseV2Id?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Venue` that is related to this `LicenseV2`. */
  venueByVenueId?: Maybe<Venue>;
  /** An edge for our `LicenseV2`. May be used by Relay 1. */
  licenseV2Edge?: Maybe<LicenseV2SEdge>;
};


/** The output of our delete `LicenseV2` mutation. */
export type DeleteLicenseV2PayloadLicenseV2EdgeArgs = {
  orderBy?: Maybe<Array<LicenseV2SOrderBy>>;
};

/** All input for the `deleteLoadedLicenseSessionById` mutation. */
export type DeleteLoadedLicenseSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLoadedLicenseSessionByYearAndMonthAndDay` mutation. */
export type DeleteLoadedLicenseSessionByYearAndMonthAndDayInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};

/** All input for the `deleteLoadedLicenseSession` mutation. */
export type DeleteLoadedLicenseSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LoadedLicenseSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LoadedLicenseSession` mutation. */
export type DeleteLoadedLicenseSessionPayload = {
  __typename?: 'DeleteLoadedLicenseSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LoadedLicenseSession` that was deleted by this mutation. */
  loadedLicenseSession?: Maybe<LoadedLicenseSession>;
  deletedLoadedLicenseSessionId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `LoadedLicenseSession`. May be used by Relay 1. */
  loadedLicenseSessionEdge?: Maybe<LoadedLicenseSessionsEdge>;
};


/** The output of our delete `LoadedLicenseSession` mutation. */
export type DeleteLoadedLicenseSessionPayloadLoadedLicenseSessionEdgeArgs = {
  orderBy?: Maybe<Array<LoadedLicenseSessionsOrderBy>>;
};

/** All input for the `deleteProductById` mutation. */
export type DeleteProductByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteProduct` mutation. */
export type DeleteProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Product` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Product` mutation. */
export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was deleted by this mutation. */
  product?: Maybe<Product>;
  deletedProductId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Product`. May be used by Relay 1. */
  productEdge?: Maybe<ProductsEdge>;
};


/** The output of our delete `Product` mutation. */
export type DeleteProductPayloadProductEdgeArgs = {
  orderBy?: Maybe<Array<ProductsOrderBy>>;
};

/** All input for the `deleteTimezoneByName` mutation. */
export type DeleteTimezoneByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteTimezone` mutation. */
export type DeleteTimezoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Timezone` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Timezone` mutation. */
export type DeleteTimezonePayload = {
  __typename?: 'DeleteTimezonePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Timezone` that was deleted by this mutation. */
  timezone?: Maybe<Timezone>;
  deletedTimezoneId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Timezone`. May be used by Relay 1. */
  timezoneEdge?: Maybe<TimezonesEdge>;
};


/** The output of our delete `Timezone` mutation. */
export type DeleteTimezonePayloadTimezoneEdgeArgs = {
  orderBy?: Maybe<Array<TimezonesOrderBy>>;
};

/** All input for the `deleteUserById` mutation. */
export type DeleteUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteUserByName` mutation. */
export type DeleteUserByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['NameType'];
};

/** All input for the `deleteUserCustomerByUserId` mutation. */
export type DeleteUserCustomerByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** All input for the `deleteUserCustomer` mutation. */
export type DeleteUserCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserCustomer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `UserCustomer` mutation. */
export type DeleteUserCustomerPayload = {
  __typename?: 'DeleteUserCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserCustomer` that was deleted by this mutation. */
  userCustomer?: Maybe<UserCustomer>;
  deletedUserCustomerId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserCustomer`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Customer` that is related to this `UserCustomer`. */
  customerByCustomerId?: Maybe<Customer>;
  /** An edge for our `UserCustomer`. May be used by Relay 1. */
  userCustomerEdge?: Maybe<UserCustomersEdge>;
};


/** The output of our delete `UserCustomer` mutation. */
export type DeleteUserCustomerPayloadUserCustomerEdgeArgs = {
  orderBy?: Maybe<Array<UserCustomersOrderBy>>;
};

/** All input for the `deleteUserFileById` mutation. */
export type DeleteUserFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteUserFile` mutation. */
export type DeleteUserFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `UserFile` mutation. */
export type DeleteUserFilePayload = {
  __typename?: 'DeleteUserFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserFile` that was deleted by this mutation. */
  userFile?: Maybe<UserFile>;
  deletedUserFileId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserFile`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `UserFile`. May be used by Relay 1. */
  userFileEdge?: Maybe<UserFilesEdge>;
};


/** The output of our delete `UserFile` mutation. */
export type DeleteUserFilePayloadUserFileEdgeArgs = {
  orderBy?: Maybe<Array<UserFilesOrderBy>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  deletedUserId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `User`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteVenueByCustomerIdAndCountryAndCityAndLocation` mutation. */
export type DeleteVenueByCustomerIdAndCountryAndCityAndLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  customerId: Scalars['UUID'];
  country: Scalars['String'];
  city: Scalars['String'];
  location: Scalars['NameType'];
};

/** All input for the `deleteVenueById` mutation. */
export type DeleteVenueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteVenue` mutation. */
export type DeleteVenueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Venue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Venue` mutation. */
export type DeleteVenuePayload = {
  __typename?: 'DeleteVenuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Venue` that was deleted by this mutation. */
  venue?: Maybe<Venue>;
  deletedVenueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Customer` that is related to this `Venue`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Timezone` that is related to this `Venue`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `Venue`. May be used by Relay 1. */
  venueEdge?: Maybe<VenuesEdge>;
};


/** The output of our delete `Venue` mutation. */
export type DeleteVenuePayloadVenueEdgeArgs = {
  orderBy?: Maybe<Array<VenuesOrderBy>>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Float']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Float']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Float']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Float']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
};

/** A `String` edge in the connection. */
export type GetAllCustomerTagEdge = {
  __typename?: 'GetAllCustomerTagEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `String` at the end of the edge. */
  node?: Maybe<Scalars['String']>;
};

/** A connection to a list of `String` values. */
export type GetAllCustomerTagsConnection = {
  __typename?: 'GetAllCustomerTagsConnection';
  /** A list of `String` objects. */
  nodes: Array<Maybe<Scalars['String']>>;
  /** A list of edges which contains the `String` and cursor to aid in pagination. */
  edges: Array<GetAllCustomerTagEdge>;
  /** The count of *all* `String` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Datetime` edge in the connection. */
export type GetMissingExchangeRateDateEdge = {
  __typename?: 'GetMissingExchangeRateDateEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Datetime` at the end of the edge. */
  node?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Datetime` values. */
export type GetMissingExchangeRateDatesConnection = {
  __typename?: 'GetMissingExchangeRateDatesConnection';
  /** A list of `Datetime` objects. */
  nodes: Array<Maybe<Scalars['Datetime']>>;
  /** A list of edges which contains the `Datetime` and cursor to aid in pagination. */
  edges: Array<GetMissingExchangeRateDateEdge>;
  /** The count of *all* `Datetime` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};


/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};

export type JwtRefreshToken = {
  __typename?: 'JwtRefreshToken';
  userId?: Maybe<Scalars['UUID']>;
  iat?: Maybe<Scalars['BigInt']>;
  exp?: Maybe<Scalars['BigInt']>;
};

/** An input for mutations affecting `JwtRefreshToken` */
export type JwtRefreshTokenInput = {
  userId?: Maybe<Scalars['UUID']>;
  iat?: Maybe<Scalars['BigInt']>;
  exp?: Maybe<Scalars['BigInt']>;
};

export type JwtToken = {
  __typename?: 'JwtToken';
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  iat?: Maybe<Scalars['BigInt']>;
  exp?: Maybe<Scalars['BigInt']>;
  customerId?: Maybe<Scalars['UUID']>;
};

export type JwtTokenPair = {
  __typename?: 'JwtTokenPair';
  accessToken?: Maybe<JwtToken>;
  refreshToken?: Maybe<JwtRefreshToken>;
};


/** A filter to be used against KeyValueHash fields. All fields are combined with a logical ‘and.’ */
export type KeyValueHashFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['KeyValueHash']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['KeyValueHash']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['KeyValueHash']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['KeyValueHash']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['KeyValueHash']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['KeyValueHash']>>;
  /** Contains the specified KeyValueHash. */
  contains?: Maybe<Scalars['KeyValueHash']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified KeyValueHash. */
  containedBy?: Maybe<Scalars['KeyValueHash']>;
};

export type LeaderboardExtraScore = {
  __typename?: 'LeaderboardExtraScore';
  id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** An input for mutations affecting `LeaderboardExtraScore` */
export type LeaderboardExtraScoreInput = {
  id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Leaderboard player scores. */
export type LeaderboardPlayerScore = Node & {
  __typename?: 'LeaderboardPlayerScore';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  leaderboardScoreId: Scalars['UUID'];
  playerId: Scalars['String'];
  playerName: Scalars['String'];
  score: LeaderboardScore;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  /** Reads a single `LeaderboardTeamScore` that is related to this `LeaderboardPlayerScore`. */
  leaderboardTeamScoreByLeaderboardScoreId?: Maybe<LeaderboardTeamScore>;
};

/**
 * A condition to be used against `LeaderboardPlayerScore` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LeaderboardPlayerScoreCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `leaderboardScoreId` field. */
  leaderboardScoreId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `playerId` field. */
  playerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `playerName` field. */
  playerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `score` field. */
  score?: Maybe<LeaderboardScoreInput>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `extraScores` field. */
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};

/** A filter to be used against `LeaderboardPlayerScore` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardPlayerScoreFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `leaderboardScoreId` field. */
  leaderboardScoreId?: Maybe<UuidFilter>;
  /** Filter by the object’s `playerId` field. */
  playerId?: Maybe<StringFilter>;
  /** Filter by the object’s `playerName` field. */
  playerName?: Maybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: Maybe<StringListFilter>;
  /** Filter by the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `score` field. */
  score?: Maybe<LeaderboardScoreFilter>;
  /** Filter by the object’s `leaderboardTeamScoreByLeaderboardScoreId` relation. */
  leaderboardTeamScoreByLeaderboardScoreId?: Maybe<LeaderboardTeamScoreFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeaderboardPlayerScoreFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeaderboardPlayerScoreFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeaderboardPlayerScoreFilter>;
};

/** An input for mutations affecting `LeaderboardPlayerScore` */
export type LeaderboardPlayerScoreInput = {
  id?: Maybe<Scalars['UUID']>;
  leaderboardScoreId: Scalars['UUID'];
  playerId: Scalars['String'];
  playerName: Scalars['String'];
  score: LeaderboardScoreInput;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};

/** Represents an update to a `LeaderboardPlayerScore`. Fields that are set will be updated. */
export type LeaderboardPlayerScorePatch = {
  id?: Maybe<Scalars['UUID']>;
  leaderboardScoreId?: Maybe<Scalars['UUID']>;
  playerId?: Maybe<Scalars['String']>;
  playerName?: Maybe<Scalars['String']>;
  score?: Maybe<LeaderboardScoreInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};

/** A connection to a list of `LeaderboardPlayerScore` values. */
export type LeaderboardPlayerScoresConnection = {
  __typename?: 'LeaderboardPlayerScoresConnection';
  /** A list of `LeaderboardPlayerScore` objects. */
  nodes: Array<Maybe<LeaderboardPlayerScore>>;
  /** A list of edges which contains the `LeaderboardPlayerScore` and cursor to aid in pagination. */
  edges: Array<LeaderboardPlayerScoresEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeaderboardPlayerScore` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LeaderboardPlayerScore` edge in the connection. */
export type LeaderboardPlayerScoresEdge = {
  __typename?: 'LeaderboardPlayerScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeaderboardPlayerScore` at the end of the edge. */
  node?: Maybe<LeaderboardPlayerScore>;
};

/** Methods to use when ordering `LeaderboardPlayerScore`. */
export enum LeaderboardPlayerScoresOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeaderboardScoreIdAsc = 'LEADERBOARD_SCORE_ID_ASC',
  LeaderboardScoreIdDesc = 'LEADERBOARD_SCORE_ID_DESC',
  PlayerIdAsc = 'PLAYER_ID_ASC',
  PlayerIdDesc = 'PLAYER_ID_DESC',
  PlayerNameAsc = 'PLAYER_NAME_ASC',
  PlayerNameDesc = 'PLAYER_NAME_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  UpdatedAtUtcAsc = 'UPDATED_AT_UTC_ASC',
  UpdatedAtUtcDesc = 'UPDATED_AT_UTC_DESC',
  ExtraScoresAsc = 'EXTRA_SCORES_ASC',
  ExtraScoresDesc = 'EXTRA_SCORES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Leaderboard round. */
export type LeaderboardRound = Node & {
  __typename?: 'LeaderboardRound';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  leaderboardSessionId: Scalars['UUID'];
  roundNum?: Maybe<Scalars['Int']>;
  timeSec: Scalars['Int'];
  modeId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataRaw: Scalars['String'];
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `LeaderboardSession` that is related to this `LeaderboardRound`. */
  leaderboardSessionByLeaderboardSessionId?: Maybe<LeaderboardSession>;
  /** Reads and enables pagination through a set of `LeaderboardTeamScore`. */
  leaderboardTeamScoresByLeaderboardRoundId: LeaderboardTeamScoresConnection;
};


/** Leaderboard round. */
export type LeaderboardRoundLeaderboardTeamScoresByLeaderboardRoundIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardTeamScoresOrderBy>>;
  condition?: Maybe<LeaderboardTeamScoreCondition>;
  filter?: Maybe<LeaderboardTeamScoreFilter>;
};

/**
 * A condition to be used against `LeaderboardRound` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LeaderboardRoundCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `leaderboardSessionId` field. */
  leaderboardSessionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `roundNum` field. */
  roundNum?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `timeSec` field. */
  timeSec?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modeId` field. */
  modeId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `dataRaw` field. */
  dataRaw?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `LeaderboardRound` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardRoundFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `leaderboardSessionId` field. */
  leaderboardSessionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `roundNum` field. */
  roundNum?: Maybe<IntFilter>;
  /** Filter by the object’s `timeSec` field. */
  timeSec?: Maybe<IntFilter>;
  /** Filter by the object’s `modeId` field. */
  modeId?: Maybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: Maybe<StringListFilter>;
  /** Filter by the object’s `dataRaw` field. */
  dataRaw?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `leaderboardTeamScoresByLeaderboardRoundId` relation. */
  leaderboardTeamScoresByLeaderboardRoundId?: Maybe<LeaderboardRoundToManyLeaderboardTeamScoreFilter>;
  /** Some related `leaderboardTeamScoresByLeaderboardRoundId` exist. */
  leaderboardTeamScoresByLeaderboardRoundIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leaderboardSessionByLeaderboardSessionId` relation. */
  leaderboardSessionByLeaderboardSessionId?: Maybe<LeaderboardSessionFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeaderboardRoundFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeaderboardRoundFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeaderboardRoundFilter>;
};

/** An input for mutations affecting `LeaderboardRound` */
export type LeaderboardRoundInput = {
  id?: Maybe<Scalars['UUID']>;
  leaderboardSessionId: Scalars['UUID'];
  roundNum?: Maybe<Scalars['Int']>;
  timeSec: Scalars['Int'];
  modeId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataRaw: Scalars['String'];
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `LeaderboardRound`. Fields that are set will be updated. */
export type LeaderboardRoundPatch = {
  id?: Maybe<Scalars['UUID']>;
  leaderboardSessionId?: Maybe<Scalars['UUID']>;
  roundNum?: Maybe<Scalars['Int']>;
  timeSec?: Maybe<Scalars['Int']>;
  modeId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataRaw?: Maybe<Scalars['String']>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `LeaderboardRound` values. */
export type LeaderboardRoundsConnection = {
  __typename?: 'LeaderboardRoundsConnection';
  /** A list of `LeaderboardRound` objects. */
  nodes: Array<Maybe<LeaderboardRound>>;
  /** A list of edges which contains the `LeaderboardRound` and cursor to aid in pagination. */
  edges: Array<LeaderboardRoundsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeaderboardRound` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LeaderboardRound` edge in the connection. */
export type LeaderboardRoundsEdge = {
  __typename?: 'LeaderboardRoundsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeaderboardRound` at the end of the edge. */
  node?: Maybe<LeaderboardRound>;
};

/** Methods to use when ordering `LeaderboardRound`. */
export enum LeaderboardRoundsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeaderboardSessionIdAsc = 'LEADERBOARD_SESSION_ID_ASC',
  LeaderboardSessionIdDesc = 'LEADERBOARD_SESSION_ID_DESC',
  RoundNumAsc = 'ROUND_NUM_ASC',
  RoundNumDesc = 'ROUND_NUM_DESC',
  TimeSecAsc = 'TIME_SEC_ASC',
  TimeSecDesc = 'TIME_SEC_DESC',
  ModeIdAsc = 'MODE_ID_ASC',
  ModeIdDesc = 'MODE_ID_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  DataRawAsc = 'DATA_RAW_ASC',
  DataRawDesc = 'DATA_RAW_DESC',
  UpdatedAtUtcAsc = 'UPDATED_AT_UTC_ASC',
  UpdatedAtUtcDesc = 'UPDATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LeaderboardTeamScore` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardRoundToManyLeaderboardTeamScoreFilter = {
  /** Every related `LeaderboardTeamScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeaderboardTeamScoreFilter>;
  /** Some related `LeaderboardTeamScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeaderboardTeamScoreFilter>;
  /** No related `LeaderboardTeamScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeaderboardTeamScoreFilter>;
};

export type LeaderboardScore = {
  __typename?: 'LeaderboardScore';
  score?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** A filter to be used against `LeaderboardScore` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardScoreFilter = {
  /** Filter by the object’s `score` field. */
  score?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeaderboardScoreFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeaderboardScoreFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeaderboardScoreFilter>;
};

/** An input for mutations affecting `LeaderboardScore` */
export type LeaderboardScoreInput = {
  score?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Leaderboard session. */
export type LeaderboardSession = Node & {
  __typename?: 'LeaderboardSession';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  licenseSessionId: Scalars['UUID'];
  reportedLeaderboardSessionId: Scalars['String'];
  startTimeLocal: Scalars['Datetime'];
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation: Scalars['String'];
  productId: Scalars['String'];
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  reportedLicenseSessionId?: Maybe<Scalars['String']>;
  /** Reads a single `LicenseSession` that is related to this `LeaderboardSession`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** Reads and enables pagination through a set of `LeaderboardRound`. */
  leaderboardRoundsByLeaderboardSessionId: LeaderboardRoundsConnection;
};


/** Leaderboard session. */
export type LeaderboardSessionLeaderboardRoundsByLeaderboardSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardRoundsOrderBy>>;
  condition?: Maybe<LeaderboardRoundCondition>;
  filter?: Maybe<LeaderboardRoundFilter>;
};

/**
 * A condition to be used against `LeaderboardSession` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LeaderboardSessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseSessionId` field. */
  licenseSessionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `reportedLeaderboardSessionId` field. */
  reportedLeaderboardSessionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `startTimeLocal` field. */
  startTimeLocal?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `reportedMasterSessionId` field. */
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reportedLicenseSessionId` field. */
  reportedLicenseSessionId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `LeaderboardSession` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardSessionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseSessionId` field. */
  licenseSessionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `reportedLeaderboardSessionId` field. */
  reportedLeaderboardSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `startTimeLocal` field. */
  startTimeLocal?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `reportedMasterSessionId` field. */
  reportedMasterSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `reportedLicenseSessionId` field. */
  reportedLicenseSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `leaderboardRoundsByLeaderboardSessionId` relation. */
  leaderboardRoundsByLeaderboardSessionId?: Maybe<LeaderboardSessionToManyLeaderboardRoundFilter>;
  /** Some related `leaderboardRoundsByLeaderboardSessionId` exist. */
  leaderboardRoundsByLeaderboardSessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseSessionByLicenseSessionId` relation. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSessionFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeaderboardSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeaderboardSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeaderboardSessionFilter>;
};

/** An input for mutations affecting `LeaderboardSession` */
export type LeaderboardSessionInput = {
  id?: Maybe<Scalars['UUID']>;
  licenseSessionId: Scalars['UUID'];
  reportedLeaderboardSessionId: Scalars['String'];
  startTimeLocal: Scalars['Datetime'];
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation: Scalars['String'];
  productId: Scalars['String'];
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  reportedLicenseSessionId?: Maybe<Scalars['String']>;
};

/** Represents an update to a `LeaderboardSession`. Fields that are set will be updated. */
export type LeaderboardSessionPatch = {
  id?: Maybe<Scalars['UUID']>;
  licenseSessionId?: Maybe<Scalars['UUID']>;
  reportedLeaderboardSessionId?: Maybe<Scalars['String']>;
  startTimeLocal?: Maybe<Scalars['Datetime']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  reportedLicenseSessionId?: Maybe<Scalars['String']>;
};

/** A connection to a list of `LeaderboardSession` values. */
export type LeaderboardSessionsConnection = {
  __typename?: 'LeaderboardSessionsConnection';
  /** A list of `LeaderboardSession` objects. */
  nodes: Array<Maybe<LeaderboardSession>>;
  /** A list of edges which contains the `LeaderboardSession` and cursor to aid in pagination. */
  edges: Array<LeaderboardSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeaderboardSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LeaderboardSession` edge in the connection. */
export type LeaderboardSessionsEdge = {
  __typename?: 'LeaderboardSessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeaderboardSession` at the end of the edge. */
  node?: Maybe<LeaderboardSession>;
};

/** Methods to use when ordering `LeaderboardSession`. */
export enum LeaderboardSessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseSessionIdAsc = 'LICENSE_SESSION_ID_ASC',
  LicenseSessionIdDesc = 'LICENSE_SESSION_ID_DESC',
  ReportedLeaderboardSessionIdAsc = 'REPORTED_LEADERBOARD_SESSION_ID_ASC',
  ReportedLeaderboardSessionIdDesc = 'REPORTED_LEADERBOARD_SESSION_ID_DESC',
  StartTimeLocalAsc = 'START_TIME_LOCAL_ASC',
  StartTimeLocalDesc = 'START_TIME_LOCAL_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  UpdatedAtUtcAsc = 'UPDATED_AT_UTC_ASC',
  UpdatedAtUtcDesc = 'UPDATED_AT_UTC_DESC',
  ReportedMasterSessionIdAsc = 'REPORTED_MASTER_SESSION_ID_ASC',
  ReportedMasterSessionIdDesc = 'REPORTED_MASTER_SESSION_ID_DESC',
  ReportedLicenseSessionIdAsc = 'REPORTED_LICENSE_SESSION_ID_ASC',
  ReportedLicenseSessionIdDesc = 'REPORTED_LICENSE_SESSION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LeaderboardRound` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardSessionToManyLeaderboardRoundFilter = {
  /** Every related `LeaderboardRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeaderboardRoundFilter>;
  /** Some related `LeaderboardRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeaderboardRoundFilter>;
  /** No related `LeaderboardRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeaderboardRoundFilter>;
};

/** Leaderboard team scores. */
export type LeaderboardTeamScore = Node & {
  __typename?: 'LeaderboardTeamScore';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  leaderboardRoundId: Scalars['UUID'];
  teamName: Scalars['String'];
  teamColor?: Maybe<Scalars['String']>;
  score: LeaderboardScore;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  /** Reads a single `LeaderboardRound` that is related to this `LeaderboardTeamScore`. */
  leaderboardRoundByLeaderboardRoundId?: Maybe<LeaderboardRound>;
  /** Reads and enables pagination through a set of `LeaderboardPlayerScore`. */
  leaderboardPlayerScoresByLeaderboardScoreId: LeaderboardPlayerScoresConnection;
};


/** Leaderboard team scores. */
export type LeaderboardTeamScoreLeaderboardPlayerScoresByLeaderboardScoreIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardPlayerScoresOrderBy>>;
  condition?: Maybe<LeaderboardPlayerScoreCondition>;
  filter?: Maybe<LeaderboardPlayerScoreFilter>;
};

/**
 * A condition to be used against `LeaderboardTeamScore` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LeaderboardTeamScoreCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `leaderboardRoundId` field. */
  leaderboardRoundId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `teamName` field. */
  teamName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `teamColor` field. */
  teamColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `score` field. */
  score?: Maybe<LeaderboardScoreInput>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `extraScores` field. */
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};

/** A filter to be used against `LeaderboardTeamScore` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardTeamScoreFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `leaderboardRoundId` field. */
  leaderboardRoundId?: Maybe<UuidFilter>;
  /** Filter by the object’s `teamName` field. */
  teamName?: Maybe<StringFilter>;
  /** Filter by the object’s `teamColor` field. */
  teamColor?: Maybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: Maybe<StringListFilter>;
  /** Filter by the object’s `updatedAtUtc` field. */
  updatedAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `score` field. */
  score?: Maybe<LeaderboardScoreFilter>;
  /** Filter by the object’s `leaderboardPlayerScoresByLeaderboardScoreId` relation. */
  leaderboardPlayerScoresByLeaderboardScoreId?: Maybe<LeaderboardTeamScoreToManyLeaderboardPlayerScoreFilter>;
  /** Some related `leaderboardPlayerScoresByLeaderboardScoreId` exist. */
  leaderboardPlayerScoresByLeaderboardScoreIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leaderboardRoundByLeaderboardRoundId` relation. */
  leaderboardRoundByLeaderboardRoundId?: Maybe<LeaderboardRoundFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeaderboardTeamScoreFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeaderboardTeamScoreFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeaderboardTeamScoreFilter>;
};

/** An input for mutations affecting `LeaderboardTeamScore` */
export type LeaderboardTeamScoreInput = {
  id?: Maybe<Scalars['UUID']>;
  leaderboardRoundId: Scalars['UUID'];
  teamName: Scalars['String'];
  teamColor?: Maybe<Scalars['String']>;
  score: LeaderboardScoreInput;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};

/** Represents an update to a `LeaderboardTeamScore`. Fields that are set will be updated. */
export type LeaderboardTeamScorePatch = {
  id?: Maybe<Scalars['UUID']>;
  leaderboardRoundId?: Maybe<Scalars['UUID']>;
  teamName?: Maybe<Scalars['String']>;
  teamColor?: Maybe<Scalars['String']>;
  score?: Maybe<LeaderboardScoreInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAtUtc?: Maybe<Scalars['Datetime']>;
  extraScores?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};

/** A connection to a list of `LeaderboardTeamScore` values. */
export type LeaderboardTeamScoresConnection = {
  __typename?: 'LeaderboardTeamScoresConnection';
  /** A list of `LeaderboardTeamScore` objects. */
  nodes: Array<Maybe<LeaderboardTeamScore>>;
  /** A list of edges which contains the `LeaderboardTeamScore` and cursor to aid in pagination. */
  edges: Array<LeaderboardTeamScoresEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeaderboardTeamScore` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LeaderboardTeamScore` edge in the connection. */
export type LeaderboardTeamScoresEdge = {
  __typename?: 'LeaderboardTeamScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeaderboardTeamScore` at the end of the edge. */
  node?: Maybe<LeaderboardTeamScore>;
};

/** Methods to use when ordering `LeaderboardTeamScore`. */
export enum LeaderboardTeamScoresOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeaderboardRoundIdAsc = 'LEADERBOARD_ROUND_ID_ASC',
  LeaderboardRoundIdDesc = 'LEADERBOARD_ROUND_ID_DESC',
  TeamNameAsc = 'TEAM_NAME_ASC',
  TeamNameDesc = 'TEAM_NAME_DESC',
  TeamColorAsc = 'TEAM_COLOR_ASC',
  TeamColorDesc = 'TEAM_COLOR_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  UpdatedAtUtcAsc = 'UPDATED_AT_UTC_ASC',
  UpdatedAtUtcDesc = 'UPDATED_AT_UTC_DESC',
  ExtraScoresAsc = 'EXTRA_SCORES_ASC',
  ExtraScoresDesc = 'EXTRA_SCORES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LeaderboardPlayerScore` object types. All fields are combined with a logical ‘and.’ */
export type LeaderboardTeamScoreToManyLeaderboardPlayerScoreFilter = {
  /** Every related `LeaderboardPlayerScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeaderboardPlayerScoreFilter>;
  /** Some related `LeaderboardPlayerScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeaderboardPlayerScoreFilter>;
  /** No related `LeaderboardPlayerScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeaderboardPlayerScoreFilter>;
};

/** A product license for the venue. */
export type License = Node & {
  __typename?: 'License';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  venueId: Scalars['UUID'];
  productId: Scalars['UUID'];
  isRevoked: Scalars['Boolean'];
  price: PriceDefinition;
  priceCalendar: Array<Maybe<PriceCalendarDefinition>>;
  royalty: RoyaltyDefinition;
  licenseKey: Scalars['LicenseKeyType'];
  expirateAtUtc: Scalars['Datetime'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Venue` that is related to this `License`. */
  venueByVenueId?: Maybe<Venue>;
  /** Reads a single `Product` that is related to this `License`. */
  productByProductId?: Maybe<Product>;
  /** Reads and enables pagination through a set of `LicenseSession`. */
  licenseSessionsByLicenseId: LicenseSessionsConnection;
  /** Reads and enables pagination through a set of `LicenseEvent`. */
  licenseEventsByLicenseId: LicenseEventsConnection;
};


/** A product license for the venue. */
export type LicenseLicenseSessionsByLicenseIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** A product license for the venue. */
export type LicenseLicenseEventsByLicenseIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
  condition?: Maybe<LicenseEventCondition>;
  filter?: Maybe<LicenseEventFilter>;
};

/** A condition to be used against `License` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LicenseCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isRevoked` field. */
  isRevoked?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<PriceDefinitionInput>;
  /** Checks for equality with the object’s `priceCalendar` field. */
  priceCalendar?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<RoyaltyDefinitionInput>;
  /** Checks for equality with the object’s `licenseKey` field. */
  licenseKey?: Maybe<Scalars['LicenseKeyType']>;
  /** Checks for equality with the object’s `expirateAtUtc` field. */
  expirateAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A license v2 event. */
export type LicenseEvent = Node & {
  __typename?: 'LicenseEvent';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  licenseId?: Maybe<Scalars['UUID']>;
  licenseSessionId?: Maybe<Scalars['UUID']>;
  licenseKey?: Maybe<Scalars['String']>;
  yandexMetricaRaw?: Maybe<Scalars['JSON']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  productVersion?: Maybe<Scalars['String']>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  eventTime: Scalars['Datetime'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `License` that is related to this `LicenseEvent`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseEvent`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
};

/**
 * A condition to be used against `LicenseEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LicenseEventCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseId` field. */
  licenseId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseSessionId` field. */
  licenseSessionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseKey` field. */
  licenseKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `yandexMetricaRaw` field. */
  yandexMetricaRaw?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `reportedSessionId` field. */
  reportedSessionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `osVersion` field. */
  osVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productVersion` field. */
  productVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPaid` field. */
  isPaid?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `comment` field. */
  comment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventTime` field. */
  eventTime?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `LicenseEvent` object types. All fields are combined with a logical ‘and.’ */
export type LicenseEventFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseId` field. */
  licenseId?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseSessionId` field. */
  licenseSessionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseKey` field. */
  licenseKey?: Maybe<StringFilter>;
  /** Filter by the object’s `reportedSessionId` field. */
  reportedSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `osVersion` field. */
  osVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `productVersion` field. */
  productVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<IntFilter>;
  /** Filter by the object’s `isPaid` field. */
  isPaid?: Maybe<BooleanFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<StringFilter>;
  /** Filter by the object’s `comment` field. */
  comment?: Maybe<StringFilter>;
  /** Filter by the object’s `eventTime` field. */
  eventTime?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `licenseByLicenseId` relation. */
  licenseByLicenseId?: Maybe<LicenseFilter>;
  /** A related `licenseByLicenseId` exists. */
  licenseByLicenseIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseSessionByLicenseSessionId` relation. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSessionFilter>;
  /** A related `licenseSessionByLicenseSessionId` exists. */
  licenseSessionByLicenseSessionIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseEventFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseEventFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseEventFilter>;
};

/** An input for mutations affecting `LicenseEvent` */
export type LicenseEventInput = {
  id?: Maybe<Scalars['UUID']>;
  licenseId?: Maybe<Scalars['UUID']>;
  licenseSessionId?: Maybe<Scalars['UUID']>;
  licenseKey?: Maybe<Scalars['String']>;
  yandexMetricaRaw?: Maybe<Scalars['JSON']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  productVersion?: Maybe<Scalars['String']>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  eventTime: Scalars['Datetime'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `LicenseEvent`. Fields that are set will be updated. */
export type LicenseEventPatch = {
  id?: Maybe<Scalars['UUID']>;
  licenseId?: Maybe<Scalars['UUID']>;
  licenseSessionId?: Maybe<Scalars['UUID']>;
  licenseKey?: Maybe<Scalars['String']>;
  yandexMetricaRaw?: Maybe<Scalars['JSON']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  productVersion?: Maybe<Scalars['String']>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['Datetime']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `LicenseEvent` values. */
export type LicenseEventsConnection = {
  __typename?: 'LicenseEventsConnection';
  /** A list of `LicenseEvent` objects. */
  nodes: Array<Maybe<LicenseEvent>>;
  /** A list of edges which contains the `LicenseEvent` and cursor to aid in pagination. */
  edges: Array<LicenseEventsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseEvent` edge in the connection. */
export type LicenseEventsEdge = {
  __typename?: 'LicenseEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseEvent` at the end of the edge. */
  node?: Maybe<LicenseEvent>;
};

/** Methods to use when ordering `LicenseEvent`. */
export enum LicenseEventsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseIdAsc = 'LICENSE_ID_ASC',
  LicenseIdDesc = 'LICENSE_ID_DESC',
  LicenseSessionIdAsc = 'LICENSE_SESSION_ID_ASC',
  LicenseSessionIdDesc = 'LICENSE_SESSION_ID_DESC',
  LicenseKeyAsc = 'LICENSE_KEY_ASC',
  LicenseKeyDesc = 'LICENSE_KEY_DESC',
  YandexMetricaRawAsc = 'YANDEX_METRICA_RAW_ASC',
  YandexMetricaRawDesc = 'YANDEX_METRICA_RAW_DESC',
  ReportedSessionIdAsc = 'REPORTED_SESSION_ID_ASC',
  ReportedSessionIdDesc = 'REPORTED_SESSION_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OsVersionAsc = 'OS_VERSION_ASC',
  OsVersionDesc = 'OS_VERSION_DESC',
  ProductVersionAsc = 'PRODUCT_VERSION_ASC',
  ProductVersionDesc = 'PRODUCT_VERSION_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  IsPaidAsc = 'IS_PAID_ASC',
  IsPaidDesc = 'IS_PAID_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  EventTimeAsc = 'EVENT_TIME_ASC',
  EventTimeDesc = 'EVENT_TIME_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `License` object types. All fields are combined with a logical ‘and.’ */
export type LicenseFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<UuidFilter>;
  /** Filter by the object’s `isRevoked` field. */
  isRevoked?: Maybe<BooleanFilter>;
  /** Filter by the object’s `licenseKey` field. */
  licenseKey?: Maybe<LicenseKeyTypeFilter>;
  /** Filter by the object’s `expirateAtUtc` field. */
  expirateAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<PriceDefinitionFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<RoyaltyDefinitionFilter>;
  /** Filter by the object’s `licenseSessionsByLicenseId` relation. */
  licenseSessionsByLicenseId?: Maybe<LicenseToManyLicenseSessionFilter>;
  /** Some related `licenseSessionsByLicenseId` exist. */
  licenseSessionsByLicenseIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseEventsByLicenseId` relation. */
  licenseEventsByLicenseId?: Maybe<LicenseToManyLicenseEventFilter>;
  /** Some related `licenseEventsByLicenseId` exist. */
  licenseEventsByLicenseIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `venueByVenueId` relation. */
  venueByVenueId?: Maybe<VenueFilter>;
  /** Filter by the object’s `productByProductId` relation. */
  productByProductId?: Maybe<ProductFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseFilter>;
};

/** An input for mutations affecting `License` */
export type LicenseInput = {
  id?: Maybe<Scalars['UUID']>;
  venueId: Scalars['UUID'];
  productId: Scalars['UUID'];
  isRevoked?: Maybe<Scalars['Boolean']>;
  price: PriceDefinitionInput;
  priceCalendar?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  royalty: RoyaltyDefinitionInput;
  licenseKey: Scalars['LicenseKeyType'];
  expirateAtUtc: Scalars['Datetime'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};


/** A filter to be used against LicenseKeyType fields. All fields are combined with a logical ‘and.’ */
export type LicenseKeyTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['LicenseKeyType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['LicenseKeyType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['LicenseKeyType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['LicenseKeyType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['LicenseKeyType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['LicenseKeyType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['LicenseKeyType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['LicenseKeyType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['LicenseKeyType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['LicenseKeyType']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['LicenseKeyType']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['LicenseKeyType']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['LicenseKeyType']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['LicenseKeyType']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['LicenseKeyType']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['LicenseKeyType']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['LicenseKeyType']>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['LicenseKeyType']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['LicenseKeyType']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['LicenseKeyType']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['LicenseKeyType']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['LicenseKeyType']>;
};

/** Represents an update to a `License`. Fields that are set will be updated. */
export type LicensePatch = {
  id?: Maybe<Scalars['UUID']>;
  venueId?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  isRevoked?: Maybe<Scalars['Boolean']>;
  price?: Maybe<PriceDefinitionInput>;
  priceCalendar?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  royalty?: Maybe<RoyaltyDefinitionInput>;
  licenseKey?: Maybe<Scalars['LicenseKeyType']>;
  expirateAtUtc?: Maybe<Scalars['Datetime']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `License` values. */
export type LicensesConnection = {
  __typename?: 'LicensesConnection';
  /** A list of `License` objects. */
  nodes: Array<Maybe<License>>;
  /** A list of edges which contains the `License` and cursor to aid in pagination. */
  edges: Array<LicensesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `License` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `License` edge in the connection. */
export type LicensesEdge = {
  __typename?: 'LicensesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `License` at the end of the edge. */
  node?: Maybe<License>;
};

/** A license session. */
export type LicenseSession = Node & {
  __typename?: 'LicenseSession';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  licenseId?: Maybe<Scalars['UUID']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenseKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  players?: Maybe<Scalars['Int']>;
  isPaid: Scalars['Boolean'];
  isPaidApproved: Scalars['Boolean'];
  isPaidOriginal: Scalars['Boolean'];
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  startTimeLocal: Scalars['Datetime'];
  endTimeLocal: Scalars['Datetime'];
  price: PriceDefinition;
  priceApproved: PriceDefinition;
  priceOriginal: PriceDefinition;
  royalty: RoyaltyDefinition;
  royaltyOriginal: RoyaltyDefinition;
  isLocked: Scalars['Boolean'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  masterSessionId?: Maybe<Scalars['UUID']>;
  licenseV2Id?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  locationCoords?: Maybe<Array<Maybe<Scalars['String']>>>;
  ip?: Maybe<Array<Maybe<Scalars['String']>>>;
  productStateAgg?: Maybe<Scalars['JSON']>;
  customerId?: Maybe<Scalars['UUID']>;
  venueId?: Maybe<Scalars['UUID']>;
  machineid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads a single `License` that is related to this `LicenseSession`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSession`. */
  licenseSessionByMasterSessionId?: Maybe<LicenseSession>;
  /** Reads a single `LicenseV2` that is related to this `LicenseSession`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseSession`. */
  productByProductId?: Maybe<Product>;
  /** Reads a single `Customer` that is related to this `LicenseSession`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Venue` that is related to this `LicenseSession`. */
  venueByVenueId?: Maybe<Venue>;
  /** Reads and enables pagination through a set of `LicenseSession`. */
  licenseSessionsByMasterSessionId: LicenseSessionsConnection;
  /** Reads and enables pagination through a set of `LicenseEvent`. */
  licenseEventsByLicenseSessionId: LicenseEventsConnection;
  /** Reads and enables pagination through a set of `LicenseSessionComment`. */
  licenseSessionCommentsByLicenseSessionId: LicenseSessionCommentsConnection;
  /** Reads and enables pagination through a set of `LeaderboardSession`. */
  leaderboardSessionsByLicenseSessionId: LeaderboardSessionsConnection;
  /** Returns the session duration in minutes. */
  duration?: Maybe<Scalars['Float']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  /** Returns the session approved price discount. */
  priceApprovedDiscount?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total approved price. */
  priceApprovedTotal?: Maybe<Scalars['PriceNumberType']>;
  priceApprovedTotalCurrencyUsdRate?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session price discount. */
  priceDiscount?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total approved price. */
  priceOriginalTotal?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total price. */
  priceTotal?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total discount. */
  royaltyApprovedDiscount?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total approved royalty. */
  royaltyApprovedTotal?: Maybe<Scalars['PriceNumberType']>;
  royaltyCurrencyUsdRate?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session royalty discount. */
  royaltyDiscount?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total approved royalty. */
  royaltyOriginalTotal?: Maybe<Scalars['PriceNumberType']>;
  /** Returns the session total royalty. */
  royaltyTotal?: Maybe<Scalars['PriceNumberType']>;
};


/** A license session. */
export type LicenseSessionLicenseSessionsByMasterSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** A license session. */
export type LicenseSessionLicenseEventsByLicenseSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
  condition?: Maybe<LicenseEventCondition>;
  filter?: Maybe<LicenseEventFilter>;
};


/** A license session. */
export type LicenseSessionLicenseSessionCommentsByLicenseSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
  condition?: Maybe<LicenseSessionCommentCondition>;
  filter?: Maybe<LicenseSessionCommentFilter>;
};


/** A license session. */
export type LicenseSessionLeaderboardSessionsByLicenseSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardSessionsOrderBy>>;
  condition?: Maybe<LeaderboardSessionCondition>;
  filter?: Maybe<LeaderboardSessionFilter>;
};

/** A license session. */
export type LicenseSessionComment = Node & {
  __typename?: 'LicenseSessionComment';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  licenseSessionId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  comment: Scalars['String'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSessionComment`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** Reads a single `User` that is related to this `LicenseSessionComment`. */
  userByUserId?: Maybe<User>;
};

/**
 * A condition to be used against `LicenseSessionComment` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCommentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseSessionId` field. */
  licenseSessionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `comment` field. */
  comment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `LicenseSessionComment` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionCommentFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseSessionId` field. */
  licenseSessionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `comment` field. */
  comment?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `licenseSessionByLicenseSessionId` relation. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSessionFilter>;
  /** A related `licenseSessionByLicenseSessionId` exists. */
  licenseSessionByLicenseSessionIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** A related `userByUserId` exists. */
  userByUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCommentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCommentFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCommentFilter>;
};

/** An input for mutations affecting `LicenseSessionComment` */
export type LicenseSessionCommentInput = {
  id?: Maybe<Scalars['UUID']>;
  licenseSessionId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  comment: Scalars['String'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `LicenseSessionComment`. Fields that are set will be updated. */
export type LicenseSessionCommentPatch = {
  id?: Maybe<Scalars['UUID']>;
  licenseSessionId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  comment?: Maybe<Scalars['String']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `LicenseSessionComment` values. */
export type LicenseSessionCommentsConnection = {
  __typename?: 'LicenseSessionCommentsConnection';
  /** A list of `LicenseSessionComment` objects. */
  nodes: Array<Maybe<LicenseSessionComment>>;
  /** A list of edges which contains the `LicenseSessionComment` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCommentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionComment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionComment` edge in the connection. */
export type LicenseSessionCommentsEdge = {
  __typename?: 'LicenseSessionCommentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionComment` at the end of the edge. */
  node?: Maybe<LicenseSessionComment>;
};

/** Methods to use when ordering `LicenseSessionComment`. */
export enum LicenseSessionCommentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseSessionIdAsc = 'LICENSE_SESSION_ID_ASC',
  LicenseSessionIdDesc = 'LICENSE_SESSION_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `LicenseSession` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseId` field. */
  licenseId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `reportedSessionId` field. */
  reportedSessionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `osVersion` field. */
  osVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `productVersion` field. */
  productVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `licenseKey` field. */
  licenseKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPaid` field. */
  isPaid?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPaidApproved` field. */
  isPaidApproved?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPaidOriginal` field. */
  isPaidOriginal?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `startTimeLocal` field. */
  startTimeLocal?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endTimeLocal` field. */
  endTimeLocal?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<PriceDefinitionInput>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<PriceDefinitionInput>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<PriceDefinitionInput>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<RoyaltyDefinitionInput>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<RoyaltyDefinitionInput>;
  /** Checks for equality with the object’s `isLocked` field. */
  isLocked?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `masterSessionId` field. */
  masterSessionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseV2Id` field. */
  licenseV2Id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `locationCoords` field. */
  locationCoords?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `ip` field. */
  ip?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `productStateAgg` field. */
  productStateAgg?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `machineid` field. */
  machineid?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LicenseSessionCustomerVenueDateAgg = {
  __typename?: 'LicenseSessionCustomerVenueDateAgg';
  date?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unknownAmount?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  paidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  unpaidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  paidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  paidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  unpaidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  paidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  paidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  unpaidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  paidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  paidOriginalDuration?: Maybe<Scalars['Float']>;
  unpaidOriginalDuration?: Maybe<Scalars['Float']>;
  paidSubmittedDuration?: Maybe<Scalars['Float']>;
  unpaidSubmittedDuration?: Maybe<Scalars['Float']>;
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  paidOriginalDurationMin?: Maybe<Scalars['Float']>;
  unpaidOriginalDurationMin?: Maybe<Scalars['Float']>;
  paidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  unpaidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
  paidOriginalDurationMax?: Maybe<Scalars['Float']>;
  unpaidOriginalDurationMax?: Maybe<Scalars['Float']>;
  paidSubmittedDurationMax?: Maybe<Scalars['Float']>;
  unpaidSubmittedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueDateAgg` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueDateAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalPlayers` field. */
  paidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayers` field. */
  unpaidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedPlayers` field. */
  paidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayers` field. */
  unpaidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidOriginalPlayersMin` field. */
  paidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayersMin` field. */
  unpaidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidSubmittedPlayersMin` field. */
  paidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayersMin` field. */
  unpaidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidOriginalPlayersMax` field. */
  paidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayersMax` field. */
  unpaidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidSubmittedPlayersMax` field. */
  paidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayersMax` field. */
  unpaidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDuration` field. */
  paidOriginalDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDuration` field. */
  unpaidOriginalDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDuration` field. */
  paidSubmittedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDuration` field. */
  unpaidSubmittedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDurationMin` field. */
  paidOriginalDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDurationMin` field. */
  unpaidOriginalDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDurationMin` field. */
  paidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDurationMin` field. */
  unpaidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDurationMax` field. */
  paidOriginalDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDurationMax` field. */
  unpaidOriginalDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDurationMax` field. */
  paidSubmittedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDurationMax` field. */
  unpaidSubmittedDurationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionCustomerVenueDateAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionCustomerVenueDateAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalPlayers` field. */
  paidOriginalPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayers` field. */
  unpaidOriginalPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedPlayers` field. */
  paidSubmittedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayers` field. */
  unpaidSubmittedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidOriginalPlayersMin` field. */
  paidOriginalPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayersMin` field. */
  unpaidOriginalPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidSubmittedPlayersMin` field. */
  paidSubmittedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayersMin` field. */
  unpaidSubmittedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidOriginalPlayersMax` field. */
  paidOriginalPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayersMax` field. */
  unpaidOriginalPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidSubmittedPlayersMax` field. */
  paidSubmittedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayersMax` field. */
  unpaidSubmittedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDuration` field. */
  paidOriginalDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDuration` field. */
  unpaidOriginalDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDuration` field. */
  paidSubmittedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDuration` field. */
  unpaidSubmittedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDurationMin` field. */
  paidOriginalDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDurationMin` field. */
  unpaidOriginalDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDurationMin` field. */
  paidSubmittedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDurationMin` field. */
  unpaidSubmittedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDurationMax` field. */
  paidOriginalDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDurationMax` field. */
  unpaidOriginalDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDurationMax` field. */
  paidSubmittedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDurationMax` field. */
  unpaidSubmittedDurationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueDateAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueDateAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueDateAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueDateAgg` values. */
export type LicenseSessionCustomerVenueDateAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueDateAggsConnection';
  /** A list of `LicenseSessionCustomerVenueDateAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueDateAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueDateAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueDateAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueDateAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueDateAgg` edge in the connection. */
export type LicenseSessionCustomerVenueDateAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueDateAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueDateAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueDateAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueDateAgg`. */
export enum LicenseSessionCustomerVenueDateAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidOriginalAmountAsc = 'PAID_ORIGINAL_AMOUNT_ASC',
  PaidOriginalAmountDesc = 'PAID_ORIGINAL_AMOUNT_DESC',
  UnpaidOriginalAmountAsc = 'UNPAID_ORIGINAL_AMOUNT_ASC',
  UnpaidOriginalAmountDesc = 'UNPAID_ORIGINAL_AMOUNT_DESC',
  PaidSubmittedAmountAsc = 'PAID_SUBMITTED_AMOUNT_ASC',
  PaidSubmittedAmountDesc = 'PAID_SUBMITTED_AMOUNT_DESC',
  UnpaidSubmittedAmountAsc = 'UNPAID_SUBMITTED_AMOUNT_ASC',
  UnpaidSubmittedAmountDesc = 'UNPAID_SUBMITTED_AMOUNT_DESC',
  UnknownAmountAsc = 'UNKNOWN_AMOUNT_ASC',
  UnknownAmountDesc = 'UNKNOWN_AMOUNT_DESC',
  PaidApprovedPlayersAsc = 'PAID_APPROVED_PLAYERS_ASC',
  PaidApprovedPlayersDesc = 'PAID_APPROVED_PLAYERS_DESC',
  UnpaidApprovedPlayersAsc = 'UNPAID_APPROVED_PLAYERS_ASC',
  UnpaidApprovedPlayersDesc = 'UNPAID_APPROVED_PLAYERS_DESC',
  PaidOriginalPlayersAsc = 'PAID_ORIGINAL_PLAYERS_ASC',
  PaidOriginalPlayersDesc = 'PAID_ORIGINAL_PLAYERS_DESC',
  UnpaidOriginalPlayersAsc = 'UNPAID_ORIGINAL_PLAYERS_ASC',
  UnpaidOriginalPlayersDesc = 'UNPAID_ORIGINAL_PLAYERS_DESC',
  PaidSubmittedPlayersAsc = 'PAID_SUBMITTED_PLAYERS_ASC',
  PaidSubmittedPlayersDesc = 'PAID_SUBMITTED_PLAYERS_DESC',
  UnpaidSubmittedPlayersAsc = 'UNPAID_SUBMITTED_PLAYERS_ASC',
  UnpaidSubmittedPlayersDesc = 'UNPAID_SUBMITTED_PLAYERS_DESC',
  PaidApprovedPlayersMinAsc = 'PAID_APPROVED_PLAYERS_MIN_ASC',
  PaidApprovedPlayersMinDesc = 'PAID_APPROVED_PLAYERS_MIN_DESC',
  UnpaidApprovedPlayersMinAsc = 'UNPAID_APPROVED_PLAYERS_MIN_ASC',
  UnpaidApprovedPlayersMinDesc = 'UNPAID_APPROVED_PLAYERS_MIN_DESC',
  PaidOriginalPlayersMinAsc = 'PAID_ORIGINAL_PLAYERS_MIN_ASC',
  PaidOriginalPlayersMinDesc = 'PAID_ORIGINAL_PLAYERS_MIN_DESC',
  UnpaidOriginalPlayersMinAsc = 'UNPAID_ORIGINAL_PLAYERS_MIN_ASC',
  UnpaidOriginalPlayersMinDesc = 'UNPAID_ORIGINAL_PLAYERS_MIN_DESC',
  PaidSubmittedPlayersMinAsc = 'PAID_SUBMITTED_PLAYERS_MIN_ASC',
  PaidSubmittedPlayersMinDesc = 'PAID_SUBMITTED_PLAYERS_MIN_DESC',
  UnpaidSubmittedPlayersMinAsc = 'UNPAID_SUBMITTED_PLAYERS_MIN_ASC',
  UnpaidSubmittedPlayersMinDesc = 'UNPAID_SUBMITTED_PLAYERS_MIN_DESC',
  PaidApprovedPlayersMaxAsc = 'PAID_APPROVED_PLAYERS_MAX_ASC',
  PaidApprovedPlayersMaxDesc = 'PAID_APPROVED_PLAYERS_MAX_DESC',
  UnpaidApprovedPlayersMaxAsc = 'UNPAID_APPROVED_PLAYERS_MAX_ASC',
  UnpaidApprovedPlayersMaxDesc = 'UNPAID_APPROVED_PLAYERS_MAX_DESC',
  PaidOriginalPlayersMaxAsc = 'PAID_ORIGINAL_PLAYERS_MAX_ASC',
  PaidOriginalPlayersMaxDesc = 'PAID_ORIGINAL_PLAYERS_MAX_DESC',
  UnpaidOriginalPlayersMaxAsc = 'UNPAID_ORIGINAL_PLAYERS_MAX_ASC',
  UnpaidOriginalPlayersMaxDesc = 'UNPAID_ORIGINAL_PLAYERS_MAX_DESC',
  PaidSubmittedPlayersMaxAsc = 'PAID_SUBMITTED_PLAYERS_MAX_ASC',
  PaidSubmittedPlayersMaxDesc = 'PAID_SUBMITTED_PLAYERS_MAX_DESC',
  UnpaidSubmittedPlayersMaxAsc = 'UNPAID_SUBMITTED_PLAYERS_MAX_ASC',
  UnpaidSubmittedPlayersMaxDesc = 'UNPAID_SUBMITTED_PLAYERS_MAX_DESC',
  PaidApprovedDurationAsc = 'PAID_APPROVED_DURATION_ASC',
  PaidApprovedDurationDesc = 'PAID_APPROVED_DURATION_DESC',
  UnpaidApprovedDurationAsc = 'UNPAID_APPROVED_DURATION_ASC',
  UnpaidApprovedDurationDesc = 'UNPAID_APPROVED_DURATION_DESC',
  PaidOriginalDurationAsc = 'PAID_ORIGINAL_DURATION_ASC',
  PaidOriginalDurationDesc = 'PAID_ORIGINAL_DURATION_DESC',
  UnpaidOriginalDurationAsc = 'UNPAID_ORIGINAL_DURATION_ASC',
  UnpaidOriginalDurationDesc = 'UNPAID_ORIGINAL_DURATION_DESC',
  PaidSubmittedDurationAsc = 'PAID_SUBMITTED_DURATION_ASC',
  PaidSubmittedDurationDesc = 'PAID_SUBMITTED_DURATION_DESC',
  UnpaidSubmittedDurationAsc = 'UNPAID_SUBMITTED_DURATION_ASC',
  UnpaidSubmittedDurationDesc = 'UNPAID_SUBMITTED_DURATION_DESC',
  PaidApprovedDurationMinAsc = 'PAID_APPROVED_DURATION_MIN_ASC',
  PaidApprovedDurationMinDesc = 'PAID_APPROVED_DURATION_MIN_DESC',
  UnpaidApprovedDurationMinAsc = 'UNPAID_APPROVED_DURATION_MIN_ASC',
  UnpaidApprovedDurationMinDesc = 'UNPAID_APPROVED_DURATION_MIN_DESC',
  PaidOriginalDurationMinAsc = 'PAID_ORIGINAL_DURATION_MIN_ASC',
  PaidOriginalDurationMinDesc = 'PAID_ORIGINAL_DURATION_MIN_DESC',
  UnpaidOriginalDurationMinAsc = 'UNPAID_ORIGINAL_DURATION_MIN_ASC',
  UnpaidOriginalDurationMinDesc = 'UNPAID_ORIGINAL_DURATION_MIN_DESC',
  PaidSubmittedDurationMinAsc = 'PAID_SUBMITTED_DURATION_MIN_ASC',
  PaidSubmittedDurationMinDesc = 'PAID_SUBMITTED_DURATION_MIN_DESC',
  UnpaidSubmittedDurationMinAsc = 'UNPAID_SUBMITTED_DURATION_MIN_ASC',
  UnpaidSubmittedDurationMinDesc = 'UNPAID_SUBMITTED_DURATION_MIN_DESC',
  PaidApprovedDurationMaxAsc = 'PAID_APPROVED_DURATION_MAX_ASC',
  PaidApprovedDurationMaxDesc = 'PAID_APPROVED_DURATION_MAX_DESC',
  UnpaidApprovedDurationMaxAsc = 'UNPAID_APPROVED_DURATION_MAX_ASC',
  UnpaidApprovedDurationMaxDesc = 'UNPAID_APPROVED_DURATION_MAX_DESC',
  PaidOriginalDurationMaxAsc = 'PAID_ORIGINAL_DURATION_MAX_ASC',
  PaidOriginalDurationMaxDesc = 'PAID_ORIGINAL_DURATION_MAX_DESC',
  UnpaidOriginalDurationMaxAsc = 'UNPAID_ORIGINAL_DURATION_MAX_ASC',
  UnpaidOriginalDurationMaxDesc = 'UNPAID_ORIGINAL_DURATION_MAX_DESC',
  PaidSubmittedDurationMaxAsc = 'PAID_SUBMITTED_DURATION_MAX_ASC',
  PaidSubmittedDurationMaxDesc = 'PAID_SUBMITTED_DURATION_MAX_DESC',
  UnpaidSubmittedDurationMaxAsc = 'UNPAID_SUBMITTED_DURATION_MAX_ASC',
  UnpaidSubmittedDurationMaxDesc = 'UNPAID_SUBMITTED_DURATION_MAX_DESC'
}

export type LicenseSessionCustomerVenueDatePriceAgg = {
  __typename?: 'LicenseSessionCustomerVenueDatePriceAgg';
  date?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  currency: Scalars['CurrencyType'];
  price?: Maybe<Scalars['BigFloat']>;
  priceMin?: Maybe<Scalars['BigFloat']>;
  priceMax?: Maybe<Scalars['BigFloat']>;
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  priceApproved?: Maybe<Scalars['BigFloat']>;
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueDatePriceAgg` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueDatePriceAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMin` field. */
  priceMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMax` field. */
  priceMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueDatePriceAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueDatePriceAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMin` field. */
  priceMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMax` field. */
  priceMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueDatePriceAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueDatePriceAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueDatePriceAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueDatePriceAgg` values. */
export type LicenseSessionCustomerVenueDatePriceAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueDatePriceAggsConnection';
  /** A list of `LicenseSessionCustomerVenueDatePriceAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueDatePriceAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueDatePriceAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueDatePriceAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueDatePriceAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueDatePriceAgg` edge in the connection. */
export type LicenseSessionCustomerVenueDatePriceAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueDatePriceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueDatePriceAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueDatePriceAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueDatePriceAgg`. */
export enum LicenseSessionCustomerVenueDatePriceAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceMinAsc = 'PRICE_MIN_ASC',
  PriceMinDesc = 'PRICE_MIN_DESC',
  PriceMaxAsc = 'PRICE_MAX_ASC',
  PriceMaxDesc = 'PRICE_MAX_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  PriceOriginalMinAsc = 'PRICE_ORIGINAL_MIN_ASC',
  PriceOriginalMinDesc = 'PRICE_ORIGINAL_MIN_DESC',
  PriceOriginalMaxAsc = 'PRICE_ORIGINAL_MAX_ASC',
  PriceOriginalMaxDesc = 'PRICE_ORIGINAL_MAX_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceApprovedMinAsc = 'PRICE_APPROVED_MIN_ASC',
  PriceApprovedMinDesc = 'PRICE_APPROVED_MIN_DESC',
  PriceApprovedMaxAsc = 'PRICE_APPROVED_MAX_ASC',
  PriceApprovedMaxDesc = 'PRICE_APPROVED_MAX_DESC',
  PriceDiscountAsc = 'PRICE_DISCOUNT_ASC',
  PriceDiscountDesc = 'PRICE_DISCOUNT_DESC',
  PriceDiscountMinAsc = 'PRICE_DISCOUNT_MIN_ASC',
  PriceDiscountMinDesc = 'PRICE_DISCOUNT_MIN_DESC',
  PriceDiscountMaxAsc = 'PRICE_DISCOUNT_MAX_ASC',
  PriceDiscountMaxDesc = 'PRICE_DISCOUNT_MAX_DESC',
  PriceApprovedDiscountAsc = 'PRICE_APPROVED_DISCOUNT_ASC',
  PriceApprovedDiscountDesc = 'PRICE_APPROVED_DISCOUNT_DESC',
  PriceApprovedDiscountMinAsc = 'PRICE_APPROVED_DISCOUNT_MIN_ASC',
  PriceApprovedDiscountMinDesc = 'PRICE_APPROVED_DISCOUNT_MIN_DESC',
  PriceApprovedDiscountMaxAsc = 'PRICE_APPROVED_DISCOUNT_MAX_ASC',
  PriceApprovedDiscountMaxDesc = 'PRICE_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionCustomerVenueDateRoyaltyAgg = {
  __typename?: 'LicenseSessionCustomerVenueDateRoyaltyAgg';
  date?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  currency: Scalars['CurrencyType'];
  royalty?: Maybe<Scalars['BigFloat']>;
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueDateRoyaltyAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueDateRoyaltyAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueDateRoyaltyAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueDateRoyaltyAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueDateRoyaltyAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueDateRoyaltyAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueDateRoyaltyAgg` values. */
export type LicenseSessionCustomerVenueDateRoyaltyAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueDateRoyaltyAggsConnection';
  /** A list of `LicenseSessionCustomerVenueDateRoyaltyAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueDateRoyaltyAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueDateRoyaltyAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueDateRoyaltyAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueDateRoyaltyAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueDateRoyaltyAgg` edge in the connection. */
export type LicenseSessionCustomerVenueDateRoyaltyAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueDateRoyaltyAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueDateRoyaltyAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueDateRoyaltyAgg`. */
export enum LicenseSessionCustomerVenueDateRoyaltyAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyMinAsc = 'ROYALTY_MIN_ASC',
  RoyaltyMinDesc = 'ROYALTY_MIN_DESC',
  RoyaltyMaxAsc = 'ROYALTY_MAX_ASC',
  RoyaltyMaxDesc = 'ROYALTY_MAX_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  RoyaltyOriginalMinAsc = 'ROYALTY_ORIGINAL_MIN_ASC',
  RoyaltyOriginalMinDesc = 'ROYALTY_ORIGINAL_MIN_DESC',
  RoyaltyOriginalMaxAsc = 'ROYALTY_ORIGINAL_MAX_ASC',
  RoyaltyOriginalMaxDesc = 'ROYALTY_ORIGINAL_MAX_DESC',
  RoyaltyApprovedAsc = 'ROYALTY_APPROVED_ASC',
  RoyaltyApprovedDesc = 'ROYALTY_APPROVED_DESC',
  RoyaltyApprovedMinAsc = 'ROYALTY_APPROVED_MIN_ASC',
  RoyaltyApprovedMinDesc = 'ROYALTY_APPROVED_MIN_DESC',
  RoyaltyApprovedMaxAsc = 'ROYALTY_APPROVED_MAX_ASC',
  RoyaltyApprovedMaxDesc = 'ROYALTY_APPROVED_MAX_DESC',
  RoyaltyDiscountAsc = 'ROYALTY_DISCOUNT_ASC',
  RoyaltyDiscountDesc = 'ROYALTY_DISCOUNT_DESC',
  RoyaltyDiscountMinAsc = 'ROYALTY_DISCOUNT_MIN_ASC',
  RoyaltyDiscountMinDesc = 'ROYALTY_DISCOUNT_MIN_DESC',
  RoyaltyDiscountMaxAsc = 'ROYALTY_DISCOUNT_MAX_ASC',
  RoyaltyDiscountMaxDesc = 'ROYALTY_DISCOUNT_MAX_DESC',
  RoyaltyApprovedDiscountAsc = 'ROYALTY_APPROVED_DISCOUNT_ASC',
  RoyaltyApprovedDiscountDesc = 'ROYALTY_APPROVED_DISCOUNT_DESC',
  RoyaltyApprovedDiscountMinAsc = 'ROYALTY_APPROVED_DISCOUNT_MIN_ASC',
  RoyaltyApprovedDiscountMinDesc = 'ROYALTY_APPROVED_DISCOUNT_MIN_DESC',
  RoyaltyApprovedDiscountMaxAsc = 'ROYALTY_APPROVED_DISCOUNT_MAX_ASC',
  RoyaltyApprovedDiscountMaxDesc = 'ROYALTY_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionCustomerVenueProductDateAgg = {
  __typename?: 'LicenseSessionCustomerVenueProductDateAgg';
  date?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productArchived?: Maybe<Scalars['Boolean']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueProductDateAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueProductDateAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productArchived` field. */
  productArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueProductDateAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueProductDateAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `productArchived` field. */
  productArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueProductDateAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueProductDateAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueProductDateAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueProductDateAgg` values. */
export type LicenseSessionCustomerVenueProductDateAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueProductDateAggsConnection';
  /** A list of `LicenseSessionCustomerVenueProductDateAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueProductDateAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueProductDateAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueProductDateAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueProductDateAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueProductDateAgg` edge in the connection. */
export type LicenseSessionCustomerVenueProductDateAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueProductDateAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueProductDateAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueProductDateAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueProductDateAgg`. */
export enum LicenseSessionCustomerVenueProductDateAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProductArchivedAsc = 'PRODUCT_ARCHIVED_ASC',
  ProductArchivedDesc = 'PRODUCT_ARCHIVED_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidApprovedPlayersAsc = 'PAID_APPROVED_PLAYERS_ASC',
  PaidApprovedPlayersDesc = 'PAID_APPROVED_PLAYERS_DESC',
  UnpaidApprovedPlayersAsc = 'UNPAID_APPROVED_PLAYERS_ASC',
  UnpaidApprovedPlayersDesc = 'UNPAID_APPROVED_PLAYERS_DESC',
  PaidApprovedPlayersMinAsc = 'PAID_APPROVED_PLAYERS_MIN_ASC',
  PaidApprovedPlayersMinDesc = 'PAID_APPROVED_PLAYERS_MIN_DESC',
  UnpaidApprovedPlayersMinAsc = 'UNPAID_APPROVED_PLAYERS_MIN_ASC',
  UnpaidApprovedPlayersMinDesc = 'UNPAID_APPROVED_PLAYERS_MIN_DESC',
  PaidApprovedPlayersMaxAsc = 'PAID_APPROVED_PLAYERS_MAX_ASC',
  PaidApprovedPlayersMaxDesc = 'PAID_APPROVED_PLAYERS_MAX_DESC',
  UnpaidApprovedPlayersMaxAsc = 'UNPAID_APPROVED_PLAYERS_MAX_ASC',
  UnpaidApprovedPlayersMaxDesc = 'UNPAID_APPROVED_PLAYERS_MAX_DESC',
  PaidApprovedDurationAsc = 'PAID_APPROVED_DURATION_ASC',
  PaidApprovedDurationDesc = 'PAID_APPROVED_DURATION_DESC',
  UnpaidApprovedDurationAsc = 'UNPAID_APPROVED_DURATION_ASC',
  UnpaidApprovedDurationDesc = 'UNPAID_APPROVED_DURATION_DESC',
  PaidApprovedDurationMinAsc = 'PAID_APPROVED_DURATION_MIN_ASC',
  PaidApprovedDurationMinDesc = 'PAID_APPROVED_DURATION_MIN_DESC',
  UnpaidApprovedDurationMinAsc = 'UNPAID_APPROVED_DURATION_MIN_ASC',
  UnpaidApprovedDurationMinDesc = 'UNPAID_APPROVED_DURATION_MIN_DESC',
  PaidApprovedDurationMaxAsc = 'PAID_APPROVED_DURATION_MAX_ASC',
  PaidApprovedDurationMaxDesc = 'PAID_APPROVED_DURATION_MAX_DESC',
  UnpaidApprovedDurationMaxAsc = 'UNPAID_APPROVED_DURATION_MAX_ASC',
  UnpaidApprovedDurationMaxDesc = 'UNPAID_APPROVED_DURATION_MAX_DESC'
}

export type LicenseSessionCustomerVenueProductYearAgg = {
  __typename?: 'LicenseSessionCustomerVenueProductYearAgg';
  year?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productArchived?: Maybe<Scalars['Boolean']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueProductYearAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueProductYearAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productArchived` field. */
  productArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueProductYearAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueProductYearAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `productArchived` field. */
  productArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueProductYearAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueProductYearAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueProductYearAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueProductYearAgg` values. */
export type LicenseSessionCustomerVenueProductYearAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueProductYearAggsConnection';
  /** A list of `LicenseSessionCustomerVenueProductYearAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueProductYearAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueProductYearAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueProductYearAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueProductYearAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueProductYearAgg` edge in the connection. */
export type LicenseSessionCustomerVenueProductYearAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueProductYearAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueProductYearAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueProductYearAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueProductYearAgg`. */
export enum LicenseSessionCustomerVenueProductYearAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProductArchivedAsc = 'PRODUCT_ARCHIVED_ASC',
  ProductArchivedDesc = 'PRODUCT_ARCHIVED_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidApprovedPlayersAsc = 'PAID_APPROVED_PLAYERS_ASC',
  PaidApprovedPlayersDesc = 'PAID_APPROVED_PLAYERS_DESC',
  UnpaidApprovedPlayersAsc = 'UNPAID_APPROVED_PLAYERS_ASC',
  UnpaidApprovedPlayersDesc = 'UNPAID_APPROVED_PLAYERS_DESC',
  PaidApprovedPlayersMinAsc = 'PAID_APPROVED_PLAYERS_MIN_ASC',
  PaidApprovedPlayersMinDesc = 'PAID_APPROVED_PLAYERS_MIN_DESC',
  UnpaidApprovedPlayersMinAsc = 'UNPAID_APPROVED_PLAYERS_MIN_ASC',
  UnpaidApprovedPlayersMinDesc = 'UNPAID_APPROVED_PLAYERS_MIN_DESC',
  PaidApprovedPlayersMaxAsc = 'PAID_APPROVED_PLAYERS_MAX_ASC',
  PaidApprovedPlayersMaxDesc = 'PAID_APPROVED_PLAYERS_MAX_DESC',
  UnpaidApprovedPlayersMaxAsc = 'UNPAID_APPROVED_PLAYERS_MAX_ASC',
  UnpaidApprovedPlayersMaxDesc = 'UNPAID_APPROVED_PLAYERS_MAX_DESC',
  PaidApprovedDurationAsc = 'PAID_APPROVED_DURATION_ASC',
  PaidApprovedDurationDesc = 'PAID_APPROVED_DURATION_DESC',
  UnpaidApprovedDurationAsc = 'UNPAID_APPROVED_DURATION_ASC',
  UnpaidApprovedDurationDesc = 'UNPAID_APPROVED_DURATION_DESC',
  PaidApprovedDurationMinAsc = 'PAID_APPROVED_DURATION_MIN_ASC',
  PaidApprovedDurationMinDesc = 'PAID_APPROVED_DURATION_MIN_DESC',
  UnpaidApprovedDurationMinAsc = 'UNPAID_APPROVED_DURATION_MIN_ASC',
  UnpaidApprovedDurationMinDesc = 'UNPAID_APPROVED_DURATION_MIN_DESC',
  PaidApprovedDurationMaxAsc = 'PAID_APPROVED_DURATION_MAX_ASC',
  PaidApprovedDurationMaxDesc = 'PAID_APPROVED_DURATION_MAX_DESC',
  UnpaidApprovedDurationMaxAsc = 'UNPAID_APPROVED_DURATION_MAX_ASC',
  UnpaidApprovedDurationMaxDesc = 'UNPAID_APPROVED_DURATION_MAX_DESC'
}

export type LicenseSessionCustomerVenueProductYearMonthAgg = {
  __typename?: 'LicenseSessionCustomerVenueProductYearMonthAgg';
  year?: Maybe<Scalars['BigFloat']>;
  month?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productArchived?: Maybe<Scalars['Boolean']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueProductYearMonthAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueProductYearMonthAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productArchived` field. */
  productArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueProductYearMonthAgg`
 * object types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueProductYearMonthAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `productArchived` field. */
  productArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueProductYearMonthAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueProductYearMonthAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueProductYearMonthAgg` values. */
export type LicenseSessionCustomerVenueProductYearMonthAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueProductYearMonthAggsConnection';
  /** A list of `LicenseSessionCustomerVenueProductYearMonthAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueProductYearMonthAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueProductYearMonthAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueProductYearMonthAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueProductYearMonthAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueProductYearMonthAgg` edge in the connection. */
export type LicenseSessionCustomerVenueProductYearMonthAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueProductYearMonthAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueProductYearMonthAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueProductYearMonthAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueProductYearMonthAgg`. */
export enum LicenseSessionCustomerVenueProductYearMonthAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProductArchivedAsc = 'PRODUCT_ARCHIVED_ASC',
  ProductArchivedDesc = 'PRODUCT_ARCHIVED_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidApprovedPlayersAsc = 'PAID_APPROVED_PLAYERS_ASC',
  PaidApprovedPlayersDesc = 'PAID_APPROVED_PLAYERS_DESC',
  UnpaidApprovedPlayersAsc = 'UNPAID_APPROVED_PLAYERS_ASC',
  UnpaidApprovedPlayersDesc = 'UNPAID_APPROVED_PLAYERS_DESC',
  PaidApprovedPlayersMinAsc = 'PAID_APPROVED_PLAYERS_MIN_ASC',
  PaidApprovedPlayersMinDesc = 'PAID_APPROVED_PLAYERS_MIN_DESC',
  UnpaidApprovedPlayersMinAsc = 'UNPAID_APPROVED_PLAYERS_MIN_ASC',
  UnpaidApprovedPlayersMinDesc = 'UNPAID_APPROVED_PLAYERS_MIN_DESC',
  PaidApprovedPlayersMaxAsc = 'PAID_APPROVED_PLAYERS_MAX_ASC',
  PaidApprovedPlayersMaxDesc = 'PAID_APPROVED_PLAYERS_MAX_DESC',
  UnpaidApprovedPlayersMaxAsc = 'UNPAID_APPROVED_PLAYERS_MAX_ASC',
  UnpaidApprovedPlayersMaxDesc = 'UNPAID_APPROVED_PLAYERS_MAX_DESC',
  PaidApprovedDurationAsc = 'PAID_APPROVED_DURATION_ASC',
  PaidApprovedDurationDesc = 'PAID_APPROVED_DURATION_DESC',
  UnpaidApprovedDurationAsc = 'UNPAID_APPROVED_DURATION_ASC',
  UnpaidApprovedDurationDesc = 'UNPAID_APPROVED_DURATION_DESC',
  PaidApprovedDurationMinAsc = 'PAID_APPROVED_DURATION_MIN_ASC',
  PaidApprovedDurationMinDesc = 'PAID_APPROVED_DURATION_MIN_DESC',
  UnpaidApprovedDurationMinAsc = 'UNPAID_APPROVED_DURATION_MIN_ASC',
  UnpaidApprovedDurationMinDesc = 'UNPAID_APPROVED_DURATION_MIN_DESC',
  PaidApprovedDurationMaxAsc = 'PAID_APPROVED_DURATION_MAX_ASC',
  PaidApprovedDurationMaxDesc = 'PAID_APPROVED_DURATION_MAX_DESC',
  UnpaidApprovedDurationMaxAsc = 'UNPAID_APPROVED_DURATION_MAX_ASC',
  UnpaidApprovedDurationMaxDesc = 'UNPAID_APPROVED_DURATION_MAX_DESC'
}

export type LicenseSessionCustomerVenueYearAgg = {
  __typename?: 'LicenseSessionCustomerVenueYearAgg';
  year?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unknownAmount?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  paidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  unpaidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  paidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  paidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  unpaidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  paidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  paidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  unpaidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  paidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  paidOriginalDuration?: Maybe<Scalars['Float']>;
  unpaidOriginalDuration?: Maybe<Scalars['Float']>;
  paidSubmittedDuration?: Maybe<Scalars['Float']>;
  unpaidSubmittedDuration?: Maybe<Scalars['Float']>;
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  paidOriginalDurationMin?: Maybe<Scalars['Float']>;
  unpaidOriginalDurationMin?: Maybe<Scalars['Float']>;
  paidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  unpaidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
  paidOriginalDurationMax?: Maybe<Scalars['Float']>;
  unpaidOriginalDurationMax?: Maybe<Scalars['Float']>;
  paidSubmittedDurationMax?: Maybe<Scalars['Float']>;
  unpaidSubmittedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueYearAgg` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalPlayers` field. */
  paidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayers` field. */
  unpaidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedPlayers` field. */
  paidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayers` field. */
  unpaidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidOriginalPlayersMin` field. */
  paidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayersMin` field. */
  unpaidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidSubmittedPlayersMin` field. */
  paidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayersMin` field. */
  unpaidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidOriginalPlayersMax` field. */
  paidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayersMax` field. */
  unpaidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidSubmittedPlayersMax` field. */
  paidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayersMax` field. */
  unpaidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDuration` field. */
  paidOriginalDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDuration` field. */
  unpaidOriginalDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDuration` field. */
  paidSubmittedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDuration` field. */
  unpaidSubmittedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDurationMin` field. */
  paidOriginalDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDurationMin` field. */
  unpaidOriginalDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDurationMin` field. */
  paidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDurationMin` field. */
  unpaidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDurationMax` field. */
  paidOriginalDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDurationMax` field. */
  unpaidOriginalDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDurationMax` field. */
  paidSubmittedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDurationMax` field. */
  unpaidSubmittedDurationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionCustomerVenueYearAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionCustomerVenueYearAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalPlayers` field. */
  paidOriginalPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayers` field. */
  unpaidOriginalPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedPlayers` field. */
  paidSubmittedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayers` field. */
  unpaidSubmittedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidOriginalPlayersMin` field. */
  paidOriginalPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayersMin` field. */
  unpaidOriginalPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidSubmittedPlayersMin` field. */
  paidSubmittedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayersMin` field. */
  unpaidSubmittedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidOriginalPlayersMax` field. */
  paidOriginalPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayersMax` field. */
  unpaidOriginalPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidSubmittedPlayersMax` field. */
  paidSubmittedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayersMax` field. */
  unpaidSubmittedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDuration` field. */
  paidOriginalDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDuration` field. */
  unpaidOriginalDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDuration` field. */
  paidSubmittedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDuration` field. */
  unpaidSubmittedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDurationMin` field. */
  paidOriginalDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDurationMin` field. */
  unpaidOriginalDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDurationMin` field. */
  paidSubmittedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDurationMin` field. */
  unpaidSubmittedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDurationMax` field. */
  paidOriginalDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDurationMax` field. */
  unpaidOriginalDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDurationMax` field. */
  paidSubmittedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDurationMax` field. */
  unpaidSubmittedDurationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueYearAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueYearAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueYearAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueYearAgg` values. */
export type LicenseSessionCustomerVenueYearAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueYearAggsConnection';
  /** A list of `LicenseSessionCustomerVenueYearAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueYearAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueYearAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueYearAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueYearAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueYearAgg` edge in the connection. */
export type LicenseSessionCustomerVenueYearAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueYearAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueYearAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueYearAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueYearAgg`. */
export enum LicenseSessionCustomerVenueYearAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidOriginalAmountAsc = 'PAID_ORIGINAL_AMOUNT_ASC',
  PaidOriginalAmountDesc = 'PAID_ORIGINAL_AMOUNT_DESC',
  UnpaidOriginalAmountAsc = 'UNPAID_ORIGINAL_AMOUNT_ASC',
  UnpaidOriginalAmountDesc = 'UNPAID_ORIGINAL_AMOUNT_DESC',
  PaidSubmittedAmountAsc = 'PAID_SUBMITTED_AMOUNT_ASC',
  PaidSubmittedAmountDesc = 'PAID_SUBMITTED_AMOUNT_DESC',
  UnpaidSubmittedAmountAsc = 'UNPAID_SUBMITTED_AMOUNT_ASC',
  UnpaidSubmittedAmountDesc = 'UNPAID_SUBMITTED_AMOUNT_DESC',
  UnknownAmountAsc = 'UNKNOWN_AMOUNT_ASC',
  UnknownAmountDesc = 'UNKNOWN_AMOUNT_DESC',
  PaidApprovedPlayersAsc = 'PAID_APPROVED_PLAYERS_ASC',
  PaidApprovedPlayersDesc = 'PAID_APPROVED_PLAYERS_DESC',
  UnpaidApprovedPlayersAsc = 'UNPAID_APPROVED_PLAYERS_ASC',
  UnpaidApprovedPlayersDesc = 'UNPAID_APPROVED_PLAYERS_DESC',
  PaidOriginalPlayersAsc = 'PAID_ORIGINAL_PLAYERS_ASC',
  PaidOriginalPlayersDesc = 'PAID_ORIGINAL_PLAYERS_DESC',
  UnpaidOriginalPlayersAsc = 'UNPAID_ORIGINAL_PLAYERS_ASC',
  UnpaidOriginalPlayersDesc = 'UNPAID_ORIGINAL_PLAYERS_DESC',
  PaidSubmittedPlayersAsc = 'PAID_SUBMITTED_PLAYERS_ASC',
  PaidSubmittedPlayersDesc = 'PAID_SUBMITTED_PLAYERS_DESC',
  UnpaidSubmittedPlayersAsc = 'UNPAID_SUBMITTED_PLAYERS_ASC',
  UnpaidSubmittedPlayersDesc = 'UNPAID_SUBMITTED_PLAYERS_DESC',
  PaidApprovedPlayersMinAsc = 'PAID_APPROVED_PLAYERS_MIN_ASC',
  PaidApprovedPlayersMinDesc = 'PAID_APPROVED_PLAYERS_MIN_DESC',
  UnpaidApprovedPlayersMinAsc = 'UNPAID_APPROVED_PLAYERS_MIN_ASC',
  UnpaidApprovedPlayersMinDesc = 'UNPAID_APPROVED_PLAYERS_MIN_DESC',
  PaidOriginalPlayersMinAsc = 'PAID_ORIGINAL_PLAYERS_MIN_ASC',
  PaidOriginalPlayersMinDesc = 'PAID_ORIGINAL_PLAYERS_MIN_DESC',
  UnpaidOriginalPlayersMinAsc = 'UNPAID_ORIGINAL_PLAYERS_MIN_ASC',
  UnpaidOriginalPlayersMinDesc = 'UNPAID_ORIGINAL_PLAYERS_MIN_DESC',
  PaidSubmittedPlayersMinAsc = 'PAID_SUBMITTED_PLAYERS_MIN_ASC',
  PaidSubmittedPlayersMinDesc = 'PAID_SUBMITTED_PLAYERS_MIN_DESC',
  UnpaidSubmittedPlayersMinAsc = 'UNPAID_SUBMITTED_PLAYERS_MIN_ASC',
  UnpaidSubmittedPlayersMinDesc = 'UNPAID_SUBMITTED_PLAYERS_MIN_DESC',
  PaidApprovedPlayersMaxAsc = 'PAID_APPROVED_PLAYERS_MAX_ASC',
  PaidApprovedPlayersMaxDesc = 'PAID_APPROVED_PLAYERS_MAX_DESC',
  UnpaidApprovedPlayersMaxAsc = 'UNPAID_APPROVED_PLAYERS_MAX_ASC',
  UnpaidApprovedPlayersMaxDesc = 'UNPAID_APPROVED_PLAYERS_MAX_DESC',
  PaidOriginalPlayersMaxAsc = 'PAID_ORIGINAL_PLAYERS_MAX_ASC',
  PaidOriginalPlayersMaxDesc = 'PAID_ORIGINAL_PLAYERS_MAX_DESC',
  UnpaidOriginalPlayersMaxAsc = 'UNPAID_ORIGINAL_PLAYERS_MAX_ASC',
  UnpaidOriginalPlayersMaxDesc = 'UNPAID_ORIGINAL_PLAYERS_MAX_DESC',
  PaidSubmittedPlayersMaxAsc = 'PAID_SUBMITTED_PLAYERS_MAX_ASC',
  PaidSubmittedPlayersMaxDesc = 'PAID_SUBMITTED_PLAYERS_MAX_DESC',
  UnpaidSubmittedPlayersMaxAsc = 'UNPAID_SUBMITTED_PLAYERS_MAX_ASC',
  UnpaidSubmittedPlayersMaxDesc = 'UNPAID_SUBMITTED_PLAYERS_MAX_DESC',
  PaidApprovedDurationAsc = 'PAID_APPROVED_DURATION_ASC',
  PaidApprovedDurationDesc = 'PAID_APPROVED_DURATION_DESC',
  UnpaidApprovedDurationAsc = 'UNPAID_APPROVED_DURATION_ASC',
  UnpaidApprovedDurationDesc = 'UNPAID_APPROVED_DURATION_DESC',
  PaidOriginalDurationAsc = 'PAID_ORIGINAL_DURATION_ASC',
  PaidOriginalDurationDesc = 'PAID_ORIGINAL_DURATION_DESC',
  UnpaidOriginalDurationAsc = 'UNPAID_ORIGINAL_DURATION_ASC',
  UnpaidOriginalDurationDesc = 'UNPAID_ORIGINAL_DURATION_DESC',
  PaidSubmittedDurationAsc = 'PAID_SUBMITTED_DURATION_ASC',
  PaidSubmittedDurationDesc = 'PAID_SUBMITTED_DURATION_DESC',
  UnpaidSubmittedDurationAsc = 'UNPAID_SUBMITTED_DURATION_ASC',
  UnpaidSubmittedDurationDesc = 'UNPAID_SUBMITTED_DURATION_DESC',
  PaidApprovedDurationMinAsc = 'PAID_APPROVED_DURATION_MIN_ASC',
  PaidApprovedDurationMinDesc = 'PAID_APPROVED_DURATION_MIN_DESC',
  UnpaidApprovedDurationMinAsc = 'UNPAID_APPROVED_DURATION_MIN_ASC',
  UnpaidApprovedDurationMinDesc = 'UNPAID_APPROVED_DURATION_MIN_DESC',
  PaidOriginalDurationMinAsc = 'PAID_ORIGINAL_DURATION_MIN_ASC',
  PaidOriginalDurationMinDesc = 'PAID_ORIGINAL_DURATION_MIN_DESC',
  UnpaidOriginalDurationMinAsc = 'UNPAID_ORIGINAL_DURATION_MIN_ASC',
  UnpaidOriginalDurationMinDesc = 'UNPAID_ORIGINAL_DURATION_MIN_DESC',
  PaidSubmittedDurationMinAsc = 'PAID_SUBMITTED_DURATION_MIN_ASC',
  PaidSubmittedDurationMinDesc = 'PAID_SUBMITTED_DURATION_MIN_DESC',
  UnpaidSubmittedDurationMinAsc = 'UNPAID_SUBMITTED_DURATION_MIN_ASC',
  UnpaidSubmittedDurationMinDesc = 'UNPAID_SUBMITTED_DURATION_MIN_DESC',
  PaidApprovedDurationMaxAsc = 'PAID_APPROVED_DURATION_MAX_ASC',
  PaidApprovedDurationMaxDesc = 'PAID_APPROVED_DURATION_MAX_DESC',
  UnpaidApprovedDurationMaxAsc = 'UNPAID_APPROVED_DURATION_MAX_ASC',
  UnpaidApprovedDurationMaxDesc = 'UNPAID_APPROVED_DURATION_MAX_DESC',
  PaidOriginalDurationMaxAsc = 'PAID_ORIGINAL_DURATION_MAX_ASC',
  PaidOriginalDurationMaxDesc = 'PAID_ORIGINAL_DURATION_MAX_DESC',
  UnpaidOriginalDurationMaxAsc = 'UNPAID_ORIGINAL_DURATION_MAX_ASC',
  UnpaidOriginalDurationMaxDesc = 'UNPAID_ORIGINAL_DURATION_MAX_DESC',
  PaidSubmittedDurationMaxAsc = 'PAID_SUBMITTED_DURATION_MAX_ASC',
  PaidSubmittedDurationMaxDesc = 'PAID_SUBMITTED_DURATION_MAX_DESC',
  UnpaidSubmittedDurationMaxAsc = 'UNPAID_SUBMITTED_DURATION_MAX_ASC',
  UnpaidSubmittedDurationMaxDesc = 'UNPAID_SUBMITTED_DURATION_MAX_DESC'
}

export type LicenseSessionCustomerVenueYearMonthAgg = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthAgg';
  year?: Maybe<Scalars['BigFloat']>;
  month?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unknownAmount?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  paidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  unpaidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  paidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  paidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  unpaidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  paidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  unpaidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  paidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  unpaidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  paidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  unpaidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  paidOriginalDuration?: Maybe<Scalars['Float']>;
  unpaidOriginalDuration?: Maybe<Scalars['Float']>;
  paidSubmittedDuration?: Maybe<Scalars['Float']>;
  unpaidSubmittedDuration?: Maybe<Scalars['Float']>;
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  paidOriginalDurationMin?: Maybe<Scalars['Float']>;
  unpaidOriginalDurationMin?: Maybe<Scalars['Float']>;
  paidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  unpaidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
  paidOriginalDurationMax?: Maybe<Scalars['Float']>;
  unpaidOriginalDurationMax?: Maybe<Scalars['Float']>;
  paidSubmittedDurationMax?: Maybe<Scalars['Float']>;
  unpaidSubmittedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueYearMonthAgg` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearMonthAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalPlayers` field. */
  paidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayers` field. */
  unpaidOriginalPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedPlayers` field. */
  paidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayers` field. */
  unpaidSubmittedPlayers?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidOriginalPlayersMin` field. */
  paidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayersMin` field. */
  unpaidOriginalPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidSubmittedPlayersMin` field. */
  paidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayersMin` field. */
  unpaidSubmittedPlayersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidOriginalPlayersMax` field. */
  paidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidOriginalPlayersMax` field. */
  unpaidOriginalPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidSubmittedPlayersMax` field. */
  paidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unpaidSubmittedPlayersMax` field. */
  unpaidSubmittedPlayersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDuration` field. */
  paidOriginalDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDuration` field. */
  unpaidOriginalDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDuration` field. */
  paidSubmittedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDuration` field. */
  unpaidSubmittedDuration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDurationMin` field. */
  paidOriginalDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDurationMin` field. */
  unpaidOriginalDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDurationMin` field. */
  paidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDurationMin` field. */
  unpaidSubmittedDurationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidOriginalDurationMax` field. */
  paidOriginalDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidOriginalDurationMax` field. */
  unpaidOriginalDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidSubmittedDurationMax` field. */
  paidSubmittedDurationMax?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `unpaidSubmittedDurationMax` field. */
  unpaidSubmittedDurationMax?: Maybe<Scalars['Float']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueYearMonthAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearMonthAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayers` field. */
  paidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayers` field. */
  unpaidApprovedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalPlayers` field. */
  paidOriginalPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayers` field. */
  unpaidOriginalPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedPlayers` field. */
  paidSubmittedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayers` field. */
  unpaidSubmittedPlayers?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMin` field. */
  paidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMin` field. */
  unpaidApprovedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidOriginalPlayersMin` field. */
  paidOriginalPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayersMin` field. */
  unpaidOriginalPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidSubmittedPlayersMin` field. */
  paidSubmittedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayersMin` field. */
  unpaidSubmittedPlayersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedPlayersMax` field. */
  paidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidApprovedPlayersMax` field. */
  unpaidApprovedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidOriginalPlayersMax` field. */
  paidOriginalPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidOriginalPlayersMax` field. */
  unpaidOriginalPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidSubmittedPlayersMax` field. */
  paidSubmittedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `unpaidSubmittedPlayersMax` field. */
  unpaidSubmittedPlayersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `paidApprovedDuration` field. */
  paidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDuration` field. */
  unpaidApprovedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDuration` field. */
  paidOriginalDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDuration` field. */
  unpaidOriginalDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDuration` field. */
  paidSubmittedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDuration` field. */
  unpaidSubmittedDuration?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMin` field. */
  paidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMin` field. */
  unpaidApprovedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDurationMin` field. */
  paidOriginalDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDurationMin` field. */
  unpaidOriginalDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDurationMin` field. */
  paidSubmittedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDurationMin` field. */
  unpaidSubmittedDurationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedDurationMax` field. */
  paidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidApprovedDurationMax` field. */
  unpaidApprovedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidOriginalDurationMax` field. */
  paidOriginalDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidOriginalDurationMax` field. */
  unpaidOriginalDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidSubmittedDurationMax` field. */
  paidSubmittedDurationMax?: Maybe<FloatFilter>;
  /** Filter by the object’s `unpaidSubmittedDurationMax` field. */
  unpaidSubmittedDurationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueYearMonthAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueYearMonthAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueYearMonthAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueYearMonthAgg` values. */
export type LicenseSessionCustomerVenueYearMonthAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthAggsConnection';
  /** A list of `LicenseSessionCustomerVenueYearMonthAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueYearMonthAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueYearMonthAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueYearMonthAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueYearMonthAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueYearMonthAgg` edge in the connection. */
export type LicenseSessionCustomerVenueYearMonthAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueYearMonthAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueYearMonthAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueYearMonthAgg`. */
export enum LicenseSessionCustomerVenueYearMonthAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidOriginalAmountAsc = 'PAID_ORIGINAL_AMOUNT_ASC',
  PaidOriginalAmountDesc = 'PAID_ORIGINAL_AMOUNT_DESC',
  UnpaidOriginalAmountAsc = 'UNPAID_ORIGINAL_AMOUNT_ASC',
  UnpaidOriginalAmountDesc = 'UNPAID_ORIGINAL_AMOUNT_DESC',
  PaidSubmittedAmountAsc = 'PAID_SUBMITTED_AMOUNT_ASC',
  PaidSubmittedAmountDesc = 'PAID_SUBMITTED_AMOUNT_DESC',
  UnpaidSubmittedAmountAsc = 'UNPAID_SUBMITTED_AMOUNT_ASC',
  UnpaidSubmittedAmountDesc = 'UNPAID_SUBMITTED_AMOUNT_DESC',
  UnknownAmountAsc = 'UNKNOWN_AMOUNT_ASC',
  UnknownAmountDesc = 'UNKNOWN_AMOUNT_DESC',
  PaidApprovedPlayersAsc = 'PAID_APPROVED_PLAYERS_ASC',
  PaidApprovedPlayersDesc = 'PAID_APPROVED_PLAYERS_DESC',
  UnpaidApprovedPlayersAsc = 'UNPAID_APPROVED_PLAYERS_ASC',
  UnpaidApprovedPlayersDesc = 'UNPAID_APPROVED_PLAYERS_DESC',
  PaidOriginalPlayersAsc = 'PAID_ORIGINAL_PLAYERS_ASC',
  PaidOriginalPlayersDesc = 'PAID_ORIGINAL_PLAYERS_DESC',
  UnpaidOriginalPlayersAsc = 'UNPAID_ORIGINAL_PLAYERS_ASC',
  UnpaidOriginalPlayersDesc = 'UNPAID_ORIGINAL_PLAYERS_DESC',
  PaidSubmittedPlayersAsc = 'PAID_SUBMITTED_PLAYERS_ASC',
  PaidSubmittedPlayersDesc = 'PAID_SUBMITTED_PLAYERS_DESC',
  UnpaidSubmittedPlayersAsc = 'UNPAID_SUBMITTED_PLAYERS_ASC',
  UnpaidSubmittedPlayersDesc = 'UNPAID_SUBMITTED_PLAYERS_DESC',
  PaidApprovedPlayersMinAsc = 'PAID_APPROVED_PLAYERS_MIN_ASC',
  PaidApprovedPlayersMinDesc = 'PAID_APPROVED_PLAYERS_MIN_DESC',
  UnpaidApprovedPlayersMinAsc = 'UNPAID_APPROVED_PLAYERS_MIN_ASC',
  UnpaidApprovedPlayersMinDesc = 'UNPAID_APPROVED_PLAYERS_MIN_DESC',
  PaidOriginalPlayersMinAsc = 'PAID_ORIGINAL_PLAYERS_MIN_ASC',
  PaidOriginalPlayersMinDesc = 'PAID_ORIGINAL_PLAYERS_MIN_DESC',
  UnpaidOriginalPlayersMinAsc = 'UNPAID_ORIGINAL_PLAYERS_MIN_ASC',
  UnpaidOriginalPlayersMinDesc = 'UNPAID_ORIGINAL_PLAYERS_MIN_DESC',
  PaidSubmittedPlayersMinAsc = 'PAID_SUBMITTED_PLAYERS_MIN_ASC',
  PaidSubmittedPlayersMinDesc = 'PAID_SUBMITTED_PLAYERS_MIN_DESC',
  UnpaidSubmittedPlayersMinAsc = 'UNPAID_SUBMITTED_PLAYERS_MIN_ASC',
  UnpaidSubmittedPlayersMinDesc = 'UNPAID_SUBMITTED_PLAYERS_MIN_DESC',
  PaidApprovedPlayersMaxAsc = 'PAID_APPROVED_PLAYERS_MAX_ASC',
  PaidApprovedPlayersMaxDesc = 'PAID_APPROVED_PLAYERS_MAX_DESC',
  UnpaidApprovedPlayersMaxAsc = 'UNPAID_APPROVED_PLAYERS_MAX_ASC',
  UnpaidApprovedPlayersMaxDesc = 'UNPAID_APPROVED_PLAYERS_MAX_DESC',
  PaidOriginalPlayersMaxAsc = 'PAID_ORIGINAL_PLAYERS_MAX_ASC',
  PaidOriginalPlayersMaxDesc = 'PAID_ORIGINAL_PLAYERS_MAX_DESC',
  UnpaidOriginalPlayersMaxAsc = 'UNPAID_ORIGINAL_PLAYERS_MAX_ASC',
  UnpaidOriginalPlayersMaxDesc = 'UNPAID_ORIGINAL_PLAYERS_MAX_DESC',
  PaidSubmittedPlayersMaxAsc = 'PAID_SUBMITTED_PLAYERS_MAX_ASC',
  PaidSubmittedPlayersMaxDesc = 'PAID_SUBMITTED_PLAYERS_MAX_DESC',
  UnpaidSubmittedPlayersMaxAsc = 'UNPAID_SUBMITTED_PLAYERS_MAX_ASC',
  UnpaidSubmittedPlayersMaxDesc = 'UNPAID_SUBMITTED_PLAYERS_MAX_DESC',
  PaidApprovedDurationAsc = 'PAID_APPROVED_DURATION_ASC',
  PaidApprovedDurationDesc = 'PAID_APPROVED_DURATION_DESC',
  UnpaidApprovedDurationAsc = 'UNPAID_APPROVED_DURATION_ASC',
  UnpaidApprovedDurationDesc = 'UNPAID_APPROVED_DURATION_DESC',
  PaidOriginalDurationAsc = 'PAID_ORIGINAL_DURATION_ASC',
  PaidOriginalDurationDesc = 'PAID_ORIGINAL_DURATION_DESC',
  UnpaidOriginalDurationAsc = 'UNPAID_ORIGINAL_DURATION_ASC',
  UnpaidOriginalDurationDesc = 'UNPAID_ORIGINAL_DURATION_DESC',
  PaidSubmittedDurationAsc = 'PAID_SUBMITTED_DURATION_ASC',
  PaidSubmittedDurationDesc = 'PAID_SUBMITTED_DURATION_DESC',
  UnpaidSubmittedDurationAsc = 'UNPAID_SUBMITTED_DURATION_ASC',
  UnpaidSubmittedDurationDesc = 'UNPAID_SUBMITTED_DURATION_DESC',
  PaidApprovedDurationMinAsc = 'PAID_APPROVED_DURATION_MIN_ASC',
  PaidApprovedDurationMinDesc = 'PAID_APPROVED_DURATION_MIN_DESC',
  UnpaidApprovedDurationMinAsc = 'UNPAID_APPROVED_DURATION_MIN_ASC',
  UnpaidApprovedDurationMinDesc = 'UNPAID_APPROVED_DURATION_MIN_DESC',
  PaidOriginalDurationMinAsc = 'PAID_ORIGINAL_DURATION_MIN_ASC',
  PaidOriginalDurationMinDesc = 'PAID_ORIGINAL_DURATION_MIN_DESC',
  UnpaidOriginalDurationMinAsc = 'UNPAID_ORIGINAL_DURATION_MIN_ASC',
  UnpaidOriginalDurationMinDesc = 'UNPAID_ORIGINAL_DURATION_MIN_DESC',
  PaidSubmittedDurationMinAsc = 'PAID_SUBMITTED_DURATION_MIN_ASC',
  PaidSubmittedDurationMinDesc = 'PAID_SUBMITTED_DURATION_MIN_DESC',
  UnpaidSubmittedDurationMinAsc = 'UNPAID_SUBMITTED_DURATION_MIN_ASC',
  UnpaidSubmittedDurationMinDesc = 'UNPAID_SUBMITTED_DURATION_MIN_DESC',
  PaidApprovedDurationMaxAsc = 'PAID_APPROVED_DURATION_MAX_ASC',
  PaidApprovedDurationMaxDesc = 'PAID_APPROVED_DURATION_MAX_DESC',
  UnpaidApprovedDurationMaxAsc = 'UNPAID_APPROVED_DURATION_MAX_ASC',
  UnpaidApprovedDurationMaxDesc = 'UNPAID_APPROVED_DURATION_MAX_DESC',
  PaidOriginalDurationMaxAsc = 'PAID_ORIGINAL_DURATION_MAX_ASC',
  PaidOriginalDurationMaxDesc = 'PAID_ORIGINAL_DURATION_MAX_DESC',
  UnpaidOriginalDurationMaxAsc = 'UNPAID_ORIGINAL_DURATION_MAX_ASC',
  UnpaidOriginalDurationMaxDesc = 'UNPAID_ORIGINAL_DURATION_MAX_DESC',
  PaidSubmittedDurationMaxAsc = 'PAID_SUBMITTED_DURATION_MAX_ASC',
  PaidSubmittedDurationMaxDesc = 'PAID_SUBMITTED_DURATION_MAX_DESC',
  UnpaidSubmittedDurationMaxAsc = 'UNPAID_SUBMITTED_DURATION_MAX_ASC',
  UnpaidSubmittedDurationMaxDesc = 'UNPAID_SUBMITTED_DURATION_MAX_DESC'
}

export type LicenseSessionCustomerVenueYearMonthPriceAgg = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthPriceAgg';
  year?: Maybe<Scalars['BigFloat']>;
  month?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  currency: Scalars['CurrencyType'];
  price?: Maybe<Scalars['BigFloat']>;
  priceMin?: Maybe<Scalars['BigFloat']>;
  priceMax?: Maybe<Scalars['BigFloat']>;
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  priceApproved?: Maybe<Scalars['BigFloat']>;
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueYearMonthPriceAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearMonthPriceAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMin` field. */
  priceMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMax` field. */
  priceMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueYearMonthPriceAgg`
 * object types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearMonthPriceAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMin` field. */
  priceMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMax` field. */
  priceMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueYearMonthPriceAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueYearMonthPriceAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueYearMonthPriceAgg` values. */
export type LicenseSessionCustomerVenueYearMonthPriceAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthPriceAggsConnection';
  /** A list of `LicenseSessionCustomerVenueYearMonthPriceAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueYearMonthPriceAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueYearMonthPriceAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueYearMonthPriceAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueYearMonthPriceAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueYearMonthPriceAgg` edge in the connection. */
export type LicenseSessionCustomerVenueYearMonthPriceAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthPriceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueYearMonthPriceAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueYearMonthPriceAgg`. */
export enum LicenseSessionCustomerVenueYearMonthPriceAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceMinAsc = 'PRICE_MIN_ASC',
  PriceMinDesc = 'PRICE_MIN_DESC',
  PriceMaxAsc = 'PRICE_MAX_ASC',
  PriceMaxDesc = 'PRICE_MAX_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  PriceOriginalMinAsc = 'PRICE_ORIGINAL_MIN_ASC',
  PriceOriginalMinDesc = 'PRICE_ORIGINAL_MIN_DESC',
  PriceOriginalMaxAsc = 'PRICE_ORIGINAL_MAX_ASC',
  PriceOriginalMaxDesc = 'PRICE_ORIGINAL_MAX_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceApprovedMinAsc = 'PRICE_APPROVED_MIN_ASC',
  PriceApprovedMinDesc = 'PRICE_APPROVED_MIN_DESC',
  PriceApprovedMaxAsc = 'PRICE_APPROVED_MAX_ASC',
  PriceApprovedMaxDesc = 'PRICE_APPROVED_MAX_DESC',
  PriceDiscountAsc = 'PRICE_DISCOUNT_ASC',
  PriceDiscountDesc = 'PRICE_DISCOUNT_DESC',
  PriceDiscountMinAsc = 'PRICE_DISCOUNT_MIN_ASC',
  PriceDiscountMinDesc = 'PRICE_DISCOUNT_MIN_DESC',
  PriceDiscountMaxAsc = 'PRICE_DISCOUNT_MAX_ASC',
  PriceDiscountMaxDesc = 'PRICE_DISCOUNT_MAX_DESC',
  PriceApprovedDiscountAsc = 'PRICE_APPROVED_DISCOUNT_ASC',
  PriceApprovedDiscountDesc = 'PRICE_APPROVED_DISCOUNT_DESC',
  PriceApprovedDiscountMinAsc = 'PRICE_APPROVED_DISCOUNT_MIN_ASC',
  PriceApprovedDiscountMinDesc = 'PRICE_APPROVED_DISCOUNT_MIN_DESC',
  PriceApprovedDiscountMaxAsc = 'PRICE_APPROVED_DISCOUNT_MAX_ASC',
  PriceApprovedDiscountMaxDesc = 'PRICE_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionCustomerVenueYearMonthRoyaltyAgg = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthRoyaltyAgg';
  year?: Maybe<Scalars['BigFloat']>;
  month?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  currency: Scalars['CurrencyType'];
  royalty?: Maybe<Scalars['BigFloat']>;
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueYearMonthRoyaltyAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearMonthRoyaltyAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueYearMonthRoyaltyAgg`
 * object types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueYearMonthRoyaltyAgg` values. */
export type LicenseSessionCustomerVenueYearMonthRoyaltyAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthRoyaltyAggsConnection';
  /** A list of `LicenseSessionCustomerVenueYearMonthRoyaltyAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueYearMonthRoyaltyAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueYearMonthRoyaltyAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueYearMonthRoyaltyAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueYearMonthRoyaltyAgg` edge in the connection. */
export type LicenseSessionCustomerVenueYearMonthRoyaltyAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueYearMonthRoyaltyAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueYearMonthRoyaltyAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueYearMonthRoyaltyAgg`. */
export enum LicenseSessionCustomerVenueYearMonthRoyaltyAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyMinAsc = 'ROYALTY_MIN_ASC',
  RoyaltyMinDesc = 'ROYALTY_MIN_DESC',
  RoyaltyMaxAsc = 'ROYALTY_MAX_ASC',
  RoyaltyMaxDesc = 'ROYALTY_MAX_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  RoyaltyOriginalMinAsc = 'ROYALTY_ORIGINAL_MIN_ASC',
  RoyaltyOriginalMinDesc = 'ROYALTY_ORIGINAL_MIN_DESC',
  RoyaltyOriginalMaxAsc = 'ROYALTY_ORIGINAL_MAX_ASC',
  RoyaltyOriginalMaxDesc = 'ROYALTY_ORIGINAL_MAX_DESC',
  RoyaltyApprovedAsc = 'ROYALTY_APPROVED_ASC',
  RoyaltyApprovedDesc = 'ROYALTY_APPROVED_DESC',
  RoyaltyApprovedMinAsc = 'ROYALTY_APPROVED_MIN_ASC',
  RoyaltyApprovedMinDesc = 'ROYALTY_APPROVED_MIN_DESC',
  RoyaltyApprovedMaxAsc = 'ROYALTY_APPROVED_MAX_ASC',
  RoyaltyApprovedMaxDesc = 'ROYALTY_APPROVED_MAX_DESC',
  RoyaltyDiscountAsc = 'ROYALTY_DISCOUNT_ASC',
  RoyaltyDiscountDesc = 'ROYALTY_DISCOUNT_DESC',
  RoyaltyDiscountMinAsc = 'ROYALTY_DISCOUNT_MIN_ASC',
  RoyaltyDiscountMinDesc = 'ROYALTY_DISCOUNT_MIN_DESC',
  RoyaltyDiscountMaxAsc = 'ROYALTY_DISCOUNT_MAX_ASC',
  RoyaltyDiscountMaxDesc = 'ROYALTY_DISCOUNT_MAX_DESC',
  RoyaltyApprovedDiscountAsc = 'ROYALTY_APPROVED_DISCOUNT_ASC',
  RoyaltyApprovedDiscountDesc = 'ROYALTY_APPROVED_DISCOUNT_DESC',
  RoyaltyApprovedDiscountMinAsc = 'ROYALTY_APPROVED_DISCOUNT_MIN_ASC',
  RoyaltyApprovedDiscountMinDesc = 'ROYALTY_APPROVED_DISCOUNT_MIN_DESC',
  RoyaltyApprovedDiscountMaxAsc = 'ROYALTY_APPROVED_DISCOUNT_MAX_ASC',
  RoyaltyApprovedDiscountMaxDesc = 'ROYALTY_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionCustomerVenueYearPriceAgg = {
  __typename?: 'LicenseSessionCustomerVenueYearPriceAgg';
  year?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  currency: Scalars['CurrencyType'];
  price?: Maybe<Scalars['BigFloat']>;
  priceMin?: Maybe<Scalars['BigFloat']>;
  priceMax?: Maybe<Scalars['BigFloat']>;
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  priceApproved?: Maybe<Scalars['BigFloat']>;
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueYearPriceAgg` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearPriceAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMin` field. */
  priceMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMax` field. */
  priceMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueYearPriceAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearPriceAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMin` field. */
  priceMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMax` field. */
  priceMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueYearPriceAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueYearPriceAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueYearPriceAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueYearPriceAgg` values. */
export type LicenseSessionCustomerVenueYearPriceAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueYearPriceAggsConnection';
  /** A list of `LicenseSessionCustomerVenueYearPriceAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueYearPriceAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueYearPriceAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueYearPriceAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueYearPriceAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueYearPriceAgg` edge in the connection. */
export type LicenseSessionCustomerVenueYearPriceAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueYearPriceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueYearPriceAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueYearPriceAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueYearPriceAgg`. */
export enum LicenseSessionCustomerVenueYearPriceAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceMinAsc = 'PRICE_MIN_ASC',
  PriceMinDesc = 'PRICE_MIN_DESC',
  PriceMaxAsc = 'PRICE_MAX_ASC',
  PriceMaxDesc = 'PRICE_MAX_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  PriceOriginalMinAsc = 'PRICE_ORIGINAL_MIN_ASC',
  PriceOriginalMinDesc = 'PRICE_ORIGINAL_MIN_DESC',
  PriceOriginalMaxAsc = 'PRICE_ORIGINAL_MAX_ASC',
  PriceOriginalMaxDesc = 'PRICE_ORIGINAL_MAX_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceApprovedMinAsc = 'PRICE_APPROVED_MIN_ASC',
  PriceApprovedMinDesc = 'PRICE_APPROVED_MIN_DESC',
  PriceApprovedMaxAsc = 'PRICE_APPROVED_MAX_ASC',
  PriceApprovedMaxDesc = 'PRICE_APPROVED_MAX_DESC',
  PriceDiscountAsc = 'PRICE_DISCOUNT_ASC',
  PriceDiscountDesc = 'PRICE_DISCOUNT_DESC',
  PriceDiscountMinAsc = 'PRICE_DISCOUNT_MIN_ASC',
  PriceDiscountMinDesc = 'PRICE_DISCOUNT_MIN_DESC',
  PriceDiscountMaxAsc = 'PRICE_DISCOUNT_MAX_ASC',
  PriceDiscountMaxDesc = 'PRICE_DISCOUNT_MAX_DESC',
  PriceApprovedDiscountAsc = 'PRICE_APPROVED_DISCOUNT_ASC',
  PriceApprovedDiscountDesc = 'PRICE_APPROVED_DISCOUNT_DESC',
  PriceApprovedDiscountMinAsc = 'PRICE_APPROVED_DISCOUNT_MIN_ASC',
  PriceApprovedDiscountMinDesc = 'PRICE_APPROVED_DISCOUNT_MIN_DESC',
  PriceApprovedDiscountMaxAsc = 'PRICE_APPROVED_DISCOUNT_MAX_ASC',
  PriceApprovedDiscountMaxDesc = 'PRICE_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionCustomerVenueYearRoyaltyAgg = {
  __typename?: 'LicenseSessionCustomerVenueYearRoyaltyAgg';
  year?: Maybe<Scalars['BigFloat']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  currency: Scalars['CurrencyType'];
  royalty?: Maybe<Scalars['BigFloat']>;
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionCustomerVenueYearRoyaltyAgg`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearRoyaltyAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A filter to be used against `LicenseSessionCustomerVenueYearRoyaltyAgg` object
 * types. All fields are combined with a logical ‘and.’
 */
export type LicenseSessionCustomerVenueYearRoyaltyAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionCustomerVenueYearRoyaltyAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionCustomerVenueYearRoyaltyAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggFilter>;
};

/** A connection to a list of `LicenseSessionCustomerVenueYearRoyaltyAgg` values. */
export type LicenseSessionCustomerVenueYearRoyaltyAggsConnection = {
  __typename?: 'LicenseSessionCustomerVenueYearRoyaltyAggsConnection';
  /** A list of `LicenseSessionCustomerVenueYearRoyaltyAgg` objects. */
  nodes: Array<Maybe<LicenseSessionCustomerVenueYearRoyaltyAgg>>;
  /** A list of edges which contains the `LicenseSessionCustomerVenueYearRoyaltyAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionCustomerVenueYearRoyaltyAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionCustomerVenueYearRoyaltyAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionCustomerVenueYearRoyaltyAgg` edge in the connection. */
export type LicenseSessionCustomerVenueYearRoyaltyAggsEdge = {
  __typename?: 'LicenseSessionCustomerVenueYearRoyaltyAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionCustomerVenueYearRoyaltyAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAgg>;
};

/** Methods to use when ordering `LicenseSessionCustomerVenueYearRoyaltyAgg`. */
export enum LicenseSessionCustomerVenueYearRoyaltyAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyMinAsc = 'ROYALTY_MIN_ASC',
  RoyaltyMinDesc = 'ROYALTY_MIN_DESC',
  RoyaltyMaxAsc = 'ROYALTY_MAX_ASC',
  RoyaltyMaxDesc = 'ROYALTY_MAX_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  RoyaltyOriginalMinAsc = 'ROYALTY_ORIGINAL_MIN_ASC',
  RoyaltyOriginalMinDesc = 'ROYALTY_ORIGINAL_MIN_DESC',
  RoyaltyOriginalMaxAsc = 'ROYALTY_ORIGINAL_MAX_ASC',
  RoyaltyOriginalMaxDesc = 'ROYALTY_ORIGINAL_MAX_DESC',
  RoyaltyApprovedAsc = 'ROYALTY_APPROVED_ASC',
  RoyaltyApprovedDesc = 'ROYALTY_APPROVED_DESC',
  RoyaltyApprovedMinAsc = 'ROYALTY_APPROVED_MIN_ASC',
  RoyaltyApprovedMinDesc = 'ROYALTY_APPROVED_MIN_DESC',
  RoyaltyApprovedMaxAsc = 'ROYALTY_APPROVED_MAX_ASC',
  RoyaltyApprovedMaxDesc = 'ROYALTY_APPROVED_MAX_DESC',
  RoyaltyDiscountAsc = 'ROYALTY_DISCOUNT_ASC',
  RoyaltyDiscountDesc = 'ROYALTY_DISCOUNT_DESC',
  RoyaltyDiscountMinAsc = 'ROYALTY_DISCOUNT_MIN_ASC',
  RoyaltyDiscountMinDesc = 'ROYALTY_DISCOUNT_MIN_DESC',
  RoyaltyDiscountMaxAsc = 'ROYALTY_DISCOUNT_MAX_ASC',
  RoyaltyDiscountMaxDesc = 'ROYALTY_DISCOUNT_MAX_DESC',
  RoyaltyApprovedDiscountAsc = 'ROYALTY_APPROVED_DISCOUNT_ASC',
  RoyaltyApprovedDiscountDesc = 'ROYALTY_APPROVED_DISCOUNT_DESC',
  RoyaltyApprovedDiscountMinAsc = 'ROYALTY_APPROVED_DISCOUNT_MIN_ASC',
  RoyaltyApprovedDiscountMinDesc = 'ROYALTY_APPROVED_DISCOUNT_MIN_DESC',
  RoyaltyApprovedDiscountMaxAsc = 'ROYALTY_APPROVED_DISCOUNT_MAX_ASC',
  RoyaltyApprovedDiscountMaxDesc = 'ROYALTY_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionDateAgg = {
  __typename?: 'LicenseSessionDateAgg';
  date?: Maybe<Scalars['Date']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unknownAmount?: Maybe<Scalars['BigInt']>;
  players?: Maybe<Scalars['BigInt']>;
  playersAvg?: Maybe<Scalars['BigFloat']>;
  playersMin?: Maybe<Scalars['Int']>;
  playersMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  durationAvg?: Maybe<Scalars['Float']>;
  durationMin?: Maybe<Scalars['Float']>;
  durationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionDateAgg` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionDateAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `playersAvg` field. */
  playersAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `playersMin` field. */
  playersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `playersMax` field. */
  playersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationAvg` field. */
  durationAvg?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMin` field. */
  durationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMax` field. */
  durationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionDateAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionDateAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<BigIntFilter>;
  /** Filter by the object’s `playersAvg` field. */
  playersAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `playersMin` field. */
  playersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `playersMax` field. */
  playersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationAvg` field. */
  durationAvg?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMin` field. */
  durationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMax` field. */
  durationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionDateAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionDateAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionDateAggFilter>;
};

/** A connection to a list of `LicenseSessionDateAgg` values. */
export type LicenseSessionDateAggsConnection = {
  __typename?: 'LicenseSessionDateAggsConnection';
  /** A list of `LicenseSessionDateAgg` objects. */
  nodes: Array<Maybe<LicenseSessionDateAgg>>;
  /** A list of edges which contains the `LicenseSessionDateAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionDateAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionDateAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionDateAgg` edge in the connection. */
export type LicenseSessionDateAggsEdge = {
  __typename?: 'LicenseSessionDateAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionDateAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionDateAgg>;
};

/** Methods to use when ordering `LicenseSessionDateAgg`. */
export enum LicenseSessionDateAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidOriginalAmountAsc = 'PAID_ORIGINAL_AMOUNT_ASC',
  PaidOriginalAmountDesc = 'PAID_ORIGINAL_AMOUNT_DESC',
  UnpaidOriginalAmountAsc = 'UNPAID_ORIGINAL_AMOUNT_ASC',
  UnpaidOriginalAmountDesc = 'UNPAID_ORIGINAL_AMOUNT_DESC',
  PaidSubmittedAmountAsc = 'PAID_SUBMITTED_AMOUNT_ASC',
  PaidSubmittedAmountDesc = 'PAID_SUBMITTED_AMOUNT_DESC',
  UnpaidSubmittedAmountAsc = 'UNPAID_SUBMITTED_AMOUNT_ASC',
  UnpaidSubmittedAmountDesc = 'UNPAID_SUBMITTED_AMOUNT_DESC',
  UnknownAmountAsc = 'UNKNOWN_AMOUNT_ASC',
  UnknownAmountDesc = 'UNKNOWN_AMOUNT_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  PlayersAvgAsc = 'PLAYERS_AVG_ASC',
  PlayersAvgDesc = 'PLAYERS_AVG_DESC',
  PlayersMinAsc = 'PLAYERS_MIN_ASC',
  PlayersMinDesc = 'PLAYERS_MIN_DESC',
  PlayersMaxAsc = 'PLAYERS_MAX_ASC',
  PlayersMaxDesc = 'PLAYERS_MAX_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DurationAvgAsc = 'DURATION_AVG_ASC',
  DurationAvgDesc = 'DURATION_AVG_DESC',
  DurationMinAsc = 'DURATION_MIN_ASC',
  DurationMinDesc = 'DURATION_MIN_DESC',
  DurationMaxAsc = 'DURATION_MAX_ASC',
  DurationMaxDesc = 'DURATION_MAX_DESC'
}

export type LicenseSessionDatePriceAgg = {
  __typename?: 'LicenseSessionDatePriceAgg';
  date?: Maybe<Scalars['Date']>;
  currency: Scalars['CurrencyType'];
  price?: Maybe<Scalars['BigFloat']>;
  priceAvg?: Maybe<Scalars['BigFloat']>;
  priceMin?: Maybe<Scalars['BigFloat']>;
  priceMax?: Maybe<Scalars['BigFloat']>;
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  priceOriginalAvg?: Maybe<Scalars['BigFloat']>;
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  priceApproved?: Maybe<Scalars['BigFloat']>;
  priceApprovedAvg?: Maybe<Scalars['BigFloat']>;
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  priceDiscountAvg?: Maybe<Scalars['BigFloat']>;
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionDatePriceAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionDatePriceAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceAvg` field. */
  priceAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMin` field. */
  priceMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMax` field. */
  priceMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalAvg` field. */
  priceOriginalAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedAvg` field. */
  priceApprovedAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountAvg` field. */
  priceDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountAvg` field. */
  priceApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `LicenseSessionDatePriceAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionDatePriceAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceAvg` field. */
  priceAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMin` field. */
  priceMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMax` field. */
  priceMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalAvg` field. */
  priceOriginalAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedAvg` field. */
  priceApprovedAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountAvg` field. */
  priceDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountAvg` field. */
  priceApprovedDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionDatePriceAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionDatePriceAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionDatePriceAggFilter>;
};

/** A connection to a list of `LicenseSessionDatePriceAgg` values. */
export type LicenseSessionDatePriceAggsConnection = {
  __typename?: 'LicenseSessionDatePriceAggsConnection';
  /** A list of `LicenseSessionDatePriceAgg` objects. */
  nodes: Array<Maybe<LicenseSessionDatePriceAgg>>;
  /** A list of edges which contains the `LicenseSessionDatePriceAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionDatePriceAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionDatePriceAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionDatePriceAgg` edge in the connection. */
export type LicenseSessionDatePriceAggsEdge = {
  __typename?: 'LicenseSessionDatePriceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionDatePriceAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionDatePriceAgg>;
};

/** Methods to use when ordering `LicenseSessionDatePriceAgg`. */
export enum LicenseSessionDatePriceAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceAvgAsc = 'PRICE_AVG_ASC',
  PriceAvgDesc = 'PRICE_AVG_DESC',
  PriceMinAsc = 'PRICE_MIN_ASC',
  PriceMinDesc = 'PRICE_MIN_DESC',
  PriceMaxAsc = 'PRICE_MAX_ASC',
  PriceMaxDesc = 'PRICE_MAX_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  PriceOriginalAvgAsc = 'PRICE_ORIGINAL_AVG_ASC',
  PriceOriginalAvgDesc = 'PRICE_ORIGINAL_AVG_DESC',
  PriceOriginalMinAsc = 'PRICE_ORIGINAL_MIN_ASC',
  PriceOriginalMinDesc = 'PRICE_ORIGINAL_MIN_DESC',
  PriceOriginalMaxAsc = 'PRICE_ORIGINAL_MAX_ASC',
  PriceOriginalMaxDesc = 'PRICE_ORIGINAL_MAX_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceApprovedAvgAsc = 'PRICE_APPROVED_AVG_ASC',
  PriceApprovedAvgDesc = 'PRICE_APPROVED_AVG_DESC',
  PriceApprovedMinAsc = 'PRICE_APPROVED_MIN_ASC',
  PriceApprovedMinDesc = 'PRICE_APPROVED_MIN_DESC',
  PriceApprovedMaxAsc = 'PRICE_APPROVED_MAX_ASC',
  PriceApprovedMaxDesc = 'PRICE_APPROVED_MAX_DESC',
  PriceDiscountAsc = 'PRICE_DISCOUNT_ASC',
  PriceDiscountDesc = 'PRICE_DISCOUNT_DESC',
  PriceDiscountAvgAsc = 'PRICE_DISCOUNT_AVG_ASC',
  PriceDiscountAvgDesc = 'PRICE_DISCOUNT_AVG_DESC',
  PriceDiscountMinAsc = 'PRICE_DISCOUNT_MIN_ASC',
  PriceDiscountMinDesc = 'PRICE_DISCOUNT_MIN_DESC',
  PriceDiscountMaxAsc = 'PRICE_DISCOUNT_MAX_ASC',
  PriceDiscountMaxDesc = 'PRICE_DISCOUNT_MAX_DESC',
  PriceApprovedDiscountAsc = 'PRICE_APPROVED_DISCOUNT_ASC',
  PriceApprovedDiscountDesc = 'PRICE_APPROVED_DISCOUNT_DESC',
  PriceApprovedDiscountAvgAsc = 'PRICE_APPROVED_DISCOUNT_AVG_ASC',
  PriceApprovedDiscountAvgDesc = 'PRICE_APPROVED_DISCOUNT_AVG_DESC',
  PriceApprovedDiscountMinAsc = 'PRICE_APPROVED_DISCOUNT_MIN_ASC',
  PriceApprovedDiscountMinDesc = 'PRICE_APPROVED_DISCOUNT_MIN_DESC',
  PriceApprovedDiscountMaxAsc = 'PRICE_APPROVED_DISCOUNT_MAX_ASC',
  PriceApprovedDiscountMaxDesc = 'PRICE_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionDateRoyaltyAgg = {
  __typename?: 'LicenseSessionDateRoyaltyAgg';
  date?: Maybe<Scalars['Date']>;
  currency: Scalars['CurrencyType'];
  royalty?: Maybe<Scalars['BigFloat']>;
  royaltyAvg?: Maybe<Scalars['BigFloat']>;
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalAvg?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedAvg?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountAvg?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionDateRoyaltyAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionDateRoyaltyAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyAvg` field. */
  royaltyAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalAvg` field. */
  royaltyOriginalAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedAvg` field. */
  royaltyApprovedAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountAvg` field. */
  royaltyDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountAvg` field. */
  royaltyApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `LicenseSessionDateRoyaltyAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionDateRoyaltyAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyAvg` field. */
  royaltyAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalAvg` field. */
  royaltyOriginalAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedAvg` field. */
  royaltyApprovedAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountAvg` field. */
  royaltyDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountAvg` field. */
  royaltyApprovedDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionDateRoyaltyAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionDateRoyaltyAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionDateRoyaltyAggFilter>;
};

/** A connection to a list of `LicenseSessionDateRoyaltyAgg` values. */
export type LicenseSessionDateRoyaltyAggsConnection = {
  __typename?: 'LicenseSessionDateRoyaltyAggsConnection';
  /** A list of `LicenseSessionDateRoyaltyAgg` objects. */
  nodes: Array<Maybe<LicenseSessionDateRoyaltyAgg>>;
  /** A list of edges which contains the `LicenseSessionDateRoyaltyAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionDateRoyaltyAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionDateRoyaltyAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionDateRoyaltyAgg` edge in the connection. */
export type LicenseSessionDateRoyaltyAggsEdge = {
  __typename?: 'LicenseSessionDateRoyaltyAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionDateRoyaltyAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionDateRoyaltyAgg>;
};

/** Methods to use when ordering `LicenseSessionDateRoyaltyAgg`. */
export enum LicenseSessionDateRoyaltyAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyAvgAsc = 'ROYALTY_AVG_ASC',
  RoyaltyAvgDesc = 'ROYALTY_AVG_DESC',
  RoyaltyMinAsc = 'ROYALTY_MIN_ASC',
  RoyaltyMinDesc = 'ROYALTY_MIN_DESC',
  RoyaltyMaxAsc = 'ROYALTY_MAX_ASC',
  RoyaltyMaxDesc = 'ROYALTY_MAX_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  RoyaltyOriginalAvgAsc = 'ROYALTY_ORIGINAL_AVG_ASC',
  RoyaltyOriginalAvgDesc = 'ROYALTY_ORIGINAL_AVG_DESC',
  RoyaltyOriginalMinAsc = 'ROYALTY_ORIGINAL_MIN_ASC',
  RoyaltyOriginalMinDesc = 'ROYALTY_ORIGINAL_MIN_DESC',
  RoyaltyOriginalMaxAsc = 'ROYALTY_ORIGINAL_MAX_ASC',
  RoyaltyOriginalMaxDesc = 'ROYALTY_ORIGINAL_MAX_DESC',
  RoyaltyApprovedAsc = 'ROYALTY_APPROVED_ASC',
  RoyaltyApprovedDesc = 'ROYALTY_APPROVED_DESC',
  RoyaltyApprovedAvgAsc = 'ROYALTY_APPROVED_AVG_ASC',
  RoyaltyApprovedAvgDesc = 'ROYALTY_APPROVED_AVG_DESC',
  RoyaltyApprovedMinAsc = 'ROYALTY_APPROVED_MIN_ASC',
  RoyaltyApprovedMinDesc = 'ROYALTY_APPROVED_MIN_DESC',
  RoyaltyApprovedMaxAsc = 'ROYALTY_APPROVED_MAX_ASC',
  RoyaltyApprovedMaxDesc = 'ROYALTY_APPROVED_MAX_DESC',
  RoyaltyDiscountAsc = 'ROYALTY_DISCOUNT_ASC',
  RoyaltyDiscountDesc = 'ROYALTY_DISCOUNT_DESC',
  RoyaltyDiscountAvgAsc = 'ROYALTY_DISCOUNT_AVG_ASC',
  RoyaltyDiscountAvgDesc = 'ROYALTY_DISCOUNT_AVG_DESC',
  RoyaltyDiscountMinAsc = 'ROYALTY_DISCOUNT_MIN_ASC',
  RoyaltyDiscountMinDesc = 'ROYALTY_DISCOUNT_MIN_DESC',
  RoyaltyDiscountMaxAsc = 'ROYALTY_DISCOUNT_MAX_ASC',
  RoyaltyDiscountMaxDesc = 'ROYALTY_DISCOUNT_MAX_DESC',
  RoyaltyApprovedDiscountAsc = 'ROYALTY_APPROVED_DISCOUNT_ASC',
  RoyaltyApprovedDiscountDesc = 'ROYALTY_APPROVED_DISCOUNT_DESC',
  RoyaltyApprovedDiscountAvgAsc = 'ROYALTY_APPROVED_DISCOUNT_AVG_ASC',
  RoyaltyApprovedDiscountAvgDesc = 'ROYALTY_APPROVED_DISCOUNT_AVG_DESC',
  RoyaltyApprovedDiscountMinAsc = 'ROYALTY_APPROVED_DISCOUNT_MIN_ASC',
  RoyaltyApprovedDiscountMinDesc = 'ROYALTY_APPROVED_DISCOUNT_MIN_DESC',
  RoyaltyApprovedDiscountMaxAsc = 'ROYALTY_APPROVED_DISCOUNT_MAX_ASC',
  RoyaltyApprovedDiscountMaxDesc = 'ROYALTY_APPROVED_DISCOUNT_MAX_DESC'
}

/** A filter to be used against `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseId` field. */
  licenseId?: Maybe<UuidFilter>;
  /** Filter by the object’s `reportedSessionId` field. */
  reportedSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `osVersion` field. */
  osVersion?: Maybe<StringListFilter>;
  /** Filter by the object’s `productVersion` field. */
  productVersion?: Maybe<StringListFilter>;
  /** Filter by the object’s `licenseKey` field. */
  licenseKey?: Maybe<StringListFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<IntFilter>;
  /** Filter by the object’s `isPaid` field. */
  isPaid?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPaidApproved` field. */
  isPaidApproved?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPaidOriginal` field. */
  isPaidOriginal?: Maybe<BooleanFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<StringListFilter>;
  /** Filter by the object’s `startTimeLocal` field. */
  startTimeLocal?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endTimeLocal` field. */
  endTimeLocal?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isLocked` field. */
  isLocked?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `masterSessionId` field. */
  masterSessionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseV2Id` field. */
  licenseV2Id?: Maybe<UuidFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<UuidFilter>;
  /** Filter by the object’s `locationCoords` field. */
  locationCoords?: Maybe<StringListFilter>;
  /** Filter by the object’s `ip` field. */
  ip?: Maybe<StringListFilter>;
  /** Filter by the object’s `productStateAgg` field. */
  productStateAgg?: Maybe<JsonFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<UuidFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `machineid` field. */
  machineid?: Maybe<StringListFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: Maybe<BooleanFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `priceApprovedTotal` field. */
  priceApprovedTotal?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `priceApprovedTotalCurrencyUsdRate` field. */
  priceApprovedTotalCurrencyUsdRate?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `priceOriginalTotal` field. */
  priceOriginalTotal?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `priceTotal` field. */
  priceTotal?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyApprovedTotal` field. */
  royaltyApprovedTotal?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyCurrencyUsdRate` field. */
  royaltyCurrencyUsdRate?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyOriginalTotal` field. */
  royaltyOriginalTotal?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyTotal` field. */
  royaltyTotal?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<PriceDefinitionFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<PriceDefinitionFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<PriceDefinitionFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<RoyaltyDefinitionFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<RoyaltyDefinitionFilter>;
  /** Filter by the object’s `licenseSessionsByMasterSessionId` relation. */
  licenseSessionsByMasterSessionId?: Maybe<LicenseSessionToManyLicenseSessionFilter>;
  /** Some related `licenseSessionsByMasterSessionId` exist. */
  licenseSessionsByMasterSessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseEventsByLicenseSessionId` relation. */
  licenseEventsByLicenseSessionId?: Maybe<LicenseSessionToManyLicenseEventFilter>;
  /** Some related `licenseEventsByLicenseSessionId` exist. */
  licenseEventsByLicenseSessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseSessionCommentsByLicenseSessionId` relation. */
  licenseSessionCommentsByLicenseSessionId?: Maybe<LicenseSessionToManyLicenseSessionCommentFilter>;
  /** Some related `licenseSessionCommentsByLicenseSessionId` exist. */
  licenseSessionCommentsByLicenseSessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leaderboardSessionsByLicenseSessionId` relation. */
  leaderboardSessionsByLicenseSessionId?: Maybe<LicenseSessionToManyLeaderboardSessionFilter>;
  /** Some related `leaderboardSessionsByLicenseSessionId` exist. */
  leaderboardSessionsByLicenseSessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseByLicenseId` relation. */
  licenseByLicenseId?: Maybe<LicenseFilter>;
  /** A related `licenseByLicenseId` exists. */
  licenseByLicenseIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseSessionByMasterSessionId` relation. */
  licenseSessionByMasterSessionId?: Maybe<LicenseSessionFilter>;
  /** A related `licenseSessionByMasterSessionId` exists. */
  licenseSessionByMasterSessionIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseV2ByLicenseV2Id` relation. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2Filter>;
  /** A related `licenseV2ByLicenseV2Id` exists. */
  licenseV2ByLicenseV2IdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `productByProductId` relation. */
  productByProductId?: Maybe<ProductFilter>;
  /** A related `productByProductId` exists. */
  productByProductIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerByCustomerId` relation. */
  customerByCustomerId?: Maybe<CustomerFilter>;
  /** A related `customerByCustomerId` exists. */
  customerByCustomerIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `venueByVenueId` relation. */
  venueByVenueId?: Maybe<VenueFilter>;
  /** A related `venueByVenueId` exists. */
  venueByVenueIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionFilter>;
};

/** An input for mutations affecting `LicenseSession` */
export type LicenseSessionInput = {
  id?: Maybe<Scalars['UUID']>;
  licenseId?: Maybe<Scalars['UUID']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenseKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPaidApproved?: Maybe<Scalars['Boolean']>;
  isPaidOriginal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  startTimeLocal: Scalars['Datetime'];
  endTimeLocal: Scalars['Datetime'];
  price: PriceDefinitionInput;
  priceApproved: PriceDefinitionInput;
  priceOriginal: PriceDefinitionInput;
  royalty: RoyaltyDefinitionInput;
  royaltyOriginal: RoyaltyDefinitionInput;
  isLocked?: Maybe<Scalars['Boolean']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  masterSessionId?: Maybe<Scalars['UUID']>;
  licenseV2Id?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  locationCoords?: Maybe<Array<Maybe<Scalars['String']>>>;
  ip?: Maybe<Array<Maybe<Scalars['String']>>>;
  productStateAgg?: Maybe<Scalars['JSON']>;
  customerId?: Maybe<Scalars['UUID']>;
  venueId?: Maybe<Scalars['UUID']>;
  machineid?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Represents an update to a `LicenseSession`. Fields that are set will be updated. */
export type LicenseSessionPatch = {
  id?: Maybe<Scalars['UUID']>;
  licenseId?: Maybe<Scalars['UUID']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenseKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPaidApproved?: Maybe<Scalars['Boolean']>;
  isPaidOriginal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  startTimeLocal?: Maybe<Scalars['Datetime']>;
  endTimeLocal?: Maybe<Scalars['Datetime']>;
  price?: Maybe<PriceDefinitionInput>;
  priceApproved?: Maybe<PriceDefinitionInput>;
  priceOriginal?: Maybe<PriceDefinitionInput>;
  royalty?: Maybe<RoyaltyDefinitionInput>;
  royaltyOriginal?: Maybe<RoyaltyDefinitionInput>;
  isLocked?: Maybe<Scalars['Boolean']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  masterSessionId?: Maybe<Scalars['UUID']>;
  licenseV2Id?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  locationCoords?: Maybe<Array<Maybe<Scalars['String']>>>;
  ip?: Maybe<Array<Maybe<Scalars['String']>>>;
  productStateAgg?: Maybe<Scalars['JSON']>;
  customerId?: Maybe<Scalars['UUID']>;
  venueId?: Maybe<Scalars['UUID']>;
  machineid?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LicenseSessionProductDateAgg = {
  __typename?: 'LicenseSessionProductDateAgg';
  date?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productArchived?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['BigInt']>;
  players?: Maybe<Scalars['BigInt']>;
  playersAvg?: Maybe<Scalars['BigFloat']>;
  playersMin?: Maybe<Scalars['Int']>;
  playersMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  durationAvg?: Maybe<Scalars['Float']>;
  durationMin?: Maybe<Scalars['Float']>;
  durationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionProductDateAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionProductDateAggCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productArchived` field. */
  productArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `count` field. */
  count?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `playersAvg` field. */
  playersAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `playersMin` field. */
  playersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `playersMax` field. */
  playersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationAvg` field. */
  durationAvg?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMin` field. */
  durationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMax` field. */
  durationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionProductDateAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionProductDateAggFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<DateFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `productArchived` field. */
  productArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `count` field. */
  count?: Maybe<BigIntFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<BigIntFilter>;
  /** Filter by the object’s `playersAvg` field. */
  playersAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `playersMin` field. */
  playersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `playersMax` field. */
  playersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationAvg` field. */
  durationAvg?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMin` field. */
  durationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMax` field. */
  durationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionProductDateAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionProductDateAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionProductDateAggFilter>;
};

/** A connection to a list of `LicenseSessionProductDateAgg` values. */
export type LicenseSessionProductDateAggsConnection = {
  __typename?: 'LicenseSessionProductDateAggsConnection';
  /** A list of `LicenseSessionProductDateAgg` objects. */
  nodes: Array<Maybe<LicenseSessionProductDateAgg>>;
  /** A list of edges which contains the `LicenseSessionProductDateAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionProductDateAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionProductDateAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionProductDateAgg` edge in the connection. */
export type LicenseSessionProductDateAggsEdge = {
  __typename?: 'LicenseSessionProductDateAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionProductDateAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionProductDateAgg>;
};

/** Methods to use when ordering `LicenseSessionProductDateAgg`. */
export enum LicenseSessionProductDateAggsOrderBy {
  Natural = 'NATURAL',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProductArchivedAsc = 'PRODUCT_ARCHIVED_ASC',
  ProductArchivedDesc = 'PRODUCT_ARCHIVED_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  PlayersAvgAsc = 'PLAYERS_AVG_ASC',
  PlayersAvgDesc = 'PLAYERS_AVG_DESC',
  PlayersMinAsc = 'PLAYERS_MIN_ASC',
  PlayersMinDesc = 'PLAYERS_MIN_DESC',
  PlayersMaxAsc = 'PLAYERS_MAX_ASC',
  PlayersMaxDesc = 'PLAYERS_MAX_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DurationAvgAsc = 'DURATION_AVG_ASC',
  DurationAvgDesc = 'DURATION_AVG_DESC',
  DurationMinAsc = 'DURATION_MIN_ASC',
  DurationMinDesc = 'DURATION_MIN_DESC',
  DurationMaxAsc = 'DURATION_MAX_ASC',
  DurationMaxDesc = 'DURATION_MAX_DESC'
}

export type LicenseSessionProductYearAgg = {
  __typename?: 'LicenseSessionProductYearAgg';
  year?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productArchived?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['BigInt']>;
  players?: Maybe<Scalars['BigInt']>;
  playersAvg?: Maybe<Scalars['BigFloat']>;
  playersMin?: Maybe<Scalars['Int']>;
  playersMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  durationAvg?: Maybe<Scalars['Float']>;
  durationMin?: Maybe<Scalars['Float']>;
  durationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionProductYearAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionProductYearAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productArchived` field. */
  productArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `count` field. */
  count?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `playersAvg` field. */
  playersAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `playersMin` field. */
  playersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `playersMax` field. */
  playersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationAvg` field. */
  durationAvg?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMin` field. */
  durationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMax` field. */
  durationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionProductYearAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionProductYearAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `productArchived` field. */
  productArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `count` field. */
  count?: Maybe<BigIntFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<BigIntFilter>;
  /** Filter by the object’s `playersAvg` field. */
  playersAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `playersMin` field. */
  playersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `playersMax` field. */
  playersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationAvg` field. */
  durationAvg?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMin` field. */
  durationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMax` field. */
  durationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionProductYearAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionProductYearAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionProductYearAggFilter>;
};

/** A connection to a list of `LicenseSessionProductYearAgg` values. */
export type LicenseSessionProductYearAggsConnection = {
  __typename?: 'LicenseSessionProductYearAggsConnection';
  /** A list of `LicenseSessionProductYearAgg` objects. */
  nodes: Array<Maybe<LicenseSessionProductYearAgg>>;
  /** A list of edges which contains the `LicenseSessionProductYearAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionProductYearAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionProductYearAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionProductYearAgg` edge in the connection. */
export type LicenseSessionProductYearAggsEdge = {
  __typename?: 'LicenseSessionProductYearAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionProductYearAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionProductYearAgg>;
};

/** Methods to use when ordering `LicenseSessionProductYearAgg`. */
export enum LicenseSessionProductYearAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProductArchivedAsc = 'PRODUCT_ARCHIVED_ASC',
  ProductArchivedDesc = 'PRODUCT_ARCHIVED_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  PlayersAvgAsc = 'PLAYERS_AVG_ASC',
  PlayersAvgDesc = 'PLAYERS_AVG_DESC',
  PlayersMinAsc = 'PLAYERS_MIN_ASC',
  PlayersMinDesc = 'PLAYERS_MIN_DESC',
  PlayersMaxAsc = 'PLAYERS_MAX_ASC',
  PlayersMaxDesc = 'PLAYERS_MAX_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DurationAvgAsc = 'DURATION_AVG_ASC',
  DurationAvgDesc = 'DURATION_AVG_DESC',
  DurationMinAsc = 'DURATION_MIN_ASC',
  DurationMinDesc = 'DURATION_MIN_DESC',
  DurationMaxAsc = 'DURATION_MAX_ASC',
  DurationMaxDesc = 'DURATION_MAX_DESC'
}

export type LicenseSessionProductYearMonthAgg = {
  __typename?: 'LicenseSessionProductYearMonthAgg';
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productArchived?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['BigInt']>;
  players?: Maybe<Scalars['BigInt']>;
  playersAvg?: Maybe<Scalars['BigFloat']>;
  playersMin?: Maybe<Scalars['Int']>;
  playersMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  durationAvg?: Maybe<Scalars['Float']>;
  durationMin?: Maybe<Scalars['Float']>;
  durationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionProductYearMonthAgg` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionProductYearMonthAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productArchived` field. */
  productArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `count` field. */
  count?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `playersAvg` field. */
  playersAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `playersMin` field. */
  playersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `playersMax` field. */
  playersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationAvg` field. */
  durationAvg?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMin` field. */
  durationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMax` field. */
  durationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionProductYearMonthAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionProductYearMonthAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<FloatFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `productArchived` field. */
  productArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `count` field. */
  count?: Maybe<BigIntFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<BigIntFilter>;
  /** Filter by the object’s `playersAvg` field. */
  playersAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `playersMin` field. */
  playersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `playersMax` field. */
  playersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationAvg` field. */
  durationAvg?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMin` field. */
  durationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMax` field. */
  durationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionProductYearMonthAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionProductYearMonthAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionProductYearMonthAggFilter>;
};

/** A connection to a list of `LicenseSessionProductYearMonthAgg` values. */
export type LicenseSessionProductYearMonthAggsConnection = {
  __typename?: 'LicenseSessionProductYearMonthAggsConnection';
  /** A list of `LicenseSessionProductYearMonthAgg` objects. */
  nodes: Array<Maybe<LicenseSessionProductYearMonthAgg>>;
  /** A list of edges which contains the `LicenseSessionProductYearMonthAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionProductYearMonthAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionProductYearMonthAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionProductYearMonthAgg` edge in the connection. */
export type LicenseSessionProductYearMonthAggsEdge = {
  __typename?: 'LicenseSessionProductYearMonthAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionProductYearMonthAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionProductYearMonthAgg>;
};

/** Methods to use when ordering `LicenseSessionProductYearMonthAgg`. */
export enum LicenseSessionProductYearMonthAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProductArchivedAsc = 'PRODUCT_ARCHIVED_ASC',
  ProductArchivedDesc = 'PRODUCT_ARCHIVED_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  PlayersAvgAsc = 'PLAYERS_AVG_ASC',
  PlayersAvgDesc = 'PLAYERS_AVG_DESC',
  PlayersMinAsc = 'PLAYERS_MIN_ASC',
  PlayersMinDesc = 'PLAYERS_MIN_DESC',
  PlayersMaxAsc = 'PLAYERS_MAX_ASC',
  PlayersMaxDesc = 'PLAYERS_MAX_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DurationAvgAsc = 'DURATION_AVG_ASC',
  DurationAvgDesc = 'DURATION_AVG_DESC',
  DurationMinAsc = 'DURATION_MIN_ASC',
  DurationMinDesc = 'DURATION_MIN_DESC',
  DurationMaxAsc = 'DURATION_MAX_ASC',
  DurationMaxDesc = 'DURATION_MAX_DESC'
}

/** A connection to a list of `LicenseSessionsAllCurrency` values. */
export type LicenseSessionsAllCurrenciesConnection = {
  __typename?: 'LicenseSessionsAllCurrenciesConnection';
  /** A list of `LicenseSessionsAllCurrency` objects. */
  nodes: Array<Maybe<LicenseSessionsAllCurrency>>;
  /** A list of edges which contains the `LicenseSessionsAllCurrency` and cursor to aid in pagination. */
  edges: Array<LicenseSessionsAllCurrenciesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionsAllCurrency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionsAllCurrency` edge in the connection. */
export type LicenseSessionsAllCurrenciesEdge = {
  __typename?: 'LicenseSessionsAllCurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionsAllCurrency` at the end of the edge. */
  node?: Maybe<LicenseSessionsAllCurrency>;
};

/** Methods to use when ordering `LicenseSessionsAllCurrency`. */
export enum LicenseSessionsAllCurrenciesOrderBy {
  Natural = 'NATURAL',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC'
}

export type LicenseSessionsAllCurrency = {
  __typename?: 'LicenseSessionsAllCurrency';
  currency: Scalars['CurrencyType'];
};

/**
 * A condition to be used against `LicenseSessionsAllCurrency` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionsAllCurrencyCondition = {
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
};

/** A filter to be used against `LicenseSessionsAllCurrency` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionsAllCurrencyFilter = {
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionsAllCurrencyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionsAllCurrencyFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionsAllCurrencyFilter>;
};

/** A connection to a list of `LicenseSession` values. */
export type LicenseSessionsConnection = {
  __typename?: 'LicenseSessionsConnection';
  /** A list of `LicenseSession` objects. */
  nodes: Array<Maybe<LicenseSession>>;
  /** A list of edges which contains the `LicenseSession` and cursor to aid in pagination. */
  edges: Array<LicenseSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSession` edge in the connection. */
export type LicenseSessionsEdge = {
  __typename?: 'LicenseSessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSession` at the end of the edge. */
  node?: Maybe<LicenseSession>;
};

/** Methods to use when ordering `LicenseSession`. */
export enum LicenseSessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseIdAsc = 'LICENSE_ID_ASC',
  LicenseIdDesc = 'LICENSE_ID_DESC',
  ReportedSessionIdAsc = 'REPORTED_SESSION_ID_ASC',
  ReportedSessionIdDesc = 'REPORTED_SESSION_ID_DESC',
  OsVersionAsc = 'OS_VERSION_ASC',
  OsVersionDesc = 'OS_VERSION_DESC',
  ProductVersionAsc = 'PRODUCT_VERSION_ASC',
  ProductVersionDesc = 'PRODUCT_VERSION_DESC',
  LicenseKeyAsc = 'LICENSE_KEY_ASC',
  LicenseKeyDesc = 'LICENSE_KEY_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  IsPaidAsc = 'IS_PAID_ASC',
  IsPaidDesc = 'IS_PAID_DESC',
  IsPaidApprovedAsc = 'IS_PAID_APPROVED_ASC',
  IsPaidApprovedDesc = 'IS_PAID_APPROVED_DESC',
  IsPaidOriginalAsc = 'IS_PAID_ORIGINAL_ASC',
  IsPaidOriginalDesc = 'IS_PAID_ORIGINAL_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  StartTimeLocalAsc = 'START_TIME_LOCAL_ASC',
  StartTimeLocalDesc = 'START_TIME_LOCAL_DESC',
  EndTimeLocalAsc = 'END_TIME_LOCAL_ASC',
  EndTimeLocalDesc = 'END_TIME_LOCAL_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  IsLockedAsc = 'IS_LOCKED_ASC',
  IsLockedDesc = 'IS_LOCKED_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  MasterSessionIdAsc = 'MASTER_SESSION_ID_ASC',
  MasterSessionIdDesc = 'MASTER_SESSION_ID_DESC',
  LicenseV2IdAsc = 'LICENSE_V2_ID_ASC',
  LicenseV2IdDesc = 'LICENSE_V2_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  LocationCoordsAsc = 'LOCATION_COORDS_ASC',
  LocationCoordsDesc = 'LOCATION_COORDS_DESC',
  IpAsc = 'IP_ASC',
  IpDesc = 'IP_DESC',
  ProductStateAggAsc = 'PRODUCT_STATE_AGG_ASC',
  ProductStateAggDesc = 'PRODUCT_STATE_AGG_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  MachineidAsc = 'MACHINEID_ASC',
  MachineidDesc = 'MACHINEID_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LeaderboardSession` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionToManyLeaderboardSessionFilter = {
  /** Every related `LeaderboardSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeaderboardSessionFilter>;
  /** Some related `LeaderboardSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeaderboardSessionFilter>;
  /** No related `LeaderboardSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeaderboardSessionFilter>;
};

/** A filter to be used against many `LicenseEvent` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionToManyLicenseEventFilter = {
  /** Every related `LicenseEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseEventFilter>;
  /** Some related `LicenseEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseEventFilter>;
  /** No related `LicenseEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseEventFilter>;
};

/** A filter to be used against many `LicenseSessionComment` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionToManyLicenseSessionCommentFilter = {
  /** Every related `LicenseSessionComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionCommentFilter>;
  /** Some related `LicenseSessionComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionCommentFilter>;
  /** No related `LicenseSessionComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionCommentFilter>;
};

/** A filter to be used against many `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionToManyLicenseSessionFilter = {
  /** Every related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionFilter>;
  /** Some related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionFilter>;
  /** No related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionFilter>;
};

export type LicenseSessionYearAgg = {
  __typename?: 'LicenseSessionYearAgg';
  year?: Maybe<Scalars['Float']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unknownAmount?: Maybe<Scalars['BigInt']>;
  players?: Maybe<Scalars['BigInt']>;
  playersAvg?: Maybe<Scalars['BigFloat']>;
  playersMin?: Maybe<Scalars['Int']>;
  playersMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  durationAvg?: Maybe<Scalars['Float']>;
  durationMin?: Maybe<Scalars['Float']>;
  durationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionYearAgg` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionYearAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `playersAvg` field. */
  playersAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `playersMin` field. */
  playersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `playersMax` field. */
  playersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationAvg` field. */
  durationAvg?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMin` field. */
  durationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMax` field. */
  durationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionYearAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionYearAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<BigIntFilter>;
  /** Filter by the object’s `playersAvg` field. */
  playersAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `playersMin` field. */
  playersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `playersMax` field. */
  playersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationAvg` field. */
  durationAvg?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMin` field. */
  durationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMax` field. */
  durationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionYearAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionYearAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionYearAggFilter>;
};

/** A connection to a list of `LicenseSessionYearAgg` values. */
export type LicenseSessionYearAggsConnection = {
  __typename?: 'LicenseSessionYearAggsConnection';
  /** A list of `LicenseSessionYearAgg` objects. */
  nodes: Array<Maybe<LicenseSessionYearAgg>>;
  /** A list of edges which contains the `LicenseSessionYearAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionYearAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionYearAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionYearAgg` edge in the connection. */
export type LicenseSessionYearAggsEdge = {
  __typename?: 'LicenseSessionYearAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionYearAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionYearAgg>;
};

/** Methods to use when ordering `LicenseSessionYearAgg`. */
export enum LicenseSessionYearAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidOriginalAmountAsc = 'PAID_ORIGINAL_AMOUNT_ASC',
  PaidOriginalAmountDesc = 'PAID_ORIGINAL_AMOUNT_DESC',
  UnpaidOriginalAmountAsc = 'UNPAID_ORIGINAL_AMOUNT_ASC',
  UnpaidOriginalAmountDesc = 'UNPAID_ORIGINAL_AMOUNT_DESC',
  PaidSubmittedAmountAsc = 'PAID_SUBMITTED_AMOUNT_ASC',
  PaidSubmittedAmountDesc = 'PAID_SUBMITTED_AMOUNT_DESC',
  UnpaidSubmittedAmountAsc = 'UNPAID_SUBMITTED_AMOUNT_ASC',
  UnpaidSubmittedAmountDesc = 'UNPAID_SUBMITTED_AMOUNT_DESC',
  UnknownAmountAsc = 'UNKNOWN_AMOUNT_ASC',
  UnknownAmountDesc = 'UNKNOWN_AMOUNT_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  PlayersAvgAsc = 'PLAYERS_AVG_ASC',
  PlayersAvgDesc = 'PLAYERS_AVG_DESC',
  PlayersMinAsc = 'PLAYERS_MIN_ASC',
  PlayersMinDesc = 'PLAYERS_MIN_DESC',
  PlayersMaxAsc = 'PLAYERS_MAX_ASC',
  PlayersMaxDesc = 'PLAYERS_MAX_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DurationAvgAsc = 'DURATION_AVG_ASC',
  DurationAvgDesc = 'DURATION_AVG_DESC',
  DurationMinAsc = 'DURATION_MIN_ASC',
  DurationMinDesc = 'DURATION_MIN_DESC',
  DurationMaxAsc = 'DURATION_MAX_ASC',
  DurationMaxDesc = 'DURATION_MAX_DESC'
}

export type LicenseSessionYearMonthAgg = {
  __typename?: 'LicenseSessionYearMonthAgg';
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  unknownAmount?: Maybe<Scalars['BigInt']>;
  players?: Maybe<Scalars['BigInt']>;
  playersAvg?: Maybe<Scalars['BigFloat']>;
  playersMin?: Maybe<Scalars['Int']>;
  playersMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  durationAvg?: Maybe<Scalars['Float']>;
  durationMin?: Maybe<Scalars['Float']>;
  durationMax?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `LicenseSessionYearMonthAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionYearMonthAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `playersAvg` field. */
  playersAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `playersMin` field. */
  playersMin?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `playersMax` field. */
  playersMax?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationAvg` field. */
  durationAvg?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMin` field. */
  durationMin?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `durationMax` field. */
  durationMax?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `LicenseSessionYearMonthAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionYearMonthAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<FloatFilter>;
  /** Filter by the object’s `paidApprovedAmount` field. */
  paidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidApprovedAmount` field. */
  unpaidApprovedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidOriginalAmount` field. */
  paidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidOriginalAmount` field. */
  unpaidOriginalAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paidSubmittedAmount` field. */
  paidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unpaidSubmittedAmount` field. */
  unpaidSubmittedAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `unknownAmount` field. */
  unknownAmount?: Maybe<BigIntFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<BigIntFilter>;
  /** Filter by the object’s `playersAvg` field. */
  playersAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `playersMin` field. */
  playersMin?: Maybe<IntFilter>;
  /** Filter by the object’s `playersMax` field. */
  playersMax?: Maybe<IntFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationAvg` field. */
  durationAvg?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMin` field. */
  durationMin?: Maybe<FloatFilter>;
  /** Filter by the object’s `durationMax` field. */
  durationMax?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionYearMonthAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionYearMonthAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionYearMonthAggFilter>;
};

/** A connection to a list of `LicenseSessionYearMonthAgg` values. */
export type LicenseSessionYearMonthAggsConnection = {
  __typename?: 'LicenseSessionYearMonthAggsConnection';
  /** A list of `LicenseSessionYearMonthAgg` objects. */
  nodes: Array<Maybe<LicenseSessionYearMonthAgg>>;
  /** A list of edges which contains the `LicenseSessionYearMonthAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionYearMonthAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionYearMonthAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionYearMonthAgg` edge in the connection. */
export type LicenseSessionYearMonthAggsEdge = {
  __typename?: 'LicenseSessionYearMonthAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionYearMonthAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionYearMonthAgg>;
};

/** Methods to use when ordering `LicenseSessionYearMonthAgg`. */
export enum LicenseSessionYearMonthAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  PaidApprovedAmountAsc = 'PAID_APPROVED_AMOUNT_ASC',
  PaidApprovedAmountDesc = 'PAID_APPROVED_AMOUNT_DESC',
  UnpaidApprovedAmountAsc = 'UNPAID_APPROVED_AMOUNT_ASC',
  UnpaidApprovedAmountDesc = 'UNPAID_APPROVED_AMOUNT_DESC',
  PaidOriginalAmountAsc = 'PAID_ORIGINAL_AMOUNT_ASC',
  PaidOriginalAmountDesc = 'PAID_ORIGINAL_AMOUNT_DESC',
  UnpaidOriginalAmountAsc = 'UNPAID_ORIGINAL_AMOUNT_ASC',
  UnpaidOriginalAmountDesc = 'UNPAID_ORIGINAL_AMOUNT_DESC',
  PaidSubmittedAmountAsc = 'PAID_SUBMITTED_AMOUNT_ASC',
  PaidSubmittedAmountDesc = 'PAID_SUBMITTED_AMOUNT_DESC',
  UnpaidSubmittedAmountAsc = 'UNPAID_SUBMITTED_AMOUNT_ASC',
  UnpaidSubmittedAmountDesc = 'UNPAID_SUBMITTED_AMOUNT_DESC',
  UnknownAmountAsc = 'UNKNOWN_AMOUNT_ASC',
  UnknownAmountDesc = 'UNKNOWN_AMOUNT_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  PlayersAvgAsc = 'PLAYERS_AVG_ASC',
  PlayersAvgDesc = 'PLAYERS_AVG_DESC',
  PlayersMinAsc = 'PLAYERS_MIN_ASC',
  PlayersMinDesc = 'PLAYERS_MIN_DESC',
  PlayersMaxAsc = 'PLAYERS_MAX_ASC',
  PlayersMaxDesc = 'PLAYERS_MAX_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  DurationAvgAsc = 'DURATION_AVG_ASC',
  DurationAvgDesc = 'DURATION_AVG_DESC',
  DurationMinAsc = 'DURATION_MIN_ASC',
  DurationMinDesc = 'DURATION_MIN_DESC',
  DurationMaxAsc = 'DURATION_MAX_ASC',
  DurationMaxDesc = 'DURATION_MAX_DESC'
}

export type LicenseSessionYearMonthPriceAgg = {
  __typename?: 'LicenseSessionYearMonthPriceAgg';
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  currency: Scalars['CurrencyType'];
  price?: Maybe<Scalars['BigFloat']>;
  priceAvg?: Maybe<Scalars['BigFloat']>;
  priceMin?: Maybe<Scalars['BigFloat']>;
  priceMax?: Maybe<Scalars['BigFloat']>;
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  priceOriginalAvg?: Maybe<Scalars['BigFloat']>;
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  priceApproved?: Maybe<Scalars['BigFloat']>;
  priceApprovedAvg?: Maybe<Scalars['BigFloat']>;
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  priceDiscountAvg?: Maybe<Scalars['BigFloat']>;
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionYearMonthPriceAgg` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionYearMonthPriceAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceAvg` field. */
  priceAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMin` field. */
  priceMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMax` field. */
  priceMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalAvg` field. */
  priceOriginalAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedAvg` field. */
  priceApprovedAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountAvg` field. */
  priceDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountAvg` field. */
  priceApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `LicenseSessionYearMonthPriceAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionYearMonthPriceAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<FloatFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceAvg` field. */
  priceAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMin` field. */
  priceMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMax` field. */
  priceMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalAvg` field. */
  priceOriginalAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedAvg` field. */
  priceApprovedAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountAvg` field. */
  priceDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountAvg` field. */
  priceApprovedDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionYearMonthPriceAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionYearMonthPriceAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionYearMonthPriceAggFilter>;
};

/** A connection to a list of `LicenseSessionYearMonthPriceAgg` values. */
export type LicenseSessionYearMonthPriceAggsConnection = {
  __typename?: 'LicenseSessionYearMonthPriceAggsConnection';
  /** A list of `LicenseSessionYearMonthPriceAgg` objects. */
  nodes: Array<Maybe<LicenseSessionYearMonthPriceAgg>>;
  /** A list of edges which contains the `LicenseSessionYearMonthPriceAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionYearMonthPriceAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionYearMonthPriceAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionYearMonthPriceAgg` edge in the connection. */
export type LicenseSessionYearMonthPriceAggsEdge = {
  __typename?: 'LicenseSessionYearMonthPriceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionYearMonthPriceAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionYearMonthPriceAgg>;
};

/** Methods to use when ordering `LicenseSessionYearMonthPriceAgg`. */
export enum LicenseSessionYearMonthPriceAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceAvgAsc = 'PRICE_AVG_ASC',
  PriceAvgDesc = 'PRICE_AVG_DESC',
  PriceMinAsc = 'PRICE_MIN_ASC',
  PriceMinDesc = 'PRICE_MIN_DESC',
  PriceMaxAsc = 'PRICE_MAX_ASC',
  PriceMaxDesc = 'PRICE_MAX_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  PriceOriginalAvgAsc = 'PRICE_ORIGINAL_AVG_ASC',
  PriceOriginalAvgDesc = 'PRICE_ORIGINAL_AVG_DESC',
  PriceOriginalMinAsc = 'PRICE_ORIGINAL_MIN_ASC',
  PriceOriginalMinDesc = 'PRICE_ORIGINAL_MIN_DESC',
  PriceOriginalMaxAsc = 'PRICE_ORIGINAL_MAX_ASC',
  PriceOriginalMaxDesc = 'PRICE_ORIGINAL_MAX_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceApprovedAvgAsc = 'PRICE_APPROVED_AVG_ASC',
  PriceApprovedAvgDesc = 'PRICE_APPROVED_AVG_DESC',
  PriceApprovedMinAsc = 'PRICE_APPROVED_MIN_ASC',
  PriceApprovedMinDesc = 'PRICE_APPROVED_MIN_DESC',
  PriceApprovedMaxAsc = 'PRICE_APPROVED_MAX_ASC',
  PriceApprovedMaxDesc = 'PRICE_APPROVED_MAX_DESC',
  PriceDiscountAsc = 'PRICE_DISCOUNT_ASC',
  PriceDiscountDesc = 'PRICE_DISCOUNT_DESC',
  PriceDiscountAvgAsc = 'PRICE_DISCOUNT_AVG_ASC',
  PriceDiscountAvgDesc = 'PRICE_DISCOUNT_AVG_DESC',
  PriceDiscountMinAsc = 'PRICE_DISCOUNT_MIN_ASC',
  PriceDiscountMinDesc = 'PRICE_DISCOUNT_MIN_DESC',
  PriceDiscountMaxAsc = 'PRICE_DISCOUNT_MAX_ASC',
  PriceDiscountMaxDesc = 'PRICE_DISCOUNT_MAX_DESC',
  PriceApprovedDiscountAsc = 'PRICE_APPROVED_DISCOUNT_ASC',
  PriceApprovedDiscountDesc = 'PRICE_APPROVED_DISCOUNT_DESC',
  PriceApprovedDiscountAvgAsc = 'PRICE_APPROVED_DISCOUNT_AVG_ASC',
  PriceApprovedDiscountAvgDesc = 'PRICE_APPROVED_DISCOUNT_AVG_DESC',
  PriceApprovedDiscountMinAsc = 'PRICE_APPROVED_DISCOUNT_MIN_ASC',
  PriceApprovedDiscountMinDesc = 'PRICE_APPROVED_DISCOUNT_MIN_DESC',
  PriceApprovedDiscountMaxAsc = 'PRICE_APPROVED_DISCOUNT_MAX_ASC',
  PriceApprovedDiscountMaxDesc = 'PRICE_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionYearMonthRoyaltyAgg = {
  __typename?: 'LicenseSessionYearMonthRoyaltyAgg';
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  currency: Scalars['CurrencyType'];
  royalty?: Maybe<Scalars['BigFloat']>;
  royaltyAvg?: Maybe<Scalars['BigFloat']>;
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalAvg?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedAvg?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountAvg?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionYearMonthRoyaltyAgg` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionYearMonthRoyaltyAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyAvg` field. */
  royaltyAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalAvg` field. */
  royaltyOriginalAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedAvg` field. */
  royaltyApprovedAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountAvg` field. */
  royaltyDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountAvg` field. */
  royaltyApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `LicenseSessionYearMonthRoyaltyAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionYearMonthRoyaltyAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<FloatFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyAvg` field. */
  royaltyAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalAvg` field. */
  royaltyOriginalAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedAvg` field. */
  royaltyApprovedAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountAvg` field. */
  royaltyDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountAvg` field. */
  royaltyApprovedDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionYearMonthRoyaltyAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionYearMonthRoyaltyAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionYearMonthRoyaltyAggFilter>;
};

/** A connection to a list of `LicenseSessionYearMonthRoyaltyAgg` values. */
export type LicenseSessionYearMonthRoyaltyAggsConnection = {
  __typename?: 'LicenseSessionYearMonthRoyaltyAggsConnection';
  /** A list of `LicenseSessionYearMonthRoyaltyAgg` objects. */
  nodes: Array<Maybe<LicenseSessionYearMonthRoyaltyAgg>>;
  /** A list of edges which contains the `LicenseSessionYearMonthRoyaltyAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionYearMonthRoyaltyAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionYearMonthRoyaltyAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionYearMonthRoyaltyAgg` edge in the connection. */
export type LicenseSessionYearMonthRoyaltyAggsEdge = {
  __typename?: 'LicenseSessionYearMonthRoyaltyAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionYearMonthRoyaltyAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionYearMonthRoyaltyAgg>;
};

/** Methods to use when ordering `LicenseSessionYearMonthRoyaltyAgg`. */
export enum LicenseSessionYearMonthRoyaltyAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyAvgAsc = 'ROYALTY_AVG_ASC',
  RoyaltyAvgDesc = 'ROYALTY_AVG_DESC',
  RoyaltyMinAsc = 'ROYALTY_MIN_ASC',
  RoyaltyMinDesc = 'ROYALTY_MIN_DESC',
  RoyaltyMaxAsc = 'ROYALTY_MAX_ASC',
  RoyaltyMaxDesc = 'ROYALTY_MAX_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  RoyaltyOriginalAvgAsc = 'ROYALTY_ORIGINAL_AVG_ASC',
  RoyaltyOriginalAvgDesc = 'ROYALTY_ORIGINAL_AVG_DESC',
  RoyaltyOriginalMinAsc = 'ROYALTY_ORIGINAL_MIN_ASC',
  RoyaltyOriginalMinDesc = 'ROYALTY_ORIGINAL_MIN_DESC',
  RoyaltyOriginalMaxAsc = 'ROYALTY_ORIGINAL_MAX_ASC',
  RoyaltyOriginalMaxDesc = 'ROYALTY_ORIGINAL_MAX_DESC',
  RoyaltyApprovedAsc = 'ROYALTY_APPROVED_ASC',
  RoyaltyApprovedDesc = 'ROYALTY_APPROVED_DESC',
  RoyaltyApprovedAvgAsc = 'ROYALTY_APPROVED_AVG_ASC',
  RoyaltyApprovedAvgDesc = 'ROYALTY_APPROVED_AVG_DESC',
  RoyaltyApprovedMinAsc = 'ROYALTY_APPROVED_MIN_ASC',
  RoyaltyApprovedMinDesc = 'ROYALTY_APPROVED_MIN_DESC',
  RoyaltyApprovedMaxAsc = 'ROYALTY_APPROVED_MAX_ASC',
  RoyaltyApprovedMaxDesc = 'ROYALTY_APPROVED_MAX_DESC',
  RoyaltyDiscountAsc = 'ROYALTY_DISCOUNT_ASC',
  RoyaltyDiscountDesc = 'ROYALTY_DISCOUNT_DESC',
  RoyaltyDiscountAvgAsc = 'ROYALTY_DISCOUNT_AVG_ASC',
  RoyaltyDiscountAvgDesc = 'ROYALTY_DISCOUNT_AVG_DESC',
  RoyaltyDiscountMinAsc = 'ROYALTY_DISCOUNT_MIN_ASC',
  RoyaltyDiscountMinDesc = 'ROYALTY_DISCOUNT_MIN_DESC',
  RoyaltyDiscountMaxAsc = 'ROYALTY_DISCOUNT_MAX_ASC',
  RoyaltyDiscountMaxDesc = 'ROYALTY_DISCOUNT_MAX_DESC',
  RoyaltyApprovedDiscountAsc = 'ROYALTY_APPROVED_DISCOUNT_ASC',
  RoyaltyApprovedDiscountDesc = 'ROYALTY_APPROVED_DISCOUNT_DESC',
  RoyaltyApprovedDiscountAvgAsc = 'ROYALTY_APPROVED_DISCOUNT_AVG_ASC',
  RoyaltyApprovedDiscountAvgDesc = 'ROYALTY_APPROVED_DISCOUNT_AVG_DESC',
  RoyaltyApprovedDiscountMinAsc = 'ROYALTY_APPROVED_DISCOUNT_MIN_ASC',
  RoyaltyApprovedDiscountMinDesc = 'ROYALTY_APPROVED_DISCOUNT_MIN_DESC',
  RoyaltyApprovedDiscountMaxAsc = 'ROYALTY_APPROVED_DISCOUNT_MAX_ASC',
  RoyaltyApprovedDiscountMaxDesc = 'ROYALTY_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionYearPriceAgg = {
  __typename?: 'LicenseSessionYearPriceAgg';
  year?: Maybe<Scalars['Float']>;
  currency: Scalars['CurrencyType'];
  price?: Maybe<Scalars['BigFloat']>;
  priceAvg?: Maybe<Scalars['BigFloat']>;
  priceMin?: Maybe<Scalars['BigFloat']>;
  priceMax?: Maybe<Scalars['BigFloat']>;
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  priceOriginalAvg?: Maybe<Scalars['BigFloat']>;
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  priceApproved?: Maybe<Scalars['BigFloat']>;
  priceApprovedAvg?: Maybe<Scalars['BigFloat']>;
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  priceDiscountAvg?: Maybe<Scalars['BigFloat']>;
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionYearPriceAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionYearPriceAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceAvg` field. */
  priceAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMin` field. */
  priceMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceMax` field. */
  priceMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalAvg` field. */
  priceOriginalAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApproved` field. */
  priceApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedAvg` field. */
  priceApprovedAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountAvg` field. */
  priceDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountAvg` field. */
  priceApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `LicenseSessionYearPriceAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionYearPriceAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceAvg` field. */
  priceAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMin` field. */
  priceMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceMax` field. */
  priceMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginal` field. */
  priceOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalAvg` field. */
  priceOriginalAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMin` field. */
  priceOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceOriginalMax` field. */
  priceOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApproved` field. */
  priceApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedAvg` field. */
  priceApprovedAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMin` field. */
  priceApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedMax` field. */
  priceApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscount` field. */
  priceDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountAvg` field. */
  priceDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMin` field. */
  priceDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceDiscountMax` field. */
  priceDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscount` field. */
  priceApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountAvg` field. */
  priceApprovedDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMin` field. */
  priceApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `priceApprovedDiscountMax` field. */
  priceApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionYearPriceAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionYearPriceAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionYearPriceAggFilter>;
};

/** A connection to a list of `LicenseSessionYearPriceAgg` values. */
export type LicenseSessionYearPriceAggsConnection = {
  __typename?: 'LicenseSessionYearPriceAggsConnection';
  /** A list of `LicenseSessionYearPriceAgg` objects. */
  nodes: Array<Maybe<LicenseSessionYearPriceAgg>>;
  /** A list of edges which contains the `LicenseSessionYearPriceAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionYearPriceAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionYearPriceAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionYearPriceAgg` edge in the connection. */
export type LicenseSessionYearPriceAggsEdge = {
  __typename?: 'LicenseSessionYearPriceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionYearPriceAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionYearPriceAgg>;
};

/** Methods to use when ordering `LicenseSessionYearPriceAgg`. */
export enum LicenseSessionYearPriceAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceAvgAsc = 'PRICE_AVG_ASC',
  PriceAvgDesc = 'PRICE_AVG_DESC',
  PriceMinAsc = 'PRICE_MIN_ASC',
  PriceMinDesc = 'PRICE_MIN_DESC',
  PriceMaxAsc = 'PRICE_MAX_ASC',
  PriceMaxDesc = 'PRICE_MAX_DESC',
  PriceOriginalAsc = 'PRICE_ORIGINAL_ASC',
  PriceOriginalDesc = 'PRICE_ORIGINAL_DESC',
  PriceOriginalAvgAsc = 'PRICE_ORIGINAL_AVG_ASC',
  PriceOriginalAvgDesc = 'PRICE_ORIGINAL_AVG_DESC',
  PriceOriginalMinAsc = 'PRICE_ORIGINAL_MIN_ASC',
  PriceOriginalMinDesc = 'PRICE_ORIGINAL_MIN_DESC',
  PriceOriginalMaxAsc = 'PRICE_ORIGINAL_MAX_ASC',
  PriceOriginalMaxDesc = 'PRICE_ORIGINAL_MAX_DESC',
  PriceApprovedAsc = 'PRICE_APPROVED_ASC',
  PriceApprovedDesc = 'PRICE_APPROVED_DESC',
  PriceApprovedAvgAsc = 'PRICE_APPROVED_AVG_ASC',
  PriceApprovedAvgDesc = 'PRICE_APPROVED_AVG_DESC',
  PriceApprovedMinAsc = 'PRICE_APPROVED_MIN_ASC',
  PriceApprovedMinDesc = 'PRICE_APPROVED_MIN_DESC',
  PriceApprovedMaxAsc = 'PRICE_APPROVED_MAX_ASC',
  PriceApprovedMaxDesc = 'PRICE_APPROVED_MAX_DESC',
  PriceDiscountAsc = 'PRICE_DISCOUNT_ASC',
  PriceDiscountDesc = 'PRICE_DISCOUNT_DESC',
  PriceDiscountAvgAsc = 'PRICE_DISCOUNT_AVG_ASC',
  PriceDiscountAvgDesc = 'PRICE_DISCOUNT_AVG_DESC',
  PriceDiscountMinAsc = 'PRICE_DISCOUNT_MIN_ASC',
  PriceDiscountMinDesc = 'PRICE_DISCOUNT_MIN_DESC',
  PriceDiscountMaxAsc = 'PRICE_DISCOUNT_MAX_ASC',
  PriceDiscountMaxDesc = 'PRICE_DISCOUNT_MAX_DESC',
  PriceApprovedDiscountAsc = 'PRICE_APPROVED_DISCOUNT_ASC',
  PriceApprovedDiscountDesc = 'PRICE_APPROVED_DISCOUNT_DESC',
  PriceApprovedDiscountAvgAsc = 'PRICE_APPROVED_DISCOUNT_AVG_ASC',
  PriceApprovedDiscountAvgDesc = 'PRICE_APPROVED_DISCOUNT_AVG_DESC',
  PriceApprovedDiscountMinAsc = 'PRICE_APPROVED_DISCOUNT_MIN_ASC',
  PriceApprovedDiscountMinDesc = 'PRICE_APPROVED_DISCOUNT_MIN_DESC',
  PriceApprovedDiscountMaxAsc = 'PRICE_APPROVED_DISCOUNT_MAX_ASC',
  PriceApprovedDiscountMaxDesc = 'PRICE_APPROVED_DISCOUNT_MAX_DESC'
}

export type LicenseSessionYearRoyaltyAgg = {
  __typename?: 'LicenseSessionYearRoyaltyAgg';
  year?: Maybe<Scalars['Float']>;
  currency: Scalars['CurrencyType'];
  royalty?: Maybe<Scalars['BigFloat']>;
  royaltyAvg?: Maybe<Scalars['BigFloat']>;
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalAvg?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedAvg?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountAvg?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LicenseSessionYearRoyaltyAgg` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LicenseSessionYearRoyaltyAggCondition = {
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: Maybe<Scalars['CurrencyType']>;
  /** Checks for equality with the object’s `royalty` field. */
  royalty?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyAvg` field. */
  royaltyAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalAvg` field. */
  royaltyOriginalAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedAvg` field. */
  royaltyApprovedAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountAvg` field. */
  royaltyDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountAvg` field. */
  royaltyApprovedDiscountAvg?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `LicenseSessionYearRoyaltyAgg` object types. All fields are combined with a logical ‘and.’ */
export type LicenseSessionYearRoyaltyAggFilter = {
  /** Filter by the object’s `year` field. */
  year?: Maybe<FloatFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyAvg` field. */
  royaltyAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMin` field. */
  royaltyMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyMax` field. */
  royaltyMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginal` field. */
  royaltyOriginal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalAvg` field. */
  royaltyOriginalAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMin` field. */
  royaltyOriginalMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyOriginalMax` field. */
  royaltyOriginalMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApproved` field. */
  royaltyApproved?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedAvg` field. */
  royaltyApprovedAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMin` field. */
  royaltyApprovedMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedMax` field. */
  royaltyApprovedMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscount` field. */
  royaltyDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountAvg` field. */
  royaltyDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMin` field. */
  royaltyDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyDiscountMax` field. */
  royaltyDiscountMax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscount` field. */
  royaltyApprovedDiscount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountAvg` field. */
  royaltyApprovedDiscountAvg?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMin` field. */
  royaltyApprovedDiscountMin?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `royaltyApprovedDiscountMax` field. */
  royaltyApprovedDiscountMax?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseSessionYearRoyaltyAggFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseSessionYearRoyaltyAggFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseSessionYearRoyaltyAggFilter>;
};

/** A connection to a list of `LicenseSessionYearRoyaltyAgg` values. */
export type LicenseSessionYearRoyaltyAggsConnection = {
  __typename?: 'LicenseSessionYearRoyaltyAggsConnection';
  /** A list of `LicenseSessionYearRoyaltyAgg` objects. */
  nodes: Array<Maybe<LicenseSessionYearRoyaltyAgg>>;
  /** A list of edges which contains the `LicenseSessionYearRoyaltyAgg` and cursor to aid in pagination. */
  edges: Array<LicenseSessionYearRoyaltyAggsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseSessionYearRoyaltyAgg` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseSessionYearRoyaltyAgg` edge in the connection. */
export type LicenseSessionYearRoyaltyAggsEdge = {
  __typename?: 'LicenseSessionYearRoyaltyAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseSessionYearRoyaltyAgg` at the end of the edge. */
  node?: Maybe<LicenseSessionYearRoyaltyAgg>;
};

/** Methods to use when ordering `LicenseSessionYearRoyaltyAgg`. */
export enum LicenseSessionYearRoyaltyAggsOrderBy {
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  RoyaltyAvgAsc = 'ROYALTY_AVG_ASC',
  RoyaltyAvgDesc = 'ROYALTY_AVG_DESC',
  RoyaltyMinAsc = 'ROYALTY_MIN_ASC',
  RoyaltyMinDesc = 'ROYALTY_MIN_DESC',
  RoyaltyMaxAsc = 'ROYALTY_MAX_ASC',
  RoyaltyMaxDesc = 'ROYALTY_MAX_DESC',
  RoyaltyOriginalAsc = 'ROYALTY_ORIGINAL_ASC',
  RoyaltyOriginalDesc = 'ROYALTY_ORIGINAL_DESC',
  RoyaltyOriginalAvgAsc = 'ROYALTY_ORIGINAL_AVG_ASC',
  RoyaltyOriginalAvgDesc = 'ROYALTY_ORIGINAL_AVG_DESC',
  RoyaltyOriginalMinAsc = 'ROYALTY_ORIGINAL_MIN_ASC',
  RoyaltyOriginalMinDesc = 'ROYALTY_ORIGINAL_MIN_DESC',
  RoyaltyOriginalMaxAsc = 'ROYALTY_ORIGINAL_MAX_ASC',
  RoyaltyOriginalMaxDesc = 'ROYALTY_ORIGINAL_MAX_DESC',
  RoyaltyApprovedAsc = 'ROYALTY_APPROVED_ASC',
  RoyaltyApprovedDesc = 'ROYALTY_APPROVED_DESC',
  RoyaltyApprovedAvgAsc = 'ROYALTY_APPROVED_AVG_ASC',
  RoyaltyApprovedAvgDesc = 'ROYALTY_APPROVED_AVG_DESC',
  RoyaltyApprovedMinAsc = 'ROYALTY_APPROVED_MIN_ASC',
  RoyaltyApprovedMinDesc = 'ROYALTY_APPROVED_MIN_DESC',
  RoyaltyApprovedMaxAsc = 'ROYALTY_APPROVED_MAX_ASC',
  RoyaltyApprovedMaxDesc = 'ROYALTY_APPROVED_MAX_DESC',
  RoyaltyDiscountAsc = 'ROYALTY_DISCOUNT_ASC',
  RoyaltyDiscountDesc = 'ROYALTY_DISCOUNT_DESC',
  RoyaltyDiscountAvgAsc = 'ROYALTY_DISCOUNT_AVG_ASC',
  RoyaltyDiscountAvgDesc = 'ROYALTY_DISCOUNT_AVG_DESC',
  RoyaltyDiscountMinAsc = 'ROYALTY_DISCOUNT_MIN_ASC',
  RoyaltyDiscountMinDesc = 'ROYALTY_DISCOUNT_MIN_DESC',
  RoyaltyDiscountMaxAsc = 'ROYALTY_DISCOUNT_MAX_ASC',
  RoyaltyDiscountMaxDesc = 'ROYALTY_DISCOUNT_MAX_DESC',
  RoyaltyApprovedDiscountAsc = 'ROYALTY_APPROVED_DISCOUNT_ASC',
  RoyaltyApprovedDiscountDesc = 'ROYALTY_APPROVED_DISCOUNT_DESC',
  RoyaltyApprovedDiscountAvgAsc = 'ROYALTY_APPROVED_DISCOUNT_AVG_ASC',
  RoyaltyApprovedDiscountAvgDesc = 'ROYALTY_APPROVED_DISCOUNT_AVG_DESC',
  RoyaltyApprovedDiscountMinAsc = 'ROYALTY_APPROVED_DISCOUNT_MIN_ASC',
  RoyaltyApprovedDiscountMinDesc = 'ROYALTY_APPROVED_DISCOUNT_MIN_DESC',
  RoyaltyApprovedDiscountMaxAsc = 'ROYALTY_APPROVED_DISCOUNT_MAX_ASC',
  RoyaltyApprovedDiscountMaxDesc = 'ROYALTY_APPROVED_DISCOUNT_MAX_DESC'
}

/** Methods to use when ordering `License`. */
export enum LicensesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  IsRevokedAsc = 'IS_REVOKED_ASC',
  IsRevokedDesc = 'IS_REVOKED_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceCalendarAsc = 'PRICE_CALENDAR_ASC',
  PriceCalendarDesc = 'PRICE_CALENDAR_DESC',
  RoyaltyAsc = 'ROYALTY_ASC',
  RoyaltyDesc = 'ROYALTY_DESC',
  LicenseKeyAsc = 'LICENSE_KEY_ASC',
  LicenseKeyDesc = 'LICENSE_KEY_DESC',
  ExpirateAtUtcAsc = 'EXPIRATE_AT_UTC_ASC',
  ExpirateAtUtcDesc = 'EXPIRATE_AT_UTC_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LicenseEvent` object types. All fields are combined with a logical ‘and.’ */
export type LicenseToManyLicenseEventFilter = {
  /** Every related `LicenseEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseEventFilter>;
  /** Some related `LicenseEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseEventFilter>;
  /** No related `LicenseEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseEventFilter>;
};

/** A filter to be used against many `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type LicenseToManyLicenseSessionFilter = {
  /** Every related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionFilter>;
  /** Some related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionFilter>;
  /** No related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionFilter>;
};

/** A license v2 for the venue. */
export type LicenseV2 = Node & {
  __typename?: 'LicenseV2';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  venueId: Scalars['UUID'];
  licenseKey: Scalars['LicenseKeyType'];
  isRevoked: Scalars['Boolean'];
  claims: Scalars['JSON'];
  expirateAtUtc?: Maybe<Scalars['Datetime']>;
  tokenLifeTimeSec?: Maybe<Scalars['BigInt']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Venue` that is related to this `LicenseV2`. */
  venueByVenueId?: Maybe<Venue>;
  /** Reads and enables pagination through a set of `LicenseSession`. */
  licenseSessionsByLicenseV2Id: LicenseSessionsConnection;
  /** Reads and enables pagination through a set of `LicenseV2Event`. */
  licenseV2EventsByLicenseV2Id: LicenseV2EventsConnection;
};


/** A license v2 for the venue. */
export type LicenseV2LicenseSessionsByLicenseV2IdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** A license v2 for the venue. */
export type LicenseV2LicenseV2EventsByLicenseV2IdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
  condition?: Maybe<LicenseV2EventCondition>;
  filter?: Maybe<LicenseV2EventFilter>;
};

/**
 * A condition to be used against `LicenseV2` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LicenseV2Condition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseKey` field. */
  licenseKey?: Maybe<Scalars['LicenseKeyType']>;
  /** Checks for equality with the object’s `isRevoked` field. */
  isRevoked?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `claims` field. */
  claims?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `expirateAtUtc` field. */
  expirateAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `tokenLifeTimeSec` field. */
  tokenLifeTimeSec?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

export type LicenseV2Event = Node & {
  __typename?: 'LicenseV2Event';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  licenseV2Id?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  dataRaw?: Maybe<Scalars['String']>;
  licenseKey?: Maybe<Scalars['String']>;
  eventTimeUtcRegistered?: Maybe<Scalars['Datetime']>;
  eventTimeUtcRaw?: Maybe<Scalars['Datetime']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  productVersion?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationCountryCode?: Maybe<Scalars['String']>;
  locationCountryName?: Maybe<Scalars['String']>;
  locationRegionName?: Maybe<Scalars['String']>;
  locationCityName?: Maybe<Scalars['String']>;
  locationCoords?: Maybe<Scalars['String']>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  productState?: Maybe<Scalars['JSON']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  revenue?: Maybe<Scalars['BigFloat']>;
  machineid?: Maybe<Scalars['String']>;
  /** Reads a single `LicenseV2` that is related to this `LicenseV2Event`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseV2Event`. */
  productByProductId?: Maybe<Product>;
};

/**
 * A condition to be used against `LicenseV2Event` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LicenseV2EventCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `licenseV2Id` field. */
  licenseV2Id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `dataRaw` field. */
  dataRaw?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `licenseKey` field. */
  licenseKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventTimeUtcRegistered` field. */
  eventTimeUtcRegistered?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `eventTimeUtcRaw` field. */
  eventTimeUtcRaw?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `reportedSessionId` field. */
  reportedSessionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reportedMasterSessionId` field. */
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `osVersion` field. */
  osVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productVersion` field. */
  productVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ip` field. */
  ip?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationCountryCode` field. */
  locationCountryCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationCountryName` field. */
  locationCountryName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationRegionName` field. */
  locationRegionName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationCityName` field. */
  locationCityName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationCoords` field. */
  locationCoords?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `players` field. */
  players?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPaid` field. */
  isPaid?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `comment` field. */
  comment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productState` field. */
  productState?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `machineid` field. */
  machineid?: Maybe<Scalars['String']>;
};

/** A filter to be used against `LicenseV2Event` object types. All fields are combined with a logical ‘and.’ */
export type LicenseV2EventFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseV2Id` field. */
  licenseV2Id?: Maybe<UuidFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<UuidFilter>;
  /** Filter by the object’s `dataRaw` field. */
  dataRaw?: Maybe<StringFilter>;
  /** Filter by the object’s `licenseKey` field. */
  licenseKey?: Maybe<StringFilter>;
  /** Filter by the object’s `eventTimeUtcRegistered` field. */
  eventTimeUtcRegistered?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `eventTimeUtcRaw` field. */
  eventTimeUtcRaw?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `reportedSessionId` field. */
  reportedSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `reportedMasterSessionId` field. */
  reportedMasterSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `osVersion` field. */
  osVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `productVersion` field. */
  productVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `ip` field. */
  ip?: Maybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<StringFilter>;
  /** Filter by the object’s `locationCountryCode` field. */
  locationCountryCode?: Maybe<StringFilter>;
  /** Filter by the object’s `locationCountryName` field. */
  locationCountryName?: Maybe<StringFilter>;
  /** Filter by the object’s `locationRegionName` field. */
  locationRegionName?: Maybe<StringFilter>;
  /** Filter by the object’s `locationCityName` field. */
  locationCityName?: Maybe<StringFilter>;
  /** Filter by the object’s `locationCoords` field. */
  locationCoords?: Maybe<StringFilter>;
  /** Filter by the object’s `players` field. */
  players?: Maybe<IntFilter>;
  /** Filter by the object’s `isPaid` field. */
  isPaid?: Maybe<BooleanFilter>;
  /** Filter by the object’s `comment` field. */
  comment?: Maybe<StringFilter>;
  /** Filter by the object’s `productState` field. */
  productState?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `machineid` field. */
  machineid?: Maybe<StringFilter>;
  /** Filter by the object’s `licenseV2ByLicenseV2Id` relation. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2Filter>;
  /** A related `licenseV2ByLicenseV2Id` exists. */
  licenseV2ByLicenseV2IdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `productByProductId` relation. */
  productByProductId?: Maybe<ProductFilter>;
  /** A related `productByProductId` exists. */
  productByProductIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseV2EventFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseV2EventFilter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseV2EventFilter>;
};

/** An input for mutations affecting `LicenseV2Event` */
export type LicenseV2EventInput = {
  id?: Maybe<Scalars['UUID']>;
  licenseV2Id?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  dataRaw?: Maybe<Scalars['String']>;
  licenseKey?: Maybe<Scalars['String']>;
  eventTimeUtcRegistered?: Maybe<Scalars['Datetime']>;
  eventTimeUtcRaw?: Maybe<Scalars['Datetime']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  productVersion?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationCountryCode?: Maybe<Scalars['String']>;
  locationCountryName?: Maybe<Scalars['String']>;
  locationRegionName?: Maybe<Scalars['String']>;
  locationCityName?: Maybe<Scalars['String']>;
  locationCoords?: Maybe<Scalars['String']>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  productState?: Maybe<Scalars['JSON']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  revenue?: Maybe<Scalars['BigFloat']>;
  machineid?: Maybe<Scalars['String']>;
};

/** Represents an update to a `LicenseV2Event`. Fields that are set will be updated. */
export type LicenseV2EventPatch = {
  id?: Maybe<Scalars['UUID']>;
  licenseV2Id?: Maybe<Scalars['UUID']>;
  productId?: Maybe<Scalars['UUID']>;
  dataRaw?: Maybe<Scalars['String']>;
  licenseKey?: Maybe<Scalars['String']>;
  eventTimeUtcRegistered?: Maybe<Scalars['Datetime']>;
  eventTimeUtcRaw?: Maybe<Scalars['Datetime']>;
  reportedSessionId?: Maybe<Scalars['String']>;
  reportedMasterSessionId?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  productVersion?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationCountryCode?: Maybe<Scalars['String']>;
  locationCountryName?: Maybe<Scalars['String']>;
  locationRegionName?: Maybe<Scalars['String']>;
  locationCityName?: Maybe<Scalars['String']>;
  locationCoords?: Maybe<Scalars['String']>;
  players?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  productState?: Maybe<Scalars['JSON']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  revenue?: Maybe<Scalars['BigFloat']>;
  machineid?: Maybe<Scalars['String']>;
};

/** A connection to a list of `LicenseV2Event` values. */
export type LicenseV2EventsConnection = {
  __typename?: 'LicenseV2EventsConnection';
  /** A list of `LicenseV2Event` objects. */
  nodes: Array<Maybe<LicenseV2Event>>;
  /** A list of edges which contains the `LicenseV2Event` and cursor to aid in pagination. */
  edges: Array<LicenseV2EventsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseV2Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseV2Event` edge in the connection. */
export type LicenseV2EventsEdge = {
  __typename?: 'LicenseV2EventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseV2Event` at the end of the edge. */
  node?: Maybe<LicenseV2Event>;
};

/** Methods to use when ordering `LicenseV2Event`. */
export enum LicenseV2EventsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseV2IdAsc = 'LICENSE_V2_ID_ASC',
  LicenseV2IdDesc = 'LICENSE_V2_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  DataRawAsc = 'DATA_RAW_ASC',
  DataRawDesc = 'DATA_RAW_DESC',
  LicenseKeyAsc = 'LICENSE_KEY_ASC',
  LicenseKeyDesc = 'LICENSE_KEY_DESC',
  EventTimeUtcRegisteredAsc = 'EVENT_TIME_UTC_REGISTERED_ASC',
  EventTimeUtcRegisteredDesc = 'EVENT_TIME_UTC_REGISTERED_DESC',
  EventTimeUtcRawAsc = 'EVENT_TIME_UTC_RAW_ASC',
  EventTimeUtcRawDesc = 'EVENT_TIME_UTC_RAW_DESC',
  ReportedSessionIdAsc = 'REPORTED_SESSION_ID_ASC',
  ReportedSessionIdDesc = 'REPORTED_SESSION_ID_DESC',
  ReportedMasterSessionIdAsc = 'REPORTED_MASTER_SESSION_ID_ASC',
  ReportedMasterSessionIdDesc = 'REPORTED_MASTER_SESSION_ID_DESC',
  OsVersionAsc = 'OS_VERSION_ASC',
  OsVersionDesc = 'OS_VERSION_DESC',
  ProductVersionAsc = 'PRODUCT_VERSION_ASC',
  ProductVersionDesc = 'PRODUCT_VERSION_DESC',
  IpAsc = 'IP_ASC',
  IpDesc = 'IP_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  LocationCountryCodeAsc = 'LOCATION_COUNTRY_CODE_ASC',
  LocationCountryCodeDesc = 'LOCATION_COUNTRY_CODE_DESC',
  LocationCountryNameAsc = 'LOCATION_COUNTRY_NAME_ASC',
  LocationCountryNameDesc = 'LOCATION_COUNTRY_NAME_DESC',
  LocationRegionNameAsc = 'LOCATION_REGION_NAME_ASC',
  LocationRegionNameDesc = 'LOCATION_REGION_NAME_DESC',
  LocationCityNameAsc = 'LOCATION_CITY_NAME_ASC',
  LocationCityNameDesc = 'LOCATION_CITY_NAME_DESC',
  LocationCoordsAsc = 'LOCATION_COORDS_ASC',
  LocationCoordsDesc = 'LOCATION_COORDS_DESC',
  PlayersAsc = 'PLAYERS_ASC',
  PlayersDesc = 'PLAYERS_DESC',
  IsPaidAsc = 'IS_PAID_ASC',
  IsPaidDesc = 'IS_PAID_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  ProductStateAsc = 'PRODUCT_STATE_ASC',
  ProductStateDesc = 'PRODUCT_STATE_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  RevenueAsc = 'REVENUE_ASC',
  RevenueDesc = 'REVENUE_DESC',
  MachineidAsc = 'MACHINEID_ASC',
  MachineidDesc = 'MACHINEID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `LicenseV2` object types. All fields are combined with a logical ‘and.’ */
export type LicenseV2Filter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `licenseKey` field. */
  licenseKey?: Maybe<LicenseKeyTypeFilter>;
  /** Filter by the object’s `isRevoked` field. */
  isRevoked?: Maybe<BooleanFilter>;
  /** Filter by the object’s `claims` field. */
  claims?: Maybe<JsonFilter>;
  /** Filter by the object’s `expirateAtUtc` field. */
  expirateAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `tokenLifeTimeSec` field. */
  tokenLifeTimeSec?: Maybe<BigIntFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `licenseSessionsByLicenseV2Id` relation. */
  licenseSessionsByLicenseV2Id?: Maybe<LicenseV2ToManyLicenseSessionFilter>;
  /** Some related `licenseSessionsByLicenseV2Id` exist. */
  licenseSessionsByLicenseV2IdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseV2EventsByLicenseV2Id` relation. */
  licenseV2EventsByLicenseV2Id?: Maybe<LicenseV2ToManyLicenseV2EventFilter>;
  /** Some related `licenseV2EventsByLicenseV2Id` exist. */
  licenseV2EventsByLicenseV2IdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `venueByVenueId` relation. */
  venueByVenueId?: Maybe<VenueFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LicenseV2Filter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LicenseV2Filter>>;
  /** Negates the expression. */
  not?: Maybe<LicenseV2Filter>;
};

/** An input for mutations affecting `LicenseV2` */
export type LicenseV2Input = {
  id?: Maybe<Scalars['UUID']>;
  venueId: Scalars['UUID'];
  licenseKey?: Maybe<Scalars['LicenseKeyType']>;
  isRevoked?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<Scalars['JSON']>;
  expirateAtUtc?: Maybe<Scalars['Datetime']>;
  tokenLifeTimeSec?: Maybe<Scalars['BigInt']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `LicenseV2`. Fields that are set will be updated. */
export type LicenseV2Patch = {
  id?: Maybe<Scalars['UUID']>;
  venueId?: Maybe<Scalars['UUID']>;
  licenseKey?: Maybe<Scalars['LicenseKeyType']>;
  isRevoked?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<Scalars['JSON']>;
  expirateAtUtc?: Maybe<Scalars['Datetime']>;
  tokenLifeTimeSec?: Maybe<Scalars['BigInt']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `LicenseV2` values. */
export type LicenseV2SConnection = {
  __typename?: 'LicenseV2SConnection';
  /** A list of `LicenseV2` objects. */
  nodes: Array<Maybe<LicenseV2>>;
  /** A list of edges which contains the `LicenseV2` and cursor to aid in pagination. */
  edges: Array<LicenseV2SEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LicenseV2` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LicenseV2` edge in the connection. */
export type LicenseV2SEdge = {
  __typename?: 'LicenseV2SEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LicenseV2` at the end of the edge. */
  node?: Maybe<LicenseV2>;
};

/** Methods to use when ordering `LicenseV2`. */
export enum LicenseV2SOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  LicenseKeyAsc = 'LICENSE_KEY_ASC',
  LicenseKeyDesc = 'LICENSE_KEY_DESC',
  IsRevokedAsc = 'IS_REVOKED_ASC',
  IsRevokedDesc = 'IS_REVOKED_DESC',
  ClaimsAsc = 'CLAIMS_ASC',
  ClaimsDesc = 'CLAIMS_DESC',
  ExpirateAtUtcAsc = 'EXPIRATE_AT_UTC_ASC',
  ExpirateAtUtcDesc = 'EXPIRATE_AT_UTC_DESC',
  TokenLifeTimeSecAsc = 'TOKEN_LIFE_TIME_SEC_ASC',
  TokenLifeTimeSecDesc = 'TOKEN_LIFE_TIME_SEC_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type LicenseV2ToManyLicenseSessionFilter = {
  /** Every related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionFilter>;
  /** Some related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionFilter>;
  /** No related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionFilter>;
};

/** A filter to be used against many `LicenseV2Event` object types. All fields are combined with a logical ‘and.’ */
export type LicenseV2ToManyLicenseV2EventFilter = {
  /** Every related `LicenseV2Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseV2EventFilter>;
  /** Some related `LicenseV2Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseV2EventFilter>;
  /** No related `LicenseV2Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseV2EventFilter>;
};

/** Record for each day when license sessions were loaded. */
export type LoadedLicenseSession = Node & {
  __typename?: 'LoadedLicenseSession';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `LoadedLicenseSession` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LoadedLicenseSessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `month` field. */
  month?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `day` field. */
  day?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `LoadedLicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type LoadedLicenseSessionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `month` field. */
  month?: Maybe<IntFilter>;
  /** Filter by the object’s `day` field. */
  day?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LoadedLicenseSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LoadedLicenseSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<LoadedLicenseSessionFilter>;
};

/** An input for mutations affecting `LoadedLicenseSession` */
export type LoadedLicenseSessionInput = {
  id?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `LoadedLicenseSession`. Fields that are set will be updated. */
export type LoadedLicenseSessionPatch = {
  id?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `LoadedLicenseSession` values. */
export type LoadedLicenseSessionsConnection = {
  __typename?: 'LoadedLicenseSessionsConnection';
  /** A list of `LoadedLicenseSession` objects. */
  nodes: Array<Maybe<LoadedLicenseSession>>;
  /** A list of edges which contains the `LoadedLicenseSession` and cursor to aid in pagination. */
  edges: Array<LoadedLicenseSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LoadedLicenseSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LoadedLicenseSession` edge in the connection. */
export type LoadedLicenseSessionsEdge = {
  __typename?: 'LoadedLicenseSessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LoadedLicenseSession` at the end of the edge. */
  node?: Maybe<LoadedLicenseSession>;
};

/** Methods to use when ordering `LoadedLicenseSession`. */
export enum LoadedLicenseSessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  DayAsc = 'DAY_ASC',
  DayDesc = 'DAY_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against LocaleType fields. All fields are combined with a logical ‘and.’ */
export type LocaleTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['LocaleType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['LocaleType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['LocaleType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['LocaleType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['LocaleType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['LocaleType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['LocaleType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['LocaleType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['LocaleType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['LocaleType']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['LocaleType']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['LocaleType']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['LocaleType']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['LocaleType']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['LocaleType']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['LocaleType']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['LocaleType']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['LocaleType']>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['LocaleType']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['LocaleType']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['LocaleType']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['LocaleType']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['LocaleType']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['LocaleType']>;
};

/** All input for the `logoutAll` mutation. */
export type LogoutAllInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `logoutAll` mutation. */
export type LogoutAllPayload = {
  __typename?: 'LogoutAllPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `logout` mutation. */
export type LogoutInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  refreshTokenIn: JwtRefreshTokenInput;
};

/** The output of our `logout` mutation. */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `CurrencyExchangeRate`. */
  createCurrencyExchangeRate?: Maybe<CreateCurrencyExchangeRatePayload>;
  /** Creates a single `CurrencyExchangeRatesFiltered`. */
  createCurrencyExchangeRatesFiltered?: Maybe<CreateCurrencyExchangeRatesFilteredPayload>;
  /** Creates a single `Customer`. */
  createCustomer?: Maybe<CreateCustomerPayload>;
  /** Creates a single `LeaderboardPlayerScore`. */
  createLeaderboardPlayerScore?: Maybe<CreateLeaderboardPlayerScorePayload>;
  /** Creates a single `LeaderboardRound`. */
  createLeaderboardRound?: Maybe<CreateLeaderboardRoundPayload>;
  /** Creates a single `LeaderboardSession`. */
  createLeaderboardSession?: Maybe<CreateLeaderboardSessionPayload>;
  /** Creates a single `LeaderboardTeamScore`. */
  createLeaderboardTeamScore?: Maybe<CreateLeaderboardTeamScorePayload>;
  /** Creates a single `License`. */
  createLicense?: Maybe<CreateLicensePayload>;
  /** Creates a single `LicenseEvent`. */
  createLicenseEvent?: Maybe<CreateLicenseEventPayload>;
  /** Creates a single `LicenseSession`. */
  createLicenseSession?: Maybe<CreateLicenseSessionPayload>;
  /** Creates a single `LicenseSessionComment`. */
  createLicenseSessionComment?: Maybe<CreateLicenseSessionCommentPayload>;
  /** Creates a single `LicenseV2`. */
  createLicenseV2?: Maybe<CreateLicenseV2Payload>;
  /** Creates a single `LicenseV2Event`. */
  createLicenseV2Event?: Maybe<CreateLicenseV2EventPayload>;
  /** Creates a single `LoadedLicenseSession`. */
  createLoadedLicenseSession?: Maybe<CreateLoadedLicenseSessionPayload>;
  /** Creates a single `Product`. */
  createProduct?: Maybe<CreateProductPayload>;
  /** Creates a single `ReportAllProduct`. */
  createReportAllProduct?: Maybe<CreateReportAllProductPayload>;
  /** Creates a single `Timezone`. */
  createTimezone?: Maybe<CreateTimezonePayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserCustomer`. */
  createUserCustomer?: Maybe<CreateUserCustomerPayload>;
  /** Creates a single `UserFile`. */
  createUserFile?: Maybe<CreateUserFilePayload>;
  /** Creates a single `Venue`. */
  createVenue?: Maybe<CreateVenuePayload>;
  /** Updates a single `CurrencyExchangeRate` using its globally unique id and a patch. */
  updateCurrencyExchangeRate?: Maybe<UpdateCurrencyExchangeRatePayload>;
  /** Updates a single `CurrencyExchangeRate` using a unique key and a patch. */
  updateCurrencyExchangeRateById?: Maybe<UpdateCurrencyExchangeRatePayload>;
  /** Updates a single `CurrencyExchangeRate` using a unique key and a patch. */
  updateCurrencyExchangeRateByYearAndMonthAndDayAndCurrency?: Maybe<UpdateCurrencyExchangeRatePayload>;
  /** Updates a single `Customer` using its globally unique id and a patch. */
  updateCustomer?: Maybe<UpdateCustomerPayload>;
  /** Updates a single `Customer` using a unique key and a patch. */
  updateCustomerById?: Maybe<UpdateCustomerPayload>;
  /** Updates a single `Customer` using a unique key and a patch. */
  updateCustomerByName?: Maybe<UpdateCustomerPayload>;
  /** Updates a single `LeaderboardPlayerScore` using its globally unique id and a patch. */
  updateLeaderboardPlayerScore?: Maybe<UpdateLeaderboardPlayerScorePayload>;
  /** Updates a single `LeaderboardPlayerScore` using a unique key and a patch. */
  updateLeaderboardPlayerScoreById?: Maybe<UpdateLeaderboardPlayerScorePayload>;
  /** Updates a single `LeaderboardPlayerScore` using a unique key and a patch. */
  updateLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerId?: Maybe<UpdateLeaderboardPlayerScorePayload>;
  /** Updates a single `LeaderboardRound` using its globally unique id and a patch. */
  updateLeaderboardRound?: Maybe<UpdateLeaderboardRoundPayload>;
  /** Updates a single `LeaderboardRound` using a unique key and a patch. */
  updateLeaderboardRoundById?: Maybe<UpdateLeaderboardRoundPayload>;
  /** Updates a single `LeaderboardRound` using a unique key and a patch. */
  updateLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeId?: Maybe<UpdateLeaderboardRoundPayload>;
  /** Updates a single `LeaderboardSession` using its globally unique id and a patch. */
  updateLeaderboardSession?: Maybe<UpdateLeaderboardSessionPayload>;
  /** Updates a single `LeaderboardSession` using a unique key and a patch. */
  updateLeaderboardSessionById?: Maybe<UpdateLeaderboardSessionPayload>;
  /** Updates a single `LeaderboardSession` using a unique key and a patch. */
  updateLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionId?: Maybe<UpdateLeaderboardSessionPayload>;
  /** Updates a single `LeaderboardTeamScore` using its globally unique id and a patch. */
  updateLeaderboardTeamScore?: Maybe<UpdateLeaderboardTeamScorePayload>;
  /** Updates a single `LeaderboardTeamScore` using a unique key and a patch. */
  updateLeaderboardTeamScoreById?: Maybe<UpdateLeaderboardTeamScorePayload>;
  /** Updates a single `LeaderboardTeamScore` using a unique key and a patch. */
  updateLeaderboardTeamScoreByLeaderboardRoundIdAndTeamName?: Maybe<UpdateLeaderboardTeamScorePayload>;
  /** Updates a single `License` using its globally unique id and a patch. */
  updateLicense?: Maybe<UpdateLicensePayload>;
  /** Updates a single `License` using a unique key and a patch. */
  updateLicenseById?: Maybe<UpdateLicensePayload>;
  /** Updates a single `License` using a unique key and a patch. */
  updateLicenseByLicenseKey?: Maybe<UpdateLicensePayload>;
  /** Updates a single `LicenseEvent` using its globally unique id and a patch. */
  updateLicenseEvent?: Maybe<UpdateLicenseEventPayload>;
  /** Updates a single `LicenseEvent` using a unique key and a patch. */
  updateLicenseEventById?: Maybe<UpdateLicenseEventPayload>;
  /** Updates a single `LicenseSession` using its globally unique id and a patch. */
  updateLicenseSession?: Maybe<UpdateLicenseSessionPayload>;
  /** Updates a single `LicenseSession` using a unique key and a patch. */
  updateLicenseSessionById?: Maybe<UpdateLicenseSessionPayload>;
  /** Updates a single `LicenseSessionComment` using its globally unique id and a patch. */
  updateLicenseSessionComment?: Maybe<UpdateLicenseSessionCommentPayload>;
  /** Updates a single `LicenseSessionComment` using a unique key and a patch. */
  updateLicenseSessionCommentById?: Maybe<UpdateLicenseSessionCommentPayload>;
  /** Updates a single `LicenseV2` using its globally unique id and a patch. */
  updateLicenseV2?: Maybe<UpdateLicenseV2Payload>;
  /** Updates a single `LicenseV2` using a unique key and a patch. */
  updateLicenseV2ById?: Maybe<UpdateLicenseV2Payload>;
  /** Updates a single `LicenseV2` using a unique key and a patch. */
  updateLicenseV2ByLicenseKey?: Maybe<UpdateLicenseV2Payload>;
  /** Updates a single `LicenseV2Event` using its globally unique id and a patch. */
  updateLicenseV2Event?: Maybe<UpdateLicenseV2EventPayload>;
  /** Updates a single `LicenseV2Event` using a unique key and a patch. */
  updateLicenseV2EventById?: Maybe<UpdateLicenseV2EventPayload>;
  /** Updates a single `LoadedLicenseSession` using its globally unique id and a patch. */
  updateLoadedLicenseSession?: Maybe<UpdateLoadedLicenseSessionPayload>;
  /** Updates a single `LoadedLicenseSession` using a unique key and a patch. */
  updateLoadedLicenseSessionById?: Maybe<UpdateLoadedLicenseSessionPayload>;
  /** Updates a single `LoadedLicenseSession` using a unique key and a patch. */
  updateLoadedLicenseSessionByYearAndMonthAndDay?: Maybe<UpdateLoadedLicenseSessionPayload>;
  /** Updates a single `Product` using its globally unique id and a patch. */
  updateProduct?: Maybe<UpdateProductPayload>;
  /** Updates a single `Product` using a unique key and a patch. */
  updateProductById?: Maybe<UpdateProductPayload>;
  /** Updates a single `Timezone` using its globally unique id and a patch. */
  updateTimezone?: Maybe<UpdateTimezonePayload>;
  /** Updates a single `Timezone` using a unique key and a patch. */
  updateTimezoneByName?: Maybe<UpdateTimezonePayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserById?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByName?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserCustomer` using its globally unique id and a patch. */
  updateUserCustomer?: Maybe<UpdateUserCustomerPayload>;
  /** Updates a single `UserCustomer` using a unique key and a patch. */
  updateUserCustomerByUserId?: Maybe<UpdateUserCustomerPayload>;
  /** Updates a single `UserFile` using its globally unique id and a patch. */
  updateUserFile?: Maybe<UpdateUserFilePayload>;
  /** Updates a single `UserFile` using a unique key and a patch. */
  updateUserFileById?: Maybe<UpdateUserFilePayload>;
  /** Updates a single `Venue` using its globally unique id and a patch. */
  updateVenue?: Maybe<UpdateVenuePayload>;
  /** Updates a single `Venue` using a unique key and a patch. */
  updateVenueById?: Maybe<UpdateVenuePayload>;
  /** Updates a single `Venue` using a unique key and a patch. */
  updateVenueByCustomerIdAndCountryAndCityAndLocation?: Maybe<UpdateVenuePayload>;
  /** Deletes a single `CurrencyExchangeRate` using its globally unique id. */
  deleteCurrencyExchangeRate?: Maybe<DeleteCurrencyExchangeRatePayload>;
  /** Deletes a single `CurrencyExchangeRate` using a unique key. */
  deleteCurrencyExchangeRateById?: Maybe<DeleteCurrencyExchangeRatePayload>;
  /** Deletes a single `CurrencyExchangeRate` using a unique key. */
  deleteCurrencyExchangeRateByYearAndMonthAndDayAndCurrency?: Maybe<DeleteCurrencyExchangeRatePayload>;
  /** Deletes a single `Customer` using its globally unique id. */
  deleteCustomer?: Maybe<DeleteCustomerPayload>;
  /** Deletes a single `Customer` using a unique key. */
  deleteCustomerById?: Maybe<DeleteCustomerPayload>;
  /** Deletes a single `Customer` using a unique key. */
  deleteCustomerByName?: Maybe<DeleteCustomerPayload>;
  /** Deletes a single `LeaderboardPlayerScore` using its globally unique id. */
  deleteLeaderboardPlayerScore?: Maybe<DeleteLeaderboardPlayerScorePayload>;
  /** Deletes a single `LeaderboardPlayerScore` using a unique key. */
  deleteLeaderboardPlayerScoreById?: Maybe<DeleteLeaderboardPlayerScorePayload>;
  /** Deletes a single `LeaderboardPlayerScore` using a unique key. */
  deleteLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerId?: Maybe<DeleteLeaderboardPlayerScorePayload>;
  /** Deletes a single `LeaderboardRound` using its globally unique id. */
  deleteLeaderboardRound?: Maybe<DeleteLeaderboardRoundPayload>;
  /** Deletes a single `LeaderboardRound` using a unique key. */
  deleteLeaderboardRoundById?: Maybe<DeleteLeaderboardRoundPayload>;
  /** Deletes a single `LeaderboardRound` using a unique key. */
  deleteLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeId?: Maybe<DeleteLeaderboardRoundPayload>;
  /** Deletes a single `LeaderboardSession` using its globally unique id. */
  deleteLeaderboardSession?: Maybe<DeleteLeaderboardSessionPayload>;
  /** Deletes a single `LeaderboardSession` using a unique key. */
  deleteLeaderboardSessionById?: Maybe<DeleteLeaderboardSessionPayload>;
  /** Deletes a single `LeaderboardSession` using a unique key. */
  deleteLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionId?: Maybe<DeleteLeaderboardSessionPayload>;
  /** Deletes a single `LeaderboardTeamScore` using its globally unique id. */
  deleteLeaderboardTeamScore?: Maybe<DeleteLeaderboardTeamScorePayload>;
  /** Deletes a single `LeaderboardTeamScore` using a unique key. */
  deleteLeaderboardTeamScoreById?: Maybe<DeleteLeaderboardTeamScorePayload>;
  /** Deletes a single `LeaderboardTeamScore` using a unique key. */
  deleteLeaderboardTeamScoreByLeaderboardRoundIdAndTeamName?: Maybe<DeleteLeaderboardTeamScorePayload>;
  /** Deletes a single `License` using its globally unique id. */
  deleteLicense?: Maybe<DeleteLicensePayload>;
  /** Deletes a single `License` using a unique key. */
  deleteLicenseById?: Maybe<DeleteLicensePayload>;
  /** Deletes a single `License` using a unique key. */
  deleteLicenseByLicenseKey?: Maybe<DeleteLicensePayload>;
  /** Deletes a single `LicenseEvent` using its globally unique id. */
  deleteLicenseEvent?: Maybe<DeleteLicenseEventPayload>;
  /** Deletes a single `LicenseEvent` using a unique key. */
  deleteLicenseEventById?: Maybe<DeleteLicenseEventPayload>;
  /** Deletes a single `LicenseSession` using its globally unique id. */
  deleteLicenseSession?: Maybe<DeleteLicenseSessionPayload>;
  /** Deletes a single `LicenseSession` using a unique key. */
  deleteLicenseSessionById?: Maybe<DeleteLicenseSessionPayload>;
  /** Deletes a single `LicenseSessionComment` using its globally unique id. */
  deleteLicenseSessionComment?: Maybe<DeleteLicenseSessionCommentPayload>;
  /** Deletes a single `LicenseSessionComment` using a unique key. */
  deleteLicenseSessionCommentById?: Maybe<DeleteLicenseSessionCommentPayload>;
  /** Deletes a single `LicenseV2` using its globally unique id. */
  deleteLicenseV2?: Maybe<DeleteLicenseV2Payload>;
  /** Deletes a single `LicenseV2` using a unique key. */
  deleteLicenseV2ById?: Maybe<DeleteLicenseV2Payload>;
  /** Deletes a single `LicenseV2` using a unique key. */
  deleteLicenseV2ByLicenseKey?: Maybe<DeleteLicenseV2Payload>;
  /** Deletes a single `LicenseV2Event` using its globally unique id. */
  deleteLicenseV2Event?: Maybe<DeleteLicenseV2EventPayload>;
  /** Deletes a single `LicenseV2Event` using a unique key. */
  deleteLicenseV2EventById?: Maybe<DeleteLicenseV2EventPayload>;
  /** Deletes a single `LoadedLicenseSession` using its globally unique id. */
  deleteLoadedLicenseSession?: Maybe<DeleteLoadedLicenseSessionPayload>;
  /** Deletes a single `LoadedLicenseSession` using a unique key. */
  deleteLoadedLicenseSessionById?: Maybe<DeleteLoadedLicenseSessionPayload>;
  /** Deletes a single `LoadedLicenseSession` using a unique key. */
  deleteLoadedLicenseSessionByYearAndMonthAndDay?: Maybe<DeleteLoadedLicenseSessionPayload>;
  /** Deletes a single `Product` using its globally unique id. */
  deleteProduct?: Maybe<DeleteProductPayload>;
  /** Deletes a single `Product` using a unique key. */
  deleteProductById?: Maybe<DeleteProductPayload>;
  /** Deletes a single `Timezone` using its globally unique id. */
  deleteTimezone?: Maybe<DeleteTimezonePayload>;
  /** Deletes a single `Timezone` using a unique key. */
  deleteTimezoneByName?: Maybe<DeleteTimezonePayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserById?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByName?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserCustomer` using its globally unique id. */
  deleteUserCustomer?: Maybe<DeleteUserCustomerPayload>;
  /** Deletes a single `UserCustomer` using a unique key. */
  deleteUserCustomerByUserId?: Maybe<DeleteUserCustomerPayload>;
  /** Deletes a single `UserFile` using its globally unique id. */
  deleteUserFile?: Maybe<DeleteUserFilePayload>;
  /** Deletes a single `UserFile` using a unique key. */
  deleteUserFileById?: Maybe<DeleteUserFilePayload>;
  /** Deletes a single `Venue` using its globally unique id. */
  deleteVenue?: Maybe<DeleteVenuePayload>;
  /** Deletes a single `Venue` using a unique key. */
  deleteVenueById?: Maybe<DeleteVenuePayload>;
  /** Deletes a single `Venue` using a unique key. */
  deleteVenueByCustomerIdAndCountryAndCityAndLocation?: Maybe<DeleteVenuePayload>;
  /**
   * Creates a JWT token and refresh token pair that will securely identify a user
   * and give them certain permissions. This token expires in 15 minutes, refresh
   * token expires in 2 months.
   */
  authenticate?: Maybe<AuthenticatePayload>;
  /** Logouts from the current user session. */
  logout?: Maybe<LogoutPayload>;
  /** Logouts from the all current user sessions. */
  logoutAll?: Maybe<LogoutAllPayload>;
  /** Redetect license. */
  redetectLicense?: Maybe<RedetectLicensePayload>;
  /**
   * Creates a JWT token and refresh token pair that will securely identify a user
   * and give them certain permissions. This token expires in 15 minutes, refresh
   * token expires in 2 months.
   */
  refreshToken?: Maybe<RefreshTokenPayload>;
  /** Registers a single customer user and creates an account. */
  registerCustomerUser?: Maybe<RegisterCustomerUserPayload>;
  registerLeaderboardPlayerScores?: Maybe<RegisterLeaderboardPlayerScoresPayload>;
  registerLeaderboardRound?: Maybe<RegisterLeaderboardRoundPayload>;
  registerLeaderboardSession?: Maybe<RegisterLeaderboardSessionPayload>;
  registerLeaderboardTeamScores?: Maybe<RegisterLeaderboardTeamScoresPayload>;
  registerLicenseV2Event?: Maybe<RegisterLicenseV2EventPayload>;
  /** Registers a single user and creates an account. */
  registerUser?: Maybe<RegisterUserPayload>;
  /** Reset self user password. */
  resetSelfPassword?: Maybe<ResetSelfPasswordPayload>;
  /** Reset user password. */
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  /** Set product name for the given locale. */
  setProductName?: Maybe<SetProductNamePayload>;
  /** Disable/Enable user account. */
  setUserDisabled?: Maybe<SetUserDisabledPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCurrencyExchangeRateArgs = {
  input: CreateCurrencyExchangeRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCurrencyExchangeRatesFilteredArgs = {
  input: CreateCurrencyExchangeRatesFilteredInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeaderboardPlayerScoreArgs = {
  input: CreateLeaderboardPlayerScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeaderboardRoundArgs = {
  input: CreateLeaderboardRoundInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeaderboardSessionArgs = {
  input: CreateLeaderboardSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeaderboardTeamScoreArgs = {
  input: CreateLeaderboardTeamScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLicenseEventArgs = {
  input: CreateLicenseEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLicenseSessionArgs = {
  input: CreateLicenseSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLicenseSessionCommentArgs = {
  input: CreateLicenseSessionCommentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLicenseV2Args = {
  input: CreateLicenseV2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLicenseV2EventArgs = {
  input: CreateLicenseV2EventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLoadedLicenseSessionArgs = {
  input: CreateLoadedLicenseSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReportAllProductArgs = {
  input: CreateReportAllProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTimezoneArgs = {
  input: CreateTimezoneInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserCustomerArgs = {
  input: CreateUserCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserFileArgs = {
  input: CreateUserFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCurrencyExchangeRateArgs = {
  input: UpdateCurrencyExchangeRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCurrencyExchangeRateByIdArgs = {
  input: UpdateCurrencyExchangeRateByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyArgs = {
  input: UpdateCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerByIdArgs = {
  input: UpdateCustomerByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerByNameArgs = {
  input: UpdateCustomerByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardPlayerScoreArgs = {
  input: UpdateLeaderboardPlayerScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardPlayerScoreByIdArgs = {
  input: UpdateLeaderboardPlayerScoreByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdArgs = {
  input: UpdateLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardRoundArgs = {
  input: UpdateLeaderboardRoundInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardRoundByIdArgs = {
  input: UpdateLeaderboardRoundByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdArgs = {
  input: UpdateLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardSessionArgs = {
  input: UpdateLeaderboardSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardSessionByIdArgs = {
  input: UpdateLeaderboardSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdArgs = {
  input: UpdateLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardTeamScoreArgs = {
  input: UpdateLeaderboardTeamScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardTeamScoreByIdArgs = {
  input: UpdateLeaderboardTeamScoreByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameArgs = {
  input: UpdateLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseArgs = {
  input: UpdateLicenseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseByIdArgs = {
  input: UpdateLicenseByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseByLicenseKeyArgs = {
  input: UpdateLicenseByLicenseKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseEventArgs = {
  input: UpdateLicenseEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseEventByIdArgs = {
  input: UpdateLicenseEventByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseSessionArgs = {
  input: UpdateLicenseSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseSessionByIdArgs = {
  input: UpdateLicenseSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseSessionCommentArgs = {
  input: UpdateLicenseSessionCommentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseSessionCommentByIdArgs = {
  input: UpdateLicenseSessionCommentByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseV2Args = {
  input: UpdateLicenseV2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseV2ByIdArgs = {
  input: UpdateLicenseV2ByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseV2ByLicenseKeyArgs = {
  input: UpdateLicenseV2ByLicenseKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseV2EventArgs = {
  input: UpdateLicenseV2EventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLicenseV2EventByIdArgs = {
  input: UpdateLicenseV2EventByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLoadedLicenseSessionArgs = {
  input: UpdateLoadedLicenseSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLoadedLicenseSessionByIdArgs = {
  input: UpdateLoadedLicenseSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLoadedLicenseSessionByYearAndMonthAndDayArgs = {
  input: UpdateLoadedLicenseSessionByYearAndMonthAndDayInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductByIdArgs = {
  input: UpdateProductByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTimezoneArgs = {
  input: UpdateTimezoneInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTimezoneByNameArgs = {
  input: UpdateTimezoneByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByIdArgs = {
  input: UpdateUserByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNameArgs = {
  input: UpdateUserByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCustomerArgs = {
  input: UpdateUserCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCustomerByUserIdArgs = {
  input: UpdateUserCustomerByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserFileArgs = {
  input: UpdateUserFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserFileByIdArgs = {
  input: UpdateUserFileByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVenueArgs = {
  input: UpdateVenueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVenueByIdArgs = {
  input: UpdateVenueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVenueByCustomerIdAndCountryAndCityAndLocationArgs = {
  input: UpdateVenueByCustomerIdAndCountryAndCityAndLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCurrencyExchangeRateArgs = {
  input: DeleteCurrencyExchangeRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCurrencyExchangeRateByIdArgs = {
  input: DeleteCurrencyExchangeRateByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyArgs = {
  input: DeleteCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerByIdArgs = {
  input: DeleteCustomerByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerByNameArgs = {
  input: DeleteCustomerByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardPlayerScoreArgs = {
  input: DeleteLeaderboardPlayerScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardPlayerScoreByIdArgs = {
  input: DeleteLeaderboardPlayerScoreByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdArgs = {
  input: DeleteLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardRoundArgs = {
  input: DeleteLeaderboardRoundInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardRoundByIdArgs = {
  input: DeleteLeaderboardRoundByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdArgs = {
  input: DeleteLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardSessionArgs = {
  input: DeleteLeaderboardSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardSessionByIdArgs = {
  input: DeleteLeaderboardSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdArgs = {
  input: DeleteLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardTeamScoreArgs = {
  input: DeleteLeaderboardTeamScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardTeamScoreByIdArgs = {
  input: DeleteLeaderboardTeamScoreByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameArgs = {
  input: DeleteLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseArgs = {
  input: DeleteLicenseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseByIdArgs = {
  input: DeleteLicenseByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseByLicenseKeyArgs = {
  input: DeleteLicenseByLicenseKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseEventArgs = {
  input: DeleteLicenseEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseEventByIdArgs = {
  input: DeleteLicenseEventByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseSessionArgs = {
  input: DeleteLicenseSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseSessionByIdArgs = {
  input: DeleteLicenseSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseSessionCommentArgs = {
  input: DeleteLicenseSessionCommentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseSessionCommentByIdArgs = {
  input: DeleteLicenseSessionCommentByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseV2Args = {
  input: DeleteLicenseV2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseV2ByIdArgs = {
  input: DeleteLicenseV2ByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseV2ByLicenseKeyArgs = {
  input: DeleteLicenseV2ByLicenseKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseV2EventArgs = {
  input: DeleteLicenseV2EventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLicenseV2EventByIdArgs = {
  input: DeleteLicenseV2EventByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLoadedLicenseSessionArgs = {
  input: DeleteLoadedLicenseSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLoadedLicenseSessionByIdArgs = {
  input: DeleteLoadedLicenseSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLoadedLicenseSessionByYearAndMonthAndDayArgs = {
  input: DeleteLoadedLicenseSessionByYearAndMonthAndDayInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductByIdArgs = {
  input: DeleteProductByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTimezoneArgs = {
  input: DeleteTimezoneInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTimezoneByNameArgs = {
  input: DeleteTimezoneByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByIdArgs = {
  input: DeleteUserByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNameArgs = {
  input: DeleteUserByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserCustomerArgs = {
  input: DeleteUserCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserCustomerByUserIdArgs = {
  input: DeleteUserCustomerByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserFileArgs = {
  input: DeleteUserFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserFileByIdArgs = {
  input: DeleteUserFileByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVenueArgs = {
  input: DeleteVenueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVenueByIdArgs = {
  input: DeleteVenueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVenueByCustomerIdAndCountryAndCityAndLocationArgs = {
  input: DeleteVenueByCustomerIdAndCountryAndCityAndLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLogoutArgs = {
  input: LogoutInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLogoutAllArgs = {
  input: LogoutAllInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRedetectLicenseArgs = {
  input: RedetectLicenseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterCustomerUserArgs = {
  input: RegisterCustomerUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterLeaderboardPlayerScoresArgs = {
  input: RegisterLeaderboardPlayerScoresInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterLeaderboardRoundArgs = {
  input: RegisterLeaderboardRoundInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterLeaderboardSessionArgs = {
  input: RegisterLeaderboardSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterLeaderboardTeamScoresArgs = {
  input: RegisterLeaderboardTeamScoresInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterLicenseV2EventArgs = {
  input: RegisterLicenseV2EventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetSelfPasswordArgs = {
  input: ResetSelfPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetProductNameArgs = {
  input: SetProductNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetUserDisabledArgs = {
  input: SetUserDisabledInput;
};


/** A filter to be used against NameType fields. All fields are combined with a logical ‘and.’ */
export type NameTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['NameType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['NameType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['NameType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['NameType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['NameType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['NameType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['NameType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['NameType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['NameType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['NameType']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['NameType']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['NameType']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['NameType']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['NameType']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['NameType']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['NameType']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['NameType']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['NameType']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['NameType']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['NameType']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['NameType']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['NameType']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['NameType']>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['NameType']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['NameType']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['NameType']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['NameType']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['NameType']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['NameType']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['NameType']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['NameType']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['NameType']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['NameType']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['NameType']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['NameType']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['NameType']>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

export type PriceCalendarDefinition = {
  __typename?: 'PriceCalendarDefinition';
  fromDayOfWeek?: Maybe<Scalars['Int']>;
  toDayOfWeek?: Maybe<Scalars['Int']>;
  fromHour?: Maybe<Scalars['Int']>;
  toHour?: Maybe<Scalars['Int']>;
  price: Scalars['PriceNumberType'];
};

/** An input for mutations affecting `PriceCalendarDefinition` */
export type PriceCalendarDefinitionInput = {
  fromDayOfWeek?: Maybe<Scalars['Int']>;
  toDayOfWeek?: Maybe<Scalars['Int']>;
  fromHour?: Maybe<Scalars['Int']>;
  toHour?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['PriceNumberType']>;
};

export type PriceDefinition = {
  __typename?: 'PriceDefinition';
  priceCurrency: Scalars['CurrencyType'];
  priceVariant?: Maybe<PriceVariantType>;
  price: Scalars['PriceNumberType'];
};

/** A filter to be used against `PriceDefinition` object types. All fields are combined with a logical ‘and.’ */
export type PriceDefinitionFilter = {
  /** Filter by the object’s `priceCurrency` field. */
  priceCurrency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `priceVariant` field. */
  priceVariant?: Maybe<PriceVariantTypeFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<PriceNumberTypeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PriceDefinitionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PriceDefinitionFilter>>;
  /** Negates the expression. */
  not?: Maybe<PriceDefinitionFilter>;
};

/** An input for mutations affecting `PriceDefinition` */
export type PriceDefinitionInput = {
  priceCurrency?: Maybe<Scalars['CurrencyType']>;
  priceVariant?: Maybe<PriceVariantType>;
  price?: Maybe<Scalars['PriceNumberType']>;
};


/** A filter to be used against PriceNumberType fields. All fields are combined with a logical ‘and.’ */
export type PriceNumberTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['PriceNumberType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['PriceNumberType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['PriceNumberType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['PriceNumberType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['PriceNumberType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['PriceNumberType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['PriceNumberType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['PriceNumberType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['PriceNumberType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['PriceNumberType']>;
};


/** A filter to be used against PriceShareType fields. All fields are combined with a logical ‘and.’ */
export type PriceShareTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['PriceShareType']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['PriceShareType']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['PriceShareType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['PriceShareType']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['PriceShareType']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['PriceShareType']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['PriceShareType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['PriceShareType']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['PriceShareType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['PriceShareType']>;
};

export enum PriceVariantType {
  PerPlayer = 'PER_PLAYER',
  PerPlayerMinute = 'PER_PLAYER_MINUTE',
  PerSession = 'PER_SESSION',
  PerSessionMinute = 'PER_SESSION_MINUTE',
  PerPlayerProgressive = 'PER_PLAYER_PROGRESSIVE',
  PerSessionProgressive = 'PER_SESSION_PROGRESSIVE'
}

/** A filter to be used against PriceVariantType fields. All fields are combined with a logical ‘and.’ */
export type PriceVariantTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PriceVariantType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PriceVariantType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PriceVariantType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PriceVariantType>;
  /** Included in the specified list. */
  in?: Maybe<Array<PriceVariantType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PriceVariantType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PriceVariantType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PriceVariantType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PriceVariantType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PriceVariantType>;
};

/** Trackable product. */
export type Product = Node & {
  __typename?: 'Product';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  imgBase64?: Maybe<Scalars['String']>;
  /** I18n name of the product (hstore, e.g. en -> Shmooter, ru -> Шмутер). Must not be used directly. */
  nameI18N?: Maybe<Scalars['KeyValueHash']>;
  isArchived: Scalars['Boolean'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  isGame: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `LicenseSession`. */
  licenseSessionsByProductId: LicenseSessionsConnection;
  /** Reads and enables pagination through a set of `License`. */
  licensesByProductId: LicensesConnection;
  /** Reads and enables pagination through a set of `LicenseV2Event`. */
  licenseV2EventsByProductId: LicenseV2EventsConnection;
  /** Returns product name based on the current locale. */
  name?: Maybe<Scalars['String']>;
  /** Returns all i18n product names */
  namesAll?: Maybe<Scalars['JSON']>;
};


/** Trackable product. */
export type ProductLicenseSessionsByProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** Trackable product. */
export type ProductLicensesByProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicensesOrderBy>>;
  condition?: Maybe<LicenseCondition>;
  filter?: Maybe<LicenseFilter>;
};


/** Trackable product. */
export type ProductLicenseV2EventsByProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
  condition?: Maybe<LicenseV2EventCondition>;
  filter?: Maybe<LicenseV2EventFilter>;
};

/** A condition to be used against `Product` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProductCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `imgBase64` field. */
  imgBase64?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `nameI18N` field. */
  nameI18N?: Maybe<Scalars['KeyValueHash']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `isGame` field. */
  isGame?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against `Product` object types. All fields are combined with a logical ‘and.’ */
export type ProductFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `imgBase64` field. */
  imgBase64?: Maybe<StringFilter>;
  /** Filter by the object’s `nameI18N` field. */
  nameI18N?: Maybe<KeyValueHashFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isGame` field. */
  isGame?: Maybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `licenseSessionsByProductId` relation. */
  licenseSessionsByProductId?: Maybe<ProductToManyLicenseSessionFilter>;
  /** Some related `licenseSessionsByProductId` exist. */
  licenseSessionsByProductIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licensesByProductId` relation. */
  licensesByProductId?: Maybe<ProductToManyLicenseFilter>;
  /** Some related `licensesByProductId` exist. */
  licensesByProductIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseV2EventsByProductId` relation. */
  licenseV2EventsByProductId?: Maybe<ProductToManyLicenseV2EventFilter>;
  /** Some related `licenseV2EventsByProductId` exist. */
  licenseV2EventsByProductIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProductFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProductFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProductFilter>;
};

/** An input for mutations affecting `Product` */
export type ProductInput = {
  id?: Maybe<Scalars['UUID']>;
  imgBase64?: Maybe<Scalars['String']>;
  /** I18n name of the product (hstore, e.g. en -> Shmooter, ru -> Шмутер). Must not be used directly. */
  nameI18N?: Maybe<Scalars['KeyValueHash']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  isGame?: Maybe<Scalars['Boolean']>;
};

/** Represents an update to a `Product`. Fields that are set will be updated. */
export type ProductPatch = {
  id?: Maybe<Scalars['UUID']>;
  imgBase64?: Maybe<Scalars['String']>;
  /** I18n name of the product (hstore, e.g. en -> Shmooter, ru -> Шмутер). Must not be used directly. */
  nameI18N?: Maybe<Scalars['KeyValueHash']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  isGame?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `Product` values. */
export type ProductsConnection = {
  __typename?: 'ProductsConnection';
  /** A list of `Product` objects. */
  nodes: Array<Maybe<Product>>;
  /** A list of edges which contains the `Product` and cursor to aid in pagination. */
  edges: Array<ProductsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Product` edge in the connection. */
export type ProductsEdge = {
  __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node?: Maybe<Product>;
};

/** Methods to use when ordering `Product`. */
export enum ProductsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImgBase64Asc = 'IMG_BASE64_ASC',
  ImgBase64Desc = 'IMG_BASE64_DESC',
  NameI18NAsc = 'NAME_I18N_ASC',
  NameI18NDesc = 'NAME_I18N_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  IsGameAsc = 'IS_GAME_ASC',
  IsGameDesc = 'IS_GAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `License` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyLicenseFilter = {
  /** Every related `License` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseFilter>;
  /** Some related `License` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseFilter>;
  /** No related `License` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseFilter>;
};

/** A filter to be used against many `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyLicenseSessionFilter = {
  /** Every related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionFilter>;
  /** Some related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionFilter>;
  /** No related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionFilter>;
};

/** A filter to be used against many `LicenseV2Event` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyLicenseV2EventFilter = {
  /** Every related `LicenseV2Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseV2EventFilter>;
  /** Some related `LicenseV2Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseV2EventFilter>;
  /** No related `LicenseV2Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseV2EventFilter>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRate`. */
  allCurrencyExchangeRates?: Maybe<CurrencyExchangeRatesConnection>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRatesFiltered`. */
  allCurrencyExchangeRatesFiltereds?: Maybe<CurrencyExchangeRatesFilteredsConnection>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRatesFilteredYearAgg`. */
  allCurrencyExchangeRatesFilteredYearAggs?: Maybe<CurrencyExchangeRatesFilteredYearAggsConnection>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRatesFilteredYearMonthAgg`. */
  allCurrencyExchangeRatesFilteredYearMonthAggs?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `Customer`. */
  allCustomers?: Maybe<CustomersConnection>;
  /** Reads and enables pagination through a set of `LeaderboardPlayerScore`. */
  allLeaderboardPlayerScores?: Maybe<LeaderboardPlayerScoresConnection>;
  /** Reads and enables pagination through a set of `LeaderboardRound`. */
  allLeaderboardRounds?: Maybe<LeaderboardRoundsConnection>;
  /** Reads and enables pagination through a set of `LeaderboardSession`. */
  allLeaderboardSessions?: Maybe<LeaderboardSessionsConnection>;
  /** Reads and enables pagination through a set of `LeaderboardTeamScore`. */
  allLeaderboardTeamScores?: Maybe<LeaderboardTeamScoresConnection>;
  /** Reads and enables pagination through a set of `License`. */
  allLicenses?: Maybe<LicensesConnection>;
  /** Reads and enables pagination through a set of `LicenseEvent`. */
  allLicenseEvents?: Maybe<LicenseEventsConnection>;
  /** Reads and enables pagination through a set of `LicenseSession`. */
  allLicenseSessions?: Maybe<LicenseSessionsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionComment`. */
  allLicenseSessionComments?: Maybe<LicenseSessionCommentsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueDateAgg`. */
  allLicenseSessionCustomerVenueDateAggs?: Maybe<LicenseSessionCustomerVenueDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueDatePriceAgg`. */
  allLicenseSessionCustomerVenueDatePriceAggs?: Maybe<LicenseSessionCustomerVenueDatePriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueDateRoyaltyAgg`. */
  allLicenseSessionCustomerVenueDateRoyaltyAggs?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueProductDateAgg`. */
  allLicenseSessionCustomerVenueProductDateAggs?: Maybe<LicenseSessionCustomerVenueProductDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueProductYearAgg`. */
  allLicenseSessionCustomerVenueProductYearAggs?: Maybe<LicenseSessionCustomerVenueProductYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueProductYearMonthAgg`. */
  allLicenseSessionCustomerVenueProductYearMonthAggs?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearAgg`. */
  allLicenseSessionCustomerVenueYearAggs?: Maybe<LicenseSessionCustomerVenueYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearMonthAgg`. */
  allLicenseSessionCustomerVenueYearMonthAggs?: Maybe<LicenseSessionCustomerVenueYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearMonthPriceAgg`. */
  allLicenseSessionCustomerVenueYearMonthPriceAggs?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearMonthRoyaltyAgg`. */
  allLicenseSessionCustomerVenueYearMonthRoyaltyAggs?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearPriceAgg`. */
  allLicenseSessionCustomerVenueYearPriceAggs?: Maybe<LicenseSessionCustomerVenueYearPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearRoyaltyAgg`. */
  allLicenseSessionCustomerVenueYearRoyaltyAggs?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionDateAgg`. */
  allLicenseSessionDateAggs?: Maybe<LicenseSessionDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionDatePriceAgg`. */
  allLicenseSessionDatePriceAggs?: Maybe<LicenseSessionDatePriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionDateRoyaltyAgg`. */
  allLicenseSessionDateRoyaltyAggs?: Maybe<LicenseSessionDateRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionProductDateAgg`. */
  allLicenseSessionProductDateAggs?: Maybe<LicenseSessionProductDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionProductYearAgg`. */
  allLicenseSessionProductYearAggs?: Maybe<LicenseSessionProductYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionProductYearMonthAgg`. */
  allLicenseSessionProductYearMonthAggs?: Maybe<LicenseSessionProductYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearAgg`. */
  allLicenseSessionYearAggs?: Maybe<LicenseSessionYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearMonthAgg`. */
  allLicenseSessionYearMonthAggs?: Maybe<LicenseSessionYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearMonthPriceAgg`. */
  allLicenseSessionYearMonthPriceAggs?: Maybe<LicenseSessionYearMonthPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearMonthRoyaltyAgg`. */
  allLicenseSessionYearMonthRoyaltyAggs?: Maybe<LicenseSessionYearMonthRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearPriceAgg`. */
  allLicenseSessionYearPriceAggs?: Maybe<LicenseSessionYearPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearRoyaltyAgg`. */
  allLicenseSessionYearRoyaltyAggs?: Maybe<LicenseSessionYearRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionsAllCurrency`. */
  allLicenseSessionsAllCurrencies?: Maybe<LicenseSessionsAllCurrenciesConnection>;
  /** Reads and enables pagination through a set of `LicenseV2`. */
  allLicenseV2S?: Maybe<LicenseV2SConnection>;
  /** Reads and enables pagination through a set of `LicenseV2Event`. */
  allLicenseV2Events?: Maybe<LicenseV2EventsConnection>;
  /** Reads and enables pagination through a set of `LoadedLicenseSession`. */
  allLoadedLicenseSessions?: Maybe<LoadedLicenseSessionsConnection>;
  /** Reads and enables pagination through a set of `Product`. */
  allProducts?: Maybe<ProductsConnection>;
  /** Reads and enables pagination through a set of `ReportAllProduct`. */
  allReportAllProducts?: Maybe<ReportAllProductsConnection>;
  /** Reads and enables pagination through a set of `ReportAllVenue`. */
  allReportAllVenues?: Maybe<ReportAllVenuesConnection>;
  /** Reads and enables pagination through a set of `ReportProductUsage`. */
  allReportProductUsages?: Maybe<ReportProductUsagesConnection>;
  /** Reads and enables pagination through a set of `Timezone`. */
  allTimezones?: Maybe<TimezonesConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `UserCustomer`. */
  allUserCustomers?: Maybe<UserCustomersConnection>;
  /** Reads and enables pagination through a set of `UserFile`. */
  allUserFiles?: Maybe<UserFilesConnection>;
  /** Reads and enables pagination through a set of `Venue`. */
  allVenues?: Maybe<VenuesConnection>;
  currencyExchangeRateById?: Maybe<CurrencyExchangeRate>;
  currencyExchangeRateByYearAndMonthAndDayAndCurrency?: Maybe<CurrencyExchangeRate>;
  customerById?: Maybe<Customer>;
  customerByName?: Maybe<Customer>;
  leaderboardPlayerScoreById?: Maybe<LeaderboardPlayerScore>;
  leaderboardPlayerScoreByLeaderboardScoreIdAndPlayerId?: Maybe<LeaderboardPlayerScore>;
  leaderboardRoundById?: Maybe<LeaderboardRound>;
  leaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeId?: Maybe<LeaderboardRound>;
  leaderboardSessionById?: Maybe<LeaderboardSession>;
  leaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionId?: Maybe<LeaderboardSession>;
  leaderboardTeamScoreById?: Maybe<LeaderboardTeamScore>;
  leaderboardTeamScoreByLeaderboardRoundIdAndTeamName?: Maybe<LeaderboardTeamScore>;
  licenseById?: Maybe<License>;
  licenseByLicenseKey?: Maybe<License>;
  licenseEventById?: Maybe<LicenseEvent>;
  licenseSessionById?: Maybe<LicenseSession>;
  licenseSessionCommentById?: Maybe<LicenseSessionComment>;
  licenseV2ById?: Maybe<LicenseV2>;
  licenseV2ByLicenseKey?: Maybe<LicenseV2>;
  licenseV2EventById?: Maybe<LicenseV2Event>;
  loadedLicenseSessionById?: Maybe<LoadedLicenseSession>;
  loadedLicenseSessionByYearAndMonthAndDay?: Maybe<LoadedLicenseSession>;
  productById?: Maybe<Product>;
  timezoneByName?: Maybe<Timezone>;
  userById?: Maybe<User>;
  userByName?: Maybe<User>;
  userCustomerByUserId?: Maybe<UserCustomer>;
  userFileById?: Maybe<UserFile>;
  venueById?: Maybe<Venue>;
  venueByCustomerIdAndCountryAndCityAndLocation?: Maybe<Venue>;
  /** Calculates the license price based on the session parameters */
  calculateLicensePrice?: Maybe<Scalars['PriceNumberType']>;
  /** Calculates the total price based on the session parameters */
  calculateTotalPrice?: Maybe<Scalars['PriceNumberType']>;
  /** Calculates the total royalty based on the session parameters */
  calculateTotalRoyalty?: Maybe<Scalars['PriceNumberType']>;
  /** Gets the user who was identified by JWT. */
  currentUser?: Maybe<User>;
  /** Gets the user id who was identified by JWT. */
  currentUserId?: Maybe<Scalars['UUID']>;
  getAllCustomerTags?: Maybe<GetAllCustomerTagsConnection>;
  getMissingExchangeRateDates?: Maybe<GetMissingExchangeRateDatesConnection>;
  leaderboardExtraScoreMax?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  leaderboardExtraScoreMin?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  leaderboardExtraScoreSum?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  /** Returns product name based on the current locale. */
  productNameFromHstore?: Maybe<Scalars['String']>;
  userSelfAttachedCustomerId?: Maybe<Scalars['UUID']>;
  /** Reads a single `CurrencyExchangeRate` using its globally unique `ID`. */
  currencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  /** Reads a single `Customer` using its globally unique `ID`. */
  customer?: Maybe<Customer>;
  /** Reads a single `LeaderboardPlayerScore` using its globally unique `ID`. */
  leaderboardPlayerScore?: Maybe<LeaderboardPlayerScore>;
  /** Reads a single `LeaderboardRound` using its globally unique `ID`. */
  leaderboardRound?: Maybe<LeaderboardRound>;
  /** Reads a single `LeaderboardSession` using its globally unique `ID`. */
  leaderboardSession?: Maybe<LeaderboardSession>;
  /** Reads a single `LeaderboardTeamScore` using its globally unique `ID`. */
  leaderboardTeamScore?: Maybe<LeaderboardTeamScore>;
  /** Reads a single `License` using its globally unique `ID`. */
  license?: Maybe<License>;
  /** Reads a single `LicenseEvent` using its globally unique `ID`. */
  licenseEvent?: Maybe<LicenseEvent>;
  /** Reads a single `LicenseSession` using its globally unique `ID`. */
  licenseSession?: Maybe<LicenseSession>;
  /** Reads a single `LicenseSessionComment` using its globally unique `ID`. */
  licenseSessionComment?: Maybe<LicenseSessionComment>;
  /** Reads a single `LicenseV2` using its globally unique `ID`. */
  licenseV2?: Maybe<LicenseV2>;
  /** Reads a single `LicenseV2Event` using its globally unique `ID`. */
  licenseV2Event?: Maybe<LicenseV2Event>;
  /** Reads a single `LoadedLicenseSession` using its globally unique `ID`. */
  loadedLicenseSession?: Maybe<LoadedLicenseSession>;
  /** Reads a single `Product` using its globally unique `ID`. */
  product?: Maybe<Product>;
  /** Reads a single `Timezone` using its globally unique `ID`. */
  timezone?: Maybe<Timezone>;
  /** Reads a single `User` using its globally unique `ID`. */
  user?: Maybe<User>;
  /** Reads a single `UserCustomer` using its globally unique `ID`. */
  userCustomer?: Maybe<UserCustomer>;
  /** Reads a single `UserFile` using its globally unique `ID`. */
  userFile?: Maybe<UserFile>;
  /** Reads a single `Venue` using its globally unique `ID`. */
  venue?: Maybe<Venue>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrencyExchangeRatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesOrderBy>>;
  condition?: Maybe<CurrencyExchangeRateCondition>;
  filter?: Maybe<CurrencyExchangeRateFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrencyExchangeRatesFilteredsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredsOrderBy>>;
  condition?: Maybe<CurrencyExchangeRatesFilteredCondition>;
  filter?: Maybe<CurrencyExchangeRatesFilteredFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrencyExchangeRatesFilteredYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredYearAggsOrderBy>>;
  condition?: Maybe<CurrencyExchangeRatesFilteredYearAggCondition>;
  filter?: Maybe<CurrencyExchangeRatesFilteredYearAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrencyExchangeRatesFilteredYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredYearMonthAggsOrderBy>>;
  condition?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggCondition>;
  filter?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomersOrderBy>>;
  condition?: Maybe<CustomerCondition>;
  filter?: Maybe<CustomerFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLeaderboardPlayerScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardPlayerScoresOrderBy>>;
  condition?: Maybe<LeaderboardPlayerScoreCondition>;
  filter?: Maybe<LeaderboardPlayerScoreFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLeaderboardRoundsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardRoundsOrderBy>>;
  condition?: Maybe<LeaderboardRoundCondition>;
  filter?: Maybe<LeaderboardRoundFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLeaderboardSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardSessionsOrderBy>>;
  condition?: Maybe<LeaderboardSessionCondition>;
  filter?: Maybe<LeaderboardSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLeaderboardTeamScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardTeamScoresOrderBy>>;
  condition?: Maybe<LeaderboardTeamScoreCondition>;
  filter?: Maybe<LeaderboardTeamScoreFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicensesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicensesOrderBy>>;
  condition?: Maybe<LicenseCondition>;
  filter?: Maybe<LicenseFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
  condition?: Maybe<LicenseEventCondition>;
  filter?: Maybe<LicenseEventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
  condition?: Maybe<LicenseSessionCommentCondition>;
  filter?: Maybe<LicenseSessionCommentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueDateAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueDateAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueDatePriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueDatePriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueDatePriceAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueDatePriceAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueDateRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueDateRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueProductDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueProductDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueProductDateAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueProductDateAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueProductYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueProductYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueProductYearAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueProductYearAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueProductYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueProductYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearMonthAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueYearMonthPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearMonthPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueYearMonthRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearMonthRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueYearPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearPriceAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearPriceAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionCustomerVenueYearRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionDateAggCondition>;
  filter?: Maybe<LicenseSessionDateAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionDatePriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionDatePriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionDatePriceAggCondition>;
  filter?: Maybe<LicenseSessionDatePriceAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionDateRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionDateRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionDateRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionDateRoyaltyAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionProductDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionProductDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionProductDateAggCondition>;
  filter?: Maybe<LicenseSessionProductDateAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionProductYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionProductYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionProductYearAggCondition>;
  filter?: Maybe<LicenseSessionProductYearAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionProductYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionProductYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionProductYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionProductYearMonthAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearAggCondition>;
  filter?: Maybe<LicenseSessionYearAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionYearMonthAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionYearMonthPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearMonthPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearMonthPriceAggCondition>;
  filter?: Maybe<LicenseSessionYearMonthPriceAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionYearMonthRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearMonthRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearMonthRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionYearMonthRoyaltyAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionYearPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearPriceAggCondition>;
  filter?: Maybe<LicenseSessionYearPriceAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionYearRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionYearRoyaltyAggFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseSessionsAllCurrenciesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsAllCurrenciesOrderBy>>;
  condition?: Maybe<LicenseSessionsAllCurrencyCondition>;
  filter?: Maybe<LicenseSessionsAllCurrencyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseV2SArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2SOrderBy>>;
  condition?: Maybe<LicenseV2Condition>;
  filter?: Maybe<LicenseV2Filter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLicenseV2EventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
  condition?: Maybe<LicenseV2EventCondition>;
  filter?: Maybe<LicenseV2EventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLoadedLicenseSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LoadedLicenseSessionsOrderBy>>;
  condition?: Maybe<LoadedLicenseSessionCondition>;
  filter?: Maybe<LoadedLicenseSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReportAllProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReportAllProductsOrderBy>>;
  condition?: Maybe<ReportAllProductCondition>;
  filter?: Maybe<ReportAllProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReportAllVenuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReportAllVenuesOrderBy>>;
  condition?: Maybe<ReportAllVenueCondition>;
  filter?: Maybe<ReportAllVenueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReportProductUsagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReportProductUsagesOrderBy>>;
  condition?: Maybe<ReportProductUsageCondition>;
  filter?: Maybe<ReportProductUsageFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTimezonesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TimezonesOrderBy>>;
  condition?: Maybe<TimezoneCondition>;
  filter?: Maybe<TimezoneFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUserCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserCustomersOrderBy>>;
  condition?: Maybe<UserCustomerCondition>;
  filter?: Maybe<UserCustomerFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUserFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserFilesOrderBy>>;
  condition?: Maybe<UserFileCondition>;
  filter?: Maybe<UserFileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllVenuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VenuesOrderBy>>;
  condition?: Maybe<VenueCondition>;
  filter?: Maybe<VenueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyExchangeRateByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  currency: Scalars['CurrencyType'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerByNameArgs = {
  name: Scalars['NameType'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardPlayerScoreByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdArgs = {
  leaderboardScoreId: Scalars['UUID'];
  playerId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardRoundByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdArgs = {
  leaderboardSessionId: Scalars['UUID'];
  roundNum: Scalars['Int'];
  modeId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardSessionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdArgs = {
  licenseSessionId: Scalars['UUID'];
  reportedLeaderboardSessionId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardTeamScoreByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameArgs = {
  leaderboardRoundId: Scalars['UUID'];
  teamName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseByLicenseKeyArgs = {
  licenseKey: Scalars['LicenseKeyType'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseEventByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseSessionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseSessionCommentByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseV2ByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseV2ByLicenseKeyArgs = {
  licenseKey: Scalars['LicenseKeyType'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseV2EventByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLoadedLicenseSessionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLoadedLicenseSessionByYearAndMonthAndDayArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTimezoneByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNameArgs = {
  name: Scalars['NameType'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserCustomerByUserIdArgs = {
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserFileByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVenueByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVenueByCustomerIdAndCountryAndCityAndLocationArgs = {
  customerId: Scalars['UUID'];
  country: Scalars['String'];
  city: Scalars['String'];
  location: Scalars['NameType'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalculateLicensePriceArgs = {
  licenseId: Scalars['UUID'];
  sessionStartTime: Scalars['Datetime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalculateTotalPriceArgs = {
  price?: Maybe<PriceDefinitionInput>;
  sessionPlayers?: Maybe<Scalars['Int']>;
  sessionDurationMin?: Maybe<Scalars['Float']>;
  sessionIsPaid?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCalculateTotalRoyaltyArgs = {
  royalty?: Maybe<RoyaltyDefinitionInput>;
  priceTotal?: Maybe<Scalars['PriceNumberType']>;
  sessionPlayers?: Maybe<Scalars['Int']>;
  sessionDurationMin?: Maybe<Scalars['Float']>;
  sessionIsPaid?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAllCustomerTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<StringFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetMissingExchangeRateDatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<DatetimeFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardExtraScoreMaxArgs = {
  a?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  b?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardExtraScoreMinArgs = {
  a?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  b?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardExtraScoreSumArgs = {
  a?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  b?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductNameFromHstoreArgs = {
  nameI18N?: Maybe<Scalars['KeyValueHash']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyExchangeRateArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardPlayerScoreArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardRoundArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardSessionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeaderboardTeamScoreArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseEventArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseSessionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseSessionCommentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseV2Args = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicenseV2EventArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLoadedLicenseSessionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTimezoneArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserCustomerArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserFileArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVenueArgs = {
  nodeId: Scalars['ID'];
};

/** All input for the `redetectLicense` mutation. */
export type RedetectLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseSessionId: Scalars['UUID'];
};

/** The output of our `redetectLicense` mutation. */
export type RedetectLicensePayload = {
  __typename?: 'RedetectLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshToken` mutation. */
export type RefreshTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  refreshTokenIn: JwtRefreshTokenInput;
};

/** The output of our `refreshToken` mutation. */
export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtTokenPair?: Maybe<JwtTokenPair>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerCustomerUser` mutation. */
export type RegisterCustomerUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userName: Scalars['NameType'];
  accountLogin: Scalars['AccountLoginType'];
  accountPassword: Scalars['String'];
  customerIdInput: Scalars['UUID'];
};

/** The output of our `registerCustomerUser` mutation. */
export type RegisterCustomerUserPayload = {
  __typename?: 'RegisterCustomerUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `User`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `registerCustomerUser` mutation. */
export type RegisterCustomerUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `registerLeaderboardPlayerScores` mutation. */
export type RegisterLeaderboardPlayerScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  timeUtcRegisteredIn?: Maybe<Scalars['Datetime']>;
  leaderboardTeamScoreIdIn?: Maybe<Scalars['UUID']>;
  playerIdIn?: Maybe<Scalars['String']>;
  playerNameIn?: Maybe<Scalars['String']>;
  scoreIn?: Maybe<LeaderboardScoreInput>;
  extraScoresIn?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  tagsIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The output of our `registerLeaderboardPlayerScores` mutation. */
export type RegisterLeaderboardPlayerScoresPayload = {
  __typename?: 'RegisterLeaderboardPlayerScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerLeaderboardRound` mutation. */
export type RegisterLeaderboardRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  timeUtcRegisteredIn?: Maybe<Scalars['Datetime']>;
  leaderboardSessionIdIn?: Maybe<Scalars['UUID']>;
  timeSecIn?: Maybe<Scalars['Int']>;
  roundNumIn?: Maybe<Scalars['Int']>;
  modeIdIn?: Maybe<Scalars['String']>;
  tagsIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataRawIn?: Maybe<Scalars['String']>;
};

/** The output of our `registerLeaderboardRound` mutation. */
export type RegisterLeaderboardRoundPayload = {
  __typename?: 'RegisterLeaderboardRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerLeaderboardSession` mutation. */
export type RegisterLeaderboardSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  timeUtcRegisteredIn?: Maybe<Scalars['Datetime']>;
  licenseKeyIn?: Maybe<Scalars['String']>;
  reportedSessionIdIn?: Maybe<Scalars['String']>;
  reportedLeaderboardSessionIdIn?: Maybe<Scalars['String']>;
  reportedProductIdIn?: Maybe<Scalars['String']>;
  timeSecIn?: Maybe<Scalars['Int']>;
};

/** The output of our `registerLeaderboardSession` mutation. */
export type RegisterLeaderboardSessionPayload = {
  __typename?: 'RegisterLeaderboardSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerLeaderboardTeamScores` mutation. */
export type RegisterLeaderboardTeamScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  timeUtcRegisteredIn?: Maybe<Scalars['Datetime']>;
  leaderboardRoundIdIn?: Maybe<Scalars['UUID']>;
  teamNameIn?: Maybe<Scalars['String']>;
  teamColorIn?: Maybe<Scalars['String']>;
  scoreIn?: Maybe<LeaderboardScoreInput>;
  extraScoresIn?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  tagsIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The output of our `registerLeaderboardTeamScores` mutation. */
export type RegisterLeaderboardTeamScoresPayload = {
  __typename?: 'RegisterLeaderboardTeamScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerLicenseV2Event` mutation. */
export type RegisterLicenseV2EventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseV2IdIn?: Maybe<Scalars['UUID']>;
  productIdIn?: Maybe<Scalars['UUID']>;
  dataRawIn?: Maybe<Scalars['String']>;
  licenseKeyIn?: Maybe<Scalars['String']>;
  eventTimeUtcRegisteredIn?: Maybe<Scalars['Datetime']>;
  eventTimeUtcRawIn?: Maybe<Scalars['Datetime']>;
  reportedSessionIdIn?: Maybe<Scalars['String']>;
  reportedMasterSessionIdIn?: Maybe<Scalars['String']>;
  osVersionIn?: Maybe<Scalars['String']>;
  productVersionIn?: Maybe<Scalars['String']>;
  machineidIn?: Maybe<Scalars['String']>;
  ipIn?: Maybe<Scalars['String']>;
  locationIn?: Maybe<Scalars['String']>;
  locationCountryCodeIn?: Maybe<Scalars['String']>;
  locationCountryNameIn?: Maybe<Scalars['String']>;
  locationRegionNameIn?: Maybe<Scalars['String']>;
  locationCityNameIn?: Maybe<Scalars['String']>;
  locationCoordsIn?: Maybe<Scalars['String']>;
  playersIn?: Maybe<Scalars['Int']>;
  isPaidIn?: Maybe<Scalars['Boolean']>;
  commentIn?: Maybe<Scalars['String']>;
  revenueIn?: Maybe<Scalars['BigFloat']>;
  productStateIn?: Maybe<Scalars['JSON']>;
};

/** The output of our `registerLicenseV2Event` mutation. */
export type RegisterLicenseV2EventPayload = {
  __typename?: 'RegisterLicenseV2EventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerUser` mutation. */
export type RegisterUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userName: Scalars['NameType'];
  accountRole: UserRoleType;
  accountLogin: Scalars['AccountLoginType'];
  accountPassword: Scalars['String'];
};

/** The output of our `registerUser` mutation. */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `User`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `registerUser` mutation. */
export type RegisterUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

export type ReportAllProduct = {
  __typename?: 'ReportAllProduct';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

/**
 * A condition to be used against `ReportAllProduct` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportAllProductCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against `ReportAllProduct` object types. All fields are combined with a logical ‘and.’ */
export type ReportAllProductFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReportAllProductFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReportAllProductFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReportAllProductFilter>;
};

/** An input for mutations affecting `ReportAllProduct` */
export type ReportAllProductInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `ReportAllProduct` values. */
export type ReportAllProductsConnection = {
  __typename?: 'ReportAllProductsConnection';
  /** A list of `ReportAllProduct` objects. */
  nodes: Array<Maybe<ReportAllProduct>>;
  /** A list of edges which contains the `ReportAllProduct` and cursor to aid in pagination. */
  edges: Array<ReportAllProductsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportAllProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReportAllProduct` edge in the connection. */
export type ReportAllProductsEdge = {
  __typename?: 'ReportAllProductsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReportAllProduct` at the end of the edge. */
  node?: Maybe<ReportAllProduct>;
};

/** Methods to use when ordering `ReportAllProduct`. */
export enum ReportAllProductsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC'
}

export type ReportAllVenue = {
  __typename?: 'ReportAllVenue';
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerArchived?: Maybe<Scalars['Boolean']>;
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  venueId?: Maybe<Scalars['String']>;
  venueCountry?: Maybe<Scalars['String']>;
  venueCity?: Maybe<Scalars['String']>;
  venueLocation?: Maybe<Scalars['String']>;
  venueArchived?: Maybe<Scalars['Boolean']>;
  venueTimezoneUtcSecondsOffset?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ReportAllVenue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportAllVenueCondition = {
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerArchived` field. */
  customerArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `customerTags` field. */
  customerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCountry` field. */
  venueCountry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCity` field. */
  venueCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueLocation` field. */
  venueLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueArchived` field. */
  venueArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `venueTimezoneUtcSecondsOffset` field. */
  venueTimezoneUtcSecondsOffset?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `ReportAllVenue` object types. All fields are combined with a logical ‘and.’ */
export type ReportAllVenueFilter = {
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<StringFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerArchived` field. */
  customerArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `customerTags` field. */
  customerTags?: Maybe<StringListFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCountry` field. */
  venueCountry?: Maybe<StringFilter>;
  /** Filter by the object’s `venueCity` field. */
  venueCity?: Maybe<StringFilter>;
  /** Filter by the object’s `venueLocation` field. */
  venueLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `venueArchived` field. */
  venueArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `venueTimezoneUtcSecondsOffset` field. */
  venueTimezoneUtcSecondsOffset?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReportAllVenueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReportAllVenueFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReportAllVenueFilter>;
};

/** A connection to a list of `ReportAllVenue` values. */
export type ReportAllVenuesConnection = {
  __typename?: 'ReportAllVenuesConnection';
  /** A list of `ReportAllVenue` objects. */
  nodes: Array<Maybe<ReportAllVenue>>;
  /** A list of edges which contains the `ReportAllVenue` and cursor to aid in pagination. */
  edges: Array<ReportAllVenuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportAllVenue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReportAllVenue` edge in the connection. */
export type ReportAllVenuesEdge = {
  __typename?: 'ReportAllVenuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReportAllVenue` at the end of the edge. */
  node?: Maybe<ReportAllVenue>;
};

/** Methods to use when ordering `ReportAllVenue`. */
export enum ReportAllVenuesOrderBy {
  Natural = 'NATURAL',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerArchivedAsc = 'CUSTOMER_ARCHIVED_ASC',
  CustomerArchivedDesc = 'CUSTOMER_ARCHIVED_DESC',
  CustomerTagsAsc = 'CUSTOMER_TAGS_ASC',
  CustomerTagsDesc = 'CUSTOMER_TAGS_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC',
  VenueCountryAsc = 'VENUE_COUNTRY_ASC',
  VenueCountryDesc = 'VENUE_COUNTRY_DESC',
  VenueCityAsc = 'VENUE_CITY_ASC',
  VenueCityDesc = 'VENUE_CITY_DESC',
  VenueLocationAsc = 'VENUE_LOCATION_ASC',
  VenueLocationDesc = 'VENUE_LOCATION_DESC',
  VenueArchivedAsc = 'VENUE_ARCHIVED_ASC',
  VenueArchivedDesc = 'VENUE_ARCHIVED_DESC',
  VenueTimezoneUtcSecondsOffsetAsc = 'VENUE_TIMEZONE_UTC_SECONDS_OFFSET_ASC',
  VenueTimezoneUtcSecondsOffsetDesc = 'VENUE_TIMEZONE_UTC_SECONDS_OFFSET_DESC'
}

export type ReportProductUsage = {
  __typename?: 'ReportProductUsage';
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  dateLocal?: Maybe<Scalars['Date']>;
  dateUtc?: Maybe<Scalars['Date']>;
  venueId?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ReportProductUsage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportProductUsageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productName` field. */
  productName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateLocal` field. */
  dateLocal?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `dateUtc` field. */
  dateUtc?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `venueId` field. */
  venueId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `ReportProductUsage` object types. All fields are combined with a logical ‘and.’ */
export type ReportProductUsageFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: Maybe<StringFilter>;
  /** Filter by the object’s `dateLocal` field. */
  dateLocal?: Maybe<DateFilter>;
  /** Filter by the object’s `dateUtc` field. */
  dateUtc?: Maybe<DateFilter>;
  /** Filter by the object’s `venueId` field. */
  venueId?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReportProductUsageFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReportProductUsageFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReportProductUsageFilter>;
};

/** A connection to a list of `ReportProductUsage` values. */
export type ReportProductUsagesConnection = {
  __typename?: 'ReportProductUsagesConnection';
  /** A list of `ReportProductUsage` objects. */
  nodes: Array<Maybe<ReportProductUsage>>;
  /** A list of edges which contains the `ReportProductUsage` and cursor to aid in pagination. */
  edges: Array<ReportProductUsagesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportProductUsage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReportProductUsage` edge in the connection. */
export type ReportProductUsagesEdge = {
  __typename?: 'ReportProductUsagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReportProductUsage` at the end of the edge. */
  node?: Maybe<ReportProductUsage>;
};

/** Methods to use when ordering `ReportProductUsage`. */
export enum ReportProductUsagesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  DateLocalAsc = 'DATE_LOCAL_ASC',
  DateLocalDesc = 'DATE_LOCAL_DESC',
  DateUtcAsc = 'DATE_UTC_ASC',
  DateUtcDesc = 'DATE_UTC_DESC',
  VenueIdAsc = 'VENUE_ID_ASC',
  VenueIdDesc = 'VENUE_ID_DESC'
}

/** All input for the `resetSelfPassword` mutation. */
export type ResetSelfPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  accountPassword: Scalars['String'];
};

/** The output of our `resetSelfPassword` mutation. */
export type ResetSelfPasswordPayload = {
  __typename?: 'ResetSelfPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resetUserPassword` mutation. */
export type ResetUserPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  accountPassword: Scalars['String'];
};

/** The output of our `resetUserPassword` mutation. */
export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type RoyaltyDefinition = {
  __typename?: 'RoyaltyDefinition';
  royaltyVariant?: Maybe<RoyaltyVariantType>;
  royaltyCurrency: Scalars['CurrencyType'];
  royalty: Scalars['PriceNumberType'];
  royaltyShare: Scalars['PriceShareType'];
};

/** A filter to be used against `RoyaltyDefinition` object types. All fields are combined with a logical ‘and.’ */
export type RoyaltyDefinitionFilter = {
  /** Filter by the object’s `royaltyVariant` field. */
  royaltyVariant?: Maybe<RoyaltyVariantTypeFilter>;
  /** Filter by the object’s `royaltyCurrency` field. */
  royaltyCurrency?: Maybe<CurrencyTypeFilter>;
  /** Filter by the object’s `royalty` field. */
  royalty?: Maybe<PriceNumberTypeFilter>;
  /** Filter by the object’s `royaltyShare` field. */
  royaltyShare?: Maybe<PriceShareTypeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RoyaltyDefinitionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RoyaltyDefinitionFilter>>;
  /** Negates the expression. */
  not?: Maybe<RoyaltyDefinitionFilter>;
};

/** An input for mutations affecting `RoyaltyDefinition` */
export type RoyaltyDefinitionInput = {
  royaltyVariant?: Maybe<RoyaltyVariantType>;
  royaltyCurrency?: Maybe<Scalars['CurrencyType']>;
  royalty?: Maybe<Scalars['PriceNumberType']>;
  royaltyShare?: Maybe<Scalars['PriceShareType']>;
};

export enum RoyaltyVariantType {
  PerRevenueShare = 'PER_REVENUE_SHARE',
  PerSessionFixed = 'PER_SESSION_FIXED',
  PerSessionMinute = 'PER_SESSION_MINUTE',
  PerPlayerFixed = 'PER_PLAYER_FIXED',
  PerPlayerMinute = 'PER_PLAYER_MINUTE',
  PerSessionFixedStrict = 'PER_SESSION_FIXED_STRICT',
  PerSessionMinuteStrict = 'PER_SESSION_MINUTE_STRICT',
  PerPlayerFixedStrict = 'PER_PLAYER_FIXED_STRICT',
  PerPlayerMinuteStrict = 'PER_PLAYER_MINUTE_STRICT',
  PerPlayerProgressive = 'PER_PLAYER_PROGRESSIVE',
  PerPlayerProgressiveStrict = 'PER_PLAYER_PROGRESSIVE_STRICT',
  PerSessionProgressive = 'PER_SESSION_PROGRESSIVE',
  PerSessionProgressiveStrict = 'PER_SESSION_PROGRESSIVE_STRICT'
}

/** A filter to be used against RoyaltyVariantType fields. All fields are combined with a logical ‘and.’ */
export type RoyaltyVariantTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<RoyaltyVariantType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<RoyaltyVariantType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<RoyaltyVariantType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<RoyaltyVariantType>;
  /** Included in the specified list. */
  in?: Maybe<Array<RoyaltyVariantType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<RoyaltyVariantType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<RoyaltyVariantType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<RoyaltyVariantType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<RoyaltyVariantType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<RoyaltyVariantType>;
};

/** All input for the `setProductName` mutation. */
export type SetProductNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  productId: Scalars['UUID'];
  locale: Scalars['LocaleType'];
  productName: Scalars['NameType'];
};

/** The output of our `setProductName` mutation. */
export type SetProductNamePayload = {
  __typename?: 'SetProductNamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Product`. May be used by Relay 1. */
  productEdge?: Maybe<ProductsEdge>;
};


/** The output of our `setProductName` mutation. */
export type SetProductNamePayloadProductEdgeArgs = {
  orderBy?: Maybe<Array<ProductsOrderBy>>;
};

/** All input for the `setUserDisabled` mutation. */
export type SetUserDisabledInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDisabledInput: Scalars['Boolean'];
};

/** The output of our `setUserDisabled` mutation. */
export type SetUserDisabledPayload = {
  __typename?: 'SetUserDisabledPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['String']>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['String']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['String']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form. (live)
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. (live) */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. (live) */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRate`. (live) */
  allCurrencyExchangeRates?: Maybe<CurrencyExchangeRatesConnection>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRatesFiltered`. (live) */
  allCurrencyExchangeRatesFiltereds?: Maybe<CurrencyExchangeRatesFilteredsConnection>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRatesFilteredYearAgg`. (live) */
  allCurrencyExchangeRatesFilteredYearAggs?: Maybe<CurrencyExchangeRatesFilteredYearAggsConnection>;
  /** Reads and enables pagination through a set of `CurrencyExchangeRatesFilteredYearMonthAgg`. (live) */
  allCurrencyExchangeRatesFilteredYearMonthAggs?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `Customer`. (live) */
  allCustomers?: Maybe<CustomersConnection>;
  /** Reads and enables pagination through a set of `LeaderboardPlayerScore`. (live) */
  allLeaderboardPlayerScores?: Maybe<LeaderboardPlayerScoresConnection>;
  /** Reads and enables pagination through a set of `LeaderboardRound`. (live) */
  allLeaderboardRounds?: Maybe<LeaderboardRoundsConnection>;
  /** Reads and enables pagination through a set of `LeaderboardSession`. (live) */
  allLeaderboardSessions?: Maybe<LeaderboardSessionsConnection>;
  /** Reads and enables pagination through a set of `LeaderboardTeamScore`. (live) */
  allLeaderboardTeamScores?: Maybe<LeaderboardTeamScoresConnection>;
  /** Reads and enables pagination through a set of `License`. (live) */
  allLicenses?: Maybe<LicensesConnection>;
  /** Reads and enables pagination through a set of `LicenseEvent`. (live) */
  allLicenseEvents?: Maybe<LicenseEventsConnection>;
  /** Reads and enables pagination through a set of `LicenseSession`. (live) */
  allLicenseSessions?: Maybe<LicenseSessionsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionComment`. (live) */
  allLicenseSessionComments?: Maybe<LicenseSessionCommentsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueDateAgg`. (live) */
  allLicenseSessionCustomerVenueDateAggs?: Maybe<LicenseSessionCustomerVenueDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueDatePriceAgg`. (live) */
  allLicenseSessionCustomerVenueDatePriceAggs?: Maybe<LicenseSessionCustomerVenueDatePriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueDateRoyaltyAgg`. (live) */
  allLicenseSessionCustomerVenueDateRoyaltyAggs?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueProductDateAgg`. (live) */
  allLicenseSessionCustomerVenueProductDateAggs?: Maybe<LicenseSessionCustomerVenueProductDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueProductYearAgg`. (live) */
  allLicenseSessionCustomerVenueProductYearAggs?: Maybe<LicenseSessionCustomerVenueProductYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueProductYearMonthAgg`. (live) */
  allLicenseSessionCustomerVenueProductYearMonthAggs?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearAgg`. (live) */
  allLicenseSessionCustomerVenueYearAggs?: Maybe<LicenseSessionCustomerVenueYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearMonthAgg`. (live) */
  allLicenseSessionCustomerVenueYearMonthAggs?: Maybe<LicenseSessionCustomerVenueYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearMonthPriceAgg`. (live) */
  allLicenseSessionCustomerVenueYearMonthPriceAggs?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearMonthRoyaltyAgg`. (live) */
  allLicenseSessionCustomerVenueYearMonthRoyaltyAggs?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearPriceAgg`. (live) */
  allLicenseSessionCustomerVenueYearPriceAggs?: Maybe<LicenseSessionCustomerVenueYearPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionCustomerVenueYearRoyaltyAgg`. (live) */
  allLicenseSessionCustomerVenueYearRoyaltyAggs?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionDateAgg`. (live) */
  allLicenseSessionDateAggs?: Maybe<LicenseSessionDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionDatePriceAgg`. (live) */
  allLicenseSessionDatePriceAggs?: Maybe<LicenseSessionDatePriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionDateRoyaltyAgg`. (live) */
  allLicenseSessionDateRoyaltyAggs?: Maybe<LicenseSessionDateRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionProductDateAgg`. (live) */
  allLicenseSessionProductDateAggs?: Maybe<LicenseSessionProductDateAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionProductYearAgg`. (live) */
  allLicenseSessionProductYearAggs?: Maybe<LicenseSessionProductYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionProductYearMonthAgg`. (live) */
  allLicenseSessionProductYearMonthAggs?: Maybe<LicenseSessionProductYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearAgg`. (live) */
  allLicenseSessionYearAggs?: Maybe<LicenseSessionYearAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearMonthAgg`. (live) */
  allLicenseSessionYearMonthAggs?: Maybe<LicenseSessionYearMonthAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearMonthPriceAgg`. (live) */
  allLicenseSessionYearMonthPriceAggs?: Maybe<LicenseSessionYearMonthPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearMonthRoyaltyAgg`. (live) */
  allLicenseSessionYearMonthRoyaltyAggs?: Maybe<LicenseSessionYearMonthRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearPriceAgg`. (live) */
  allLicenseSessionYearPriceAggs?: Maybe<LicenseSessionYearPriceAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionYearRoyaltyAgg`. (live) */
  allLicenseSessionYearRoyaltyAggs?: Maybe<LicenseSessionYearRoyaltyAggsConnection>;
  /** Reads and enables pagination through a set of `LicenseSessionsAllCurrency`. (live) */
  allLicenseSessionsAllCurrencies?: Maybe<LicenseSessionsAllCurrenciesConnection>;
  /** Reads and enables pagination through a set of `LicenseV2`. (live) */
  allLicenseV2S?: Maybe<LicenseV2SConnection>;
  /** Reads and enables pagination through a set of `LicenseV2Event`. (live) */
  allLicenseV2Events?: Maybe<LicenseV2EventsConnection>;
  /** Reads and enables pagination through a set of `LoadedLicenseSession`. (live) */
  allLoadedLicenseSessions?: Maybe<LoadedLicenseSessionsConnection>;
  /** Reads and enables pagination through a set of `Product`. (live) */
  allProducts?: Maybe<ProductsConnection>;
  /** Reads and enables pagination through a set of `ReportAllProduct`. (live) */
  allReportAllProducts?: Maybe<ReportAllProductsConnection>;
  /** Reads and enables pagination through a set of `ReportAllVenue`. (live) */
  allReportAllVenues?: Maybe<ReportAllVenuesConnection>;
  /** Reads and enables pagination through a set of `ReportProductUsage`. (live) */
  allReportProductUsages?: Maybe<ReportProductUsagesConnection>;
  /** Reads and enables pagination through a set of `Timezone`. (live) */
  allTimezones?: Maybe<TimezonesConnection>;
  /** Reads and enables pagination through a set of `User`. (live) */
  allUsers?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `UserCustomer`. (live) */
  allUserCustomers?: Maybe<UserCustomersConnection>;
  /** Reads and enables pagination through a set of `UserFile`. (live) */
  allUserFiles?: Maybe<UserFilesConnection>;
  /** Reads and enables pagination through a set of `Venue`. (live) */
  allVenues?: Maybe<VenuesConnection>;
  /**  (live) */
  currencyExchangeRateById?: Maybe<CurrencyExchangeRate>;
  /**  (live) */
  currencyExchangeRateByYearAndMonthAndDayAndCurrency?: Maybe<CurrencyExchangeRate>;
  /**  (live) */
  customerById?: Maybe<Customer>;
  /**  (live) */
  customerByName?: Maybe<Customer>;
  /**  (live) */
  leaderboardPlayerScoreById?: Maybe<LeaderboardPlayerScore>;
  /**  (live) */
  leaderboardPlayerScoreByLeaderboardScoreIdAndPlayerId?: Maybe<LeaderboardPlayerScore>;
  /**  (live) */
  leaderboardRoundById?: Maybe<LeaderboardRound>;
  /**  (live) */
  leaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeId?: Maybe<LeaderboardRound>;
  /**  (live) */
  leaderboardSessionById?: Maybe<LeaderboardSession>;
  /**  (live) */
  leaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionId?: Maybe<LeaderboardSession>;
  /**  (live) */
  leaderboardTeamScoreById?: Maybe<LeaderboardTeamScore>;
  /**  (live) */
  leaderboardTeamScoreByLeaderboardRoundIdAndTeamName?: Maybe<LeaderboardTeamScore>;
  /**  (live) */
  licenseById?: Maybe<License>;
  /**  (live) */
  licenseByLicenseKey?: Maybe<License>;
  /**  (live) */
  licenseEventById?: Maybe<LicenseEvent>;
  /**  (live) */
  licenseSessionById?: Maybe<LicenseSession>;
  /**  (live) */
  licenseSessionCommentById?: Maybe<LicenseSessionComment>;
  /**  (live) */
  licenseV2ById?: Maybe<LicenseV2>;
  /**  (live) */
  licenseV2ByLicenseKey?: Maybe<LicenseV2>;
  /**  (live) */
  licenseV2EventById?: Maybe<LicenseV2Event>;
  /**  (live) */
  loadedLicenseSessionById?: Maybe<LoadedLicenseSession>;
  /**  (live) */
  loadedLicenseSessionByYearAndMonthAndDay?: Maybe<LoadedLicenseSession>;
  /**  (live) */
  productById?: Maybe<Product>;
  /**  (live) */
  timezoneByName?: Maybe<Timezone>;
  /**  (live) */
  userById?: Maybe<User>;
  /**  (live) */
  userByName?: Maybe<User>;
  /**  (live) */
  userCustomerByUserId?: Maybe<UserCustomer>;
  /**  (live) */
  userFileById?: Maybe<UserFile>;
  /**  (live) */
  venueById?: Maybe<Venue>;
  /**  (live) */
  venueByCustomerIdAndCountryAndCityAndLocation?: Maybe<Venue>;
  /** Calculates the license price based on the session parameters (live) */
  calculateLicensePrice?: Maybe<Scalars['PriceNumberType']>;
  /** Calculates the total price based on the session parameters (live) */
  calculateTotalPrice?: Maybe<Scalars['PriceNumberType']>;
  /** Calculates the total royalty based on the session parameters (live) */
  calculateTotalRoyalty?: Maybe<Scalars['PriceNumberType']>;
  /** Gets the user who was identified by JWT. (live) */
  currentUser?: Maybe<User>;
  /** Gets the user id who was identified by JWT. (live) */
  currentUserId?: Maybe<Scalars['UUID']>;
  /**  (live) */
  getAllCustomerTags?: Maybe<GetAllCustomerTagsConnection>;
  /**  (live) */
  getMissingExchangeRateDates?: Maybe<GetMissingExchangeRateDatesConnection>;
  /**  (live) */
  leaderboardExtraScoreMax?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  /**  (live) */
  leaderboardExtraScoreMin?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  /**  (live) */
  leaderboardExtraScoreSum?: Maybe<Array<Maybe<LeaderboardExtraScore>>>;
  /** Returns product name based on the current locale. (live) */
  productNameFromHstore?: Maybe<Scalars['String']>;
  /**  (live) */
  userSelfAttachedCustomerId?: Maybe<Scalars['UUID']>;
  /** Reads a single `CurrencyExchangeRate` using its globally unique `ID`. (live) */
  currencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  /** Reads a single `Customer` using its globally unique `ID`. (live) */
  customer?: Maybe<Customer>;
  /** Reads a single `LeaderboardPlayerScore` using its globally unique `ID`. (live) */
  leaderboardPlayerScore?: Maybe<LeaderboardPlayerScore>;
  /** Reads a single `LeaderboardRound` using its globally unique `ID`. (live) */
  leaderboardRound?: Maybe<LeaderboardRound>;
  /** Reads a single `LeaderboardSession` using its globally unique `ID`. (live) */
  leaderboardSession?: Maybe<LeaderboardSession>;
  /** Reads a single `LeaderboardTeamScore` using its globally unique `ID`. (live) */
  leaderboardTeamScore?: Maybe<LeaderboardTeamScore>;
  /** Reads a single `License` using its globally unique `ID`. (live) */
  license?: Maybe<License>;
  /** Reads a single `LicenseEvent` using its globally unique `ID`. (live) */
  licenseEvent?: Maybe<LicenseEvent>;
  /** Reads a single `LicenseSession` using its globally unique `ID`. (live) */
  licenseSession?: Maybe<LicenseSession>;
  /** Reads a single `LicenseSessionComment` using its globally unique `ID`. (live) */
  licenseSessionComment?: Maybe<LicenseSessionComment>;
  /** Reads a single `LicenseV2` using its globally unique `ID`. (live) */
  licenseV2?: Maybe<LicenseV2>;
  /** Reads a single `LicenseV2Event` using its globally unique `ID`. (live) */
  licenseV2Event?: Maybe<LicenseV2Event>;
  /** Reads a single `LoadedLicenseSession` using its globally unique `ID`. (live) */
  loadedLicenseSession?: Maybe<LoadedLicenseSession>;
  /** Reads a single `Product` using its globally unique `ID`. (live) */
  product?: Maybe<Product>;
  /** Reads a single `Timezone` using its globally unique `ID`. (live) */
  timezone?: Maybe<Timezone>;
  /** Reads a single `User` using its globally unique `ID`. (live) */
  user?: Maybe<User>;
  /** Reads a single `UserCustomer` using its globally unique `ID`. (live) */
  userCustomer?: Maybe<UserCustomer>;
  /** Reads a single `UserFile` using its globally unique `ID`. (live) */
  userFile?: Maybe<UserFile>;
  /** Reads a single `Venue` using its globally unique `ID`. (live) */
  venue?: Maybe<Venue>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNodeArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCurrencyExchangeRatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesOrderBy>>;
  condition?: Maybe<CurrencyExchangeRateCondition>;
  filter?: Maybe<CurrencyExchangeRateFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCurrencyExchangeRatesFilteredsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredsOrderBy>>;
  condition?: Maybe<CurrencyExchangeRatesFilteredCondition>;
  filter?: Maybe<CurrencyExchangeRatesFilteredFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCurrencyExchangeRatesFilteredYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredYearAggsOrderBy>>;
  condition?: Maybe<CurrencyExchangeRatesFilteredYearAggCondition>;
  filter?: Maybe<CurrencyExchangeRatesFilteredYearAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCurrencyExchangeRatesFilteredYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CurrencyExchangeRatesFilteredYearMonthAggsOrderBy>>;
  condition?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggCondition>;
  filter?: Maybe<CurrencyExchangeRatesFilteredYearMonthAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomersOrderBy>>;
  condition?: Maybe<CustomerCondition>;
  filter?: Maybe<CustomerFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLeaderboardPlayerScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardPlayerScoresOrderBy>>;
  condition?: Maybe<LeaderboardPlayerScoreCondition>;
  filter?: Maybe<LeaderboardPlayerScoreFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLeaderboardRoundsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardRoundsOrderBy>>;
  condition?: Maybe<LeaderboardRoundCondition>;
  filter?: Maybe<LeaderboardRoundFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLeaderboardSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardSessionsOrderBy>>;
  condition?: Maybe<LeaderboardSessionCondition>;
  filter?: Maybe<LeaderboardSessionFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLeaderboardTeamScoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeaderboardTeamScoresOrderBy>>;
  condition?: Maybe<LeaderboardTeamScoreCondition>;
  filter?: Maybe<LeaderboardTeamScoreFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicensesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicensesOrderBy>>;
  condition?: Maybe<LicenseCondition>;
  filter?: Maybe<LicenseFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
  condition?: Maybe<LicenseEventCondition>;
  filter?: Maybe<LicenseEventFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
  condition?: Maybe<LicenseSessionCommentCondition>;
  filter?: Maybe<LicenseSessionCommentFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueDateAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueDateAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueDatePriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueDatePriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueDatePriceAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueDatePriceAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueDateRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueDateRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueDateRoyaltyAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueProductDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueProductDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueProductDateAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueProductDateAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueProductYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueProductYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueProductYearAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueProductYearAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueProductYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueProductYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueProductYearMonthAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearMonthAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueYearMonthPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearMonthPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearMonthPriceAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueYearMonthRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearMonthRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueYearPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearPriceAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearPriceAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionCustomerVenueYearRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCustomerVenueYearRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionCustomerVenueYearRoyaltyAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionDateAggCondition>;
  filter?: Maybe<LicenseSessionDateAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionDatePriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionDatePriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionDatePriceAggCondition>;
  filter?: Maybe<LicenseSessionDatePriceAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionDateRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionDateRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionDateRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionDateRoyaltyAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionProductDateAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionProductDateAggsOrderBy>>;
  condition?: Maybe<LicenseSessionProductDateAggCondition>;
  filter?: Maybe<LicenseSessionProductDateAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionProductYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionProductYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionProductYearAggCondition>;
  filter?: Maybe<LicenseSessionProductYearAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionProductYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionProductYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionProductYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionProductYearMonthAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionYearAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearAggCondition>;
  filter?: Maybe<LicenseSessionYearAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionYearMonthAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearMonthAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearMonthAggCondition>;
  filter?: Maybe<LicenseSessionYearMonthAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionYearMonthPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearMonthPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearMonthPriceAggCondition>;
  filter?: Maybe<LicenseSessionYearMonthPriceAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionYearMonthRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearMonthRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearMonthRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionYearMonthRoyaltyAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionYearPriceAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearPriceAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearPriceAggCondition>;
  filter?: Maybe<LicenseSessionYearPriceAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionYearRoyaltyAggsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionYearRoyaltyAggsOrderBy>>;
  condition?: Maybe<LicenseSessionYearRoyaltyAggCondition>;
  filter?: Maybe<LicenseSessionYearRoyaltyAggFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseSessionsAllCurrenciesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsAllCurrenciesOrderBy>>;
  condition?: Maybe<LicenseSessionsAllCurrencyCondition>;
  filter?: Maybe<LicenseSessionsAllCurrencyFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseV2SArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2SOrderBy>>;
  condition?: Maybe<LicenseV2Condition>;
  filter?: Maybe<LicenseV2Filter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLicenseV2EventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
  condition?: Maybe<LicenseV2EventCondition>;
  filter?: Maybe<LicenseV2EventFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllLoadedLicenseSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LoadedLicenseSessionsOrderBy>>;
  condition?: Maybe<LoadedLicenseSessionCondition>;
  filter?: Maybe<LoadedLicenseSessionFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllReportAllProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReportAllProductsOrderBy>>;
  condition?: Maybe<ReportAllProductCondition>;
  filter?: Maybe<ReportAllProductFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllReportAllVenuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReportAllVenuesOrderBy>>;
  condition?: Maybe<ReportAllVenueCondition>;
  filter?: Maybe<ReportAllVenueFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllReportProductUsagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReportProductUsagesOrderBy>>;
  condition?: Maybe<ReportProductUsageCondition>;
  filter?: Maybe<ReportProductUsageFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTimezonesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TimezonesOrderBy>>;
  condition?: Maybe<TimezoneCondition>;
  filter?: Maybe<TimezoneFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllUserCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserCustomersOrderBy>>;
  condition?: Maybe<UserCustomerCondition>;
  filter?: Maybe<UserCustomerFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllUserFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserFilesOrderBy>>;
  condition?: Maybe<UserFileCondition>;
  filter?: Maybe<UserFileFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllVenuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VenuesOrderBy>>;
  condition?: Maybe<VenueCondition>;
  filter?: Maybe<VenueFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCurrencyExchangeRateByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  currency: Scalars['CurrencyType'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCustomerByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCustomerByNameArgs = {
  name: Scalars['NameType'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardPlayerScoreByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdArgs = {
  leaderboardScoreId: Scalars['UUID'];
  playerId: Scalars['String'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardRoundByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdArgs = {
  leaderboardSessionId: Scalars['UUID'];
  roundNum: Scalars['Int'];
  modeId: Scalars['String'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardSessionByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdArgs = {
  licenseSessionId: Scalars['UUID'];
  reportedLeaderboardSessionId: Scalars['String'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardTeamScoreByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameArgs = {
  leaderboardRoundId: Scalars['UUID'];
  teamName: Scalars['String'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseByLicenseKeyArgs = {
  licenseKey: Scalars['LicenseKeyType'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseEventByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseSessionByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseSessionCommentByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseV2ByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseV2ByLicenseKeyArgs = {
  licenseKey: Scalars['LicenseKeyType'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseV2EventByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLoadedLicenseSessionByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLoadedLicenseSessionByYearAndMonthAndDayArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProductByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTimezoneByNameArgs = {
  name: Scalars['String'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserByNameArgs = {
  name: Scalars['NameType'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserCustomerByUserIdArgs = {
  userId: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFileByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionVenueByIdArgs = {
  id: Scalars['UUID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionVenueByCustomerIdAndCountryAndCityAndLocationArgs = {
  customerId: Scalars['UUID'];
  country: Scalars['String'];
  city: Scalars['String'];
  location: Scalars['NameType'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCalculateLicensePriceArgs = {
  licenseId: Scalars['UUID'];
  sessionStartTime: Scalars['Datetime'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCalculateTotalPriceArgs = {
  price?: Maybe<PriceDefinitionInput>;
  sessionPlayers?: Maybe<Scalars['Int']>;
  sessionDurationMin?: Maybe<Scalars['Float']>;
  sessionIsPaid?: Maybe<Scalars['Boolean']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCalculateTotalRoyaltyArgs = {
  royalty?: Maybe<RoyaltyDefinitionInput>;
  priceTotal?: Maybe<Scalars['PriceNumberType']>;
  sessionPlayers?: Maybe<Scalars['Int']>;
  sessionDurationMin?: Maybe<Scalars['Float']>;
  sessionIsPaid?: Maybe<Scalars['Boolean']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGetAllCustomerTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<StringFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGetMissingExchangeRateDatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<DatetimeFilter>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardExtraScoreMaxArgs = {
  a?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  b?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardExtraScoreMinArgs = {
  a?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  b?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardExtraScoreSumArgs = {
  a?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
  b?: Maybe<Array<Maybe<LeaderboardExtraScoreInput>>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProductNameFromHstoreArgs = {
  nameI18N?: Maybe<Scalars['KeyValueHash']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCurrencyExchangeRateArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCustomerArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardPlayerScoreArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardRoundArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardSessionArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLeaderboardTeamScoreArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseEventArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseSessionArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseSessionCommentArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseV2Args = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLicenseV2EventArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLoadedLicenseSessionArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProductArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTimezoneArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserCustomerArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFileArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 * 
 * #### Live Queries
 * 
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 * 
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 * 
 * Live queries can be very expensive, so try and keep them small and focussed.
 * 
 * #### Events
 * 
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionVenueArgs = {
  nodeId: Scalars['ID'];
};

export type Timezone = Node & {
  __typename?: 'Timezone';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  name: Scalars['String'];
  utcSecondsOffset?: Maybe<Scalars['BigFloat']>;
  isDst?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByTimezone: UsersConnection;
  /** Reads and enables pagination through a set of `Venue`. */
  venuesByTimezone: VenuesConnection;
};


export type TimezoneUsersByTimezoneArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type TimezoneVenuesByTimezoneArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VenuesOrderBy>>;
  condition?: Maybe<VenueCondition>;
  filter?: Maybe<VenueFilter>;
};

/**
 * A condition to be used against `Timezone` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TimezoneCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utcSecondsOffset` field. */
  utcSecondsOffset?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `isDst` field. */
  isDst?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against `Timezone` object types. All fields are combined with a logical ‘and.’ */
export type TimezoneFilter = {
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `utcSecondsOffset` field. */
  utcSecondsOffset?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `isDst` field. */
  isDst?: Maybe<BooleanFilter>;
  /** Filter by the object’s `usersByTimezone` relation. */
  usersByTimezone?: Maybe<TimezoneToManyUserFilter>;
  /** Some related `usersByTimezone` exist. */
  usersByTimezoneExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `venuesByTimezone` relation. */
  venuesByTimezone?: Maybe<TimezoneToManyVenueFilter>;
  /** Some related `venuesByTimezone` exist. */
  venuesByTimezoneExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TimezoneFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TimezoneFilter>>;
  /** Negates the expression. */
  not?: Maybe<TimezoneFilter>;
};

/** An input for mutations affecting `Timezone` */
export type TimezoneInput = {
  name: Scalars['String'];
  utcSecondsOffset?: Maybe<Scalars['BigFloat']>;
  isDst?: Maybe<Scalars['Boolean']>;
};

/** Represents an update to a `Timezone`. Fields that are set will be updated. */
export type TimezonePatch = {
  name?: Maybe<Scalars['String']>;
  utcSecondsOffset?: Maybe<Scalars['BigFloat']>;
  isDst?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `Timezone` values. */
export type TimezonesConnection = {
  __typename?: 'TimezonesConnection';
  /** A list of `Timezone` objects. */
  nodes: Array<Maybe<Timezone>>;
  /** A list of edges which contains the `Timezone` and cursor to aid in pagination. */
  edges: Array<TimezonesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Timezone` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Timezone` edge in the connection. */
export type TimezonesEdge = {
  __typename?: 'TimezonesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Timezone` at the end of the edge. */
  node?: Maybe<Timezone>;
};

/** Methods to use when ordering `Timezone`. */
export enum TimezonesOrderBy {
  Natural = 'NATURAL',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UtcSecondsOffsetAsc = 'UTC_SECONDS_OFFSET_ASC',
  UtcSecondsOffsetDesc = 'UTC_SECONDS_OFFSET_DESC',
  IsDstAsc = 'IS_DST_ASC',
  IsDstDesc = 'IS_DST_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `User` object types. All fields are combined with a logical ‘and.’ */
export type TimezoneToManyUserFilter = {
  /** Every related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserFilter>;
  /** Some related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserFilter>;
  /** No related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserFilter>;
};

/** A filter to be used against many `Venue` object types. All fields are combined with a logical ‘and.’ */
export type TimezoneToManyVenueFilter = {
  /** Every related `Venue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<VenueFilter>;
  /** Some related `Venue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<VenueFilter>;
  /** No related `Venue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<VenueFilter>;
};

/** All input for the `updateCurrencyExchangeRateById` mutation. */
export type UpdateCurrencyExchangeRateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CurrencyExchangeRate` being updated. */
  currencyExchangeRatePatch: CurrencyExchangeRatePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateCurrencyExchangeRateByYearAndMonthAndDayAndCurrency` mutation. */
export type UpdateCurrencyExchangeRateByYearAndMonthAndDayAndCurrencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CurrencyExchangeRate` being updated. */
  currencyExchangeRatePatch: CurrencyExchangeRatePatch;
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  currency: Scalars['CurrencyType'];
};

/** All input for the `updateCurrencyExchangeRate` mutation. */
export type UpdateCurrencyExchangeRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CurrencyExchangeRate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CurrencyExchangeRate` being updated. */
  currencyExchangeRatePatch: CurrencyExchangeRatePatch;
};

/** The output of our update `CurrencyExchangeRate` mutation. */
export type UpdateCurrencyExchangeRatePayload = {
  __typename?: 'UpdateCurrencyExchangeRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrencyExchangeRate` that was updated by this mutation. */
  currencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CurrencyExchangeRate`. May be used by Relay 1. */
  currencyExchangeRateEdge?: Maybe<CurrencyExchangeRatesEdge>;
};


/** The output of our update `CurrencyExchangeRate` mutation. */
export type UpdateCurrencyExchangeRatePayloadCurrencyExchangeRateEdgeArgs = {
  orderBy?: Maybe<Array<CurrencyExchangeRatesOrderBy>>;
};

/** All input for the `updateCustomerById` mutation. */
export type UpdateCustomerByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Customer` being updated. */
  customerPatch: CustomerPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateCustomerByName` mutation. */
export type UpdateCustomerByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Customer` being updated. */
  customerPatch: CustomerPatch;
  name: Scalars['NameType'];
};

/** All input for the `updateCustomer` mutation. */
export type UpdateCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Customer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Customer` being updated. */
  customerPatch: CustomerPatch;
};

/** The output of our update `Customer` mutation. */
export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Customer` that was updated by this mutation. */
  customer?: Maybe<Customer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Customer`. May be used by Relay 1. */
  customerEdge?: Maybe<CustomersEdge>;
};


/** The output of our update `Customer` mutation. */
export type UpdateCustomerPayloadCustomerEdgeArgs = {
  orderBy?: Maybe<Array<CustomersOrderBy>>;
};

/** All input for the `updateLeaderboardPlayerScoreById` mutation. */
export type UpdateLeaderboardPlayerScoreByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardPlayerScore` being updated. */
  leaderboardPlayerScorePatch: LeaderboardPlayerScorePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerId` mutation. */
export type UpdateLeaderboardPlayerScoreByLeaderboardScoreIdAndPlayerIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardPlayerScore` being updated. */
  leaderboardPlayerScorePatch: LeaderboardPlayerScorePatch;
  leaderboardScoreId: Scalars['UUID'];
  playerId: Scalars['String'];
};

/** All input for the `updateLeaderboardPlayerScore` mutation. */
export type UpdateLeaderboardPlayerScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardPlayerScore` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeaderboardPlayerScore` being updated. */
  leaderboardPlayerScorePatch: LeaderboardPlayerScorePatch;
};

/** The output of our update `LeaderboardPlayerScore` mutation. */
export type UpdateLeaderboardPlayerScorePayload = {
  __typename?: 'UpdateLeaderboardPlayerScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardPlayerScore` that was updated by this mutation. */
  leaderboardPlayerScore?: Maybe<LeaderboardPlayerScore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardTeamScore` that is related to this `LeaderboardPlayerScore`. */
  leaderboardTeamScoreByLeaderboardScoreId?: Maybe<LeaderboardTeamScore>;
  /** An edge for our `LeaderboardPlayerScore`. May be used by Relay 1. */
  leaderboardPlayerScoreEdge?: Maybe<LeaderboardPlayerScoresEdge>;
};


/** The output of our update `LeaderboardPlayerScore` mutation. */
export type UpdateLeaderboardPlayerScorePayloadLeaderboardPlayerScoreEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardPlayerScoresOrderBy>>;
};

/** All input for the `updateLeaderboardRoundById` mutation. */
export type UpdateLeaderboardRoundByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardRound` being updated. */
  leaderboardRoundPatch: LeaderboardRoundPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeId` mutation. */
export type UpdateLeaderboardRoundByLeaderboardSessionIdAndRoundNumAndModeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardRound` being updated. */
  leaderboardRoundPatch: LeaderboardRoundPatch;
  leaderboardSessionId: Scalars['UUID'];
  roundNum: Scalars['Int'];
  modeId: Scalars['String'];
};

/** All input for the `updateLeaderboardRound` mutation. */
export type UpdateLeaderboardRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardRound` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeaderboardRound` being updated. */
  leaderboardRoundPatch: LeaderboardRoundPatch;
};

/** The output of our update `LeaderboardRound` mutation. */
export type UpdateLeaderboardRoundPayload = {
  __typename?: 'UpdateLeaderboardRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardRound` that was updated by this mutation. */
  leaderboardRound?: Maybe<LeaderboardRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardSession` that is related to this `LeaderboardRound`. */
  leaderboardSessionByLeaderboardSessionId?: Maybe<LeaderboardSession>;
  /** An edge for our `LeaderboardRound`. May be used by Relay 1. */
  leaderboardRoundEdge?: Maybe<LeaderboardRoundsEdge>;
};


/** The output of our update `LeaderboardRound` mutation. */
export type UpdateLeaderboardRoundPayloadLeaderboardRoundEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardRoundsOrderBy>>;
};

/** All input for the `updateLeaderboardSessionById` mutation. */
export type UpdateLeaderboardSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardSession` being updated. */
  leaderboardSessionPatch: LeaderboardSessionPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionId` mutation. */
export type UpdateLeaderboardSessionByLicenseSessionIdAndReportedLeaderboardSessionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardSession` being updated. */
  leaderboardSessionPatch: LeaderboardSessionPatch;
  licenseSessionId: Scalars['UUID'];
  reportedLeaderboardSessionId: Scalars['String'];
};

/** All input for the `updateLeaderboardSession` mutation. */
export type UpdateLeaderboardSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardSession` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeaderboardSession` being updated. */
  leaderboardSessionPatch: LeaderboardSessionPatch;
};

/** The output of our update `LeaderboardSession` mutation. */
export type UpdateLeaderboardSessionPayload = {
  __typename?: 'UpdateLeaderboardSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardSession` that was updated by this mutation. */
  leaderboardSession?: Maybe<LeaderboardSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseSession` that is related to this `LeaderboardSession`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** An edge for our `LeaderboardSession`. May be used by Relay 1. */
  leaderboardSessionEdge?: Maybe<LeaderboardSessionsEdge>;
};


/** The output of our update `LeaderboardSession` mutation. */
export type UpdateLeaderboardSessionPayloadLeaderboardSessionEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardSessionsOrderBy>>;
};

/** All input for the `updateLeaderboardTeamScoreById` mutation. */
export type UpdateLeaderboardTeamScoreByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardTeamScore` being updated. */
  leaderboardTeamScorePatch: LeaderboardTeamScorePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLeaderboardTeamScoreByLeaderboardRoundIdAndTeamName` mutation. */
export type UpdateLeaderboardTeamScoreByLeaderboardRoundIdAndTeamNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeaderboardTeamScore` being updated. */
  leaderboardTeamScorePatch: LeaderboardTeamScorePatch;
  leaderboardRoundId: Scalars['UUID'];
  teamName: Scalars['String'];
};

/** All input for the `updateLeaderboardTeamScore` mutation. */
export type UpdateLeaderboardTeamScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeaderboardTeamScore` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeaderboardTeamScore` being updated. */
  leaderboardTeamScorePatch: LeaderboardTeamScorePatch;
};

/** The output of our update `LeaderboardTeamScore` mutation. */
export type UpdateLeaderboardTeamScorePayload = {
  __typename?: 'UpdateLeaderboardTeamScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeaderboardTeamScore` that was updated by this mutation. */
  leaderboardTeamScore?: Maybe<LeaderboardTeamScore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LeaderboardRound` that is related to this `LeaderboardTeamScore`. */
  leaderboardRoundByLeaderboardRoundId?: Maybe<LeaderboardRound>;
  /** An edge for our `LeaderboardTeamScore`. May be used by Relay 1. */
  leaderboardTeamScoreEdge?: Maybe<LeaderboardTeamScoresEdge>;
};


/** The output of our update `LeaderboardTeamScore` mutation. */
export type UpdateLeaderboardTeamScorePayloadLeaderboardTeamScoreEdgeArgs = {
  orderBy?: Maybe<Array<LeaderboardTeamScoresOrderBy>>;
};

/** All input for the `updateLicenseById` mutation. */
export type UpdateLicenseByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `License` being updated. */
  licensePatch: LicensePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLicenseByLicenseKey` mutation. */
export type UpdateLicenseByLicenseKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `License` being updated. */
  licensePatch: LicensePatch;
  licenseKey: Scalars['LicenseKeyType'];
};

/** All input for the `updateLicenseEventById` mutation. */
export type UpdateLicenseEventByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LicenseEvent` being updated. */
  licenseEventPatch: LicenseEventPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLicenseEvent` mutation. */
export type UpdateLicenseEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LicenseEvent` being updated. */
  licenseEventPatch: LicenseEventPatch;
};

/** The output of our update `LicenseEvent` mutation. */
export type UpdateLicenseEventPayload = {
  __typename?: 'UpdateLicenseEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseEvent` that was updated by this mutation. */
  licenseEvent?: Maybe<LicenseEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `License` that is related to this `LicenseEvent`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseEvent`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** An edge for our `LicenseEvent`. May be used by Relay 1. */
  licenseEventEdge?: Maybe<LicenseEventsEdge>;
};


/** The output of our update `LicenseEvent` mutation. */
export type UpdateLicenseEventPayloadLicenseEventEdgeArgs = {
  orderBy?: Maybe<Array<LicenseEventsOrderBy>>;
};

/** All input for the `updateLicense` mutation. */
export type UpdateLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `License` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `License` being updated. */
  licensePatch: LicensePatch;
};

/** The output of our update `License` mutation. */
export type UpdateLicensePayload = {
  __typename?: 'UpdateLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `License` that was updated by this mutation. */
  license?: Maybe<License>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Venue` that is related to this `License`. */
  venueByVenueId?: Maybe<Venue>;
  /** Reads a single `Product` that is related to this `License`. */
  productByProductId?: Maybe<Product>;
  /** An edge for our `License`. May be used by Relay 1. */
  licenseEdge?: Maybe<LicensesEdge>;
};


/** The output of our update `License` mutation. */
export type UpdateLicensePayloadLicenseEdgeArgs = {
  orderBy?: Maybe<Array<LicensesOrderBy>>;
};

/** All input for the `updateLicenseSessionById` mutation. */
export type UpdateLicenseSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LicenseSession` being updated. */
  licenseSessionPatch: LicenseSessionPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLicenseSessionCommentById` mutation. */
export type UpdateLicenseSessionCommentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LicenseSessionComment` being updated. */
  licenseSessionCommentPatch: LicenseSessionCommentPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLicenseSessionComment` mutation. */
export type UpdateLicenseSessionCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseSessionComment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LicenseSessionComment` being updated. */
  licenseSessionCommentPatch: LicenseSessionCommentPatch;
};

/** The output of our update `LicenseSessionComment` mutation. */
export type UpdateLicenseSessionCommentPayload = {
  __typename?: 'UpdateLicenseSessionCommentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSessionComment` that was updated by this mutation. */
  licenseSessionComment?: Maybe<LicenseSessionComment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSessionComment`. */
  licenseSessionByLicenseSessionId?: Maybe<LicenseSession>;
  /** Reads a single `User` that is related to this `LicenseSessionComment`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `LicenseSessionComment`. May be used by Relay 1. */
  licenseSessionCommentEdge?: Maybe<LicenseSessionCommentsEdge>;
};


/** The output of our update `LicenseSessionComment` mutation. */
export type UpdateLicenseSessionCommentPayloadLicenseSessionCommentEdgeArgs = {
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
};

/** All input for the `updateLicenseSession` mutation. */
export type UpdateLicenseSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseSession` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LicenseSession` being updated. */
  licenseSessionPatch: LicenseSessionPatch;
};

/** The output of our update `LicenseSession` mutation. */
export type UpdateLicenseSessionPayload = {
  __typename?: 'UpdateLicenseSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseSession` that was updated by this mutation. */
  licenseSession?: Maybe<LicenseSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `License` that is related to this `LicenseSession`. */
  licenseByLicenseId?: Maybe<License>;
  /** Reads a single `LicenseSession` that is related to this `LicenseSession`. */
  licenseSessionByMasterSessionId?: Maybe<LicenseSession>;
  /** Reads a single `LicenseV2` that is related to this `LicenseSession`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseSession`. */
  productByProductId?: Maybe<Product>;
  /** Reads a single `Customer` that is related to this `LicenseSession`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Venue` that is related to this `LicenseSession`. */
  venueByVenueId?: Maybe<Venue>;
  /** An edge for our `LicenseSession`. May be used by Relay 1. */
  licenseSessionEdge?: Maybe<LicenseSessionsEdge>;
};


/** The output of our update `LicenseSession` mutation. */
export type UpdateLicenseSessionPayloadLicenseSessionEdgeArgs = {
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
};

/** All input for the `updateLicenseV2ById` mutation. */
export type UpdateLicenseV2ByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LicenseV2` being updated. */
  licenseV2Patch: LicenseV2Patch;
  id: Scalars['UUID'];
};

/** All input for the `updateLicenseV2ByLicenseKey` mutation. */
export type UpdateLicenseV2ByLicenseKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LicenseV2` being updated. */
  licenseV2Patch: LicenseV2Patch;
  licenseKey: Scalars['LicenseKeyType'];
};

/** All input for the `updateLicenseV2EventById` mutation. */
export type UpdateLicenseV2EventByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LicenseV2Event` being updated. */
  licenseV2EventPatch: LicenseV2EventPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLicenseV2Event` mutation. */
export type UpdateLicenseV2EventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseV2Event` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LicenseV2Event` being updated. */
  licenseV2EventPatch: LicenseV2EventPatch;
};

/** The output of our update `LicenseV2Event` mutation. */
export type UpdateLicenseV2EventPayload = {
  __typename?: 'UpdateLicenseV2EventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2Event` that was updated by this mutation. */
  licenseV2Event?: Maybe<LicenseV2Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LicenseV2` that is related to this `LicenseV2Event`. */
  licenseV2ByLicenseV2Id?: Maybe<LicenseV2>;
  /** Reads a single `Product` that is related to this `LicenseV2Event`. */
  productByProductId?: Maybe<Product>;
  /** An edge for our `LicenseV2Event`. May be used by Relay 1. */
  licenseV2EventEdge?: Maybe<LicenseV2EventsEdge>;
};


/** The output of our update `LicenseV2Event` mutation. */
export type UpdateLicenseV2EventPayloadLicenseV2EventEdgeArgs = {
  orderBy?: Maybe<Array<LicenseV2EventsOrderBy>>;
};

/** All input for the `updateLicenseV2` mutation. */
export type UpdateLicenseV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LicenseV2` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LicenseV2` being updated. */
  licenseV2Patch: LicenseV2Patch;
};

/** The output of our update `LicenseV2` mutation. */
export type UpdateLicenseV2Payload = {
  __typename?: 'UpdateLicenseV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LicenseV2` that was updated by this mutation. */
  licenseV2?: Maybe<LicenseV2>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Venue` that is related to this `LicenseV2`. */
  venueByVenueId?: Maybe<Venue>;
  /** An edge for our `LicenseV2`. May be used by Relay 1. */
  licenseV2Edge?: Maybe<LicenseV2SEdge>;
};


/** The output of our update `LicenseV2` mutation. */
export type UpdateLicenseV2PayloadLicenseV2EdgeArgs = {
  orderBy?: Maybe<Array<LicenseV2SOrderBy>>;
};

/** All input for the `updateLoadedLicenseSessionById` mutation. */
export type UpdateLoadedLicenseSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LoadedLicenseSession` being updated. */
  loadedLicenseSessionPatch: LoadedLicenseSessionPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateLoadedLicenseSessionByYearAndMonthAndDay` mutation. */
export type UpdateLoadedLicenseSessionByYearAndMonthAndDayInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LoadedLicenseSession` being updated. */
  loadedLicenseSessionPatch: LoadedLicenseSessionPatch;
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};

/** All input for the `updateLoadedLicenseSession` mutation. */
export type UpdateLoadedLicenseSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LoadedLicenseSession` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LoadedLicenseSession` being updated. */
  loadedLicenseSessionPatch: LoadedLicenseSessionPatch;
};

/** The output of our update `LoadedLicenseSession` mutation. */
export type UpdateLoadedLicenseSessionPayload = {
  __typename?: 'UpdateLoadedLicenseSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LoadedLicenseSession` that was updated by this mutation. */
  loadedLicenseSession?: Maybe<LoadedLicenseSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `LoadedLicenseSession`. May be used by Relay 1. */
  loadedLicenseSessionEdge?: Maybe<LoadedLicenseSessionsEdge>;
};


/** The output of our update `LoadedLicenseSession` mutation. */
export type UpdateLoadedLicenseSessionPayloadLoadedLicenseSessionEdgeArgs = {
  orderBy?: Maybe<Array<LoadedLicenseSessionsOrderBy>>;
};

/** All input for the `updateProductById` mutation. */
export type UpdateProductByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Product` being updated. */
  productPatch: ProductPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateProduct` mutation. */
export type UpdateProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Product` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Product` being updated. */
  productPatch: ProductPatch;
};

/** The output of our update `Product` mutation. */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was updated by this mutation. */
  product?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Product`. May be used by Relay 1. */
  productEdge?: Maybe<ProductsEdge>;
};


/** The output of our update `Product` mutation. */
export type UpdateProductPayloadProductEdgeArgs = {
  orderBy?: Maybe<Array<ProductsOrderBy>>;
};

/** All input for the `updateTimezoneByName` mutation. */
export type UpdateTimezoneByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Timezone` being updated. */
  timezonePatch: TimezonePatch;
  name: Scalars['String'];
};

/** All input for the `updateTimezone` mutation. */
export type UpdateTimezoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Timezone` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Timezone` being updated. */
  timezonePatch: TimezonePatch;
};

/** The output of our update `Timezone` mutation. */
export type UpdateTimezonePayload = {
  __typename?: 'UpdateTimezonePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Timezone` that was updated by this mutation. */
  timezone?: Maybe<Timezone>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Timezone`. May be used by Relay 1. */
  timezoneEdge?: Maybe<TimezonesEdge>;
};


/** The output of our update `Timezone` mutation. */
export type UpdateTimezonePayloadTimezoneEdgeArgs = {
  orderBy?: Maybe<Array<TimezonesOrderBy>>;
};

/** All input for the `updateUserById` mutation. */
export type UpdateUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateUserByName` mutation. */
export type UpdateUserByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
  name: Scalars['NameType'];
};

/** All input for the `updateUserCustomerByUserId` mutation. */
export type UpdateUserCustomerByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserCustomer` being updated. */
  userCustomerPatch: UserCustomerPatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateUserCustomer` mutation. */
export type UpdateUserCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserCustomer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserCustomer` being updated. */
  userCustomerPatch: UserCustomerPatch;
};

/** The output of our update `UserCustomer` mutation. */
export type UpdateUserCustomerPayload = {
  __typename?: 'UpdateUserCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserCustomer` that was updated by this mutation. */
  userCustomer?: Maybe<UserCustomer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserCustomer`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Customer` that is related to this `UserCustomer`. */
  customerByCustomerId?: Maybe<Customer>;
  /** An edge for our `UserCustomer`. May be used by Relay 1. */
  userCustomerEdge?: Maybe<UserCustomersEdge>;
};


/** The output of our update `UserCustomer` mutation. */
export type UpdateUserCustomerPayloadUserCustomerEdgeArgs = {
  orderBy?: Maybe<Array<UserCustomersOrderBy>>;
};

/** All input for the `updateUserFileById` mutation. */
export type UpdateUserFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserFile` being updated. */
  userFilePatch: UserFilePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateUserFile` mutation. */
export type UpdateUserFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserFile` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserFile` being updated. */
  userFilePatch: UserFilePatch;
};

/** The output of our update `UserFile` mutation. */
export type UpdateUserFilePayload = {
  __typename?: 'UpdateUserFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserFile` that was updated by this mutation. */
  userFile?: Maybe<UserFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserFile`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `UserFile`. May be used by Relay 1. */
  userFileEdge?: Maybe<UserFilesEdge>;
};


/** The output of our update `UserFile` mutation. */
export type UpdateUserFilePayloadUserFileEdgeArgs = {
  orderBy?: Maybe<Array<UserFilesOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `User`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `updateVenueByCustomerIdAndCountryAndCityAndLocation` mutation. */
export type UpdateVenueByCustomerIdAndCountryAndCityAndLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Venue` being updated. */
  venuePatch: VenuePatch;
  customerId: Scalars['UUID'];
  country: Scalars['String'];
  city: Scalars['String'];
  location: Scalars['NameType'];
};

/** All input for the `updateVenueById` mutation. */
export type UpdateVenueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Venue` being updated. */
  venuePatch: VenuePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateVenue` mutation. */
export type UpdateVenueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Venue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Venue` being updated. */
  venuePatch: VenuePatch;
};

/** The output of our update `Venue` mutation. */
export type UpdateVenuePayload = {
  __typename?: 'UpdateVenuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Venue` that was updated by this mutation. */
  venue?: Maybe<Venue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Customer` that is related to this `Venue`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Timezone` that is related to this `Venue`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** An edge for our `Venue`. May be used by Relay 1. */
  venueEdge?: Maybe<VenuesEdge>;
};


/** The output of our update `Venue` mutation. */
export type UpdateVenuePayloadVenueEdgeArgs = {
  orderBy?: Maybe<Array<VenuesOrderBy>>;
};

/** A user of the system. */
export type User = Node & {
  __typename?: 'User';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  name: Scalars['NameType'];
  /** Time zone from ver_controlpanel.timezones */
  timezone: Scalars['String'];
  /** Locale for i18n, e.g. en or ru. */
  locale: Scalars['LocaleType'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Timezone` that is related to this `User`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** Reads and enables pagination through a set of `LicenseSessionComment`. */
  licenseSessionCommentsByUserId: LicenseSessionCommentsConnection;
  /** Reads a single `UserCustomer` that is related to this `User`. */
  userCustomerByUserId?: Maybe<UserCustomer>;
  /** Reads and enables pagination through a set of `UserFile`. */
  userFilesByUserId: UserFilesConnection;
  /** Returns true if the user is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']>;
  /** Returns the user login. */
  login?: Maybe<Scalars['AccountLoginType']>;
  /** Returns the user role. */
  role?: Maybe<UserRoleType>;
};


/** A user of the system. */
export type UserLicenseSessionCommentsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionCommentsOrderBy>>;
  condition?: Maybe<LicenseSessionCommentCondition>;
  filter?: Maybe<LicenseSessionCommentFilter>;
};


/** A user of the system. */
export type UserUserFilesByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserFilesOrderBy>>;
  condition?: Maybe<UserFileCondition>;
  filter?: Maybe<UserFileFilter>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['NameType']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locale` field. */
  locale?: Maybe<Scalars['LocaleType']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Relationship between user and customer */
export type UserCustomer = Node & {
  __typename?: 'UserCustomer';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  userId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `UserCustomer`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Customer` that is related to this `UserCustomer`. */
  customerByCustomerId?: Maybe<Customer>;
};

/**
 * A condition to be used against `UserCustomer` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserCustomerCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `UserCustomer` object types. All fields are combined with a logical ‘and.’ */
export type UserCustomerFilter = {
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Filter by the object’s `customerByCustomerId` relation. */
  customerByCustomerId?: Maybe<CustomerFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserCustomerFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserCustomerFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserCustomerFilter>;
};

/** An input for mutations affecting `UserCustomer` */
export type UserCustomerInput = {
  userId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `UserCustomer`. Fields that are set will be updated. */
export type UserCustomerPatch = {
  userId?: Maybe<Scalars['UUID']>;
  customerId?: Maybe<Scalars['UUID']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `UserCustomer` values. */
export type UserCustomersConnection = {
  __typename?: 'UserCustomersConnection';
  /** A list of `UserCustomer` objects. */
  nodes: Array<Maybe<UserCustomer>>;
  /** A list of edges which contains the `UserCustomer` and cursor to aid in pagination. */
  edges: Array<UserCustomersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserCustomer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserCustomer` edge in the connection. */
export type UserCustomersEdge = {
  __typename?: 'UserCustomersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserCustomer` at the end of the edge. */
  node?: Maybe<UserCustomer>;
};

/** Methods to use when ordering `UserCustomer`. */
export enum UserCustomersOrderBy {
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A user files. */
export type UserFile = Node & {
  __typename?: 'UserFile';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
  name: Scalars['String'];
  ext: Scalars['String'];
  status: UserFileStatusType;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `UserFile`. */
  userByUserId?: Maybe<User>;
};

/**
 * A condition to be used against `UserFile` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserFileCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ext` field. */
  ext?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<UserFileStatusType>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `UserFile` object types. All fields are combined with a logical ‘and.’ */
export type UserFileFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `ext` field. */
  ext?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<UserFileStatusTypeFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFileFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFileFilter>;
};

/** An input for mutations affecting `UserFile` */
export type UserFileInput = {
  id?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
  name: Scalars['String'];
  ext: Scalars['String'];
  status?: Maybe<UserFileStatusType>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `UserFile`. Fields that are set will be updated. */
export type UserFilePatch = {
  id?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  status?: Maybe<UserFileStatusType>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `UserFile` values. */
export type UserFilesConnection = {
  __typename?: 'UserFilesConnection';
  /** A list of `UserFile` objects. */
  nodes: Array<Maybe<UserFile>>;
  /** A list of edges which contains the `UserFile` and cursor to aid in pagination. */
  edges: Array<UserFilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserFile` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserFile` edge in the connection. */
export type UserFilesEdge = {
  __typename?: 'UserFilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserFile` at the end of the edge. */
  node?: Maybe<UserFile>;
};

/** Methods to use when ordering `UserFile`. */
export enum UserFilesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ExtAsc = 'EXT_ASC',
  ExtDesc = 'EXT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum UserFileStatusType {
  Submitted = 'SUBMITTED',
  Failed = 'FAILED',
  Ready = 'READY'
}

/** A filter to be used against UserFileStatusType fields. All fields are combined with a logical ‘and.’ */
export type UserFileStatusTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<UserFileStatusType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<UserFileStatusType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<UserFileStatusType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<UserFileStatusType>;
  /** Included in the specified list. */
  in?: Maybe<Array<UserFileStatusType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<UserFileStatusType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<UserFileStatusType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<UserFileStatusType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<UserFileStatusType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<UserFileStatusType>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<NameTypeFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: Maybe<StringFilter>;
  /** Filter by the object’s `locale` field. */
  locale?: Maybe<LocaleTypeFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isDisabled` field. */
  isDisabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `login` field. */
  login?: Maybe<AccountLoginTypeFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<UserRoleTypeFilter>;
  /** Filter by the object’s `licenseSessionCommentsByUserId` relation. */
  licenseSessionCommentsByUserId?: Maybe<UserToManyLicenseSessionCommentFilter>;
  /** Some related `licenseSessionCommentsByUserId` exist. */
  licenseSessionCommentsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userCustomerByUserId` relation. */
  userCustomerByUserId?: Maybe<UserCustomerFilter>;
  /** A related `userCustomerByUserId` exists. */
  userCustomerByUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userFilesByUserId` relation. */
  userFilesByUserId?: Maybe<UserToManyUserFileFilter>;
  /** Some related `userFilesByUserId` exist. */
  userFilesByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `timezoneByTimezone` relation. */
  timezoneByTimezone?: Maybe<TimezoneFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['NameType'];
  /** Time zone from ver_controlpanel.timezones */
  timezone?: Maybe<Scalars['String']>;
  /** Locale for i18n, e.g. en or ru. */
  locale?: Maybe<Scalars['LocaleType']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['NameType']>;
  /** Time zone from ver_controlpanel.timezones */
  timezone?: Maybe<Scalars['String']>;
  /** Locale for i18n, e.g. en or ru. */
  locale?: Maybe<Scalars['LocaleType']>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
};

export enum UserRoleType {
  VerControlpanelUser = 'VER_CONTROLPANEL_USER',
  VerControlpanelAdmin = 'VER_CONTROLPANEL_ADMIN',
  VerControlpanelReport = 'VER_CONTROLPANEL_REPORT'
}

/** A filter to be used against UserRoleType fields. All fields are combined with a logical ‘and.’ */
export type UserRoleTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<UserRoleType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<UserRoleType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<UserRoleType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<UserRoleType>;
  /** Included in the specified list. */
  in?: Maybe<Array<UserRoleType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<UserRoleType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<UserRoleType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<UserRoleType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<UserRoleType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<UserRoleType>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  LocaleAsc = 'LOCALE_ASC',
  LocaleDesc = 'LOCALE_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `LicenseSessionComment` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLicenseSessionCommentFilter = {
  /** Every related `LicenseSessionComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionCommentFilter>;
  /** Some related `LicenseSessionComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionCommentFilter>;
  /** No related `LicenseSessionComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionCommentFilter>;
};

/** A filter to be used against many `UserFile` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserFileFilter = {
  /** Every related `UserFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserFileFilter>;
  /** Some related `UserFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserFileFilter>;
  /** No related `UserFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserFileFilter>;
};


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** A venue of the customer. */
export type Venue = Node & {
  __typename?: 'Venue';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  customerId: Scalars['UUID'];
  location: Scalars['NameType'];
  isArchived: Scalars['Boolean'];
  /** Time zone from ver_controlpanel.timezones */
  timezone: Scalars['String'];
  leaderboardLogin?: Maybe<Scalars['String']>;
  leaderboardPassword?: Maybe<Scalars['String']>;
  priceDefault?: Maybe<PriceDefinition>;
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinition>>>;
  royaltyDefault?: Maybe<RoyaltyDefinition>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** Reads a single `Customer` that is related to this `Venue`. */
  customerByCustomerId?: Maybe<Customer>;
  /** Reads a single `Timezone` that is related to this `Venue`. */
  timezoneByTimezone?: Maybe<Timezone>;
  /** Reads and enables pagination through a set of `LicenseV2`. */
  licenseV2SByVenueId: LicenseV2SConnection;
  /** Reads and enables pagination through a set of `LicenseSession`. */
  licenseSessionsByVenueId: LicenseSessionsConnection;
  /** Reads and enables pagination through a set of `License`. */
  licensesByVenueId: LicensesConnection;
};


/** A venue of the customer. */
export type VenueLicenseV2SByVenueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseV2SOrderBy>>;
  condition?: Maybe<LicenseV2Condition>;
  filter?: Maybe<LicenseV2Filter>;
};


/** A venue of the customer. */
export type VenueLicenseSessionsByVenueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicenseSessionsOrderBy>>;
  condition?: Maybe<LicenseSessionCondition>;
  filter?: Maybe<LicenseSessionFilter>;
};


/** A venue of the customer. */
export type VenueLicensesByVenueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LicensesOrderBy>>;
  condition?: Maybe<LicenseCondition>;
  filter?: Maybe<LicenseFilter>;
};

/** A condition to be used against `Venue` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VenueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Scalars['NameType']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leaderboardLogin` field. */
  leaderboardLogin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leaderboardPassword` field. */
  leaderboardPassword?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `priceDefault` field. */
  priceDefault?: Maybe<PriceDefinitionInput>;
  /** Checks for equality with the object’s `priceCalendarDefault` field. */
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  /** Checks for equality with the object’s `royaltyDefault` field. */
  royaltyDefault?: Maybe<RoyaltyDefinitionInput>;
  /** Checks for equality with the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `country` field. */
  country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Venue` object types. All fields are combined with a logical ‘and.’ */
export type VenueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<UuidFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<NameTypeFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: Maybe<StringFilter>;
  /** Filter by the object’s `leaderboardLogin` field. */
  leaderboardLogin?: Maybe<StringFilter>;
  /** Filter by the object’s `leaderboardPassword` field. */
  leaderboardPassword?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAtUtc` field. */
  createdAtUtc?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `country` field. */
  country?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `priceDefault` field. */
  priceDefault?: Maybe<PriceDefinitionFilter>;
  /** Filter by the object’s `royaltyDefault` field. */
  royaltyDefault?: Maybe<RoyaltyDefinitionFilter>;
  /** Filter by the object’s `licenseV2SByVenueId` relation. */
  licenseV2SByVenueId?: Maybe<VenueToManyLicenseV2Filter>;
  /** Some related `licenseV2SByVenueId` exist. */
  licenseV2SByVenueIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licenseSessionsByVenueId` relation. */
  licenseSessionsByVenueId?: Maybe<VenueToManyLicenseSessionFilter>;
  /** Some related `licenseSessionsByVenueId` exist. */
  licenseSessionsByVenueIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `licensesByVenueId` relation. */
  licensesByVenueId?: Maybe<VenueToManyLicenseFilter>;
  /** Some related `licensesByVenueId` exist. */
  licensesByVenueIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerByCustomerId` relation. */
  customerByCustomerId?: Maybe<CustomerFilter>;
  /** Filter by the object’s `timezoneByTimezone` relation. */
  timezoneByTimezone?: Maybe<TimezoneFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<VenueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<VenueFilter>>;
  /** Negates the expression. */
  not?: Maybe<VenueFilter>;
};

/** An input for mutations affecting `Venue` */
export type VenueInput = {
  id?: Maybe<Scalars['UUID']>;
  customerId: Scalars['UUID'];
  location: Scalars['NameType'];
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Time zone from ver_controlpanel.timezones */
  timezone: Scalars['String'];
  leaderboardLogin?: Maybe<Scalars['String']>;
  leaderboardPassword?: Maybe<Scalars['String']>;
  priceDefault?: Maybe<PriceDefinitionInput>;
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  royaltyDefault?: Maybe<RoyaltyDefinitionInput>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

/** Represents an update to a `Venue`. Fields that are set will be updated. */
export type VenuePatch = {
  id?: Maybe<Scalars['UUID']>;
  customerId?: Maybe<Scalars['UUID']>;
  location?: Maybe<Scalars['NameType']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Time zone from ver_controlpanel.timezones */
  timezone?: Maybe<Scalars['String']>;
  leaderboardLogin?: Maybe<Scalars['String']>;
  leaderboardPassword?: Maybe<Scalars['String']>;
  priceDefault?: Maybe<PriceDefinitionInput>;
  priceCalendarDefault?: Maybe<Array<Maybe<PriceCalendarDefinitionInput>>>;
  royaltyDefault?: Maybe<RoyaltyDefinitionInput>;
  createdAtUtc?: Maybe<Scalars['Datetime']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Venue` values. */
export type VenuesConnection = {
  __typename?: 'VenuesConnection';
  /** A list of `Venue` objects. */
  nodes: Array<Maybe<Venue>>;
  /** A list of edges which contains the `Venue` and cursor to aid in pagination. */
  edges: Array<VenuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Venue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Venue` edge in the connection. */
export type VenuesEdge = {
  __typename?: 'VenuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Venue` at the end of the edge. */
  node?: Maybe<Venue>;
};

/** Methods to use when ordering `Venue`. */
export enum VenuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  LeaderboardLoginAsc = 'LEADERBOARD_LOGIN_ASC',
  LeaderboardLoginDesc = 'LEADERBOARD_LOGIN_DESC',
  LeaderboardPasswordAsc = 'LEADERBOARD_PASSWORD_ASC',
  LeaderboardPasswordDesc = 'LEADERBOARD_PASSWORD_DESC',
  PriceDefaultAsc = 'PRICE_DEFAULT_ASC',
  PriceDefaultDesc = 'PRICE_DEFAULT_DESC',
  PriceCalendarDefaultAsc = 'PRICE_CALENDAR_DEFAULT_ASC',
  PriceCalendarDefaultDesc = 'PRICE_CALENDAR_DEFAULT_DESC',
  RoyaltyDefaultAsc = 'ROYALTY_DEFAULT_ASC',
  RoyaltyDefaultDesc = 'ROYALTY_DEFAULT_DESC',
  CreatedAtUtcAsc = 'CREATED_AT_UTC_ASC',
  CreatedAtUtcDesc = 'CREATED_AT_UTC_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `License` object types. All fields are combined with a logical ‘and.’ */
export type VenueToManyLicenseFilter = {
  /** Every related `License` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseFilter>;
  /** Some related `License` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseFilter>;
  /** No related `License` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseFilter>;
};

/** A filter to be used against many `LicenseSession` object types. All fields are combined with a logical ‘and.’ */
export type VenueToManyLicenseSessionFilter = {
  /** Every related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseSessionFilter>;
  /** Some related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseSessionFilter>;
  /** No related `LicenseSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseSessionFilter>;
};

/** A filter to be used against many `LicenseV2` object types. All fields are combined with a logical ‘and.’ */
export type VenueToManyLicenseV2Filter = {
  /** Every related `LicenseV2` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LicenseV2Filter>;
  /** Some related `LicenseV2` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LicenseV2Filter>;
  /** No related `LicenseV2` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LicenseV2Filter>;
};

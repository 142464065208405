import {action, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Maybe, Query, Venue} from "../generated/graphql";
import {
    apolloClient,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import VenueFragment from "../api/graphql/VenueFragment";

class ActiveVenuesStore {
    @observable
    public customerId: string | undefined = undefined;

    @observable.ref
    public activeVenues: Maybe<Venue>[] | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => this.rootStore.activeCustomers.allActiveCustomers,
            (allActiveCustomers) => {
                if (allActiveCustomers) {
                    this.setCustomerId(allActiveCustomers[0]?.id);
                } else {
                    this.reset();
                }
            }
        )

        reaction(
            () => this.customerId,
            (customerId) => {
                if (customerId) {
                    this.fetchActiveVenues(customerId);
                } else {
                    this.activeVenues = undefined;
                }
            }
        );

        reaction(
            () => this.rootStore.venues.venues,
            () => {
                if (this.customerId) {
                    this.fetchActiveVenues(this.customerId);
                }
            }
        );
    }

    @action
    public fetchActiveVenues(customerId: string) {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query, {
            customerId: string
        }>({
            query: gql`query ($customerId: UUID!) {
                currentUserId,
                allVenues(orderBy: [LOCATION_ASC], condition: {isArchived: false, customerId: $customerId}) {
                    nodes {
                        ...VenueFragment
                    }
                }
            },
            ${VenueFragment}`,
            variables: {
                customerId
            }
        })).then(result => {
            runInAction(() => {
                this.activeVenues = result.data.allVenues?.nodes;
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public setCustomerId(customerId: string | undefined) {
        this.customerId = customerId;
    }

    @action
    public reset() {
        this.customerId = undefined;
        this.activeVenues = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    ActiveVenuesStore
};
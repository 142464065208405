import {IReactionPublic} from "mobx";

function debounceEffect<T>(effect: (arg: T, r: IReactionPublic) => void, debounceMs: number) {
    let timer: any;
    return (arg: T, r: IReactionPublic) => {
        clearTimeout(timer)
        timer = setTimeout(() => effect(arg, r), debounceMs)
    };
}

export {
    debounceEffect
}
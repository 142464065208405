import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Mutation,
    PriceCalendarDefinition,
    PriceDefinition,
    RoyaltyDefinition
} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";

class NewCustomerStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async create(
        name: string,
        tags: string[],
        priceDefault?: PriceDefinition,
        priceCalendarDefault?: PriceCalendarDefinition[],
        royaltyDefault?: RoyaltyDefinition
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.createCustomer(name, tags, priceDefault, priceCalendarDefault, royaltyDefault);

            this.rootStore.customers.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private createCustomer(customerName: string,
                           tags: string[],
                          priceDefault?: PriceDefinition,
                          priceCalendarDefault?: PriceCalendarDefinition[],
                          royaltyDefault?: RoyaltyDefinition): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            customerName: string,
            tags: string[],
            priceDefault?: PriceDefinition,
            priceCalendarDefault?: PriceCalendarDefinition[],
            royaltyDefault?: RoyaltyDefinition
        }>({
            mutation: gql`mutation ($customerName: NameType!, $tags: [String], $priceDefault: PriceDefinitionInput, $priceCalendarDefault: [PriceCalendarDefinitionInput], $royaltyDefault: RoyaltyDefinitionInput) {
                createCustomer(input: {customer: {name: $customerName, tags: $tags, priceDefault: $priceDefault, priceCalendarDefault: $priceCalendarDefault, royaltyDefault: $royaltyDefault}}) {
                    clientMutationId
                }
            }`,
            variables: {
                customerName,
                tags,
                priceDefault,
                priceCalendarDefault,
                royaltyDefault
            }
        }));
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }
}

export {
    NewCustomerStore
};
import {PriceDefinition, PriceVariantType} from "../generated/graphql";
import React from "react";
import {t} from "ttag";
import {moneyToDisplayStr} from "../utils/MoneyUtils";
import {CRow} from "@coreui/react";

const Price: React.FC<{price: PriceDefinition, label?: string}> = (props) => {
    let priceType;

    switch (props.price.priceVariant) {
        case PriceVariantType.PerPlayer:
            priceType = t`Per player`;
            break;
        case PriceVariantType.PerPlayerMinute:
            priceType = t`Per player minute`;
            break;
        case PriceVariantType.PerPlayerProgressive:
            priceType = t`Per player hour (1st hour up to 90min)`;
            break;
        case PriceVariantType.PerSession:
            priceType = t`Per session`;
            break;
        case PriceVariantType.PerSessionMinute:
            priceType = t`Per minute`;
            break;
        case PriceVariantType.PerSessionProgressive:
            priceType = t`Per hour (1st hour up to 90min)`;
            break;
    }

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{props.label && props.label}{priceType}:</strong>
            </CRow>

            <CRow gutters={false}>
                {moneyToDisplayStr(props.price.price)}{props.price.priceCurrency}
            </CRow>
        </React.Fragment>
    );
};

export default Price;
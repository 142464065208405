import {Timezone} from "../generated/graphql";
import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../context/StoreContext";

const Timezones = observer(() => {
    const store = useContext(StoreContext);
    const {auth} = store;

    const options = (auth.timezones ? auth.timezones : []).filter(timezone => !!timezone)
        .map((timezone: Timezone | null) =>
            <option key={timezone?.name} value={timezone?.name ?? ""}>{timezone?.name}</option>
        );

    return (
        <React.Fragment>
            {options}
        </React.Fragment>
    );
});

export default Timezones;
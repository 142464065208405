import {action, autorun, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Mutation,
    PriceCalendarDefinition,
    PriceDefinition,
    RoyaltyDefinition,
    Venue
} from "../generated/graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {ExecutionResult} from "graphql";

class EditVenueStore {
    @observable.ref
    public venue: Venue | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.rootStore.venues.venues) {
                this.reset();
            }
        });
    }

    @action
    public async save(
        country: string,
        city: string,
        location: string,
        timezone: string,
        isArchived: boolean,
        priceDefault?: PriceDefinition,
        priceCalendarDefault?: PriceCalendarDefinition[],
        royaltyDefault?: RoyaltyDefinition
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.saveQuery(this.venue?.id, country, city, location, timezone, isArchived, priceDefault, priceCalendarDefault, royaltyDefault);

            this.rootStore.venues.fetchVenues();

            this.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async saveQuery(venueId: string,
                            country: string,
                            city: string,
                            location: string,
                            timezone: string,
                            isArchived: boolean,
                            priceDefault?: PriceDefinition,
                            priceCalendarDefault?: PriceCalendarDefinition[],
                            royaltyDefault?: RoyaltyDefinition): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            venueId: string,
            country: string,
            city: string,
            location: string,
            timezone: string,
            isArchived: boolean,
            priceDefault?: PriceDefinition,
            priceCalendarDefault?: PriceCalendarDefinition[],
            royaltyDefault?: RoyaltyDefinition
        }>({
            mutation: gql`mutation ($venueId: UUID!, $country: String!, $city: String!, $location: NameType!, $timezone: String!, $isArchived: Boolean!, $priceDefault: PriceDefinitionInput, $priceCalendarDefault: [PriceCalendarDefinitionInput], $royaltyDefault: RoyaltyDefinitionInput) {
                updateVenueById(input: {venuePatch: {country: $country, city: $city, location: $location, timezone: $timezone, isArchived: $isArchived, priceDefault: $priceDefault, priceCalendarDefault: $priceCalendarDefault, royaltyDefault: $royaltyDefault}, id: $venueId}) {
                    clientMutationId
                }
            }`,
            variables: {
                venueId,
                country,
                city,
                location,
                timezone,
                isArchived,
                priceDefault,
                priceCalendarDefault,
                royaltyDefault
            }
        }));
    }

    @action
    public setVenue(venue: Venue | undefined) {
        this.reset();

        setTimeout(() => runInAction(() => {
            this.venue = venue;
        }));
    }

    @action
    public reset() {
        this.venue = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    EditVenueStore
};
import {action, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Customer, LicenseV2, Mutation, RoyaltyVariantType, Venue} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {
    LicenseV2Claim,
    LicenseV2ClaimId, LicenseV2PriceClaimValue,
    LicenseV2RoyaltyClaimValue,
    LicenseV2StringClaimValue
} from "../api/LicenseV2Claims";
import {compareLicenseV2Claim, defaultLicenseV2ClaimValue} from "../utils/LicenseV2ClaimUtils";

class EditLicenseV2Store {
    @observable
    public license: LicenseV2 | undefined;

    @observable.ref
    public customer: Customer | null | undefined;

    @observable.ref
    public venue: Venue | null | undefined;

    @observable
    public expirateAtUtc: string | null = null;

    @observable
    public isRevoked: boolean = false;

    @observable.ref
    public claims: LicenseV2Claim[] = [] as LicenseV2Claim[];

    @observable.ref
    public editClaim: LicenseV2Claim | undefined;

    @observable
    public editClaimIndex: number | undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        this.reset();

        reaction(() => {
            return {
                licenses: this.rootStore.licensesV2.licenses
            };
        }, () => {
            if (this.license) {
                this.reset();
            }
        });

        reaction(() => {
            return {
                allActiveCustomers: this.rootStore.activeCustomers.allActiveCustomers,
                customer: this.customer
            };
        }, ({allActiveCustomers, customer}) => {
            if (allActiveCustomers && !customer) {
                this.customer = allActiveCustomers[0];
            }

            if (customer) {
                this.rootStore.activeVenues.setCustomerId(customer.id);
            }
        });

        reaction(() => {
            return {
                activeVenuesCustomerId: this.rootStore.activeVenues.customerId,
                activeVenues: this.rootStore.activeVenues.activeVenues,
                customer: this.customer,
                venue: this.venue
            };
        }, ({activeVenuesCustomerId, activeVenues, customer, venue}) => {
            if (customer && venue?.customerByCustomerId?.id !== customer.id && activeVenues && activeVenuesCustomerId === customer.id) {
                this.venue = activeVenues[0];
            }
        });

        reaction(() => {
            return {
                license: this.license
            };
        }, ({license}) => {
            if (license) {
                this.venue = license.venueByVenueId;
                this.customer = this.venue?.customerByCustomerId;
                this.expirateAtUtc = license.expirateAtUtc;
                this.isRevoked = license.isRevoked;
                this.claims = [...license.claims];
            }
        });
    }

    @action
    public setLicense(license: LicenseV2 | undefined) {
        if (license !== undefined) {
            this.reset();
        } else {
            this.editClaim = undefined;
            this.editClaimIndex = undefined;
            this.loading = false;
            this.error = undefined;
            this.isRevoked = false;
        }

        setTimeout(() => runInAction(() => {
            this.license = license;
        }));
    }

    @action
    public setCustomer(value: Customer | undefined) {
        this.customer = value;
    }

    @action
    public setVenue(value: Venue | undefined) {
        this.venue = value;
    }

    @action
    public setExpirateAtUtc(value: string | null) {
        this.expirateAtUtc = value;
    }

    @action
    public setIsRevoked(value: boolean) {
        this.isRevoked = value;
    }

    @action
    public setClaims(claims: LicenseV2Claim[]) {
        const claimsUpd = claims.map(royaltyClaim => {
            if (royaltyClaim.id !== LicenseV2ClaimId.ROYALTY) {
                return royaltyClaim;
            }

            const royaltyClaimValue = royaltyClaim.value as LicenseV2RoyaltyClaimValue;

            if (!royaltyClaimValue || royaltyClaimValue.royalty_variant !== RoyaltyVariantType.PerRevenueShare) {
                return royaltyClaim;
            }

            const priceClaimByProduct = claims.find(priceClaim =>
                priceClaim.id === LicenseV2ClaimId.PRICE && royaltyClaim.product_id === priceClaim.product_id
            );

            const priceClaim = priceClaimByProduct || claims.find(
                priceClaim => priceClaim.id === LicenseV2ClaimId.PRICE
            );

            if (!priceClaim) {
                return royaltyClaim;
            }

            return {
                ...royaltyClaim,
                value: {
                    ...royaltyClaimValue,
                    royalty_currency: (priceClaim.value as LicenseV2PriceClaimValue).price_currency
                }
            };
        });

        this.claims = claimsUpd.sort(compareLicenseV2Claim);
    }

    @action
    public setEditClaim(claim: LicenseV2Claim | undefined) {
        if (claim) {
            this.editClaim = {...claim};
        } else {
            this.editClaim = undefined;
        }
    }

    @action
    public setEditClaimIndex(index: number | undefined) {
        this.editClaimIndex = index;
    }

    @action
    public async save() {
        if (this.license) {
            return await this.update();
        } else {
            return await this.create();
        }
    }

    @action
    public reset() {
        this.cancel();

        this.license = undefined;

        this.customer = undefined;

        this.venue = undefined;

        this.expirateAtUtc = null;

        this.isRevoked = false;

        this.claims = [
            {id: LicenseV2ClaimId.ALLOW_MULTISESSIONS},
            {id: LicenseV2ClaimId.ALLOW_SHAREDSPACES},
            //{id: LicenseV2ClaimId.ALLOW_MACHINE},
            {id: LicenseV2ClaimId.PRICE, value: defaultLicenseV2ClaimValue(LicenseV2ClaimId.PRICE)},
            {id: LicenseV2ClaimId.ROYALTY, value: defaultLicenseV2ClaimValue(LicenseV2ClaimId.ROYALTY)},
            {id: LicenseV2ClaimId.ALLOW_PRODUCTS, value: {
                exact: this.rootStore.products.activeProducts.map(p => p.id)
            } as LicenseV2StringClaimValue}
        ];

        this.editClaim = undefined;

        this.editClaimIndex = undefined;
    }

    @action
    public cancel() {
        this.loading = false;
        this.error = undefined;
    }

    @action
    private async create() {
        this.loading = true;
        this.error = undefined;

        try {
            await this.createQuery(this.venue?.id, this.expirateAtUtc, this.claims);

            this.rootStore.licensesV2.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async update() {
        this.loading = true;
        this.error = undefined;

        try {
            await this.updateQuery(this.license?.id, this.isRevoked, this.expirateAtUtc, this.claims);

            this.rootStore.licensesV2.fetchLicenses();

            this.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private async createQuery(venueId: string,
                         expirateAtUtc: string | null,
                         claims: LicenseV2Claim[]): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            venueId: string,
            expirateAtUtc: string | null,
            claims: any,
            tokenLifeTimeSec: number
        }>({
            mutation: gql`mutation ($venueId: UUID!, $expirateAtUtc: Datetime, $claims: JSON!, $tokenLifeTimeSec: BigInt) {
                createLicenseV2(input: {licenseV2: {venueId: $venueId, expirateAtUtc: $expirateAtUtc, claims: $claims, tokenLifeTimeSec: $tokenLifeTimeSec}}) {
                    clientMutationId
                }
            }`,
            variables: {
                venueId,
                expirateAtUtc,
                claims,
                tokenLifeTimeSec: 24 * 60 * 60
            }
        }));
    }

    private async updateQuery(licenseId: string,
                         isRevoked: boolean,
                         expirateAtUtc: string | null,
                         claims: LicenseV2Claim[]): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            licenseId: string,
            isRevoked: boolean,
            expirateAtUtc: string | null,
            claims: any
        }>({
            mutation: gql`mutation ($licenseId: UUID!, $isRevoked: Boolean!, $expirateAtUtc: Datetime, $claims: JSON!) {
                updateLicenseV2ById(input: {licenseV2Patch: {isRevoked: $isRevoked, expirateAtUtc: $expirateAtUtc, claims: $claims}, id: $licenseId}) {
                    clientMutationId
                }
            }`,
            variables: {
                licenseId,
                isRevoked,
                expirateAtUtc,
                claims
            }
        }));
    }
}

export {
    EditLicenseV2Store
};
import React, {useContext} from "react"
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CPopover,
    CRow,
    CSpinner
} from "@coreui/react"
import CIcon from "@coreui/icons-react"
import {observer} from "mobx-react-lite";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import {LoginInput} from "../../stores/LoginStore";
import {StoreContext} from "../../context/StoreContext";
import Error from "../Error";

const login = observer(() => {
    const store = useContext(StoreContext);
    const {login} = store;

    const {register, handleSubmit, errors} = useForm<LoginInput>();

    const onSubmit = (data: LoginInput) => login.login(data);

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="6">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm onSubmit={handleSubmit(onSubmit)}>
                                        <h1>{jt`Login`}</h1>
                                        <p className="text-muted">{jt`Sign In to your Another World account`}</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CPopover content={t`Required`} placement="right" advancedOptions={{onShow: () => !!errors.login}}>
                                                <CInput name="login" type="text" placeholder={t`Username`}
                                                        autoComplete="login"
                                                        innerRef={register({required: true})}
                                                        invalid={!!errors.login}/>
                                            </CPopover>
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CPopover content={t`Required`} placement="right" advancedOptions={{onShow: () => !!errors.password}}>
                                                <CInput name="password" type="password" placeholder={t`Password`}
                                                        autoComplete="current-password"
                                                        innerRef={register({required: true})}
                                                        invalid={!!errors.password}/>
                                            </CPopover>
                                        </CInputGroup>

                                        <CRow>
                                            {login.error && <Error>{login.error}</Error>}
                                        </CRow>

                                        <CRow>
                                            <CCol xs="6">
                                                <CButton color="primary" className="px-4" type="submit" disabled={login.loading}>
                                                    {jt`Login`}
                                                    {login.loading && <CSpinner color="info" style={{width:'1rem', height:'1rem'}}/>}
                                                </CButton>
                                            </CCol>
                                            <CCol xs="6" className="text-right">
                                                <CPopover interactive={true}
                                                          trigger="click"
                                                          content={t`Please ask Another World support to reset your password or create new account`}>
                                                    <CButton color="secondary" className="px-4">{jt`Forgot password?`}</CButton>
                                                </CPopover>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
});

export default login;
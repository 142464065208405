import gql from "graphql-tag";
import VenueFragment from "./VenueFragment";

const LicenseV2Fragment = gql`fragment LicenseV2Fragment on LicenseV2 {
    id,
    createdAtUtc,
    expirateAtUtc,
    isRevoked,
    licenseKey,
    claims,
    tokenLifeTimeSec,
    venueByVenueId {
        ...VenueFragment
    }
},
${VenueFragment}`;

export default LicenseV2Fragment;
import {PropsWithChildren, useContext} from "react";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../context/StoreContext";

const dateFormatter = require('date-and-time');

interface DateAndTimeProps {
    date: Date
    format?: string,
    local?: boolean
}

const DateOnlyFormat = "YYYY/MM/DD";
const DateAndTimeFormat = "YYYY/MM/DD HH:mm";
const DateAndTimeFullFormat = "YYYY/MM/DD HH:mm:ss";

const DateAndTime = observer((props: PropsWithChildren<DateAndTimeProps>) => {
    const store = useContext(StoreContext);
    const {auth} = store;

    let date = props.date;
    if (!props.local) {
       date = new Date(date.getTime() + (+auth.currentUser?.timezoneByTimezone?.utcSecondsOffset)*1000);
    }

    return dateFormatter.format(date, props.format ? props.format : DateAndTimeFullFormat);
});

export {
    DateOnlyFormat,
    DateAndTimeFormat,
    DateAndTimeFullFormat,
    DateAndTime
};
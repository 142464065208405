import {AbstractApi} from "./AbstractApi";

class UserFilesApi extends AbstractApi {
    protected baseUrl(): string {
        return `${super.baseUrl()}/api/v1/user_files`;
    }

    public download(userFileId: string, ext: string) {
        window.open(`${this.baseUrl()}/${encodeURIComponent(userFileId)}/${encodeURIComponent(ext)}`);
    }
}

const userFilesApi = new UserFilesApi();

export default userFilesApi;
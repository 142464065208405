import {
    PriceCalendarDefinition,
    PriceDefinition,
    PriceVariantType,
    RoyaltyDefinition,
    RoyaltyVariantType
} from "../generated/graphql";

enum LicenseV2ClaimId {
    PRICE = "price",
    PRICE_CALENDAR = "price_calendar",
    ROYALTY = "royalty",
    ALLOW_PRODUCTS = "allow_products",
    ALLOW_PRODUCT_VARIANTS = "allow_product_variants",
    PREFERRED_PRODUCT_VARIANTS = "preferred_product_variants",
    ALLOW_MULTISESSIONS = "allow_multisessions",
    ALLOW_SHAREDSPACES = "allow_sharedspaces",
    ALLOW_MACHINES = "allow_machines",
    PLAYERS = "players",
    PRODUCT_OPTION_STRING = "product_option_string",
    PRODUCT_OPTION_NUMBER = "product_option_number",
    PRODUCT_OPTION_BOOLEAN = "product_option_boolean"
}

interface LicenseV2PriceClaimValue {
    price_currency: string;
    price_variant: PriceVariantType;
    price: number;
}

function toPriceDefinition(priceClaimValue: LicenseV2PriceClaimValue): PriceDefinition {
    return {
        priceCurrency: priceClaimValue.price_currency,
        priceVariant: priceClaimValue.price_variant,
        price: priceClaimValue.price
    };
}

interface LicenseV2RoyaltyClaimValue {
    royalty_variant: RoyaltyVariantType;
    royalty_currency: string;
    royalty: number;
    royalty_share: number;
}

function toRoyaltyDefinition(royaltyClaimValue: LicenseV2RoyaltyClaimValue): RoyaltyDefinition {
    return {
        royaltyVariant: royaltyClaimValue.royalty_variant,
        royaltyCurrency: royaltyClaimValue.royalty_currency,
        royalty: royaltyClaimValue.royalty,
        royaltyShare: royaltyClaimValue.royalty_share
    };
}

interface LicenseV2PriceCalendarItemClaimValue {
    from_day_of_week: number;
    to_day_of_week: number;
    from_hour: number;
    to_hour: number;
    price: number;
}

function toPriceCalendarDefinition(priceCalendarItemClaimValue: LicenseV2PriceCalendarItemClaimValue): PriceCalendarDefinition {
    return {
        fromDayOfWeek: priceCalendarItemClaimValue.from_day_of_week,
        toDayOfWeek: priceCalendarItemClaimValue.to_day_of_week,
        fromHour: priceCalendarItemClaimValue.from_hour,
        toHour: priceCalendarItemClaimValue.to_hour,
        price: priceCalendarItemClaimValue.price
    };
}

function toPriceCalendar(priceCalendarItemClaimValue: LicenseV2PriceCalendarItemClaimValue[]): PriceCalendarDefinition[] {
    return priceCalendarItemClaimValue.map(val => toPriceCalendarDefinition(val));
}

interface LicenseV2NumberClaimValue {
    exact?: number[] | null;
    range_from?: number | null;
    range_upto?: number | null;
}

interface LicenseV2BooleanClaimValue {
    exact: boolean;
}

interface LicenseV2StringClaimValue {
    exact: string[];
}

interface LicenseV2ProductOptionStringClaimValue extends LicenseV2StringClaimValue {
    option_id: string;
}

interface LicenseV2ProductOptionNumberClaimValue extends LicenseV2NumberClaimValue {
    option_id: string;
}

interface LicenseV2ProductOptionBooleanClaimValue extends LicenseV2BooleanClaimValue {
    option_id: string;
}

type LicenseV2ProductOptionClaimValue = LicenseV2ProductOptionStringClaimValue
    | LicenseV2ProductOptionNumberClaimValue
    | LicenseV2ProductOptionBooleanClaimValue;

type LicenseV2ClaimValue = LicenseV2PriceClaimValue
    | LicenseV2RoyaltyClaimValue
    | LicenseV2PriceCalendarItemClaimValue[]
    | LicenseV2NumberClaimValue
    | LicenseV2StringClaimValue
    | LicenseV2BooleanClaimValue
    | LicenseV2ProductOptionClaimValue;

interface LicenseV2Claim {
    id: LicenseV2ClaimId;
    product_id?: string | null;
    value?: LicenseV2ClaimValue
}

export type {
    LicenseV2Claim,
    LicenseV2PriceClaimValue,
    LicenseV2RoyaltyClaimValue,
    LicenseV2PriceCalendarItemClaimValue,
    LicenseV2NumberClaimValue,
    LicenseV2StringClaimValue,
    LicenseV2BooleanClaimValue,
    LicenseV2ProductOptionStringClaimValue,
    LicenseV2ProductOptionNumberClaimValue,
    LicenseV2ProductOptionBooleanClaimValue,
    LicenseV2ProductOptionClaimValue,
    LicenseV2ClaimValue
}

export {
    LicenseV2ClaimId,
    toPriceDefinition,
    toRoyaltyDefinition,
    toPriceCalendar
}
import React, {useState} from "react"
import {observer} from "mobx-react-lite";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow, CSpinner
} from "@coreui/react";
import {jt, t} from "ttag";
import authApi from "../../api/AuthApi";
import {ApiError} from "../../api/AbstractApi";
import {baseApiURI} from "../../utils/ApiUtils";
import Error from "../Error";

const baseApiAbsoluteURI = baseApiURI || `${window.location.protocol}\/\/${window.location.host}`;

const ServicesView = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null as (string | null));
    const [token, setToken] = useState("");

    const onIssueToken = () => {
        setToken("");
        setIsLoading(true);
        setError(null);

        authApi.issueServiceToken().then((token) => {
            setToken(token);
        }, (error: ApiError) => {
            setError(error.getMessage());
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const renderQuery = (title: string, query: string, parameters?: {name: string, example: string, description: string, isOptional?: boolean}[]) => {
        return (
            <React.Fragment>
                <CRow style={{wordBreak: "break-all"}} className="mt-3" gutters={false}>
                    <strong>{title}</strong>
                </CRow>

                <CRow style={{wordBreak: "break-all", display: "block"}} className="mt-1" gutters={false}>
                    <strong>Get</strong> {baseApiAbsoluteURI}{query}
                </CRow>

                <CRow style={{wordBreak: "break-all", display: "block"}} gutters={false}>
                    <strong>Accept:</strong> application/json
                </CRow>

                <CRow style={{wordBreak: "break-all", display: "block"}} gutters={false}>
                    <strong>Authorization:</strong> Bearer {token}
                </CRow>

                {parameters && parameters.length > 0 && parameters.map(param =>
                    <CRow key={param.name} style={{wordBreak: "break-all", display: "block"}} gutters={false}>
                        <strong>{param.name}:</strong> {param.description}. Example: {baseApiAbsoluteURI}{query}?{param.name}={encodeURIComponent(param.example)}
                    </CRow>
                )}
            </React.Fragment>
        );
    };

    return (
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            {t`Services`}
                        </CCardHeader>

                        <CCardBody>
                            <CRow gutters={false}>
                                <CButton color="success" onClick={onIssueToken}>{jt`Issue token`}</CButton>
                            </CRow>

                            {isLoading && <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}

                            {error && <Error>{error}</Error>}

                            {token && <React.Fragment>
                                <CRow className="mt-3" gutters={false}>
                                    <strong>{t`Token`}</strong>
                                </CRow>

                                <CRow style={{wordBreak: "break-all"}} className="mt-1" gutters={false}>
                                    {token}
                                </CRow>
                            </React.Fragment>}

                            {token && renderQuery(t`Get all venues`, "/api/v1/report_services/venues")}

                            {token && renderQuery(t`Get all products`, "/api/v1/report_services/products")}

                            {token && renderQuery(t`Get license sessions`, "/api/v1/report_services/license_sessions", [
                                {
                                    name: "start_date",
                                    example: "2023-01-01",
                                    description: t`Filter license sessions starting from the date (venue local)`
                                },
                                {
                                    name: "end_date",
                                    example: "2023-12-01",
                                    description: t`Filter license sessions till the date (venue local)`,
                                    isOptional: true
                                },
                                {
                                    name: "count",
                                    example: "5",
                                    description: t`Max rows at page`,
                                    isOptional: true
                                },
                                {
                                    name: "get_zero",
                                    example: "true",
                                    description: t`Return license sessions with zero revenue and royalty`,
                                    isOptional: true
                                },
                                {
                                    name: "page_cursor",
                                    example: "zI1NiIsI",
                                    description: t`Page cursor (pagination)`,
                                    isOptional: true
                                }
                            ])}

                            {token && renderQuery(t`Get products usage`, "/api/v1/report_services/products_usage", [
                                {
                                    name: "start_date",
                                    example: "2023-01-01",
                                    description: t`Filter products usage starting from the date (venue local)`
                                },
                                {
                                    name: "end_date",
                                    example: "2023-12-01",
                                    description: t`Filter products usage till the date (venue local)`,
                                    isOptional: true
                                },
                                {
                                    name: "count",
                                    example: "5",
                                    description: t`Max rows at page`,
                                    isOptional: true
                                },
                                {
                                    name: "page_cursor",
                                    example: "zI1NiIsI",
                                    description: t`Page cursor (pagination)`,
                                    isOptional: true
                                }
                            ])}
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
    );
});

export default ServicesView;
import gql from "graphql-tag";
import VenueFragment from "./VenueFragment";

const LicenseFragment = gql`fragment LicenseFragment on License {
    id,
    createdAtUtc,
    expirateAtUtc,
    isRevoked,
    licenseKey,
    priceCalendar {
        fromDayOfWeek,
        toDayOfWeek,
        fromHour,
        toHour,
        price
    },
    price {
        price,
        priceCurrency,
        priceVariant
    },
    royalty {
        royalty,
        royaltyShare,
        royaltyCurrency,
        royaltyVariant
    },
    venueByVenueId {
        ...VenueFragment
    },
    productByProductId {
        id,
        isArchived,
        name
    }
},
${VenueFragment}`;

export default LicenseFragment;
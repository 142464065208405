import React, {useContext} from "react"
import {observer} from "mobx-react-lite";
import {
    CCard, CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CLabel,
    CRow, CSpinner,
    CSwitch
} from "@coreui/react";
import {t} from "ttag";
import {StoreContext} from "../../context/StoreContext";
import {UserRoleType} from "../../generated/graphql";
import EditProduct from "../EditProduct";
import Error from "../Error";
import ProductWidget from "../ProductWidget";
import UserLicenseWidget from "../UserLicenseWidget";
import UserLicenseV2Widget from "../UserLicenseV2Widget";

const ProductsView = observer(() => {
    const store = useContext(StoreContext);
    const {auth, products, newProduct, userLicenses, userLicensesV2} = store;

    const createProduct = (names: {[locale: string]: string},
                           imgBase64?: string) => {
        if (imgBase64) {
            newProduct.create(names, imgBase64);
        }
    };

    const error = products.error || userLicenses.error || userLicensesV2.error;
    const isLoading = products.loading || userLicenses.loading || userLicensesV2.loading;

    const errorBlock = error && <CCardGroup>
        <CCard className="p-4">
            <CCardBody>
                <Error>{error}</Error>
            </CCardBody>
        </CCard>
    </CCardGroup>;

    return isLoading || error ? (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="4">
                        {isLoading && <CSpinner color="primary" style={{width:'4rem', height:'4rem'}}/>}

                        {errorBlock}
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    ) : (
        <React.Fragment>
            {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <CRow>
                <CDropdown>
                    <CDropdownToggle color="primary">
                        {t`New Product`}
                    </CDropdownToggle>

                    <CDropdownMenu>
                        <div className="px-4 py-3">
                            <EditProduct onSubmit={createProduct}
                                         onCancel={() => newProduct.reset()}
                                         loading={newProduct.loading}
                                         error={newProduct.error}
                                         isVertical={true}
                                         hideCancel={true}/>
                        </div>
                    </CDropdownMenu>
                </CDropdown>
            </CRow>}

            <CRow className="mt-2">
                <CLabel htmlFor="isarchived" style={{width: "100%"}}>{t`Show archived`}</CLabel>
                <CSwitch name="isarchived"
                         defaultChecked={products.archivedProductsShow}
                         shape="pill"
                         color="secondary"
                         onClick={() => products.toggleArchivedProductsShow()}/>
            </CRow>

            <CRow className="px-4 py-3">
                {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin &&
                (products.products ? products.products : []).filter(product => !!product && (products.archivedProductsShow || !product.isArchived)).map(product => {
                    return <CCol sm="6" lg="3" key={product?.id || ""}>
                        {product && <ProductWidget product={product}/>}
                    </CCol>;
                })}

                {auth.currentUser?.role === UserRoleType.VerControlpanelUser &&
                (userLicenses.licenses ? userLicenses.licenses : [])
                        .filter(license => !!license && (products.archivedProductsShow || !license.isRevoked))
                        .map(license => {
                            return <CCol sm="6" lg="3" key={license?.id || ""}>
                                {license && <UserLicenseWidget license={license}/>}
                            </CCol>;
                })}
            </CRow>

            <CRow className="px-4 py-3">
                {auth.currentUser?.role === UserRoleType.VerControlpanelUser &&
                        (userLicensesV2.licenses ? userLicensesV2.licenses : [])
                                .filter(license => !!license && (products.archivedProductsShow || !license.isRevoked))
                                .map(license => {
                                    return <CCol xs={12} sm={12} md={6} lg={6} xl={4} xxl={3} key={license?.id || ""}>
                                        {license && <UserLicenseV2Widget license={license}/>}
                                    </CCol>;
                                })}
            </CRow>
        </React.Fragment>
    );
});

export default ProductsView;
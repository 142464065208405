const defaultChartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom'
    },
    scales: {
        xAxes: [{
            gridLines: {
                drawOnChartArea: true
            }
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true
            },
            gridLines: {
                display: true
            }
        }]
    },
    elements: {
        point: {
            radius: 2,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
        },
        line: {
            tension: 0.00001
        }
    },
    tooltips : {
        position: "nearest"
    }
};

export {
    defaultChartOptions
}
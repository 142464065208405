import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {LicenseV2Claim, LicenseV2ClaimValue, LicenseV2PriceClaimValue} from "../api/LicenseV2Claims";
import {CInput, CInputGroup, CInputGroupAppend, CInputGroupText, CPopover, CRow, CSelect} from "@coreui/react";
import {t} from "ttag";
import PriceVariants from "./PriceVariants";
import Currencies from "./Currencies";
import {moneyToDisplayStr} from "../utils/MoneyUtils";

interface EditLicenseV2PriceClaimProps {
    editClaim: LicenseV2Claim;
    onSetEditClaimValue: (value: LicenseV2ClaimValue) => void;
}

function validate(value: string | null | undefined): boolean {
    return !!value && !!value.match(/^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/g);
}

const EditLicenseV2PriceClaim = observer((props: EditLicenseV2PriceClaimProps) => {
    const [isPriceValid, setIsPriceValid] = useState(false);

    const priceInputRef = useRef<HTMLInputElement>();

    const priceValue = props.editClaim.value as LicenseV2PriceClaimValue;

    if (!priceValue) {
        return null;
    }

    useEffect(() => {
        setIsPriceValid(validate(priceInputRef?.current?.value));
    }, [priceInputRef?.current?.value]);

    const onChangePrice = (value: string) => {
        if (!validate(value)) {
            props.onSetEditClaimValue({
                price: undefined
            } as LicenseV2ClaimValue);
        } else {
            props.onSetEditClaimValue({
                price: +value
            } as LicenseV2ClaimValue);
        }
    };

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{t`Price type`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Required`} placement="right"
                          advancedOptions={{onShow: () => !priceValue.price_variant}}>
                    <CSelect value={priceValue.price_variant}
                             invalid={!priceValue.price_variant}
                             onChange={(event) => props.onSetEditClaimValue({
                                 price_variant: (event.target as HTMLSelectElement).value
                             } as LicenseV2PriceClaimValue)}>
                        <PriceVariants/>
                    </CSelect>
                </CPopover>
            </CRow>

            <CRow gutters={false}>
                <strong>{t`Price currency`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Required`} placement="right"
                          advancedOptions={{onShow: () => !priceValue.price_currency}}>
                    <CSelect value={priceValue.price_currency}
                             invalid={!priceValue.price_currency}
                             onChange={(event) => props.onSetEditClaimValue({
                                 price_currency: (event.target as HTMLSelectElement).value
                             } as LicenseV2PriceClaimValue)}>
                        <Currencies/>
                    </CSelect>
                </CPopover>
            </CRow>

            <CRow gutters={false}>
                <strong>{t`Price`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Required, example 12345.67`} placement="right"
                          advancedOptions={{onShow: () => !isPriceValid}}>
                    <CInputGroup>
                        <CInput type="text"
                                innerRef={priceInputRef}
                                invalid={!isPriceValid}
                                defaultValue={priceValue.price && moneyToDisplayStr("" + priceValue.price) || "0"}
                                onChange={(event) => onChangePrice((event.target as HTMLInputElement).value)}/>
                        <CInputGroupAppend>
                            <CInputGroupText>{priceValue.price_currency}</CInputGroupText>
                        </CInputGroupAppend>
                    </CInputGroup>
                </CPopover>
            </CRow>
        </React.Fragment>
    );
});

export default EditLicenseV2PriceClaim;
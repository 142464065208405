import React from "react";
import {t} from "ttag";

const Currencies: React.FC = () => {
    const currencies = [
        [t`Russia Rouble`, "RUB"],
        [t`Euro`, "EUR"],
        [t`USA Dollar`, "USD"],
        [t`Australia Dollar`, "AUD"],
        [t`Great Britain Pound`, "GBP"],
        [t`Japan Yen`, "JPY"],
        [t`Switzerland Franc`, "CHF"],
        [t`Afghanistan Afghani`, "AFN"],
        [t`Albania Lek`, "ALL"],
        [t`Algeria Dinar`, "DZD"],
        [t`Angola Kwanza`, "AOA"],
        [t`Argentina Peso`, "ARS"],
        [t`Armenia Dram`, "AMD"],
        [t`Aruba Florin`, "AWG"],
        [t`Azerbaijan New Manat`, "AZN"],
        [t`Bahamas Dollar`, "BSD"],
        [t`Bahrain Dinar`, "BHD"],
        [t`Bangladesh Taka`, "BDT"],
        [t`Barbados Dollar`, "BBD"],
        [t`Belarus Ruble`, "BYN"],
        [t`Belize Dollar`, "BZD"],
        [t`Bermuda Dollar`, "BMD"],
        [t`Bhutan Ngultrum`, "BTN"],
        [t`Bolivia Boliviano`, "BOB"],
        [t`Bosnia Mark`, "BAM"],
        [t`Botswana Pula`, "BWP"],
        [t`Brazil Real`, "BRL"],
        [t`Brunei Dollar`, "BND"],
        [t`Bulgaria Lev`, "BGN"],
        [t`Burundi Franc`, "BIF"],
        [t`CFA Franc BCEAO`, "XOF"],
        [t`CFA Franc BEAC`, "XAF"],
        [t`CFP Franc`, "XPF"],
        [t`Cambodia Riel`, "KHR"],
        [t`Canada Dollar`, "CAD"],
        [t`Cape Verde Escudo`, "CVE"],
        [t`Cayman Islands Dollar`, "KYD"],
        [t`Chili Peso`, "CLP"],
        [t`China Yuan/Renminbi`, "CNY"],
        [t`Colombia Peso`, "COP"],
        [t`Comoros Franc`, "KMF"],
        [t`Congo Franc`, "CDF"],
        [t`Costa Rica Colon`, "CRC"],
        [t`Croatia Kuna`, "HRK"],
        [t`Cuba Convertible Peso`, "CUC"],
        [t`Cuba Peso`, "CUP"],
        [t`Czech Koruna`, "CZK"],
        [t`Denmark Krone`, "DKK"],
        [t`Djibouti Franc`, "DJF"],
        [t`Dominican Republich Peso`, "DOP"],
        [t`East Caribbean Dollar`, "XCD"],
        [t`Egypt Pound`, "EGP"],
        [t`El Salvador Colon`, "SVC"],
        [t`Ethiopia Birr`, "ETB"],
        [t`Falkland Islands Pound`, "FKP"],
        [t`Fiji Dollar`, "FJD"],
        [t`Gambia Dalasi`, "GMD"],
        [t`Georgia Lari`, "GEL"],
        [t`Ghana New Cedi`, "GHS"],
        [t`Gibraltar Pound`, "GIP"],
        [t`Guatemala Quetzal`, "GTQ"],
        [t`Guinea Franc`, "GNF"],
        [t`Guyana Dollar`, "GYD"],
        [t`Haiti Gourde`, "HTG"],
        [t`Honduras Lempira`, "HNL"],
        [t`Hong Kong Dollar`, "HKD"],
        [t`Hungary Forint`, "HUF"],
        [t`Iceland Krona`, "ISK"],
        [t`India Rupee`, "INR"],
        [t`Indonesia Rupiah`, "IDR"],
        [t`Iran Rial`, "IRR"],
        [t`Iraq Dinar`, "IQD"],
        [t`Israel New Shekel`, "ILS"],
        [t`Jamaica Dollar`, "JMD"],
        [t`Jordan Dinar`, "JOD"],
        [t`Kazakhstan Tenge`, "KZT"],
        [t`Kenya Shilling`, "KES"],
        [t`Kuwait Dinar`, "KWD"],
        [t`Kyrgyzstan Som`, "KGS"],
        [t`Laos Kip`, "LAK"],
        [t`Lebanon Pound`, "LBP"],
        [t`Lesotho Loti`, "LSL"],
        [t`Liberia Dollar`, "LRD"],
        [t`Libya Dinar`, "LYD"],
        [t`Macau Pataca`, "MOP"],
        [t`Macedonia Denar`, "MKD"],
        [t`Malagasy Ariary`, "MGA"],
        [t`Malawi Kwacha`, "MWK"],
        [t`Malaysia Ringgit`, "MYR"],
        [t`Maldives Rufiyaa`, "MVR"],
        [t`Mauritania Ouguiya`, "MRO"],
        [t`Mauritius Rupee`, "MUR"],
        [t`Mexico Peso`, "MXN"],
        [t`Moldova Leu`, "MDL"],
        [t`Mongolia Tugrik`, "MNT"],
        [t`Morocco Dirham`, "MAD"],
        [t`Mozambique New Metical`, "MZN"],
        [t`Myanmar Kyat`, "MMK"],
        [t`NL Antilles Guilder`, "ANG"],
        [t`Namibia Dollar`, "NAD"],
        [t`Nepal Rupee`, "NPR"],
        [t`New Zealand Dollar`, "NZD"],
        [t`Nicaragua Cordoba Oro`, "NIO"],
        [t`Nigeria Naira`, "NGN"],
        [t`North Korea Won`, "KPW"],
        [t`Norway Kroner`, "NOK"],
        [t`Oman Rial`, "OMR"],
        [t`Pakistan Rupee`, "PKR"],
        [t`Panama Balboa`, "PAB"],
        [t`Papua New Guinea Kina`, "PGK"],
        [t`Paraguay Guarani`, "PYG"],
        [t`Peru Nuevo Sol`, "PEN"],
        [t`Philippines Peso`, "PHP"],
        [t`Poland Zloty`, "PLN"],
        [t`Qatar Rial`, "QAR"],
        [t`Romania New Lei`, "RON"],
        [t`Rwanda Franc`, "RWF"],
        [t`Samoa Tala`, "WST"],
        [t`Sao Tome/Principe Dobra`, "STD"],
        [t`Saudi Arabia Riyal`, "SAR"],
        [t`Serbia Dinar`, "RSD"],
        [t`Seychelles Rupee`, "SCR"],
        [t`Sierra Leone Leone`, "SLL"],
        [t`Singapore Dollar`, "SGD"],
        [t`Solomon Islands Dollar`, "SBD"],
        [t`Somali Shilling`, "SOS"],
        [t`South Africa Rand`, "ZAR"],
        [t`South Korea Won`, "KRW"],
        [t`Sri Lanka Rupee`, "LKR"],
        [t`St Helena Pound`, "SHP"],
        [t`Sudan Pound`, "SDG"],
        [t`Suriname Dollar`, "SRD"],
        [t`Swaziland Lilangeni`, "SZL"],
        [t`Sweden Krona`, "SEK"],
        [t`Syria Pound`, "SYP"],
        [t`Taiwan Dollar`, "TWD"],
        [t`Tanzania Shilling`, "TZS"],
        [t`Thailand Baht`, "THB"],
        [t`Tonga Pa'an`, "TOP"],
        [t`Trinidad/Tobago Dollar`, "TTD"],
        [t`Tunisia Dinar`, "TND"],
        [t`Turkish New Lira`, "TRY"],
        [t`Turkmenistan Manat`, "TMM"],
        [t`Uganda Shilling`, "UGX"],
        [t`Ukraine Hryvnia`, "UAH"],
        [t`Uzbekistan Sum`, "UZS"],
        [t`Uruguay Peso`, "UYU"],
        [t`United Arab Emirates Dirham`, "AED"],
        [t`Vanuatu Vatu`, "VUV"],
        [t`Venezuela Bolivar`, "VEB"],
        [t`Vietnam Dong`, "VND"],
        [t`Yemen Rial`, "YER"],
        [t`Zambi Kwacha`, "ZMK"],
        [t`Zimbabwe Dollar`, "ZWD"]
    ];

    const options = currencies.map(currency =>
        <option key={currency[1]} value={currency[1]}>{currency[0]} - {currency[1]}</option>
    );

    return (
        <React.Fragment>
            {options}
        </React.Fragment>
    );
};

export default Currencies;
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {LicenseV2NumberClaimValue} from "../api/LicenseV2Claims";
import {CInput, CPopover, CRow} from "@coreui/react";
import {t} from "ttag";

interface EditLicenseV2NumberClaimProps {
    label?: string;
    editClaimValue: LicenseV2NumberClaimValue;
    onSetEditClaimValue: (value: LicenseV2NumberClaimValue) => void;
}

function validate(value: string | null | undefined): boolean {
    return !!value && !!value.match(/^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/g);
}

const EditLicenseV2NumberClaim = observer((props: EditLicenseV2NumberClaimProps) => {
    const [isFromValid, setIsFromValid] = useState(false);
    const [isUptoValid, setIsUptoValid] = useState(false);

    const fromInputRef = useRef<HTMLInputElement>();
    const uptoInputRef = useRef<HTMLInputElement>();

    if (!props.editClaimValue) {
        return null;
    }

    useEffect(() => {
        setIsFromValid(!fromInputRef?.current?.value || validate(fromInputRef?.current?.value));

        setIsUptoValid(!uptoInputRef?.current?.value || validate(uptoInputRef?.current?.value));
    }, [fromInputRef?.current?.value, uptoInputRef?.current?.value]);

    const onChangeFrom = (value: string) => {
        if (!validate(value)) {
            props.onSetEditClaimValue({
                range_from: undefined
            } as LicenseV2NumberClaimValue);
        } else {
            props.onSetEditClaimValue({
                range_from: +value
            } as LicenseV2NumberClaimValue);
        }
    };

    const onChangeUpto = (value: string) => {
        if (!validate(value)) {
            props.onSetEditClaimValue({
                range_upto: undefined
            } as LicenseV2NumberClaimValue);
        } else {
            props.onSetEditClaimValue({
                range_upto: +value
            } as LicenseV2NumberClaimValue);
        }
    };

    return (
        <React.Fragment>
            {props.label && <CRow gutters={false}>
                <strong>{props.label}</strong>
            </CRow>}

            <CRow gutters={false}>
                <strong>{t`From`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Example 12345.67`} placement="right"
                          advancedOptions={{onShow: () => !isFromValid}}>
                    <CInput type="text"
                            innerRef={fromInputRef}
                            invalid={!isFromValid}
                            defaultValue={!props.editClaimValue.range_from && props.editClaimValue.range_from !== 0 ? "" : props.editClaimValue.range_from}
                            onChange={(event) => onChangeFrom((event.target as HTMLInputElement).value)}/>
                </CPopover>
            </CRow>

            <CRow gutters={false}>
                <strong>{t`Up to`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Example 12345.67`} placement="right"
                          advancedOptions={{onShow: () => !isUptoValid}}>
                    <CInput type="text"
                            innerRef={uptoInputRef}
                            invalid={!isUptoValid}
                            defaultValue={!props.editClaimValue.range_upto && props.editClaimValue.range_upto !== 0 ? "" : props.editClaimValue.range_upto}
                            onChange={(event) => onChangeUpto((event.target as HTMLInputElement).value)}/>
                </CPopover>
            </CRow>
        </React.Fragment>
    );
});

export default EditLicenseV2NumberClaim;
import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {CCard, CCardBody, CCardHeader, CCol, CRow, CSpinner} from "@coreui/react";
import {t} from "ttag";
import {CChartLine} from "@coreui/react-chartjs";
import Error from "./Error";
import {defaultChartOptions} from "../utils/ChartUtils";

const ProductReportsCharts = observer(() => {
    const store = useContext(StoreContext);
    const {productsUsageAgg} = store;

    return productsUsageAgg.error || productsUsageAgg.loading ? (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`Charts`}
                    </CCardHeader>

                    <CCardBody>
                        {productsUsageAgg.error && <Error>{productsUsageAgg.error}</Error>}
                        {productsUsageAgg.loading && <CSpinner color="primary" style={{width:'4rem', height:'4rem'}}/>}
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    ): (
        <React.Fragment>
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => productsUsageAgg.setShowTotalSessions(!productsUsageAgg.showTotalSessions)}>
                            {t`Total product usages`}
                        </CCardHeader>

                        {productsUsageAgg.showTotalSessions &&
                            <CCardBody>
                                <CChartLine
                                        style={{height: '250px', marginTop: '0px'}}
                                        datasets={productsUsageAgg.aggTotalSessionsDatasets}
                                        options={defaultChartOptions}
                                        labels={productsUsageAgg.aggDateLabels}/>
                            </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => productsUsageAgg.setShowPlayers(!productsUsageAgg.showPlayers)}>
                            {t`Players`}
                        </CCardHeader>

                        {productsUsageAgg.showPlayers &&
                            <CCardBody>
                                <CChartLine
                                        style={{height: '250px', marginTop: '0px'}}
                                        datasets={productsUsageAgg.aggPlayersDatasets}
                                        options={defaultChartOptions}
                                        labels={productsUsageAgg.aggDateLabels}/>
                            </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader role="button" onClick={() => productsUsageAgg.setShowDuration(!productsUsageAgg.showDuration)}>
                            {t`Duration (min)`}
                        </CCardHeader>

                        {productsUsageAgg.showDuration &&
                            <CCardBody>
                                <CChartLine
                                        style={{height: '250px', marginTop: '0px'}}
                                        datasets={productsUsageAgg.aggDurationDatasets}
                                        options={defaultChartOptions}
                                        labels={productsUsageAgg.aggDateLabels}/>
                            </CCardBody>}
                    </CCard>
                </CCol>
            </CRow>
        </React.Fragment>
    );
});

export default ProductReportsCharts;
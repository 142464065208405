import {observer} from "mobx-react-lite";
import {LicenseV2, Product} from "../generated/graphql";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {
    CBadge,
    CCarousel,
    CCarouselControl,
    CCarouselIndicators, CCarouselInner,
    CCarouselItem,
    CImg, CRow,
    CWidgetDropdown
} from "@coreui/react";
import {t} from "ttag";
import {ProductsStore} from "../stores/ProductsStore";
import Price from "./Price";
import Royalty from "./Royalty";
import {DateAndTime, DateOnlyFormat} from "./DateAndTime";
import PriceCalendar from "./PriceCalendar";
import {LicenseV2Claim, LicenseV2ClaimId} from "../api/LicenseV2Claims";
import LicenseV2Claims from "./LicenseV2Claims";

const getLicenseStatusBadgeColor = (isRevoked: boolean, isExpired: boolean) =>
    isRevoked ? "secondary" : (isExpired ? "danger" : "success");

const getStatusBadgeColor = (isArchived: boolean) => isArchived ? "secondary" : "success";

const UserLicenseV2Widget = observer((props: {license: LicenseV2}) => {
    const store = useContext(StoreContext);
    const {products} = store;

    const productIds = props.license.claims?.find((claim: LicenseV2Claim) => claim?.id === LicenseV2ClaimId.ALLOW_PRODUCTS)?.value?.exact || [];

    const licensedProducts = productIds.map((id: string) => products.products && products.products.find(p => p?.id === id)).filter((p: Product) => !!p);

    const licensedProductsImgBase64 = Object.fromEntries(licensedProducts.map((p: Product) =>
        [p.id, ProductsStore.getImage(products.products, p.id)]
    ));

    const isRevoked = props.license.isRevoked;
    const isExpired = props.license.expirateAtUtc && new Date(Date.now()) > new Date(props.license.expirateAtUtc);

    const isVenueArchived = !!props.license.venueByVenueId?.isArchived;

    const location = props.license.venueByVenueId ? `${props.license.venueByVenueId.country} ${props.license.venueByVenueId.city} ${props.license.venueByVenueId.location}` : "";

    return (
        <CWidgetDropdown header={location}
                         color={props.license.isRevoked ? "secondary" : "gradient-dark"}
                         footerSlot={
                             <React.Fragment>
                                 <div className="px-3 py-2">
                                     <CCarousel animate={false} className="mb-3">
                                         <CCarouselInner>
                                             {licensedProducts.map((p: Product) => {
                                                 const imgBase64 = licensedProductsImgBase64[p.id];

                                                 if (!imgBase64) {
                                                     return null;
                                                 }

                                                 return (
                                                         <CCarouselItem key={p.id}>
                                                             <CImg className="d-block w-100"
                                                                   alt={p.name || t`Product`}
                                                                   title={p.name || t`Product`}
                                                                   src={imgBase64}
                                                                   height="250"
                                                                   style={{
                                                                       maxWidth: "100%",
                                                                       objectFit: "cover",
                                                                       objectPosition: "center",
                                                                       margin: "auto"
                                                                   }}/>
                                                         </CCarouselItem>
                                                 );
                                             })}
                                         </CCarouselInner>

                                         <CCarouselControl direction="prev"/>
                                         <CCarouselControl direction="next"/>
                                         <CCarouselIndicators/>
                                     </CCarousel>

                                     <dl className="row">
                                         <dt className="col-sm-4">{t`Venue`}</dt>
                                         <dd className="col-sm-8">
                                             {location}
                                             <CBadge color={getStatusBadgeColor(isVenueArchived)}>
                                                 {isVenueArchived ? t`archived` : t`active`}
                                             </CBadge>
                                         </dd>

                                         <dt className="col-sm-4 text-truncate" title={t`License key`}>{t`License key`}</dt>
                                         <dd className="col-sm-8">
                                             {props.license.licenseKey}
                                         </dd>

                                         <dt className="col-sm-4">{t`Status`}</dt>
                                         <dd className="col-sm-8">
                                             <CBadge color={getLicenseStatusBadgeColor(isRevoked, isExpired)}>
                                                 {isRevoked ? t`revoked` : (isExpired ? t`expired` : t`active`)}
                                             </CBadge>
                                         </dd>

                                         {props.license.expirateAtUtc && <React.Fragment>
                                             <dt className="col-sm-4 text-truncate" title={t`Expiration date`}>{t`Expiration date`}</dt>
                                             <dd className="col-sm-8">
                                                 <DateAndTime date={new Date(props.license.expirateAtUtc)} format={DateOnlyFormat}/>
                                             </dd>
                                         </React.Fragment>}
                                     </dl>

                                     <CRow gutters={false}>
                                         <h5>
                                             <strong>{t`Claims`}</strong>
                                         </h5>
                                     </CRow>

                                     <div style={{border: "1px solid #d8dbe0"}} className="px-3 py-2">
                                        <LicenseV2Claims claims={props.license.claims}/>
                                     </div>
                                 </div>
                             </React.Fragment>
                         }>
        </CWidgetDropdown>
    );
});

export default UserLicenseV2Widget;
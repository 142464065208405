import {action, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {LicenseV2, LicenseV2Filter, Maybe, Query} from "../generated/graphql";
import {
    apolloClient,
    DelayMSDefault,
    PageInfo,
    PageParam,
    PageSizeDefault,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import {debounceEffect} from "../utils/MobxUtils";
import LicenseV2Fragment from "../api/graphql/LicenseV2Fragment";
import {compareLicenseV2Claim} from "../utils/LicenseV2ClaimUtils";

class LicensesV2Store {
    @observable.ref
    public filter: LicenseV2Filter | undefined = undefined;

    @observable.ref
    public page: PageParam = {
        after: null,
        before: null,
        isLast: false
    };

    @observable.ref
    public licenses: Maybe<LicenseV2>[] | undefined = undefined;

    @observable.ref
    public pageInfo: PageInfo | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => {
                return {
                    filter: this.filter,
                    page: this.page
                };
            },
            debounceEffect(() => {
                this.fetchLicenses();
            }, DelayMSDefault)
        );
    }

    @action
    public fetchLicenses() {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseV2Filter | undefined,
            afterInput: string | null,
            firstInput: number | null,
            beforeInput: string | null,
            lastInput: number | null
        }>({
            query: gql`query ($filterInput: LicenseV2Filter, $afterInput: Cursor, $firstInput: Int, $beforeInput: Cursor, $lastInput: Int) {
                currentUserId,
                allLicenseV2S(orderBy: [CREATED_AT_UTC_DESC], filter: $filterInput, after: $afterInput, first: $firstInput, before: $beforeInput, last: $lastInput) {
                    nodes {
                        ...LicenseV2Fragment
                    },
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    totalCount
                }
            },
            ${LicenseV2Fragment}`,
            variables: {
                filterInput: this.filter,
                afterInput: this.page.after,
                firstInput: this.page.after || (!this.page.before && !this.page.isLast) ? PageSizeDefault : null,
                beforeInput: this.page.before,
                lastInput: this.page.before || this.page.isLast ? PageSizeDefault : null
            }
        })).then(result => {
            runInAction(() => {
                this.licenses = result.data.allLicenseV2S?.nodes?.map(license => {
                    if (!license) {
                        return license;
                    }

                    return {
                        ...license,
                        claims: [...license.claims].sort(compareLicenseV2Claim)
                    }
                });
                this.pageInfo = {
                    firstPage: !result.data.allLicenseV2S?.pageInfo.hasPreviousPage ? undefined : {
                        after: null,
                        before: null
                    },
                    lastPage: !result.data.allLicenseV2S?.pageInfo.hasNextPage ? undefined : {
                        after: null,
                        before: null,
                        isLast: true
                    },
                    nextPage: !result.data.allLicenseV2S?.pageInfo.hasNextPage ? undefined : {
                        after: result.data.allLicenseV2S?.pageInfo.endCursor as string,
                        before: null
                    },
                    prevPage: !result.data.allLicenseV2S?.pageInfo.hasPreviousPage ? undefined : {
                        after: null,
                        before: result.data.allLicenseV2S?.pageInfo.startCursor as string
                    },
                    totalCount: result.data.allLicenseV2S?.totalCount || 0
                };
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public setFilter(filter: LicenseV2Filter | undefined) {
        this.filter = filter;

        if (this.pageInfo?.firstPage) {
            this.setPage(this.pageInfo.firstPage);
        }
    }

    @action
    public setPage(page: PageParam) {
        this.page = page;
    }

    @action
    public reset() {
        this.filter = undefined;
        this.page = {
            after: null,
            before: null,
            isLast: false
        };
        this.licenses = undefined;
        this.pageInfo = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    LicensesV2Store
};
import React, {useContext} from "react"
import {observer, Observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CLink,
    CRow,
} from "@coreui/react";
import {c, t} from "ttag";
import Pagination from "../Pagination";
import {Maybe, User, UserRoleType} from "../../generated/graphql";
import {DateAndTime} from "../DateAndTime";
import CIcon from "@coreui/icons-react";
import EditUser from "../EditUser";
import Error from "../Error";

const getStatusBadgeColor = (isDisabled: boolean) => isDisabled ? "secondary" : "success";

const getRoleBadgeColor = (role?: Maybe<UserRoleType>) => {
    switch (role) {
        case UserRoleType.VerControlpanelAdmin:
            return "danger";
        case UserRoleType.VerControlpanelReport:
            return "warning";
        default:
            return "secondary";
    }
}

const getRoleText = (role?: Maybe<UserRoleType>) => {
    switch (role) {
        case UserRoleType.VerControlpanelAdmin:
            return t`admin`;
        case UserRoleType.VerControlpanelReport:
            return t`report`;
        case UserRoleType.VerControlpanelUser:
            return t`user`;
        default:
            return t`unknown`;
    }
}

const fields = [
    {key: "name", _style: {width: "10%"}},
    {key: "login", _style: {width: "10%"}},
    {key: "locale", _style: {width: "5%"}},
    {key: "timezone", _style: {width: "20%"}},
    {key: "isDisabled", _style: {width: "7%"}},
    {key: "role", _style: {width: "7%"}},
    "attachedCustomer",
    {key: "createdAtUtc", _style: {width: "5%"}},
    {
        key: "edit",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const UsersView = observer(() => {
    const store = useContext(StoreContext);
    const {users, editUser, newUser} = store;

    const toggleEdit = (user: User) => {
        if (editUser.user?.id === user.id) {
            editUser.setUser(undefined);
        } else {
            editUser.setUser(user);
        }
    };

    const saveUser = (name: string,
                      locale: string,
                      timezone: string,
                      isDisabled?: boolean,
                      role?: UserRoleType,
                      customerId?: string,
                      login?: string,
                      password?: string) => {
        editUser.save(name, locale, timezone, !!isDisabled, password);
    };

    const createUser = (name: string,
                        locale: string,
                        timezone: string,
                        isDisabled?: boolean,
                        role?: UserRoleType,
                        customerId?: string,
                        login?: string,
                        password?: string) => {
        if (login && password && role) {
            newUser.create(name, locale, timezone, login, password, role, customerId);
        }
    };

    const filter = (str: string) => {
        users.setFilter(!str ? undefined : {
            or: [
                {
                    name: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    login: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    userCustomerByUserId: {
                        customerByCustomerId: {
                            name: {
                                likeInsensitive: `%${str}%`
                            }
                        }
                    }
                }
            ]
        });
    };

    const refresh = () => {
        users.fetchUsers();
    };

    return (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`Users`}

                        <CLink className="ml-1"
                               title={t`Refresh`}
                               onClick={() => refresh()}
                               disabled={users.loading}>
                            <CIcon name="cil-sync" className="mfe-2" />
                        </CLink>
                    </CCardHeader>

                    <CCardBody>
                        <CDropdown>
                            <CDropdownToggle color="primary">
                                {t`New user`}
                            </CDropdownToggle>

                            <CDropdownMenu>
                                <div className="px-4 py-3">
                                    <EditUser onSubmit={createUser}
                                              onCancel={() => newUser.reset()}
                                              loading={newUser.loading}
                                              error={newUser.error}
                                              isVertical={true}
                                              hideCancel={true}/>
                                </div>
                            </CDropdownMenu>
                        </CDropdown>

                        <CDataTable
                                noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                items={(users.users && users.users.length > 0 && users.users) || []}
                                loading={users.loading}
                                fields={fields}
                                border={true}
                                tableFilter={{external: true, label: t`Filter:`, placeholder: t`type username, login, customer`}}
                                onTableFilterChange={filter}
                                columnHeaderSlot={{
                                    name: t`Username`,
                                    login: c("username").t`Login`,
                                    locale: t`Locale`,
                                    timezone: t`Timezone`,
                                    isDisabled: t`Status`,
                                    role: t`Role`,
                                    attachedCustomer: t`Customer`,
                                    createdAtUtc: t`Registered`
                                }}
                                scopedSlots={{
                                    isDisabled: (user: User) => (
                                        <td>
                                            <CBadge color={getStatusBadgeColor(!!user.isDisabled)}>
                                                {user.isDisabled ? t`disabled` : t`active`}
                                            </CBadge>
                                        </td>
                                    ),
                                    role: (user: User) => (
                                        <td>
                                            <CBadge color={getRoleBadgeColor(user.role)}>
                                                {getRoleText(user.role)}
                                            </CBadge>
                                        </td>
                                    ),
                                    attachedCustomer: (user: User) => {
                                        return <td>
                                            {user.userCustomerByUserId?.customerByCustomerId?.name}
                                            {user.userCustomerByUserId?.customerByCustomerId?.name &&
                                            !!user.userCustomerByUserId?.customerByCustomerId?.isArchived &&
                                                <CBadge color="secondary">
                                                    {t`archived`}
                                                </CBadge>}
                                        </td>;
                                    },
                                    createdAtUtc: (user: User) => (
                                        <td>
                                            <DateAndTime date={new Date(user.createdAtUtc)}/>
                                        </td>
                                    ),
                                    edit: (user: User) =>
                                        <Observer>{() =>
                                            <td className="py-2">
                                                <CLink onClick={() => toggleEdit(user)} disabled={editUser.loading}>
                                                    <CIcon name="cil-pencil" className="mfe-2" />
                                                </CLink>
                                            </td>
                                        }</Observer>,
                                    details: (user: User) =>
                                            <Observer key={user.id}>{() => {
                                                return (
                                                        <React.Fragment>
                                                            {editUser.user?.id === user.id && <CCardBody>
                                                                <EditUser onSubmit={saveUser}
                                                                          onCancel={() => editUser.reset()}
                                                                          user={user}
                                                                          loading={editUser.loading}
                                                                          error={editUser.error}/>
                                                            </CCardBody>}
                                                        </React.Fragment>
                                                );
                                            }}</Observer>
                                }}
                                underTableSlot={users.error ? <Error>{users.error}</Error> : <Pagination loading={users.loading}
                                                            pageInfo={users.pageInfo}
                                                            onPage={(page) => users.setPage(page)}/>}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
});

export default UsersView;
import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Mutation,
    PriceCalendarDefinition,
    PriceDefinition,
    RoyaltyDefinition
} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";

class NewVenueStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async create(
        customerId: string,
        country: string,
        city: string,
        location: string,
        timezone: string,
        priceDefault?: PriceDefinition,
        priceCalendarDefault?: PriceCalendarDefinition[],
        royaltyDefault?: RoyaltyDefinition
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.createVenue(customerId, country, city, location, timezone, priceDefault, priceCalendarDefault, royaltyDefault);

            this.rootStore.venues.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private createVenue(customerId: string,
                        country: string,
                        city: string,
                        location: string,
                        timezone: string,
                        priceDefault?: PriceDefinition,
                        priceCalendarDefault?: PriceCalendarDefinition[],
                        royaltyDefault?: RoyaltyDefinition): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            customerId: string,
            country: string,
            city: string,
            location: string,
            timezone: string,
            priceDefault?: PriceDefinition,
            priceCalendarDefault?: PriceCalendarDefinition[],
            royaltyDefault?: RoyaltyDefinition
        }>({
            mutation: gql`mutation ($customerId: UUID!, $country: String!, $city: String!, $location: NameType!, $timezone: String!, $priceDefault: PriceDefinitionInput, $priceCalendarDefault: [PriceCalendarDefinitionInput], $royaltyDefault: RoyaltyDefinitionInput) {
                createVenue(input: {venue: {customerId: $customerId, country: $country, city: $city, location: $location, timezone: $timezone, priceDefault: $priceDefault, priceCalendarDefault: $priceCalendarDefault, royaltyDefault: $royaltyDefault}}) {
                    clientMutationId
                }
            }`,
            variables: {
                customerId,
                country,
                city,
                location,
                timezone,
                priceDefault,
                priceCalendarDefault,
                royaltyDefault
            }
        }));
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }
}

export {
    NewVenueStore
};
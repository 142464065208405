import gql from "graphql-tag";
import LicenseSessionFragment from "./LicenseSessionFragment";

const MasterLicenseSessionFragment = gql`fragment MasterLicenseSessionFragment on LicenseSession {
    ...LicenseSessionFragment,
    licenseSessionsByMasterSessionId(orderBy: [CREATED_AT_UTC_DESC]) {
        nodes {
            ...LicenseSessionFragment
        }
    }
}, ${LicenseSessionFragment}`;

export default MasterLicenseSessionFragment;
import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {StoreContext} from "../context/StoreContext";
import {Customer} from "../generated/graphql";

const Customers = observer(() => {
    const store = useContext(StoreContext);
    const {activeCustomers} = store;

    const options = (activeCustomers.allActiveCustomers ? activeCustomers.allActiveCustomers : []).filter(customer => !!customer)
            .map((customer: Customer | null) =>
                    <option key={customer?.id} value={customer?.id ?? ""}>{customer?.name}</option>
            );
    return (
            <React.Fragment>
                {options}
            </React.Fragment>
    );
});

export default Customers;
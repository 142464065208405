import {
    cifUs,
    cifRu
} from '@coreui/icons'
import {
    cilAccountLogout,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplications,
    cilApplicationsSettings,
    cilArrowRight,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBasketball,
    cilBell,
    cilBold,
    cilBookmark,
    cilBriefcase,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDataTransferDown,
    cilDrop,
    cilDollar,
    cilDescription,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilGraph,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilLoopCircular,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilScrubber,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilStorage,
    cilSun,
    cilSync,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning
} from '@coreui/icons'

export const icons = Object.assign({}, {}, {
    cilAccountLogout,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplications,
    cilApplicationsSettings,
    cilArrowRight,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBasketball,
    cilBell,
    cilBold,
    cilBookmark,
    cilBriefcase,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDataTransferDown,
    cilDrop,
    cilDollar,
    cilDescription,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilGraph,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilLoopCircular,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilScrubber,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilStorage,
    cilSun,
    cilSync,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning
}, {
    cifUs,
    cifRu
})

import gql from "graphql-tag";

const ProductFullFragment = gql`fragment ProductFullFragment on Product {
    id,
    isArchived,
    name,
    namesAll,
    imgBase64,
    createdAtUtc,
    isGame
}`;

export default ProductFullFragment;
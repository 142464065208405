import {StatusCodes} from "http-status-codes";
import {AbstractApi, wrapForApiErrors} from "./AbstractApi";
import {toISODate} from "../utils/DateUtils";

class LoadReportApi extends AbstractApi {
    protected baseUrl(): string {
        return `${super.baseUrl()}/api/v1/loadreport`;
    }

    public submitTask(day: Date): Promise<Response> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/submit_task?day=${encodeURIComponent(toISODate(day) as string)}`, {
            method: "POST",
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK]);
    }
}

const loadReportApi = new LoadReportApi();

export default loadReportApi;
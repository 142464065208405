import {action, autorun, observable} from "mobx";
import {UserRoleType} from "../generated/graphql";
import {RootStore} from "./RootStore";
import {AggregateDateType} from "../api/LicenseSessionsApi";
import {addYears, beginOfTheYear} from "../utils/DateUtils";

const localStorageDashboardViewKey = "dashboardView";

enum DashboardViewType {
    CurrentMonth = "CurrentMonth",
    TwoLastYearsByMonth = "TwoLastYearsByMonth"
}

class DashboardStore {
    @observable
    public view: DashboardViewType = DashboardViewType.CurrentMonth;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.rootStore.auth?.currentUser && !this.rootStore.auth.isServiceUser) {
                const localStorageVal = window.localStorage.getItem(localStorageDashboardViewKey);

                if (localStorageVal) {
                    this.setView(localStorageVal as DashboardViewType);
                } else if (this.rootStore.auth?.currentUser.role === UserRoleType.VerControlpanelAdmin) {
                    this.setView(DashboardViewType.TwoLastYearsByMonth);
                }
            }
        });
    }

    @action
    public setView(view: DashboardViewType) {
        this.view = view;

        window.localStorage.setItem(localStorageDashboardViewKey, view);

        this.reset();
    }

    @action
    public reset() {
        this.rootStore.reportsFilter.reset();

        switch (this.view) {
            case DashboardViewType.TwoLastYearsByMonth:
                this.rootStore.reportsFilter.setAggregateDateType(AggregateDateType.ByMonth);

                this.rootStore.reportsFilter.setFilter({
                    filterDateStart: beginOfTheYear(addYears(new Date(), -2))
                });

                break;
        }

        this.rootStore.licenseSessionsAgg.reset();
    }
}

export {
    DashboardStore,
    DashboardViewType
}
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {LicenseV2Claim, LicenseV2RoyaltyClaimValue} from "../api/LicenseV2Claims";
import {CInput, CInputGroup, CInputGroupAppend, CInputGroupText, CPopover, CRow, CSelect} from "@coreui/react";
import {t} from "ttag";
import Currencies from "./Currencies";
import {moneyToDisplayStr} from "../utils/MoneyUtils";
import RoyaltyVariants from "./RoyaltyVariants";
import {RoyaltyVariantType} from "../generated/graphql";

interface EditLicenseV2RoyaltyClaimProps {
    editClaim: LicenseV2Claim;
    onSetEditClaimValue: (value: LicenseV2RoyaltyClaimValue) => void;
}

function validateRoyalty(value: string | null | undefined): boolean {
    return !!value && !!value.match(/^(([1-9][0-9]{0,6})|0)([.][0-9]{1,3})?$/g);
}

function validateRoyaltyShare(value: string | null | undefined): boolean {
    return !!value && !!value.match(/^(([1-9][0-9]?)|0)([.][0-9]{1,2})?$/g);
}

const EditLicenseV2RoyaltyClaim = observer((props: EditLicenseV2RoyaltyClaimProps) => {
    const [isRoyaltyValid, setIsRoyaltyValid] = useState(false);
    const [isRoyaltyShareValid, setIsRoyaltyShareValid] = useState(false);

    const royaltyInputRef = useRef<HTMLInputElement>();
    const royaltyShareInputRef = useRef<HTMLInputElement>();

    const royaltyValue = props.editClaim.value as LicenseV2RoyaltyClaimValue;

    if (!royaltyValue) {
        return null;
    }

    useEffect(() => {
        setIsRoyaltyValid(validateRoyalty(royaltyInputRef?.current?.value));

        setIsRoyaltyShareValid(validateRoyaltyShare(royaltyShareInputRef?.current?.value));
    }, [royaltyInputRef?.current?.value, royaltyShareInputRef?.current?.value]);

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{t`Royalty type`}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Required`} placement="right"
                          advancedOptions={{onShow: () => !royaltyValue.royalty_variant}}>
                    <CSelect value={royaltyValue.royalty_variant}
                             invalid={!royaltyValue.royalty_variant}
                             onChange={(event) => props.onSetEditClaimValue({
                                 royalty_variant: (event.target as HTMLSelectElement).value
                             } as LicenseV2RoyaltyClaimValue)}>
                        <RoyaltyVariants/>
                    </CSelect>
                </CPopover>
            </CRow>

            {royaltyValue.royalty_variant !== RoyaltyVariantType.PerRevenueShare && <React.Fragment>
                <CRow gutters={false}>
                    <strong>{t`Royalty currency`}</strong>
                </CRow>

                <CRow gutters={false}>
                    <CPopover content={t`Required`} placement="right"
                              advancedOptions={{onShow: () => !royaltyValue.royalty_currency}}>
                        <CSelect value={royaltyValue.royalty_currency}
                                 invalid={!royaltyValue.royalty_currency}
                                 onChange={(event) => props.onSetEditClaimValue({
                                     royalty_currency: (event.target as HTMLSelectElement).value
                                 } as LicenseV2RoyaltyClaimValue)}>
                            <Currencies/>
                        </CSelect>
                    </CPopover>
                </CRow>
            </React.Fragment>}

            {royaltyValue.royalty_variant !== RoyaltyVariantType.PerRevenueShare && <React.Fragment>
                <CRow gutters={false}>
                    <strong>{t`Royalty`}</strong>
                </CRow>

                <CRow gutters={false}>
                    <CPopover content={t`Required, example 12345.67`} placement="right"
                              advancedOptions={{onShow: () => !isRoyaltyValid}}>
                        <CInputGroup>
                            <CInput type="text"
                                    innerRef={royaltyInputRef}
                                    invalid={!isRoyaltyValid}
                                    defaultValue={royaltyValue.royalty && moneyToDisplayStr("" + royaltyValue.royalty) || "0"}
                                    onChange={(event) => props.onSetEditClaimValue({
                                        royalty: +((event.target as HTMLInputElement).value)
                                    } as LicenseV2RoyaltyClaimValue)}/>
                            <CInputGroupAppend>
                                <CInputGroupText>{royaltyValue.royalty_currency}</CInputGroupText>
                            </CInputGroupAppend>
                        </CInputGroup>
                    </CPopover>
                </CRow>
            </React.Fragment>}

            {royaltyValue.royalty_variant === RoyaltyVariantType.PerRevenueShare && <React.Fragment>
                <CRow gutters={false}>
                    <strong>{t`Royalty (%)`}</strong>
                </CRow>

                <CRow gutters={false}>
                    <CPopover content={t`Required, example 15.65`} placement="right"
                              advancedOptions={{onShow: () => !isRoyaltyShareValid}}>
                        <CInputGroup>
                            <CInput type="text"
                                    innerRef={royaltyShareInputRef}
                                    invalid={!isRoyaltyShareValid}
                                    defaultValue={royaltyValue.royalty_share && (+royaltyValue.royalty_share * 100).toFixed(2) || "0"}
                                    onChange={(event) => props.onSetEditClaimValue({
                                        royalty_share: +((event.target as HTMLInputElement).value) / 100
                                    } as LicenseV2RoyaltyClaimValue)}/>
                            <CInputGroupAppend>
                                <CInputGroupText>%</CInputGroupText>
                            </CInputGroupAppend>
                        </CInputGroup>
                    </CPopover>
                </CRow>
            </React.Fragment>}
        </React.Fragment>
    );
});

export default EditLicenseV2RoyaltyClaim;
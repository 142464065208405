import {action, computed, observable, reaction, runInAction} from "mobx";
import {
    CurrencyExchangeRatesFiltered,
    CurrencyExchangeRatesFilteredFilter,
    CurrencyExchangeRatesFilteredYearAgg,
    CurrencyExchangeRatesFilteredYearAggFilter,
    CurrencyExchangeRatesFilteredYearMonthAgg,
    CurrencyExchangeRatesFilteredYearMonthAggFilter,
    Customer,
    LicenseSessionCustomerVenueDateAgg,
    LicenseSessionCustomerVenueDateAggFilter,
    LicenseSessionCustomerVenueDatePriceAgg,
    LicenseSessionCustomerVenueDatePriceAggFilter,
    LicenseSessionCustomerVenueDateRoyaltyAgg,
    LicenseSessionCustomerVenueDateRoyaltyAggFilter,
    LicenseSessionCustomerVenueYearAgg,
    LicenseSessionCustomerVenueYearAggFilter,
    LicenseSessionCustomerVenueYearMonthAgg,
    LicenseSessionCustomerVenueYearMonthAggFilter,
    LicenseSessionCustomerVenueYearMonthPriceAgg,
    LicenseSessionCustomerVenueYearMonthPriceAggFilter,
    LicenseSessionCustomerVenueYearMonthRoyaltyAgg,
    LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter,
    LicenseSessionCustomerVenueYearPriceAgg,
    LicenseSessionCustomerVenueYearPriceAggFilter,
    LicenseSessionCustomerVenueYearRoyaltyAgg,
    LicenseSessionCustomerVenueYearRoyaltyAggFilter,
    Maybe,
    Query,
    UserRoleType,
    Venue
} from "../generated/graphql";
import {RootStore} from "./RootStore";
import {debounceEffect} from "../utils/MobxUtils";
import {apolloClient, DelayMSDefault, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {getDateSeries, getMonthSeries, getYearSeries, toISODate} from "../utils/DateUtils";
import {t} from "ttag";
import {distinctColors} from "../utils/ColorUtils";
import gql from "graphql-tag";
import {
    AggregateCustomersType,
    AggregateDateType,
    CompareSumType,
    CompareType,
    IncludeUnknown,
    IncludeUnpaid
} from "../api/LicenseSessionsApi";
import {
    createDataset,
    getAggCustomerVenueKey,
    getAggDataAggregator,
    getAggSumDataAggregator,
    getLabelPostfixByCompareSumType,
    getLabelPostfixByCompareType,
    getLabelPrefixByKey
} from "../utils/LicenseSessionsAggUtils";

type LicenseSessionAggFilter =
    LicenseSessionCustomerVenueDateAggFilter | LicenseSessionCustomerVenueYearMonthAggFilter | LicenseSessionCustomerVenueYearAggFilter;

type LicenseSessionAgg =
    LicenseSessionCustomerVenueDateAgg | LicenseSessionCustomerVenueYearMonthAgg | LicenseSessionCustomerVenueYearAgg;

type LicenseSessionPriceAggFilter =
    LicenseSessionCustomerVenueDatePriceAggFilter | LicenseSessionCustomerVenueYearMonthPriceAggFilter | LicenseSessionCustomerVenueYearPriceAggFilter

type LicenseSessionPriceAgg =
    LicenseSessionCustomerVenueDatePriceAgg | LicenseSessionCustomerVenueYearMonthPriceAgg | LicenseSessionCustomerVenueYearPriceAgg;

type LicenseSessionRoyaltyAggFilter =
    LicenseSessionCustomerVenueDateRoyaltyAggFilter | LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter | LicenseSessionCustomerVenueYearRoyaltyAggFilter;

type LicenseSessionRoyaltyAgg =
    LicenseSessionCustomerVenueDateRoyaltyAgg | LicenseSessionCustomerVenueYearMonthRoyaltyAgg | LicenseSessionCustomerVenueYearRoyaltyAgg;

type CurrencyExchangeRatesFilter =
    CurrencyExchangeRatesFilteredFilter | CurrencyExchangeRatesFilteredYearAggFilter | CurrencyExchangeRatesFilteredYearMonthAggFilter;

type CurrencyExchangeRates =
    CurrencyExchangeRatesFiltered | CurrencyExchangeRatesFilteredYearAgg | CurrencyExchangeRatesFilteredYearMonthAgg;

function getCurrencyExchangeRateDateKey(date: {year?: number | null, month?: number | null, day?: number | null}, aggregateDateType: AggregateDateType): string {
    switch (aggregateDateType) {
        case AggregateDateType.ByDay:
            return `${date.year}-${date.month}-${date.day}`;
        case AggregateDateType.ByMonth:
            return `${date.year}-${date.month}`;
        case AggregateDateType.ByYear:
            return date.year + "";
    }
}

function getAggPaidDataAccessor(isPaid: boolean, compareType: CompareType) {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;
            switch (compareType) {
                case CompareType.Approved:
                    return isPaid ? +aggCaster.paidApprovedAmount : +aggCaster.unpaidApprovedAmount;
                case CompareType.Submitted:
                    return isPaid ? +aggCaster.paidSubmittedAmount : +aggCaster.unpaidSubmittedAmount;
                case CompareType.Original:
                    return isPaid ? +aggCaster.paidOriginalAmount : +aggCaster.unpaidOriginalAmount;
            }
        }

        return 0;
    }
}

function getAggPlayersDataAccessor(compareSumType: CompareSumType, compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalPlayersDataAccessor(compareType, isPaid);
        case CompareSumType.Min:
            return getAggMinPlayersDataAccessor(compareType, isPaid);
        case CompareSumType.Max:
            return getAggMaxPlayersDataAccessor(compareType, isPaid);
    }
}

function getAggDurationDataAccessor(compareSumType: CompareSumType, compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalDurationDataAccessor(compareType, isPaid);
        case CompareSumType.Min:
            return getAggMinDurationDataAccessor(compareType, isPaid);
        case CompareSumType.Max:
            return getAggMaxDurationDataAccessor(compareType, isPaid);
    }
}

function getAggTotalPlayersDataAccessor(compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        let players: number | null | undefined = 0;

        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;

            switch (compareType) {
                case CompareType.Approved:
                    players = isPaid ? aggCaster.paidApprovedPlayers : aggCaster.unpaidApprovedPlayers;
                    break;
                case CompareType.Submitted:
                    players = isPaid ? aggCaster.paidSubmittedPlayers : aggCaster.unpaidSubmittedPlayers;
                    break;
                case CompareType.Original:
                    players = isPaid ? aggCaster.paidOriginalPlayers : aggCaster.unpaidOriginalPlayers;
                    break;
            }

            if (players) {
                players = +players;
            }
        }

        return players || 0;
    };
}

function getAggMinPlayersDataAccessor(compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        let players: number | null | undefined = 0;

        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;

            switch (compareType) {
                case CompareType.Approved:
                    players = isPaid ? aggCaster.paidApprovedPlayersMin : aggCaster.unpaidApprovedPlayersMin;
                    break;
                case CompareType.Submitted:
                    players = isPaid ? aggCaster.paidSubmittedPlayersMin : aggCaster.unpaidSubmittedPlayersMin;
                    break;
                case CompareType.Original:
                    players = isPaid ? aggCaster.paidOriginalPlayersMin : aggCaster.unpaidOriginalPlayersMin;
                    break;
            }

            if (players) {
                players = +players;
            }
        }

        return players || 0;
    };
}

function getAggMaxPlayersDataAccessor(compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        let players: number | null | undefined = 0;

        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;

            switch (compareType) {
                case CompareType.Approved:
                    players = isPaid ? aggCaster.paidApprovedPlayersMax : aggCaster.unpaidApprovedPlayersMax;
                    break;
                case CompareType.Submitted:
                    players = isPaid ? aggCaster.paidSubmittedPlayersMax : aggCaster.unpaidSubmittedPlayersMax;
                    break;
                case CompareType.Original:
                    players = isPaid ? aggCaster.paidOriginalPlayersMax : aggCaster.unpaidOriginalPlayersMax;
                    break;
            }

            if (players) {
                players = +players;
            }
        }

        return players || 0;
    };
}

function getAggTotalDurationDataAccessor(compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        let duration: number | null | undefined = 0;

        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;

            switch (compareType) {
                case CompareType.Approved:
                    duration = isPaid ? aggCaster.paidApprovedDuration : aggCaster.unpaidApprovedDuration;
                    break;
                case CompareType.Submitted:
                    duration = isPaid ? aggCaster.paidSubmittedDuration : aggCaster.unpaidSubmittedDuration;
                    break;
                case CompareType.Original:
                    duration = isPaid ? aggCaster.paidOriginalDuration : aggCaster.unpaidOriginalDuration;
                    break;
            }

            if (duration) {
                duration = +duration;
            }
        }

        return duration || 0;
    };
}

function getAggMinDurationDataAccessor(compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        let duration: number | null | undefined = 0;

        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;

            switch (compareType) {
                case CompareType.Approved:
                    duration = isPaid ? aggCaster.paidApprovedDurationMin : aggCaster.unpaidApprovedDurationMin;
                    break;
                case CompareType.Submitted:
                    duration = isPaid ? aggCaster.paidSubmittedDurationMin : aggCaster.unpaidSubmittedDurationMin;
                    break;
                case CompareType.Original:
                    duration = isPaid ? aggCaster.paidOriginalDurationMin : aggCaster.unpaidOriginalDurationMin;
                    break;
            }

            if (duration) {
                duration = +duration;
            }
        }

        return duration || 0;
    };
}

function getAggMaxDurationDataAccessor(compareType: CompareType, isPaid: boolean): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => {
        let duration: number | null | undefined = 0;

        if (agg) {
            const aggCaster = agg as LicenseSessionAgg;

            switch (compareType) {
                case CompareType.Approved:
                    duration = isPaid ? aggCaster.paidApprovedDurationMax : aggCaster.unpaidApprovedDurationMax;
                    break;
                case CompareType.Submitted:
                    duration = isPaid ? aggCaster.paidSubmittedDurationMax : aggCaster.unpaidSubmittedDurationMax;
                    break;
                case CompareType.Original:
                    duration = isPaid ? aggCaster.paidOriginalDurationMax : aggCaster.unpaidOriginalDurationMax;
                    break;
            }

            if (duration) {
                duration = +duration;
            }
        }

        return duration || 0;
    };
}

function getAggPriceDataAccessor(compareType: CompareType, compareSumType: CompareSumType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalPriceDataAccessor(compareType);
        case CompareSumType.Min:
            return getAggMinPriceDataAccessor(compareType);
        case CompareSumType.Max:
            return getAggMaxPriceDataAccessor(compareType);
    }
}

function getAggTotalPriceDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionPriceAgg;
            let price;

            switch (compareType) {
                case CompareType.Approved:
                    price = aggCaster.priceApproved;
                    break;
                case CompareType.Submitted:
                    price = aggCaster.price;
                    break;
                case CompareType.Original:
                    price = aggCaster.priceOriginal;
                    break;
            }

            if (price) {
                return +price;
            }
        }

        return 0;
    }
}

function getAggMinPriceDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionPriceAgg;
            let price;

            switch (compareType) {
                case CompareType.Approved:
                    price = aggCaster.priceApprovedMin;
                    break;
                case CompareType.Submitted:
                    price = aggCaster.priceMin;
                    break;
                case CompareType.Original:
                    price = aggCaster.priceOriginalMin;
                    break;
            }

            if (price) {
                return +price;
            }
        }

        return 0;
    }
}

function getAggMaxPriceDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionPriceAgg;
            let price;

            switch (compareType) {
                case CompareType.Approved:
                    price = aggCaster.priceApprovedMax;
                    break;
                case CompareType.Submitted:
                    price = aggCaster.priceMax;
                    break;
                case CompareType.Original:
                    price = aggCaster.priceOriginalMax;
                    break;
            }

            if (price) {
                return +price;
            }
        }

        return 0;
    }
}

function getAggRoyaltyDataAccessor(compareType: CompareType, compareSumType: CompareSumType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalRoyaltyDataAccessor(compareType);
        case CompareSumType.Min:
            return getAggMinRoyaltyDataAccessor(compareType);
        case CompareSumType.Max:
            return getAggMaxRoyaltyDataAccessor(compareType);
    }
}

function getAggTotalRoyaltyDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionRoyaltyAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.royaltyApproved;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.royalty;
                    break;
                case CompareType.Original:
                    royalty = aggCaster.royaltyOriginal;
                    break;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggMinRoyaltyDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionRoyaltyAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.royaltyApprovedMin;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.royaltyMin;
                    break;
                case CompareType.Original:
                    royalty = aggCaster.royaltyOriginalMin;
                    break;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggMaxRoyaltyDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionRoyaltyAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.royaltyApprovedMax;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.royaltyMax;
                    break;
                case CompareType.Original:
                    royalty = aggCaster.royaltyOriginalMax;
                    break;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggPriceDiscountDataAccessor(compareType: CompareType, compareSumType: CompareSumType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalPriceDiscountDataAccessor(compareType);
        case CompareSumType.Min:
            return getAggMinPriceDiscountDataAccessor(compareType);
        case CompareSumType.Max:
            return getAggMaxPriceDiscountDataAccessor(compareType);
    }
}

function getAggTotalPriceDiscountDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionPriceAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.priceApprovedDiscount;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.priceDiscount;
                    break;
                default:
                    return 0;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggMinPriceDiscountDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionPriceAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.priceApprovedDiscountMin;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.priceDiscountMin;
                    break;
                default:
                    return 0;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggMaxPriceDiscountDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionPriceAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.priceApprovedDiscountMax;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.priceDiscountMax;
                    break;
                default:
                    return 0;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggRoyaltyDiscountDataAccessor(compareType: CompareType, compareSumType: CompareSumType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggTotalRoyaltyDiscountDataAccessor(compareType);
        case CompareSumType.Min:
            return getAggMinRoyaltyDiscountDataAccessor(compareType);
        case CompareSumType.Max:
            return getAggMaxRoyaltyDiscountDataAccessor(compareType);
    }
}

function getAggTotalRoyaltyDiscountDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionRoyaltyAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.royaltyApprovedDiscount;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.royaltyDiscount;
                    break;
                default:
                    return 0;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggMinRoyaltyDiscountDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionRoyaltyAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.royaltyApprovedDiscountMin;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.royaltyDiscountMin;
                    break;
                default:
                    return 0;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

function getAggMaxRoyaltyDiscountDataAccessor(compareType: CompareType): (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number {
    return (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
        if (agg) {
            const aggCaster = agg as LicenseSessionRoyaltyAgg;
            let royalty;

            switch (compareType) {
                case CompareType.Approved:
                    royalty = aggCaster.royaltyApprovedDiscountMax;
                    break;
                case CompareType.Submitted:
                    royalty = aggCaster.royaltyDiscountMax;
                    break;
                default:
                    return 0;
            }

            if (royalty) {
                return +royalty;
            }
        }

        return 0;
    }
}

class LicenseSessionsAggStore {
    @observable.ref
    public licenseSessionsAgg: Maybe<LicenseSessionAgg>[] | undefined = undefined;

    @observable.ref
    public licenseSessionsPriceAgg: Maybe<LicenseSessionPriceAgg>[] | undefined = undefined;

    @observable.ref
    public licenseSessionsRoyaltyAgg: Maybe<LicenseSessionRoyaltyAgg>[] | undefined = undefined;

    @observable.ref
    public currencyExchangeRates: Maybe<CurrencyExchangeRates>[] | undefined = undefined;

    @observable
    public showTotalActiveCustomers: boolean = true;

    @observable
    public showTotalSessions: boolean = true;

    @observable
    public showPlayers: boolean = false;

    @observable
    public showDuration: boolean = false;

    @observable
    public showPrice: boolean = true;

    @observable
    public showRoyalty: boolean = true;

    @observable
    public showPriceDiscount: boolean = false;

    @observable
    public showRoyaltyDiscount: boolean = false;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(() => this.filter, debounceEffect(() => {
            this.fetchAgg();
        }, DelayMSDefault));
    }

    @computed
    public get filter(): LicenseSessionAggFilter | LicenseSessionPriceAggFilter | LicenseSessionRoyaltyAggFilter | undefined {
        return LicenseSessionsAggStore.constructLicenseSessionAggFilter(
            this.rootStore.reportsFilter.filterIncludeUnknown,
            this.rootStore.reportsFilter.filterCustomers,
            this.rootStore.reportsFilter.filterCustomerTags,
            this.rootStore.reportsFilter.filterVenues,
            this.rootStore.reportsFilter.filterDateStart,
            this.rootStore.reportsFilter.filterDateEnd,
            this.rootStore.reportsFilter.aggregateDateType
        );
    }

    @computed
    public get exchangeRatesFilter(): CurrencyExchangeRatesFilter | undefined {
        return LicenseSessionsAggStore.constructCurrencyExchangeRatesFilter(
            this.rootStore.reportsFilter.filterDateStart,
            this.rootStore.reportsFilter.filterDateEnd,
            this.rootStore.reportsFilter.aggregateDateType
        );
    }

    @computed
    public get aggDateLabels(): string[] {
        return this.aggDateSeries.map(val => {
            if (typeof(val) === "string") {
                return val;
            } else {
                return `${val.year}${val.month ? '-' + val.month : ''}`;
            }
        });
    }

    @computed
    public get aggDateSeries(): (string | {year: string, month?: string})[] {
        const licenseSessionAggFirst =
            this.licenseSessionsAgg && this.licenseSessionsAgg.length > 0 && this.licenseSessionsAgg[0] as LicenseSessionAgg;

        let dateStart = this.rootStore.reportsFilter.filterDateStart ? this.rootStore.reportsFilter.filterDateStart : null;

        const dateEnd = this.rootStore.reportsFilter.filterDateEnd ?
            this.rootStore.reportsFilter.filterDateEnd : new Date();

        switch (this.rootStore.reportsFilter.aggregateDateType) {
            case AggregateDateType.ByDay:
                const licenseSessionAggDayFirst = licenseSessionAggFirst && licenseSessionAggFirst as LicenseSessionCustomerVenueDateAgg;
                if (!dateStart && licenseSessionAggDayFirst) {
                    dateStart = new Date(licenseSessionAggDayFirst.date);
                }
                return !dateStart ? [] : getDateSeries(dateStart, dateEnd);
            case AggregateDateType.ByMonth:
                const licenseSessionAggMonthFirst = licenseSessionAggFirst && licenseSessionAggFirst as LicenseSessionCustomerVenueYearMonthAgg;
                if (!dateStart && licenseSessionAggMonthFirst) {
                    dateStart = new Date(`${licenseSessionAggMonthFirst.year}-${licenseSessionAggMonthFirst.month}`);
                }
                return !dateStart ? [] : getMonthSeries(dateStart, dateEnd);
            case AggregateDateType.ByYear:
                const licenseSessionAggYearFirst = licenseSessionAggFirst && licenseSessionAggFirst as LicenseSessionCustomerVenueYearAgg;
                if (!dateStart && licenseSessionAggYearFirst) {
                    dateStart = new Date(`${licenseSessionAggYearFirst.year}`);
                }
                return !dateStart ? [] : getYearSeries(dateStart, dateEnd);
        }

        return [];
    }

    @computed
    public get aggCustomerNames(): (string | null)[] {
        const allNames = this.licenseSessionsAgg &&
            this.licenseSessionsAgg
                .map(agg => agg?.customerName || null);

        if (allNames && allNames.length > 0) {
            return Array.from(new Set(allNames).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggVenueLocations(): (string | null)[] {
        const isAdmin = this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin;

        const allLocations = this.licenseSessionsAgg &&
            this.licenseSessionsAgg.map(agg => getAggCustomerVenueKey(isAdmin, agg));

        if (allLocations && allLocations.length > 0) {
            return Array.from(new Set(allLocations).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggPriceCurrencies(): string[] {
        const allCurrencies = this.licenseSessionsPriceAgg &&
            this.licenseSessionsPriceAgg
                .map(agg => agg?.currency || "");

        if (allCurrencies && allCurrencies.length > 0) {
            return Array.from(new Set(allCurrencies).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggRoyaltyCurrencies(): string[] {
        const allCurrencies = this.licenseSessionsRoyaltyAgg &&
            this.licenseSessionsRoyaltyAgg
                .map(agg => agg?.currency || "");

        if (allCurrencies && allCurrencies.length > 0) {
            return Array.from(new Set(allCurrencies).values());
        } else {
            return [];
        }
    }

    @computed
    public get aggTotalActiveCustomersDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        if (!this.licenseSessionsAgg) {
            return datasets;
        }

        const compareType = this.rootStore.reportsFilter.compareType;
        const labelPostfix = getLabelPostfixByCompareType(compareType);

        let minSessions = 0;
        switch (this.rootStore.reportsFilter.aggregateDateType) {
            case AggregateDateType.ByDay:
                minSessions = 1;
                break;
            case AggregateDateType.ByMonth:
                minSessions = 20;
                break;
            case AggregateDateType.ByYear:
                minSessions = 240;
                break;
        }

        const customerNames = this.aggCustomerNames;

        const aggDataset = (isPaid: boolean) => {
            return this.aggDateSeries.map(date => {
                const agg = this.filterAggDataByDate(this.licenseSessionsAgg, date);

                if (!agg || agg.length === 0) {
                    return 0;
                }

                return customerNames.filter(customerName => {
                    let accumulator: number = getAggSumDataAggregator();
                    for (let aggRow of agg) {
                        if (!aggRow || !(aggRow.customerName === customerName || !aggRow.customerName && !customerName)) {
                            continue;
                        }

                        accumulator = getAggSumDataAggregator(accumulator, getAggPaidDataAccessor(isPaid, this.rootStore.reportsFilter.compareType)(aggRow));
                    }

                    return getAggSumDataAggregator(accumulator, undefined, agg.length) >= minSessions;
                }).length;
            });
        }

        const paidDataset = createDataset(
            t`Paid ${labelPostfix} active customers (more than ${minSessions} sessions)`,
            distinctColors[currentColorIndex],
            aggDataset(true)
        );

        if (paidDataset) {
            datasets.push(paidDataset);
            currentColorIndex++;
        }

        if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
            const unpaidDataset = createDataset(
                t`Unpaid ${labelPostfix} active customers (more than ${minSessions} sessions)`,
                distinctColors[currentColorIndex],
                aggDataset(false)
            );

            if (unpaidDataset) {
                datasets.push(unpaidDataset);
                currentColorIndex++;
            }
        }

        return datasets;
    }

    @computed
    public get aggTotalSessionsDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareType = this.rootStore.reportsFilter.compareType;

        const labelPostfix = getLabelPostfixByCompareType(compareType);

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const paidDataset = createDataset(
                labelPrefix ? t`${labelPrefix} - paid ${labelPostfix}` : t`Paid ${labelPostfix}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsAgg, key, getAggPaidDataAccessor(true, compareType), getAggSumDataAggregator)
            );

            if (paidDataset) {
                datasets.push(paidDataset);
                currentColorIndex++;
            }

            if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
                const unpaidDataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - unpaid ${labelPostfix}` : t`Unpaid ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(this.licenseSessionsAgg, key, getAggPaidDataAccessor(false, compareType), getAggSumDataAggregator)
                );

                if (unpaidDataset) {
                    datasets.push(unpaidDataset);
                    currentColorIndex++;
                }
            }

            return datasets;
        });
    }

    @computed
    public get aggPlayersDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareType = this.rootStore.reportsFilter.compareType;
        const compareSumType = this.rootStore.reportsFilter.compareSumType;

        const labelPostfixByCompareType = getLabelPostfixByCompareType(compareType);
        const labelPostfixBySumType = getLabelPostfixByCompareSumType(compareSumType);

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const paidDataset = createDataset(
                labelPrefix ? t`${labelPrefix} - paid players ${labelPostfixByCompareType} ${labelPostfixBySumType}` : t`Paid players ${labelPostfixByCompareType} ${labelPostfixBySumType}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsAgg, key, getAggPlayersDataAccessor(compareSumType, compareType, true), getAggDataAggregator(compareSumType))
            );

            if (paidDataset) {
                datasets.push(paidDataset);
                currentColorIndex++;
            }

            if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
                const unpaidDataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - unpaid players ${labelPostfixByCompareType} ${labelPostfixBySumType}` : t`Unpaid players ${labelPostfixByCompareType} ${labelPostfixBySumType}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(this.licenseSessionsAgg, key, getAggPlayersDataAccessor(compareSumType, compareType, false), getAggDataAggregator(compareSumType))
                );

                if (unpaidDataset) {
                    datasets.push(unpaidDataset);
                    currentColorIndex++;
                }
            }

            return datasets;
        });
    }

    @computed
    public get aggDurationDatasets(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareType = this.rootStore.reportsFilter.compareType;
        const compareSumType = this.rootStore.reportsFilter.compareSumType;

        const labelPostfixByCompareType = getLabelPostfixByCompareType(compareType);
        const labelPostfixBySumType = getLabelPostfixByCompareSumType(compareSumType);

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const paidDataset = createDataset(
                labelPrefix ? t`${labelPrefix} - paid duration ${labelPostfixByCompareType} ${labelPostfixBySumType}` : t`Paid duration ${labelPostfixByCompareType} ${labelPostfixBySumType}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsAgg, key, getAggDurationDataAccessor(compareSumType, compareType, true), getAggDataAggregator(compareSumType))
            );

            if (paidDataset) {
                datasets.push(paidDataset);
                currentColorIndex++;
            }

            if (this.rootStore.reportsFilter.filterIncludeUnpaid === IncludeUnpaid.Include) {
                const unpaidDataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - unpaid duration ${labelPostfixByCompareType} ${labelPostfixBySumType}` : t`Unpaid duration ${labelPostfixByCompareType} ${labelPostfixBySumType}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(this.licenseSessionsAgg, key, getAggDurationDataAccessor(compareSumType, compareType, false), getAggDataAggregator(compareSumType))
                );

                if (unpaidDataset) {
                    datasets.push(unpaidDataset);
                    currentColorIndex++;
                }
            }

            return datasets;
        });
    }

    @action
    public setShowTotalActiveCustomers(showTotalActiveCustomers: boolean) {
        this.showTotalActiveCustomers = showTotalActiveCustomers;
    }

    @action
    public setShowTotalSessions(showTotalSessions: boolean) {
        this.showTotalSessions = showTotalSessions;
    }

    @action
    public setShowPlayers(showPlayers: boolean) {
        this.showPlayers = showPlayers;
    }

    @action
    public setShowDuration(showDuration: boolean) {
        this.showDuration = showDuration;
    }

    @action
    public setShowPrice(showPrice: boolean) {
        this.showPrice = showPrice;
    }

    @action
    public setShowRoyalty(showRoyalty: boolean) {
        this.showRoyalty = showRoyalty;
    }

    @action
    public setShowPriceDiscount(showPriceDiscount: boolean) {
        this.showPriceDiscount = showPriceDiscount;
    }

    @action
    public setShowRoyaltyDiscount(showRoyaltyDiscount: boolean) {
        this.showRoyaltyDiscount = showRoyaltyDiscount;
    }

    @computed
    public get currencyExchangeRatesDictionary(): {[currency: string]: {[key: string]: number}} {
        const dict: {[currency: string]: {[key: string]: number}} = {};

        if (!this.currencyExchangeRates) {
            return dict;
        }

        this.currencyExchangeRates.forEach(rate => {
            if (!rate) {
                return;
            }

            if (!dict[rate.currency]) {
                dict[rate.currency] = {};
            }

            const dateKey = getCurrencyExchangeRateDateKey(rate, this.rootStore.reportsFilter.aggregateDateType);

            dict[rate.currency][dateKey] = (rate as any).rate || (rate as any).rateAvg;
        });

        return dict;
    }

    @computed
    public get aggTotalUSDPriceDataset(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareSumType = this.rootStore.reportsFilter.compareSumType;
        const compareType = this.rootStore.reportsFilter.compareType;

        const labelPostfix = `${getLabelPostfixByCompareSumType(compareSumType)} ${getLabelPostfixByCompareType(compareType)}`;

        const aggPriceDataAccessor = getAggPriceDataAccessor(compareType, compareSumType);

        const priceDataAccessor = (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
            const price = aggPriceDataAccessor(agg);

            if (!price) {
                return 0;
            }

            const aggCaster = agg as LicenseSessionPriceAgg;

            if (!aggCaster || !aggCaster.currency || !this.currencyExchangeRatesDictionary[aggCaster.currency]) {
                return 0;
            }

            const exchangeRates = this.currencyExchangeRatesDictionary[aggCaster.currency];

            const dateKey = this.rootStore.reportsFilter.aggregateDateType === AggregateDateType.ByDay ? {
                day: new Date((agg as LicenseSessionCustomerVenueDatePriceAgg).date).getDate(),
                month: new Date((agg as LicenseSessionCustomerVenueDatePriceAgg).date).getMonth() + 1,
                year: new Date((agg as LicenseSessionCustomerVenueDatePriceAgg).date).getFullYear()
            } : aggCaster;

            const exchangeRateDateKey = getCurrencyExchangeRateDateKey(
                dateKey as {day?: number | null, month?: number | null, year?: number | null},
                this.rootStore.reportsFilter.aggregateDateType
            );

            if (!exchangeRateDateKey || !exchangeRates[exchangeRateDateKey]) {
                return 0;
            }

            return price / exchangeRates[exchangeRateDateKey];
        };

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const dataset = createDataset(
                labelPrefix ? t`${labelPrefix} - revenue ${labelPostfix}` : t`Revenue ${labelPostfix}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsPriceAgg, key, priceDataAccessor, getAggDataAggregator(compareSumType))
            );

            if (dataset) {
                datasets.push(dataset);
                currentColorIndex++;
            }

            return datasets;
        });
    }

    @computed
    public get aggPriceDatasets(): {[currency: string]: any[]} {
        const result: {[currency: string]: any[]} = {};

        const compareSumType = this.rootStore.reportsFilter.compareSumType;
        const compareType = this.rootStore.reportsFilter.compareType;

        this.aggPriceCurrencies.forEach(currency => {
            const datasets: any[] = [];
            let currentColorIndex = 0;

            const labelPostfix = `${getLabelPostfixByCompareSumType(compareSumType)} ${getLabelPostfixByCompareType(compareType)}`;

            const priceAgg = !this.licenseSessionsPriceAgg ? undefined : this.licenseSessionsPriceAgg.filter(agg => agg && agg.currency === currency);

            result[currency] = this.getAggDatasets(key => {
                let labelPrefix = getLabelPrefixByKey(key);

                const dataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - revenue ${labelPostfix}` : t`Revenue ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(priceAgg, key, getAggPriceDataAccessor(compareType, compareSumType), getAggDataAggregator(compareSumType))
                );

                if (dataset) {
                    datasets.push(dataset);
                    currentColorIndex++;
                }

                return datasets;
            });
        });

        return result;
    }

    @computed
    public get aggTotalUSDRoyaltyDataset(): any[] {
        const datasets: any[] = [];
        let currentColorIndex = 0;

        const compareSumType = this.rootStore.reportsFilter.compareSumType;
        const compareType = this.rootStore.reportsFilter.compareType;

        const labelPostfix = `${getLabelPostfixByCompareSumType(compareSumType)} ${getLabelPostfixByCompareType(compareType)}`;

        const aggRoyaltyDataAccessor = getAggRoyaltyDataAccessor(compareType, compareSumType);

        const royaltyDataAccessor = (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null): number => {
            const royalty = aggRoyaltyDataAccessor(agg);

            if (!royalty) {
                return 0;
            }

            const aggCaster = agg as LicenseSessionRoyaltyAgg;

            if (!aggCaster || !aggCaster.currency || !this.currencyExchangeRatesDictionary[aggCaster.currency]) {
                return 0;
            }

            const exchangeRates = this.currencyExchangeRatesDictionary[aggCaster.currency];

            const dateKey = this.rootStore.reportsFilter.aggregateDateType === AggregateDateType.ByDay ? {
                day: new Date((agg as LicenseSessionCustomerVenueDateRoyaltyAgg).date).getDate(),
                month: new Date((agg as LicenseSessionCustomerVenueDateRoyaltyAgg).date).getMonth() + 1,
                year: new Date((agg as LicenseSessionCustomerVenueDateRoyaltyAgg).date).getFullYear()
            } : aggCaster;

            const exchangeRateDateKey = getCurrencyExchangeRateDateKey(
                dateKey as {day?: number | null, month?: number | null, year?: number | null},
                this.rootStore.reportsFilter.aggregateDateType
            );

            if (!exchangeRateDateKey || !exchangeRates[exchangeRateDateKey]) {
                return 0;
            }

            return royalty / exchangeRates[exchangeRateDateKey];
        }

        return this.getAggDatasets(key => {
            let labelPrefix = getLabelPrefixByKey(key);

            const dataset = createDataset(
                labelPrefix ? t`${labelPrefix} - royalty ${labelPostfix}` : t`Royalty ${labelPostfix}`,
                distinctColors[currentColorIndex],
                this.getAggData(this.licenseSessionsRoyaltyAgg, key, royaltyDataAccessor, getAggDataAggregator(compareSumType))
            );

            if (dataset) {
                datasets.push(dataset);
                currentColorIndex++;
            }

            return datasets;
        });
    }

    @computed
    public get aggRoyaltyDatasets(): {[currency: string]: any[]} {
        const result: {[currency: string]: any[]} = {};

        const compareSumType = this.rootStore.reportsFilter.compareSumType;
        const compareType = this.rootStore.reportsFilter.compareType;

        this.aggRoyaltyCurrencies.forEach(currency => {
            const datasets: any[] = [];
            let currentColorIndex = 0;

            const labelPostfix = `${getLabelPostfixByCompareSumType(compareSumType)} ${getLabelPostfixByCompareType(compareType)}`;

            const royaltyAgg = !this.licenseSessionsRoyaltyAgg ? undefined : this.licenseSessionsRoyaltyAgg.filter(agg => agg && agg.currency === currency);

            result[currency] = this.getAggDatasets(key => {
                let labelPrefix = getLabelPrefixByKey(key);

                const dataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - royalty ${labelPostfix}` : t`Royalty ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(royaltyAgg, key, getAggRoyaltyDataAccessor(compareType, compareSumType), getAggDataAggregator(compareSumType))
                );

                if (dataset) {
                    datasets.push(dataset);
                    currentColorIndex++;
                }

                return datasets;
            });
        });

        return result;
    }

    @computed
    public get aggPriceDiscountDatasets(): {[currency: string]: any[]} {
        const result: {[currency: string]: any[]} = {};

        const compareSumType = this.rootStore.reportsFilter.compareSumType;
        const compareType = this.rootStore.reportsFilter.compareType;

        this.aggPriceCurrencies.forEach(currency => {
            const datasets: any[] = [];
            let currentColorIndex = 0;

            const labelPostfix = `${getLabelPostfixByCompareSumType(compareSumType)} ${getLabelPostfixByCompareType(compareType)}`;

            const priceAgg = !this.licenseSessionsPriceAgg ? undefined : this.licenseSessionsPriceAgg.filter(agg => agg && agg.currency === currency);

            result[currency] = this.getAggDatasets(key => {
                let labelPrefix = getLabelPrefixByKey(key);

                const dataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - revenue discount ${labelPostfix}` : t`Revenue discount ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(priceAgg, key, getAggPriceDiscountDataAccessor(compareType, compareSumType), getAggDataAggregator(compareSumType))
                );

                if (dataset) {
                    datasets.push(dataset);
                    currentColorIndex++;
                }

                return datasets;
            });
        });

        return result;
    }

    @computed
    public get aggRoyaltyDiscountDatasets(): {[currency: string]: any[]} {
        const result: {[currency: string]: any[]} = {};

        const compareSumType = this.rootStore.reportsFilter.compareSumType;
        const compareType = this.rootStore.reportsFilter.compareType;

        this.aggRoyaltyCurrencies.forEach(currency => {
            const datasets: any[] = [];
            let currentColorIndex = 0;

            const labelPostfix = `${getLabelPostfixByCompareSumType(compareSumType)} ${getLabelPostfixByCompareType(compareType)}`;

            const royaltyAgg = !this.licenseSessionsRoyaltyAgg ? undefined : this.licenseSessionsRoyaltyAgg.filter(agg => agg && agg.currency === currency);

            result[currency] = this.getAggDatasets(key => {
                let labelPrefix = getLabelPrefixByKey(key);

                const dataset = createDataset(
                    labelPrefix ? t`${labelPrefix} - royalty discount ${labelPostfix}` : t`Royalty discount ${labelPostfix}`,
                    distinctColors[currentColorIndex],
                    this.getAggData(royaltyAgg, key, getAggRoyaltyDiscountDataAccessor(compareType, compareSumType), getAggDataAggregator(compareSumType))
                );

                if (dataset) {
                    datasets.push(dataset);
                    currentColorIndex++;
                }

                return datasets;
            });
        });

        return result;
    }

    @action
    public async fetchAgg() {
        this.loading = true;
        this.error = undefined;

        try {
            switch (this.rootStore.reportsFilter.aggregateDateType) {
                case AggregateDateType.ByDay: {
                    const result = await this.aggregateByDateQuery();
                    runInAction(() => {
                        this.licenseSessionsAgg = result.data.allLicenseSessionCustomerVenueDateAggs?.nodes;
                        this.licenseSessionsPriceAgg = result.data.allLicenseSessionCustomerVenueDatePriceAggs?.nodes;
                        this.licenseSessionsRoyaltyAgg = result.data.allLicenseSessionCustomerVenueDateRoyaltyAggs?.nodes;
                        this.currencyExchangeRates = result.data.allCurrencyExchangeRatesFiltereds?.nodes;
                    });
                    break;
                }
                case AggregateDateType.ByMonth: {
                    const result = await this.aggregateByMonthQuery();
                    runInAction(() => {
                        this.licenseSessionsAgg = result.data.allLicenseSessionCustomerVenueYearMonthAggs?.nodes;
                        this.licenseSessionsPriceAgg = result.data.allLicenseSessionCustomerVenueYearMonthPriceAggs?.nodes;
                        this.licenseSessionsRoyaltyAgg = result.data.allLicenseSessionCustomerVenueYearMonthRoyaltyAggs?.nodes;
                        this.currencyExchangeRates = result.data.allCurrencyExchangeRatesFilteredYearMonthAggs?.nodes;
                    });
                    break;
                }
                case AggregateDateType.ByYear: {
                    const result = await this.aggregateByYearQuery();
                    runInAction(() => {
                        this.licenseSessionsAgg = result.data.allLicenseSessionCustomerVenueYearAggs?.nodes;
                        this.licenseSessionsPriceAgg = result.data.allLicenseSessionCustomerVenueYearPriceAggs?.nodes;
                        this.licenseSessionsRoyaltyAgg = result.data.allLicenseSessionCustomerVenueYearRoyaltyAggs?.nodes;
                        this.currencyExchangeRates = result.data.allCurrencyExchangeRatesFilteredYearAggs?.nodes;
                    });
                    break;
                }
            }
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async aggregateByDateQuery() {
        return wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseSessionAggFilter | undefined,
            priceFilterInput: LicenseSessionPriceAggFilter | undefined,
            royaltyFilterInput: LicenseSessionRoyaltyAggFilter | undefined,
            exchangeRatesFilterInput: CurrencyExchangeRatesFilter | undefined
        }>({
            query: gql`query ($filterInput: LicenseSessionCustomerVenueDateAggFilter, $priceFilterInput: LicenseSessionCustomerVenueDatePriceAggFilter, $royaltyFilterInput: LicenseSessionCustomerVenueDateRoyaltyAggFilter, $exchangeRatesFilterInput: CurrencyExchangeRatesFilteredFilter) {
                currentUserId,

                allLicenseSessionCustomerVenueDateAggs(filter: $filterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        paidApprovedDuration,
                        unpaidApprovedDuration,
                        paidOriginalDuration,
                        unpaidOriginalDuration,
                        paidSubmittedDuration,
                        unpaidSubmittedDuration,
                        paidApprovedDurationMax,
                        unpaidApprovedDurationMax,
                        paidOriginalDurationMax,
                        unpaidOriginalDurationMax,
                        paidSubmittedDurationMax,
                        unpaidSubmittedDurationMax,
                        paidApprovedDurationMin,
                        unpaidApprovedDurationMin,
                        paidOriginalDurationMin,
                        unpaidOriginalDurationMin,
                        paidSubmittedDurationMin,
                        unpaidSubmittedDurationMin,
                        paidApprovedAmount,
                        paidOriginalAmount,
                        paidSubmittedAmount,
                        paidApprovedPlayers,
                        unpaidApprovedPlayers,
                        paidOriginalPlayers,
                        unpaidOriginalPlayers,
                        paidSubmittedPlayers,
                        unpaidSubmittedPlayers,
                        paidApprovedPlayersMax,
                        unpaidApprovedPlayersMax,
                        paidOriginalPlayersMax,
                        unpaidOriginalPlayersMax,
                        paidSubmittedPlayersMax,
                        unpaidSubmittedPlayersMax,
                        paidApprovedPlayersMin,
                        unpaidApprovedPlayersMin,
                        paidOriginalPlayersMin,
                        unpaidOriginalPlayersMin,
                        paidSubmittedPlayersMin,
                        unpaidSubmittedPlayersMin,
                        unknownAmount,
                        unpaidApprovedAmount,
                        unpaidOriginalAmount,
                        unpaidSubmittedAmount,
                        date
                    }
                },

                allLicenseSessionCustomerVenueDatePriceAggs(filter: $priceFilterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        price,
                        priceMin,
                        priceMax,
                        priceApproved,
                        priceApprovedMin,
                        priceApprovedMax,
                        priceOriginal,
                        priceOriginalMin,
                        priceOriginalMax,
                        priceDiscount,
                        priceDiscountMax,
                        priceDiscountMin,
                        priceApprovedDiscount,
                        priceApprovedDiscountMax,
                        priceApprovedDiscountMin,
                        currency,
                        date
                    }
                },

                allLicenseSessionCustomerVenueDateRoyaltyAggs(filter: $royaltyFilterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        royalty,
                        royaltyMin,
                        royaltyMax,
                        royaltyApproved,
                        royaltyApprovedMin,
                        royaltyApprovedMax,
                        royaltyOriginal,
                        royaltyOriginalMin,
                        royaltyOriginalMax,
                        royaltyDiscount,
                        royaltyDiscountMax,
                        royaltyDiscountMin,
                        royaltyApprovedDiscount,
                        royaltyApprovedDiscountMax,
                        royaltyApprovedDiscountMin,
                        currency,
                        date
                    }
                },

                allCurrencyExchangeRatesFiltereds(filter: $exchangeRatesFilterInput) {
                    nodes {
                        currency,
                        rate,
                        year,
                        month,
                        day
                    }
                }
            }`,
            variables: {
                filterInput: this.filter,
                priceFilterInput: this.filter,
                royaltyFilterInput: this.filter,
                exchangeRatesFilterInput: this.exchangeRatesFilter
            }
        }));
    }

    @action
    private async aggregateByMonthQuery() {
        return wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseSessionAggFilter | undefined,
            priceFilterInput: LicenseSessionPriceAggFilter | undefined,
            royaltyFilterInput: LicenseSessionRoyaltyAggFilter | undefined,
            exchangeRatesFilterInput: CurrencyExchangeRatesFilter | undefined
        }>({
            query: gql`query ($filterInput: LicenseSessionCustomerVenueYearMonthAggFilter, $priceFilterInput: LicenseSessionCustomerVenueYearMonthPriceAggFilter, $royaltyFilterInput: LicenseSessionCustomerVenueYearMonthRoyaltyAggFilter, $exchangeRatesFilterInput: CurrencyExchangeRatesFilteredYearMonthAggFilter) {
                currentUserId,

                allLicenseSessionCustomerVenueYearMonthAggs(filter: $filterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        paidApprovedDuration,
                        unpaidApprovedDuration,
                        paidOriginalDuration,
                        unpaidOriginalDuration,
                        paidSubmittedDuration,
                        unpaidSubmittedDuration,
                        paidApprovedDurationMax,
                        unpaidApprovedDurationMax,
                        paidOriginalDurationMax,
                        unpaidOriginalDurationMax,
                        paidSubmittedDurationMax,
                        unpaidSubmittedDurationMax,
                        paidApprovedDurationMin,
                        unpaidApprovedDurationMin,
                        paidOriginalDurationMin,
                        unpaidOriginalDurationMin,
                        paidSubmittedDurationMin,
                        unpaidSubmittedDurationMin,
                        paidApprovedAmount,
                        paidOriginalAmount,
                        paidSubmittedAmount,
                        paidApprovedPlayers,
                        unpaidApprovedPlayers,
                        paidOriginalPlayers,
                        unpaidOriginalPlayers,
                        paidSubmittedPlayers,
                        unpaidSubmittedPlayers,
                        paidApprovedPlayersMax,
                        unpaidApprovedPlayersMax,
                        paidOriginalPlayersMax,
                        unpaidOriginalPlayersMax,
                        paidSubmittedPlayersMax,
                        unpaidSubmittedPlayersMax,
                        paidApprovedPlayersMin,
                        unpaidApprovedPlayersMin,
                        paidOriginalPlayersMin,
                        unpaidOriginalPlayersMin,
                        paidSubmittedPlayersMin,
                        unpaidSubmittedPlayersMin,
                        unknownAmount,
                        unpaidApprovedAmount,
                        unpaidOriginalAmount,
                        unpaidSubmittedAmount,
                        year,
                        month
                    }
                },

                allLicenseSessionCustomerVenueYearMonthPriceAggs(filter: $priceFilterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        price,
                        priceMin,
                        priceMax,
                        priceApproved,
                        priceApprovedMin,
                        priceApprovedMax,
                        priceOriginal,
                        priceOriginalMin,
                        priceOriginalMax,
                        priceDiscount,
                        priceDiscountMax,
                        priceDiscountMin,
                        priceApprovedDiscount,
                        priceApprovedDiscountMax,
                        priceApprovedDiscountMin,
                        currency,
                        year,
                        month
                    }
                },

                allLicenseSessionCustomerVenueYearMonthRoyaltyAggs(filter: $royaltyFilterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        royalty,
                        royaltyMin,
                        royaltyMax,
                        royaltyApproved,
                        royaltyApprovedMin,
                        royaltyApprovedMax,
                        royaltyOriginal,
                        royaltyOriginalMin,
                        royaltyOriginalMax,
                        royaltyDiscount,
                        royaltyDiscountMax,
                        royaltyDiscountMin,
                        royaltyApprovedDiscount,
                        royaltyApprovedDiscountMax,
                        royaltyApprovedDiscountMin,
                        currency,
                        year,
                        month
                    }
                },

                allCurrencyExchangeRatesFilteredYearMonthAggs(filter: $exchangeRatesFilterInput) {
                    nodes {
                        currency,
                        rateAvg,
                        year,
                        month
                    }
                }
            }`,
            variables: {
                filterInput: this.filter,
                priceFilterInput: this.filter,
                royaltyFilterInput: this.filter,
                exchangeRatesFilterInput: this.exchangeRatesFilter
            }
        }));
    }

    @action
    private async aggregateByYearQuery() {
        return wrapForApiErrors(apolloClient.query<Query, {
            filterInput: LicenseSessionAggFilter | undefined,
            priceFilterInput: LicenseSessionPriceAggFilter | undefined,
            royaltyFilterInput: LicenseSessionRoyaltyAggFilter | undefined,
            exchangeRatesFilterInput: CurrencyExchangeRatesFilter | undefined
        }>({
            query: gql`query ($filterInput: LicenseSessionCustomerVenueYearAggFilter, $priceFilterInput: LicenseSessionCustomerVenueYearPriceAggFilter, $royaltyFilterInput: LicenseSessionCustomerVenueYearRoyaltyAggFilter, $exchangeRatesFilterInput: CurrencyExchangeRatesFilteredYearAggFilter) {
                currentUserId,

                allLicenseSessionCustomerVenueYearAggs(filter: $filterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        paidApprovedDuration,
                        unpaidApprovedDuration,
                        paidOriginalDuration,
                        unpaidOriginalDuration,
                        paidSubmittedDuration,
                        unpaidSubmittedDuration,
                        paidApprovedDurationMax,
                        unpaidApprovedDurationMax,
                        paidOriginalDurationMax,
                        unpaidOriginalDurationMax,
                        paidSubmittedDurationMax,
                        unpaidSubmittedDurationMax,
                        paidApprovedDurationMin,
                        unpaidApprovedDurationMin,
                        paidOriginalDurationMin,
                        unpaidOriginalDurationMin,
                        paidSubmittedDurationMin,
                        unpaidSubmittedDurationMin,
                        paidApprovedAmount,
                        paidOriginalAmount,
                        paidSubmittedAmount,
                        paidApprovedPlayers,
                        unpaidApprovedPlayers,
                        paidOriginalPlayers,
                        unpaidOriginalPlayers,
                        paidSubmittedPlayers,
                        unpaidSubmittedPlayers,
                        paidApprovedPlayersMax,
                        unpaidApprovedPlayersMax,
                        paidOriginalPlayersMax,
                        unpaidOriginalPlayersMax,
                        paidSubmittedPlayersMax,
                        unpaidSubmittedPlayersMax,
                        paidApprovedPlayersMin,
                        unpaidApprovedPlayersMin,
                        paidOriginalPlayersMin,
                        unpaidOriginalPlayersMin,
                        paidSubmittedPlayersMin,
                        unpaidSubmittedPlayersMin,
                        unknownAmount,
                        unpaidApprovedAmount,
                        unpaidOriginalAmount,
                        unpaidSubmittedAmount,
                        year
                    }
                },

                allLicenseSessionCustomerVenueYearPriceAggs(filter: $priceFilterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        price,
                        priceMin,
                        priceMax,
                        priceApproved,
                        priceApprovedMin,
                        priceApprovedMax,
                        priceOriginal,
                        priceOriginalMin,
                        priceOriginalMax,
                        priceDiscount,
                        priceDiscountMax,
                        priceDiscountMin,
                        priceApprovedDiscount,
                        priceApprovedDiscountMax,
                        priceApprovedDiscountMin,
                        currency,
                        year
                    }
                },

                allLicenseSessionCustomerVenueYearRoyaltyAggs(filter: $royaltyFilterInput) {
                    nodes {
                        customerId,
                        customerName,
                        venueId,
                        venueCountry,
                        venueCity,
                        venueLocation,
                        royalty,
                        royaltyMin,
                        royaltyMax,
                        royaltyApproved,
                        royaltyApprovedMin,
                        royaltyApprovedMax,
                        royaltyOriginal,
                        royaltyOriginalMin,
                        royaltyOriginalMax,
                        royaltyDiscount,
                        royaltyDiscountMax,
                        royaltyDiscountMin,
                        royaltyApprovedDiscount,
                        royaltyApprovedDiscountMax,
                        royaltyApprovedDiscountMin,
                        currency,
                        year
                    }
                },
                
                allCurrencyExchangeRatesFilteredYearAggs(filter: $exchangeRatesFilterInput) {
                    nodes {
                        currency,
                        rateAvg,
                        year
                    }
                }
            }`,
            variables: {
                filterInput: this.filter,
                priceFilterInput: this.filter,
                royaltyFilterInput: this.filter,
                exchangeRatesFilterInput: this.exchangeRatesFilter
            }
        }));
    }

    @action
    public reset() {
        this.licenseSessionsAgg = undefined;
        this.licenseSessionsPriceAgg = undefined;
        this.licenseSessionsRoyaltyAgg = undefined;
        this.loading = false;
        this.error = undefined;
    }

    private filterAggDataByDate(
        allData: Maybe<LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg>[] | undefined,
        date: string | {year: string, month?: string}
    ): Maybe<LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg>[] {
        let agg: Maybe<LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg>[];

        switch (this.rootStore.reportsFilter.aggregateDateType) {
            case AggregateDateType.ByDay:
                agg = (allData && allData.filter(agg => {
                    const aggCasted = agg && agg as LicenseSessionCustomerVenueDateAgg | LicenseSessionCustomerVenueDatePriceAgg | LicenseSessionCustomerVenueDateRoyaltyAgg;
                    return aggCasted && aggCasted.date === date;
                })) || [];
                break;
            case AggregateDateType.ByMonth: {
                const dateCasted = date as { year: string, month: string };
                agg = (allData && allData.filter(agg => {
                    const aggCasted = agg && agg as LicenseSessionCustomerVenueYearMonthAgg | LicenseSessionCustomerVenueYearMonthPriceAgg | LicenseSessionCustomerVenueYearMonthRoyaltyAgg;
                    return aggCasted && aggCasted.year?.toString() === dateCasted.year && aggCasted.month?.toString() === dateCasted.month;
                })) || [];
                break;
            }
            case AggregateDateType.ByYear: {
                const dateCasted = date as { year: string };
                agg = (allData && allData.filter(agg => {
                    const aggCasted = agg && agg as LicenseSessionCustomerVenueYearAgg | LicenseSessionCustomerVenueYearPriceAgg | LicenseSessionCustomerVenueYearRoyaltyAgg;
                    return aggCasted && aggCasted.year?.toString() === dateCasted.year;
                })) || [];
                break;
            }
        }

        return agg;
    }

    private getAggData(
        allData: Maybe<LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg>[] | undefined,
        key: {customerVenueName?: string} | null | undefined,
        dataAccessor: (agg: LicenseSessionAgg | LicenseSessionPriceAgg | LicenseSessionRoyaltyAgg | null) => number,
        dataAggregator: (accumulator?: number, currentValue?: number, rows?: number) => number
    ) {
        const isAdmin = this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin;
        const isSplitByVenue = this.rootStore.reportsFilter.aggregateCustomersType === AggregateCustomersType.SplitByVenue;

        return this.aggDateSeries.map(date => {
            let agg = this.filterAggDataByDate(allData, date);

            if (agg.length === 0) {
                return dataAccessor(null);
            }

            agg = agg.filter(agg =>
                key === undefined ||
                (key === null && !agg?.customerName) ||
                (key?.customerVenueName && key?.customerVenueName === (isSplitByVenue ? getAggCustomerVenueKey(isAdmin, agg) : agg?.customerName))
            );

            let accumulator: number = dataAggregator();
            for (let aggRow of agg) {
                accumulator = dataAggregator(accumulator, dataAccessor(aggRow));
            }

            return dataAggregator(accumulator, undefined, agg.length);
        });
    }

    private getAggDatasets(addDataset: (key: {customerVenueName?: string} | null | undefined) => any[]): any[] {
        let customerVenueNames: (string | null)[];

        switch (this.rootStore.reportsFilter.aggregateCustomersType) {
            case AggregateCustomersType.Split:
                customerVenueNames = this.aggCustomerNames;
                break;
            case AggregateCustomersType.SplitByVenue:
                customerVenueNames = this.aggVenueLocations;
                break;
            default:
                customerVenueNames = [];
        }

        let addUnknown: boolean = false;

        let datasets: any[] = [];

        for (let customerVenueName of customerVenueNames) {
            if (!customerVenueName) {
                addUnknown = true;
                continue;
            }

            datasets = addDataset({customerVenueName});
        }

        if (customerVenueNames.length === 0) {
            datasets = addDataset(undefined);
        }

        if (addUnknown) {
            datasets = addDataset(null);
        }

        return datasets;
    }

    private static constructLicenseSessionAggFilter(
        filterIncludeUnknown: IncludeUnknown,
        filterCustomers: Customer[],
        filterCustomerTags: string[],
        filterVenues: Venue[],
        filterDateStart: Date | null,
        filterDateEnd: Date | null,
        aggregateDateType: AggregateDateType
    ): LicenseSessionAggFilter | LicenseSessionPriceAggFilter | LicenseSessionRoyaltyAggFilter | undefined {
        const conditions: (LicenseSessionAggFilter | LicenseSessionPriceAggFilter | LicenseSessionRoyaltyAggFilter)[] = [];

        if (filterCustomers.length > 0 && filterIncludeUnknown !== IncludeUnknown.IncludeOnly) {
            const customersCondition = {
                customerId: {
                    in: filterCustomers.map(customer => customer.id)
                }
            };

            if (filterIncludeUnknown === IncludeUnknown.Include) {
                conditions.push({
                    or: [
                        {
                            customerId: {
                                isNull: true
                            }
                        },
                        customersCondition
                    ]
                });
            } else {
                conditions.push(customersCondition);
            }
        }

        if (filterCustomers.length <= 0 && filterIncludeUnknown === IncludeUnknown.NotInclude) {
            conditions.push({
                and: [
                    {
                        customerId: {
                            isNull: false
                        }
                    }
                ]
            });
        }

        if (filterCustomerTags.length > 0 && filterIncludeUnknown !== IncludeUnknown.IncludeOnly) {
            const tagsCondition = {
                customerTags: {
                    contains: filterCustomerTags
                }
            };

            if (filterIncludeUnknown === IncludeUnknown.Include) {
                conditions.push({
                    or: [
                        {
                            customerId: {
                                isNull: true
                            }
                        },
                        tagsCondition
                    ]
                });
            } else {
                conditions.push(tagsCondition);
            }
        }

        if (filterIncludeUnknown === IncludeUnknown.IncludeOnly) {
            conditions.push({
                and: [
                    {
                        customerId: {
                            isNull: true
                        }
                    }
                ]
            });
        }

        if (filterVenues.length > 0 && filterIncludeUnknown !== IncludeUnknown.IncludeOnly) {
            const venuesCondition = {
                venueId: {
                    in: filterVenues.map(venue => venue.id)
                }
            };

            if (filterIncludeUnknown === IncludeUnknown.Include) {
                conditions.push({
                    or: [
                        {
                            venueId: {
                                isNull: true
                            }
                        },
                        venuesCondition
                    ]
                });
            } else {
                conditions.push(venuesCondition);
            }
        }

        if (filterDateStart) {
            switch (aggregateDateType) {
                case AggregateDateType.ByDay:
                    conditions.push({
                        date: {
                            greaterThanOrEqualTo: toISODate(filterDateStart)
                        }
                    });
                    break;
                case AggregateDateType.ByMonth:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateStart.getFullYear()
                                        }
                                    },
                                    {
                                        month: {
                                            greaterThanOrEqualTo: filterDateStart.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    greaterThan: filterDateStart.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByYear:
                    conditions.push({
                        year: {
                            greaterThanOrEqualTo: filterDateStart.getFullYear()
                        }
                    });
                    break;
            }
        }

        if (filterDateEnd) {
            switch (aggregateDateType) {
                case AggregateDateType.ByDay:
                    conditions.push({
                        date: {
                            lessThanOrEqualTo: toISODate(filterDateEnd)
                        }
                    });
                    break;
                case AggregateDateType.ByMonth:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateEnd.getFullYear()
                                        }
                                    },
                                    {
                                        month: {
                                            lessThanOrEqualTo: filterDateEnd.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    lessThan: filterDateEnd.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByYear:
                    conditions.push({
                        year: {
                            lessThanOrEqualTo: filterDateEnd.getFullYear()
                        }
                    });
                    break;
            }
        }

        return conditions.length <= 0 ? undefined : {
            and: conditions
        };
    }

    private static constructCurrencyExchangeRatesFilter(
        filterDateStart: Date | null,
        filterDateEnd: Date | null,
        aggregateDateType: AggregateDateType
    ): CurrencyExchangeRatesFilter | undefined {
        const conditions: CurrencyExchangeRatesFilter[] = [];

        if (filterDateStart) {
            switch (aggregateDateType) {
                case AggregateDateType.ByDay:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateStart.getFullYear()
                                        },
                                    },
                                    {
                                        month: {
                                            equalTo: filterDateStart.getMonth() + 1
                                        }
                                    },
                                    {
                                        day: {
                                            greaterThanOrEqualTo: filterDateStart.getDate()
                                        }
                                    }
                                ]
                            },
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateStart.getFullYear()
                                        },
                                    },
                                    {
                                        month: {
                                            greaterThan: filterDateStart.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    greaterThan: filterDateStart.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByMonth:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateStart.getFullYear()
                                        }
                                    },
                                    {
                                        month: {
                                            greaterThanOrEqualTo: filterDateStart.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    greaterThan: filterDateStart.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByYear:
                    conditions.push({
                        year: {
                            greaterThanOrEqualTo: filterDateStart.getFullYear()
                        }
                    });
                    break;
            }
        }

        if (filterDateEnd) {
            switch (aggregateDateType) {
                case AggregateDateType.ByDay:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateEnd.getFullYear()
                                        },
                                    },
                                    {
                                        month: {
                                            equalTo: filterDateEnd.getMonth() + 1
                                        }
                                    },
                                    {
                                        day: {
                                            lessThanOrEqualTo: filterDateEnd.getDate()
                                        }
                                    }
                                ]
                            },
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateEnd.getFullYear()
                                        },
                                    },
                                    {
                                        month: {
                                            lessThan: filterDateEnd.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    lessThan: filterDateEnd.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByMonth:
                    conditions.push({
                        or: [
                            {
                                and: [
                                    {
                                        year: {
                                            equalTo: filterDateEnd.getFullYear()
                                        }
                                    },
                                    {
                                        month: {
                                            lessThanOrEqualTo: filterDateEnd.getMonth() + 1
                                        }
                                    }
                                ]
                            },
                            {
                                year: {
                                    lessThan: filterDateEnd.getFullYear()
                                }
                            }
                        ]
                    });
                    break;
                case AggregateDateType.ByYear:
                    conditions.push({
                        year: {
                            lessThanOrEqualTo: filterDateEnd.getFullYear()
                        }
                    });
                    break;
            }
        }

        return conditions.length <= 0 ? undefined : {
            and: conditions
        };
    }
}

export default LicenseSessionsAggStore;
import {action, autorun, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Mutation, User} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import UserFragment from "../api/graphql/UserFragment";

class EditUserStore {
    @observable.ref
    public user: User | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.rootStore.users.users) {
                this.reset();
            }
        });
    }

    @action
    public async save(
        name: string,
        locale: string,
        timezone: string,
        isDisabled: boolean,
        password?: string
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            const result = await EditUserStore.saveGeneralSettings(this.user?.id, name, locale, timezone);

            await this.setDisabled(isDisabled);

            if (password) {
                await this.resetPassword(password);
            }

            if (result?.data?.updateUserById?.user && result?.data?.updateUserById?.user?.id === this.rootStore.auth.currentUser?.id) {
                this.rootStore.auth.setCurrentUser(result.data.updateUserById.user);
            }

            this.rootStore.users.fetchUsers();

            this.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public static saveGeneralSettings(userId: string, userName: string, locale: string, timezone: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            userId: string,
            locale: string,
            userName: string,
            timezone: string
        }>({
            mutation: gql`mutation ($userId: UUID!, $locale: LocaleType!, $userName: NameType!, $timezone: String!) {
                updateUserById(input: {userPatch: {locale: $locale, name: $userName, timezone: $timezone}, id: $userId}) {
                    user {
                        ...UserFragment
                    }
                }
            },
            ${UserFragment}`,
            variables: {
                userId,
                locale,
                userName,
                timezone
            }
        }));
    }

    private resetPassword(password: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            userId: string,
            password: string
        }>({
            mutation: gql`mutation ($userId: UUID!, $password: String!) {
                resetUserPassword(input: {id: $userId, accountPassword: $password}) {
                    clientMutationId
                }
            }`,
            variables: {
                userId: this.user?.id,
                password: password
            }
        }));
    }

    private setDisabled(isDisabled: boolean): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            userId: string,
            isDisabled: boolean
        }>({
            mutation: gql`mutation ($userId: UUID!, $isDisabled: Boolean!) {
                setUserDisabled(input: {id: $userId, isDisabledInput: $isDisabled}) {
                    clientMutationId
                }
            }`,
            variables: {
                userId: this.user?.id,
                isDisabled
            }
        }));
    }

    @action
    public setUser(user: User | undefined) {
        this.reset();

        setTimeout(() => runInAction(() => {
            this.user = user;
        }));
    }

    @action
    public reset() {
        this.user = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    EditUserStore
};
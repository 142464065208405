import {
    LicenseV2BooleanClaimValue,
    LicenseV2Claim,
    LicenseV2ClaimId,
    LicenseV2ClaimValue,
    LicenseV2NumberClaimValue,
    LicenseV2PriceCalendarItemClaimValue,
    LicenseV2PriceClaimValue,
    LicenseV2ProductOptionBooleanClaimValue,
    LicenseV2ProductOptionNumberClaimValue,
    LicenseV2ProductOptionStringClaimValue,
    LicenseV2RoyaltyClaimValue,
    LicenseV2StringClaimValue
} from "../api/LicenseV2Claims";
import {t} from "ttag";
import {PriceVariantType, RoyaltyVariantType} from "../generated/graphql";

const LicenseV2ClaimIdOrder: LicenseV2ClaimId[] = [
    LicenseV2ClaimId.ALLOW_MACHINES,
    LicenseV2ClaimId.ALLOW_MULTISESSIONS,
    LicenseV2ClaimId.ALLOW_SHAREDSPACES,
    LicenseV2ClaimId.PRICE,
    LicenseV2ClaimId.PRICE_CALENDAR,
    LicenseV2ClaimId.ROYALTY,
    LicenseV2ClaimId.ALLOW_PRODUCTS,
    LicenseV2ClaimId.ALLOW_PRODUCT_VARIANTS,
    LicenseV2ClaimId.PREFERRED_PRODUCT_VARIANTS,
    LicenseV2ClaimId.PLAYERS,
    LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN,
    LicenseV2ClaimId.PRODUCT_OPTION_STRING,
    LicenseV2ClaimId.PRODUCT_OPTION_NUMBER
];

function getLicenseV2ClaimIdName(id: LicenseV2ClaimId): string {
    switch (id) {
        case LicenseV2ClaimId.PRICE:
            return t`Price`;
        case LicenseV2ClaimId.PRICE_CALENDAR:
            return t`Price calendar`;
        case LicenseV2ClaimId.ROYALTY:
            return t`Royalty`;
        case LicenseV2ClaimId.ALLOW_PRODUCTS:
            return t`Allow products`;
        case LicenseV2ClaimId.ALLOW_PRODUCT_VARIANTS:
            return t`Allow product variants`;
        case LicenseV2ClaimId.PREFERRED_PRODUCT_VARIANTS:
            return t`Preferred product variants`;
        case LicenseV2ClaimId.PLAYERS:
            return t`Allow players amount`;
        case LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN:
            return t`Boolean product option`;
        case LicenseV2ClaimId.PRODUCT_OPTION_STRING:
            return t`String product option`;
        case LicenseV2ClaimId.PRODUCT_OPTION_NUMBER:
            return t`Number product option`;
        case LicenseV2ClaimId.ALLOW_SHAREDSPACES:
            return t`Allow shared spaces`;
        case LicenseV2ClaimId.ALLOW_MULTISESSIONS:
            return t`Allow multi sessions`;
        case LicenseV2ClaimId.ALLOW_MACHINES:
            return t`Limit to a machine`;
        default:
            return t`Unknown`;
    }
}

function defaultLicenseV2ClaimValue(id: LicenseV2ClaimId): LicenseV2ClaimValue | undefined {
    switch (id) {
        case LicenseV2ClaimId.PRICE:
            return {
                price_currency: "USD",
                price_variant: PriceVariantType.PerPlayer,
                price: 0
            } as LicenseV2PriceClaimValue;
        case LicenseV2ClaimId.PRICE_CALENDAR:
            return [];
        case LicenseV2ClaimId.ROYALTY:
            return {
                royalty_variant: RoyaltyVariantType.PerRevenueShare,
                royalty_currency: "USD",
                royalty: 0,
                royalty_share: 0
            } as LicenseV2RoyaltyClaimValue;
        case LicenseV2ClaimId.ALLOW_PRODUCTS:
        case LicenseV2ClaimId.ALLOW_PRODUCT_VARIANTS:
        case LicenseV2ClaimId.ALLOW_MACHINES:
        case LicenseV2ClaimId.PREFERRED_PRODUCT_VARIANTS:
            return {
                exact: []
            } as LicenseV2StringClaimValue;
        case LicenseV2ClaimId.PLAYERS:
            return {
                range_upto: 20
            } as LicenseV2NumberClaimValue;
        case LicenseV2ClaimId.PRODUCT_OPTION_STRING:
            return {
                option_id: "",
                exact: []
            } as LicenseV2ProductOptionStringClaimValue;
        case LicenseV2ClaimId.PRODUCT_OPTION_NUMBER:
            return {
                option_id: "",
                range_from: 0,
                range_upto: 0
            } as LicenseV2ProductOptionNumberClaimValue;
        case LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN:
            return {
                option_id: "",
                exact: true
            } as LicenseV2ProductOptionBooleanClaimValue;
    }
}

function compareLicenseV2ClaimId(id1: LicenseV2ClaimId, id2: LicenseV2ClaimId): number {
    const id1Order = LicenseV2ClaimIdOrder.findIndex(v => v === id1);
    const id2Order = LicenseV2ClaimIdOrder.findIndex(v => v === id2);

    return id1Order - id2Order;
}

function compareLicenseV2Claim(c1: LicenseV2Claim, c2: LicenseV2Claim): number {
    if (!c1 || !c2) {
        return 0;
    }

    if (!c1.product_id && c2.product_id) {
        return -1;
    } else if (c1.product_id && !c2.product_id) {
        return 1;
    } else if (c1.product_id && c2.product_id) {
        if (c1.product_id > c2.product_id) {
            return -1;
        } else if (c1.product_id < c2.product_id) {
            return 1;
        }
    }

    return compareLicenseV2ClaimId(c1.id, c2.id);
}

function licenseV2NumberClaimValueToString(licenseV2NumberClaimValue: LicenseV2NumberClaimValue): string {
    if (licenseV2NumberClaimValue.exact && licenseV2NumberClaimValue.exact.length === 0) {
        return t`None`;
    } else if (licenseV2NumberClaimValue.exact && licenseV2NumberClaimValue.exact.length > 0) {
        return licenseV2NumberClaimValue.exact.join(", ");
    }

    const hasRangeFrom = licenseV2NumberClaimValue.range_from || licenseV2NumberClaimValue.range_from === 0;
    const hasRangeUpto = licenseV2NumberClaimValue.range_upto || licenseV2NumberClaimValue.range_upto === 0;

    if (hasRangeFrom && hasRangeUpto) {
        return t`From ${licenseV2NumberClaimValue.range_from} up to ${licenseV2NumberClaimValue.range_upto}`;
    } else if (hasRangeFrom && !hasRangeUpto) {
        return t`From ${licenseV2NumberClaimValue.range_from}`;
    } else if (!hasRangeFrom && hasRangeUpto) {
        return t`Up to ${licenseV2NumberClaimValue.range_upto}`;
    } else {
        return t`Wrong value`;
    }
}

function licenseV2StringClaimValueToString(licenseV2StringClaimValue: LicenseV2StringClaimValue): string {
    if (licenseV2StringClaimValue.exact && licenseV2StringClaimValue.exact.length === 0) {
        return t`None`;
    } else if (licenseV2StringClaimValue.exact && licenseV2StringClaimValue.exact.length > 0) {
        return licenseV2StringClaimValue.exact.join(", ");
    } else {
        return t`Wrong value`;
    }
}

function licenseV2BooleanClaimValueToString(licenseV2BooleanClaimValue: LicenseV2BooleanClaimValue): string {
    if (licenseV2BooleanClaimValue.exact === true) {
        return t`Yes`;
    } else if (licenseV2BooleanClaimValue.exact === false) {
        return t`No`;
    } else {
        return t`Wrong value`;
    }
}

function validateLicenseV2PriceClaimValue(value: LicenseV2PriceClaimValue): boolean {
    return !!(value && value.price_variant && value.price_currency && value.price >= 0);
}

function validateLicenseV2PriceCalendarClaimValue(value: LicenseV2PriceCalendarItemClaimValue[]): boolean {
    return value && Array.isArray(value) && value.length > 0 && value.every(p => !!p
        && typeof(p.from_day_of_week) === "number"
        && p.from_day_of_week >= 0
        && typeof(p.to_day_of_week) === "number"
        && p.to_day_of_week <= 6
        && typeof(p.from_hour) === "number"
        && p.from_hour >= 0
        && typeof(p.to_hour) === "number"
        && p.to_hour <= 23
        && p.from_day_of_week <= p.to_day_of_week
        && p.from_hour <= p.to_hour
        && (typeof(p.price) === "number" || !Number.isNaN(+p.price))
        && p.price >= 0
    );
}

function validateLicenseV2RoyaltyClaimValue(value: LicenseV2RoyaltyClaimValue): boolean {
    return !!value && !!value.royalty_variant &&
        (value.royalty_variant !== RoyaltyVariantType.PerRevenueShare && value.royalty >= 0 && !!value.royalty_currency
            || value.royalty_variant === RoyaltyVariantType.PerRevenueShare && value.royalty_share >= 0);
}

function validateLicenseV2StringClaimValue(value: LicenseV2StringClaimValue): boolean {
    return !!value && !!value.exact && Array.isArray(value.exact) && !value.exact.some(v => !v);
}

function validateLicenseV2BooleanClaimValue(value: LicenseV2BooleanClaimValue): boolean {
    return !!value && (value.exact === true || value.exact === false);
}

function validateLicenseV2NumberClaimValue(value: LicenseV2NumberClaimValue, checkNumber?: (v: number) => boolean): boolean {
    if (!value) {
        return false;
    }

    if (Array.isArray(value.exact) && !value.exact.every(v => (!!v || v === 0) && (!checkNumber || checkNumber(v)))) {
        return false;
    }

    if ((value.range_from || value.range_from === 0) && checkNumber && !checkNumber(value.range_from)) {
        return false;
    }

    if ((value.range_upto || value.range_upto === 0) && checkNumber && !checkNumber(value.range_upto)) {
        return false;
    }

    if ((value.range_from || value.range_from === 0) && (value.range_upto || value.range_upto === 0) && value.range_from > value.range_upto) {
        return false;
    }

    if (!Array.isArray(value.exact) && !value.range_from && value.range_from !== 0 && !value.range_upto && value.range_upto !== 0) {
        return false;
    }

    return true;
}

function validateLicenseV2Claim(claim: LicenseV2Claim): boolean {
    if (!claim || !claim.id) {
        return false;
    }

    switch (claim.id) {
        case LicenseV2ClaimId.PRICE:
            return validateLicenseV2PriceClaimValue(claim.value as LicenseV2PriceClaimValue);
        case LicenseV2ClaimId.PRICE_CALENDAR:
            return validateLicenseV2PriceCalendarClaimValue(claim.value as LicenseV2PriceCalendarItemClaimValue[]);
        case LicenseV2ClaimId.ROYALTY:
            return validateLicenseV2RoyaltyClaimValue(claim.value as LicenseV2RoyaltyClaimValue);
        case LicenseV2ClaimId.ALLOW_PRODUCTS:
            return validateLicenseV2StringClaimValue(claim.value as LicenseV2StringClaimValue);
        case LicenseV2ClaimId.ALLOW_MACHINES:
            return validateLicenseV2StringClaimValue(claim.value as LicenseV2StringClaimValue) && (claim.value as LicenseV2StringClaimValue).exact.length <= 1;
        case LicenseV2ClaimId.ALLOW_PRODUCT_VARIANTS:
            return !!claim.product_id && validateLicenseV2StringClaimValue(claim.value as LicenseV2StringClaimValue);
        case LicenseV2ClaimId.PREFERRED_PRODUCT_VARIANTS:
            return !!claim.product_id && validateLicenseV2StringClaimValue(claim.value as LicenseV2StringClaimValue);
        case LicenseV2ClaimId.PLAYERS:
            return validateLicenseV2NumberClaimValue(claim.value as LicenseV2NumberClaimValue, v => v > 0);
        case LicenseV2ClaimId.PRODUCT_OPTION_STRING: {
            const value = claim.value as LicenseV2ProductOptionStringClaimValue;
            return !!value.option_id && validateLicenseV2StringClaimValue(value);
        } case LicenseV2ClaimId.PRODUCT_OPTION_NUMBER: {
            const value = claim.value as LicenseV2ProductOptionNumberClaimValue;
            return !!value.option_id && validateLicenseV2NumberClaimValue(value);
        } case LicenseV2ClaimId.PRODUCT_OPTION_BOOLEAN: {
            const value = claim.value as LicenseV2ProductOptionBooleanClaimValue;
            return !!value.option_id && validateLicenseV2BooleanClaimValue(value);
        } case LicenseV2ClaimId.ALLOW_SHAREDSPACES:
        case LicenseV2ClaimId.ALLOW_MULTISESSIONS:
            return true;
    }

    return false;
}

export {
    LicenseV2ClaimIdOrder,
    getLicenseV2ClaimIdName,
    defaultLicenseV2ClaimValue,
    compareLicenseV2Claim,
    licenseV2NumberClaimValueToString,
    licenseV2StringClaimValueToString,
    licenseV2BooleanClaimValueToString,
    validateLicenseV2Claim,
    validateLicenseV2PriceCalendarClaimValue,
    validateLicenseV2StringClaimValue
}
import {
    CButton,
    CCol,
    CForm, CInput,
    CLabel, CPopover,
    CRow, CSelect,
    CSpinner,
    CSwitch
} from "@coreui/react";
import React, {useContext} from "react";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import Error from "./Error";
import {StoreContext} from "../context/StoreContext";
import {observer} from "mobx-react-lite";
import Customers from "./Customers";
import Venues from "./Venues";
import {toISODate} from "../utils/DateUtils";
import LicenseV2Claims from "./LicenseV2Claims";
import {LicenseV2Claim, LicenseV2ClaimId} from "../api/LicenseV2Claims";
import {defaultLicenseV2ClaimValue, validateLicenseV2Claim} from "../utils/LicenseV2ClaimUtils";

interface EditLicenseV2Props {
    isVertical?: boolean;
}

const EditLicenseV2: React.FC<EditLicenseV2Props> = observer((props: EditLicenseV2Props) => {
    const store = useContext(StoreContext);
    const {editLicenseV2, activeCustomers, activeVenues} = store;

    const {register, setValue, handleSubmit, errors, getValues} = useForm();

    const onCancel = () => {
        editLicenseV2.reset();
    };

    const onSubmit = () => {
        editLicenseV2.save();
    };

    const onChangeCustomer = (value: string) => {
        if (activeCustomers.allActiveCustomers) {
            editLicenseV2.setCustomer(activeCustomers.allActiveCustomers?.find(c => c && c.id === value) || undefined);
        }
    };

    const onChangeVenue = (value: string) => {
        if (activeVenues.activeVenues) {
            editLicenseV2.setVenue(activeVenues.activeVenues?.find(v => v && v.id === value) || undefined);
        }
    };

    const onAddClaim = (id: LicenseV2ClaimId, productId?: string) => {
        setTimeout(() => {
            editLicenseV2.setEditClaim({
                id,
                value: defaultLicenseV2ClaimValue(id),
                product_id: productId
            } as LicenseV2Claim);

            editLicenseV2.setEditClaimIndex(undefined);
        });
    };

    const onRemoveClaim = () => {
        setTimeout(() => {
            if (editLicenseV2.editClaimIndex !== undefined) {
                editLicenseV2.setClaims(
                        editLicenseV2.claims.filter((claim, i) => i !== editLicenseV2.editClaimIndex)
                );
            }

            editLicenseV2.setEditClaim(undefined);
            editLicenseV2.setEditClaimIndex(undefined);
        });
    };

    const onEditClaim = (index: number) => {
        setTimeout(() => {
            const claim = editLicenseV2.claims[index];

            editLicenseV2.setEditClaim(claim);
            editLicenseV2.setEditClaimIndex(index);
        });
    };

    const onSaveClaim = () => {
        setTimeout(() => {
            if (!editLicenseV2.editClaim || !validateLicenseV2Claim(editLicenseV2.editClaim)) {
                return;
            }

            const claims = editLicenseV2.editClaimIndex === undefined ?
                    editLicenseV2.claims :
                    editLicenseV2.claims.filter((claim, i) => i !== editLicenseV2.editClaimIndex);

            editLicenseV2.setClaims([
                ...claims,
                editLicenseV2.editClaim
            ]);

            editLicenseV2.setEditClaim(undefined);
            editLicenseV2.setEditClaimIndex(undefined);
        });
    };

    const onCancelEditClaim = () => {
        setTimeout(() => {
            editLicenseV2.setEditClaim(undefined);
            editLicenseV2.setEditClaimIndex(undefined);
        });
    };

    const onSetEditClaim = (claim: LicenseV2Claim) => {
        setTimeout(() => {
            editLicenseV2.setEditClaim(claim);
        });
    };

    const onChangeExpirateAtUtc = (value: string) => {
        let expirateAtUtc = value ? new Date(value) : null;

        const today = new Date();

        if (expirateAtUtc && expirateAtUtc < today) {
            expirateAtUtc = null;

            setValue("expirateAtUtc", "");
        }

        editLicenseV2.setExpirateAtUtc(expirateAtUtc ? expirateAtUtc.toISOString() : expirateAtUtc);
    };

    const onChangeIsRevoked = (value: boolean) => {
        editLicenseV2.setIsRevoked(value);
    };

    const customerBlock = !editLicenseV2.license && <React.Fragment>
        <CLabel htmlFor="customerId">{t`Customer`}</CLabel>
        <CPopover
                content={activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length > 0 ? t`Required` : t`No active customers`)}
                placement="right"
                advancedOptions={{onShow: () => !!errors.customerId || !!activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length <= 0)}}>
            <CSelect name="customerId"
                     innerRef={register({required: true})}
                     invalid={!!errors.customerId}
                     value={editLicenseV2.customer?.id}
                     onChange={() => onChangeCustomer(getValues("customerId"))}>
                <Customers/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const venueBlock = !editLicenseV2.license && <React.Fragment>
        <CLabel htmlFor="venueId">{t`Venue`}</CLabel>
        <CPopover
                content={activeVenues.error || (activeVenues.activeVenues && activeVenues.activeVenues.length > 0 ? t`Required` : t`No active venues`)}
                placement="right"
                advancedOptions={{onShow: () => !!errors.venueId || !!activeVenues.error || (activeVenues.activeVenues && activeVenues.activeVenues.length <= 0)}}>
            <CSelect name="venueId"
                     innerRef={register({required: true})}
                     invalid={!!errors.venueId}
                     value={editLicenseV2.venue?.id}
                     onChange={() => onChangeVenue(getValues("venueId"))}>
                <Venues/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const claimsBlock = <div style={{width: "100%"}}>
        <LicenseV2Claims claims={editLicenseV2.claims}
                         editClaim={editLicenseV2.editClaim}
                         editClaimIndex={editLicenseV2.editClaimIndex}
                         onAdd={onAddClaim}
                         onRemove={onRemoveClaim}
                         onEdit={onEditClaim}
                         onCancel={onCancelEditClaim}
                         onSave={onSaveClaim}
                         onSetEditClaim={onSetEditClaim}/>
    </div>;

    const expirateAtBlock = <React.Fragment>
        <CLabel htmlFor="expirateAtUtc" style={{width: "100%"}}>{t`Expiration date`}</CLabel>
        <CInput type="date"
                name="expirateAtUtc"
                title={t`Expiration date`}
                innerRef={register()}
                value={editLicenseV2.expirateAtUtc && toISODate(new Date(editLicenseV2.expirateAtUtc)) || ""}
                onChange={event => onChangeExpirateAtUtc((event.target as HTMLInputElement).value)}/>
    </React.Fragment>;

    const revokedBlock = editLicenseV2.license && <React.Fragment>
        <CLabel htmlFor="isrevoked" style={{width: "100%"}}>{t`Revoked`}</CLabel>
        <CSwitch name="isrevoked"
                 innerRef={register()}
                 shape="pill"
                 color="secondary"
                 defaultChecked={editLicenseV2.isRevoked}
                 onChange={() => onChangeIsRevoked(getValues("isrevoked"))}/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit"
                                 disabled={!!editLicenseV2.loading}>
        {editLicenseV2.license ? t`Save` : t`Create`}
        {editLicenseV2.loading &&
                <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = <CButton color="secondary" className="px-4 ml-2" type="reset"
                                 onClick={onCancel}>{editLicenseV2.license ? jt`Cancel` : jt`Reset`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        {customerBlock && <CRow>
            {customerBlock}
        </CRow>}

        {venueBlock && <CRow>
            {venueBlock}
        </CRow>}

        {claimsBlock && <CRow style={{width: "100%"}} className="mt-3 mb-3">
            {claimsBlock}
        </CRow>}

        {expirateAtBlock && <CRow>
            {expirateAtBlock}
        </CRow>}

        {revokedBlock && <CRow>
            {revokedBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            {customerBlock && <CCol>
                {customerBlock}
            </CCol>}

            {venueBlock && <CCol>
                {venueBlock}
            </CCol>}

            {claimsBlock && <CCol xs={5}>
                {claimsBlock}
            </CCol>}

            {expirateAtBlock && <CCol xs={2}>
                {expirateAtBlock}
            </CCol>}

            {revokedBlock && <CCol xs={1}>
                {revokedBlock}
            </CCol>}

            <CCol xs={3}>
                {submitBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
            <CForm onSubmit={handleSubmit(onSubmit)}>
                {elements}

                {editLicenseV2.error && <CRow><Error>{editLicenseV2.error}</Error></CRow>}
            </CForm>
    );
});

export default EditLicenseV2;
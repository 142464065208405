import React, {useContext} from "react"
import {observer} from "mobx-react-lite";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CPopover,
    CRow,
    CSelect,
    CSpinner
} from "@coreui/react";
import {jt, t} from "ttag";
import {useForm} from "react-hook-form";
import {StoreContext} from "../../context/StoreContext";
import Error from "../Error";
import {CurrentUserSettingsInput} from "../../stores/CurrentUserSettingsStore";
import Timezones from "../Timezones";
import Locales from "../Locales";

const CurrentUserSettingsView = observer(() => {
    const store = useContext(StoreContext);
    const {auth, currentUserSettings} = store;

    const {register, handleSubmit, errors, getValues} = useForm<CurrentUserSettingsInput>();

    const onSubmit = async (data: CurrentUserSettingsInput) => currentUserSettings.save(data);

    return (
        <CRow>
            <CCol xs="12" sm="4">
                <CCard>
                    <CCardHeader>
                        {t`User settings`}
                    </CCardHeader>

                    <CCardBody>
                        <CForm onSubmit={handleSubmit(onSubmit)}>
                            <CFormGroup>
                                <CLabel htmlFor="newusername">{t`Username`}</CLabel>

                                <CPopover content={t`Required`} placement="right" advancedOptions={{onShow: () => !!errors.newusername}}>
                                    <CInput name="newusername" type="text"
                                            autoComplete="new-user"
                                            defaultValue={auth.currentUser?.name}
                                            innerRef={register({required: true})}
                                            invalid={!!errors.newusername}/>
                                </CPopover>
                            </CFormGroup>

                            <CFormGroup>
                                <CLabel htmlFor="locale">{t`Locale`}</CLabel>

                                <CPopover content={t`Required`} placement="right" advancedOptions={{onShow: () => !!errors.locale}}>
                                    <CSelect name="locale"
                                             defaultValue={auth.currentUser?.locale}
                                             innerRef={register({required: true})}
                                             invalid={!!errors.locale}>
                                        <Locales/>
                                    </CSelect>
                                </CPopover>
                            </CFormGroup>

                            <CFormGroup>
                                <CLabel htmlFor="timezone">{t`Timezone`}</CLabel>

                                <CPopover content={t`Required`} placement="right" advancedOptions={{onShow: () => !!errors.timezone}}>
                                    <CSelect name="timezone"
                                             defaultValue={auth.currentUser?.timezone}
                                             innerRef={register({required: true})}
                                             invalid={!!errors.timezone}>
                                        <Timezones/>
                                    </CSelect>
                                </CPopover>
                            </CFormGroup>

                            <CFormGroup>
                                <CLabel htmlFor="newpassword">{t`New password`}</CLabel>

                                <CInput name="newpassword" type="password"
                                        autoComplete="new-password"
                                        innerRef={register()}
                                        invalid={!!errors.newpassword}/>
                            </CFormGroup>

                            <CFormGroup>
                                <CLabel htmlFor="passwordcopy">{t`New password copy`}</CLabel>

                                <CPopover content={t`Should match password`} placement="right" advancedOptions={{onShow: () => !!errors.passwordcopy}}>
                                    <CInput name="passwordcopy" type="password"
                                            autoComplete="new-password"
                                            innerRef={register({validate: val => val === getValues("newpassword")})}
                                            invalid={!!errors.passwordcopy}/>
                                </CPopover>
                            </CFormGroup>

                            <CRow>
                                {currentUserSettings.error && <Error>{currentUserSettings.error}</Error>}
                            </CRow>

                            <CRow>
                                <CCol xs="6">
                                    <CButton color="primary" className="px-4" type="submit" disabled={currentUserSettings.loading}>
                                        {jt`Save`}
                                        {currentUserSettings.loading && <CSpinner color="info" style={{width:'1rem', height:'1rem'}}/>}
                                    </CButton>
                                </CCol>
                                <CCol xs="6" className="text-right">
                                    <CButton color="secondary" className="px-4" type="reset">{jt`Cancel`}</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
});

export default CurrentUserSettingsView;
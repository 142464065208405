import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {
    LicenseSession,
    PriceDefinition,
    RoyaltyDefinition,
    UserRoleType
} from "../../generated/graphql";
import {
    CBadge, CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CCollapse,
    CDataTable,
    CLink, CModal, CModalBody, CModalHeader, CModalTitle,
    CRow, CSpinner,
} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import Pagination from "../Pagination";
import Error from "../Error";
import EditLicenseSession from "../EditLicenseSession";
import Comments from "../Comments";
import {DateAndTime} from "../DateAndTime";
import Price from "../Price";
import {moneyToDisplayStr} from "../../utils/MoneyUtils";
import Royalty from "../Royalty";
import ReportsFilter from "../ReportsFilter";
import FinancialReportsCharts from "../FinancialReportsCharts";
import {OrderByType} from "../../api/LicenseSessionsApi";
import {leaderboardBaseURI} from "../../utils/ApiUtils";

const getLicenseSessionStatusBadgeColor = (isUnknown: boolean, isApproved: boolean, isLocked: boolean) =>
        isLocked ? "secondary" : (isUnknown ? "danger" : (isApproved ? "success" : "warning"));

const getLicenseSessionPaidBadgeColor = (isPaid: boolean) =>
        isPaid ? "success" : "danger";

const fields = [
    "venue",
    {key: "startTimeLocal", _style: {width: "5%"}},
    {key: "other", _style: {width: "7%"}},
    "submitted",
    "approved",
    "original",
    "status",
    {
        key: "edit",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const FinancialReportsView = observer(() => {
    const store = useContext(StoreContext);
    const {auth, licenseSessions, licenseSessionsAgg } = store;

    const onApprove = (licenseSession: LicenseSession) => {
        licenseSessions.saveLicenseSession(
            licenseSession.id,
            {
                price: licenseSession.price.price,
                priceCurrency: licenseSession.price.priceCurrency,
                priceVariant: licenseSession.price.priceVariant
            },
            licenseSession.isPaidApproved,
            "",
            !licenseSession.royalty ? undefined : {
                royalty: licenseSession.royalty.royalty,
                royaltyVariant: licenseSession.royalty.royaltyVariant,
                royaltyCurrency: licenseSession.royalty.royaltyCurrency,
                royaltyShare: licenseSession.royalty.royaltyShare
            }
        );
    };

    const onDiscard = (licenseSession: LicenseSession) => {
        licenseSessions.saveLicenseSession(
            licenseSession.id,
            {
                price: licenseSession.priceApproved.price,
                priceCurrency: licenseSession.priceApproved.priceCurrency,
                priceVariant: licenseSession.priceApproved.priceVariant
            },
            licenseSession.isPaidApproved,
            ""
        );
    };

    const toggleEdit = (licenseSession: LicenseSession) => {
        licenseSessions.editLicenseSession(
                licenseSessions.editingLicenseSession?.id === licenseSession.id ? undefined : licenseSession
        );
    };

    const saveLicenseSession = (licenseSessionId: string, price: PriceDefinition, isPaid: boolean, comment: string, royalty?: RoyaltyDefinition, isLocked?: boolean) => {
        licenseSessions.saveLicenseSession(licenseSessionId, price, isPaid, comment, royalty, isLocked);
    };

    const refresh = () => {
        licenseSessionsAgg.fetchAgg();
        licenseSessions.fetchLicenseSessions();
    };

    const submitExportToExcelTask = () => {
        licenseSessions.submitExportToExcelTask();
    };

    const redetectAllUnknownLicenseSessions = () => {
        licenseSessions.redetectAllUnknown();
    };

    const renderAdditionalLicenseSessionInfo = (licenseSession: LicenseSession) => {
        const duration = licenseSession.duration?.toFixed(2);
        const leaderboardUrl = `${leaderboardBaseURI}/${licenseSession.reportedSessionId}`;

        return <React.Fragment key={licenseSession.id}>
            {licenseSession.masterSessionId && <React.Fragment>
                <CRow gutters={false}>
                    <strong>{t`Product:`}</strong>
                </CRow>

                <CRow gutters={false}>
                    {licenseSession.productByProductId?.name || t`Unknown`}
                </CRow>

                <CRow gutters={false}>
                    <strong>{t`Players:`}</strong>
                </CRow>

                <CRow gutters={false}>
                    {licenseSession.players}
                </CRow>

                <CRow gutters={false}>
                    <strong>{t`Date (local)`}</strong>
                </CRow>

                <CRow gutters={false}>
                    <DateAndTime date={new Date(licenseSession.startTimeLocal)} local={true}/>
                </CRow>

                <CRow gutters={false}>
                    <strong>{t`Duration:`}</strong>
                </CRow>

                <CRow gutters={false}>
                    {t`${duration}min`}
                </CRow>

                <CRow gutters={false}>
                    <strong>{t`Leaderboard`}</strong>
                </CRow>

                <CRow gutters={false}>
                    <CLink href={leaderboardUrl}
                           target="_blank">
                        {leaderboardUrl}
                    </CLink>
                </CRow>
            </React.Fragment>}

            <CRow gutters={false}>
                <strong>{t`License key:`}</strong>
            </CRow>

            <CRow gutters={false}>
                {licenseSession.licenseKey?.join(', ') || t`Unknown`}
            </CRow>

            <CRow gutters={false}>
                <strong>{t`Reported id:`}</strong>
            </CRow>

            <CRow gutters={false}>
                {licenseSession.reportedSessionId || t`Unknown`}
            </CRow>

            <CRow gutters={false}>&nbsp;</CRow>
        </React.Fragment>;
    };

    return (
        <React.Fragment>
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            {t`Filter`}

                            <CLink className="ml-1"
                                   title={t`Refresh`}
                                   onClick={() => refresh()}
                                   disabled={licenseSessions.loading || licenseSessionsAgg.loading}>
                                <CIcon name="cil-sync" className="mfe-2" />
                            </CLink>

                            <CLink className="ml-1"
                                   title={auth.isSecondaryMode ? t`Isn't available in secondary mode` : t`Submit export to excel task`}
                                   onClick={() => submitExportToExcelTask()}
                                   disabled={licenseSessions.loading || licenseSessionsAgg.loading || auth.isSecondaryMode}>
                                <CIcon name="cil-cloud-download" className="mfe-2" />
                            </CLink>

                            {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <CLink onClick={() => redetectAllUnknownLicenseSessions()}
                                   disabled={licenseSessions.loading}
                                   title={t`Redetect all unknown license sessions`}>
                                <CIcon name="cil-loop-circular" className="mfe-2" />
                            </CLink>}
                        </CCardHeader>

                        <CCardBody>
                            <CModal show={licenseSessions.showSubmitExportToExcelTaskResult}
                                    onClose={() => licenseSessions.resetShowSubmitExportToExcelTaskResult()}
                                    color="info">
                                <CModalHeader closeButton>
                                    <CModalTitle>{t`Submit export to excel task`}</CModalTitle>
                                </CModalHeader>

                                <CModalBody>
                                    {licenseSessions.error && <Error>{licenseSessions.error}</Error>}
                                    {!licenseSessions.error && t`The report was successfully submitted as a task. Please find the result excel file in the Files panel`}
                                </CModalBody>
                            </CModal>

                            <ReportsFilter showUnpaid={true}
                                           showUnknown={true}
                                           showCustomers={true}
                                           showVenues={true}
                                           showDates={true}
                                           showOrderBy={true}
                                           orderBySupportedValues={[OrderByType.ByDateDesc, OrderByType.ByDateAsc, OrderByType.SubmittedFirst]}
                                           showAggregateBy={true}
                                           showSplitBy={true}
                                           showCompareBy={true}
                                           showCompareSumType={true}
                                           areControlsEnabled={!licenseSessions.loading && !licenseSessionsAgg.loading}/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <FinancialReportsCharts/>

            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            {t`License Sessions`}
                        </CCardHeader>

                        <CCardBody>
                            <CDataTable
                                    noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                    items={(licenseSessions.licenseSessions && licenseSessions.licenseSessions.length > 0 && licenseSessions.licenseSessions) || []}
                                    loading={licenseSessions.loading}
                                    fields={fields}
                                    border={true}
                                    columnHeaderSlot={{
                                        venue: t`Customer/Venue`,
                                        submitted: t`Submitted`,
                                        approved: t`Approved`,
                                        original: t`Original`,
                                        status: t`Status`,
                                        other: t`Info`,
                                        startTimeLocal: t`Date (local)`
                                    }}
                                    scopedSlots={{
                                        venue: (licenseSession: LicenseSession) => {
                                            const venue = licenseSession.venueByVenueId;
                                            const customer = licenseSession.customerByCustomerId;

                                            return !venue ? (
                                                <td>
                                                    <CBadge color="danger">
                                                        {t`unknown`}
                                                    </CBadge>
                                                </td>
                                            ) : (
                                                <td>
                                                    {customer?.name}

                                                    {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin &&
                                                            customer?.tags?.map(tag =>
                                                                    <CBadge key={tag} color="info" className="ml-2">{tag}</CBadge>
                                                            )} / {venue?.country ? venue?.country + " " : ""}{venue?.city ? venue?.city + " " : ""}{venue?.location}
                                                </td>
                                            );
                                        },
                                        submitted: (licenseSession: LicenseSession) =>
                                            <Observer>{() => {
                                                const price = moneyToDisplayStr(licenseSession.priceTotal);
                                                const priceCurrency = licenseSession.price.priceCurrency;

                                                const royalty = moneyToDisplayStr(licenseSession.royaltyTotal);
                                                const royaltyCurrency = licenseSession.royalty.royaltyCurrency;

                                                return licenseSession.isApproved ? <td></td> : (
                                                        <td>
                                                            <CRow gutters={false}>
                                                                <CBadge color={getLicenseSessionPaidBadgeColor(licenseSession.isPaid)}>
                                                                    {licenseSession.isPaid ? t`paid` : t`unpaid`}
                                                                </CBadge>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <strong>{t`Revenue:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {price}{priceCurrency}
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <strong>{t`Royalty:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {royalty}{royaltyCurrency}
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <CLink onClick={() => licenseSessions.toggleShowMoreSubmitted(licenseSession.id)}
                                                                       disabled={licenseSessions.loading}
                                                                       title={t`Toggle more info`}>
                                                                    <CIcon name="cil-storage" className="mfe-2"/>
                                                                </CLink>
                                                            </CRow>

                                                            <CCollapse
                                                                    show={licenseSessions.showMoreSubmittedLicenseSessionIds.has(licenseSession.id)}>
                                                                <Price price={licenseSession.price} label={t`Price `}/>

                                                                <Royalty royalty={licenseSession.royalty} label={t`Royalty `}/>
                                                            </CCollapse>
                                                        </td>
                                                );
                                            }}</Observer>,
                                        approved: (licenseSession: LicenseSession) =>
                                            <Observer>{() => {
                                                const price = moneyToDisplayStr(licenseSession.priceApprovedTotal);
                                                const priceCurrency = licenseSession.priceApproved.priceCurrency;

                                                const royalty = moneyToDisplayStr(licenseSession.royaltyApprovedTotal);
                                                const royaltyCurrency = licenseSession.royalty.royaltyCurrency;

                                                return (
                                                        <td>
                                                            <CRow gutters={false}>
                                                                <CBadge color={getLicenseSessionPaidBadgeColor(licenseSession.isPaidApproved)}>
                                                                    {licenseSession.isPaidApproved ? t`paid` : t`unpaid`}
                                                                </CBadge>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <strong>{t`Revenue:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {price}{priceCurrency}
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <strong>{t`Royalty:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {royalty}{royaltyCurrency}
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <CLink onClick={() => licenseSessions.toggleShowMoreApproved(licenseSession.id)}
                                                                       disabled={licenseSessions.loading}
                                                                       title={t`Toggle more info`}>
                                                                    <CIcon name="cil-storage" className="mfe-2"/>
                                                                </CLink>
                                                            </CRow>

                                                            <CCollapse
                                                                    show={licenseSessions.showMoreApprovedLicenseSessionIds.has(licenseSession.id)}>
                                                                <Price price={licenseSession.priceApproved} label={t`Price `}/>

                                                                <Royalty royalty={licenseSession.royalty} label={t`Royalty `}/>
                                                            </CCollapse>
                                                        </td>
                                                );
                                            }}</Observer>,
                                        original: (licenseSession: LicenseSession) =>
                                            <Observer>{() => {
                                                const price = moneyToDisplayStr(licenseSession.priceOriginalTotal);
                                                const priceCurrency = licenseSession.priceOriginal.priceCurrency;

                                                const royalty = moneyToDisplayStr(licenseSession.royaltyOriginalTotal);
                                                const royaltyCurrency = licenseSession.royaltyOriginal.royaltyCurrency;

                                                return (
                                                        <td>
                                                            <CRow gutters={false}>
                                                                <CBadge color={getLicenseSessionPaidBadgeColor(licenseSession.isPaidOriginal)}>
                                                                    {licenseSession.isPaidOriginal ? t`paid` : t`unpaid`}
                                                                </CBadge>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <strong>{t`Revenue:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {price}{priceCurrency}
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <strong>{t`Royalty:`}</strong>
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                {royalty}{royaltyCurrency}
                                                            </CRow>

                                                            <CRow gutters={false}>
                                                                <CLink onClick={() => licenseSessions.toggleShowMoreOriginal(licenseSession.id)}
                                                                       disabled={licenseSessions.loading}
                                                                       title={t`Toggle more info`}>
                                                                    <CIcon name="cil-storage" className="mfe-2"/>
                                                                </CLink>
                                                            </CRow>

                                                            <CCollapse
                                                                    show={licenseSessions.showMoreOriginalLicenseSessionIds.has(licenseSession.id)}>
                                                                <Price price={licenseSession.priceOriginal} label={t`Price `}/>

                                                                <Royalty royalty={licenseSession.royaltyOriginal} label={t`Royalty `}/>
                                                            </CCollapse>
                                                        </td>
                                                );
                                            }}</Observer>,
                                        status: (licenseSession: LicenseSession) =>
                                            <Observer>{() => {
                                                const isUnknown = !licenseSession.licenseByLicenseId && !licenseSession.licenseV2ByLicenseV2Id;

                                                return (
                                                <td>
                                                    <CRow gutters={false}>
                                                        <CBadge color={getLicenseSessionStatusBadgeColor(isUnknown, !!licenseSession.isApproved, licenseSession.isLocked)}>
                                                            {licenseSession.isLocked ? t`locked` : (isUnknown ? t`unknown` : (licenseSession.isApproved ? t`approved` : t`submitted`))}
                                                        </CBadge>
                                                    </CRow>

                                                    {!licenseSession.isApproved && licenseSession.priceDiscount && +licenseSession.priceDiscount != 0 && <React.Fragment>
                                                        <CRow gutters={false}>
                                                            <strong>{t`Submitted revenue discount:`}</strong>
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            {+licenseSession.priceDiscount > 0 && '+'}{licenseSession.priceDiscount}{licenseSession.priceOriginal.priceCurrency}
                                                        </CRow>
                                                    </React.Fragment>}

                                                    {!licenseSession.isApproved && licenseSession.royaltyDiscount && +licenseSession.royaltyDiscount != 0 && <React.Fragment>
                                                        <CRow gutters={false}>
                                                            <strong>{t`Submitted royalty discount:`}</strong>
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            {+licenseSession.royaltyDiscount > 0 && '+'}{licenseSession.royaltyDiscount}{licenseSession.royaltyOriginal.royaltyCurrency}
                                                        </CRow>
                                                    </React.Fragment>}

                                                    {!licenseSession.isApproved && licenseSession.price.priceCurrency !== licenseSession.priceOriginal.priceCurrency && <CBadge color="warning">
                                                        {t`unknown discount`}
                                                    </CBadge>}

                                                    {licenseSession.priceApprovedDiscount && licenseSession.priceApprovedDiscount != 0 && <React.Fragment>
                                                        <CRow gutters={false}>
                                                            <strong>{t`Approved revenue discount:`}</strong>
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            {+licenseSession.priceApprovedDiscount > 0 && '+'}{licenseSession.priceApprovedDiscount}{licenseSession.priceOriginal.priceCurrency}
                                                        </CRow>
                                                    </React.Fragment>}

                                                    {licenseSession.royaltyApprovedDiscount && licenseSession.royaltyApprovedDiscount != 0 && <React.Fragment>
                                                        <CRow gutters={false}>
                                                            <strong>{t`Approved royalty discount:`}</strong>
                                                        </CRow>

                                                        <CRow gutters={false}>
                                                            {+licenseSession.royaltyApprovedDiscount > 0 && '+'}{licenseSession.royaltyApprovedDiscount}{licenseSession.royaltyOriginal.royaltyCurrency}
                                                        </CRow>
                                                    </React.Fragment>}

                                                    {licenseSession.isApproved && (licenseSession.priceApproved.priceCurrency !== licenseSession.priceOriginal.priceCurrency || licenseSession.royalty.royaltyCurrency !== licenseSession.royaltyOriginal.royaltyCurrency) && <CBadge color="warning">
                                                        {t`unknown approved discount`}
                                                    </CBadge>}

                                                    {!licenseSession.isApproved && licenseSessions.editingLicenseSession?.id !== licenseSession.id && <CRow gutters={false} className="mt-2">
                                                        {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin &&
                                                            <CButton color="primary"
                                                                     className="mr-2"
                                                                     disabled={!!licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.loading} onClick={() => onApprove(licenseSession)}>
                                                                {t`Approve`}

                                                                {licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.loading &&
                                                                        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
                                                            </CButton>}

                                                        <CButton color="dark"
                                                                 disabled={!!licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.loading} onClick={() => onDiscard(licenseSession)}>
                                                            {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ? t`Discard` : t`Reset`}

                                                            {licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.loading &&
                                                                    <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
                                                        </CButton>
                                                    </CRow>}

                                                    {licenseSession.licenseSessionCommentsByLicenseSessionId.nodes.length > 0 && <CRow gutters={false} className="mt-2">
                                                        <Comments comments={licenseSession.licenseSessionCommentsByLicenseSessionId.nodes}/>
                                                    </CRow>}

                                                    {licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.error && <CRow gutters={false} className="mt-2">
                                                        <Error>{licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.error}</Error>
                                                    </CRow>}
                                                </td>
                                            );
                                            }}</Observer>,
                                        other: (licenseSession: LicenseSession) =>
                                            <Observer>{() => {
                                                const duration = licenseSession.duration?.toFixed(2);
                                                const leaderboardUrl = `${leaderboardBaseURI}/${licenseSession.reportedSessionId}`;

                                                return (
                                                <td>
                                                    <CRow gutters={false}>
                                                        <strong>{t`Players:`}</strong>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        {licenseSession.players}
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <strong>{t`Duration:`}</strong>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        {t`${duration}min`}
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <strong>{t`Games:`}</strong>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        {licenseSession.licenseSessionsByMasterSessionId.nodes.length || !licenseSession.masterSessionId && 1 || 0}
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <strong>{t`Leaderboard`}</strong>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <CLink href={leaderboardUrl}
                                                               target="_blank">
                                                            {leaderboardUrl}
                                                        </CLink>
                                                    </CRow>

                                                    <CRow gutters={false}>
                                                        <CLink onClick={() => licenseSessions.toggleShowMoreInfo(licenseSession.id)}
                                                               disabled={licenseSessions.loading}
                                                               title={t`Toggle more info`}>
                                                            <CIcon name="cil-storage" className="mfe-2"/>
                                                        </CLink>
                                                    </CRow>

                                                    <CCollapse show={licenseSessions.showMoreInfoLicenseSessionIds.has(licenseSession.id)}>
                                                        {licenseSession.licenseSessionsByMasterSessionId.nodes.map(ls => ls && renderAdditionalLicenseSessionInfo(ls))}

                                                        {licenseSession.licenseSessionsByMasterSessionId.nodes.length > 0 && <CRow gutters={false}>&nbsp;</CRow>}

                                                        <CRow gutters={false}>
                                                            <strong>{t`Common`}</strong>
                                                        </CRow>

                                                        {renderAdditionalLicenseSessionInfo(licenseSession)}

                                                        {licenseSession.productVersion && licenseSession.productVersion.length > 0 &&
                                                                <React.Fragment>
                                                                    <CRow gutters={false}>
                                                                        <strong>{t`Product:`}</strong>
                                                                    </CRow>

                                                                    <CRow gutters={false}>
                                                                        {licenseSession.productVersion?.join(', ')}
                                                                    </CRow>
                                                                </React.Fragment>}

                                                        {licenseSession.osVersion && licenseSession.osVersion.length > 0 &&
                                                                <React.Fragment>
                                                                    <CRow gutters={false}>
                                                                        <strong>{t`OS ver:`}</strong>
                                                                    </CRow>

                                                                    <CRow gutters={false}>
                                                                        {licenseSession.osVersion?.join(', ')}
                                                                    </CRow>
                                                                </React.Fragment>}

                                                        {licenseSession.location && licenseSession.location.length > 0 &&
                                                                <React.Fragment>
                                                                    <CRow gutters={false}>
                                                                        <strong>{t`Location:`}</strong>
                                                                    </CRow>

                                                                    <CRow gutters={false}>
                                                                        {licenseSession.location?.join(', ')}
                                                                    </CRow>
                                                                </React.Fragment>}

                                                        {licenseSession.ip && licenseSession.ip.length > 0 &&
                                                                <React.Fragment>
                                                                    <CRow gutters={false}>
                                                                        <strong>{t`Ip:`}</strong>
                                                                    </CRow>

                                                                    <CRow gutters={false}>
                                                                        {licenseSession.ip?.join(', ')}
                                                                    </CRow>
                                                                </React.Fragment>}

                                                        {licenseSession.machineid && licenseSession.machineid.length > 0 &&
                                                                <React.Fragment>
                                                                    <CRow gutters={false}>
                                                                        <strong>{t`Machine id:`}</strong>
                                                                    </CRow>

                                                                    <CRow gutters={false}>
                                                                        {licenseSession.machineid?.join(', ')}
                                                                    </CRow>
                                                                </React.Fragment>}
                                                    </CCollapse>
                                                </td>
                                                );
                                            }}</Observer>,
                                        startTimeLocal: (licenseSession: LicenseSession) => (
                                                <td>
                                                    <DateAndTime date={new Date(licenseSession.startTimeLocal)} local={true}/>
                                                </td>
                                        ),
                                        edit: (licenseSession: LicenseSession) =>
                                                <Observer>{() => {
                                                    return (
                                                        <td className="py-2">
                                                            {(!licenseSession.isLocked || auth.currentUser?.role === UserRoleType.VerControlpanelAdmin) && <CLink onClick={() => toggleEdit(licenseSession)}
                                                                   disabled={licenseSessions.loading}
                                                                   title={t`Edit`}>
                                                                <CIcon name="cil-pencil" className="mfe-2"/>
                                                            </CLink>}

                                                            {!licenseSession.licenseByLicenseId && !licenseSession.licenseV2ByLicenseV2Id && <CLink onClick={() => licenseSessions.redetectLicenseSession(licenseSession.id)}
                                                                   disabled={licenseSessions.loading}
                                                                   title={t`Redetect license session`}>
                                                                <CIcon name="cil-loop-circular" className="mfe-2" />
                                                            </CLink>}
                                                        </td>
                                                    );
                                                }}</Observer>,
                                        details: (licenseSession: LicenseSession) =>
                                                <Observer key={licenseSession.id}>{() => {
                                                    return licenseSessions.editingLicenseSession?.id !== licenseSession.id ? (<React.Fragment/>) : (
                                                            <React.Fragment>
                                                                <CCardBody>
                                                                     <EditLicenseSession onSubmit={(
                                                                             price: PriceDefinition,
                                                                             isPaid: boolean,
                                                                             comment: string,
                                                                             royalty?: RoyaltyDefinition,
                                                                             isLocked?: boolean
                                                                     ) => saveLicenseSession(licenseSession.id, price, isPaid, comment, royalty, isLocked)}
                                                                     onCancel={() => licenseSessions.editLicenseSession(undefined)}
                                                                     licenseSession={licenseSession}
                                                                     loading={!!licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.loading}
                                                                     error={licenseSessions.editingLicenseSessionStatus.get(licenseSession.id)?.error}/>
                                                                </CCardBody>
                                                            </React.Fragment>
                                                    );
                                                }}</Observer>
                                    }}
                                    underTableSlot={licenseSessions.error ? <Error>{licenseSessions.error}</Error> : <Pagination loading={licenseSessions.loading}
                                                                                                                     pageInfo={licenseSessions.pageInfo}
                                                                                                                     onPage={(page) => licenseSessions.setPage(page)}/>}/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </React.Fragment>
    );
});

export default FinancialReportsView;
import {action, autorun, computed, observable, runInAction} from "mobx";
import {t} from "ttag";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {ApiError, NotAuthenticatedError} from "../api/AbstractApi";
import {Maybe, Query, Timezone, User, UserRoleType} from "../generated/graphql";
import {goToPrev, goToWithPrev, routes} from "../routes";
import {loadCurrentLocale, setCurrentLocale} from "../utils/I18nUtils";
import authApi from "../api/AuthApi";
import CurrentUserFragment from "../api/graphql/CurrentUserFragment";
import healthApi from "../api/HealthApi";

class UserNotConfigured implements ApiError {
    getMessage(): string {
        return t`User is not configured properly! Please ask Another World support for further instructions.`;
    }
}

class AuthStore {
    private rootStore: RootStore;

    @observable.ref
    public currentUser: Maybe<User> | undefined = undefined;

    @observable.ref
    public timezones: Maybe<Timezone>[] | undefined = undefined;

    @observable
    public isSecondaryMode: boolean | undefined = false;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.currentUser) {
                setCurrentLocale(this.currentUser.locale);
                loadCurrentLocale();
            }
        })
    }

    @computed
    public get isAuthorized(): boolean {
        return !!this.currentUser &&
            (this.currentUser.role === UserRoleType.VerControlpanelAdmin ||
             this.currentUser.role === UserRoleType.VerControlpanelUser && !!this.currentUser.userCustomerByUserId?.customerByCustomerId ||
             this.currentUser.role === UserRoleType.VerControlpanelReport);
    }

    @computed
    public get isServiceUser(): boolean {
        return !!this.currentUser &&
            (this.currentUser.role === UserRoleType.VerControlpanelReport);
    }

    @computed
    public get isAuthorizing(): boolean {
        return this.loading;
    }

    @action
    public fetchCurrentInfo() {
        this.currentUser = undefined;
        this.timezones = undefined;
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query>({
            query: gql`query {
                currentUserId,
                currentUser {
                    ...CurrentUserFragment
                },
                allTimezones {
                    nodes {
                        name,
                        utcSecondsOffset
                    }
                }
            }
            ${CurrentUserFragment}`,
            fetchPolicy: "network-only"
        })).then(result => {
            this.setCurrentUser(result.data.currentUser);

            if (this.isAuthorized) {
                this.setTimezones(result.data.allTimezones?.nodes);

                goToPrev(this.rootStore);
            } else {
                console.error(`AuthStore.fetchCurrentUser gets unexpected result: ${JSON.stringify(result.data)}`);
                this.reset();

                runInAction(() => {
                    this.error = (new UserNotConfigured()).getMessage();
                });

                //goToWithPrev(this.rootStore, routes.login);
            }
        }, error => {
            if (error instanceof NotAuthenticatedError) {
                this.setCurrentUser(null);
                goToWithPrev(this.rootStore, routes.login);
            } else {
                runInAction(() => {
                    this.error = error.getMessage();
                });
            }
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });

        this.fetchIsSecondaryMode();
    }

    @action
    public fetchIsSecondaryMode() {
        this.isSecondaryMode = undefined;

        healthApi.isSeoondaryMode().then((isSecondaryMode) => {
            runInAction(() => {
                this.isSecondaryMode = isSecondaryMode;
            });
        }, (error: ApiError) => {
            console.error(error.getMessage());
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            })
        });
    }

    @action
    public logout(allSessions: boolean = false) {
        this.currentUser = undefined;
        this.loading = true;
        this.error = undefined;

        const resultPromise = allSessions ?
            authApi.logoutAll() : authApi.logout();

        resultPromise.then(() => {
            window.location.href = "/";
        }, (error: ApiError) => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            })
        });
    }

    @action
    public setCurrentUser(user: Maybe<User> | undefined) {
        this.currentUser = user;
    }

    @action
    public setTimezones(timezones: Maybe<Timezone>[] | undefined) {
        this.timezones = timezones;
    }

    @action
    public reset() {
        this.currentUser = undefined;
        this.timezones = undefined;
        this.isSecondaryMode = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export default AuthStore;
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {LicenseV2ClaimValue, LicenseV2StringClaimValue} from "../api/LicenseV2Claims";
import {CInput, CInputGroup, CPopover, CRow} from "@coreui/react";
import {t} from "ttag";
import JSON5 from "json5";
import {validateLicenseV2StringClaimValue} from "../utils/LicenseV2ClaimUtils";

interface EditLicenseV2StringClaimProps {
    label?: string;
    editClaimValue: LicenseV2StringClaimValue;
    onSetEditClaimValue: (value: LicenseV2ClaimValue) => void;
}

const validate = (val: string | null | undefined): boolean => {
    try {
        if (!val || val.trim().length === 0) {
            return false;
        }

        const parsed = JSON5.parse(val) as string[];

        return validateLicenseV2StringClaimValue({
            exact: parsed
        });
    } catch (e) {
        return false;
    }
};

const EditLicenseV2StringClaim = observer((props: EditLicenseV2StringClaimProps) => {
    const [isStringValid, setIsStringValid] = useState(false);

    const stringInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        setIsStringValid(validate(stringInputRef?.current?.value));
    }, [stringInputRef?.current?.value]);

    const onChange = (value: string) => {
        if (!validate(value)) {
            props.onSetEditClaimValue({
                exact: undefined
            });
        } else {
            props.onSetEditClaimValue({
                exact: JSON5.parse(value) as string[]
            });
        }
    };

    return (
        <React.Fragment>
            {props.label && <CRow gutters={false}>
                <strong>{props.label}</strong>
            </CRow>}

            <CRow gutters={false}>
                <CPopover content={t`Example ["String value 1", "Some value"] or [] in case of none`} placement="right"
                          advancedOptions={{onShow: () => !isStringValid}}>
                    <CInputGroup>
                        <CInput type="text"
                                innerRef={stringInputRef}
                                invalid={!isStringValid}
                                defaultValue={props.editClaimValue.exact && JSON5.stringify(props.editClaimValue.exact) || ""}
                                onChange={(event) => onChange((event.target as HTMLInputElement).value)}/>
                    </CInputGroup>
                </CPopover>
            </CRow>
        </React.Fragment>
    );
});

export default EditLicenseV2StringClaim;
import {CButton, CCol, CForm, CInput, CLabel, CPopover, CRow, CSelect, CSpinner, CSwitch} from "@coreui/react";
import React, {useContext} from "react";
import {c, jt, t} from "ttag";
import {observer} from "mobx-react-lite";
import {useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";
import {User, UserRoleType} from "../generated/graphql";
import {StoreContext} from "../context/StoreContext";
import Error from "./Error";
import Locales from "./Locales";
import Timezones from "./Timezones";
import Customers from "./Customers";

interface EditUserProps {
    user?: User;
    loading?: boolean;
    error?: string;
    onSubmit: (
        name: string,
        locale: string,
        timezone: string,
        isDisabled?: boolean,
        role?: UserRoleType,
        customerId?: string,
        login?: string,
        password?: string
    ) => void;
    onCancel?: () => void;
    isVertical?: boolean;
    hideCancel?: boolean;
}

interface UserSettingsInput {
    newusername: string;
    locale: string;
    timezone: string;
    role?: UserRoleType;
    customerId?: string;
    isdisabled?: boolean;
    login?: string;
    newpassword: string;
}

const EditUser: React.FC<EditUserProps> = observer((props) => {
    const store = useContext(StoreContext);
    const {auth, activeCustomers} = store;

    const {register, handleSubmit, errors, watch, setValue, reset} = useForm<UserSettingsInput>({
        defaultValues: {
            newusername: props.user?.name,
            locale: props.user?.locale,
            timezone: props.user?.timezone || auth.currentUser?.timezone,
            role: UserRoleType.VerControlpanelUser,
            isdisabled: !!props.user?.isDisabled
        }
    });

    const setDefaultCredentials = () => {
        const uuidSplit = uuidv4().split('-');
        setValue("login", `user_${uuidSplit[1]}`);
        setValue("newpassword", uuidSplit[2]);
    };

    const onSubmit = (data: UserSettingsInput) => {
        props.onSubmit(
            data.newusername,
            data.locale,
            data.timezone,
            data.isdisabled,
            data.role,
            data.customerId,
            data.login,
            data.newpassword
        );
        reset();
    };

    const usernameBlock = <React.Fragment>
        <CLabel htmlFor="newusername">{t`Username`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.newusername}}>
            <CInput name="newusername" type="text"
                    autoComplete="new-user"
                    innerRef={register({required: true})}
                    invalid={!!errors.newusername}
                    onBlur={() => !props.user && setDefaultCredentials()}/>
        </CPopover>
    </React.Fragment>;

    const localeBlock = <React.Fragment>
        <CLabel htmlFor="locale">{t`Locale`}</CLabel>
        <CPopover content={t`Required`} placement="right" advancedOptions={{onShow: () => !!errors.locale}}>
            <CSelect name="locale"
                     innerRef={register({required: true})}
                     invalid={!!errors.locale}>
                <Locales/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const timezoneBlock = <React.Fragment>
        <CLabel htmlFor="timezone">{t`Timezone`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.timezone}}>
            <CSelect name="timezone"
                     innerRef={register({required: true})}
                     invalid={!!errors.timezone}>
                <Timezones/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const roleBlock = !props.user && <React.Fragment>
        <CLabel htmlFor="role">{t`Role`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.role}}>
            <CSelect name="role"
                     innerRef={register({required: true})}
                     invalid={!!errors.role}>
                <option key={UserRoleType.VerControlpanelUser} value={UserRoleType.VerControlpanelUser}>{t`user`}</option>

                <option key={UserRoleType.VerControlpanelAdmin} value={UserRoleType.VerControlpanelAdmin}>{t`admin`}</option>

                <option key={UserRoleType.VerControlpanelReport} value={UserRoleType.VerControlpanelReport}>{t`report`}</option>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const customerBlock = !props.user && watch("role", UserRoleType.VerControlpanelUser) === UserRoleType.VerControlpanelUser && <React.Fragment>
        <CLabel htmlFor="customerId">{t`Customer`}</CLabel>
        <CPopover content={activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length > 0 ? t`Required` : t`No active customers`)} placement="right"
                  advancedOptions={{onShow: () => !!errors.customerId || !!activeCustomers.error || (activeCustomers.allActiveCustomers && activeCustomers.allActiveCustomers.length <= 0)}}>
            <CSelect name="customerId"
                     innerRef={register({required: true})}
                     invalid={!!errors.customerId}>
                <Customers/>
            </CSelect>
        </CPopover>
    </React.Fragment>;

    const loginBlock = !props.user && <React.Fragment>
        <CLabel htmlFor="login">{c("username").t`Login`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.login}}>
            <CInput name="login" type="text"
                    autoComplete="new-user"
                    innerRef={register({required: true})}
                    invalid={!!errors.login}/>
        </CPopover>
    </React.Fragment>;

    const passwordBlock = <React.Fragment>
        <CLabel htmlFor="newpassword">{t`Password`}</CLabel>
        <CPopover content={t`Required`} placement="right"
                  advancedOptions={{onShow: () => !!errors.newpassword}}>
            <CInput name="newpassword" type="text"
                    autoComplete="new-password"
                    innerRef={register({required: !props.user?.id})}
                    invalid={!!errors.newpassword}/>
        </CPopover>
    </React.Fragment>;

    const disabledBlock = props.user && <React.Fragment>
        <CLabel htmlFor="isdisabled" style={{width: "100%"}}>{t`Disabled`}</CLabel>
        <CSwitch name="isdisabled"
                 innerRef={register()}
                 shape="pill"
                 color="danger"/>
    </React.Fragment>;

    const submitBlock = <CButton color="primary" className={{"px-4": true, "ml-2": !props.isVertical}} type="submit" disabled={!!props.loading}>
        {props.user ? t`Save` : t`Create`}
        {props.loading &&
        <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
    </CButton>;

    const cancelBlock = !props.hideCancel &&
            <CButton color="secondary" className="px-4 ml-2" type="reset" onClick={props.onCancel}>{jt`Cancel`}</CButton>;

    const elements = props.isVertical ? <React.Fragment>
        <CRow>
            {usernameBlock}
        </CRow>

        <CRow className="mt-2">
            {localeBlock}
        </CRow>

        <CRow className="mt-2">
            {timezoneBlock}
        </CRow>

        {roleBlock && <CRow className="mt-2">
            {roleBlock}
        </CRow>}

        {customerBlock && <CRow className="mt-2">
            {customerBlock}
        </CRow>}

        {loginBlock && <CRow className="mt-2">
            {loginBlock}
        </CRow>}

        <CRow className="mt-2">
            {passwordBlock}
        </CRow>

        {disabledBlock && <CRow className="mt-2">
            {disabledBlock}
        </CRow>}

        <CRow className="mt-2" alignHorizontal="right">
            {submitBlock}

            {cancelBlock}
        </CRow>
    </React.Fragment> : <React.Fragment>
        <CRow form={true}>
            <CCol>
                {usernameBlock}
            </CCol>

            <CCol>
                {localeBlock}
            </CCol>

            <CCol>
                {timezoneBlock}
            </CCol>

            {roleBlock && <CCol>
                {roleBlock}
            </CCol>}

            {customerBlock && <CCol>
                {customerBlock}
            </CCol>}

            {loginBlock && <CCol>
                {loginBlock}
            </CCol>}

            <CCol>
                {passwordBlock}
            </CCol>

            {disabledBlock && <CCol sm="2">
                {disabledBlock}
            </CCol>}

            <CCol>
                {submitBlock}

                {cancelBlock}
            </CCol>
        </CRow>
    </React.Fragment>;

    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            {elements}

            {props.error && <CRow><Error>{props.error}</Error></CRow>}
        </CForm>
    );
});

export default EditUser;
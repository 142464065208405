import {StatusCodes} from "http-status-codes";
import {t} from "ttag";
import {AbstractApi, ApiError, wrapForApiErrors} from "./AbstractApi";

class WrongCredentialsError implements ApiError {
    getMessage(): string {
        return t`Wrong credentials!`;
    }
}

class AuthApi extends AbstractApi {
    protected baseUrl(): string {
        return `${super.baseUrl()}/api/v1/auth`;
    }

    public authenticate(login: string, password: string): Promise<void> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/authenticate`, {
            method: "POST",
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined,
            body: JSON.stringify({
                login,
                password
            }),
            headers: {"Content-Type": "application/json"}
        }), [StatusCodes.OK, StatusCodes.FORBIDDEN]).then((response: Response) => {
            if (response.status === StatusCodes.FORBIDDEN) {
                throw new WrongCredentialsError();
            }
        });
    }

    public logout(): Promise<void> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/logout`, {
            method: "POST",
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK, StatusCodes.FORBIDDEN]).then((response: Response) => {
            if (response.status === StatusCodes.FORBIDDEN) {
                console.warn("AuthApi.logout failed");
            }
        });
    }

    public logoutAll(): Promise<void> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/logout_all`, {
            method: "POST",
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK, StatusCodes.FORBIDDEN]).then((response: Response) => {
            if (response.status === StatusCodes.FORBIDDEN) {
                console.warn("AuthApi.logoutAll failed");
            }
        });
    }

    public issueServiceToken(): Promise<string> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/issue_service_token`, {
            method: "POST",
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK]).then(async (response: Response) => {
            const json = await response.json() as {token: string};

            return json && json.token;
        });
    }
}

const authApi = new AuthApi();

export default authApi;
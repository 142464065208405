import gql from "graphql-tag";

const UserFragment = gql`fragment UserFragment on User {
    id,
    role,
    name,
    login,
    locale,
    timezone,
    timezoneByTimezone {
        utcSecondsOffset
    },
    createdAtUtc,
    isDisabled,
    userCustomerByUserId {
        customerByCustomerId {
            id,
            name,
            isArchived
        }
    }
}`;

export default UserFragment;
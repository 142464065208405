import {action, computed, observable, reaction, runInAction} from "mobx";
import {RootStore} from "./RootStore";
import {
    Customer,
    LicenseSession,
    LicenseSessionFilter,
    LicenseSessionsOrderBy,
    Maybe,
    Product, Venue
} from "../generated/graphql";
import {
    DelayMSDefault,
    PageInfo,
    PageParam,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import {debounceEffect} from "../utils/MobxUtils";
import {toISODate} from "../utils/DateUtils";
import {
    getAllLicensesPageInfo, getAllLicensesQuery, IncludeUnpaid,
    OrderByType
} from "../api/LicenseSessionsApi";

class ProductsUsageStore {
    @observable.ref
    public page: PageParam = {
        after: null,
        before: null,
        isLast: false
    };

    @observable.ref
    public licenseSessions: Maybe<LicenseSession>[] | undefined = undefined;

    @observable
    public showMoreInfoLicenseSessionIds: Set<string> = new Set<string>();

    @observable.ref
    public pageInfo: PageInfo | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(() => {
            return {
                filter: this.filter,
                orderBy: this.orderBy
            };
        }, () => {
            if (this.pageInfo?.firstPage) {
                this.setPage(this.pageInfo.firstPage);
            }
        });

        reaction(() => {
            return {
                filter: this.filter,
                orderBy: this.orderBy,
                page: this.page
            };
        }, debounceEffect(() => {
            this.fetchLicenseSessions();
        }, DelayMSDefault));
    }

    @computed
    public get filter(): LicenseSessionFilter | undefined {
        return ProductsUsageStore.constructLicenseSessionFilter(
            this.rootStore.reportsFilter.filterIncludeUnpaid,
            this.rootStore.reportsFilter.filterCustomers,
            this.rootStore.reportsFilter.filterCustomerTags,
            this.rootStore.reportsFilter.filterVenues,
            this.rootStore.reportsFilter.filterProducts,
            this.rootStore.reportsFilter.filterDateStart,
            this.rootStore.reportsFilter.filterDateEnd
        );
    }

    @computed
    public get orderBy(): LicenseSessionsOrderBy[] {
        switch (this.rootStore.reportsFilter.orderBy) {
            case OrderByType.ByDateAsc:
                return [LicenseSessionsOrderBy.StartTimeLocalAsc, LicenseSessionsOrderBy.CreatedAtUtcAsc];
            case OrderByType.ByDateDesc:
            default:
                return [LicenseSessionsOrderBy.StartTimeLocalDesc, LicenseSessionsOrderBy.CreatedAtUtcDesc];
        }
    }

    @action
    public async fetchLicenseSessions() {
        this.loading = true;
        this.error = undefined;

        try {
            const result = await this.licenseSessionsQuery();

            runInAction(() => {
                this.licenseSessions = result.data.allLicenseSessions?.nodes;
                this.pageInfo = getAllLicensesPageInfo(result.data.allLicenseSessions);
            });
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async licenseSessionsQuery() {
        return wrapForApiErrors(getAllLicensesQuery(
            this.orderBy,
            this.page,
            this.filter
        ));
    }

    @action
    public setPage(page: PageParam) {
        this.page = page;
        this.showMoreInfoLicenseSessionIds.clear();
    }

    @action
    public toggleShowMoreInfo(licenseSessionId: string) {
        if (this.showMoreInfoLicenseSessionIds.has(licenseSessionId)) {
            this.showMoreInfoLicenseSessionIds.delete(licenseSessionId);
        } else {
            this.showMoreInfoLicenseSessionIds.add(licenseSessionId)
        }
    }

    @action
    public reset() {
        this.page = {
            after: null,
            before: null,
            isLast: false
        };

        this.licenseSessions = undefined;
        this.showMoreInfoLicenseSessionIds.clear();
        this.pageInfo = undefined;
        this.loading = false;
        this.error = undefined;
    }

    private static constructLicenseSessionFilter(
        filterIncludeUnpaid: IncludeUnpaid,
        filterCustomers: Customer[],
        filterCustomerTags: string[],
        filterVenues: Venue[],
        filterProducts: Product[],
        filterDateStart: Date | null,
        filterDateEnd: Date | null
    ): LicenseSessionFilter | undefined {
        const conditions: LicenseSessionFilter[] = [];

        if (filterCustomers.length > 0) {
            const customersCondition = {
                customerId: {
                    in: filterCustomers.map(customer => customer.id)
                }
            };

            conditions.push(customersCondition);
        }

        if (filterCustomerTags.length > 0) {
            const tagsCondition = {
                customerByCustomerId: {
                    tags: {
                        contains: filterCustomerTags
                    }
                }
            };

            conditions.push(tagsCondition);
        }

        if (filterIncludeUnpaid === IncludeUnpaid.NotInclude) {
            conditions.push({
                isPaidApproved: {
                    equalTo: true
                }
            });
        }

        if (filterVenues.length > 0) {
            const venuesCondition = {
                venueId: {
                    in: filterVenues.map(venue => venue.id)
                }
            };

            conditions.push(venuesCondition);
        }

        if (filterProducts.length > 0) {
            const productsCondition = {
                productId: {
                    in: filterProducts.map(product => product.id)
                }
            };

            conditions.push(productsCondition);
        }

        if (filterDateStart) {
            conditions.push({
                startTimeLocal: {
                    greaterThanOrEqualTo: toISODate(filterDateStart)
                }
            });
        }

        if (filterDateEnd) {
            conditions.push({
                startTimeLocal: {
                    lessThanOrEqualTo: `${toISODate(filterDateEnd)}T23:59:59`
                }
            });
        }

        conditions.push({
            productByProductId: {
                isGame: {
                    equalTo: true
                }
            }
        });

        return conditions.length <= 0 ? undefined : {
            and: conditions
        };
    }
}

export default ProductsUsageStore;
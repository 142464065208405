import {action, observable, runInAction} from "mobx";
import {RootStore} from "./RootStore";
import authApi from "../api/AuthApi";
import {goToPrev} from "../routes";
import {ApiError} from "../api/AbstractApi";

interface LoginInput {
    login: string;
    password: string;
}

class LoginStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public login(input: LoginInput) {
        this.loading = true;
        this.error = undefined;

        authApi.authenticate(input.login, input.password).then(() => {
            this.rootStore.auth.reset();
            goToPrev(this.rootStore);
        }, (error: ApiError) => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            })
        });
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }
}

export type {
    LoginInput
};

export {
    LoginStore
};
import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Mutation,
    PriceCalendarDefinition,
    PriceDefinition,
    RoyaltyDefinition
} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";

class NewLicenseStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async create(
        productId: string,
        venueId: string,
        licenseKey: string,
        expirateAtUtc: string,
        price: PriceDefinition,
        royalty: RoyaltyDefinition,
        priceCalendar?: PriceCalendarDefinition[]
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            await this.createLicense(productId, venueId, licenseKey, expirateAtUtc, price, royalty, priceCalendar);

            this.rootStore.licenses.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private createLicense(productId: string,
                          venueId: string,
                          licenseKey: string,
                          expirateAtUtc: string,
                          price: PriceDefinition,
                          royalty: RoyaltyDefinition,
                          priceCalendar?: PriceCalendarDefinition[]): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            productId: string,
            venueId: string,
            licenseKey: string,
            expirateAtUtc: string,
            price: PriceDefinition,
            priceCalendar?: PriceCalendarDefinition[],
            royalty: RoyaltyDefinition
        }>({
            mutation: gql`mutation ($productId: UUID!, $venueId: UUID!, $licenseKey: LicenseKeyType!, $expirateAtUtc: Datetime!, $price: PriceDefinitionInput!, $priceCalendar: [PriceCalendarDefinitionInput], $royalty: RoyaltyDefinitionInput!) {
                createLicense(input: {license: {productId: $productId, venueId: $venueId, licenseKey: $licenseKey, expirateAtUtc: $expirateAtUtc, price: $price, priceCalendar: $priceCalendar, royalty: $royalty}}) {
                    clientMutationId
                }
            }`,
            variables: {
                productId,
                venueId,
                licenseKey,
                expirateAtUtc,
                price,
                priceCalendar,
                royalty
            }
        }));
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }
}

export {
    NewLicenseStore
};
import AuthStore from "./AuthStore";
import UiStore from "./UiStore";
import {RouterStore} from "mobx-router";
import {LoginStore} from "./LoginStore";
import {CurrentUserSettingsStore} from "./CurrentUserSettingsStore";
import {UsersStore} from "./UsersStore";
import {CustomersStore} from "./CustomersStore";
import {ActiveCustomersStore} from "./ActiveCustomersStore";
import {EditUserStore} from "./EditUserStore";
import {NewUserStore} from "./NewUserStore";
import {EditCustomerStore} from "./EditCustomerStore";
import {NewCustomerStore} from "./NewCustomerStore";
import {VenuesStore} from "./VenuesStore";
import {EditVenueStore} from "./EditVenueStore";
import {NewVenueStore} from "./NewVenueStore";
import {ProductsStore} from "./ProductsStore";
import {EditProductStore} from "./EditProductStore";
import {NewProductStore} from "./NewProductStore";
import {LicensesStore} from "./LicensesStore";
import {EditLicenseStore} from "./EditLicenseStore";
import {NewLicenseStore} from "./NewLicenseStore";
import {ActiveVenuesStore} from "./ActiveVenuesStore";
import {UserLicensesStore} from "./UserLicensesStore";
import LicenseSessionsStore from "./LicenseSessionsStore";
import LicenseSessionsAggStore from "./LicenseSessionsAggStore";
import {ReportsFilterStore} from "./ReportsFilterStore";
import {LoadedSesionsStore} from "./LoadedSesionsStore";
import {UserFilesStore} from "./UserFilesStore";
import ProductsUsageStore from "./ProductsUsageStore";
import ProductsUsageAggStore from "./ProductsUsageAggStore";
import {DashboardStore} from "./DashboardStore";
import {LicensesV2Store} from "./LicensesV2Store";
import {EditLicenseV2Store} from "./EditLicenseV2Store";
import {UserLicensesV2Store} from "./UserLicensesV2Store";

class RootStore {
    public readonly router: RouterStore<RootStore>;
    public readonly auth: AuthStore;
    public readonly login: LoginStore;
    public readonly currentUserSettings: CurrentUserSettingsStore;
    public readonly users: UsersStore;
    public readonly editUser: EditUserStore;
    public readonly newUser: NewUserStore;
    public readonly customers: CustomersStore;
    public readonly editCustomer: EditCustomerStore;
    public readonly newCustomer: NewCustomerStore;
    public readonly activeCustomers: ActiveCustomersStore;
    public readonly venues: VenuesStore;
    public readonly activeVenues: ActiveVenuesStore;
    public readonly editVenue: EditVenueStore;
    public readonly newVenue: NewVenueStore;
    public readonly products: ProductsStore;
    public readonly editProduct: EditProductStore;
    public readonly newProduct: NewProductStore;
    public readonly licenses: LicensesStore;
    public readonly userLicenses: UserLicensesStore;
    public readonly editLicense: EditLicenseStore;
    public readonly newLicense: NewLicenseStore;
    public readonly licenseSessions: LicenseSessionsStore;
    public readonly licenseSessionsAgg: LicenseSessionsAggStore;
    public readonly reportsFilter: ReportsFilterStore;
    public readonly productsUsage: ProductsUsageStore;
    public readonly productsUsageAgg: ProductsUsageAggStore;
    public readonly loadedSessions: LoadedSesionsStore;
    public readonly userFiles: UserFilesStore;
    public readonly dashboard: DashboardStore;
    public readonly licensesV2: LicensesV2Store;
    public readonly editLicenseV2: EditLicenseV2Store;
    public readonly userLicensesV2: UserLicensesV2Store;
    public readonly ui: UiStore;

    constructor() {
        this.router = new RouterStore<RootStore>(this);
        this.auth = new AuthStore(this);
        this.login = new LoginStore(this);
        this.currentUserSettings = new CurrentUserSettingsStore(this);
        this.users = new UsersStore(this);
        this.editUser = new EditUserStore(this);
        this.newUser = new NewUserStore(this);
        this.customers = new CustomersStore(this);
        this.editCustomer = new EditCustomerStore(this);
        this.newCustomer = new NewCustomerStore(this);
        this.activeCustomers = new ActiveCustomersStore(this);
        this.venues = new VenuesStore(this);
        this.activeVenues = new ActiveVenuesStore(this);
        this.editVenue = new EditVenueStore(this);
        this.newVenue = new NewVenueStore(this);
        this.products = new ProductsStore(this);
        this.editProduct = new EditProductStore(this);
        this.newProduct = new NewProductStore(this);
        this.licenses = new LicensesStore(this);
        this.userLicenses = new UserLicensesStore(this);
        this.editLicense = new EditLicenseStore(this);
        this.newLicense = new NewLicenseStore(this);
        this.reportsFilter = new ReportsFilterStore(this);
        this.licenseSessions = new LicenseSessionsStore(this);
        this.licenseSessionsAgg = new LicenseSessionsAggStore(this);
        this.productsUsage = new ProductsUsageStore(this);
        this.productsUsageAgg = new ProductsUsageAggStore(this);
        this.loadedSessions = new LoadedSesionsStore(this);
        this.userFiles = new UserFilesStore(this);
        this.dashboard = new DashboardStore(this);
        this.licensesV2 = new LicensesV2Store(this);
        this.editLicenseV2 = new EditLicenseV2Store(this);
        this.userLicensesV2 = new UserLicensesV2Store(this);
        this.ui = new UiStore(this);
    }
}

const store = new RootStore();

export {
    RootStore,
    store
};

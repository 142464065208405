import React, {useContext} from "react";
import {CSidebar, CSidebarBrand, CSidebarNav, CSidebarNavItem, CSidebarNavTitle} from "@coreui/react";
import {t} from "ttag";
import {StoreContext} from "../context/StoreContext";
import {observer} from "mobx-react-lite";
import {routes} from "../routes";
import {UserRoleType} from "../generated/graphql";

const AdminSections = observer(() => {
    const store = useContext(StoreContext);
    const {router} = store;

    return (
        <React.Fragment>
            <CSidebarNavTitle>
                {t`Admin Panel`}
            </CSidebarNavTitle>

            <CSidebarNavItem name={t`Products`}
                             icon="cil-applications"
                             addLinkClass={router.currentRoute === routes.products ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.products)}/>

            <CSidebarNavItem name={t`Customers`}
                             icon="cil-briefcase"
                             addLinkClass={router.currentRoute === routes.customers ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.customers)}/>

            <CSidebarNavItem name={t`Users`}
                             icon="cil-user"
                             addLinkClass={router.currentRoute === routes.users ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.users)}/>

            <CSidebarNavItem name={t`Venues`}
                             icon="cil-basketball"
                             addLinkClass={router.currentRoute === routes.venues ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.venues)}/>

            <CSidebarNavItem name={t`Licenses`}
                             icon="cil-description"
                             addLinkClass={router.currentRoute === routes.licensesV2 ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.licensesV2)}/>

            <CSidebarNavTitle>
                {t`Obsolete`}
            </CSidebarNavTitle>

            <CSidebarNavItem name={t`Licenses`}
                             icon="cil-description"
                             addLinkClass={router.currentRoute === routes.licenses ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.licenses)}/>

            <CSidebarNavItem name={t`Loaded sessions`}
                             icon="cil-cloud-download"
                             addLinkClass={router.currentRoute === routes.loadedSessions ? "c-active" : undefined}
                             onClick={() => router.goTo(routes.loadedSessions)}/>
        </React.Fragment>
    );
});

const Sidebar = observer(() => {
    const store = useContext(StoreContext);
    const {ui, router, auth} = store;

    return (
        <CSidebar show={ui.sidebarShow}
                  onShowChange={ui.setSidebarShow}>
            <CSidebarBrand tag="a" role="button" className="d-md-down-none"
                           onClick={() => router.goTo(routes.authorized)}>
                <img className="c-sidebar-brand-full"
                     src="AW_logo_eng_white_flat_horiz.png"
                     width={110}
                     alt={t`Another World Control Panel`}/>
            </CSidebarBrand>

            <CSidebarNav>
                {auth.isServiceUser &&
                        <CSidebarNavItem name={t`Services`}
                                         icon="cil-settings"
                                         addLinkClass={router.currentRoute === routes.services ? "c-active" : undefined}
                                         onClick={() => router.goTo(routes.services)}/>}

                {!auth.isServiceUser &&
                    <CSidebarNavItem name={t`Dashboard`}
                                 icon="cil-speedometer"
                                 addLinkClass={router.currentRoute === routes.dashboard ? "c-active" : undefined}
                                 onClick={() => router.goTo(routes.dashboard)}/>}

                {!auth.isServiceUser &&
                    <CSidebarNavItem name={t`Financial reports`}
                                 icon="cil-spreadsheet"
                                 addLinkClass={router.currentRoute === routes.financialReports ? "c-active" : undefined}
                                 onClick={() => router.goTo(routes.financialReports)}/>}

                {!auth.isServiceUser &&
                    <CSidebarNavItem name={t`Product reports`}
                                 icon="cil-spreadsheet"
                                 addLinkClass={router.currentRoute === routes.productReports ? "c-active" : undefined}
                                 onClick={() => router.goTo(routes.productReports)}/>}

                {auth.currentUser?.role === UserRoleType.VerControlpanelUser &&
                    <CSidebarNavItem name={t`Products`}
                                     icon="cil-applications"
                                     addLinkClass={router.currentRoute === routes.products ? "c-active" : undefined}
                                     onClick={() => router.goTo(routes.products)}/>}

                {!auth.isServiceUser &&
                    <CSidebarNavItem name={t`User files`}
                                 icon="cil-file"
                                 addLinkClass={router.currentRoute === routes.userFiles ? "c-active" : undefined}
                                 disabled={auth.isSecondaryMode}
                                 title={auth.isSecondaryMode ? t`Isn't available in secondary mode` : undefined}
                                 onClick={() => router.goTo(routes.userFiles)}/>}

                {auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && <AdminSections/>}
            </CSidebarNav>
        </CSidebar>
    );
});

export default Sidebar;
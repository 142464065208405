import {observer} from "mobx-react-lite";
import React from "react";
import {
    LicenseV2BooleanClaimValue,
} from "../api/LicenseV2Claims";
import {CRow, CSwitch} from "@coreui/react";
import {t} from "ttag";

interface EditLicenseV2BooleanClaimProps {
    label?: string;
    editClaimValue: LicenseV2BooleanClaimValue;
    onSetEditClaimValue: (value: LicenseV2BooleanClaimValue) => void;
}

const EditLicenseV2BooleanClaim = observer((props: EditLicenseV2BooleanClaimProps) => {
    if (!props.editClaimValue) {
        return null;
    }

    return (
        <React.Fragment>
            {props.label && <CRow gutters={false}>
                <strong>{props.label}</strong>
            </CRow>}

            <CRow gutters={false} title={props.editClaimValue.exact ? t`Yes` : t`No`}>
                <CSwitch shape="pill"
                         color="secondary"
                         className="mt-2"
                         checked={props.editClaimValue.exact}
                         onChange={(event) => props.onSetEditClaimValue({
                             exact: ((event.target as HTMLInputElement).checked)
                         } as LicenseV2BooleanClaimValue)}/>
            </CRow>
        </React.Fragment>
    );
});

export default EditLicenseV2BooleanClaim;
import {addLocale, useLocale as applyLocale} from "ttag";

const localStorageLocaleKey = "locale";

const supportedLocales: {[key: string]: string} = {
    "en": "English",
    "ru": "Русский",
    "pl": "Polski"
};

function predictLocale(): string | null {
    const navigatorSafe: {[key: string]: any} = navigator;

    const predictedLocaleRawMethod1 = navigatorSafe["languages"] && navigatorSafe["languages"].length && navigatorSafe["languages"][0];
    const predictedLocaleRawMethod2 = navigatorSafe["userLanguage"];
    const predictedLocaleRawMethod3 = navigatorSafe["language"];
    const predictedLocaleRawMethod4 = navigatorSafe["browserLanguage"];

    let predictedLocaleRaw: string = predictedLocaleRawMethod1 || predictedLocaleRawMethod2 || predictedLocaleRawMethod3 || predictedLocaleRawMethod4;

    return predictedLocaleRaw && predictedLocaleRaw.split('-')[0];
}

function detectCurrentLocale(): string | null {
    const localStorageValue = window.localStorage.getItem(localStorageLocaleKey);

    const locale = localStorageValue || predictLocale();

    if (locale && supportedLocales[locale]) {
        return locale;
    }

    return null;
}

function setCurrentLocale(locale: string) {
    window.localStorage.setItem(localStorageLocaleKey, locale);
}

function loadCurrentLocale() {
    const locale = detectCurrentLocale();

    if (locale) {
        if (locale !== "en") {
            const translationsObj = require(`../l10n/${locale}.po.json`);
            addLocale(locale, translationsObj);
        }
        applyLocale(locale);
    }
}

export {
    supportedLocales,
    setCurrentLocale,
    loadCurrentLocale
}
import {action, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Mutation, UserRoleType} from "../generated/graphql";
import {ExecutionResult} from "graphql";
import {apolloClient, wrapForApiErrors} from "../api/graphql/ApolloClient";
import UserFragment from "../api/graphql/UserFragment";
import {EditUserStore} from "./EditUserStore";

class NewUserStore {
    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public async create(
        name: string,
        locale: string,
        timezone: string,
        login: string,
        password: string,
        role: UserRoleType,
        customerId?: string
    ) {
        this.loading = true;
        this.error = undefined;

        try {
            const result = role === UserRoleType.VerControlpanelUser && customerId ?
                await this.registerCustomerUser(name, login, password, customerId) :
                await this.registerUser(name, login, password, role);

            const userId = result?.data?.registerUser?.user?.id || result?.data?.registerCustomerUser?.user?.id;

            if (userId) {
                await EditUserStore.saveGeneralSettings(
                    userId,
                    name,
                    locale,
                    timezone
                );
            }

            this.rootStore.users.reset();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private registerUser(userName: string, login: string, password: string, role: UserRoleType): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            userName: string,
            login: string,
            password: string,
            role: UserRoleType
        }>({
            mutation: gql`mutation ($userName: NameType!, $login: AccountLoginType!, $password: String!, $role: UserRoleType!) {
                registerUser(input: {userName: $userName, accountRole: $role, accountLogin: $login, accountPassword: $password}) {
                    user {
                        ...UserFragment
                    }
                }
            },
            ${UserFragment}`,
            variables: {
                userName,
                login,
                password,
                role
            }
        }));
    }

    private registerCustomerUser(userName: string, login: string, password: string, customerId: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            userName: string,
            login: string,
            password: string,
            customerId: string
        }>({
            mutation: gql`mutation ($userName: NameType!, $login: AccountLoginType!, $password: String!, $customerId: UUID!) {
                registerCustomerUser(input: {userName: $userName, accountLogin: $login, accountPassword: $password, customerIdInput: $customerId}) {
                    user {
                        ...UserFragment
                    }
                }
            },
            ${UserFragment}`,
            variables: {
                userName,
                login,
                password,
                customerId
            }
        }));
    }

    @action
    public reset() {
        this.loading = false;
        this.error = undefined;
    }
}

export {
    NewUserStore
};
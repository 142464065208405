import {CompareSumType, CompareType} from "../api/LicenseSessionsApi";
import {t} from "ttag";

function getLabelPostfixByCompareSumType(compareSumType: CompareSumType) {
    switch (compareSumType) {
        case CompareSumType.Min:
            return t`min`;
        case CompareSumType.Max:
            return t`max`;
        default:
            return "";
    }
}

function getLabelPrefixByKey(key: {customerVenueName?: string, productName?: string} | undefined | null) {
    let labelPrefix = "";

    if (key === null) {
        labelPrefix = t`Unknown`;
    } else if (key?.customerVenueName && key.productName) {
        labelPrefix = `${key?.customerVenueName} : ${key.productName}`
    } else if (key?.customerVenueName) {
        labelPrefix = key.customerVenueName;
    } else if (key?.productName) {
        labelPrefix = key.productName;
    }

    return labelPrefix;
}

function getLabelPostfixByCompareType(compareType: CompareType) {
    switch (compareType) {
        case CompareType.Submitted:
            return t`submitted`;
        case CompareType.Approved:
            return t`approved`;
        case CompareType.Original:
            return t`original`;
    }
}

function createDataset(label: string, color: string, data: number[]): any {
    if (data.every(val => val == 0)) {
        return undefined;
    }

    return {
        label,
        backgroundColor: 'transparent',
        borderColor: color,
        pointHoverBackgroundColor: color,
        borderWidth: 2,
        data
    };
}

function getAggSumDataAggregator(accumulator?: number, currentValue?: number, rows?: number): number {
    if (accumulator === undefined) {
        return 0;
    } else if (currentValue === undefined) {
        return !accumulator ? accumulator : +(accumulator.toFixed(2));
    } else {
        return accumulator + currentValue;
    }
}
/*
function getAggAvgDataAggregator(accumulator?: number, currentValue?: number, rows?: number): number {
    if (accumulator === undefined) {
        return 0.0;
    } else if (currentValue === undefined && rows !== undefined) {
        return accumulator / rows;
    } else if (currentValue !== undefined) {
        return accumulator + currentValue;
    }

    return accumulator;
}*/

function getAggMinDataAggregator(accumulator?: number, currentValue?: number, rows?: number): number {
    if (accumulator === undefined) {
        return Number.MAX_VALUE;
    } else if (currentValue === undefined) {
        return accumulator;
    } else if (accumulator > currentValue) {
        return currentValue;
    } else {
        return accumulator;
    }
}

function getAggMaxDataAggregator(accumulator?: number, currentValue?: number, rows?: number): number {
    if (accumulator === undefined) {
        return -Number.MAX_VALUE;
    } else if (currentValue === undefined) {
        return accumulator;
    } else if (accumulator < currentValue) {
        return currentValue;
    } else {
        return accumulator;
    }
}

function getAggDataAggregator(compareSumType: CompareSumType): (accumulator?: number, currentValue?: number, rows?: number) => number {
    switch (compareSumType) {
        case CompareSumType.Total:
            return getAggSumDataAggregator;
        case CompareSumType.Min:
            return getAggMinDataAggregator;
        case CompareSumType.Max:
            return getAggMaxDataAggregator;
    }
}

function getAggCustomerVenueKey(isAdmin: boolean, agg: {customerName?: string | null, venueCountry?: string | null, venueCity?: string | null, venueLocation?: string | null} | null): string | null {
    if (!agg || !(agg.venueLocation || agg.venueCountry || agg.venueCity)) {
        return null;
    }

    const location = `${agg.venueCountry ? agg.venueCountry + " " : ""}${agg.venueCity ? agg.venueCity + " " : ""}${agg.venueLocation}`;

    return isAdmin ? `${agg.customerName} / ${location}` : location;
}

export {
    getLabelPostfixByCompareSumType,
    getLabelPostfixByCompareType,
    getLabelPrefixByKey,
    createDataset,
    getAggSumDataAggregator,
    getAggMinDataAggregator,
    getAggMaxDataAggregator,
    getAggDataAggregator,
    getAggCustomerVenueKey
}
import gql from "graphql-tag";

const ProductFragment = gql`fragment ProductFragment on Product {
    id,
    isArchived,
    name,
    createdAtUtc,
    isGame
}`;

export default ProductFragment;
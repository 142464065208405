import {action, computed, observable, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {LoadedLicenseSession, Maybe, Query} from "../generated/graphql";
import {
    apolloClient,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import {ApiError} from "../api/AbstractApi";
import loadReportApi from "../api/LoadReportApi";

const timelineStartYear = 2020;
const timelineStartMonth = 9;
const timelineStartDay = 1;

const timelineFirstDate = new Date(timelineStartYear, timelineStartMonth, timelineStartDay, 0, 0, 0, 0);

interface TimelineRow {
    year: number;
    month: number;
    day: number;
    loadedAtUtc?: string;
}

class LoadedSesionsStore {
    @observable.ref
    private loadedLicenseSession: Maybe<LoadedLicenseSession>[] | undefined = undefined;

    @observable
    public loadedLicenseSessionSubmitStatus: Map<string, {loading: boolean, error: string | undefined}>
        = new Map<string, {loading: boolean, error: string | undefined}>();

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed
    public get timeline(): TimelineRow[] {
        const date = new Date ();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setDate(date.getDate() - 1);

        const timeline: TimelineRow[] = [];

        do {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            const loadedLicenseSession = this.loadedLicenseSession &&
                this.loadedLicenseSession.find(rep => rep &&
                    rep.year === year &&
                    rep.month === month &&
                    rep.day === day
                );

            timeline.push({
                year,
                month,
                day,
                loadedAtUtc: loadedLicenseSession ? loadedLicenseSession.createdAtUtc : undefined
            });

            date.setDate(date.getDate() - 1);
        } while (date >= timelineFirstDate);

        return timeline;
    }

    @action
    public fetchLoadedLicenseSession() {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query>({
            query: gql`query {
                currentUserId,
                allLoadedLicenseSessions(orderBy: [YEAR_DESC, MONTH_DESC, DAY_DESC]) {
                    nodes {
                        year,
                        month,
                        day,
                        createdAtUtc
                    }
                }
            }`
        })).then(result => {
            runInAction(() => {
                this.loadedLicenseSession = result.data.allLoadedLicenseSessions?.nodes;
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public submitLoadLicenseSession(year: number, month: number, day: number) {
        const key = [year, month, day].toString();

        this.loadedLicenseSessionSubmitStatus.set(key, {
            loading: true,
            error: undefined
        });

        loadReportApi.submitTask(new Date(year, month - 1, day)).then(() => {
        }, (error: ApiError) => {
            runInAction(() => {
                this.loadedLicenseSessionSubmitStatus.set(key, {
                    loading: false,
                    error: error.getMessage()
                });
            });
        }).finally(() => {
            runInAction(() => {
                this.loadedLicenseSessionSubmitStatus.set(key, {
                    loading: false,
                    error: undefined
                });
            })
        });
    }

    @action
    public reset() {
        this.loadedLicenseSession = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export type {
    TimelineRow
};

export {
    LoadedSesionsStore,
};
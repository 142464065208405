import {AbstractApi, wrapForApiErrors} from "./AbstractApi";
import {StatusCodes} from "http-status-codes";

class HealthApi extends AbstractApi {
    protected baseUrl(): string {
        return `${super.baseUrl()}/api/v1/health`;
    }

    public isSeoondaryMode(): Promise<boolean> {
        return wrapForApiErrors(fetch(this.baseUrl(), {
            method: "GET",
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK]).then(async (response: Response) => {
            const json = await response.json() as {isSecondaryMode: boolean};

            if (!json || (json.isSecondaryMode !== true && json.isSecondaryMode !== false)) {
                throw new Error("Unexpected response");
            }

            return json.isSecondaryMode;
        });
    }
}

const healthApi = new HealthApi();

export default healthApi;
import gql from "graphql-tag";

const LicenseSessionFragment = gql`fragment LicenseSessionFragment on LicenseSession {
    id,
    masterSessionId,
    createdAtUtc,
    reportedSessionId,
    osVersion,
    productVersion,
    licenseKey,
    players,
    isPaid,
    isPaidApproved,
    isPaidOriginal,
    location,
    ip,
    machineid,
    startTimeLocal,
    duration,
    isApproved,
    isLocked,
    price {
        price,
        priceCurrency,
        priceVariant
    },
    priceApproved {
        price,
        priceCurrency,
        priceVariant
    },
    priceOriginal {
        price,
        priceCurrency,
        priceVariant
    },
    priceApprovedTotal,
    priceOriginalTotal,
    priceTotal,
    royalty {
        royalty,
        royaltyShare,
        royaltyCurrency,
        royaltyVariant
    },
    royaltyOriginal {
        royalty,
        royaltyShare,
        royaltyCurrency,
        royaltyVariant
    },
    royaltyApprovedTotal,
    royaltyOriginalTotal,
    royaltyTotal,
    priceDiscount,
    priceApprovedDiscount,
    royaltyDiscount,
    royaltyApprovedDiscount,
    productByProductId {
        id,
        name
    },
    licenseByLicenseId {
        id,
        expirateAtUtc,
        isRevoked
    },
    licenseV2ByLicenseV2Id {
        id,
        expirateAtUtc,
        isRevoked
    },
    venueByVenueId {
        id,
        country,
        city,
        location,
        isArchived
    },
    customerByCustomerId {
        id,
        name,
        tags,
        isArchived
    },
    licenseSessionCommentsByLicenseSessionId(orderBy: [CREATED_AT_UTC_DESC]) {
        nodes {
            id,
            userByUserId {
                id,
                name
            },
            comment,
            createdAtUtc
        }
    }
}`;

export default LicenseSessionFragment;
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {LicenseV2Claim, LicenseV2PriceCalendarItemClaimValue} from "../api/LicenseV2Claims";
import {CInput, CInputGroup, CPopover, CRow} from "@coreui/react";
import {t} from "ttag";
import JSON5 from "json5";
import {getLicenseV2ClaimIdName, validateLicenseV2PriceCalendarClaimValue} from "../utils/LicenseV2ClaimUtils";

interface EditLicenseV2PriceCalendarClaimProps {
    editClaim: LicenseV2Claim;
    onSetEditClaimValue: (value: LicenseV2PriceCalendarItemClaimValue[] | undefined) => void;
}

const validatePriceCalendar = (val: string | null | undefined): boolean => {
    try {
        if (!val || val.trim().length === 0) {
            return false;
        }

        const parsed = JSON5.parse(val) as LicenseV2PriceCalendarItemClaimValue[];

        return validateLicenseV2PriceCalendarClaimValue(parsed);
    } catch (e) {
        return false;
    }
};

const EditLicenseV2PriceCalendarClaim = observer((props: EditLicenseV2PriceCalendarClaimProps) => {
    const [isPriceCalendarValid, setIsPriceCalendarValid] = useState(false);

    const priceCalendarInputRef = useRef<HTMLInputElement>();

    const priceCalendarValue = props.editClaim.value as LicenseV2PriceCalendarItemClaimValue[];

    useEffect(() => {
        setIsPriceCalendarValid(validatePriceCalendar(priceCalendarInputRef?.current?.value));
    }, [priceCalendarInputRef?.current?.value]);

    const onChange = (value: string) => {
        if (!validatePriceCalendar(value)) {
            props.onSetEditClaimValue(undefined);
        } else {
            props.onSetEditClaimValue(JSON5.parse(value) as LicenseV2PriceCalendarItemClaimValue[]);
        }
    };

    return (
        <React.Fragment>
            <CRow gutters={false}>
                <strong>{getLicenseV2ClaimIdName(props.editClaim.id)}</strong>
            </CRow>

            <CRow gutters={false}>
                <CPopover content={t`Example [{from_day_of_week: 6, to_day_of_week: 6, from_hour: 11, to_hour: 11, price: 1200}, ...], where day of week is 0-6, Sunday is 0`} placement="right"
                          advancedOptions={{onShow: () => !isPriceCalendarValid}}>
                    <CInputGroup>
                        <CInput type="text"
                                innerRef={priceCalendarInputRef}
                                invalid={!isPriceCalendarValid}
                                defaultValue={priceCalendarValue && JSON5.stringify(priceCalendarValue) || ""}
                                onChange={(event) => onChange((event.target as HTMLInputElement).value)}/>
                    </CInputGroup>
                </CPopover>
            </CRow>
        </React.Fragment>
    );
});

export default EditLicenseV2PriceCalendarClaim;
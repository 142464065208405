import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol, CCollapse, CDataTable,
    CLink,
    CRow, CSpinner
} from "@coreui/react";
import {c, t} from "ttag";
import CIcon from "@coreui/icons-react";
import {DateAndTime, DateOnlyFormat} from "../DateAndTime";
import Error from "../Error";
import {TimelineRow} from "../../stores/LoadedSesionsStore";

const getStatusBadgeColor = (isLoaded: boolean, isSubmitted: boolean) => isLoaded ? "success" : (isSubmitted ? "info" : "danger");

const fields = [
    {key: "date"},
    {key: "createdAtUtc", _style: {width: "25%"}},
    {
        key: "load",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const LoadedSessionsView = observer(() => {
    const store = useContext(StoreContext);
    const {loadedSessions, auth} = store;

    const refresh = () => {
        loadedSessions.fetchLoadedLicenseSession();
    };

    const getTimelineKey = (timelineRow: TimelineRow) =>
        [timelineRow.year, timelineRow.month, timelineRow.day].toString();

    return (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`Loaded sessions (obsolete)`}

                        <CLink className="ml-1"
                               title={t`Refresh`}
                               onClick={() => refresh()}
                               disabled={loadedSessions.loading}>
                            <CIcon name="cil-sync" className="mfe-2" />
                        </CLink>
                    </CCardHeader>

                    <CCardBody>
                        <CDataTable
                                noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                items={(loadedSessions.timeline && loadedSessions.timeline.length > 0 && loadedSessions.timeline) || []}
                                loading={loadedSessions.loading}
                                fields={fields}
                                border={true}
                                columnHeaderSlot={{
                                    date: t`Date`,
                                    createdAtUtc: t`Loaded`
                                }}
                                scopedSlots={{
                                    date: (timelineRow: TimelineRow) =>
                                        <Observer>{() => {
                                            const key = getTimelineKey(timelineRow);
                                            const isSubmitted = loadedSessions.loadedLicenseSessionSubmitStatus.has(key) && !loadedSessions.loadedLicenseSessionSubmitStatus.get(key)?.error;
                                            const isLoading = loadedSessions.loadedLicenseSessionSubmitStatus.has(key) && !!loadedSessions.loadedLicenseSessionSubmitStatus.get(key)?.loading;

                                            return (
                                                <td>
                                                    <DateAndTime
                                                            date={new Date(timelineRow.year, timelineRow.month - 1, timelineRow.day)}
                                                            format={DateOnlyFormat} local={true}/>

                                                    <CBadge color={getStatusBadgeColor(!!timelineRow.loadedAtUtc, isSubmitted)}>
                                                        {timelineRow.loadedAtUtc ? t`loaded` : (isSubmitted ? c("loadedSessions").t`submitted` : t`not loaded`)}
                                                    </CBadge>

                                                    {isLoading && <CSpinner color="info" style={{width: '1rem', height: '1rem'}}/>}
                                                </td>
                                            );
                                        }}</Observer>,
                                    createdAtUtc: (timelineRow: TimelineRow) => (
                                        <td>
                                            {timelineRow.loadedAtUtc && <DateAndTime date={new Date(timelineRow.loadedAtUtc)}/>}
                                        </td>
                                    ),
                                    load: (timelineRow: TimelineRow) => {
                                        const key = getTimelineKey(timelineRow);

                                        return (
                                            <Observer>{() =>
                                                    <td className="py-2">
                                                        {!timelineRow.loadedAtUtc && <CLink onClick={() => loadedSessions.submitLoadLicenseSession(timelineRow.year, timelineRow.month, timelineRow.day)}
                                                               disabled={loadedSessions.loading || (loadedSessions.loadedLicenseSessionSubmitStatus.has(key) && loadedSessions.loadedLicenseSessionSubmitStatus.get(key)?.loading) || auth.isSecondaryMode}
                                                               title={auth.isSecondaryMode ? t`Isn't available in secondary mode` : t`Submit sessions loading task`}>
                                                            <CIcon name="cil-loop-circular" className="mfe-2"/>
                                                        </CLink>}
                                                    </td>
                                            }</Observer>
                                        );
                                    },
                                    details: (timelineRow: TimelineRow) => {
                                        const key = getTimelineKey(timelineRow);

                                        return (
                                            <Observer key={key}>{() =>
                                                    <CCollapse show={loadedSessions.loadedLicenseSessionSubmitStatus.has(key) && !!loadedSessions.loadedLicenseSessionSubmitStatus.get(key)?.error}>
                                                        <CCardBody>
                                                            <Error>{loadedSessions.loadedLicenseSessionSubmitStatus.get(key)?.error}</Error>
                                                        </CCardBody>
                                                    </CCollapse>
                                            }</Observer>
                                        );
                                    }
                                }}
                                underTableSlot={loadedSessions.error && <Error>{loadedSessions.error}</Error>}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
});

export default LoadedSessionsView;
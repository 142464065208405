import {action, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Customer, Maybe, Query, UserRoleType} from "../generated/graphql";
import {
    apolloClient,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";

class ActiveCustomersStore {
    @observable.ref
    public allActiveCustomers: Maybe<Customer>[] | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin,
            (shouldFetch) => {
                if (shouldFetch) {
                    this.fetchAllActiveCustomers();
                }
            }
        );

        reaction(
            () => this.rootStore.customers.customers,
            () => {
                this.fetchAllActiveCustomers();
            }
        );
    }

    @action
    public fetchAllActiveCustomers() {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query>({
            query: gql`query {
                currentUserId,
                allCustomers(orderBy: NAME_ASC, condition: {isArchived: false}) {
                    nodes {
                        id,
                        name
                    }
                }
            }`
        })).then(result => {
            runInAction(() => {
                this.allActiveCustomers = result.data.allCustomers?.nodes;
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public reset() {
        this.allActiveCustomers = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    ActiveCustomersStore
};
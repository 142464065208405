import gql from "graphql-tag";
import CustomerFragment from "./CustomerFragment";

const VenueFragment = gql`fragment VenueFragment on Venue {
    id,
    isArchived,
    country,
    city,
    location,
    createdAtUtc,
    timezone,
    priceCalendarDefault {
        fromDayOfWeek,
        toDayOfWeek,
        fromHour,
        toHour,
        price
    },
    priceDefault {
        price,
        priceCurrency,
        priceVariant
    },
    royaltyDefault {
        royalty,
        royaltyShare,
        royaltyCurrency,
        royaltyVariant
    },
    customerByCustomerId {
        ...CustomerFragment
    }
},
${CustomerFragment}`;

export default VenueFragment;
import {action, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {Maybe, Query, User, UserFilter} from "../generated/graphql";
import {
    apolloClient,
    DelayMSDefault,
    PageInfo,
    PageParam,
    PageSizeDefault,
    wrapForApiErrors
} from "../api/graphql/ApolloClient";
import UserFragment from "../api/graphql/UserFragment";
import {debounceEffect} from "../utils/MobxUtils";

class UsersStore {
    @observable.ref
    public filter: UserFilter | undefined = undefined;

    @observable.ref
    public page: PageParam = {
        after: null,
        before: null,
        isLast: false
    };

    @observable.ref
    public users: Maybe<User>[] | undefined = undefined;

    @observable.ref
    public pageInfo: PageInfo | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => {
                return {
                    filter: this.filter,
                    page: this.page
                };
            },
            debounceEffect(() => {
                this.fetchUsers();
            }, DelayMSDefault)
        );
    }

    @action
    public fetchUsers() {
        this.loading = true;
        this.error = undefined;

        wrapForApiErrors(apolloClient.query<Query, {
            filterInput: UserFilter | undefined,
            afterInput: string | null,
            firstInput: number | null,
            beforeInput: string | null,
            lastInput: number | null
        }>({
            query: gql`query ($filterInput: UserFilter, $afterInput: Cursor, $firstInput: Int, $beforeInput: Cursor, $lastInput: Int) {
                currentUserId,
                allUsers(orderBy: CREATED_AT_UTC_DESC, filter: $filterInput, after: $afterInput, first: $firstInput, before: $beforeInput, last: $lastInput) {
                    nodes {
                        ...UserFragment
                    },
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    },
                    totalCount
                }
            }
            ${UserFragment}`,
            variables: {
                filterInput: this.filter,
                afterInput: this.page.after,
                firstInput: this.page.after || (!this.page.before && !this.page.isLast) ? PageSizeDefault : null,
                beforeInput: this.page.before,
                lastInput: this.page.before || this.page.isLast ? PageSizeDefault : null
            }
        })).then(result => {
            runInAction(() => {
                this.users = result.data.allUsers?.nodes;
                this.pageInfo = {
                    firstPage: !result.data.allUsers?.pageInfo.hasPreviousPage ? undefined : {
                        after: null,
                        before: null
                    },
                    lastPage: !result.data.allUsers?.pageInfo.hasNextPage ? undefined : {
                        after: null,
                        before: null,
                        isLast: true
                    },
                    nextPage: !result.data.allUsers?.pageInfo.hasNextPage ? undefined : {
                        after: result.data.allUsers?.pageInfo.endCursor as string,
                        before: null
                    },
                    prevPage: !result.data.allUsers?.pageInfo.hasPreviousPage ? undefined : {
                        after: null,
                        before: result.data.allUsers?.pageInfo.startCursor as string
                    },
                    totalCount: result.data.allUsers?.totalCount || 0
                };
            });
        }, error => {
            runInAction(() => {
                this.error = error.getMessage();
            });
        }).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    @action
    public setFilter(filter: UserFilter | undefined) {
        this.filter = filter;

        if (this.pageInfo?.firstPage) {
            this.setPage(this.pageInfo.firstPage);
        }
    }

    @action
    public setPage(page: PageParam) {
        this.page = page;
    }

    @action
    public reset() {
        this.filter = undefined;
        this.page = {
            after: null,
            before: null,
            isLast: false
        };
        this.users = undefined;
        this.pageInfo = undefined;
        this.loading = false;
        this.error = undefined;
    }
}

export {
    UsersStore
};